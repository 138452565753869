import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Route, Redirect, useHistory } from "react-router-dom"
import ErrorBoundary from "../../components/ErrorBoundary"
import {
  GET_PERMISSIONS_LIST,
  GET_ORGANIZATION_BY_SLUG,
} from "../../pages/Organizations/gql/Gql"
import { useDispatch, useSelector } from "react-redux"
import { useLazyQuery } from "@apollo/client"
import PageNotFound from "../../pages/404"
import Loader from "../../components/Common/Loader"
import { generateDarkAndLightColors } from "../../functions/functions"
import { useLocation } from "react-router-dom"
import { orgDetails } from "../../store/actions"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  userPermission,
  path,
  ...rest
}) => {
  let history = useHistory()
  // console.log("con", isAuthProtected && !localStorage.getItem("authUser"))
  const [getPermissionOrgWise, getPermissionOrgWiseList] = useLazyQuery(
    GET_PERMISSIONS_LIST,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [getOrganizationBySlug, getOrganizationBySlugList] = useLazyQuery(
    GET_ORGANIZATION_BY_SLUG,
    {
      fetchPolicy: "no-cache",
    }
  )

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const switchAccount = new URLSearchParams(location.search).get("token")
  if(switchAccount){
    localStorage.setItem("token", switchAccount)
    setTimeout(() => {
      let currentUrl = new URL(window.location.href)
      currentUrl.searchParams.delete("token")
      window.history.replaceState({}, document.title, currentUrl.href)
      history.push("/dashboard")
      window.location.reload()  
    }, 1000)
  }
  const currentPage = location.pathname
  useEffect(() => {
    if (currentPage == "/login" || currentPage == "/register") {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [])

  const userData = useSelector(state => state.Login?.user?.user)
  useEffect(() => {
    if (userData) {
      getPermissionOrgWise({
        variables: {
          argumentsInput: {
            role_id: Number(userData?.role.id),
            organization_id: orgData ? orgData.id : 1,
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data &&
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role &&
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role
        ?.rolePermissions
    ) {
      const moduleList =
        getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role
          ?.rolePermissions
      const slugArray = moduleList.map(permission => permission.method.slug)
      localStorage.setItem("userPermission", JSON.stringify(slugArray))
    }
  }, [getPermissionOrgWiseList.data])

  const pathName = path.replace(/^\/|\/$/g, "")
  const dispatch = useDispatch()

  let allPaths = userPermission
    ? [
        ...userPermission,
        "login",
        "register",
        "organizations",
        "user-permission",
        "orgnization-users",
        "orgnization-settings",
      ]
    : [
        "login",
        "register",
        "organizations",
        "user-permission",
        "orgnization-users",
        "orgnization-settings",
      ]
  let hasPermission = allPaths?.includes(pathName)

  useEffect(() => {
    let currentPath = window.location.origin + "/"
    // const currentPath = "https://alchemy.qa.wendy.health/"
    getOrganizationBySlug({
      variables: {
        organizationInput: {
          domain: currentPath,
        },
      },
    }).then(res => {
      if (res?.data?.getOrganizationBySlug?.data?.organization) {
        const orgData = res?.data?.getOrganizationBySlug?.data?.organization
        dispatch(orgDetails(orgData))
        if(orgData?.account){
          let accData = orgData?.account;
          const orgIdentifier = orgData?.organization_identifier;
          if (accData && orgIdentifier) {
            accData = { ...accData, organization_identifier : orgIdentifier };
          }      
          const encodedData = btoa(JSON.stringify(accData));
          localStorage.setItem('accountData', encodedData);
        }else{
          localStorage.removeItem("accountData")
        }
        if (orgData?.id) {
          const encodedId = btoa(orgData?.id)
          localStorage.setItem("orgId", JSON.stringify(encodedId))
        }
        if (orgData.id !== 1) {
          localStorage.setItem("orgData", JSON.stringify(orgData))
          if (orgData?.favicon) {
            let link = document.querySelector("link[rel~='icon']")
            if (!link) {
              link = document.createElement("link")
              link.rel = "icon"
              document.getElementsByTagName("head")[0].appendChild(link)
            }
            link.href = orgData?.favicon
            document.title = orgData?.name
          }

          const primaryShadesColor = generateDarkAndLightColors(
            orgData?.primary_color
          )
          const defaultColors = {
            "--background-color": "#F4F7FE",
            "--text-color-dark": "#000",
            "--text-dark": "#1e1e1e",
            "--text-light": "#ffffff",
            "--chart-text": "#878e94",
            "--text-color-inactive-links": "#172426",
            "--dark-green": "#22696D",
            "--btn-disabled": "#7FA8AA",
            "--green-color-light": "#84a17d",
            "--green-color-light-table": "#84a17d",
            "--green-color-bg-card": "#488386",
            "--green-color-bg-card-th": "#488386",
            "--green-color-bg-card-tbl": "#488386",
            "--bs-body-font-size": "1rem",
            "--bs-nav-link-color": "var(--dark-green)",
            "--bs-nav-link-hover-color": "var(var(--green-color-light))",
            "--bs-btn-color": "#fff",
            "--bs-btn-bg": "#22696D",
            "--bs-btn-border-color": "#22696D",
            "--bs-btn-hover-color": "#fff",
            "--bs-btn-hover-bg": "#488386",
            "--bs-btn-hover-border-color": "#488386",
            "--bs-btn-focus-shadow-rgb": "95, 106, 187",
            "--bs-btn-active-color": "#fff",
            "--bs-btn-active-bg": "#22696D",
            "--bs-btn-active-border-color": "#22696D",
            "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
            "--bs-btn-disabled-color": "#fff",
            "--bs-btn-disabled-bg": "#2e4338",
            "--bs-btn-disabled-border-color": "#2e4338",
            "--bs-card-green-light": "#cddbc9",
            "--bs-card-green-light-tr": "#cddbc9"
          }

          const customColors = orgData?.primary_color
            ? {
                "--dark-green": primaryShadesColor?.dark,
                "--text-color-dark": orgData?.font_color,
                "--text-dark": orgData?.font_color,
                "--text-light": orgData?.font_color,
                "--chart-text": orgData?.font_color,
                "--text-color-inactive-links": orgData?.font_color,
                "--btn-disabled": orgData?.primary_color,
                "--green-color-light": primaryShadesColor?.light,
                "--green-color-light-table": "white",
                "--green-color-bg-card": orgData?.primary_color,
                "--green-color-bg-card-th": "white",
                "--green-color-bg-card-tbl": "white",
                "--bs-nav-link-color": orgData?.primary_color,
                "--bs-nav-link-hover-color": orgData?.secondary_color,
                "--bs-btn-color": "#fff",
                "--bs-btn-bg": orgData?.primary_color,
                "--bs-btn-border-color": orgData?.secondary_color,
                "--bs-btn-hover-color": "#fff",
                "--bs-btn-hover-bg": orgData?.secondary_color,
                "--bs-btn-hover-border-color": orgData?.primary_color,
                "--bs-btn-focus-shadow-rgb": "95, 106, 187",
                "--bs-btn-active-color": " #fff",
                "--bs-btn-active-bg": orgData?.primary_color,
                "--bs-btn-active-border-color": orgData?.primary_color,
                "--bs-btn-active-shadow": `inset 0 3px 5px ${orgData?.primary_color}`,
                "--bs-btn-disabled-color": "#fff",
                "--bs-btn-disabled-bg": orgData?.primary_color,
                "--bs-btn-disabled-border-color": orgData?.secondary_color,
                "--bs-card-green-light": primaryShadesColor?.light,
                "--bs-card-green-light-tr": "#eee",
              }
            : {}

          const allColors = { ...defaultColors, ...customColors }

          Object.entries(allColors).forEach(([property, value]) => {
            document.documentElement.style.setProperty(property, value)
          })
        } else {
          localStorage.removeItem("orgData")
          if (history.location.pathname === "/lookup") {
            document.title = "Wendy Lookup"
          } else {
            document.title = "NFC Connect"
          }
        }
      } else {
        localStorage.removeItem("orgData")
        if (history.location.pathname === "/lookup") {
          document.title = "Wendy Lookup"
        } else {
          document.title = "NFC Connect"
        }
      }
    })
  }, [])

  useEffect(() => {
    if (history.location.pathname === "/lookup") {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement("link")
        link.rel = "icon"
        document.getElementsByTagName("head")[0].appendChild(link)
      }
      link.href = "/wendyImg.jpg"
      document.title = "Wendy Lookup"
    }
  }, [])

  return (
    <Route
      {...rest}
      render={props => {
        let userData = JSON.parse(localStorage.getItem("authUser"))
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          localStorage.setItem(
            "next",
            history.location.pathname.includes("next")
          )
          return (
            <Redirect
              to={{
                pathname: "/login",
                search: `next=${history.location.pathname}`,
                state: { from: props.location },
              }}
            />
          )
        } else if (
          userData &&
          userData?.user.role &&
          userData?.user.role.role_name == "Client"
        ) {
          if (
            !props.location.pathname.includes("/appointments/join/") ||
            !props.location.pathname.includes("/login")
          ) {
          } else if (
            userData &&
            !props.location.pathname.includes("/appointments/join/") &&
            !props.location.pathname.includes("/login")
          ) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  search: `next=/dashboard`,
                  state: { from: props.location },
                }}
              />
            )
          }
        } else if (
          userData &&
          userData?.user.role &&
          userData?.user.role.role_name == "Patient"
        ) {
          if (
            !props.location.pathname.includes("/appointments/join/") ||
            !props.location.pathname.includes("/login")
          ) {
          } else if (
            userData &&
            !props.location.pathname.includes("/appointments/join/") &&
            !props.location.pathname.includes("/login")
          ) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  search: `next=/dashboard`,
                  state: { from: props.location },
                }}
              />
            )
          }
        } else {
          let userData = JSON.parse(localStorage.getItem("authUser"))
          let is_password_reset = localStorage.getItem("is_password_reset")
          if (
            userData?.user?.user_type !== "admin" &&
            is_password_reset === "false" &&
            props.location.pathname !== "/update-password"
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/update-password",
                  state: { from: props.location },
                }}
              />
            )
          }
        }
        return (currentPage == "/login" || currentPage == "/register") &&
          isLoading ? (
          <div className="mainLoader">
            <Loader />
          </div>
        ) : (
          <Layout>
            <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          </Layout>
        )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
