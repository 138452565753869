import React, { useCallback, useEffect, useState } from "react"
import {
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { useParams, Link, useHistory } from "react-router-dom"
import PhoneNoField from "./PhoneNoField"
import style from "./style.module.scss"
import {
  CREATE_EDIT_USER,
  GET_CLIENT_APPOINTMENT,
  GET_USER,
  GET_USER_BY_USER,
  GET_USER_ROLE,
} from "./gql/userGql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_ORGANIZATION } from "../Organizations/gql/Gql"
import Select from "react-select"
import Soinner from "../../assets/images/Loader.svg"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import { GET_TIMEZONE } from "../Counselor/gql/Gql"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loader from "../../components/Common/Loader"
import { debounce } from "lodash"
const MultiRoleUser = () => {
  let { username } = useParams()
  const decodedId = username && atob(username)
  username = decodedId
  let history = useHistory()
  const [roleList, setRoleList] = useState([])
  const [organizationsList, setOrganizationsList] = useState([])
  const [multiOrgRolesArr, setMultiOrgRolesArr] = useState([
    { roleOptions: [], orgOptions: [] },
  ])
  const [emptyFields, setEmptyFields] = useState([])

  const [selectedRoleArr, setSelectedRoleArr] = useState([])
  const [selectedOrgArrLength, setSelectedOrgArrLength] = useState(0)
  const [selectedOrgArr, setSelectedOrgArr] = useState([])
  const [selectedRoleArrIds, setSelectedRoleArrIds] = useState([])
  const [selectedOrgArrIds, setSelectedOrgArrIds] = useState([])
  const [getTimezone, getTimezoneRes] = useLazyQuery(GET_TIMEZONE, {
    fetchPolicy: "no-cache",
  })
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role_id: "",
    mobile_number: "",
    first_name: "",
    last_name: "",
    organizationIds: [],
    roleIds: [],
    url: "",
    timezone: "",
    primary_phone: "",
  })
  const [getUserDetail, details] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  })

  const [getEmailByRole, getEmailByRoleRes] = useLazyQuery(GET_USER_BY_USER, {
    fetchPolicy: "no-cache",
  })
  const [getClientAppointment, clientAppointmentRes] = useLazyQuery(
    GET_CLIENT_APPOINTMENT,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [isSpinner, setIsSpinner] = useState(false)
  const [hasAppointments, setHasAppointments] = useState([])
  React.useEffect(() => {
    if (username) {
      setIsSpinner(true)
      getUserDetail({
        variables: {
          argumentsInput: {
            id: parseInt(username),
          },
        },
      })
    } else {
      setIsSpinner(false)
    }
  }, [username])

  useEffect(() => {
    if (username) {
      getClientAppointment({
        variables: {
          argumentsInput: {
            page: 1,
            limit: 20,
            client_id: parseInt(username),
            search: "",
            sort: "utc_datetime",
            sort_dir: "DESC",
            start_date: "",
            end_date: "",
            organization_id: null,
            device_type:"web",
          },
        },
      })
    }
  }, [username])

  useEffect(() => {
    if (
      clientAppointmentRes?.data &&
      clientAppointmentRes?.data?.getAppointments &&
      clientAppointmentRes?.data?.getAppointments?.status &&
      clientAppointmentRes?.data?.getAppointments?.code === 200
    ) {
      const allAppointmentOrgList = clientAppointmentRes?.data?.getAppointments?.data?.appointments_arr.map((elem)=>{
        return elem?.appointments?.organization?.id
      })
      setHasAppointments(allAppointmentOrgList)
    }
  }, [clientAppointmentRes.data])

  React.useEffect(() => {
    if (
      details.data &&
      details.data?.getUser &&
      details.data?.getUser?.status
    ) {
      const userData = details.data?.getUser?.data?.user
      const roleArr = userData?.organizations.map(elem => {
        return {
          id: elem?.role?.id,
          value: elem?.role?.id,
          name: elem?.role?.role_name,
          label: elem?.role?.role_name,
        }
      })
      const orgArr = userData?.organizations.map(elem => {
        return {
          id: elem?.organization?.id,
          value: elem?.organization?.id,
          name: elem?.organization?.name,
          label: elem?.organization?.name,
        }
      })
      const orgList = userData?.organizations.map(item => {
        return {
          orgOptions: organizationsList,
          roleOptions: roleList,
        }
      })
      setSelectedOrgArr(orgArr)
      setSelectedRoleArr(roleArr)
      setMultiOrgRolesArr(orgList)
      setSelectedOrgArrLength(orgList.length)
      setFormData(prevData => ({
        ...prevData,
        id: userData?.id,
        name: userData.name ? userData.name?.trim() : "",
        email: userData.email ? userData.email?.trim() : "",
        first_name: userData.first_name ? userData.first_name?.trim() : "",
        last_name: userData.last_name ? userData.last_name?.trim() : "",
        name: userData?.calendar?.name?.trim() || "",
        mobile_number: userData?.mobile_number || "",
      }))
      setIsSpinner(false)
    } else {
      if (username) {
        setIsSpinner(true)
      }
    }
  }, [details?.data])

  const [getUserRoleList, rolesResponse] = useLazyQuery(GET_USER_ROLE, {
    fetchPolicy: "no-cache",
  })

  const [getOrganizations] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: "no-cache",
  })
  function cancelForm() {
    history.push("/orgnization-users")
  }

  function getUserRoleListFun() {
    getUserRoleList({
      variables: {
        argumentsInput: {
          page: 1,
          limit: 1000,
          search: "",
          sort: "id",
        },
      },
    })
  }

  const getOrganizationsFun = () => {
    getOrganizations({
      variables: {
        argumentsInput: {
          limit: 50,
          page: 1,
          search: "",
        },
      },
      onCompleted({ getOrganizations }) {
        if (getOrganizations?.data && getOrganizations?.data?.organizations) {
          const orgList = getOrganizations?.data?.organizations.map(item => {
            return {
              id: item.id,
              name: item.name,
              label: item.name,
              value: item.id,
            }
          })
          setOrganizationsList(orgList)
          setMultiOrgRolesArr(prevState => {
            return prevState.map(item => ({
              ...item,
              orgOptions: orgList,
            }))
          })
        }
      },
    })
  }

  React.useEffect(() => {
    if (
      rolesResponse.data &&
      rolesResponse.data.getRoles &&
      rolesResponse.data.getRoles.status
    ) {
      let data = rolesResponse.data.getRoles.data.roles.map(item => {
        return {
          id: item.id,
          value: item.id,
          name: item.role_name,
          label: item.role_name,
        }
      })
      data = data.filter(elem => {
        return elem.name.toLowerCase() != "superadmin"
      })
      setRoleList(data)

      setMultiOrgRolesArr(prevState => {
        return prevState?.map(item => ({
          ...item,
          roleOptions: data,
        }))
      })
    }
  }, [rolesResponse.data])

  useEffect(() => {
    getUserRoleListFun()
    getOrganizationsFun()
  }, [])

  useEffect(() => {
    if (selectedRoleArr.length > 0) {
      const allIds = selectedRoleArr.map(elem => {
        return elem.id
      })
      if (allIds.includes(2)) {
        getTimezone()
      }
      setSelectedRoleArrIds(allIds)
    }
    if (selectedOrgArr.length > 0) {
      const allIds = selectedOrgArr.map(elem => {
        return elem.id
      })
      setSelectedOrgArrIds(allIds)
    }
  }, [selectedRoleArr, selectedOrgArr])

  const changeRole = (value, ind) => {
    const newArray = [...selectedRoleArr]
    newArray[ind] = value
    setSelectedRoleArr(newArray)
  }
  const changeOrg = (value, ind) => {
    const newArray = [...selectedOrgArr]
    newArray[ind] = value
    setSelectedOrgArr(newArray)
  }

  const removeField = (org, index) => {
    const removedList = multiOrgRolesArr.filter((elem, ind) => {
      return ind !== index
    })
    const newRoleList = selectedRoleArr.filter((elem, ind) => {
      return ind !== index
    })
    const newOrgList = selectedOrgArr.filter((elem, ind) => {
      return ind !== index
    })
    if (organizationsList.length !== multiOrgRolesArr.length) {
      const updatedMultiOrgRolesArr = [...removedList]
      updatedMultiOrgRolesArr[
        updatedMultiOrgRolesArr.length - 1
      ].orgOptions.push(org)
      setMultiOrgRolesArr(updatedMultiOrgRolesArr)
    } else {
      setMultiOrgRolesArr(removedList)
    }

    setSelectedRoleArr(newRoleList)
    setSelectedOrgArr(newOrgList)
  }

  const addRoleOrg = () => {
    const newArray = JSON.parse(JSON.stringify(multiOrgRolesArr))
    const existingObject = {
      roleOptions: roleList,
      orgOptions: organizationsList,
    }
    const newObj = { ...existingObject }

    selectedOrgArr.map(elem => {
      newObj.orgOptions = newObj.orgOptions.filter(
        option => option.id !== elem.id
      )
    })

    newArray.push(newObj)
    setMultiOrgRolesArr(newArray)
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name === "email") {
      const newValue = value.replace(/\s/g, "")
      setFormData(prevData => ({
        ...prevData,
        [name]: newValue,
      }))
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }
  }
  const handlePhoneInput = val => {
    setFormData(prevData => ({
      ...prevData,
      primary_phone: val,
      mobile_number: val,
    }))
  }

  const [submitMutation, submitResponse] = useMutation(CREATE_EDIT_USER)

  const submitForm = () => {
    const roleIds = selectedRoleArr.map(elem => {
      return elem.id
    })
    const organizationIds = selectedOrgArr.map(elem => {
      return elem.id
    })
    const validateClient = data => {
      const requiredFields = ["email", "first_name", "last_name"]
      if (roleIds.includes(2) && !username) {
        requiredFields.push("name", "timezone")
      }
      let missingFields = requiredFields.filter(field => !data[field])
      if (multiOrgRolesArr.length !== selectedOrgArr.length) {
        missingFields.push("organizationIds")
      }
      if (multiOrgRolesArr.length !== selectedRoleArr.length) {
        missingFields.push("roleIds")
      }

      setEmptyFields(missingFields)
      if (missingFields.length > 0) {
        window.scrollTo({ top: 150, behavior: "smooth" })
        return false
      }

      return true
    }

    const clientData = {
      id: username ? parseInt(username) : null,
      role_id: selectedRoleArr.length > 0 ? selectedRoleArr[0].id : 3,
      name: "",
      email: formData?.email?.toLowerCase(),
      mobile_number:
        formData?.mobile_number.length > 5 ? formData?.mobile_number : null,
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      organizationIds: organizationIds,
      roleIds: roleIds,
    }
    const counsellorData = {
      id: username ? parseInt(username) : null,
      name: formData?.name,
      email: formData?.email?.toLowerCase(),
      primary_phone:
        formData?.mobile_number.length > 5 ? formData?.mobile_number : null,
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      mobile_number:
        formData?.mobile_number.length > 5 ? formData?.mobile_number : null,
      organizationIds: organizationIds,
      roleIds: roleIds,
      locations_group_ids: [],
      timezone: formData?.timezone?.id,
    }

    validateClient(clientData)
    if (roleIds.includes(2)) {
      if (validateClient(counsellorData)) {
        if (username) {
          const { locations_group_ids, timezone, primary_phone, ...rest } =
            counsellorData
          const newData = { ...rest, is_edit: true, is_role_editable: true }
          submitMutation({
            variables: {
              createUserInput: newData,
            },
          })
        } else {
          submitMutation({
            variables: {
              createUserInput: counsellorData,
            },
          })
        }
      }
    } else {
      if (validateClient(clientData)) {
        if (username) {
          const { ...rest } = clientData
          const newData = { ...rest, is_edit: true, is_role_editable: true }
          submitMutation({
            variables: {
              createUserInput: newData,
            },
          })
        } else {
          submitMutation({
            variables: {
              createUserInput: clientData,
            },
          })
        }
      }
    }
  }

  const [isSubmitted, setIsSubmitted] = useState(false)

  React.useEffect(() => {
    if (
      submitResponse?.data &&
      submitResponse?.data?.createAdminUser?.status &&
      submitResponse?.data?.createAdminUser?.code === 200
    ) {
      toast.success(submitResponse.data.createAdminUser?.message)

      setIsSubmitted(false)
      setTimeout(() => {
        if (username) {
          setIsSubmitted(false)
          cancelForm()
        } else {
          setIsSubmitted(false)
          cancelForm()
        }
      }, 3000)
    } else {
      if (submitResponse?.data && submitResponse?.data?.createAdminUser) {
        toast.error(submitResponse.data.createAdminUser?.message)
        setIsSubmitted(false)
      }
      setIsSubmitted(false)
    }
  }, [submitResponse.data])

  const handleImg = async (acceptedFiles, field) => {
    if (acceptedFiles[0].size > 5 * 1024 * 1000) {
      toast.error("Max File Upload Size is Upto 5 MB, try with smaller size")
      return
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }(field, preview)

    let payload = new FormData()
    payload.set("file", acceptedFiles[0])
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get(
      "orgId"
    )
    const decodedOrgIdFromUrl = orgIdValueFromUrl
      ? decodeURIComponent(orgIdValueFromUrl)
      : undefined

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        {
          method: "POST",
          body: payload,
          headers: {
            organization:
              decodedOrgIdFromUrl ||
              orgData?.organization_identifier ||
              process.env.REACT_APP_MULTITENENT,
          },
        }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        setFormData(prevData => ({
          ...prevData,
          url: preview,
        }))
      } else {
        setFormData(prevData => ({
          ...prevData,
          url: undefined,
        }))
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      setFormData(prevData => ({
        ...prevData,
        url: undefined,
      }))
      toast.error("Failed to upload cover image")
    }
  }

  const [timezone, setTimeZone] = useState([])

  useEffect(() => {
    if (
      getTimezoneRes.data &&
      getTimezoneRes.data.getAllTimeZones &&
      getTimezoneRes.data.getAllTimeZones.code === 200
    ) {
      let arraytimezone =
        getTimezoneRes?.data?.getAllTimeZones?.data?.allTimeZone

      arraytimezone = arraytimezone.map(e => {
        return { name: e.display, id: e.zoneName }
      })
      setTimeZone(arraytimezone)
      setFormData(prevData => ({
        ...prevData,
        timezone: {
          id: "America/Vancouver",
          name: "(GMT-8:00) America/Vancouver",
        },
      }))
    }
  }, [getTimezoneRes.data])

  const handleChangeTImezone = value => {
    setFormData(prevData => ({
      ...prevData,
      timezone: value,
    }))
  }

  const handleCheckEmail = e => {
    const email = e.target.value.toLowerCase().replace(/\s/g, "")
    function isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailPattern.test(email)
    }
    if (isValidEmail(email)) {
      getEmailByRole({
        variables: {
          argumentsInput: {
            email: email,
          },
        },
      })
    }
  }

  const isAlreadyExistsFun = useCallback(debounce(handleCheckEmail, 1000), [])

  useEffect(() => {
    if (
      getEmailByRoleRes.data?.getUserByEmail?.status &&
      getEmailByRoleRes.data?.getUserByEmail?.code === 200
    ) {
      const isExist = getEmailByRoleRes.data?.getUserByEmail?.data?.is_exist
      if (isExist) {
        const userData = getEmailByRoleRes.data?.getUserByEmail?.data?.user
        const encodedId = btoa(userData.id)
        toast.success(
          "User with this email is already exists. Redirecting you to the edit page."
        )
        setTimeout(() => {
          history.push(`/multiUsers/${encodedId}/edit`)
        }, 1000)
      }
    }
  }, [getEmailByRoleRes.data])

  return (
    <React.Fragment>
      <div className="page-content">
        {isSpinner ? (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          </>
        ) : (
          <div className="container-fluid">
            <Row>
              <Col className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <i
                      className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                      id="backArrow"
                      onClick={() => cancelForm()}
                    />
                    <h4 className={`${style.headingsubtitle} card-title-main`}>
                      {username ? "Edit user" : "Add User"}
                    </h4>
                  </div>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="/users">Users</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">
                          {username ? "Edit user" : "Add User"}
                        </Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col className="col-8 user-role-create-height position-relative ">
                <h4 className="card-title-main mb-4 ms-4 ps-1">User Details</h4>

                <Card className="transparent-bg-card">
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter your email"
                            type="email"
                            onChange={event => {
                              handleChange(event)
                              isAlreadyExistsFun(event)
                            }}
                            disabled={username}
                            value={formData.email.trim() || ""}
                          />
                          {emptyFields.includes("email") && !formData.email && (
                            <p className="text-danger">Please enter email</p>
                          )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">First Name</Label>
                          <Input
                            id="first_name"
                            name="first_name"
                            className="form-control"
                            placeholder="Enter your first name"
                            type="text"
                            onChange={handleChange}
                            value={formData.first_name || ""}
                          />
                          {emptyFields.includes("first_name") &&
                            !formData.first_name && (
                              <p className="text-danger">
                                Please enter first name
                              </p>
                            )}
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Last Name</Label>
                          <Input
                            id="last_name"
                            name="last_name"
                            className="form-control"
                            placeholder="Enter your last name"
                            type="text"
                            onChange={handleChange}
                            value={formData.last_name || ""}
                          />
                          {emptyFields.includes("last_name") &&
                            !formData.last_name && (
                              <p className="text-danger">
                                Please enter last name
                              </p>
                            )}
                        </div>
                      </Col>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Phone number</Label>
                          <div className="input-group">
                            <PhoneNoField
                              className="phoneinpute"
                              name="mobile_number"
                              onChange={value => {
                                if (!value.startsWith("+")) {
                                  value = "+" + value
                                }
                                handlePhoneInput(value)
                              }}
                              value={formData.mobile_number || ""}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="">
                        <Label className="form-label">Organizations</Label>
                      </Col>
                      <Col md={6} className="">
                        <div className="">
                          <Label className="form-label">Roles</Label>
                        </div>
                      </Col>
                    </Row>
                    {multiOrgRolesArr.length > 0 &&
                      multiOrgRolesArr?.map((elem, ind) => {
                        return (
                          <Row key={ind} className="d-flex- align-items-center">
                            <Col md={6} className="">
                              <Select
                                className={`input`}
                                value={selectedOrgArr[ind] || ""}
                                options={elem?.orgOptions}
                                placeholder="Select Organization"
                                isDisabled={
                                  multiOrgRolesArr.length > ind + 1 ||
                                  hasAppointments?.includes(selectedOrgArr[ind]?.id)
                                }
                                onChange={value => changeOrg(value, ind)}
                              />
                            </Col>
                            <Col
                              md={multiOrgRolesArr.length > 1 ? 5 : 6}
                              className=""
                            >
                              <Select
                                className={`input`}
                                value={selectedRoleArr[ind] || ""}
                                options={elem?.roleOptions}
                                placeholder="Select Role"
                                isDisabled={ hasAppointments?.includes(selectedOrgArr[ind]?.id)}
                                onChange={value => changeRole(value, ind)}
                              />
                            </Col>
                            {multiOrgRolesArr.length > 1 && (
                              <Col md={1} className="p-2">
                                <Button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    removeField(selectedOrgArr[ind], ind)
                                  }
                                  disabled={hasAppointments?.includes(selectedOrgArr[ind]?.id)}
                                >
                                  -
                                </Button>
                              </Col>
                            )}
                          </Row>
                        )
                      })}
                    <Row>
                      <Col md={6}>
                        {emptyFields.includes("organizationIds") &&
                          selectedOrgArrIds.length !=
                            multiOrgRolesArr.length && (
                            <p className="text-danger">
                              Please select organization
                            </p>
                          )}
                      </Col>
                      <Col md={6}>
                        {emptyFields.includes("roleIds") &&
                          selectedRoleArr.length != multiOrgRolesArr.length && (
                            <p className="text-danger">Please select role</p>
                          )}
                      </Col>
                    </Row>

                    {organizationsList.length !== multiOrgRolesArr.length && (
                      <Row className="mt-2">
                        <Col md={12}>
                          <Button
                            className="btn btn-primary"
                            onClick={() => addRoleOrg()}
                            disabled={
                              multiOrgRolesArr.length !==
                                selectedRoleArr.length ||
                              multiOrgRolesArr.length !== selectedOrgArr.length
                            }
                          >
                            Add{" "}
                          </Button>
                        </Col>
                      </Row>
                    )}

                    {selectedRoleArrIds.includes(2) && (
                      <>
                        <p className="h5 mt-3 font-weight-bold">
                          Calendar Details
                        </p>
                        <Row className="mt-3">
                          <Col
                            xl={5}
                            lg={12}
                            md={5}
                            className="mb-4 form-custom-white-inputs"
                          >
                            <Label className="form-label">Image</Label>
                            <Dropzone
                              multiple={false}
                              onDrop={file => handleImg(file, "url")}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <input {...getInputProps()} />

                                  {!formData?.url ? (
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <div className="mb-2">
                                        <i className="display-4 uil uil-cloud-upload" />
                                      </div>
                                      <h5>Drop JPEG, JPG or PNG.</h5>
                                      <h6>
                                        It can be square, landscape or portrait.
                                        Upto 5MB file size.
                                      </h6>
                                    </div>
                                  ) : (
                                    <>
                                      {formData?.url?.state === "uploading" && (
                                        <img
                                          className="needsclick rounded bg-light w-100 h-auto"
                                          alt="primary"
                                          src={Soinner}
                                        />
                                      )}
                                      {formData?.url?.state === "uploaded" && (
                                        <div>
                                          <img
                                            {...getRootProps()}
                                            className="needsclick rounded bg-light w-100 h-auto"
                                            alt="primary"
                                            src={formData?.url?.url}
                                          />
                                          <button
                                            onClick={() => {
                                              setFormData(prevData => ({
                                                ...prevData,
                                                url: undefined,
                                              }))
                                            }}
                                            className={`${style.removeButton} mdi mdi-close`}
                                            type="button"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <div className="mb-2 form-custom-white-inputs">
                              <Label className="form-label">
                                Calendar Name
                              </Label>
                              <Input
                                id="name"
                                name="name"
                                className={` form-control`}
                                placeholder="Enter calendar name"
                                type="text"
                                onChange={handleChange}
                                value={formData?.name || ""}
                              />
                              {emptyFields.includes("name") &&
                                !formData.name && (
                                  <p className="text-danger">
                                    Please enter calendar name
                                  </p>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <div className="mb-2 form-custom-white-inputs">
                              <Label className="form-label">Timezone</Label>
                              <CustomMultiSelect
                                className="input"
                                name="timezone"
                                onChange={handleChangeTImezone}
                                placeholder="Select Timezone"
                                value={formData?.timezone}
                                options={timezone}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <div className="flex-wrap gap-2 text-end mb-3">
                      <button
                        className="btn btn-primary w-sm waves-effect waves-light"
                        type="button"
                        // disabled={isSubmitted}
                        onClick={() => submitForm()}
                      >
                        {username ? "Update" : "Submit"}
                      </button>
                      &nbsp; &nbsp; &nbsp;
                      <Button
                        type="button"
                        color="secondary"
                        className="btn-inactive btn"
                        disabled={isSubmitted}
                        onClick={() => {
                          cancelForm()
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default MultiRoleUser
