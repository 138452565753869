import { FocusError } from "focus-formik-error"
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { UPDATE_COUNSELORPAY } from "./gql/Gql"
import { useMutation } from "@apollo/client"
import { useFormik } from "formik"
import * as Yup from "yup"
import style from "./style.module.scss"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const CounsellorPay = ({ initialCpayValues, id, getCounselorDetail, userData }) => {
  const [isSubmittedCpay, setIsSubmittedCpay] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  let history = useHistory()
  const [submitMutationCpay, submitResponseCpay] =
    useMutation(UPDATE_COUNSELORPAY)

  const handleSubmitCpay = async values => {
    setInProgress(true)
    try {
      const calendarInput = {
        id: parseInt(id),
        service_fee: parseInt(values.service_fee),
        share_percentage: parseInt(values.share_percentage),
        gst_enabled: values.gst_enabled,
        external_amount: parseInt(values.external_amount),
      }
      await submitMutationCpay({
        variables: {
          updateCalendarInput: calendarInput,
        },
      }).then(res => {
        toast.success("Counsellor pay updated successfully")
        getCounselorDetail({
          variables: {
            argumentsInput: {
              id: parseInt(id),
            },
          },
        })
        setInProgress(false)
        setIsSubmittedCpay(false)

      })
    } catch (e) {
        setInProgress(false)
      setIsSubmittedCpay(false)
    }
  }

  const validationCpay = useFormik({
    enableReinitialize: true,
    initialValues: initialCpayValues,
    validationSchema: Yup.object({
      gst_enabled: Yup.boolean(),
      service_fee: Yup.number("Service fee should be number only")
        .min(0, "Service fee must be at least 0")
        .max(5, "Service fee must be less than or equal to 5"),
      share_percentage: Yup.number("Share percentage should be number only")
        .min(0, "Share percentage must be at least 0")
        .max(100, "Share percentage must be less than or equal to 100"),
      external_amount: Yup.number("External amount should be number only")
        .min(0, "External amount must be at least 0")
        .max(1000, "Share percentage must be less than or equal to 1000"),
    }),
    onSubmit: values => {
      handleSubmitCpay(values)
    },
  })

  useEffect(() => {
    const { external_amount, service_fee, share_percentage } =
      validationCpay.values

    if (
      external_amount !== "" &&
      service_fee !== "" &&
      share_percentage !== ""
    ) {
      setIsSubmittedCpay(false)
    } else {
      setIsSubmittedCpay(true)
    }
  }, [validationCpay.values])

  return (
    <>
      <Row className={`${style.cardcenter} counselor-pay-section pt-1`}>
        <Col md={12}>
          <Card className="light-green-bg-card-white m-0 px-3 border-light-shadow">
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={e => {
                  e.preventDefault()
                  validationCpay.handleSubmit()
                  return false
                }}
              >
                <FocusError formik={validationCpay} />
      
                    {userData.role.id === 1 && (
                      <>
                        <h5 className="font-weight-bold  mb-4 pt-3">
                          Counsellor Pay
                        </h5>
                        <Row className="form-custom-white-inputs ">
                          <Col md="6">
                            <div className="mb-2">
                              <Label className="form-label">
                                Counsellor Share (In %)
                              </Label>
                              <Input
                                className="form-control"
                                type="number"
                                name="share_percentage"
                                value={
                                  validationCpay.values &&
                                  validationCpay.values.share_percentage
                                }
                                onChange={validationCpay.handleChange}
                                onBlur={validationCpay.handleBlur}
                                invalid={
                                  validationCpay.touched.share_percentage &&
                                  validationCpay.errors.share_percentage
                                    ? true
                                    : false
                                }
                              />
                              <small className="light-gray">
                                Percentage of counsellor share
                              </small>
                              {validationCpay.touched.share_percentage &&
                              validationCpay.errors.share_percentage ? (
                                <FormFeedback type="invalid">
                                  {validationCpay.errors.share_percentage}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-2">
                              <Label className="form-label">
                                External (In $)
                              </Label>
                              <Input
                                className="form-control"
                                type="number"
                                name="external_amount"
                                onChange={validationCpay.handleChange}
                                onBlur={validationCpay.handleBlur}
                                invalid={
                                  validationCpay?.touched?.external_amount &&
                                  validationCpay?.errors?.external_amount
                                    ? true
                                    : false
                                }
                                value={validationCpay?.values?.external_amount}
                              />
                              <small className="light-gray">
                                Additional pay when counsellor work from his own
                                workspace
                              </small>
                              {validationCpay?.touched?.external_amount &&
                              validationCpay.errors.external_amount ? (
                                <FormFeedback type="invalid">
                                  {validationCpay?.errors.external_amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-2">
                              <Label className="form-label">
                                Service fee (In %)
                              </Label>
                              <Input
                                className="form-control"
                                type="number"
                                name="service_fee"
                                onChange={validationCpay.handleChange}
                                onBlur={validationCpay.handleBlur}
                                value={validationCpay?.values?.service_fee}
                                invalid={
                                  validationCpay?.touched?.service_fee &&
                                  validationCpay?.errors?.service_fee
                                    ? true
                                    : false
                                }
                              />
                              {validationCpay?.touched?.service_fee &&
                              validationCpay?.errors?.service_fee ? (
                                <FormFeedback type="invalid">
                                  {validationCpay?.errors?.service_fee}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="mb-2">
                              <Label className="form-label">GST</Label>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch2"
                                  name="gst_enabled"
                                  onChange={e => {
                                    validationCpay.setFieldValue(
                                      "gst_enabled",
                                      !validationCpay?.values?.gst_enabled
                                    )
                                  }}
                                  onClick={e => {
                                    validationCpay.setFieldValue(
                                      "gst_enabled",
                                      !validationCpay?.values?.gst_enabled
                                    )
                                  }}
                                  value={validationCpay?.values?.gst_enabled}
                                  checked={validationCpay?.values?.gst_enabled}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    <div className="text-start mt-4">
                      {!inProgress ? (
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                          disabled={isSubmittedCpay}
                        >
                          {"Update"}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          disabled
                        >
                          <Spinner
                            type="grow"
                            className="me-1 align-middle spinner-grow-sm "
                            color="light"
                          />
                          Processing...
                        </button>
                      )}
                      <button
                  className="waves-effect waves-light btn btn-light  ms-2"
                  onClick={()=> history.goBack()}
                  type="button"
                >
                  Cancel
                </button>
                    </div>
          
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CounsellorPay
