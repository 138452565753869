import React from "react"
import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { CREATE_ROOM } from "./gql/Gql"
import { useMutation } from "@apollo/client"
import {  useSelector } from "react-redux"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const AddRoom = () => {
  let history = useHistory()
  let { locationId } = useParams()
  const decodedId = locationId && atob(locationId);
  locationId = decodedId
  const [roomName, setRoomName] = useState("")
  const [isSubmit, setIsSubmit] = useState(false)
  const locationName  = useSelector(state => state.calendar.location)
  const location = useLocation()
  const locationValue = new URLSearchParams(location.search).get("location")

  const [initAvailability, setInitAvailability] = useState([
    {
      day: "Monday",
      availabilities: [
        {
          week_day: "Monday",
          start_time: "09:00:00",
          end_time: "17:00:00",
          is_closed: false,
        },
      ],
    },
    {
      day: "Tuesday",
      availabilities: [
        {
          week_day: "Tuesday",
          start_time: "09:00:00",
          end_time: "17:00:00",
          is_closed: false,
        },
      ],
    },
    {
      day: "Wednesday",
      availabilities: [
        {
          week_day: "Wednesday",
          start_time: "09:00:00",
          end_time: "17:00:00",
          is_closed: false,
        },
      ],
    },
    {
      day: "Thursday",
      availabilities: [
        {
          week_day: "Thursday",
          start_time: "09:00:00",
          end_time: "17:00:00",
          is_closed: false,
        },
      ],
    },
    {
      day: "Friday",
      availabilities: [
        {
          week_day: "Friday",
          start_time: "09:00:00",
          end_time: "17:00:00",
          is_closed: false,
        },
      ],
    },
    {
      day: "Saturday",
      availabilities: [
        {
          week_day: "Saturday",
          start_time: "09:00:00",
          end_time: "17:00:00",
          is_closed: false,
        },
      ],
    },
    {
      day: "Sunday",
      availabilities: [
        {
          week_day: "Sunday",
          start_time: "09:00:00",
          end_time: "17:00:00",
          is_closed: false,
        },
      ],
    },
  ])
  const [multipleAvailData, setMultipleAvailData] = useState([])
  const [isMultipleHoursOpen, setIsMultipleHoursOpen] = useState(false)

  const [createRoomMutation, createRoomMutationRes] = useMutation(CREATE_ROOM)

  const createRoomFun = () => {
    // setIsSubmit(true)

    const availabilityData = initAvailability.flatMap(dayData => {
      return dayData.availabilities.map(availability => {
        return {
          start_time: availability.start_time,
          week_day: availability.week_day,
          is_closed: availability.is_closed,
          end_time: availability.end_time,
        }
      })
    })
    let createRoomObj = {
      location_id: parseInt(locationId),
      name: roomName,
      availability: availabilityData,
    }
    if (roomName && availabilityData) {
      setIsSubmit(true)
      createRoomMutation({
        variables: {
          createRoomInput: createRoomObj,
        },
      })
        .then(res => {
          toast.success(res.data.CreateRoom.message)
          setTimeout(() => {
            setIsSubmit(false)
          }, 1500)
        })
        .catch(err => {
          toast.error("something went wrong !")
          setIsSubmit(false)
        })
    } else {
      toast.error("Enter room details properly")
      setIsSubmit(false)
    }
  }

  const handleToggleDynamic = (e, item, index) => {
    const updatedAvailabilities = [...initAvailability]
    updatedAvailabilities[index].availabilities.map(elem => {
      elem.is_closed = e.target.checked
    })
    setInitAvailability(updatedAvailabilities)
  }

  const handleMultipleHoursModal = availData => {
    if (availData) {
      setMultipleAvailData(availData)
    }
    setIsMultipleHoursOpen(!isMultipleHoursOpen)
  }
  const handleMultiTimeSlot = (e, index, time) => {
    const newArray = [...multipleAvailData]

    newArray[index][time] = e.target.value + ":00"

    setMultipleAvailData(newArray)
  }

  const removeAvailability = index => {
    const updatedAvailabilities = multipleAvailData.filter(
      (availability, ind) => ind !== index
    )
    setMultipleAvailData(updatedAvailabilities)
  }

  const addAvailability = () => {
    if (multipleAvailData.length < 5) {
      setMultipleAvailData([
        ...multipleAvailData,
        {
          start_time: "",
          end_time: "",
          week_day: multipleAvailData[0].week_day,
        },
      ])
    }
  }

  const saveMultipleHours = () => {
    const availabilityInputArr = multipleAvailData.map(item => ({
      start_time: item.start_time,
      end_time: item.end_time,
      is_closed: false,
      week_day: item.week_day,
    }))

    const isNotEmpty = availabilityInputArr.every(availability => {
      return (
        availability.start_time.trim() !== "" &&
        availability.end_time.trim() !== ""
      )
    })

    if (isNotEmpty && availabilityInputArr.length >= 0) {
      function validateAvailability(availabilities) {
        const sortedAvailabilities = availabilities.slice().sort((a, b) => {
          return a.start_time.localeCompare(b.start_time)
        })

        for (let i = 0; i < sortedAvailabilities.length; i++) {
          const prevAvailability = sortedAvailabilities[i - 1]
          const currentAvailability = sortedAvailabilities[i]
          let prevEndTime = ""
          if (prevAvailability) {
            prevEndTime = new Date(`1970-01-01T${prevAvailability.end_time}`)
          }

          const currentStartTime = new Date(
            `1970-01-01T${currentAvailability.start_time}`
          )

          const currentEndTime = new Date(
            `1970-01-01T${currentAvailability.end_time}`
          )
          if (currentStartTime >= currentEndTime) {
            return false
          }

          if (prevEndTime) {
            if (prevEndTime >= currentStartTime) {
              return false
            }
          }
        }

        return true
      }
      let isValid = ""
      if (availabilityInputArr.length > 0) {
        isValid = validateAvailability(availabilityInputArr)
      } else {
        isValid = true
      }

      if (isValid) {
        function updateInitialValue(initialValue, modifiedValue) {
          const updatedValue = [...initialValue]
          let modifiedAvail = []
          for (const modifiedItem of modifiedValue) {
            const dayIndex = updatedValue.findIndex(
              item => item.day === modifiedItem.week_day
            )

            if (dayIndex !== -1) {
              modifiedAvail.push({
                week_day: modifiedItem.week_day,
                start_time: modifiedItem.start_time,
                end_time: modifiedItem.end_time,
                is_closed: false,
              })
            }
          }
          const weekDay = updatedValue.findIndex(
            item => item.day === modifiedValue[0].week_day
          )
          updatedValue[weekDay].availabilities = modifiedAvail
          return updatedValue
        }

        const updatedInitialValue = updateInitialValue(
          initAvailability,
          availabilityInputArr
        )
        setInitAvailability(updatedInitialValue)
        handleMultipleHoursModal()
      } else {
        toast.error("Time slots are overriding each other")
      }
    } else {
      toast.error("Select proper time slots")
    }
  }

  const handleChange = (e, index, field) => {
    const newArray = [...initAvailability]
    newArray[index].availabilities[0][field] = e.target.value + ":00"
    setInitAvailability(newArray)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <Col xs={12} md={12}>
            <div className="flex align-item-center">
              <i
                className="waves-effect color-dark-green  mdi mdi-arrow-left font-size-22 mr-2"
                id="backArrow"
                onClick={() => history.goBack()}
              />
              <h4 className="mt-1 mx-2 card-title-main"> Create New Room</h4>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="d-flex justify-content-center ">
        <Col xs={12} md={12}>
          <Card className="transparent-bg-card ">
            <CardBody>
              <Row className="form-custom-white-inputs d-flex justify-content-center">
                <Col
                  xl={9}
                  md={9}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Label className="form-check-label mx-5 px-1">
                    Location :{" "}
                  </Label>
                  <Input
                    name="name"
                    className="form-control w-50 mx-5 "
                    placeholder="Enter Location Name"
                    type="text"
                    disabled={true}
                    value={locationName || locationValue}
                    autoComplete="off"
                  />
                </Col>
                <Col
                  xl={9}
                  md={9}
                  className="d-flex justify-content-start align-items-center mt-3"
                >
                  <Label className="form-check-label mx-5 mt px-1">
                    Room Name :{" "}
                  </Label>
                  <Input
                    name="name"
                    className="form-control w-50 mx-4 "
                    placeholder="Enter Room Name"
                    type="text"
                    onChange={e => setRoomName(e.target.value)}
                    value={roomName}
                    autoComplete="off"
                  />
                </Col>
                <Col xl={8} md={8} className="mt-5">
                  {initAvailability?.map((item, index) => {
                    return (
                      <>
                        <div className="mb-4 row" key={index}>
                          <Col md="3" className="">
                            <div className="form-check form-switch ">
                              <Input
                                type="checkbox"
                                className="form-check-input default-search-input"
                                id={`customSwitch-${index}`}
                                name={`initAvailability[${index}].is_closed`}
                                checked={!item?.availabilities[0].is_closed}
                                onChange={e =>
                                  handleToggleDynamic(
                                    e,
                                    item?.availabilities,
                                    index
                                  )
                                }
                                onClick={e =>
                                  handleToggleDynamic(
                                    e,
                                    item?.availabilities,
                                    index
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`customSwitch-${index}`}
                              >
                                {item?.availabilities[0].week_day}
                              </label>
                            </div>
                          </Col>
                          {item?.availabilities[0]?.is_closed ? (
                            <Col md="8" className="mb-2 menu-item-16px">
                              Closed
                            </Col>
                          ) : (
                            <>
                              <Col md="3" className="custom-date-picker-style">
                                <Input
                                  id="name"
                                  className="form-control default-search-input"
                                  name={`availabilities[${index}].start_time`}
                                  placeholder="From"
                                  type="time"
                                  onChange={e =>
                                    handleChange(e, index, "start_time")
                                  }
                                  value={
                                    item?.availabilities[0].start_time || ""
                                  }
                                  disabled={item?.availabilities[0]?.is_closed}
                                />
                                {item?.availabilities.length > 1 && (
                                  <h6
                                    className="mt-2 seeMoreTimeSlots"
                                    onClick={() => {
                                      handleMultipleHoursModal(
                                        item?.availabilities
                                      )
                                    }}
                                  >
                                    +{item?.availabilities.length - 1} more
                                  </h6>
                                )}
                              </Col>
                              <Col md="3" className="custom-date-picker-style">
                                <Input
                                  id="end_time"
                                  name={`availabilities[${index}].end_time`}
                                  className="form-control default-search-input"
                                  placeholder="To"
                                  type="time"
                                  onChange={e =>
                                    handleChange(e, index, "end_time")
                                  }
                                  value={item?.availabilities[0].end_time || ""}
                                  disabled={item?.availabilities[0]?.is_closed}
                                />
                              </Col>
                              <Col md="2">
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleMultipleHoursModal(
                                      item?.availabilities
                                    )
                                  }}
                                  className="h-40 btn btn-primary waves-effect waves-light d-flex align-items-center justify-content-center"
                                >
                                  <i
                                    className="waves-effect mdi mdi-pencil font-size-20 "
                                    id="backArrow"
                                  />
                                </button>
                              </Col>
                            </>
                          )}
                        </div>
                      </>
                    )
                  })}
                </Col>
              </Row>
              <Row className="mt-5 mx-3">
                <Col xs={9} className="text-end">
                  <button
                    className="btn btn-primary "
                    type="button"
                    onClick={createRoomFun}
                    disabled={isSubmit}
                  >
                    Create Room
                  </button>
                  <button
                    className="btn btn-inactive mx-2"
                    type="button"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
              <Modal isOpen={isMultipleHoursOpen}>
                <ModalBody>
                  <div className="w-100 text-end">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => handleMultipleHoursModal()}
                    ></button>
                  </div>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={12} className="mt-2 mb-2 relative">
                        <p className="text-center card-title-main">
                          Select {multipleAvailData[0]?.week_day} timeslots
                        </p>
                        <>
                          {multipleAvailData.map((item, index) => (
                            <div key={index}>
                              <Row className="mt-4 align-items-end">
                                <Col
                                  md="5"
                                  className="custom-date-picker-style"
                                >
                                  <Label>Start Time</Label>
                                  <Input
                                    className="form-control default-search-input"
                                    name={`availabilities[${index}].start_time`}
                                    placeholder="From"
                                    type="time"
                                    onChange={e =>
                                      handleMultiTimeSlot(
                                        e,
                                        index,
                                        "start_time"
                                      )
                                    }
                                    value={item.start_time}
                                  />
                                </Col>
                                <Col
                                  md="5"
                                  className="custom-date-picker-style"
                                >
                                  <Label>End Time</Label>
                                  <Input
                                    className="form-control default-search-input"
                                    name={`availabilities[${index}].end_time`}
                                    placeholder="To"
                                    type="time"
                                    onChange={e =>
                                      handleMultiTimeSlot(e, index, "end_time")
                                    }
                                    value={item.end_time}
                                  />
                                </Col>
                                {multipleAvailData.length > 1 && (
                                  <Col
                                    md="2"
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <button
                                      type="button"
                                      onClick={() => removeAvailability(index)}
                                      className="h-40 btn d-flex align-items-center waves-effect waves-light"
                                    >
                                      <i className="waves-effect mdi mdi-close font-size-20" />
                                    </button>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          ))}
                        </>
                        {multipleAvailData.length < 5 && (
                          <Row className="mt-2">
                            <Col md="5" className="custom-date-picker-style">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={addAvailability}
                              >
                                Add more hours
                              </button>
                            </Col>
                          </Row>
                        )}
                      </Col>

                      <Col md={12} className="mt-4 align-items-center">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => saveMultipleHours()}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn  margin-left-10"
                          onClick={() => handleMultipleHoursModal()}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AddRoom
