import React from "react"
import { Row } from "reactstrap"
import UserList from "../User/UserList"
import { hasPermission } from "../../functions/functions"
import PageNotFound from "../404"
const ClientList = () => {
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          {hasPermission() ? (
            <div className="container-fluid">
              <div className="p-2">
                <h4 className="ps-2 card-title-main">My Clients</h4>
                <h5 className={`ps-2  card-para-main`}>
                  List of all the clients assigned to counsellor with their
                  details.
                </h5>
              </div>

              <Row className="">
                <UserList isClientList={true} />
              </Row>
            </div>
          ) : (
            <>
              <PageNotFound />
            </>
          )}
        </div>
      </React.Fragment>
    </>
  )
}

export default ClientList
