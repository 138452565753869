import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  BreadcrumbItem,
} from "reactstrap"
import { useHistory, useParams, Link } from "react-router-dom"
import { GET_CLIENTS_DATA, GET_COUNSELOR } from "./gql/Gql"
import ClientList from "./components/ClientList"
import AppointmentTypes from "./components/AppointmentTypes"
import { Chip } from "@material-ui/core"
import { useLazyQuery } from "@apollo/client"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import Loader from "../../components/Common/Loader"
import TabContent from "../../components/Common/TabBar/TabContent"
import TabNavItem from "../../components/Common/TabBar/TabNavItem"
import classnames from "classnames"
import { makeStyles } from "@material-ui/core"
import { formatPhoneNumber } from "../../functions/functions"

const useStyles = makeStyles(theme => ({
  tabBar: {
    borderBottom: "1px solid #DCDDDF",
    "& li": {
      padding: "5px 15px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: 600,
    },
    "& .active": {
      borderBottom: "2px solid #153c27",
    },
  },
}))

const counselorDetails = () => {
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = decodedId
  let history = useHistory()
  const classes = useStyles()
  const [counselorDetails, setcounselorDetails] = useState({})
  const [counsellingType, setCounsellingType] = useState([])
  const [therapies, setTherapies] = useState([])
  const [phoneNumberList, setPhoneNumberList] = useState([])
  const [isSpinner, setIsSpinner] = useState(false)
  const [activeTab, setActiveTab] = useState("clients")
  const [appointmentList, setAppointmentList] = useState([])
  const [appointmentTypeList, setAppointmentTypeList] = useState([])

  const [getcounselorDetails, counselorResponse] = useLazyQuery(GET_COUNSELOR, {
    fetchPolicy: "no-cache",
  })
  const [getClientData, ClientData] = useLazyQuery(GET_CLIENTS_DATA, {
    fetchPolicy: "no-cache",
  })

  const handleEditClick = () => {
    const encodedId = btoa(id)
    history.push(`/counsellors/${encodedId}/edit`)
  }

  React.useEffect(() => {
    if (id) {
      getcounselorDetails({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
      getClientData({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [])
  useEffect(() => {
    if (
      ClientData.data &&
      ClientData.data.getClients &&
      ClientData.data.getClients.data
    ) {
      setAppointmentList(ClientData.data.getClients.data.clientResponse)
    }
  }, [ClientData.data])

  React.useEffect(() => {
    if (
      id &&
      counselorResponse.data &&
      counselorResponse.data.getCalendar &&
      counselorResponse.data.getCalendar.status &&
      counselorResponse.data.getCalendar.code === 200
    ) {
      const isPhoneNumberData =
        counselorResponse.data.getCalendar.data.calendar?.user?.phones ===
        undefined
      setIsSpinner(false)
      setcounselorDetails(counselorResponse.data.getCalendar.data.calendar)
      setCounsellingType(
        counselorResponse.data.getCalendar.data.calendar.counselling_type
      )
      setTherapies(counselorResponse.data.getCalendar.data.calendar.therapies)
      setPhoneNumberList(
        !isPhoneNumberData
          ? counselorResponse.data.getCalendar.data.calendar?.user?.phones
          : []
      )
      setAppointmentTypeList(
        counselorResponse.data.getCalendar.data.calendar.appointment_types
      )
    } else {
      setIsSpinner(true)
      setcounselorDetails({})
      setCounsellingType([])
      setTherapies([])
      setPhoneNumberList([])
      setAppointmentTypeList([])
      setAppointmentList([])
    }
  }, [counselorResponse.data])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={false}>
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.push("/counsellors")}
                  />
                  <h4 className={`${style.headingsubtitle}  card-title-main`}>
                    Counsellor Details
                  </h4>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/counsellors">Counsellor</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">Counsellor Details</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {isSpinner ? (
            <Row>
              <Col className="col-12 user-role-create-height">
                <div className="spinner">
                  <Loader />
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xl={12}>
                <Card className="light-green-bg-card">
                  <CardBody>
                    {counselorDetails.name && (
                      <div className="d-flex gap-5 align-items-center">
                        <CardTitle className="h4 ">
                          <h4 className="card-title-main">
                            {counselorDetails.name}
                          </h4>
                        </CardTitle>
                        <div className="text-end flex-fill">
                          <a
                            onClick={() => handleEditClick()}
                            className="btn btn-primary"
                          >
                            <i className="uil uil-pen me-2"></i> Edit
                          </a>
                        </div>
                      </div>
                    )}
                    {counselorDetails.email && (
                      <div className="d-flex gap-2 align-items-center">
                        <i className="mdi mdi-email font-size-20  m-0 mt-2 color-dark-green"></i>
                        <p className="card-para-main m-0 mt-2 card-para-main">
                          {counselorDetails.email}
                        </p>
                      </div>
                    )}

                    {counselorDetails.user &&
                      counselorDetails?.user?.mobile_number && (
                        <div className="d-flex gap-2 align-items-center">
                          <i className="mdi mdi-phone font-size-18 m-0 mt-1 color-dark-green"></i>
                          <p className="card-para-main m-0 mt-2 card-para-main">
                            {formatPhoneNumber(
                              counselorDetails?.user?.mobile_number
                            )}
                            {phoneNumberList.length > 0 && (
                              <>
                                {", "}
                                {phoneNumberList
                                  .map(item =>
                                    formatPhoneNumber(item.mobile_number)
                                  )
                                  .join(", ")}
                              </>
                            )}
                          </p>
                        </div>
                      )}

                    {counsellingType.length > 0 && (
                      <>
                        <hr />
                        <h4 className="card-title mb-3">Counselling Type</h4>
                        {counsellingType &&
                          counsellingType.map((n, index) => (
                            <Chip
                              key={index}
                              label={n.name}
                              variant="outlined"
                              className="chip-style-margin"
                            />
                          ))}
                      </>
                    )}

                    {therapies.length > 0 && (
                      <>
                        <hr />
                        <h4 className="card-title mb-3">Therapies</h4>
                        {therapies &&
                          therapies.map((n, index) => (
                            <Chip
                              key={index}
                              label={n.name}
                              variant="outlined"
                              className="chip-style-margin"
                            />
                          ))}
                      </>
                    )}

                    {/* ################################################   APPOINTMENTS TYPES    ############################################################## */}

                    <Row className="mt-4">
                      <div className="Tabs">
                        <ul
                          className={classnames(
                            "nav d-flex gap-4 tab-items-counsellor-details",
                            classes.tabBar
                          )}
                        >
                          <TabNavItem
                            title="Client Details"
                            id="clients"
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                          />
                          <TabNavItem
                            title="Appointments Types"
                            id="appointments"
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                          />
                        </ul>
                        <div className="outlet ">
                          <TabContent id="clients" activeTab={activeTab}>
                            <ClientList appointments={appointmentList} />
                          </TabContent>
                          <TabContent id="appointments" activeTab={activeTab}>
                            <AppointmentTypes
                              appointmentTypes={appointmentTypeList}
                            />
                          </TabContent>
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default counselorDetails
