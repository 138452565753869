import React, { useState, useEffect, useCallback } from "react"
import { Card, CardBody } from "reactstrap"
import useStyles from "../styles"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import Table from "../../../components/Common/Table"
import classNames from "classnames"
import { GET_APPOINTMENTS } from "./gql/gql"
import { useLazyQuery } from "@apollo/client"
import {
  capitalizeFirstLetter,
  convertUTCToLocal,
  formatCurrency,
  formatPhoneNumber,
  meetingType,
} from "../../../functions/functions"
import moment from "moment"
import style from "./style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"

function PartiallyPaid({ handlePartiallyPaidCount, setIsLoader }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  //************************** Appointment Data ***************************** */

  const [getAppointmentList, listResponse] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: "no-cache",
  })

  function getAppointmentListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder
  ) {
    getAppointmentList({
      variables: {
        argumentsInput: {
          partially_paid: true,
          device_type:"web",
        },
      },
    })
  }

  useEffect(() => {
    getAppointmentListFun()
  }, [])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointments &&
      listResponse.data.getAppointments.code === 200 &&
      listResponse.data.getAppointments.status
    ) {
      setTimeout(() => {
        setLoading(false)
        setIsLoader(false)
      }, 1000)
      if (listResponse.data.getAppointments.data) {
        const appointments = listResponse.data.getAppointments.data.appointments_arr?.map((elem)=>{
          return elem?.appointment
        })
        setTotalCount(listResponse.data.getAppointments.data.totalCount)
        setAppointments(appointments)
      }
    } else {
      setLoading(true)
      setAppointments([])
    }
  }, [listResponse.data])

  const refreshAPI = () => {
    setLoading(true)
    setIsLoader(true)
    getAppointmentListFun()
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  }, [loading])

  useEffect(() => {
    handlePartiallyPaidCount(totalCount)
  }, [totalCount])

  const columns = [
    {
      text: "Client Name",
      dataField: "first_name",
      formatter: NameFun,
      style: {
        minWidth: "150px",
      },
    },
    {
      text: "Meeting Type",
      dataField: "type_of_appointment",
      align: "center",
      headerAlign: "center",
      style: {
        minWidth: 150,
      },
      formatter: meetingTypeFun,
    },
    {
      text: "Time",
      dataField: "time",
      align: "center",
      headerAlign: "center",
      style: {
        minWidth: 150,
      },
      formatter: (cell, row) => {
        const startTime = row?.utc_datetime
          ? convertUTCToLocal(row?.utc_datetime)
          : null
        return moment(startTime).format("hh:mm A")
      },
    },
    {
      text: "Date",
      dataField: "date",
      align: "center",
      headerAlign: "center",
      style: {
        minWidth: 150,
      },
      formatter: (cell, row) => {
        const startDate = row?.utc_datetime
          ? convertUTCToLocal(row?.utc_datetime)
          : null
        return moment(startDate).format("DD-MMM-yyyy")
      },
    },
    {
      text: "Paid",
      dataField: "amount_paid",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        return "$" + formatCurrency(cell)
      },
    },
    {
      text: "Price",
      dataField: "price",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        return "$" + formatCurrency(cell)
      },
    },
  ]

  function NameFun(rowValue, row) {
    return (
      <div className="d-flex gap-2">
        <div className="d-flex flex-column">
          <span
            className={classNames(
              "text-capitalize",
              classes.boldFont,
              classes.normalFontsize
            )}
          >
            {row?.first_name} {row?.last_name}
          </span>
          <div>{formatPhoneNumber(row?.phone)}</div>
        </div>
      </div>
    )
  }

  function meetingTypeFun(value, row) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {value.call_type ? meetingType(value.call_type) : ""}
      </div>
    )
  }

  return (
    <React.Fragment>
      <Card body className="light-green-bg-card-table">
        <CardBody className="p-0">
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <h4
              title="This shows the list of non-direct client who paid partially"
              className={classNames("card-title", classes.cardTitle)}
            >
              Partially Paid
              <Tooltip
                className={style.tooltip}
                placement="right"
                title={
                  <div className="invoice-list-fsize-14">
                    This shows the list of non-direct client who paid partially.
                  </div>
                }
                arrow
              >
                <i
                  className={`uil ul-bg-dark uil-info-circle ${style.circleinfo}`}
                ></i>
              </Tooltip>
            </h4>
            <img
              src={refresh}
              width="24px"
              height="24px"
              className={classNames(
                classes.refreshIcon,
                loading && classes.refresh
              )}
              onClick={refreshAPI}
            />
          </div>

          <div
            className={classNames(
              "table-responsive table-custom-redesign",
              classes.appointmentTable,
              classes.scrollbarTable
            )}
          >
            <Table
              data={appointments}
              isPagination={false}
              columns={columns}
              noDataIndication={"No Data Found"}
              isBordereded={false}
              isStriped={false}
              isHover={false}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default PartiallyPaid
