import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import { useHistory } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  DELETE_STRIPE_ACCOUNT,
  LINKED_ACCOUNT_STATUS,
  LINK_ACCOUNT,
} from "./gql/dashboardAdminGql"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loader from "../../../components/Common/Loader"

const LinkAccount = () => {
  let history = useHistory()
  const [accountStatus, setAccountStatus] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [isRemoveDown, setIsRemoveDown] = useState(false)
  const [accountStatusData, setAccountStatusData] = useState()
  const [accountDetailsStatus, setAccountDetailsStatus] = useState()
  const [linkBankAccountMutation, linkBankAccountMutationRes] =
    useMutation(LINK_ACCOUNT)
  const [deleteStripeAccountMutation, deleteStripeAccountMutationRes] =
    useMutation(DELETE_STRIPE_ACCOUNT)
  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    let encodedAccountData = localStorage.getItem("accountData")
    const accountStat = encodedAccountData
      ? JSON.parse(atob(encodedAccountData))
      : null
    if (accountStat) {
      setIsLoader(true)
      getAccountStatus({
        variables: {
          accountId: accountStat?.external_account_id,
        },
      })
    }
    setAccountStatus(accountStat)
  }, [])

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [accountStatus])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountStatusData(allData)
      setIsLoader(false)
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])

  const userData = useSelector(state => state.Login?.user?.user)

  const linkAnAccount = () => {
    let orgData = JSON.parse(localStorage.getItem("orgData"))

    const adminDetails = {
      email: userData?.email,
      organizationIdentifier:
        orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,
    }
    setIsLoading(true)
    linkBankAccountMutation({
      variables: adminDetails,
    })
  }

  useEffect(() => {
    if (linkBankAccountMutationRes?.data?.createStripeAccount?.data) {
      if (
        linkBankAccountMutationRes?.data?.createStripeAccount?.data
          ?.organizationAccount
      ) {
        let stripLink =
          linkBankAccountMutationRes?.data?.createStripeAccount?.data
            ?.organizationAccount?.external_link
        window.location.href = stripLink
      } else {
        toast.error("Oops! Something went wrong")
        setIsLoading(false)
      }
    }
  }, [linkBankAccountMutationRes?.data])

  const deleteStripeAcc = () => {
    if (accountStatus) {
      setIsRemoveDown(true)
      deleteStripeAccountMutation({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }

  useEffect(() => {
    if (deleteStripeAccountMutationRes?.data?.deleteStripeAccount?.status) {
      toast.success(
        deleteStripeAccountMutationRes?.data?.deleteStripeAccount?.message
      )
      setIsRemoveDown(false)
      localStorage.removeItem("accountData")
      setAccountStatus(null)
    } else {
      toast.error(
        deleteStripeAccountMutationRes?.data?.deleteStripeAccount?.message
      )
      setIsRemoveDown(false)
    }
  }, [deleteStripeAccountMutationRes.data])

  return (
    <React.Fragment>
      <div className="page-content">
        <Row className="container-fluid">
          <div className="d-flex align-items-center">
            <i
              className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
              id="backArrow"
              onClick={() => history.push("/dashboard")}
            />
            <h4 className="card-title-main mt-2 mx-2">Account Details</h4>
          </div>
          <Col md={6} className="bg-white linkSection m-0 p-3 mt-5 d-flex justify-content-center align-items-center flex-column">
            {isLoader ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="accHeader w-100">
                  <p className="h5 py-2 font-weight-bold px-2 border-dark border-bottom text-center">
                    {accountStatus ? (
                      <span>
                        Payment Account{" "}
                        {!accountDetailsStatus ? (
                          <i className="fas fa-exclamation-circle text-danger cursor-pointer mx-2"></i>
                        ) : (
                          <></>
                        )}
                      </span>
                    ) : (
                      "Link Payment Account"
                    )}
                  </p>
                </div>
                {accountStatus ? (
                  <>
                    {!accountDetailsStatus ? (
                      <div className="acoountWaring p-2 mx-2">
                        <p className="h6 p-0 m-0 d-flex">
                          <i className="fas fa-exclamation-circle text-danger cursor-pointer mt-2 mx-2"></i>
                          <span>
                            You did not enter payment details properly, so need
                            to remove this payment account & add details again.
                          </span>
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="linkedAccBody mx-2 my-3">
                      <img src="/stripe.png" />
                    </div>
                    {!accountDetailsStatus ? (
                      <div className="mx-2">
                        <Button
                          className="btn-danger"
                          onClick={deleteStripeAcc}
                          disabled={isRemoveDown}
                        >
                          {isRemoveDown ? "Removing.." : "Remove"}
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <div
                    className="nonLinkedAccBody text-center mx-2 my-3 cursor-pointer"
                    onClick={linkAnAccount}
                  >
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="rounded-icon">
                          <i className="uil uil-plus"></i>
                        </div>
                        <p className="h6 mt-2">Link a new bank account</p>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default LinkAccount
