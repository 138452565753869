import React from "react"
class TableInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue,
      ...props,
    }
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onhandleCancle = this.onhandleCancle.bind(this)
    this.onhandleSuccess = this.onhandleSuccess.bind(this)
  }

  focus() {
    console.log("on focus")
    // this.refs.inputRef.focus();
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    })
  }
  onhandleCancle(value, id, row){
    props.onhandleCancle(value, id, row)
  }
  onhandleSuccess(e, value, row, index){
    props.onhandleSuccess(e, value, row, index)
  }

  onBlur(e) {
    // this.props.onUpdate(this.state.value);
  }

  getValue() {
    return this.state.value
  }

  componentDidMount() {
    this.range.focus() // <<<<<<<<< here
  }

  render() {
    const { onUpdate, ...rest } = this.props
    let { value, row, index } = this.state

    return [
      <div key="table-inp" className="d-flex gap-2 p-1 h-40">
        <input
          ref={node => (this.range = node)}
          type="text"
          name="price"
          // ref="inputRef"
          className="form-control custom-textbox"
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          {...rest}
        />

        <button
          key="submit"
          type="button"
          className="btn btn-success btn-sm d-flex align-items-center justify-content-center"
          onClick={e => this.state.onhandleSuccess(e, value, row, index)}
        >
          <i className="bx bx-check font-size-18 text-white"></i>
        </button>

        <button
          key="cancel"
          className="btn btn-dark btn-sm d-flex align-items-center justify-content-center fx-10"
          onClick={() => this.state.onhandleCancle(row.price, row.id, row)}
        >
          <i className="bx bx-x font-size-18 text-white"></i>
        </button>
      </div>,
    ]
  }
}

export default TableInput
