import React, { useCallback, useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import useStyles from "../Dashboard/styles"
import style from "./style.module.scss"
import { toast } from "react-toastify"
import { debounce } from "lodash"
// import { fetchApi } from "../../common/api"
import { useHistory, useLocation } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_BLOGS, DEL_BLOGS, GET_COUNSELORS, SUBMIT_BLOG } from "./gql/Gql"
import { formatToCommonDate, hasPermission } from "../../functions/functions"
import DeleteModal from "../../components/Common/DeleteModal"

import edit from "../../assets/images/dashboard-icons/edit.svg"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import { useSelector } from "react-redux"
import PageNotFound from "../404"

const Blogs = () => {
  const classes = useStyles()
  let history = useHistory()
  let [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    totalCount: 0,
  })
  let [list, setList] = useState([])
  let [isSpinner, setIsSpinner] = useState(true)
  const [searchText, setSearchText] = useState("")
  const [getBlogsApi, blogResponse] = useLazyQuery(GET_BLOGS, {
    fetchPolicy: "no-cache",
  })
  const [deleteModalId, setDeleteModalId] = useState(-1)
  const [submitMutation, submitResponse] = useMutation(DEL_BLOGS)
  const [inProgress, setInProgress] = useState(false)
  const [getCounselor, counselorResponse] = useLazyQuery(GET_COUNSELORS, {
    fetchPolicy: "no-cache",
  })
  const [submitApprove, approveResponse] = useMutation(SUBMIT_BLOG)
  const userRole = useSelector(state => state.Login?.user?.user?.role)
  const [calenderId, setCalenderId] = useState(0)
  const [approveReq, setApproveReq] = useState(false)

  const getBlogs = async (limit, page, search) => {
    try {
      let obj = {
        limit,
        page,
        search,
        is_all: true,
      }
      if (userRole.id !== 1) {
        obj.calendar_id = calenderId
      }
      getBlogsApi({
        variables: {
          argumentsInput: obj,
        },
      })
      setState({
        ...state,
        page: Number(page),
        sizePerPage: Number(limit),
      })
    } catch (error) {
      setIsSpinner(false)
    }
  }

  const getCounselorApi = async () => {
    try {
      const res = await getCounselor({
        variables: {
          argumentsInput: {},
        },
      })
      const { code, status, data } = res.data?.getCalendarLegend
      if (
        code == 200 &&
        status &&
        data?.calendars?.length &&
        data?.calendars?.[0]?.id
      ) {
        setCalenderId(parseInt(data?.calendars?.[0]?.id))
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getCounselorApi()
  }, [])

  useEffect(() => {
    if (calenderId) {
      const { sizePerPage, page } = state
      getBlogs(sizePerPage, page, searchText)
    }
  }, [calenderId])

  useEffect(() => {
    if (blogResponse?.data?.blogs?.data?.blogs) {
      const data = blogResponse.data.blogs?.data
      const { sizePerPage, page } = state
      const { totalCount } = data
      setState({
        ...state,
        totalCount,
      })
      const blogs =
      data ? blogResponse.data.blogs?.data?.blogs.map((data, i) => ({
              ...data,
              index: (page - 1) * sizePerPage + i + 1,
              bgColor: data.is_requested
                ? data.status === "active"
                  ? "#ffcc4f"
                  : "#ff6060"
                : "",
            }))
          : []
      setList(blogs)
      setIsSpinner(false)
    }
  }, [blogResponse.data])

  const actionFormatter = (cell, row) => {
    const encodedId = btoa(row.id);
    return(
    <div className="d-flex align-items-center blog-container-justify-center flex-wrap gap-2">
      {userRole.id === 1 ? (
        <>
          {!row.is_approved && row.is_requested && (
            <>
              <button
                type="button"
                className="btn btn-primary mx-1"
                title="approve"
                disabled={approveReq}
                onClick={() => {
                  setApproveReq(true)
                  handleApprove(row, true)
                }}
              >
                <i className="bx bx-check font-size-20 text-white vertical-align"></i>
              </button>
              <button
                type="button"
                className="btn btn-inactive mx-1"
                title="reject"
                disabled={approveReq}
                onClick={() => {
                  setApproveReq(true)
                  handleApprove(row, false)
                }}
              >
                <i className="bx bx-x font-size-20 color-dark-green vertical-align"></i>
              </button>
            </>
          )}
        </>
      ) : (
        <>
          {
            <button
              className={`btn w-sm waves-effect waves-light mx-2 ${
                row?.is_approved ? "btn-inactive" : "btn-primary"
              }`}
              disabled={row.is_requested}
              onClick={() => {
                handleApproveReq(row, !row?.is_approved)
              }}
            >
              {!row?.is_approved ? "Req. to Publish" : "Req. to Unpublish"}
            </button>
          }
        </>
      )}
      <Button
        className="btn btn-primary cursor-pointer"
        onClick={() => history.push(`/blogs/${encodedId}`)}
      >
        <img src={edit} className="mx-1" />
      </Button>
      <a
        onClick={() => setDeleteModalId(Number(row.id))}
        className="px-3 text-danger delete-btn cursor-pointer"
      >
        <img src={deleteBin} className="margin-top-10px-xs " />
      </a>
    </div>
  )}

  const columns = [
    {
      text: "ID",
      dataField: "id",
      keyField: "id",
      hidden: true,
    },
    {
      text: "#",
      dataField: "index",
      style: (cell, row, rowIndex) => ({
        backgroundColor: row.bgColor,
      }),
    },
    {
      text: "Name",
      dataField: "name",
      formatter: cell => <div className="blogs-mx-width-150">{cell}</div>,
      style: (cell, row, rowIndex) => ({
        backgroundColor: row.bgColor,
      }),
    },
    {
      text: "Thumbnail",
      dataField: "thumbnail_image",
      formatter: cell =>
        cell ? (
          <img src={cell} height={70} className="blogs-img-styling " />
        ) : (
          ""
        ),
      style: (cell, row, rowIndex) => ({
        backgroundColor: row.bgColor,
      }),
    },
    {
      text: "Post Summary",
      dataField: "post_summary",
      formatter: cell => (
        <div
          className="eclipes"
          dangerouslySetInnerHTML={{ __html: cell }}
        ></div>
      ),
      style: (cell, row, rowIndex) => ({
        backgroundColor: row.bgColor,
        // maxWidth: "90px",
      }),
    },
    {
      text: "Status",
      dataField: "status",
      formatter: (cell, row) => {
        return cell && cell == "active" ? (
          <span className="rounded-pill text-wrap lh-1 bg-info badge bg-secondary">
            {row.is_requested ? "Req. to Publish" : "Published"}
          </span>
        ) : (
          <span className="rounded-pill text-wrap lh-1 bg-danger badge bg-secondary">
            {row.is_requested ? "Req. to Unpublish" : "Unpublished"}
          </span>
        )
      },
      style: (cell, row, rowIndex) => ({
        backgroundColor: row.bgColor,
        maxWidth: "90px",
      }),
    },
    userRole.id == 1
      ? {
          text: "Author",
          dataField: "therapist_calendar",
          formatter: (cell, row) => <>{cell?.name ?? "NA"}</>,
          style: (cell, row, rowIndex) => ({
            backgroundColor: row.bgColor,
          }),
        }
      : {
          style: (cell, row, rowIndex) => ({
            backgroundColor: row.bgColor,
          }),
        },
    {
      text: "Modified",
      dataField: "updated_at",
      formatter: (cell, row) => {
        return cell && formatToCommonDate(cell, "DD-MMM-YYYY HH:mm")
      },
      style: (cell, row, rowIndex) => ({
        backgroundColor: row.bgColor,
      }),
    },
    {
      text: "Action",
      dataField: "action",
      formatter: actionFormatter,
      style: (cell, row, rowIndex) => ({
        minWidth: "150px",
        backgroundColor: row.bgColor,
      }),
    },
  ]

  const delaySearch = useCallback(
    debounce(search => {
      getBlogs(state?.sizePerPage, 1, search)
    }, 1000),
    []
  )

  const handleSearchText = search => {
    setIsSpinner(true)
    setSearchText(search)
    delaySearch(search)
  }

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setIsSpinner(true)
    getBlogs(sizePerPage, page, searchText)
  }
  const handleAdd = () => {
    return history.push("/blogs/add")
  }

  const handleDelete = async id => {
    try {
      setInProgress(true)
      await submitMutation({
        variables: {
          createBlogInput: { id },
        },
      })
      const { sizePerPage, page } = state
      await getBlogs(sizePerPage, page, searchText)
      setTimeout(() => {
        toast.success("Record deleted successfully")
      }, 1500)
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong !")
    } finally {
      setInProgress(false)
      setDeleteModalId(-1)
    }
  }

  const handleApprove = async (data, approved) => {
    try {
      let obj = {
        id: data.id,
        name: data.name,
        post_summary: data.post_summary,
        post_body: data.post_body,
        main_image: data.main_image,
        thumbnail_image: data.thumbnail_image,
        is_requested: false,
        is_approved: approved,
        status: data?.status ? "active" : "inactive",
      }
      const res = await submitApprove({
        variables: {
          createBlogInput: obj,
        },
      })
      const { sizePerPage, page } = state
      await getBlogs(sizePerPage, page, searchText)
      setTimeout(() => {
        toast.success(`Blog ${approved ? "approved" : "rejected"} successfully`)
        setApproveReq(false)
      }, 1500)
    } catch (error) {
      console.error(error)
    }
  }

  const handleApproveReq = async (data, requestPublish) => {
    try {
      let obj = {
        calendar_id: calenderId,
        id: data.id,
        name: data.name,
        post_summary: data.post_summary,
        post_body: data.post_body,
        main_image: data.main_image,
        thumbnail_image: data.thumbnail_image,
        is_requested: true,
        is_approved: false,
      }
      obj.status = requestPublish ? "active" : "inactive"
      const res = await submitApprove({
        variables: {
          createBlogInput: obj,
        },
      })
      const { sizePerPage, page } = state
      await getBlogs(sizePerPage, page, searchText)
      toast.success(
        `Requested for ${
          requestPublish ? "Publish" : "Unpublish"
        } blog successfully`
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="page-content">
    {hasPermission() ? (
      <div className="container-fluid">
        <div>
          <div className="py-3">
            <h4 className="card-title-main">Blogs</h4>
            <h5 className="card-para-main">List of all the blogs.</h5>
          </div>
        </div>
        <Row className="mg-top-default-ui">
          <Col>
            <Card className="light-green-bg-card-table">
              <CardBody>
                <Row className="d-flex align-item-center justify-content-between mb-2 mt-4">
                  <Col
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    className="align-self-center"
                  >
                    <Input
                      value={searchText}
                      onChange={e => handleSearchText(e.target.value)}
                      placeholder="Search"
                      className="default-search-input"
                    />
                  </Col>
                  <Col sm={12} md={9} lg={9} xl={6} className="text-end">
                    <Button
                      className="btn btn-primary margin-top-10px-xs "
                      onClick={() => {
                        handleAdd()
                      }}
                    >
                      <span>
                        <i className="mdi mdi-plus" />
                        Add
                      </span>
                    </Button>
                  </Col>
                </Row>
                <div className="table-custom-redesign">
                  <Table
                    data={list}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    keyField={"id"}
                    isHover={false}
                    isStriped={false}
                    isBordereded={false}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <DeleteModal
          show={deleteModalId !== -1}
          onDeleteClick={() => {
            handleDelete(deleteModalId)
          }}
          onCloseClick={() => {
            setDeleteModalId(-1)
          }}
          inProgress={inProgress}
        />
      </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  )
}
export default Blogs
