import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
} from "reactstrap"
import DatePicker from "react-datepicker"
import refresh from "../../assets/images/dashboard-icons/refresh.svg"
import moment from "moment"
import classNames from "classnames"
import useStyles from "./styles"

const DashboardDateFilters = props => {
  const {
    loading,
    refreshAPI,
    value,
    setValue,
    endDate,
    setEndDate,
    startDate,
    handleMonthYear,
  } = props
  const classes = useStyles()

  return (
    <div className="float-end d-flex gap-2 align-items-center custom-date-picker-style">
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          className="text-reset bg-light dropdownInputWhite p-2 pointer"
          id="dropdownMenuButton5"
          caret
        >
          <span className="">
            {value}
            <i className="mdi mdi-chevron-down ms-1"></i>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            value={"Monthly"}
            onClick={e => setValue(e.target.value)}
          >
            Monthly
          </DropdownItem>
          <DropdownItem
            value={"Yearly"}
            onClick={e => setValue(e.target.value)}
          >
            Yearly
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <div className="date-filter-w100px">
        {value == "Monthly" ? (
          <DatePicker
            selected={startDate}
            className="form-control pointer"
            onChange={value => handleMonthYear(value, "Monthly")}
            dateFormat="MMM-yyyy"
            showMonthYearPicker={value == "Monthly" ? true : false}
            customInput={
              <input type="text" id="validationCustom01" placeholder="Date" />
            }
          />
        ) : (
          <DatePicker
            selected={endDate}
            className="form-control pointer"
            dateFormat="yyyy"
            onChange={value => setEndDate(value)}
            showYearPicker={value == "Yearly" ? true : false}
            customInput={
              <input type="text" id="validationCustom01" placeholder="Date" />
            }
          />
        )}
      </div>
      <span>
        <img
          src={refresh}
          width="24px"
          height="24px"
          className={classNames(
            classes.refreshIcon,
            loading && classes.refresh
          )}
          onClick={refreshAPI}
        />
      </span>
    </div>
  )
}

export default DashboardDateFilters
