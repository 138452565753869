import { useQuery, gql } from "@apollo/client"

export const SIGNIN_GQL = gql`
  query Query($loginInput: loginInput!) {
    signIn(loginInput: $loginInput) {
      code
      status
      message
      data {
        token
        user {
          id
          name
          email
          mobile_number
          is_active
          first_name
          last_name
          role {
            role_name
            id
          }
          calendar {
            item_id
          }
        }
      }
    }
  }
`

export const SEND_OTP = gql`
  mutation SendEmailOtp($createUserInput: createUserInput!) {
    sendEmailOtp(createUserInput: $createUserInput) {
      code
      data {
        is_registered
        users {
          id
          role {
            slug
          }
          organizations {
            id

            organization {
              id
              organization_identifier
              name
              primary_color
              favicon
              secondary_color
              font_color
              domain
              logo
            }
          }
        }
        __typename
      }
      message
      status
      __typename
    }
  }
`
export const SEND_EMAIL_OTP = gql`
  mutation SendEmailOtp($createUserInput: createUserInput!) {
    sendEmailOtp(createUserInput: $createUserInput) {
      code
      data {
        is_registered
        users {
          id
          role{
            slug
          }
          organizations {
            id
            organization {
              name
              id
              description
              organization_identifier
              favicon
              logo
              domain
              is_active
            }
          }
        }
        __typename
      }
      message
      status
      __typename
    }
  }
`

export const GET_USER_BY_MOBILE = gql`
  query GetUserByMobileNumber($argumentsInput: ArgumentsInput) {
    getUserByMobileNumber(argumentsInput: $argumentsInput) {
      __typename
      message
      status
      code
      data {
        __typename
        user {
          __typename
          id
          is_email_confirmed
          uuid
          name
          first_name
          last_name
          password
          email
          mobile_number
          country_code
          national_number
          device_token
          age
          is_active
          token
          is_email_confirmed
          role {
            __typename
            id
            role_name
          }
          role_id
        }
      }
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($updatePasswordInput: updatePasswordInput!) {
    changePassword(updatePasswordInput: $updatePasswordInput) {
      code
      status
      message
    }
  }
`

export const OTP_VERIFY = gql`
  mutation VerifyEmailOtp($createUserInput: createUserInput!) {
    verifyEmailOtp(createUserInput: $createUserInput) {
      code
      message
      status
      data {
        users {
          token
          id
          first_name
          last_name
          role_id
          email
          age
          mobile_number
          device_token
          role {
            id
            rolePermissions {
              method {
                slug
              }
            }
            slug
            role_name
          }
          calendar {
            id
            name
            counselling_type {
              id
              name
            }
          }
        }
      }
    }
  }
`
export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($confirmEmailInput: forgetPaaswordInput!) {
    forgotPassword(confirmEmailInput: $confirmEmailInput) {
      code
      status
      message
    }
  }
`

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($passwordInput: PasswordInput) {
    updatePassword(passwordInput: $passwordInput) {
      code
      status
      message
    }
  }
`

export const EDIT_PROFILE = gql`
  mutation Mutation($createUserInput: createUserInput!) {
    createEditUser(createUserInput: $createUserInput) {
      code
      status
      message
      data {
        user {
          id
          name
          password
          email
          mobile_number
          is_active
          role {
            role_name
            slug
            id
            is_active
          }
        }
      }
    }
  }
`

export const OTP_VALIDATE = gql`
  mutation ForgotPassword($confirmEmailInput: ConfirmEmailInput!) {
    resetPassword(confirmEmailInput: $confirmEmailInput) {
      code
      status
      message
    }
  }
`

export const CREATE_ADMIN_USER = gql`
  mutation CreateAdminUser($createUserInput: createUserInput!) {
    createAdminUser(createUserInput: $createUserInput) {
      code
      status
      message
      data {
        user {
          email
        }
      }
    }
  }
`

export const REGISTER_USER = gql`
  query Query($loginInput: loginInput!) {
    signIn(loginInput: $loginInput) {
      code
      status
      message
      data {
        token
        user {
          id
          name
          email
          mobile_number
          is_active
          role {
            id
            role_name
          }
        }
      }
    }
  }
`
export const VARIFY_EMAIL = gql`
  mutation VerifyEmailOtp($createUserInput: createUserInput!) {
    verifyEmailOtp(createUserInput: $createUserInput) {
      code
      message
      status
      data {
        users {
          token
          id
          first_name
          last_name
          role_id
          email
          age
          mobile_number
          device_token
          created_at
          role {
            id
            rolePermissions {
              method {
                slug
              }
            }
            slug
            role_name
          }
          calendar {
            id
            name
            counselling_type {
              id
              name
            }
          }
        }
      }
    }
  }
`
export const ON_BOARD = gql`
  mutation Mutation($updateUserInput: updateUserInput!) {
    UpdateClient(updateUserInput: $updateUserInput) {
      code
      message
      status
      data {
        user {
          id
          first_name
          last_name
          password
          mobile_number
          is_active
          calendar {
            id
            counselling_type {
              id
              name
            }
          }
          role {
            role_name
            id
          }
        }
      }
    }
  }
`
