import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import { Divider } from "@material-ui/core"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import useStyles from "../styles"
import classNames from "classnames"
import millify from "millify"

const SessionStatistic = ({ dashboardData, callMainApi }) => {
  const [sessions, setSessions] = useState([
    {
      id: 1,
      title: "Session Completed",
      value: 0,
      prefix: "",
      suffix: "",
      decimal: 0,
      color: "#5B73E8",
    },
    {
      id: 2,
      title: "No Show/Cancel",
      value: 0,
      prefix: "",
      suffix: "",
      decimal: 0,
      color: "#50A5F1",
    },
    {
      id: 3,
      title: "External session",
      value: 0,
      prefix: "",
      suffix: "",
      decimal: 0,
      color: "#34C38F",
    },
    {
      id: 4,
      title: "Internal session",
      value: 0,
      prefix: "",
      suffix: "",
      decimal: 0,
      color: "#F1B44C",
    },
  ])

  useEffect(() => {
    setSessions([
      {
        id: 1,
        title: "Session Completed",
        value: dashboardData.session_completed,
        prefix: "",
        suffix: "",
        decimal: 0,
        color: "#5B73E8",
      },
      {
        id: 2,
        title: "No Show/Cancel",
        value: dashboardData.no_show_counts,
        prefix: "",
        suffix: "",
        decimal: 0,
        color: "#50A5F1",
      },
      {
        id: 3,
        title: "External Session",
        value: dashboardData.external_sessions,
        prefix: "",
        suffix: "",
        decimal: 0,
        color: "#34C38F",
      },
      {
        id: 4,
        title: "Internal Session",
        value: dashboardData.internal_Sessions,
        prefix: "",
        suffix: "",
        decimal: 0,
        color: "#F1B44C",
      },
    ])
  }, [dashboardData])

  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setSessions([
      {
        id: 1,
        title: "Session Completed",
        value: dashboardData.session_completed || 0,
        prefix: "",
        suffix: "",
        decimal: 0,
        color: "#5B73E8",
      },
      {
        id: 2,
        title: "No Show/Cancel",
        value: dashboardData.no_show_counts,
        prefix: "",
        suffix: "",
        decimal: 0,
        color: "#50A5F1",
      },
      {
        id: 3,
        title: "External Session",
        value: dashboardData.external_sessions,
        prefix: "",
        suffix: "",
        decimal: 0,
        color: "#34C38F",
      },
      {
        id: 4,
        title: "Internal Session",
        value: dashboardData.internal_Sessions,
        prefix: "",
        suffix: "",
        decimal: 0,
        color: "#F1B44C",
      },
    ])
  }, [dashboardData])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  }, [loading])

  const refreshApi = () => {
    setLoading(true)
    callMainApi(true)
  }

  return (
    <React.Fragment>
      <Card className="card-dark-bg">
        <CardBody className={classes.CardPadding}>
          <div className="d-flex justify-content-end">
            <img
              src={refresh}
              width="24px"
              height="24px"
              className={classNames(
                classes.refreshIcon,
                loading && classes.refresh,
                "img-refresh-stats"
              )}
              onClick={() => refreshApi()}
            />
          </div>
          <div className={classes.sessionContainer}>
            {dashboardData.session_completed ?
              sessions.map((session, index) => (
                <React.Fragment key={index}>
                  <div className={classes.sessionItem}>
                    <div title={session.value?.toLocaleString("en-US")}>
                      <p className={classes.sessionTitle} id="session-title">
                        {session.title}
                      </p>

                      <p className={`${classes.value} session-flex-title-per`}>
                        <span className="value-session-stats">
                          {millify(session.value)}
                        </span>
                        {/* TODO: Need to make this dynamic*/}
                        {/* <span className="percentage-session-stats">+1.3%</span> */}
                      </p>
                      <p>
                        {/* TODO: Need to make this dynamic*/}
                        {/* <span>
                          Previous Month <b>290</b>
                        </span> */}
                      </p>
                    </div>
                  </div>
                  {index !== sessions.length - 1 ? (
                    <Divider className={`${classes.divider} divider-w-h `} />
                  ) : null}
                </React.Fragment>
              )) : "No data found!"}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SessionStatistic
