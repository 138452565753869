import React, { useCallback, useState } from "react"
import Table from "../../components/Common/Table"
import { Card, CardBody, Col, Row } from "reactstrap"
import { GET_USERS } from "./gql/userGql"
import { useLazyQuery } from "@apollo/client"
import { useHistory, useLocation } from "react-router-dom"
import { debounce } from "lodash"
import { capitalizeFirstLetter } from "../../functions/functions"
import moment from "moment"

const TrashUsers = () => {
  const location = useLocation()
  const [users, setUsers] = useState([])
  const [isSpinner, setIsSpinner] = useState(false)
  const [totalCount, setTotalCount] = useState(20)
  const [state, setState] = useState({
    page: 1,
    data: users,
    sizePerPage: 20,
    sortBy: "created_at",
    sortOrder: "DESC",
  })

  const [getUserList, listResponse] = useLazyQuery(GET_USERS, {
    fetchPolicy: "no-cache",
  })

  function getUserListFun(page, sizePerPage, searchText, sortField, sortOrder) {
    const argumentsInput = {
      page: page,
      limit: sizePerPage,
      search: searchText,
      sort: sortField,
      sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
      is_deleted_users: true,
    }

    getUserList({
      variables: {
        argumentsInput: argumentsInput,
      },
    })
      .then(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          searchText,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
      .catch(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          searchText,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
  }

  React.useEffect(() => {
    const selectedPage = new URLSearchParams(location.search).get("page")
    let page = state.page
    if (selectedPage) {
      page = Number(selectedPage)
    }

    getUserListFun(page, state.sizePerPage, "", state.sortBy, state.sortOrder)
  }, [])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getUsers &&
      listResponse.data.getUsers.status &&
      listResponse.data.getUsers.code === 200
    ) {
      setIsSpinner(false)
      const updatedUsers = listResponse.data.getUsers.data.users.map(user => ({
        ...user,
        role: user.role !== null ? user.role : { id: 3, role_name: "Client" },
      }))
      setUsers(updatedUsers)
      setTotalCount(listResponse.data.getUsers.data.totalCount)
      setState(s => ({ ...s, data: updatedUsers }))
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  const changeHandler = ({
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
  }) => {
    const field = sortField === null ? "id" : sortField
    getUserListFun(1, sizePerPage, searchText, field, sortOrder)
  }

  const delaySearch = useCallback(debounce(changeHandler, 1000), [])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setState(p => ({
      ...p,
      page,
      sizePerPage,
    }))
    let currentUrl = new URL(window.location.href)

    currentUrl.searchParams.set("page", page)
    window.history.replaceState({}, document.title, currentUrl.href)
    const field = sortField === null ? "id" : sortField
    setIsSpinner(true)
    if (type === "search") {
      delaySearch({ page, sizePerPage, searchText, sortField, sortOrder })
    } else {
      getUserListFun(page, sizePerPage, searchText, field, sortOrder)
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="m-0">
          {rowIndex + 1 + state.sizePerPage * (state.page - 1)}
        </p>
      </>
    )
  }

  const roleNameShow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <p className="m-0">{row?.organizations[0]?.role?.role_name}</p>
      </>
    )
  }

  function fullNameFun(value, row, index, field) {
    const firstName = row?.first_name ? row?.first_name : " "
    const lastName = row?.last_name ? row?.last_name : " "

    return (
      <span>
        {capitalizeFirstLetter(firstName) +
          " " +
          capitalizeFirstLetter(lastName)}
      </span>
    )
  }

  function dateFormatter(value, row, index, field) {
    return value === null
      ? " "
      : moment(row?.organizations[0]?.created_at).format("DD-MMM-yyyy")
  }

  const columns = [
    {
      text: "#",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "Name",
      dataField: "first_name",
      formatter: fullNameFun,
      headerStyle: {
        cursor: "pointer",
      },
      style: {
        maxWidth: 150,
      },
    },
    {
      text: "Role",
      dataField: "role.role_name",
      width: 200,
      formatter: roleNameShow,
    },
    {
      text: "Email Address",
      dataField: "email",
      width: 70,
      style: {
        maxWidth: 150,
      },
    },
    {
      text: "Created On",
      dataField: "created_at",
      sort: false,
      formatter: dateFormatter,
    },
  ]

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null

  return (
    <div>
      <React.Fragment>
        <>
          <div className="page-content">
            <div className="container-fluid">
              <div className="d-flex flex-wrap-mobile-only justify-content-between">
                <div className="p-2 w-75">
                  <h4 className="card-title-main">Users</h4>
                  <h5 className="card-para-main ">
                    List of all the deleted users, counsellors and clients
                    registered with the{" "}
                    {orgData?.name ? orgData?.name : "No Fear Counselling"}.
                  </h5>
                </div>
              </div>
              <Row className="mg-top-default-ui">
                <Col className="col-12">
                  <Card className="light-green-bg-card-table">
                    <CardBody className="table-custom-redesign">
                      <Table
                        data={state.data}
                        page={state.page}
                        sizePerPage={state.sizePerPage}
                        totalSize={totalCount}
                        onTableChange={handleTableChange}
                        columns={columns}
                        noDataIndication={"No Data Found"}
                        loading={isSpinner}
                        isSearch={true}
                        isHover={false}
                        isStriped={false}
                        isBordereded={false}
                        location={location.pathname}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </>
      </React.Fragment>
    </div>
  )
}

export default TrashUsers
