import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
// import images
import logo from "../assets/images/logo.png"
import logolight from "../assets/images/logo-light.png"

const ErrorBoundryCheck = () => {
  let history = useHistory();

  useEffect(()=>{
    test
  },[])

  return (
    <React.Fragment>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col md={12} lg={12} xl={12}>
            <Card className="container-fluid vh-100">
              <CardBody>
                <Link to="/">
                  <img src={logo} alt="" height="40" width="80" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </Link>
                <Row>
                  <Col className="col-12">
                    <div className="d-flex align-items-center flex-column">
                      <i className="uil uil-exclamation-triangle text-primary fa-5x"></i>
                      <h1 className="display-1">404</h1>
                      <h1>Ooops!!</h1>
                      <p className="text-center">THAT PAGE DOESN&apos;T EXIST OR IS UNAVAILABLE.</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button className="btn btn-primary w-sm waves-effect waves-light" type="button"
                        onClick={() => history.push(`/dashboard`)}>
                        Go Back to Home
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default ErrorBoundryCheck;
