import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import plus from "../../assets/images/dashboard-icons/twilio/plus.svg"
import minus from "../../assets/images/dashboard-icons/twilio/minus.svg"
import {
  convertUTCToLocal,
  calculateDurationBetweenTwoTimes,
  capitalizeFirstLetter,
} from "../../functions/functions"
import moment from "moment"
import classNames from "classnames"
import CardWarning from "../Appointments/CardWarning"
import { LINKED_ACCOUNT_STATUS } from "../Appointments/gql/Gql"
import { useLazyQuery } from "@apollo/client"
import useStyles from "./style"

function Details({ appointmentDetails, appointmentForms }) {
  const classes = useStyles()
  const [openKey, setOpenKey] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [warningMsg, setWarningMsg] = useState(false)
  const userData = useSelector(state => state.Login?.user?.user)

  const handleToggle = key => {
    setOpenKey(openKey !== key ? key : null)
  }

  const Schedule_date = appointmentDetails?.utc_datetime
    ? convertUTCToLocal(appointmentDetails.utc_datetime, "DD-MMM-YYYY")
    : null
  const startTime = appointmentDetails?.utc_datetime
    ? convertUTCToLocal(appointmentDetails.utc_datetime)
    : null
  const endTime = appointmentDetails?.duration
    ? moment(startTime).add(appointmentDetails.duration, "minutes")
    : null

    const encodedAccountData = localStorage.getItem("accountData")
  const accountStatus = encodedAccountData
  ? JSON.parse(atob(encodedAccountData))
  : null

  const [accountDetailsStatus, setAccountDetailsStatus] = useState()

  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])


  return (
    <>
          <div className="bg-light ">
            {warningMsg === "error" && <p className="text-danger py-2 h-5 mx-2">This client has not added card details yet.</p>}
            {warningMsg === "success" && <p className="text-success py-2 h-5 mx-2">Client added their card details successfully.</p> }
         </div>
      {(accountStatus && accountDetailsStatus) && <div className={`my-4 p-0 ${isOpen ? "warning-section open" : ""}`}>
        <CardWarning
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setWarningMsg={setWarningMsg}
          appointmentDetails={appointmentDetails}
          userId={userData.role.id}
        />
      </div>}
      <div className={`${classes.accordionContainer} light-green-bg-card-video`}>
        <ul className={classes.accordionList}>
          <li>
            <div
              className="d-flex gap-2"
              onClick={() => handleToggle("Client Information")}
            >
              <div>
                <img
                  src={!(openKey === "Client Information") ? plus : minus}
                  width="16px"
                  height="16px"
                />
              </div>
              <div>
                <h3 className={`${classes.accordionTitle} f-18-card-title-main`}>
                  <span>{"Client Information"}</span>
                </h3>
              </div>
            </div>
            <div
              className={classNames(
                openKey === "Client Information"
                  ? classes.activeAccordianContent
                  : null,
                classes.accordianContent
              )}
            >
              <div className="d-flex gap-2">
                <div>
                  <img
                    src={plus}
                    width="16px"
                    height="16px"
                    className="invisible "
                  />
                </div>
                <div>
                  <div className="d-flex flex-column gap-2 twilio-mgt-20">
                    <div className="d-flex flex-column">
                      <span
                        className={`${classes.accordianContentTitle} card-sub-title-tabs`}
                      >
                        {"Name"}
                      </span>
                      <span className={classes.accordianContentData}>
                        {appointmentDetails?.first_name}{" "}
                        {appointmentDetails?.last_name}
                      </span>
                    </div>
                  </div>

                  {appointmentDetails?.email && (
                    <div className="d-flex flex-column gap-2 twilio-mgt-20">
                      <div className="d-flex flex-column">
                        <span
                          className={`${classes.accordianContentTitle} card-sub-title-tabs`}
                        >
                          {"Email"}
                        </span>
                        <span className={classes.accordianContentData}>
                          {appointmentDetails?.email}
                        </span>
                      </div>
                    </div>
                  )}
                  {appointmentDetails?.phone && (
                    <div className="d-flex flex-column gap-2 twilio-mgt-20">
                      <div className="d-flex flex-column">
                        <span
                          className={`${classes.accordianContentTitle} card-sub-title-tabs`}
                        >
                          {"Phone"}
                        </span>
                        <span className={classes.accordianContentData}>
                          {appointmentDetails?.phone}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="d-flex flex-column gap-2 twilio-mgt-20">
                    <div className="d-flex flex-column">
                      <span
                        className={`${classes.accordianContentTitle} card-sub-title-tabs`}
                      >
                        {"Schedule Date"}
                      </span>
                      <span className={classes.accordianContentData}>
                        {Schedule_date}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 twilio-mgt-20">
                    <div className="d-flex flex-column">
                      <span
                        className={`${classes.accordianContentTitle} card-sub-title-tabs`}
                      >
                        {"Schedule Time & Duration"}
                      </span>
                      <span className={classes.accordianContentData}>
                        {moment(startTime).format("h:mmA")}
                        {" - "}
                        {moment(endTime).format("h:mmA")}
                        <span></span>
                        {" · "}
                        {calculateDurationBetweenTwoTimes(startTime, endTime)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div
              className="d-flex gap-2"
              onClick={() => handleToggle("Counsellor Information")}
            >
              <div>
                <img
                  src={!(openKey === "Counsellor Information") ? plus : minus}
                  width="16px"
                  height="16px"
                />
              </div>
              <div>
                <h3 className={`${classes.accordionTitle} f-18-card-title-main`}>
                  <span>{"Counsellor Information"}</span>
                </h3>
              </div>
            </div>
            <div
              className={classNames(
                openKey === "Counsellor Information"
                  ? classes.activeAccordianContent
                  : null,
                classes.accordianContent
              )}
            >
              <div className="d-flex gap-2">
                <div>
                  <img
                    src={plus}
                    width="16px"
                    height="16px"
                    className="invisible "
                  />
                </div>
                <div>
                  <div className="d-flex flex-column gap-2 twilio-mgt-20">
                    <div className="d-flex flex-column">
                      <span
                        className={`${classes.accordianContentTitle} card-sub-title-tabs`}
                      >
                        {"Name"}
                      </span>
                      <span className={classes.accordianContentData}>
                        {appointmentDetails?.therapist_calendar?.name}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-2 twilio-mgt-20">
                    <div className="d-flex flex-column">
                      <span
                        className={`${classes.accordianContentTitle} card-sub-title-tabs`}
                      >
                        {"Email"}
                      </span>
                      <span className={classes.accordianContentData}>
                        {appointmentDetails?.therapist_calendar?.email}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          {appointmentForms &&
            userData &&
            userData?.role?.id < 3 &&
            appointmentForms.map((appointmentForm, index) => (
              <li key={appointmentForm.id}>
                <>
                  <div
                    onClick={() => handleToggle(appointmentForm?.name)}
                    className="d-flex gap-2"
                  >
                    <div>
                      <img
                        src={!(openKey === appointmentForm?.name) ? plus : minus}
                        width="16px"
                        height="16px"
                      />
                    </div>
                    <div>
                      <h3
                        className={`${classes.accordionTitle} f-18-card-title-main`}
                      >
                        <span>{appointmentForm?.name}</span>
                      </h3>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      openKey === appointmentForm?.name
                        ? classes.activeAccordianContent
                        : null,
                      classes.accordianContent
                    )}
                  >
                    <div className="d-flex gap-2">
                      <div>
                        <img
                          src={plus}
                          width="16px"
                          height="16px"
                          className="invisible "
                        />
                      </div>
                      <div>
                        {appointmentForm.forms &&
                          appointmentForm.forms.map(appointmentFormdetails => (
                            <div
                              className="d-flex flex-column gap-2 twilio-mgt-20"
                              key={appointmentFormdetails.id}
                            >
                              <div className="d-flex flex-column">
                                <span
                                  className={`${classes.accordianContentTitle} card-sub-title-tabs`}
                                >
                                  {capitalizeFirstLetter(
                                    appointmentFormdetails.name
                                  )}
                                </span>
                                <span className={classes.accordianContentData}>
                                  {appointmentFormdetails.value}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

export default Details
