/* eslint-disable react/display-name */
import React, { useCallback, useState, ReactNode, useRef } from "react"
import { Button } from "reactstrap"
import Select, { components } from "react-select"
import { makeStyles } from "@material-ui/core"
import _ from "lodash"
import classNames from "classnames"
import style from "../style.module.scss"
import PropTypes from "prop-types"
import Tooltip from "@material-ui/core/Tooltip"

//icons
import notPaid from "../../../assets/images/legend-icons/not-paid.svg"
import noApproval from "../../../assets/images/legend-icons/noApproval.png"
import submitted from "../../../assets/images/legend-icons/submitted.svg"
import activatedDeactivated from "../../../assets/images/legend-icons/activate-deactivated.svg"
import oneSessionOrWeek from "../../../assets/images/legend-icons/one-session-week.svg"
import twoSessionOrWeek from "../../../assets/images/legend-icons/two-sessions-week.svg"
import partiallyPaid from "../../../assets/images/legend-icons/partially-paid.svg"
import { hexToRgba } from "../../../functions/functions"

const useStyles = makeStyles(theme => ({
  selectContainer: {
    border: "none",
    borderColor: "hsl(0, 0%, 80%)",
    width: "250px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    borderRadius: "4px",
    padding: 0,
    cursor: "default",
    minHeight: "38px",
    //outline: "0 !important",
    position: "relative",
  },

  selectHoveredContainer: {
    borderColor: "hsl(0, 0%, 80%)",
    width: "250px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    borderRadius: "4px",
    padding: 0,
    cursor: "default",
    minHeight: "38px",
    outline: "0 !important",
    position: "relative",
    "&:focus": {
      borderColor: "#adc9a6",
      color: "hsl(0, 0%, 20%)",
      boxShadow: "0 0 0 1px #adc9a6",
      cursor: "default",
    },
    "&:hover": {
      borderColor: "#adc9a6",
      color: "hsl(0, 0%, 20%)",
      boxShadow: "0 0 0 1px #adc9a6",
      cursor: "default",
    },
  },

  dropdownIndicator: {
    color: "hsl(0, 0%, 80%)",
  },

  dropdownIndicatorActive: {
    color: "hsl(0, 0%, 20%)",
  },

  menuContainer: {
    backgroundColor: "white",
    //borderRadius: 4,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    boxShadow:
      "0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)",
    // marginTop: 8,
    position: "absolute",
    zIndex: 2,
    width: "100%",
    border: "1px solid rgb(21, 60, 39)",
    borderTop: "none",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },

  container: {
    position: "relative",
    width: "100%",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },

  placeholder: {
    color: "grey",
  },
}))

function CustomDropdown(props) {
  const {
    options,
    onCounselorChange,
    isVisible,
    isHovered = true,
    isDirectClient,
    counsellorId,
    isClientDropdown = false,
    counsellorChange,
  } = props
  const ref = useRef()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState()
  const [sortedArray, setSortedArray] = useState([])
  const storedData = localStorage.getItem("orgData")
  const customColor = storedData ? JSON.parse(storedData) : null

  React.useEffect(() => {
    const sortedList = _.sortBy(options, [
      function (option) {
        return option.name
      },
    ])
    setSortedArray(sortedList)
    if (!isClientDropdown & (sortedList.length > 0)) {
      let counsellorObj = null
      if (counsellorId) {
        const filterObj = _.find(sortedList, function (o) {
          return o.id === counsellorId
        })
        counsellorObj = {
          value: filterObj?.id,
          label: filterObj?.name,
          data: {
            id: filterObj?.id,
            name: filterObj?.name,
            document_submitted_on_status:
              filterObj?.document_submitted_on_status,
            request_status: filterObj?.request_status,
            approval_status: filterObj?.approval_status,
            one_sessions_one_week_left: filterObj?.one_sessions_one_week_left,
            two_sessions_two_week_left: filterObj?.two_sessions_two_week_left,
            no_payment: filterObj?.no_payment,
            partial_paid: filterObj?.partial_paid,
          },
        }
      } else {
        counsellorObj = {
          value: sortedList[0].id,
          label: sortedList[0].name,
          data: {
            id: sortedList[0].id,
            name: sortedList[0].name,
            document_submitted_on_status:
              sortedList[0].document_submitted_on_status,
            request_status: sortedList[0].request_status,
            approval_status: sortedList[0].approval_status,
            one_sessions_one_week_left:
              sortedList[0].one_sessions_one_week_left,
            two_sessions_two_week_left:
              sortedList[0].two_sessions_two_week_left,
            no_payment: sortedList[0].no_payment,
            partial_paid: sortedList[0].partial_paid,
          },
        }
      }
      onCounselorChange(counsellorId || sortedList[0].id)
      setValue(counsellorObj)
    }
  }, [options, counsellorChange])

  React.useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpen])

  const valueShowWithLegends = e => {
    return isDirectClient ? (
      <div className="d-flex align-items-center gap-1 default-search-input">
        <span className={style.label}>{e?.label}</span>
        {e?.data?.approval_status === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Used session > approved session/expiry date met"}
            arrow
          >
            <img src={noApproval} width="20px" height="20px" />
          </Tooltip>
        )}
        {e?.data?.document_submitted_on_status === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Paperwork Got Submitted"}
            arrow
          >
            <img src={submitted} width="20px" height="20px" />
          </Tooltip>
        )}
        {e?.data?.request_status === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Request to activate/deactivate"}
            arrow
          >
            <img src={activatedDeactivated} width="20px" height="20px" />
          </Tooltip>
        )}
        {e?.data?.two_sessions_two_week_left === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Session Expire in 2 week/ 2 Session Left"}
            arrow
          >
            <img src={twoSessionOrWeek} width="20px" height="20px" />
          </Tooltip>
        )}
        {e?.data?.one_sessions_one_week_left === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Session Expire in 1 week/ 1 Session Left"}
            arrow
          >
            <img src={oneSessionOrWeek} width="20px" height="20px" />
          </Tooltip>
        )}
        {e?.data?.no_payment === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Used session > Charged session"}
            arrow
          >
            <img src={notPaid} width="20px" height="20px" />
          </Tooltip>
        )}
        {e?.data?.partial_paid === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Partially Paid"}
            arrow
          >
            <img src={partiallyPaid} width="20px" height="20px" />
          </Tooltip>
        )}
      </div>
    ) : (
      <div className="d-flex align-items-center gap-1 default-search-input">
        <span className={style.label}>{e?.label}</span>
        {e?.data?.request_status === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Request to activate/deactivate"}
            arrow
          >
            <img src={activatedDeactivated} width="20px" height="20px" />
          </Tooltip>
        )}
        {e?.data?.no_payment === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Used session > Charged session"}
            arrow
          >
            <img src={notPaid} width="20px" height="20px" />
          </Tooltip>
        )}
        {e?.data?.partial_paid === true && (
          <Tooltip
            placement="right"
            className={style.tooltip}
            title={"Partially Paid"}
            arrow
          >
            <img src={partiallyPaid} width="20px" height="20px" />
          </Tooltip>
        )}
      </div>
    )
  }

  return (
    isVisible && (
      <Dropdown
        isOpen={isOpen}
        ref={ref}
        onClose={() => setIsOpen(false)}
        target={
          <Button
            className={
              isHovered
                ? `${classes.selectHoveredContainer} custom-dropdown-btn-styling`
                : `${classes.selectContainer} custom-dropdown-btn-styling`
            }
            onClick={() => setIsOpen(prev => !prev)}
            isselected={isOpen.toString()}
          >
            <div className="custom-dropdown-p0p8">
              {value ? (
                valueShowWithLegends(value)
              ) : (
                <div className={classes.placeholder}>{`Select a ${
                  isClientDropdown ? "Client" : "Counsellor"
                }`}</div>
              )}
            </div>
            <div className="btn-app-list-f-24">
              <i
                className={classNames(
                  "uil-angle-down custom-dropdown-p0p8",
                  classes.dropdownIndicator,
                  isOpen ? classes.dropdownIndicatorActive : null
                )}
              ></i>
            </div>
          </Button>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={newValue => {
            onCounselorChange(newValue.value)
            setValue(newValue)
            setIsOpen(false)
          }}
          options={sortedArray.map(option => ({
            value: option.id,
            label: option.name,
            data: option,
          }))}
          formatOptionLabel={option => valueShowWithLegends(option)}
          placeholder={`Search a ${isClientDropdown ? "Client" : "Counsellor"}`}
          styles={{
            control: (provided, state) => ({
              ...provided,
              width: "calc(100% - 16px)",
              margin: 8,
              borderColor:
                state.isFocused && customColor && customColor?.primary_color,
              boxShadow:
                state.isFocused &&
                customColor &&
                `0 0 5px ${hexToRgba(customColor?.primary_color, 1)}`,
              "&:hover": {
                boxShadow:
                  state.isFocused &&
                  customColor &&
                  `0 0 5px ${hexToRgba(customColor?.secondary_color, 1)}`,
              },
            }),
            menu: () => ({
              boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
            }),
          }}
          tabSelectsValue={false}
          value={value}
        />
      </Dropdown>
    )
  )
}

CustomDropdown.defaultProps = {
  options: [],
}

CustomDropdown.propTypes = {
  onCounselorChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  isVisible: PropTypes.bool.isRequired,
}

export default CustomDropdown

const Menu = props => {
  const classes = useStyles()
  return <div className={classes.menuContainer} {...props} />
}

const Blanket = props => <div className="custom-dropdown-blanket" {...props} />

const Dropdown = React.forwardRef(
  ({ children, isOpen, target, onClose }, ref) => {
    const classes = useStyles()
    return (
      <div className={classes.container} ref={ref}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
      </div>
    )
  }
)

const DropdownIndicator = props => {
  ;<components.DropdownIndicator {...props}>
    <i className="uil-search font-size-18"></i>
  </components.DropdownIndicator>
}
