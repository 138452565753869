import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import style from "./style.module.scss"
import { useSelector } from "react-redux"
import TableInput from "../../components/Common/TableInput"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import moment from "moment"
const ExpandHtml = ({
  row,
  expandedData,
  onhandleSuccess,
  onhandleCancle,
  handleDelete,
  handleOnExpand,
  setIsEdit,
  handleMouseEnter,
  handleMouseLeave,
  onTableChange,
  handleOutstaningList,
  isEdit,
}) => {
  const userRole = useSelector(state => state.Login?.user?.user?.role)
  const expandColumns = [
    {
      text: "",
      dataField: "checkbox",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            <input
              type="checkbox"
              data-title={row.id}
              onClick={e => handleOutstaningList(e, row, true)}
            />
          </span>
        )
      },
      hidden: userRole.role_name == "Admin" ? false : true,
      editable: false,
    },
    {
      text: "Description",
      dataField: "type",
      width: 150,
      formatter: (cell, row) => {
        return <span>{cell}</span>
      },
      editable: false,
    },
    {
      text: "Date",
      dataField: "date",
      width: 150,
      formatter: (cell, row) => {
        return <span>{row.date && moment(row.date).format("DD-MMM-YYYY")}</span>
      },
      editable: false,
    },
    {
      text: "Rate",
      dataField: "price",
      formatExtraData: { columnId: "price" },
      width: 150,
      editable: false,
      formatter: (cell, row, index, field) => {
        return (
          <>
            {isEdit && isEdit[row.id] ? (
              <TableInput
                onhandleSuccess={onhandleSuccess}
                onhandleCancle={onhandleCancle}
                row={row}
                defaultValue={Number(cell).toFixed(2)}
                index={index}
              />
            ) : (
              <span
                className="d-flex"
                onClick={e => {
                  let currentTableId = e.target
                    .closest(".expandedTable")
                    .getAttribute("data-key")
                  return setIsEdit(prev => ({
                    ...prev,
                    ...{
                      [row.id]: true,
                      [`parentId_${currentTableId}`]: currentTableId,
                    },
                  }))
                }}
              >
                ${Number(cell).toFixed(2)}
                {row.top_up_amount
                  ? ` ($${Number(row.top_up_amount).toFixed(2)})`
                  : ""}
                <i
                  className={`mdi mdi mdi-pencil pointer ${
                    userRole.role_name == "Admin" ? "" : "hidden"
                  }`}
                ></i>
              </span>
            )}
          </>
        )
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {},
      },
    },
    {
      text: "Counsellor Rate",
      dataField: "counsellor_rate",
      width: 150,
      editable: false,
      formatter: (cell, row) => {
        return <span>${Number(cell).toFixed(2)}</span>
      },
    },
    {
      text: "GST",
      dataField: "gst",
      width: 150,
      formatter: (cell, row) => {
        return <span>{Number(cell).toFixed(2)}</span>
      },
      editable: false,
    },
    {
      text: "Total",
      dataField: "total",
      width: 150,
      formatter: (cell, row) => {
        return <span>${Number(cell).toFixed(2)}</span>
      },
      editable: false,
    },
    {
      text: "Action",
      dataField: "action",
      width: 150,
      align: "center",
      hidden: userRole.role_name == "Admin" ? false : true,
      formatter: (cell, row) => {
        return (
          <div data-title={row.id} onClick={e => handleDelete(e, row)}>
            <i className="mdi mdi mdi-delete pointer invoice-list-fsize-20"></i>
          </div>
        )
      },
      editable: false,
    },
  ]
  return (
    <div className="expand">
      <div className={`w-100 ${style.textRight}`}>
        <button
          className={`btn mb-2 ${style.btnPurple}`}
          onClick={() => handleOnExpand(row, false)}
        >
          Close
        </button>
      </div>
      <Row>
        <Col className={`col-12 expandedTable  ${row.id}`} data-key={row.id}>
          <Card className="rounded-0 light-green-bg-card-table">
            <CardBody className="p-0  table-custom-redesign">
              <Table
                data={(expandedData && expandedData[row.id]) || []}
                columns={expandColumns}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                noDataIndication={"No Data Found"}
                isLoader={true}
                loading={expandedData && expandedData[row.id] ? false : true}
                isPagination={false}
                keyField={row.id}
                isSearch={false}
                isStriped={false}
                isHover={false}
                isBordereded={false}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  onStartEdit: (
                    row,
                    column,
                    rowIndex,
                    columnIndex,
                    newValue
                  ) => {
                    
                  },
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                    
                  },
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    
                  },
                })}
                onTableChange={onTableChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default ExpandHtml
