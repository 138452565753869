import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
import { useLazyQuery, useMutation } from "@apollo/client"
import Select from "react-select"

import {
  GET_ALL_TIMEZONE,
  GET_APPOINTMENT_BY_TYPE,
  GET_COUNSELLOR_BY_APPOINTMENTTYPE,
  GET_AVAIL_TIME,
  GET_MONTHLY_AVAILABLITY,
  GET_FORM_FIELDS,
  GET_AVAILABLITY_FILTER,
  CREATE_APPOINTMENT,
  CANCEL_APPOINTMENT,
  GET_APPOINTMENT,
  GET_COUNSELORS,
  GET_BLOCK_TIMESLOT,
  GET_AVAILABLE_ROOMS,
  CREATE_ADMIN_USER,
  GET_APPOINTMENT_TYPE,
  CHECK_PAYMENT_METHOD,
  LINKED_ACCOUNT_STATUS,
} from "./gql/Gql"
import { useHistory, useParams, Link } from "react-router-dom"
import { FocusError } from "focus-formik-error"
import Switch from "react-switch"
import { toast } from "react-toastify"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import style from "./style.module.scss"
import "react-toastify/dist/ReactToastify.css"
import * as Yup from "yup"
import { DateTime } from "luxon"
import { useFormik } from "formik"
import { useSelector } from "react-redux"
import CustomSelect from "../../components/CustomSelect"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import PhoneNoField from "../Counselor/PhoneNoField"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import moment from "moment-timezone"
import { GET_USERS, GET_USER, GET_USER_BY_USER } from "../User/gql/userGql"
import { GET_PAYMENT_OPTIONS } from "../Invoicing-Receipt-Management/InvoicingManagement/components/gql"
import PageNotFound from "../404"
import { debounce } from "lodash"
const CreateNewAppointment = () => {
  let { userId } = useParams()
  const decodedId = userId && atob(userId)
  userId = decodedId
  let { calendarId } = useParams()
  const decodedCounsellorId = calendarId && atob(calendarId)
  calendarId = decodedCounsellorId
  let history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)
  const [getAppointmentType, appointmentTypeRes] = useLazyQuery(
    GET_APPOINTMENT_BY_TYPE,
    { fetchPolicy: "no-cache" }
  )
  const [getCounsellorList, getCounsellorListRes] = useLazyQuery(
    GET_COUNSELORS,
    { fetchPolicy: "no-cache" }
  )

  const [getTimezone, timezoneRes] = useLazyQuery(GET_ALL_TIMEZONE, {
    fetchPolicy: "no-cache",
  })
  const [getFields, getFieldsRes] = useLazyQuery(GET_FORM_FIELDS, {
    fetchPolicy: "no-cache",
  })
  const [getTimeSlots, timeSlotsRes] = useLazyQuery(GET_AVAIL_TIME, {
    fetchPolicy: "no-cache",
  })
  const [getBlockOffSlots, getBlockOffSlotsRes] = useLazyQuery(
    GET_BLOCK_TIMESLOT,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getMonthlyAvail, getMonthlyAvailRes] = useLazyQuery(
    GET_MONTHLY_AVAILABLITY,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getAvailabilityFilter, getAvailabilityFilterData] = useLazyQuery(
    GET_AVAILABLITY_FILTER,
    { fetchPolicy: "no-cache" }
  )

  const [getUserList, listResponse] = useLazyQuery(GET_USERS, {
    fetchPolicy: "no-cache",
  })
  const [getUserDetail, details] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  })

  const [getPaymentOpt, getPaymentOptList] = useLazyQuery(GET_PAYMENT_OPTIONS, {
    fetchPolicy: "no-cache",
  })

  const [getAvailableRoomsList, getAvailableRoomsListRes] = useLazyQuery(
    GET_AVAILABLE_ROOMS,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getAppointmentByIdType, getAppointmentByIdTypeRes] = useLazyQuery(
    GET_APPOINTMENT_TYPE,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [createAppointmentMutation, createAppointmentRes] =
    useMutation(CREATE_APPOINTMENT)

  const [createAdminUserMutation, createAdminUserRes] =
    useMutation(CREATE_ADMIN_USER)

  const [cancelAppointmentMutation, cancelAppointmentRes] =
    useMutation(CANCEL_APPOINTMENT)

  const [getAppointmentDetail, appointmentResponse] = useLazyQuery(
    GET_APPOINTMENT,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [selectedAppointment, setSelectedAppointment] = useState({})
  const [allTImeSlot, setAllTImeSlot] = useState()
  const [isoDate, setIsoDate] = useState()
  const [submitDisable, setSubmitDisable] = useState(false)
  const [isoTime, setIsoTime] = useState()
  const [selectedDate, setSelectedDate] = useState("")
  const [selectedSlot, setSelectedSlot] = useState("")
  const [breakTime, setBreakTime] = useState(0)
  const [selectedCounsellor, setSelectedCounsellor] = useState({})
  const [timeZoneList, setTimeZoneList] = useState([])
  const [timeSlotOptions, setTimeSlotsOptions] = useState([])
  const [inputElements, setInputElements] = useState([])
  const [modal, setModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [appointmentDetails, setAppointmentDetails] = useState({})
  const [continueSlot, setContinueSlot] = useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState()
  const [counsellorList, setCounsellorList] = useState([])
  const [timeSlotList, setTimeSlotList] = useState([])
  const [durationSelected, setDurationSelected] = useState("")
  const [timeSlots, setTimeSlots] = useState([])
  const [recHeader, setRecHeader] = useState()
  const [dayRepeat, setDayRepeat] = useState()
  const [repeateTImes, setRepeateTImes] = useState()
  const [dateFilterList, setDateFilterList] = useState([])
  const [dateFilterListCalendar, setDateFilterListCalendar] = useState([])
  const [availabilityData, setAvailabilityData] = useState([])
  const [addRecurringList, setAddRecurringList] = useState(false)
  const [userFirstNameOption, setUserFirstNameOption] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [hideCreateBtn, setHideCreateBtn] = useState(false)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [userFieldDisabled, setUserFieldDisabled] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState()
  const [responseDataList, setResponseDataList] = useState()
  const [intakeFormName, SetIntakeFormName] = useState()
  const [appointmentData, setAppointmentData] = useState()
  const [confirmData, setConfirmData] = useState()
  const [confirmDateTime, setConfirmDateTime] = useState()
  const [isRoomsAvailable, setIsRoomsAvailable] = useState(false)
  const [blockTimeslots, setBlockTimeslots] = useState([])
  const [blockTimeslotsList, setBlockTimeslotsList] = useState([])
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState("")
  const [yesNoOpt, setYesNoOpt] = useState([
    { value: "true", name: "Yes", id: "true" },
    { value: "false", name: "No", id: "false" },
  ])
  const [isSwitchOn, setIsSwitchOn] = useState(false)
  const [isUserSwitchOn, setIsUserSwitchOn] = useState(false)
  const [clientData, setClientData] = useState(true)
  const [isRoomSwitchOn, setIsRoomSwitchOn] = useState(false)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [roomList, setRoomList] = useState([])
  const [roomArr, setRoomArr] = useState([])
  const [showTooltip, setShowTooltip] = useState(false)

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isInPerson, setIsInPerson] = useState(true)

  const [users, setUsers] = useState([])
  const [getAllRoles, setGetAllRoles] = useState([])
  const [getAllOrg, setGetAllOrgs] = useState([])
  const [isUserAlreadyExist, setIsUserAlreadyExist] = useState(false)
  const [alreadyUserId, setAlreadyUserId] = useState(null)
  const [coupleClients, setCoupleClients] = useState([])

  const [getEmailByRole, getEmailByRoleRes] = useLazyQuery(GET_USER_BY_USER, {
    fetchPolicy: "no-cache",
  })

  const handleCheckEmail = e => {
    const email = e.target.value.toLowerCase().replace(/\s/g, "")
    function isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailPattern.test(email)
    }
    if (isValidEmail(email)) {
      validation.setFieldValue("email", email)
      getEmailByRole({
        variables: {
          argumentsInput: {
            email: email,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (
      getEmailByRoleRes.data?.getUserByEmail?.status &&
      getEmailByRoleRes.data?.getUserByEmail?.code === 200
    ) {
      const isExist = getEmailByRoleRes.data?.getUserByEmail?.data?.is_exist
      if (isExist) {
        setIsUserAlreadyExist(true)
        const userData = getEmailByRoleRes.data?.getUserByEmail?.data?.user
        getUserDetail({
          variables: {
            argumentsInput: {
              id: parseInt(userData?.id),
            },
          },
        })
        let data = {
          first_name: userData?.first_name ? userData?.first_name?.trim() : "",
          last_name: userData?.last_name ? userData?.last_name?.trim() : "",
          email: userData?.email ? userData?.email?.trim() : "",
          mobile_number: userData?.mobile_number || "",
        }
        validation.setFieldValue("last_name", data.last_name)
        validation.setFieldValue("first_name", data.last_name)
        validation.setFieldValue("email", data.email)
        validation.setFieldValue("mobile_number", data.mobile_number)
        setAlreadyUserId(userData?.id)
      }
    } else {
      setIsUserAlreadyExist(false)
    }
  }, [getEmailByRoleRes.data])
  const isAlreadyExistsFun = useCallback(debounce(handleCheckEmail, 1000), [])

  const [initialValues, setInitialValues] = useState({
    appointment_type: "",
    counsellor: "",
    timezone: "",
    email: "",
    first_name: "",
    last_name: "",
    date: "",
    phone: "",
    selectedSlot: "",
    payment_type: "",
    notes: "",
  })

  const repeatOptions = [
    { value: "weekly", label: `Every ${timeSlotList[0]?.week_day}` },
    { value: "biweekly", label: `Every Other ${timeSlotList[0]?.week_day}` },
    { value: "thirdweekly", label: `Every Third ${timeSlotList[0]?.week_day}` },
    { value: "onceInMonth", label: "Once in a Month" },
    { value: "daily", label: "Daily" },
  ]

  const repeateNumber = Array.from({ length: 24 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }))

  const [appointmentTypeList, setAppointmentTypeList] = useState([])
  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      appointment_type: Yup.object().test(
        "notEmptyArr",
        "Please select appointment type",
        value => {
          return value && (value == "undefined" || value.value !== null)
        }
      ),
      counsellor: Yup.string().required("Please select counsellor"),
      timezone: Yup.string().required("Please select timezone"),
      email: Yup.string()
        .email("Enter valid email address")
        .required("Email address is required"),
      first_name: Yup.string().required("First name must be required "),
      last_name: Yup.string().required("Last name must be required"),
      date: Yup.string().required("Date must be required"),
      selectedSlot: Yup.string().required("Select time slot for appointment"),
    }),
    onSubmit: values => {
      // setIsSubmitted(true);
      const timezone = values.timezone
      let utc_datetime = []
      let date_without_utc = []
      let end_time = ""
      let start_time = ""
      let formattedStartDate = ""
      let formattedErrDate = []
      const counsellorTimeZone = calendarId
        ? counsellorList[1]?.timezone
        : selectedCounsellor?.timezone

      if (dateFilterList.length > 0) {
        const convertToUTC = (date, time, timezone) => {
          const dateTime = moment.tz(
            `${date} ${time}`,
            "YYYY-MM-DD HH:mm:ss",
            timezone
          )

          return dateTime.utc().format("YYYY-MM-DD HH:mm:ss")
        }

        let dateFilterDate = dateFilterListCalendar.filter(
          elem => elem.is_available === true
        )
        utc_datetime = dateFilterDate.map(item =>
          convertToUTC(item.date, item.start_time, counsellorTimeZone)
        )

        date_without_utc = dateFilterDate.map(elem => {
          return `${elem.date} ${elem.start_time}`
        })
        const formatTime = time => {
          const [hours, minutes, seconds] = time?.split(":").map(Number)
          const period = hours < 12 ? "AM" : "PM"
          const formattedHours = hours % 12 || 12
          return `${formattedHours}:${minutes
            .toString()
            .padStart(2, "0")} ${period}`
        }
        start_time = formatTime(dateFilterDate[0]?.start_time)
        end_time = formatTime(dateFilterDate[0]?.end_time)
        const cDate = new Date(dateFilterDate[0]?.date)

        // //formated time
        const options = { year: "numeric", month: "long", day: "numeric" }
        formattedStartDate = cDate.toLocaleDateString("en-US", options)

        formattedErrDate = dateFilterDate.map(elem => {
          const cDate = new Date(elem.date)
          //formated time
          const options = { year: "numeric", month: "long", day: "numeric" }
          return cDate.toLocaleDateString("en-US", options)
        })
      }

      if (selectedTimeSlot) {
        const [startTime, endTime] = selectedSlot.split("-")
        const formattedDate = selectedDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        })

        const startDateTime = moment.tz(
          `${formattedDate} ${startTime}`,
          "MMM DD, YYYY h:mm A",
          counsellorTimeZone
        )
        utc_datetime = [startDateTime.utc().format("YYYY-MM-DD HH:mm:ss.SSS")]
        end_time = endTime
        start_time = startTime
      }

      const currentDate = new Date()

      //formated time
      const options = { year: "numeric", month: "long", day: "numeric" }
      const formattedDate = currentDate.toLocaleDateString("en-US", options)

      //utc format date time

      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, "0")
      const day = String(currentDate.getDate()).padStart(2, "0")
      const hours = String(currentDate.getHours()).padStart(2, "0")
      const minutes = String(currentDate.getMinutes()).padStart(2, "0")
      const seconds = String(currentDate.getSeconds()).padStart(2, "0")
      const milliseconds = String(currentDate.getMilliseconds()).padStart(
        3,
        "0"
      )

      const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`

      //formated duration

      let formatDuration = null

      if (userId) {
        formatDuration = appointmentDetails.duration
      } else {
        formatDuration = selectedAppointment.duration
      }

      function findObjectValueById(arr, obj) {
        const result = []

        for (const item of arr) {
          if (item.id in obj) {
            result.push({
              field_id: JSON.stringify(item.id),
              name: item.name,
              value: obj[item.id],
              id: JSON.stringify(item.id),
            })
          }
        }
        return result
      }

      const roomIdArr = isInPerson
        ? roomArr.length > 1
          ? roomArr
              .map(elem => elem?.rooms[0]?.id || undefined)
              .filter(id => id !== undefined)
          : roomArr[0]?.rooms[0]?.id
          ? [roomArr[0]?.rooms[0]?.id]
          : []
        : []

      let multiClients = null
      let multiClientsIds = null
      if (coupleClients.length > 0) {
        const allClient = coupleClients.map(elem => {
          return {
            first_name: elem?.first_name,
            email: elem?.email,
            last_name: elem?.last_name,
            phone: elem?.phone,
            id: elem?.selectedClient?.value,
          }
        })
        const lastSelectedClient = {
          first_name: values?.first_name,
          email: values?.email,
          last_name: values?.last_name,
          phone: values?.phone,
          id: selectedUser?.value,
        }

        allClient.push(lastSelectedClient)
        multiClients = allClient
      }

      if (multiClients?.length > 0) {
        multiClientsIds = multiClients.map(elem => {
          return elem.id
        })
      }

      const emailAddresses = multiClients
        ? multiClients.map(item => item.email)
        : []
      const concatenatedEmails = emailAddresses.join(", ")

      const formData = findObjectValueById(responseDataList, values)
      const appointment_data = {
        amount_paid: "0",
        appointment_type_id: selectedAppointment.id,
        calendar: calendarId
          ? selectedCounsellor.first_name
          : selectedCounsellor.name,
        calendar_id: calendarId ? parseInt(calendarId) : selectedCounsellor.id,
        canceled: false,
        date: selectedTimeSlot?.date || formattedStartDate,
        date_created: formattedDate,
        datetime: formattedTime,
        duration: formatDuration,
        email: multiClients ? multiClients[0]?.email : values.email,
        participants: selectedRoomSize?.value ? selectedRoomSize?.value : 2,
        end_time: end_time,
        weekday_without_utc: moment(
          selectedTimeSlot?.date || formattedStartDate
        ).format("dddd"),
        first_name: multiClients
          ? multiClients[0]?.first_name
          : values.first_name,
        last_name: multiClients ? multiClients[0]?.last_name : values.last_name,
        notes: values.notes,
        paid: "no",
        payment_type_id: selectedPayment?.id ? selectedPayment?.id : "",
        phone: values?.phone?.length > 5 ? values?.phone : null,
        price: selectedAppointment.price ? selectedAppointment.price : "0",
        scheduled_by: "",
        time: start_time,
        timezone: timezone,
        type: selectedAppointment.name,
        user_id: multiClients ? multiClients[0]?.id : selectedUser.value,
        utc_datetime: utc_datetime,
        participant_arr: multiClients ? multiClientsIds : [selectedUser.value],
        emails: multiClients ? concatenatedEmails : null,
        forms: [
          {
            name: values.first_name,
            form_values: formData,
          },
        ],
        date_arr: formattedErrDate,
        rooms: roomIdArr,
        date_without_utc_arr: date_without_utc,
      }

      const update_data = {
        id: parseFloat(userId),
        utc_datetime: utc_datetime.join(),
        room_id: roomIdArr[0] || null,
        date: formattedErrDate[0],
        weekday_without_utc: moment(formattedErrDate[0]).format("dddd"),
        date_without_utc: date_without_utc[0],
        time: start_time,
        end_time: end_time,
      }
      if (!userId) {
        if (appointment_data.payment_type_id === "") {
          toast.error("Please select Payment type")
        }
        if (appointment_data.utc_datetime.length > 0) {
          handleSubmit(appointment_data)
        } else {
          toast.error("Please select time slot properly")
        }
      } else {
        if (update_data.utc_datetime.length > 0) {
          handleSubmit(update_data)
        } else {
          toast.error("Please select time slot properly")
        }
      }
    },
  })

  const handleSubmit = async (values, id) => {
    setAppointmentData(values)
    if (!userId) {
      try {
        setSubmitDisable(true)
        await createAppointmentMutation({
          variables: {
            createAppointmentInput: values,
          },
        })
      } catch (e) {
        setSubmitDisable(false)
      }
    } else {
      try {
        setSubmitDisable(true)
        await cancelAppointmentMutation({
          variables: {
            updateAppointmentInput: values,
          },
        })
      } catch (e) {
        setSubmitDisable(false)
      }
    }
  }
  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(":")
    const hour = parseInt(hours)
    const period = hour >= 12 ? "PM" : "AM"
    const formattedHour = hour % 12 || 12 // Convert 0 to 12 for 12:00 AM
    return `${formattedHour}:${minutes} ${period}`
  }

  const handleCancel = async () => {
    var appointmentIds = []

    confirmData.appointments.map(elem => {
      appointmentIds.push(JSON.stringify(elem.id))
    })

    try {
      await cancelAppointmentMutation({
        variables: {
          updateAppointmentInput: {
            appointmentIds: appointmentIds,
          },
        },
      })
    } catch (e) {}
  }
  const [modalDelete, setModalDelete] = useState(false)
  function deleteToggle() {
    setModalDelete(!modalDelete)
  }

  const addToGoogle = () => {
    const appointmentIds = confirmData.appointments.map(
      appointment => `idList=${appointment.id}`
    )

    const subscriptionURL = appointmentIds.join("&")

    let url = `${process.env.REACT_APP_API_ENDPOINT}/calendar/subscribe?${subscriptionURL}`

    window.open(url)
  }

  const goBackButtuon = () => {
    const counsellor = calendarId ? calendarId : selectedCounsellor.id
    history.push(`/appointments?tab=calendar&counsellor=${counsellor}`)
  }

  React.useEffect(() => {
    if (
      cancelAppointmentRes.data &&
      cancelAppointmentRes.data.updateFromAppointment.status &&
      cancelAppointmentRes.data.updateFromAppointment.code === 200
    ) {
      setSubmitDisable(false)
      toast.success("Appointment Updated Successfully")
      setTimeout(() => {
        window.history.back()
      }, 3000)
    } else {
      if (
        cancelAppointmentRes.data &&
        cancelAppointmentRes.data.updateFromAppointment
      ) {
        toast.error(cancelAppointmentRes.data.updateFromAppointment.message)
      }
    }
  }, [cancelAppointmentRes.data])

  React.useEffect(() => {
    if (
      createAppointmentRes.data &&
      createAppointmentRes.data.createAppointment.status &&
      createAppointmentRes.data.createAppointment.code === 200
    ) {
      toast.success(createAppointmentRes.data.createAppointment.message)
      setConfirmData(createAppointmentRes.data.createAppointment.data)
      setConfirmModal(true)
      setSubmitDisable(false)
    } else {
      if (
        createAppointmentRes.data &&
        createAppointmentRes.data.createAppointment
      ) {
        toast.error(createAppointmentRes.data.createAppointment.message)
        setSubmitDisable(false)
      }
    }
  }, [createAppointmentRes.data])

  const handleDropDown = (values, field) => {
    validation.setFieldValue(field, values.id)
  }
  const handleGetVal = (e, field) => {
    validation.setFieldValue(field, e.target.value)
  }

  const handleGetCheck = (e, field) => {
    validation.setFieldValue(field, e.value)
  }

  const getMonthlyAvailFun = (calendar_id, month, year) => {
    getMonthlyAvail({
      variables: {
        argumentsInput: {
          calendar_id: calendarId ? parseInt(calendarId) : calendar_id || "",
          month: month,
          year: year,
          is_closed: true,
        },
      },
    })
  }

  const getCounsellorListFun = search => {
    getCounsellorList({
      variables: {
        argumentsInput: {
          page: 1,
          limit: 20,
          search: search || "",
          sort: "id",
          sort_dir: "DESC",
        },
      },
    })
  }

  const getAvailableRoomsFun = roomVariables => {
    getAvailableRoomsList({
      variables: {
        argumentsInput: {
          availability: roomVariables,
        },
      },
    })
  }
  const getAppointmentTypeFun = id => {
    getAppointmentByIdType({
      variables: {
        getAppointmentTypeByIdId: id,
      },
    })
  }

  useEffect(() => {
    if (
      getAppointmentByIdTypeRes?.data?.getAppointmentTypeById?.data &&
      getAppointmentByIdTypeRes?.data?.getAppointmentTypeById?.data
        ?.appointmentType
    ) {
      const isPerson =
        getAppointmentByIdTypeRes?.data?.getAppointmentTypeById?.data
          ?.appointmentType?.call_type

      if (isPerson === "in person") {
        setIsInPerson(true)
      } else {
        setIsInPerson(false)
      }
    }
  }, [getAppointmentByIdTypeRes?.data])

  useEffect(() => {
    if (
      getAvailableRoomsListRes?.data?.getAvailableRoomsForCounsellor?.data &&
      getAvailableRoomsListRes?.data?.getAvailableRoomsForCounsellor?.data
        ?.rooms
    ) {
      setRoomArr(
        getAvailableRoomsListRes?.data?.getAvailableRoomsForCounsellor?.data
          ?.rooms
      )

      //   function formatDate(dateString) {
      //     const options = { year: "numeric", month: "long", day: "numeric" }
      //     return new Date(dateString).toLocaleDateString(undefined, options)
      //   }

      //   const formattedData =
      //     getAvailableRoomsListRes.data.getAvailableRooms.data.roomResponseArr.map(
      //       item => ({
      //         ...item,
      //         override_date: formatDate(item.override_date),
      //       })
      //     )
      //   if (formattedData.length === 0) {
      //     setIsRoomsAvailable(true)
      //   } else {
      //     setIsRoomsAvailable(false)
      //   }
      //   setRoomList(formattedData)
    }
  }, [getAvailableRoomsListRes.data])

  const handleChangeRoom = (selectedOptions, overrideDate) => {
    setSelectedRooms(prevSelectedRooms => ({
      ...prevSelectedRooms,
      [overrideDate]: selectedOptions,
    }))
  }

  const getAppointmentTypeById = calendarId => {
    getAppointmentType({
      variables: {
        argumentsInput: {
          calendar_id: calendarId,
        },
      },
    })
  }

  useEffect(() => {
    if (calendarId) {
      getAppointmentTypeById(parseInt(calendarId))
      setSelectedCounsellor(userData)
      validation.setFieldValue("counsellor", calendarId)
    }
    getTimezone()
    getCounsellorListFun("")
    getTimeSlots()
    getFields()
    getUserListFun("")
    getPaymentOpt()
  }, [])
  useEffect(() => {
    const currentDate = new Date()
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const currentMonth = monthNames[currentDate.getMonth()]
    const currentYear = currentDate.getFullYear()
    if (selectedCounsellor.id) {
      getMonthlyAvailFun(selectedCounsellor.id, currentMonth, currentYear)
    }
  }, [selectedCounsellor.id])

  useEffect(() => {
    if (
      appointmentTypeRes.data &&
      appointmentTypeRes.data?.getAllAppointmentsTypeByCategory?.code == 200 &&
      appointmentTypeRes.data?.getAllAppointmentsTypeByCategory?.data
    ) {
      let options = [
        {
          name: "Select Appointment Type",
          label: "Select Appointment Type",
          value: null,
          id: null,
        },
      ]

      let data =
        appointmentTypeRes.data?.getAllAppointmentsTypeByCategory?.data.categoryTypeAppointmentTypes.map(
          item => {
            let option = {
              name: item.category || "-",
              label: item.category || "-",
            }
            let Suboption = []
            item.appointmentTypes.map(subItem => {
              Suboption.push({
                name:
                  `${subItem.name} (${subItem.duration} minutes @ $${subItem.calendar_appoinement_type_price}) - ${item.category}` ||
                  "-",
                value: subItem.id,
                id: subItem.id,
                duration: subItem.duration,
                price: subItem.calendar_appoinement_type_price,
                label: subItem.name,
                break_time: subItem.break_time,
                call_type: subItem?.call_type,
              })
            })
            option.options = Suboption
            options.push(option)
          }
        )

      setAppointmentTypeList(options)
    }
  }, [appointmentTypeRes.data])

  useEffect(() => {
    if (
      timeSlotsRes.data &&
      timeSlotsRes.data.getAvailabilityForDate?.data?.availabilities[0]
        ?.start_time
    ) {
      let data = timeSlotsRes.data.getAvailabilityForDate.data.availabilities[0]

      let options = [
        {
          name: "Select time slot",
          label: "Select time slot",
          start_time: data.start_time,
          end_time: data.end_time,
          id: data.id,
          week_day: data.week_day,
        },
      ]

      setTimeSlotList(options)
    }
  }, [timeSlotsRes.data])

  useEffect(() => {
    if (confirmData) {
      function formatDatetimeWithTimezone(utcDatetime, timeZone) {
        const inputDate = new Date(utcDatetime)

        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
          timeZone,
        }

        return inputDate.toLocaleString("en-US", options)
      }

      const formattedAppointments = confirmData.appointments.map(
        appointment => ({
          ...appointment,
          formatted_datetime: formatDatetimeWithTimezone(
            appointment.utc_datetime,
            appointmentData.timezone
          ),
        })
      )

      setConfirmDateTime(formattedAppointments)
    }

    const dateTimeInTimeZone = DateTime.now().setZone(appointmentData?.timezone)

    const abbreviation = dateTimeInTimeZone.offsetNameShort

    setTimezoneAbbreviation(abbreviation)
  }, [confirmData])

  const createUser = () => {
    let { first_name, last_name, email, phone } = validation.values
    function validateEmailEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      return emailRegex.test(email)
    }
    const isValidEmail = validateEmailEmail(email)
    const storedData = localStorage.getItem("orgId")
    const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
    let allRoles = getAllRoles
    let allOrgs = getAllOrg
    if (!allOrgs.includes(orgId)) {
      allRoles.push(3)
      allOrgs.push(orgId)
    }

    let userInput = {
      id: isUserAlreadyExist ? alreadyUserId : null,
      role_id: 3,
      email: initialValues.email
        ? initialValues.email.toLowerCase()
        : validation.values.email.toLowerCase(),
      mobile_number: phone?.length > 9 ? phone : null,
      first_name: first_name,
      last_name: last_name,
      organizationIds: allOrgs,
      roleIds: allRoles,
      is_role_editable: isUserAlreadyExist ? true : undefined,
      is_edit: isUserAlreadyExist ? true : undefined,
    }
    if (isValidEmail) {
      createAdminUserMutation({
        variables: {
          createUserInput: userInput,
        },
      })
        .then(res => {
          if (
            res?.data?.createAdminUser?.code === 200 &&
            res?.data?.createAdminUser?.data
          ) {
            let { id, first_name } = res.data.createAdminUser.data.user
            let userData = {
              label: first_name,
              name: first_name,
              value: id,
            }
            setSelectedUser(userData)
            toast.success(res?.data?.createAdminUser?.message)
            setHideCreateBtn(true)
            recheckMaymentMethod(userData)
          } else {
            toast.error(res?.data?.createAdminUser?.message)
          }
        })
        .catch(err => {
          toast.error("Something went wrong!")
        })
    } else {
      toast.error("Please enter valid email")
    }
  }

  useEffect(() => {
    let { first_name, last_name, email, phone } = validation.values
    if (first_name && last_name && email) {
      setClientData(false)
    } else {
      setClientData(true)
    }
  }, [validation.values])

  const openMapView = () => {
    const latitude = confirmData?.appointmentType?.latitude
    const longitude = confirmData?.appointmentType?.longitude
    const zoom = 15

    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}&z=${zoom}`

    window.open(mapUrl, "_blank")
  }

  useEffect(() => {
    if (
      getMonthlyAvailRes.data &&
      getMonthlyAvailRes.data?.getMonthlyAvailabilities &&
      getMonthlyAvailRes?.data?.getMonthlyAvailabilities?.data
        ?.categoryMonthWise
    ) {
      setAvailabilityData(
        getMonthlyAvailRes?.data?.getMonthlyAvailabilities?.data
          ?.categoryMonthWise
      )
    }
  }, [getMonthlyAvailRes.data])

  useEffect(() => {
    if (
      getCounsellorListRes.data &&
      getCounsellorListRes.data?.getCalendarLegend?.code == 200 &&
      getCounsellorListRes.data?.getCalendarLegend?.data
    ) {
      setCounsellorList([
        { value: "", name: "Select counsellor", label: "Select counsellor" },
        ...getCounsellorListRes.data?.getCalendarLegend?.data.calendars,
      ])
    }
  }, [getCounsellorListRes.data])

  useEffect(() => {
    if (
      timezoneRes.data &&
      timezoneRes.data?.getAllTimeZones?.code == "200" &&
      timezoneRes.data?.getAllTimeZones.data
    ) {
      let list = timezoneRes.data?.getAllTimeZones.data.allTimeZone.map(
        item => {
          return {
            name: item.display,
            id: item.zoneName,
          }
        }
      )
      setTimeZoneList(list)
      if (!userId) {
        validation.setFieldValue("timezone", "America/Vancouver")
      }
    }
  }, [timezoneRes.data])

  useEffect(() => {
    if (getFieldsRes.data) {
      let responseData

      if (
        getFieldsRes?.data?.findForms?.data?.formbuilders[0]?.data !== undefined
      ) {
        responseData = JSON.parse(
          getFieldsRes.data.findForms.data.formbuilders[0].data
        )
      }
      SetIntakeFormName(
        getFieldsRes?.data?.findForms?.data?.formbuilders[0]?.name
      )
      setResponseDataList(responseData)
      const elementsToRender = []
      if (responseData) {
        for (const field of responseData) {
          if (
            field.type === "textarea" ||
            field.type === "textbox" ||
            field.type === "address"
          ) {
            elementsToRender.push(
              <Col md="12" key={field.id}>
                <div className="mb-4 form-custom-white-inputs">
                  <Label className="form-label">
                    {field.name} {field.required && "*"}
                  </Label>
                  <Input
                    id={field.id}
                    name={field.id}
                    className="form-control"
                    type="text" // Make sure to use the appropriate type here
                    required={field.required}
                    value={validation.values && validation.values[field.id]}
                    onChange={e => handleGetVal(e, field.id)}
                  />
                  {isSubmitted
                    ? field.required &&
                      !validation?.values[field.id] &&
                      isSubmitted && (
                        <p className="text-danger mx-1 mt-2  ">
                          This field is required
                        </p>
                      )
                    : null}
                </div>
              </Col>
            )
          } else if (
            field.type === "checkboxlist" ||
            field.type === "dropdown"
          ) {
            let arrayOptions = field.options.map(e => {
              return { name: e, id: e }
            })

            elementsToRender.push(
              <Col md="12" key={field.id}>
                <div className="mb-4 form-custom-white-inputs ">
                  <Label className="form-label">
                    {" "}
                    {field.name} {field.required && "*"}
                  </Label>
                  <CustomSelect
                    className="input custom-date-picker-style-bradius"
                    options={arrayOptions}
                    isMulti={false}
                    name={field.id}
                    value={validation.values && validation.values[field.id]}
                    onChange={e => handleDropDown(e, field.id)}
                    required={field.required}
                    // error with onchange
                  />
                  {isSubmitted
                    ? field.required &&
                      !validation?.values[field.id] &&
                      isSubmitted && (
                        <p className="text-danger mx-1 mt-2 ">
                          This field is required
                        </p>
                      )
                    : null}
                </div>
              </Col>
            )
          } else if (field.type === "yesno") {
            elementsToRender.push(
              <Col md="12" key={field.id}>
                <div className="mb-4 form-custom-white-inputs ">
                  <Label className="form-label" for={field.id}>
                    {field.name} {field.required && "*"}
                  </Label>

                  <CustomSelect
                    className="input custom-date-picker-style-bradius"
                    options={yesNoOpt}
                    isMulti={false}
                    name={field.id}
                    value={validation.values && validation.values[field.id]}
                    onChange={e => handleGetCheck(e, field.id)}
                    required={field.required}
                  />
                  {isSubmitted
                    ? field.required &&
                      !validation?.values[field.id] &&
                      isSubmitted && (
                        <p className="text-danger mx-1 mt-2 ">
                          This field is required
                        </p>
                      )
                    : null}
                </div>
              </Col>
            )
          }
        }
      }

      setInputElements(elementsToRender)
    }
  }, [getFieldsRes, validation.values, isSubmitted])

  // useEffect(() => {
  //  console.log(validation.values)
  // }, [validation.values])

  const handleTimeSlots = (value, durationS) => {
    const inputDate = new Date(value)
    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, "0")
    const day = String(inputDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    const date = new Date(formattedDate)

    if (!isNaN(date.getTime())) {
      setTimeSlotsOptions([])

      getBlockOffSlots({
        variables: {
          argumentsInput: {
            date: formattedDate,
            calendar_id: calendarId
              ? parseInt(calendarId)
              : selectedCounsellor.id,
          },
        },
      }).then(res => {
        let blockoffTimeslots = res.data.getBlockOffSlots.data.block_off_slots
        setBlockTimeslotsList(blockoffTimeslots)
        const convertTimeFormat = time => {
          const [hours, minutes, seconds] = time.split(":")
          const period = hours < 12 ? "AM" : "PM"
          const formattedHours = hours % 12 || 12
          return `${formattedHours}:${minutes
            .toString()
            .padStart(2, "0")} ${period}`
        }
        const formattedData = blockoffTimeslots.map(item => ({
          ...item,
          end_time: convertTimeFormat(item.end_time),
          start_time: convertTimeFormat(item.start_time),
        }))
        setBlockTimeslots(formattedData)
      })

      getTimeSlots({
        variables: {
          argumentsInput: {
            date: formattedDate,
            calendar_id: calendarId
              ? parseInt(calendarId)
              : selectedCounsellor.id,
          },
        },
      }).then(res => {
        let availabilities = res.data.getAvailabilityForDate.data.availabilities
        let options = []
        if (availabilities.length === 1) {
          // Only one availability slot, process it directly
          const availability = availabilities[0]
          const { id, week_day, start_time, end_time } = availability

          let currentTime = new Date(`${formattedDate}T${start_time}`)
          const endTime = new Date(`${formattedDate}T${end_time}`)
          const duration = durationS * 60 * 1000

          const timeSlots = []
          const breakTimeMin = breakTime * 60 * 1000

          while (currentTime < endTime) {
            const slotEndTime = new Date(currentTime.getTime() + duration)
            const endTimeWithBreak = new Date(
              currentTime.getTime() + duration + breakTimeMin
            )

            if (slotEndTime > endTime) {
              break
            }

            const startTimeFormatted = currentTime.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })

            const endTimeFormatted = slotEndTime.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })

            timeSlots.push(`${startTimeFormatted}-${endTimeFormatted}`)
            currentTime = endTimeWithBreak
          }
          // Create an option for each time slot
          timeSlots.forEach(timeSlot => {
            options.push({
              name: `${timeSlot}`,
              label: `${timeSlot}`,
              start_time: start_time,
              end_time: end_time,
              id: id,
              week_day: week_day,
            })
          })
        } else {
          // Multiple availability slots, process each one
          availabilities.forEach(availability => {
            const { id, week_day, start_time, end_time } = availability

            let currentTime = new Date(`${formattedDate}T${start_time}`)
            const endTime = new Date(`${formattedDate}T${end_time}`)
            const duration = durationS * 60 * 1000
            const breakTimeMin = breakTime * 60 * 1000
            const timeSlots = []

            while (currentTime < endTime) {
              const slotEndTime = new Date(currentTime.getTime() + duration)
              const endTimeWithBreak = new Date(
                currentTime.getTime() + duration + breakTimeMin
              )

              if (slotEndTime > endTime) {
                break
              }

              const startTimeFormatted = currentTime.toLocaleTimeString(
                "en-US",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )

              const endTimeFormatted = slotEndTime.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })

              timeSlots.push(`${startTimeFormatted}-${endTimeFormatted}`)
              currentTime = endTimeWithBreak
            }

            // Create an option for each time slot
            timeSlots.forEach(timeSlot => {
              options.push({
                name: `${timeSlot}`,
                label: `${timeSlot}`,
                start_time: start_time,
                end_time: end_time,
                id: id,
                week_day: week_day,
              })
            })
          })
        }
        // Set the time slot options
        setTimeSlotsOptions(options)
      })
    }
  }
  const [roomSizeList, setRoomSizeList] = useState([
    {
      label: "Individual Counselling",
      name: "Individual Counselling",
      value: 2,
      id: 1,
    },
    {
      label: "Couples/Family Counselling",
      name: "Couples/Family Counselling",
      value: 10,
      id: 2,
    },
    {
      label: "Group Counselling",
      name: "Group Counselling",
      value: 50,
      id: 3,
    },
  ])
  const [selectedRoomSize, setSelectedRoomSize] = useState(roomSizeList[0])
  const handleRoomSize = value => {
    setSelectedRoomSize(value)
  }

  const handleAppointmentClick = (value, validation) => {
    setSelectedAppointment(value)

    setDurationSelected(value.duration)
    validation.setFieldValue("selectedSlot", "")

    setSelectedSlot([])
    validation.setFieldValue("appointment_type", value)

    if (selectedDate && value.duration) {
      handleTimeSlots(selectedDate, value.duration)
    }

    setBreakTime(value.break_time)
  }
  const handleDateClick = (value, validation) => {
    setSelectedDate(value)
    const formattedStartDate = value.toLocaleString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour12: true,
    })
    setRecHeader(formattedStartDate)
    validation.setFieldValue("date", value)
    validation.setFieldValue("selectedSlot", "")
    handleTimeSlots(value, durationSelected)
  }
  const handleMonthChange = value => {
    const month = value.toLocaleString("en-US", { month: "long" })
    const year = value.getFullYear()
    getMonthlyAvailFun(selectedCounsellor.id, month, year)
  }
  const handleCounsellorChange = (value, validation) => {
    validation.setFieldValue("counsellor", value.id)
    setSelectedCounsellor(value)
    getAppointmentTypeById(value.id)
  }

  useEffect(() => {
    const hasMScAndSupervisedBy = str => {
      return (
        str?.includes("MA") &&
        str?.includes("Supervised by Ofir Vaisman RCC #11281")
      )
    }
    if (selectedCounsellor) {
      if (hasMScAndSupervisedBy(selectedCounsellor?.name)) {
        const groupRoomSize = {
          label: "Group Counselling",
          name: "Group Counselling",
          value: 50,
          id: 3,
        }
        setRoomSizeList([groupRoomSize])
        setSelectedRoomSize(groupRoomSize)
      } else {
        const groupRoomSize = [
          {
            label: "Individual Counselling",
            name: "Individual Counselling",
            value: 2,
            id: 1,
          },
          {
            label: "Couples/Family Counselling",
            name: "Couples/Family Counselling",
            value: 10,
            id: 2,
          },
          {
            label: "Group Counselling",
            name: "Group Counselling",
            value: 50,
            id: 3,
          },
        ]
        setRoomSizeList(groupRoomSize)
        setSelectedRoomSize(groupRoomSize[0])
      }
    }
  }, [validation?.values?.counsellor, selectedCounsellor])

  const [customTimeSlot, setCustomTimeSlot] = useState(false)
  const [customStartTime, setCustomStartTime] = useState(null)
  const [customEndTime, setCustomEndTime] = useState(null)
  const toggleCustomTimeslot = () => {
    setCustomTimeSlot(!customTimeSlot)
    if (validation.values.selectedSlot && !customTimeSlot) {
      const [startTimeString, endTimeString] =
        validation.values.selectedSlot.split("-")
      const formattedStartTime = moment(startTimeString, "hh:mm A").format(
        "HH:mm"
      )
      setCustomStartTime(formattedStartTime + ":00")
    }
  }

  const handleCustomTimeSelect = e => {
    setCustomStartTime(e.target.value + ":00")
  }

  const [showOverrideWarning, setShowOverrideWarning] = useState(false)
  useEffect(() => {
    if (customTimeSlot && customStartTime) {
      setIsRoomSwitchOn(false)
      const startTimeMoment = moment(customStartTime, "HH:mm:ss")
      const startTime = startTimeMoment.format("h:mm A")
      const endTime = startTimeMoment
        .add(selectedAppointment?.duration, "minutes")
        .format("h:mm A")
      const timeRangeString = `${startTime}-${endTime}`
      setCustomEndTime(endTime)
      setIsRoomSwitchOn(false)
      setRepeateTImes(1)
      validation.setFieldValue("selectedSlot", timeRangeString)
      setSelectedSlot(timeRangeString)
      setAddRecurringList(false)
      setDateFilterList([])
      if (validation.values.timezone) {
        setContinueSlot(true)
      }
      setSelectedTimeSlot()

      const givenTimeMoment = moment(customStartTime, "HH:mm:ss")
      const givenEndTimeMoment = moment(endTime, "HH:mm:ss")
      for (let slot of blockTimeslotsList) {
        const startTime = moment(slot.start_time, "HH:mm:ss")
        const endTime = moment(slot.end_time, "HH:mm:ss")
        if (
          givenTimeMoment.isBetween(startTime, endTime, null, "[]") ||
          givenEndTimeMoment.isBetween(startTime, endTime, null, "[]")
        ) {
          setShowOverrideWarning(true)
          break
        } else {
          setShowOverrideWarning(false)
        }
      }
    } else {
      setShowOverrideWarning(false)
    }
  }, [customStartTime])

  const handleTimeSlot = (value, validation) => {
    setIsRoomSwitchOn(false)
    setRepeateTImes(1)
    validation.setFieldValue("selectedSlot", value)
    setSelectedSlot(value)
    setAddRecurringList(false)
    setDateFilterList([])
    if (validation.values.timezone) {
      setContinueSlot(true)
    }
    setSelectedTimeSlot()
  }

  useEffect(() => {
    let formatedTimeSlots = timeSlotOptions.map((slot, index) => {
      return { name: slot.name, id: index }
    })

    function doTimeSlotsOverlap(slot1, slot2) {
      const [start1, end1] = slot1.name.split("-")
      const [start2, end2] = [slot2.start_time, slot2.end_time]
      const start1Time = new Date(`01/01/2023 ${start1}`).getTime()
      const end1Time = new Date(`01/01/2023 ${end1}`).getTime()
      const start2Time = new Date(`01/01/2023 ${start2}`).getTime()
      const end2Time = new Date(`01/01/2023 ${end2}`).getTime()

      return start1Time < end2Time && start2Time < end1Time
    }
    const filteredSlots = formatedTimeSlots.filter(timeslot => {
      return !blockTimeslots.some(removeslot =>
        doTimeSlotsOverlap(timeslot, removeslot)
      )
    })

    setTimeSlots(filteredSlots)
  }, [blockTimeslots, timeSlotOptions])

  const formatTimeSlot = () => {
    const inputDate = new Date(selectedDate)
    const getYear = inputDate.getFullYear()
    const getMonth = String(inputDate.getMonth() + 1).padStart(2, "0")
    const getDay = String(inputDate.getDate()).padStart(2, "0")
    const formattedDt = `${getYear}-${getMonth}-${getDay}`

    const convertTimeTo24HourFormat = time => {
      const timeParts = time.split(" ")
      const meridian = timeParts[1]
      const timeWithoutMeridian = timeParts[0]
      setAddRecurringList(false)

      const [hours, minutes] = timeWithoutMeridian.split(":")
      let hour = parseInt(hours, 10)

      if (meridian === "PM" && hour !== 12) {
        hour += 12
      } else if (meridian === "AM" && hour === 12) {
        hour = 0
      }

      const formattedTime = `${hour.toString().padStart(2, "0")}:${minutes}:00`
      return formattedTime
    }

    const inputTimeRange = selectedSlot

    const timeParts = inputTimeRange.split("-")

    const startTm = convertTimeTo24HourFormat(timeParts[0])
    const endTm = convertTimeTo24HourFormat(timeParts[1])

    getAvailabilityFilter({
      variables: {
        argumentsInput: {
          start_date: formattedDt,
          start_time: startTm,
          end_time: endTm,
          calendar_id: calendarId
            ? parseInt(calendarId)
            : selectedCounsellor.id,
          repeat: Number(repeateTImes) || 1,
          booking_type: "weekly",
        },
      },
    })

    const formattedDate = selectedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    })
    const counsellorTimeZone = calendarId
      ? counsellorList[1]?.timezone
      : selectedCounsellor?.timezone
    const clientTimeZone = validation.values.timezone
    const [startTime, endTime] = selectedSlot.split("-")
    const dateObj = new Date(formattedDate)

    const fYear = dateObj.getFullYear()
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0")
    const day = dateObj.getDate().toString().padStart(2, "0")

    const FordDate = `${fYear}-${month}-${day}T00:00:00`

    const initialDateTime = moment.tz(FordDate, counsellorTimeZone)

    // Convert to the target timezone
    const doctorDate = initialDateTime.tz(counsellorTimeZone)
    const dateString = formattedDate
    const dateObject = new Date(dateString)
    const year = dateObject.getFullYear()
    doctorDate.year(year)

    doctorDate.set({
      hour: moment(startTime, "hh:mm A").hours(),
      minute: moment(startTime, "hh:mm A").minutes(),
      second: 0,
    })

    const clientDate = doctorDate.clone().tz(clientTimeZone)
    const clientStartTime = moment(clientDate, "hh:mm A").tz(clientTimeZone)

    const startT = moment.tz(
      clientStartTime,
      "YYYY-MM-DDTHH:mm:ssZ",
      clientTimeZone
    )

    const clientEndTime = startT.clone().add(durationSelected, "minutes")

    const date = clientDate.format("MMMM DD, YYYY")
    const time = `${clientStartTime.format("hh:mm A")}-${clientEndTime.format(
      "hh:mm A"
    )}`

    setSelectedTimeSlot({ date: date, time: time })
  }
  const addTImeSlot = () => {
    formatTimeSlot()
    setDateFilterList([])
    setAddRecurringList(false)
  }

  useEffect(() => {
    if (
      validation.values.timezone &&
      selectedSlot &&
      dateFilterList.length === 0
    ) {
      // formatTimeSlot()
      setContinueSlot(true)
    }
    if (
      validation.values.timezone &&
      !selectedTimeSlot &&
      dateFilterList.length > 0
    ) {
      const timeSlots = allTImeSlot

      const convertedTimeSlots = []
      const convertedTimeSlotsCalendar = []
      const counsellorTimeZone = calendarId
        ? counsellorList[1]?.timezone
        : selectedCounsellor?.timezone
      const clientTimeZone = validation.values.timezone
      timeSlots.forEach(timeSlot => {
        // const dateObj = new Date(timeSlot.date)

        // const fYear = dateObj.getFullYear()
        // const month = (dateObj.getMonth() + 1).toString().padStart(2, "0")
        // const day = dateObj.getDate().toString().padStart(2, "0")

        const FordDate = timeSlot.date

        const initialDateTime = moment.tz(FordDate, counsellorTimeZone)

        // Convert to the target timezone
        const doctorDate = initialDateTime.tz(counsellorTimeZone)
        const dateString = timeSlot.date
        const dateObject = new Date(dateString)
        const year = dateObject.getFullYear()
        doctorDate.year(year)

        doctorDate.set({
          hour: moment(timeSlot.start_time, "HH:mm:ss").hours(),
          minute: moment(timeSlot.start_time, "HH:mm:ss").minutes(),
          second: moment(timeSlot.start_time, "HH:mm:ss").seconds(),
        })
        const startTime = moment.tz(
          doctorDate,
          "YYYY-MM-DDTHH:mm:ssZ",
          clientTimeZone
        )

        const doctorEndTime = startTime.clone().add(durationSelected, "minutes")

        const clientDate = doctorDate.clone().tz(clientTimeZone)

        const clientStartTime = moment(clientDate, "hh:mm A").tz(clientTimeZone)
        const startT = moment.tz(
          clientStartTime,
          "YYYY-MM-DDTHH:mm:ssZ",
          clientTimeZone
        )

        const clientEndTime = startT.clone().add(durationSelected, "minutes")

        const convertedTimeSlot = {
          date: clientDate.format("YYYY-MM-DD"),
          is_available: timeSlot.is_available,
          start_time: clientStartTime.format("HH:mm:ss"),
          end_time: clientEndTime.format("HH:mm:ss"),
        }

        const convertedTimeSlotCal = {
          date: doctorDate.format("YYYY-MM-DD"),
          is_available: timeSlot.is_available,
          start_time: doctorDate.format("HH:mm:ss"),
          end_time: doctorEndTime.format("HH:mm:ss"),
        }

        convertedTimeSlots.push(convertedTimeSlot)
        convertedTimeSlotsCalendar.push(convertedTimeSlotCal)
      })

      setDateFilterList(convertedTimeSlots)
      setDateFilterListCalendar(convertedTimeSlotsCalendar)
      formatTimeSlot()
      setModal(false)
      setContinueSlot(false)
      setSelectedTimeSlot()
      // setRepeateTImes()
      setDayRepeat()
    }
  }, [validation.values.timezone])

  const addRecurring = () => {
    setModal(true)
    // formatTimeSlot()
  }

  const handleDayRepeat = e => {
    setDayRepeat(e.value)
  }
  const handleRepeatTImes = e => {
    setRepeateTImes(e.value)
  }

  const addRecurringTime = () => {
    const inputDate = new Date(selectedDate)
    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, "0")
    const day = String(inputDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`

    const convertTimeTo24HourFormat = time => {
      const timeParts = time.split(" ")
      const meridian = timeParts[1]
      const timeWithoutMeridian = timeParts[0]
      setAddRecurringList(false)

      const [hours, minutes] = timeWithoutMeridian.split(":")
      let hour = parseInt(hours, 10)

      if (meridian === "PM" && hour !== 12) {
        hour += 12
      } else if (meridian === "AM" && hour === 12) {
        hour = 0
      }

      const formattedTime = `${hour.toString().padStart(2, "0")}:${minutes}:00`
      return formattedTime
    }

    const inputTimeRange = selectedSlot

    const timeParts = inputTimeRange.split("-")

    const startTime = convertTimeTo24HourFormat(timeParts[0])
    const endTime = convertTimeTo24HourFormat(timeParts[1])

    getAvailabilityFilter({
      variables: {
        argumentsInput: {
          start_date: formattedDate,
          start_time: startTime,
          end_time: endTime,
          calendar_id: calendarId
            ? parseInt(calendarId)
            : selectedCounsellor.id,
          repeat: Number(repeateTImes),
          booking_type: dayRepeat,
        },
      },
    })
  }

  const cancleRecurring = () => {
    setModal(false)
  }

  useEffect(() => {
    if (
      getAvailabilityFilterData.data &&
      getAvailabilityFilterData.data?.getAvailabilityByDatesFilter &&
      getAvailabilityFilterData.data?.getAvailabilityByDatesFilter?.data?.list
    ) {
      setContinueSlot(false)

      const timeSlots =
        getAvailabilityFilterData.data?.getAvailabilityByDatesFilter?.data?.list
      setAllTImeSlot(
        getAvailabilityFilterData.data?.getAvailabilityByDatesFilter?.data?.list
      )
      const convertedTimeSlots = []
      const convertedTimeSlotsCalendar = []
      const counsellorTimeZone = calendarId
        ? counsellorList[1]?.timezone
        : selectedCounsellor?.timezone
      const clientTimeZone = validation.values.timezone
      timeSlots.forEach(timeSlot => {
        // const dateObj = new Date(timeSlot.date)

        // const fYear = dateObj.getFullYear()
        // const month = (dateObj.getMonth() + 1).toString().padStart(2, "0")
        // const day = dateObj.getDate().toString().padStart(2, "0")

        const FordDate = timeSlot.date

        const initialDateTime = moment.tz(FordDate, counsellorTimeZone)

        // Convert to the target timezone
        const doctorDate = initialDateTime.tz(counsellorTimeZone)
        const dateString = timeSlot.date
        const dateObject = new Date(dateString)
        const year = dateObject.getFullYear()
        doctorDate.year(year)

        doctorDate.set({
          hour: moment(timeSlot.start_time, "HH:mm:ss").hours(),
          minute: moment(timeSlot.start_time, "HH:mm:ss").minutes(),
          second: moment(timeSlot.start_time, "HH:mm:ss").seconds(),
        })

        const startTime = moment.tz(
          doctorDate,
          "YYYY-MM-DDTHH:mm:ssZ",
          counsellorTimeZone
        )

        const doctorEndTime = startTime.clone().add(durationSelected, "minutes")
        const clientDate = doctorDate.clone().tz(clientTimeZone)

        const clientStartTime = moment(clientDate, "hh:mm A").tz(clientTimeZone)
        const startT = moment.tz(
          clientStartTime,
          "YYYY-MM-DDTHH:mm:ssZ",
          clientTimeZone
        )

        const clientEndTime = startT.clone().add(durationSelected, "minutes")

        const convertedTimeSlot = {
          date: clientDate.format("YYYY-MM-DD"),
          is_available: timeSlot.is_available,
          start_time: clientStartTime.format("HH:mm:ss"),
          end_time: clientEndTime.format("HH:mm:ss"),
        }

        const convertedTimeSlotCal = {
          date: doctorDate.format("YYYY-MM-DD"),
          is_available: timeSlot.is_available,
          start_time: doctorDate.format("HH:mm:ss"),
          end_time: doctorEndTime.format("HH:mm:ss"),
        }

        convertedTimeSlots.push(convertedTimeSlot)
        convertedTimeSlotsCalendar.push(convertedTimeSlotCal)
      })

      setDateFilterList(convertedTimeSlots)
      setDateFilterListCalendar(convertedTimeSlotsCalendar)
      setModal(false)
      setContinueSlot(false)
      setSelectedTimeSlot()
      // setRepeateTImes()
      setDayRepeat()
    }
  }, [getAvailabilityFilterData.data])
  const isDateDisabled = date => {
    const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" })
    const dayAvailability = availabilityData?.find(
      availability => availability.day === getDayName(dayOfWeek)
    )

    if (!dayAvailability) {
      // If the day is not found in availabilityData, disable the date
      return false
    }

    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    // Check if the provided date is earlier than the current date
    if (date < currentDate) {
      return false // Disable the date if it's in the past
    }

    const dateNumber = date.getDate()

    return dayAvailability.monthWiseAvailablities.some(
      availability => availability.date === dateNumber
    )
  }
  // Function to get the day name based on day index

  const getDayName = dayIndex => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ]
    const index = days.indexOf(dayIndex)
    return days[index]
  }
  const handleDeleteSlot = index => {
    const newDateFilterList = dateFilterList.filter((elem, ind) => {
      return ind !== index
    })
    setDateFilterList(newDateFilterList)
  }
  const addRecurringListFun = () => {
    setAddRecurringList(true)
    const newDateFilterList = dateFilterList.filter(elem => {
      return elem.is_available === true
    })
    const newRoomListDate = allTImeSlot.filter(elem => {
      return elem.is_available === true
    })
    setDateFilterList(newDateFilterList)
    const roomVariables = newRoomListDate.map(elem => {
      const dayOfWeek = new Date(elem.date).toLocaleDateString("en-US", {
        weekday: "long",
      })
      return {
        calendar_id: calendarId ? parseInt(calendarId) : selectedCounsellor.id,
        override_date: elem.date,
        start_time: elem.start_time,
        end_time: elem.end_time,
        week_day: dayOfWeek,
      }
    })
    getAvailableRoomsFun(roomVariables)
    getAppointmentTypeFun(selectedAppointment.id)
  }

  function getUserListFun(search) {
    getUserList({
      variables: {
        argumentsInput: {
          page: 1,
          limit: 20,
          search: search || "",
          sort: "id",
          sort_dir: "DESC",
          role_id: 3,
        },
      },
    })
  }

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getUsers &&
      listResponse.data.getUsers.status &&
      listResponse.data.getUsers.code === 200
    ) {
      setUsers(listResponse.data.getUsers.data.users)
    } else {
    }
  }, [listResponse.data])

  useEffect(() => {
    if (users) {
      const allUser = users.map(value => ({
        value: value.id,
        label:
          value.first_name +
          " " +
          value.last_name +
          " " +
          `${value.mobile_number ? value.mobile_number : ""}`,
        name: value.first_name,
      }))
      if (coupleClients.length > 0) {
        const idSet = new Set(coupleClients.map(item => item.id))
        const filteredUser = allUser.filter(item => !idSet.has(item.value))
        setUserFirstNameOption(filteredUser)
      } else {
        setUserFirstNameOption(allUser)
      }
    }
  }, [users])

  const [isPaymentMethod, setIsPaymentMethod] = useState([])
  const [checkPaymentMethod, checkPaymentMethodRes] = useLazyQuery(
    CHECK_PAYMENT_METHOD,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [intervalId, setIntervalId] = useState()

  const openCardDetailsPage = () => {
    const UserObj = JSON.stringify(selectedUser)
    const encodedData = UserObj && btoa(UserObj)
    if (encodedData) {
      window.open(`/card-details?userId=${encodedData}`, "_blank")
    } else {
      toast.error("Please select an user!")
    }
    const id = setInterval(() => {
      recheckMaymentMethod()
    }, 5000)
    setIntervalId(id)
  }

  useEffect(() => {
    if (checkPaymentMethodRes?.data) {
      const isCardAdded =
        checkPaymentMethodRes?.data?.getUserPaymentMethod?.data
          ?.userPaymentMethods
      setIsPaymentMethod(isCardAdded)
      if (isCardAdded?.length > 0) {
        clearInterval(intervalId)
      }
    }
  }, [checkPaymentMethodRes.data])

  const recheckMaymentMethod = user => {
    checkPaymentMethod({
      variables: {
        clientId: user
          ? JSON.stringify(user.value)
          : JSON.stringify(selectedUser.value),
      },
    })
  }

  const handleUserChange = (value, validation) => {
    setUserFieldDisabled(true)
    validation.setFieldValue("first_name", value.name)
    setSelectedUser({
      label: value.name,
      name: value.name,
      value: value.value,
    })
    recheckMaymentMethod(value)
  }

  const seachUsers = e => {
    getUserListFun(e)
  }

  React.useEffect(() => {
    if (selectedUser && !isUserSwitchOn) {
      getUserDetail({
        variables: {
          argumentsInput: {
            id: parseInt(selectedUser.value),
          },
        },
      })
    }
  }, [selectedUser])

  React.useEffect(() => {
    if (
      details.data &&
      details.data?.getUser &&
      details.data?.getUser?.status
    ) {
      const allRoles = details.data?.getUser?.data?.user?.organizations.map(
        elem => {
          return elem?.role?.id
        }
      )
      setGetAllRoles(allRoles)
      const allOrgs = details.data?.getUser?.data?.user?.organizations.map(
        elem => {
          return elem?.organization?.id
        }
      )

      setGetAllOrgs(allOrgs)
      const storedData = localStorage.getItem("orgId")
      const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
      if (allOrgs.includes(orgId) && isUserSwitchOn) {
        toast.error(
          "This user already exists in this system. Please create the client with a fresh email address"
        )
        validation.setFieldValue("last_name", "")
        validation.setFieldValue("first_name", "")
        validation.setFieldValue("email", "")
        validation.setFieldValue("phone", "")
      } else {
        let data = {
          ...details.data?.getUser?.data?.user,
          role_id: details.data?.getUser?.data?.user.role.id,
          name: details.data?.getUser?.data?.user.name
            ? details.data?.getUser?.data?.user.name?.trim()
            : "",
          email: details.data?.getUser?.data?.user.email
            ? details.data?.getUser?.data?.user.email?.trim()
            : "",
          first_name: details.data?.getUser?.data?.user.first_name
            ? details.data?.getUser?.data?.user.first_name?.trim()
            : "",
          last_name: details.data?.getUser?.data?.user.last_name
            ? details.data?.getUser?.data?.user.last_name?.trim()
            : "",
          mobile_number: details.data?.getUser?.data?.user.mobile_number,
        }
        // setIsDisableRoleId(details.data?.getUser?.data?.user?.role?.id)
        // setInitialValues(data)
        setUserFieldDisabled(false)
        validation.setFieldValue("first_name", data.first_name)
        validation.setFieldValue("last_name", data.last_name)
        validation.setFieldValue("email", data.email)
        validation.setFieldValue(
          "phone",
          data.mobile_number ? data.mobile_number : null
        )
      }
    }
  }, [details?.data])

  useEffect(() => {
    if (getPaymentOptList?.data?.getPaymentType?.data?.paymentTypes) {
      const options =
        getPaymentOptList?.data?.getPaymentType?.data?.paymentTypes.map(d => ({
          label: d.name,
          value: d.name,
          id: d.id,
          is_direct: d.is_direct,
        }))
      setPaymentOptions(options)
    }
  }, [getPaymentOptList.data])
  const handlePaymentOption = (value, validation) => {
    validation.setFieldValue("payment_type", value.value)
    setSelectedPayment(value)
  }

  React.useEffect(() => {
    if (userId) {
      getAppointmentDetail({
        variables: {
          argumentsInput: {
            id: parseInt(userId),
          },
        },
      })
    }
  }, [userId])

  React.useEffect(() => {
    if (
      userId &&
      appointmentResponse.data &&
      appointmentResponse.data?.getAppointment
    ) {
      let appointmentRes =
        appointmentResponse.data?.getAppointment?.data?.appointment

      setSelectedCounsellor(appointmentRes?.therapist_calendar)
      setSelectedUser({
        id: appointmentRes.first_name,
        label: appointmentRes.first_name,
      })
      setSelectedAppointment(appointmentRes.type_of_appointment)
      setDurationSelected(appointmentRes.duration)

      const targetTimezone = appointmentRes?.timezone
      const utcTimestamp = appointmentRes?.utc_datetime
      let selectedSlot = ""
      const localTime = moment(utcTimestamp).tz(targetTimezone)
      const isoDate = localTime.format("YYYY-MM-DD")
      setIsoDate(isoDate)
      const isoTime = localTime.format("HH:mm:ss")
      const formattedDate = new Date(`${isoDate}T00:00:00`).toString()
      setIsoTime(isoTime)
      setSelectedDate(new Date(formattedDate))

      const matchedPaymentType = paymentOptions.find(
        paymentType => paymentType?.id === appointmentRes.payment_type?.id
      )
      if (matchedPaymentType) {
        setSelectedPayment(matchedPaymentType)
      }

      let data = {
        ...initialValues,
        ...appointmentRes,
        appointment_type: {
          id: appointmentRes?.type_of_appointment.id,
          value: appointmentRes?.type_of_appointment.id,
          name: appointmentRes?.type_of_appointment.name,
        },
        counsellor: appointmentRes?.therapist_calendar.id,
        timezone: appointmentRes?.timezone,
        email: appointmentRes.email,
        first_name: appointmentRes.first_name,
        last_name: appointmentRes.last_name,
        date: new Date(formattedDate),
        phone: appointmentRes.phone,
        payment_type: matchedPaymentType ? matchedPaymentType?.value : "",
        notes: appointmentRes.notes,
      }
      setAppointmentDetails(appointmentRes)
      setInitialValues(data)
    } else {
      setAppointmentDetails({})
    }
  }, [appointmentResponse.data])

  useEffect(() => {
    if (userId) {
      handleTimeSlots(isoDate, durationSelected)
    }
  }, [selectedCounsellor.id])

  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn)
  }

  const handleUserCreate = () => {
    setIsUserSwitchOn(!isUserSwitchOn)
  }
  const handleRoomSwitchChange = () => {
    setIsRoomSwitchOn(!isRoomSwitchOn)
  }

  const userPermissionData = localStorage.getItem("userPermission")
  let userPermission = userPermissionData ? JSON.parse(userPermissionData) : []

  const [showError, setShowError] = useState(false)
  const [detailsPending, setDetailsPending] = useState(false)
  const encodedAccountData = localStorage.getItem("accountData")
  const accountStatus = encodedAccountData
    ? JSON.parse(atob(encodedAccountData))
    : null

  const [accountDetailsStatus, setAccountDetailsStatus] = useState(false)

  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])

  useEffect(() => {
    if (accountStatus && accountDetailsStatus) {
      setShowError(true)
      if (isPaymentMethod.length === 0) {
        if (selectedPayment && !selectedPayment?.is_direct) {
          setDetailsPending(true)
        } else {
          setDetailsPending(false)
        }
      } else {
        setDetailsPending(false)
      }
    } else {
      setShowError(false)
    }
  }, [
    accountStatus,
    accountDetailsStatus,
    isPaymentMethod,
    selectedPayment,
    selectedUser,
  ])

  const addMoreUser = () => {
    const newClient = {
      selectedClient: selectedUser,
      id: selectedUser.value,
      first_name: validation?.values?.first_name,
      last_name: validation?.values?.last_name,
      email: validation?.values?.email,
      phone: validation?.values?.phone,
    }
    setCoupleClients(preUsers => [...preUsers, newClient])

    setTimeout(() => {
      const idSet = new Set(coupleClients.map(item => item.id))
      const allUser = users.map(value => ({
        value: value.id,
        label:
          value.first_name +
          " " +
          value.last_name +
          " " +
          `${value.mobile_number ? value.mobile_number : ""}`,
        name: value.first_name,
      }))
      const filteredUser = allUser.filter(item => !idSet.has(item.value))
      setUserFirstNameOption(filteredUser)
      validation.setFieldValue("last_name", "")
      validation.setFieldValue("first_name", "")
      validation.setFieldValue("email", "")
      validation.setFieldValue("phone", "")
      setSelectedUser(null)
    }, 500)
  }

  const removeAddUserOption = () => {
    if (coupleClients.length > 1) {
      const lastAddedUser = coupleClients[coupleClients.length - 1]
      const removelastElem = coupleClients.slice(0, -1)
      validation.setFieldValue("last_name", lastAddedUser?.last_name)
      validation.setFieldValue("first_name", lastAddedUser?.first_name)
      validation.setFieldValue("email", lastAddedUser?.email)
      validation.setFieldValue("phone", lastAddedUser?.phone)
      setSelectedUser(lastAddedUser?.selectedClient)
      setCoupleClients(removelastElem)
    } else {
      const lastAddedUser = coupleClients[coupleClients.length - 1]
      validation.setFieldValue("last_name", lastAddedUser?.last_name)
      validation.setFieldValue("first_name", lastAddedUser?.first_name)
      validation.setFieldValue("email", lastAddedUser?.email)
      validation.setFieldValue("phone", lastAddedUser?.phone)
      setSelectedUser(lastAddedUser?.selectedClient)
      setCoupleClients([])
    }
    const allUser = users.map(value => ({
      value: value.id,
      label:
        value.first_name +
        " " +
        value.last_name +
        " " +
        `${value.mobile_number ? value.mobile_number : ""}`,
      name: value.first_name,
    }))
    setUserFirstNameOption(allUser)
    setIsUserSwitchOn(false)
  }

  const removeUser = index => {
    const nonRemovedUser = coupleClients.filter((elem, ind) => {
      return ind !== index
    })
    setCoupleClients(nonRemovedUser)
  }

  useEffect(() => {
    if (coupleClients.length > 0) {
      const idSet = new Set(coupleClients.map(item => item.id))
      const filteredUser = userFirstNameOption.filter(
        item => !idSet.has(item.value)
      )
      setUserFirstNameOption(filteredUser)
    }
  }, [coupleClients])
  return !userPermission.includes("create-appointment") ? (
    <PageNotFound />
  ) : (
    <React.Fragment>
      <div className="page-content mb-5">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    {userId ? "Reschedule " : "Create New "}Appointment
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <FocusError formik={validation} />
            <Row className={style.cardcenter}>
              <Col xl="8">
                <Card className="transparent-bg-card">
                  <CardBody>
                    <h4 className="card-title-appointments mb-3 mt-3">
                      Choose Appointment{" "}
                    </h4>
                    <p></p>
                    <Row>
                      {!calendarId && (
                        <Col md="12">
                          <div className="mb-3 form-custom-white-inputs ">
                            <Label className="form-label">
                              Select Calendar *
                            </Label>
                            <CustomSelect
                              className="input custom-date-picker-style-bradius"
                              disabled={userId ? true : false}
                              onChange={value =>
                                handleCounsellorChange(value, validation)
                              }
                              placeholder="Select Calendar"
                              value={validation.values.counsellor}
                              options={counsellorList}
                              error={validation.errors?.counsellor}
                              touched={validation.touched?.counsellor}
                              onBlur={() => {
                                validation.setFieldTouched("counsellor", true)
                              }}
                            />
                          </div>
                        </Col>
                      )}
                      <Col md="12">
                        <div className="mb-3 form-custom-white-inputs ">
                          <Label className="form-label">
                            {" "}
                            Appointment Type *
                          </Label>
                          <CustomMultiSelect
                            className="input "
                            onChange={value =>
                              handleAppointmentClick(value, validation)
                            }
                            placeholder="Select Appointment Type"
                            value={validation.values?.appointment_type}
                            options={appointmentTypeList}
                            error={validation.errors?.appointment_type}
                            touched={validation.touched?.appointment_type}
                            disabled={userId || appointmentTypeList.length <= 1}
                            isMulti={false}
                            onBlur={() => {
                              validation.setFieldTouched(
                                "appointment_type",
                                true
                              )
                            }}
                          />
                          {validation.touched.appointment_type &&
                          validation.errors.appointment_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.appointment_type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {selectedAppointment?.call_type?.toLowerCase() ==
                        "online video" &&
                        !userId && (
                          <Col md="12">
                            <div className="mb-3 form-custom-white-inputs ">
                              <Label className="form-label">
                                {" "}
                                Video Room Size
                              </Label>
                              <CustomMultiSelect
                                className="input "
                                onChange={value => handleRoomSize(value)}
                                placeholder="Select Video Room Size"
                                value={selectedRoomSize}
                                options={roomSizeList}
                                isMulti={false}
                              />
                            </div>
                          </Col>
                        )}
                      {(selectedRoomSize?.value == 10 ||
                        selectedRoomSize?.value == 50) &&
                        selectedAppointment?.call_type?.toLowerCase() ==
                          "online video" && (
                          <Col
                            md={"12"}
                            className="alert alert-warning m-0  p-1"
                          >
                            <p className="text-dark m-0 p-0">
                              Note :{" "}
                              <span>
                                For best video quality, We recommend that room
                                have no more than 4 participants in a video call
                              </span>
                            </p>
                          </Col>
                        )}
                      <h4 className="card-title-appointments mb-3 mt-3">
                        Choose Date & Time
                      </h4>
                      <Col md="12">
                        <div className="mb-3 form-custom-white-inputs ">
                          <Label className="form-label">
                            Counsellor&apos;s Time Zone *
                          </Label>
                          <CustomSelect
                            className="input custom-date-picker-style-bradius"
                            placeholder="Select Timezone"
                            value={
                              calendarId
                                ? counsellorList[1]?.timezone
                                : selectedCounsellor?.timezone
                            }
                            disabled={true}
                            options={timeZoneList}
                            onBlur={() => {
                              validation.setFieldTouched("timezone", true)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3 form-custom-white-inputs d-flex justify-content-between">
                          <DatePicker
                            selected={validation.values.date}
                            name="date"
                            onChange={value =>
                              handleDateClick(value, validation)
                            }
                            onMonthChange={handleMonthChange}
                            disabled={
                              selectedAppointment && !selectedAppointment.id
                                ? true
                                : false
                            }
                            autoComplete="off"
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Select Date"
                            className={`form-control custom-date-picker-style`}
                            customInput={
                              <input
                                type="text"
                                id="validationCustom01"
                                placeholder="Date"
                                autoComplete="off"
                              />
                            }
                            filterDate={isDateDisabled}
                          />
                          {validation.touched.date && validation.errors.date ? (
                            <FormFeedback type="invalid" className="d-block">
                              {validation.errors.date}
                            </FormFeedback>
                          ) : null}
                          <div className="d-flex align-items-center">
                            <p className="m-0 p-0 font-size-12 font-weight-bold text-center mx-1">
                              Custom Slot?
                            </p>
                            <Switch
                              onChange={toggleCustomTimeslot}
                              checked={customTimeSlot}
                              onColor="#153c3c"
                              onHandleColor="#2693e6"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              width={40}
                              height={20}
                              disabled={!selectedDate ? true : false}
                            />
                          </div>
                        </div>
                      </Col>
                      {!customTimeSlot && (
                        <Col md="6">
                          <div className="mb-3 form-custom-white-inputs ">
                            <div className={style.custom_radio_group}></div>
                            <CustomSelect
                              className="input custom-date-picker-style-bradius"
                              onChange={value =>
                                handleTimeSlot(value.name, validation)
                              }
                              name="selectedSlot"
                              disabled={!selectedDate ? true : false}
                              placeholder="Select Time Slot"
                              value={validation.values.selectedSlot}
                              options={timeSlots}
                              error={validation.errors?.selectedSlot}
                              touched={validation.touched?.selectedSlot}
                              invalid={
                                validation.touched.selectedSlot &&
                                validation.errors.selectedSlot
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.selectedSlot &&
                            validation.errors.selectedSlot ? (
                              <FormFeedback type="invalid">
                                {validation.errors.selectedSlot}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}
                      {customTimeSlot && (
                        <Col md={6}>
                          <Row className="w-100 d-flex align-items-center">
                            <Col className="col-8">
                              <Input
                                className="form-control default-search-input w-100 w-md-50"
                                name={`blocktime_start_time`}
                                placeholder="From"
                                type="time"
                                onChange={e => handleCustomTimeSelect(e)}
                                value={customStartTime}
                              />
                            </Col>
                            {customEndTime && (
                              <>
                                <Col className="col-1 m-0 p-0">
                                  <p className="m-0 p-0 font-weight-bold">
                                    {" "}
                                    To{" "}
                                  </p>
                                </Col>
                                <Col className="col-3 m-0 p-0">
                                  <p className="m-0 p-0 font-weight-bold">
                                    {" "}
                                    {customEndTime}
                                  </p>
                                </Col>
                              </>
                            )}
                          </Row>
                        </Col>
                      )}

                      <Col md="12">
                        <div className="mb-3 form-custom-white-inputs ">
                          <Label className="form-label">
                            Client&apos;s Time Zone *
                          </Label>
                          <CustomSelect
                            className="input custom-date-picker-style-bradius"
                            onChange={value =>
                              validation.setFieldValue("timezone", value.id)
                            }
                            placeholder="Select Timezone"
                            value={validation.values.timezone}
                            disabled={
                              (selectedAppointment &&
                                !selectedAppointment.id) ||
                              userId
                                ? true
                                : false
                            }
                            options={timeZoneList}
                            error={validation.errors?.timezone}
                            touched={validation.touched?.timezone}
                            onBlur={() => {
                              validation.setFieldTouched("timezone", true)
                            }}
                          />
                        </div>
                      </Col>
                      {/* {selectedSlot && } */}
                      {continueSlot && timeZoneList && (
                        <Col md="12">
                          <div className="d-flex my-3">
                            <Button
                              type="button"
                              className="waves-effect waves-light btn btn-primary "
                              onClick={addTImeSlot}
                            >
                              {"Check Availability"}
                            </Button>
                            {!userId && (
                              <>
                                <div className="mx-3 mt-2">OR</div>

                                <Button
                                  type="button"
                                  className="waves-effect waves-light btn btn-primary"
                                  onClick={addRecurring}
                                >
                                  {"Add Recurring "}
                                </Button>
                              </>
                            )}
                          </div>
                        </Col>
                      )}

                      {/* {selectedTimeSlot && !continueSlot && (
                        <Col md="6">
                          <p className="h6">
                            {"Selected time slot based on client's timezone:"}
                          </p>
                          <p className="h6">
                            {selectedTimeSlot.date +
                              ", " +
                              selectedTimeSlot.time}
                          </p>
                        </Col>
                      )} */}

                      {dateFilterList.length > 0 &&
                        !continueSlot &&
                        !addRecurringList && (
                          <Col md="12">
                            <p className="text-dark">
                              {
                                "Selected time slot based on client's timezone :"
                              }
                            </p>
                            {showOverrideWarning && (
                              <p
                                className="p-1 px-2 m-0 alert alert-warning"
                                role="alert"
                              >
                                The time conflicts with another appointment or
                                is outside of your hours. View Day » Are you
                                sure you want to schedule this appointment even
                                though it might overbook?
                              </p>
                            )}
                            {dateFilterList &&
                              dateFilterList.map((item, index) => {
                                const dateParts = item?.date.split("-")
                                const year = dateParts[0]
                                const month = new Date(
                                  item?.date + "T00:00:00"
                                ).toLocaleString("default", { month: "long" })
                                const day = parseInt(dateParts[2])
                                const startTime = formatTime(item.start_time)
                                const endTime = formatTime(item.end_time)
                                const formattedDate = `${month} ${day}, ${year}`

                                if (item.is_available) {
                                  return (
                                    <div key={index} className="d-flex  mt-3">
                                      <Col md="6">
                                        <p className="h6 mt-1">
                                          {formattedDate}, {startTime} to{" "}
                                          {endTime}
                                        </p>
                                      </Col>
                                      <Col md="6">
                                        <a
                                          onClick={() =>
                                            handleDeleteSlot(index)
                                          }
                                          className="text-danger delete-btn cursor-pointer"
                                        >
                                          <img src={deleteBin} />
                                        </a>
                                      </Col>
                                    </div>
                                  )
                                }

                                if (item.is_available === false) {
                                  return (
                                    <div key={index} className="d-flex  mt-3">
                                      <Col md="6">
                                        <p
                                          key={index}
                                          className="h6 parent-container "
                                        >
                                          <i
                                            className="mdi mdi-alert-circle-outline tooltip-info-icon text-danger cursor-pointer mdi-lg "
                                            onMouseEnter={() =>
                                              setShowTooltip(true)
                                            }
                                            onMouseLeave={() =>
                                              setShowTooltip(false)
                                            }
                                          />{" "}
                                          {showTooltip && (
                                            <div className="tooltip-info">
                                              This time slot is already blocked
                                            </div>
                                          )}
                                          <del>
                                            {formattedDate}, {startTime} to{" "}
                                            {endTime}
                                          </del>
                                        </p>{" "}
                                      </Col>
                                      <Col md="5">
                                        <a
                                          onClick={() =>
                                            handleDeleteSlot(index)
                                          }
                                          className="text-danger delete-btn cursor-pointer"
                                        >
                                          <img src={deleteBin} />
                                        </a>
                                      </Col>
                                    </div>
                                  )
                                }
                              })}
                            <Button
                              type="button"
                              className="waves-effect waves-light  btn btn-primary"
                              onClick={addRecurringListFun}
                            >
                              {"Continue"}
                            </Button>
                          </Col>
                        )}

                      {!continueSlot && addRecurringList && (
                        <Col md="12">
                          <p className="h6 mb-3">
                            {"Selected time slot based on client's timezone :"}
                          </p>
                          {dateFilterList &&
                            dateFilterList.map((item, index) => {
                              const dateParts = item?.date.split("-")
                              const year = dateParts[0]
                              const month = new Date(
                                item?.date + "T00:00:00"
                              ).toLocaleString("default", { month: "long" })
                              const day = parseInt(dateParts[2])
                              const formattedDate = `${month} ${day}, ${year}`
                              const startTime = formatTime(item.start_time)
                              const endTime = formatTime(item.end_time)

                              if (item.is_available) {
                                return (
                                  <div key={index} className="d-flex  mt-3">
                                    <p className="h6 mt-1">
                                      {formattedDate}, {startTime} to {endTime}
                                    </p>
                                  </div>
                                )
                              }
                              if (item.is_available === false) {
                                return (
                                  <div key={index} className="d-flex  mt-3">
                                    <p className="h6 mt-1">
                                      <del>
                                        {formattedDate}, {startTime} to{" "}
                                        {endTime}
                                      </del>
                                    </p>
                                  </div>
                                )
                              }
                            })}
                        </Col>
                      )}

                      {/* <Col md="12">
                        <label className="mt-3 d-flex align-items-center">
                          <Switch
                            onChange={handleRoomSwitchChange}
                            checked={isRoomSwitchOn}
                            onColor="#153c3c"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={40}
                            height={20}
                          />
                          <span className="mx-3">Do you want select room?</span>
                        </label>
                      </Col>
                      {roomList.length > 0 && isRoomSwitchOn ? (
                        <Col md="12">
                          <div className="form-custom-white-inputs">
                            {roomList.map(item => (
                              <div key={item.override_date} className="my-3">
                                <Label>
                                  Select Room for date {item.override_date}
                                </Label>
                                {item.rooms.length > 0 ? (
                                  <Select
                                    placeholder="Select room"
                                    isMulti={false}
                                    className="custom-date-picker-style-bradius"
                                    options={item.rooms.map(room => ({
                                      value: room.id,
                                      label: `${room.location.name} > ${room.name}`,
                                    }))}
                                    onChange={selectedOptions =>
                                      handleChangeRoom(
                                        selectedOptions,
                                        item.override_date
                                      )
                                    }
                                    value={
                                      selectedRooms[item.override_date] || []
                                    }
                                  />
                                ) : (
                                  <h4 className="text-danger">
                                    No rooms available for selected time slot !
                                  </h4>
                                )}
                              </div>
                            ))}
                          </div>
                        </Col>
                      ) : (
                        isRoomsAvailable ||
                        (isRoomSwitchOn && (
                          <Col md="12">
                            <div className="form-custom-white-inputs mt-3">
                              <h4 className="text-danger">
                                No rooms available !
                              </h4>
                            </div>
                          </Col>
                        ))
                      )} */}

                      {/* display client's details */}
                      {((addRecurringList && dateFilterList.length > 0) ||
                        userId) && (
                        <>
                          {" "}
                          <h4 className="card-title-appointments mb-3 mt-3">
                            Client Details
                          </h4>
                          <>
                            {!userId && (
                              <Col
                                md="12"
                                className="d-flex justify-content-between"
                              >
                                <label className="my-2 d-flex align-items-center">
                                  <Switch
                                    onChange={handleUserCreate}
                                    checked={isUserSwitchOn}
                                    onColor="#153c3c"
                                    onHandleColor="#2693e6"
                                    handleDiameter={20}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={40}
                                    height={20}
                                  />
                                  <span className="mx-3">
                                    {(isUserSwitchOn &&
                                      "Select existing client?") ||
                                      "Create new client?"}
                                  </span>
                                </label>
                                {coupleClients.length > 0 && (
                                  <div>
                                    <Button
                                      className="button-delete"
                                      onClick={removeAddUserOption}
                                    >
                                      <span className="mdi mdi-trash-can-outline font-size-14"></span>
                                    </Button>
                                  </div>
                                )}
                              </Col>
                            )}
                            <Col md="6">
                              <div className="mb-3 form-custom-white-inputs ">
                                <Label className="form-label">
                                  First Name *
                                </Label>
                                {!isUserSwitchOn ? (
                                  <Select
                                    options={userFirstNameOption}
                                    placeholder="Select User "
                                    value={selectedUser}
                                    isDisabled={userId ? true : false}
                                    onChange={value =>
                                      handleUserChange(value, validation)
                                    }
                                    onInputChange={seachUsers}
                                    error={
                                      validation.touched.first_name &&
                                      validation.errors.first_name
                                    }
                                  />
                                ) : (
                                  <Input
                                    id="client"
                                    name="first_name"
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    disabled={
                                      userId || userFieldDisabled ? true : false
                                    }
                                    value={validation.values.first_name || ""}
                                    invalid={
                                      validation.touched.first_name &&
                                      validation.errors.first_name
                                        ? true
                                        : false
                                    }
                                  />
                                )}

                                {validation.touched.first_name &&
                                validation.errors.first_name ? (
                                  <FormFeedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation.errors.first_name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-3 form-custom-white-inputs ">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                  id="client"
                                  name="last_name"
                                  className="form-control"
                                  placeholder="Enter Last Name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  disabled={
                                    userId || userFieldDisabled ? true : false
                                  }
                                  value={validation.values.last_name || ""}
                                  invalid={
                                    validation.touched.last_name &&
                                    validation.errors.last_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.last_name &&
                                validation.errors.last_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.last_name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-3 form-custom-white-inputs ">
                                <Label className="form-label">Email</Label>
                                <Input
                                  id="email"
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter Email"
                                  type="email"
                                  disabled={
                                    userId || userFieldDisabled ? true : false
                                  }
                                  onChange={event => {
                                    validation.handleChange(event)
                                    isAlreadyExistsFun(event)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-3 form-custom-white-inputs ">
                                <Label className="form-label">Phone</Label>
                                <PhoneNoField
                                  className="phoneinpute"
                                  name="phone"
                                  value={validation.values.phone}
                                  onBlur={validation.handleBlur}
                                  disabled={
                                    userId || userFieldDisabled ? true : false
                                  }
                                  onChange={value =>
                                    validation.setFieldValue("phone", value)
                                  }
                                  handleChange={validation.handleChange}
                                  error={
                                    validation.touched.phone &&
                                    validation.errors.phone
                                  }
                                />
                                {validation.touched.phone &&
                                validation.errors.phone ? (
                                  <FormFeedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation.errors.phone}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            {isUserSwitchOn && !hideCreateBtn && (
                              <Col md="12">
                                <div className="mb-3 form-custom-white-inputs text-end ">
                                  <Button
                                    type="button"
                                    color="secondary"
                                    className="btn-inactive btn btn-sm"
                                    disabled={clientData}
                                    onClick={() => createUser()}
                                  >
                                    Create
                                  </Button>
                                </div>
                              </Col>
                            )}
                          </>
                          {selectedUser && (
                            <Col md="12 mb-2">
                              <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => addMoreUser()}
                                disabled={
                                  userId || userFieldDisabled ? true : false
                                }
                              >
                                Add +
                              </Button>
                            </Col>
                          )}
                          {coupleClients.length > 0 &&
                            coupleClients.map((elem, index) => {
                              return (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <p className="h6 font-weight-bold">
                                      Client {index + 1} :{" "}
                                    </p>
                                    <Button
                                      className="button-delete"
                                      onClick={() => removeUser(index)}
                                    >
                                      <span className="mdi mdi-trash-can-outline font-size-14"></span>
                                    </Button>
                                  </div>
                                  <Col md="6">
                                    <div className="mb-3 form-custom-white-inputs ">
                                      <Label className="form-label">
                                        First Name
                                      </Label>
                                      <Input
                                        id="first_name"
                                        name="first_name"
                                        className="form-control"
                                        disabled={true}
                                        value={elem?.first_name}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="mb-3 form-custom-white-inputs ">
                                      <Label className="form-label">
                                        Last Name
                                      </Label>
                                      <Input
                                        id="last_name"
                                        name="last_name"
                                        className="form-control"
                                        disabled={true}
                                        value={elem?.last_name}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="mb-3 form-custom-white-inputs ">
                                      <Label className="form-label">
                                        Last Name
                                      </Label>
                                      <Input
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        disabled={true}
                                        value={elem?.email}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="mb-3 form-custom-white-inputs ">
                                      <Label className="form-label">
                                        Phone
                                      </Label>
                                      <PhoneNoField
                                        className="phoneinpute"
                                        name="phone"
                                        value={elem?.phone}
                                        disabled={true}
                                      />
                                    </div>
                                  </Col>
                                </>
                              )
                            })}
                          <Col md="12">
                            <div className="mb-3 form-custom-white-inputs ">
                              <Label className="form-label">Plans</Label>

                              <textarea
                                id="notes"
                                name="notes"
                                className="form-control additional-information-client"
                                placeholder="Enter your Plans"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                disabled={userId ? true : false}
                                value={validation.values.notes || ""}
                              ></textarea>
                            </div>
                          </Col>
                          {!userId && (
                            <>
                              {" "}
                              <Col md="12" className="  ">
                                <div className="mb-3 form-custom-white-inputs ">
                                  <Label className="form-label">
                                    Payment Types *
                                  </Label>

                                  <Select
                                    placeholder="Select Payment Option"
                                    className="custom-date-picker-style-bradius "
                                    options={paymentOptions}
                                    value={selectedPayment}
                                    onChange={value =>
                                      handlePaymentOption(value, validation)
                                    }
                                    isDisabled={userId ? true : false}
                                  />
                                </div>
                              </Col>
                              {showError ? (
                                detailsPending && selectedUser ? (
                                  selectedPayment ? (
                                    <Col md={12} className="mt-3">
                                      <div className="pre-card-section form-custom-white-inputs">
                                        <Label className="form-label">
                                          Securely Save Client&apos;s Card for
                                          Future Sessions
                                        </Label>
                                        <p>
                                          The client&apos;s payment information
                                          is currently missing. As a result, the
                                          appointments will not be automatically
                                          paid. Please kindly add the payment
                                          option by clicking on the Add Card
                                          button.
                                        </p>
                                        <Button
                                          className="btn btn-primary"
                                          onClick={() => openCardDetailsPage()}
                                        >
                                          Add Card
                                        </Button>
                                      </div>
                                    </Col>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {!detailsPending &&
                                showError &&
                                selectedUser &&
                                selectedPayment &&
                                !selectedPayment?.is_direct &&
                                isPaymentMethod.length > 0 && (
                                  <div className=" m-0 mt-2 p-2 form-custom-white-inputs">
                                    <Label className="form-label">
                                      Credit/Debit Card
                                    </Label>
                                    <div className="border border-dark rounded mt-3 p-4 w-100 d-flex justify-content-between">
                                      <p className="h5 card-digit font-weight-bold  text-capitalize">
                                        **** **** ****{" "}
                                        {isPaymentMethod[0]?.last4}
                                      </p>
                                      <p className="h5 card-digit  text-capitalize">
                                        {isPaymentMethod[0]?.exp_month} /{" "}
                                        {isPaymentMethod[0]?.exp_year}
                                      </p>
                                      <p className="h5 card-digit  text-capitalize">
                                        {isPaymentMethod[0]?.brand?.toUpperCase()}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              {responseDataList && (
                                <Col md="12">
                                  <label className="my-5 d-flex align-items-center">
                                    <Switch
                                      onChange={handleSwitchChange}
                                      checked={isSwitchOn}
                                      onColor="#153c3c"
                                      onHandleColor="#2693e6"
                                      handleDiameter={20}
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                      width={40}
                                      height={20}
                                    />
                                    <span className="mx-3">
                                      Fill out intake form
                                    </span>
                                  </label>
                                </Col>
                              )}
                            </>
                          )}
                          {!userId && isSwitchOn && (
                            <>
                              <Col md="12">
                                <p className="h2 mb-5">
                                  {intakeFormName} {intakeFormName && ":"}
                                </p>
                              </Col>
                              {inputElements &&
                                responseDataList &&
                                inputElements.map((element, index) => (
                                  <React.Fragment key={index}>
                                    {element}
                                  </React.Fragment>
                                ))}
                            </>
                          )}{" "}
                        </>
                      )}

                      {addRecurringList && dateFilterList.length > 0 && (
                        <Col xl="12">
                          <div className="text-end ">
                            <button
                              className="btn btn-primary w-sm waves-effect waves-light mx-3"
                              type="submit"
                              onClick={() => setIsSubmitted(true)}
                              disabled={submitDisable}
                            >
                              {!userId
                                ? submitDisable
                                  ? "Saving..."
                                  : "Save"
                                : submitDisable
                                ? "Updating..."
                                : "Update"}
                            </button>
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-inactive btn"
                              onClick={() => history.goBack()}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
          <Modal isOpen={modal} size="lg">
            <ModalHeader toggle={() => setModal(undefined)} tag="h4">
              <p>{recHeader + ", " + selectedSlot}</p>
            </ModalHeader>
            <ModalBody className="p-4">
              <div className="mt-3">Repeat</div>
              <Select
                value={dayRepeat?.label}
                onChange={e => handleDayRepeat(e)}
                options={repeatOptions}
                className="mt-4 w-75"
              />
              <div className="mt-3">Times To Repeat</div>
              <Select
                value={repeateTImes?.label}
                onChange={e => handleRepeatTImes(e)}
                options={repeateNumber}
                className="mt-4 w-75"
              />

              <div className="d-flex">
                <Button
                  type="button"
                  className="waves-effect waves-light my-5 mr-5 btn btn-primary"
                  onClick={addRecurringTime}
                >
                  Add Recurring Times
                </Button>
                <Button
                  type="button"
                  className="waves-effect waves-light my-5 mx-5btn btn-inactive btn-lg ms-2"
                  onClick={cancleRecurring}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={confirmModal} size="lg">
            <ModalHeader toggle={() => goBackButtuon()} tag="h4">
              <p>Confirmation</p>
            </ModalHeader>
            <ModalBody className="p-4">
              <p>
                (NFC) Initial Report (CA$ {appointmentData?.price}) with{" "}
                {appointmentData?.calendar}
              </p>

              {confirmDateTime?.map(elem => {
                return (
                  <p key={elem.id} className="h4 mt-4">
                    {elem.formatted_datetime} {timezoneAbbreviation}
                  </p>
                )
              })}

              {
                <div className="mt-4 d-flex">
                  <Button
                    type="button "
                    className=" btn-primary "
                    onClick={openMapView}
                  >
                    <span>Display Location</span>
                  </Button>
                </div>
              }
              <div className="mt-4 d-flex">
                <Button
                  type="button "
                  className=" btn-danger"
                  onClick={() => setModalDelete(true)}
                >
                  <span>Cancel Appointments</span>
                </Button>
              </div>

              <div className="mt-4 d-flex">
                <Button
                  type="button "
                  className=" btn-success"
                  onClick={addToGoogle}
                >
                  <span>Add toiCal / Outlook / Google</span>
                </Button>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={modalDelete}
            toggle={() => {
              deleteToggle()
            }}
            centered={true}
          >
            <div className="modal-body">
              <Row>
                <Col className="col-12">
                  <div className="d-flex align-items-center flex-column">
                    <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                    <h3>Are you sure?</h3>
                    <h5>{"You won't be able to revert this!"}</h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center">
                    <Button
                      type="button"
                      onClick={() => {
                        setModalDelete(false)
                      }}
                      className="btn btn-inactive waves-effect m-2"
                      data-dismiss="modal"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => handleCancel()}
                    >
                      Yes, Cancel it
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateNewAppointment
