import { gql } from "@apollo/client"
export const GET_PAYMENT_TYPES = gql`
  query PaymentSourceDropdown($argumentsInput: ArgumentsInput) {
    paymentSourceDropdown(argumentsInput: $argumentsInput) {
      code
      message
      status
      data {
        totalCount
        paymentSources {
          created_at
          is_direct
          name
          updated_at
          rate
          appointment_rate_type
          appointment_rate_couple_counselling_type
          id
        }
      }
    }
  }
`
export const SUBMIT_RATES = gql`
  mutation UpdateRate($updateRate: ArgumentsInput!) {
    updateRate(updateRate: $updateRate) {
      code
      message
      status
    }
  }
`
export const DELETE_PAYMENT_TYPE = gql`
  query DeletePaymentType($deletePaymentTypeId: Int!) {
    deletePaymentType(id: $deletePaymentTypeId) {
      code
      message
      status
    }
  }
`

export const ADD_PAYMENT_TYPE = gql`
  mutation CreatePaymentSource($createPaymentSource: ArgumentsInput!) {
    createPaymentSource(createPaymentSource: $createPaymentSource) {
      code
      message
      status
    }
  }
`

export const UPDATE_PAYMENT_TYPE = gql`
  mutation UpdateRate($updateRate: ArgumentsInput!) {
    updateRate(updateRate: $updateRate) {
      code
      message
      status
      __typename
    }
  }
`

export const GET_PAYMENT_TYPE_ID = gql`
  query GetPaymentSourceById($getPaymentSourceByIdId: Int!) {
    getPaymentSourceById(id: $getPaymentSourceByIdId) {
      data {
        paymentType {
          appointment_rate_type
          appointment_rate_couple_counselling_type
          id
          is_direct
          name
          rate
          tax_percentage
        }
      }
    }
  }
`
