import { gql } from "@apollo/client"
export const GET_LOCATION = gql`
  query GetLocation($getLocationInput: CreateLocationInput!) {
    getLocation(getLocationInput: $getLocationInput) {
      id
      name
      location_id
      slug
      longitude
      latitude
      google_maps_url
      location_group
      item_id
      created_at
      updated_at
      status
      location_group_id {
        id
        name
      }
      address
    }
  }
`
export const SUBMIT_LOCATION = gql`
  mutation CreateLocation($createLocationInput: CreateLocationInput!) {
    createLocation(createLocationInput: $createLocationInput) {
      address
      google_maps_url
      item_id
      id
      latitude
      status
      location_group
      location_id
      longitude
      name
      slug
    }
  }
`
export const DEL_LOCATION = gql`
  mutation RemoveLocation($getLocationInput: CreateLocationInput!) {
    removeLocation(getLocationInput: $getLocationInput) {
      name
    }
  }
`
export const GET_ALL_ROOMS = gql`
query GetAllRooms {
  getAllRooms {
    data {
      rooms {
        id
        name
        location {
          name
        }
      }
    }
  }
}
`

export const GET_ALL_BLOCKED_ROOMS = gql`
query GetAllBlockedRooms {
  getAllBlockedRooms {
    code
    message
    status
    data {
      blockedRooms {
        id
        start_time
        end_time
        override_date
        is_blocked
        room {
          id
          name
          location {
            name
          }
        }
      }
    }
  }
}
`

export const BLOCK_OFF_ROOMS = gql`
  mutation BlockOffRoom($createAvailabilityInput: CreateAvailabilityInputArr) {
    blockOffRoom(createAvailabilityInput: $createAvailabilityInput) {
      code
      message
      status
    }
  }
`

export const GET_ROOMS_BY_LOCATION = gql`
  query GetRoomsByLocation($locationId: Int!) {
    getRoomsByLocation(location_id: $locationId) {
      data {
        is_closed_all
        roomavilabilitiesMultiple {
          day
          room {
            id
            name
          }
          availabilities {
            id
            is_closed
            start_time
            end_time
            week_day
          }
        }
        # rooms {
        #   id
        #   availability {
        #     id
        #     week_day
        #   }
        # }
      }
    }
  }
`

export const GET_ROOMS_BY_LOCATIONS = gql`
  query GetRoomsByLocation($getCalendarRooms: CreateAvailabilityInput) {
    getRoomsByLocation(getCalendarRooms: $getCalendarRooms) {
      data {
        is_closed_all
        roomavilabilitiesMultiple {
          day
          room {
            id
            name
            location{
              name
            }
          }
          availabilities {
            id
            is_closed
            start_time
            end_time
            week_day
          }
        }
        # rooms {
        #   id
        #   availability {
        #     id
        #     week_day
        #   }
        # }
      }
    }
  }
`

export const SET_ROOM = gql`
  mutation SetRoomAvailability(
    $createAvailabilityInput: CreateAvailabilityInputArr
  ) {
    setRoomAvailability(createAvailabilityInput: $createAvailabilityInput) {
      id
    }
  }
`

export const DELETE_BLOCK_TIME = gql`
mutation DeleteBlockedTimeRoom($deleteBlockedTimeRoomId: Int!) {
  deleteBlockedTimeRoom(id: $deleteBlockedTimeRoomId) {
    code
    message
    status
  }
}
`

export const SET_OVERRIDE_HOURS_DATE = gql`
  mutation SetOverrideHoursRoomForDate(
    $setOverrideHoursRoomForDate: CreateAvailabilityInputArr
  ) {
    setOverrideHoursRoomForDate(
      setOverrideHoursRoomForDate: $setOverrideHoursRoomForDate
    ) {
      code
      message
      status
    }
  }
`

export const CREATE_ROOM = gql`
  mutation CreateRoom($createRoomInput: roomInput!) {
    CreateRoom(createRoomInput: $createRoomInput) {
      code
      message
      status
      data {
        room {
          end_time
          id
          name
          start_time
          created_at
          updated_at
        }
      }
    }
  }
`
export const DELETE_ROOM = gql`
  mutation DeleteRoom($deleteRoomId: Int!) {
    deleteRoom(id: $deleteRoomId) {
      code
      message
      status
    }
  }
`
export const GET_CALENDAR_ROOM = gql`
  query GetCalendarRooms($getCalendarRooms: CreateAvailabilityInput) {
    getCalendarRooms(getCalendarRooms: $getCalendarRooms) {
      data {
        is_closed_all
        roomavilabilitiesMultiple {
          day
          room {
            id
            name
            location {
              name
            }
          }

          availabilities {
            id
            override_id
            start_time
            is_closed
            end_time
            week_day
          }
        }
      }
    }
  }
`
export const GET_ROOM_BLOKED_TIME = gql`
query GetRoomBlockedTime($getRoomBlockedTimeId: Int!) {
  getRoomBlockedTime(id: $getRoomBlockedTimeId) {
    id
    room_id
    start_time
    end_time
    override_date
    room {
      id
      name
    }
  }
  }
`
export const GET_MONTH_WISE_ROOM = gql`
  query GetMonthWiseAvailabilitiesRoomView($argumentsInput: ArgumentsInput) {
    getMonthWiseAvailabilitiesRoomView(argumentsInput: $argumentsInput) {
      data {
        roomavilabilitiesMultiple {
          date
          is_closed_all_for_whole_day
          availabilities {
            override_id
            id
            is_closed
            end_time
            start_time
          }
        }
      }
    }
  }
`
