import React, { useEffect, useRef, useState } from "react"
import { getParticipantNameFromTokenIdentity } from "../../functions/functions"
import LottieAnimation from "../../components/LottieAnimation"

export default function Video({
  user,
  isVideoEnabled,
  isAudioEnabled,
  appointmentDetails,
}) {
  useEffect(() => {
    document.body.classList.add("isStatusBar")
  }, [])

  const audioRef = useRef()
  const videoRef = useRef()
  const [audioTracks, setAudioTracks] = useState([])
  const [videoTracks, setVideoTracks] = useState([])

  useEffect(
    function storeAVTracksForUser() {
      let initialAudioTracks = Array.from(user.audioTracks.values())
        .map(publication => publication.track)
        .filter(track => track !== null)
      let initialVideoTracks = Array.from(user.videoTracks.values())
        .map(publication => publication.track)
        .filter(track => track !== null)
      setAudioTracks(initialAudioTracks)
      setVideoTracks(initialVideoTracks)

      function trackSubscribed(track) {
        if (track.kind === "video") {
          setVideoTracks(tracks => [...tracks, track])
        }
        if (track.kind === "audio") {
          setAudioTracks(tracks => [...tracks, track])
        }
      }

      function trackUnsubscribed(track) {
        if (track.kind === "video") {
          setVideoTracks(tracks => tracks.filter(n => n !== track))
        }
        if (track.kind === "audio") {
          setAudioTracks(tracks => tracks.filter(n => n !== track))
        }
      }

      // add new tracks to local state as they are subscribed to
      user.on("trackSubscribed", trackSubscribed)
      user.on("trackUnsubscribed", trackUnsubscribed)

      return () => {
        setAudioTracks([])
        setVideoTracks([])
        user.removeAllListeners()
      }
    },
    [user, isVideoEnabled]
  )

  useEffect(
    function attachVideoToDOM() {
      const videoTrack = videoTracks[0]
      if (videoTrack) {
        videoTrack.attach(videoRef.current)
        return () => {
          videoTrack.detach()
        }
      }
    },
    [videoTracks]
  )

  useEffect(
    function attachAudioToDOM() {
      const audioTrack = audioTracks[0]
      if (audioTrack) {
        audioTrack.attach(audioRef.current)
        return () => {
          audioTrack.detach()
        }
      }
    },
    [audioTracks]
  )

  const name = getParticipantNameFromTokenIdentity(user.identity)

  return (
    <>
      {!!user && (
        <div
          className={`local-participant ${
            isAudioEnabled ? "audio-enabled" : "audio-disabled"
          } ${isVideoEnabled ? "video-enabled" : "video-disabled"}`}
        >
          <audio ref={audioRef} />
          {isVideoEnabled ? (
            <>
              <video
                ref={videoRef}
                className="video-stream local-video"
              />
              <div className="mic-status">
                {isAudioEnabled ? (
                  <i className="fa fa-microphone"></i>
                ) : (
                  <i className="fa fa-microphone-slash"></i>
                )}
              </div>
            </>
          ) : (
            <div className="avatarContainer">
              <LottieAnimation
                firstName={name.initials}
                lastName=""
                enableAnimation={isAudioEnabled}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}
