import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { withRouter } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { CHANGE_PASSWORD } from "./gql/loginGql"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useHistory } from "react-router-dom"

const ChangePassword = props => {
  let history = useHistory()

  const [inProgress, setInProgress] = useState(false)
  const [initChangePasswordInput, setInitChangePasswordInput] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initChangePasswordInput,
    validationSchema: Yup.object({
      old_password: Yup.string()
        .required("Please Enter Old Password")
        .min(6, "Must be 6 characters or more"),
      new_password: Yup.string()
        .required("Please Enter New Password")
        .min(6, "Must be 6 characters or more"),
      confirm_password: Yup.string()
        .required("Please Enter Confirm Password")
        .min(6, "Must be 6 characters or more")
        .oneOf([Yup.ref("new_password"), null], "New passwords must match"),
    }),
    onSubmit: values => {
      console.log(values)
      handleSubmit(values)
    },
  })

  const handleSubmit = async values => {
    let updatePasswordInput = {
      old_password: values.old_password,
      new_password: values.new_password,
    }
    try {
      console.log(updatePasswordInput)
      setInProgress(true)
      await submitMutation({
        variables: {
          updatePasswordInput: updatePasswordInput,
        },
      })
      validation.resetForm()
    } catch (e) {
      setInProgress(false)
      console.log(e)
    }
  }

  const [submitMutation, submitResponse] = useMutation(CHANGE_PASSWORD)
  React.useEffect(() => {
    setInProgress(false)
    if (submitResponse.data && submitResponse.data.changePassword.status) {
      toast.success(submitResponse.data.changePassword.message)
      setTimeout(() => {
        logout()
      }, 3000)
    } else {
      if (submitResponse.data && submitResponse.data.changePassword) {
        toast.error(submitResponse.data.changePassword.message)
      }
    }
  }, [submitResponse.data])

  function logout() {
    localStorage.removeItem("token")
    localStorage.removeItem("is_password_reset")
    localStorage.removeItem("authUser")
    history.push("/login")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* Render Breadcrumb */}
          <Row className="align-items-center justify-content-center">
            <Col xl="12">
              <div className="page-title-box d-flex align-items-center ">
                <i
                  className="waves-effect card-title-main mdi mdi-arrow-left font-size-22 mr-5"
                  onClick={() => history.push("/")}
                  id="backArrow"
                ></i>
                <h4 className="mb-0 card-title-main errorBoundary-ml-10">
                  Change Password
                </h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center ">
            <Col xl="12">
              <Card className="transparent-bg-card">
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="">
                      <Col
                        xl="4"
                        className="mt-3"
                        md={{
                          offset: 0,
                          size: 6,
                        }}
                      >
                        <div className="form-group form-custom-white-inputs">
                          <Label className="form-label">Old Password</Label>
                          <Input
                            name="old_password"
                            className="form-control default-input-style"
                            placeholder="Enter Old Password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.old_password || ""}
                            invalid={
                              validation.touched.old_password &&
                              validation.errors.old_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.old_password &&
                          validation.errors.old_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.old_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl="4"
                        className="mt-3"
                        md={{
                          offset: 0,
                          size: 6,
                        }}
                      >
                        <div className="form-group form-custom-white-inputs">
                          <Label className="form-label">New Password</Label>
                          <Input
                            name="new_password"
                            className="form-control w-100 default-input-style"
                            placeholder="Enter New Password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.new_password || ""}
                            invalid={
                              validation.touched.new_password &&
                              validation.errors.new_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.new_password &&
                          validation.errors.new_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.new_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col
                        xl="4"
                        className="mt-3"
                        md={{
                          size: 6,
                        }}
                      >
                        <div className="form-group form-custom-white-inputs">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="confirm_password"
                            className="form-control default-input-style"
                            placeholder="Enter Confirm Password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ""}
                            invalid={
                              validation.touched.confirm_password &&
                              validation.errors.confirm_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirm_password &&
                          validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirm_password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={4}>
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            color="danger"
                          >
                            Update
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ChangePassword)
