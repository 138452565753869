import React, { useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import style from "../User/style.module.scss"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  ADD_DOMAIN,
  CHECK_DOMAIN_STATUS,
  REMOVE_DOMAIN,
  VERIFY_DOMAIN,
} from "./gql/Gql"
import verified from "../../assets/images/verified.JPG"
import notVerifired from "../../assets/images/notVerifired.JPG"
import Tooltip from "@material-ui/core/Tooltip"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loader from "../../components/Common/Loader"
const DomainEmail = () => {
  let { id } = useParams()
  const decodedId = id && atob(id)
  id = decodedId
  let history = useHistory()
  let domainValue = new URLSearchParams(location.search).get("ses")
  const decodedSes = domainValue && atob(domainValue)
  domainValue = decodedSes
  const [domainName, setDomainName] = useState(null)
  const [domainStatusData, setDomainStatusData] = useState([])
  const [domainStatus, setDomainStatus] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [isDomainAvailable, setIsDomainAvailable] = useState(false)
  const handleDomainName = e => {
    setDomainName(e.target.value.toLowerCase())
  }

  const [getDomainStatus, getDomainStatusRes] = useLazyQuery(
    CHECK_DOMAIN_STATUS,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [addDomain, addDomainRes] = useMutation(ADD_DOMAIN)
  const [verifyDomain, verifyDomainRes] = useMutation(VERIFY_DOMAIN)
  const [removeDomainName, removeDomainNameRes] = useMutation(REMOVE_DOMAIN)

  const getDomainStatusFun = () => {
    if (domainName) {
      setIsLoading(true)
      addDomain({
        variables: {
          organizationInput: {
            domain: domainName,
            organization_id: parseInt(id),
          },
        },
      })
    } else {
      toast.error("Please add Domain name")
    }
  }
  const getDomainStatusDetailsFun = () => {
    setIsLoading(true)
    getDomainStatus({
      variables: {
        organizationInput: {
          domain: domainName,
        },
      },
    })
  }

  useEffect(() => {
    if (
      addDomainRes?.data &&
      addDomainRes?.data?.addDomainDkim &&
      addDomainRes?.data?.addDomainDkim?.status
    ) {
      setIsLoading(false)
      toast.success(addDomainRes?.data?.addDomainDkim?.message)
    } else {
      setIsLoading(false)
    }
    getDomainStatusDetailsFun()
  }, [addDomainRes.data])

  const handleCopyClick = async text => {
    try {
      await navigator.clipboard.writeText(text)
    } catch (error) {
      console.error("Unable to copy to clipboard", error)
    }
  }

  useEffect(() => {
    if (
      getDomainStatusRes.data &&
      getDomainStatusRes?.data?.checkDkimVerificationStatus?.status &&
      getDomainStatusRes?.data?.checkDkimVerificationStatus?.code === 200
    ) {
      const statusData =
        getDomainStatusRes?.data?.checkDkimVerificationStatus?.data?.ses_domain
          ?.dkim_keys
      setDomainStatusData(statusData)
      setDomainStatus(
        getDomainStatusRes?.data?.checkDkimVerificationStatus?.data?.ses_domain
          ?.status
      )
      setIsLoading(false)
    } else {
      setDomainStatusData([])
      setDomainStatus(null)
      setIsLoading(false)
    }
  }, [getDomainStatusRes.data])

  const varifyDomain = () => {
    verifyDomain({
      variables: {
        organizationInput: {
          domain: domainName,
        },
      },
    })
  }

  useEffect(() => {
    if (
      verifyDomainRes.data &&
      verifyDomainRes?.data?.verifyDomain?.data &&
      verifyDomainRes?.data?.verifyDomain?.data?.ses_domain
    ) {
      toast.success("The domain has been sent for verification.")
    }
  }, [verifyDomainRes.data])

  const removeDomain = () => {
    if (domainName) {
      removeDomainName({
        variables: {
          organizationInput: {
            domain: domainName,
          },
        },
      })
    } else {
      toast.error("Please enter domain name!")
    }
  }

  useEffect(() => {
    if (
      removeDomainNameRes?.data &&
      removeDomainNameRes?.data?.removeDomain?.status
    ) {
      toast.success(removeDomainNameRes?.data?.removeDomain?.message)
      setDomainName("")
      setDomainStatus(null)
      setDomainStatusData([])
      setIsDomainAvailable(false)
    } else {
      toast.error(removeDomainNameRes?.data?.removeDomain?.message)
    }
  }, [removeDomainNameRes.data])

  useEffect(() => {
    if (decodedSes) {
      setDomainName(decodedSes)
      setIsDomainAvailable(true)
      setIsLoading(true)
      getDomainStatus({
        variables: {
          organizationInput: {
            domain: decodedSes,
          },
        },
      })
    }
  }, [decodedSes])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    Domain Verification
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-lg-12 col-sm-12 position-relative my-4">
              <Row className="d-flex justify-content-center">
                <Col md={10}>
                  <span>
                    Improve deliverability & brand awareness by using your own
                    domain.
                  </span>
                  <div className="d-flex flex-md-row align-item-center mt-3">
                    <Input
                      name="name"
                      className="form-control w-75"
                      placeholder="Domain name (i.e. xyz.com)"
                      type="text"
                      value={domainName}
                      onChange={handleDomainName}
                    />
                    <Button
                      className="btn btn-primary domainBtn"
                      onClick={getDomainStatusFun}
                      disabled={isDomainAvailable}
                    >
                      Add domain <i className="uil uil-arrow-right" />
                    </Button>
                  </div>
                </Col>
                {domainStatusData.length > 0 && (
                  <>
                    <Col md={10} className="mt-5 mx-1">
                      <p className="h4">DNS settings</p>
                      <span>
                        Head over to your DNS provider and add DKIM and
                        Return-Path DNS records to verify your domain and ensure
                        flawless delivery.
                      </span>
                    </Col>
                    <Col md={10} className="mt-3">
                      <div>
                        <p className="h5">Identity status</p>
                      </div>
                      <div className="d-flex flex-row align-item-center">
                        <img
                          src={
                            domainStatus === "success" ? verified : notVerifired
                          }
                          alt=""
                          height="30"
                        />
                        <p className="text-dark mt-1 mx-1">
                          {domainStatus === "success"
                            ? "Verified"
                            : "Not Verifired"}
                        </p>
                      </div>
                    </Col>
                    <Col md={10} className="mt-3 mx-1">
                      <Row className="d-none d-md-flex border-bottom border-black mb-2 pb-2">
                        <Col className=" h5" md={2}>
                          Type
                        </Col>
                        <Col className=" h5" md={5}>
                          Hostname
                        </Col>

                        <Col className=" h5" md={5}>
                          Add this value
                        </Col>
                      </Row>
                      {domainStatusData.length > 0 ? (
                        domainStatusData?.map(elem => (
                          <>
                            <Row key={elem.id}>
                              {/* <Col className="" md={2}>
                            <div className="d-flex align-items-center ">
                              <div>
                                <img
                                  src={
                                    domainStatus === "success"
                                      ? verified
                                      : notVerifired
                                  }
                                  alt=""
                                  height="30"
                                />
                              </div>
                              <div className="mx-4 mt-2 d-flex align-items-center flex-column justify-content-start">
                                <p className="h5 m-0 ">DKIM</p>
                                <p className="text-dark">
                                  {domainStatus === "success"
                                    ? "Verified"
                                    : "Not Verifired"}
                                </p>
                              </div>
                            </div>
                          </Col> */}
                              <Col className="" md={2}>
                                <p className="d-flex d-md-none h6">Type : </p>
                                <span className="h6">CNAME</span>
                              </Col>
                              <Col className="" md={5}>
                                <div className="text-break">
                                  <p className="d-flex d-md-none h6">
                                    Hostname :{" "}
                                  </p>
                                  <span>
                                    {elem?.CNAME}
                                    <i
                                      className="fas fa-copy mx-2 cursor-pointer"
                                      onClick={() =>
                                        handleCopyClick(elem?.CNAME)
                                      }
                                    />
                                  </span>
                                </div>
                              </Col>

                              <Col className="" md={5}>
                                <div className="text-break">
                                  <p className="d-flex d-md-none h6">
                                    Value :{" "}
                                  </p>
                                  <span>
                                    {elem?.Value}{" "}
                                    <i
                                      className="fas fa-copy mx-2 cursor-pointer"
                                      onClick={() =>
                                        handleCopyClick(elem?.Value)
                                      }
                                    />
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <hr className="domainElements"></hr>
                          </>
                        ))
                      ) : isLoading ? (
                        <div className="d-flex justify-content-center">
                          <Loader />
                        </div>
                      ) : (
                        <p className="text-center h5 mt-3">No Data Found!</p>
                      )}
                    </Col>
                    <Col md={10} className="mt-5">
                      {domainStatus === "pending" && (
                        <div>
                          <p className="CloudFlareAlert text-white">
                           <span className="font-weight-bold">CloudFlare users:</span>  Please make sure to disable the
                            proxy for the CNAME DNS record.
                          </p>
                          <p className="h5"> Note : </p>
                          <p className="h6">
                            It may take up to 48 hours for the DNS changes to be
                            recognized.
                          </p>
                        </div>
                      )}
                      <div className="mt-5">
                        {domainStatus === "pending" && (
                          <Button
                            className="btn btn-primary"
                            onClick={varifyDomain}
                          >
                            Verify Domain{" "}
                            <i className="fas fa-check mx-2 cursor-pointer" />
                          </Button>
                        )}
                        {domainStatus === "success" && (
                          <Button
                            className="waves-effect waves-light mx-5btn btn-inactive btn-lg ms-2"
                            disabled={true}
                          >
                            Domain verified
                            <i className="fas fa-check mx-2 cursor-pointer" />
                          </Button>
                        )}

                        {domainStatus && (
                          <Button
                            className="waves-effect waves-light mx-5btn btn-inactive btn-lg ms-2"
                            onClick={removeDomain}
                          >
                            Remove Domain{" "}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DomainEmail
