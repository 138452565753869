import React, { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "reactstrap"
import { capitalizeFirstLetter } from "../../functions/functions"
import { useLazyQuery } from "@apollo/client"
import { CHECK_PAYMENT_METHOD } from "./gql/Gql"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import "react-toastify/dist/ReactToastify.css"
import { toast } from "react-toastify"

const CardWarning = ({ isOpen, setIsOpen, setWarningMsg, appointmentDetails, userId }) => {
  const togglePopup = () => {
    setIsOpen(false)
    setWarningModal(!warningModal)
  }
  const history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)

  const [isPaymentMethod, setIsPaymentMethod] = useState([])
  const [warningModal, setWarningModal] = useState(false)

  const [checkPaymentMethod, checkPaymentMethodRes] = useLazyQuery(
    CHECK_PAYMENT_METHOD,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [getPaymentMethod, getPaymentMethodRes] = useLazyQuery(
    CHECK_PAYMENT_METHOD,
    {
      fetchPolicy: "no-cache",
    }
  )

  const recheckPaymentMethod = () => {
    if(appointmentDetails?.client?.id){
      checkPaymentMethod({
        variables: {
          clientId: JSON.stringify(appointmentDetails?.client?.id),
        },
      })  
    }else{
      checkPaymentMethod({
        variables: {
          clientId: JSON.stringify(userData?.id),
        },
      })
    }
  }

  const getPaymentMethodFun = () => {
    if(appointmentDetails?.client?.id){
      getPaymentMethod({
        variables: {
          clientId: JSON.stringify(appointmentDetails?.client?.id),
        },
      })  
    }else{
      getPaymentMethod({
        variables: {
          clientId: JSON.stringify(userData?.id),
        },
      })
    }
  }

  useEffect(() => {
    getPaymentMethodFun()
  }, [appointmentDetails?.client?.id || userData?.id])

  useEffect(() => {
    if (checkPaymentMethodRes?.data) {
      const isCardAdded =
        checkPaymentMethodRes?.data?.getUserPaymentMethod?.data
          ?.userPaymentMethods
setIsPaymentMethod(isCardAdded)
      setIsOpen(isCardAdded.length === 0)
      if(isCardAdded.length > 0){
        toast.success("Client added their card details successfully.")
        if(history.location.pathname.startsWith("/appointments/join/")){
          setWarningMsg("success")
          setTimeout(() => {
            setWarningMsg(false)
          }, 3000);
        }
      }else{
        toast.error("This client has not added card details yet.")
        if(history.location.pathname.startsWith("/appointments/join/")){
        setWarningMsg("error")
        setTimeout(() => { 
          setWarningMsg(false)
        }, 3000);
      }

      }
    }
  }, [checkPaymentMethodRes.data])


  useEffect(() => {
    if (getPaymentMethodRes?.data) {
      const isCardAdded =
        getPaymentMethodRes?.data?.getUserPaymentMethod?.data
          ?.userPaymentMethods
      setIsPaymentMethod(isCardAdded)
      setIsOpen(isCardAdded.length === 0)
    }
  }, [getPaymentMethodRes.data])

  const openCardDetails = () => {
    if (history.location.pathname.startsWith("/appointments/join/")) {
      window.open("/card-details", "_blank")
    } else {
      history.push(`/card-details`)
    }
  }

  const warningModalToggle = () => {
    setWarningModal(!warningModal)
  }
  return (
    <>
      {isPaymentMethod.length == 0 ? (
        <>
        <div className={`popup-container ${isOpen ? "open" : ""}`}>
          <div className="alert alert-danger p-2">
            <Row className="d-flex flex-row justify-content-center align-items-center">
              <Col xs={12} md={9}>
                <p className={`p-2 popup-details ${isOpen ? "open" : ""} h6`}>
                  {userId === 3 && "Your "}Payment information is currently absent{" "}
                  {userId !== 3 &&
                    (appointmentDetails?.first_name
                      ? `for ${capitalizeFirstLetter(
                          appointmentDetails?.first_name
                        )}`
                      : "for this client ")}{" "}
                  {userId !== 3 && appointmentDetails?.last_name
                    ? capitalizeFirstLetter(appointmentDetails?.last_name)
                    : ""}
                  . Consequently, Appointments cannot be automatically processed for payment.{" "}
                  {userId === 3
                    ? "Please kindly add the payment option by clicking on Add button."
                    : "Please reach out to the client and kindly arge them to add their payment method via their dashboard."}
                </p>
              </Col>
              <Col xs={12} md={2} className="d-flex justify-content-end">
                {userId === 3 ? (
                  <Button
                    className="btn btn-primary"
                    onClick={() => openCardDetails()}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    className="btn btn-sm btn-primary"
                    onClick={() => recheckPaymentMethod()}
                  >
                    Recheck
                  </Button>
                )}
              </Col>
              <Col
                xs={12}
                md={1}
                className="close-button d-flex justify-content-end align-items-center"
              >
                <i
                  className="waves-effect mdi mdi-close font-size-20 text-dark"
                  onClick={()=>setWarningModal(true)}
                />
              </Col>
            </Row>
          </div>
        </div>
        <Modal
        isOpen={warningModal}
        toggle={() => {
          warningModalToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setWarningModal(false)
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => togglePopup()}
                >
                  Yes, Close it
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CardWarning
