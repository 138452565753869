import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  Row,
  UncontrolledTooltip,
  Spinner,
  Input,
  Label,
} from "reactstrap"
import Table from "../../components/Common/Table"
import { hasPermission, sortCaret } from "../../functions/functions"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_COUNSELORS, SYNC_API_DATA } from "./gql/Gql"
import debounce from "lodash.debounce"
import { useHistory } from "react-router-dom"
import moment from "moment"
import classNames from "classnames"
import useStyles from "./styles"
import DatePicker from "react-datepicker"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loader from "../../components/Common/Loader"
import editIcon from "../../assets/images/dashboard-icons/edit.png"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PageNotFound from "../404"
import { DELETE_USER } from "../User/gql/userGql"
import DeleteCounsellor from "./DeleteCounsellor"
const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  <div className="text-center d-grid-pl-5">
    {columns
      .map(column => ({
        ...column,
        toggle: toggles[column.dataField],
      }))
      .map((column, index) =>
        index > 0 ? (
          <div
            key={column.dataField}
            className="form-check form-switch form-switch-sm"
          >
            <Label check>
              <Input
                type="checkbox"
                key={column.dataField}
                name={column.dataField}
                inline
                // label={column.text}
                aria-checked={column.toggle ? "true" : "false"}
                className="form-check-input"
                id={column.dataField}
                onChange={e => onColumnToggle(column.dataField)}
                onClick={e => onColumnToggle(column.dataField)}
                checked={column.toggle}
                disabled={column.disabled}
              />{" "}
              <span className="defaultText">
              {column.text}</span>
            </Label>
          </div>
        ) : null
      )}
  </div>
)
const CounselorList = () => {
  let history = useHistory()
  const classes = useStyles()
  const [isSpinner, setIsSpinner] = useState(false)
  const [isSyncData, setIsSyncData] = useState(false)
  const [totalCount, setTotalCount] = useState(20)
  const [counselors, setCounselors] = useState([])
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    sortBy: "created_at",
    sortOrder: "DESC",
  })

  const handleShowDetailsClick = row => {
    const encodedId = btoa(row.id)
    history.push(`/counsellors/${encodedId}`)
  }

  const [getCounselorList, listResponse] = useLazyQuery(GET_COUNSELORS, {
    fetchPolicy: "no-cache",
  })
  const [syncDataApi, syncData] = useLazyQuery(SYNC_API_DATA, {
    fetchPolicy: "no-cache",
  })

  function getCounselorListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
    startDate,
    endDate
  ) {
    let inputValues = {
      page: page,
      limit: sizePerPage,
      search: searchText,
      sort: sortField,
      sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
    }
    if (startDate && endDate) {
      inputValues = {
        ...inputValues,
        ...{ start_date: startDate, end_date: endDate },
      }
    }

    setState(p => ({
      ...p,
      page,
      sizePerPage,
      sortBy: sortField,
      sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
    }))

    getCounselorList({
      variables: {
        argumentsInput: inputValues,
      },
    }).catch(() => {
      setState(p => ({
        ...p,
        page,
        sizePerPage,
        sortBy: sortField,
        sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
      }))
    })
  }

  React.useEffect(() => {
    getCounselorListFun(
      state.page,
      state.sizePerPage,
      "",
      state.sortBy,
      state.sortOrder
    )
  }, [])
  const [deleteRecord, setDeleteRecord] = useState({
    id: null,
  })
  const [inProgress, setInProgress] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteMutation, deleteResponse] = useMutation(DELETE_USER)
  function deleteToggle() {
    setModalDelete(!modalDelete)
  }
  const handleDelete = async () => {
    const storedData = localStorage.getItem("orgId")
    const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
    try {
      setInProgress(true)
      await deleteMutation({
        variables: {
          argumentsInput: {
            id: deleteRecord.id,
            organization_id: orgId ? orgId : undefined
          },
        },
      })
    } catch (e) {
      setInProgress(false)
      console.log(e)
    }
    setDeleteRecord({
      id: null,
    })
    deleteToggle()
  }
  const openDeleteModel = async id => {
    setModalDelete(!modalDelete)
    setDeleteRecord({
      id,
    })
  }

  React.useEffect(() => {
    if (deleteResponse?.data && deleteResponse?.data?.deleteUser?.status) {
      toast.success(deleteResponse?.data?.deleteUser?.message)
      getCounselorListFun(
        state.page,
        state.sizePerPage,
        "",
        state.sortBy,
        state.sortOrder
      )
      setInProgress(false)
    } else {
      if (deleteResponse?.data && deleteResponse?.data?.deleteUser) {
        toast.error(deleteResponse?.data?.deleteUser?.message)
      }
      setInProgress(false)
    }
  }, [deleteResponse.data])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getCalendars &&
      listResponse.data.getCalendars.status &&
      listResponse.data.getCalendars.code === 200
    ) {
      setIsSpinner(false)
      let arr = listResponse.data.getCalendars.data.calendars
      if (arr && arr.length > 0) {
        arr = arr.map((i, rowIndex) => ({
          ...i,
          hash: rowIndex + 1 + state.sizePerPage * (state.page - 1),
        }))
      }
      setCounselors(arr)
      setTotalCount(listResponse.data.getCalendars.data.totalCount)
    } else if (
      listResponse.data &&
      listResponse.data.getCalendars &&
      !listResponse.data.getCalendars.status &&
      listResponse.data.getCalendars.code !== 200
    ) {
      setIsSpinner(false)
      setCounselors([])
      setTotalCount(0)
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  const [columns, setColumns] = useState([
    {
      text: "#",
      dataField: "hash",
      width: 150,
    },
    {
      text: "Name",
      dataField: "user",
      disabled: true,
      formatter: (cell, row) => {
        return (
          <div
          >
            {cell && `${cell.first_name || ''} ${cell.last_name || ''}`  }
          </div>
        )
      },
    },
    {
      text: "Calendar Name",
      dataField: "name",
      sort: true,
      disabled: true,
      formatter: (cell, row) => {
        return (
          <div className="border-dark cursor-pointer text-capitalize border-bottom" onClick={() => handleShowDetailsClick(row)}>
            {cell}
          </div>
        )
      },
      headerStyle: {
        cursor:"pointer"
      },
    },
    {
      text: "Slug",
      dataField: "slug",
      disabled: true,
    },
    {
      text: "Email Address",
      dataField: "email",
      width: 270,
      sortCaret: sortCaret,
    },
    {
      text: "Total Clients",
      dataField: "clientCount",
      width: 270,
      headerAlign: "center",
      align: "center",
    },
    {
      text: "Counsellor's Share (In%)",
      dataField: "share_percentage",
      width: 270,
      headerAlign: "center",
      align: "center",
      hidden: true,
    },
    {
      text: "External Amount (In$)",
      dataField: "external_amount",
      width: 270,
      headerAlign: "center",
      align: "center",
      hidden: true,
    },
    {
      text: "Service Fee (In%)",
      dataField: "service_fee",
      width: 270,
      headerAlign: "center",
      align: "center",
      hidden: true,
    },
    {
      text: "GST",
      dataField: "gst_enabled",
      width: 270,
      headerAlign: "center",
      align: "center",
      hidden: true,
      formatter: (cell, row) => {
        return cell ? (
          <span className="rounded-pill bg-info badge bg-secondary-12">Active</span>
        ) : (
          <span className="rounded-pill bg-danger badge bg-secondary font-size-12">Inactive</span>
        )
      },
    },
    {
      text: "Status",
      dataField: "is_published",
      headerAlign: "center",
      align: "center",
      hidden: true,
      formatter: (cell, row) => {
        return cell ? (
          <span className="rounded-pill bg-info badge bg-secondary font-size-12">Published</span>
        ) : (
          <span className="rounded-pill bg-danger badge bg-secondary font-size-12">Unpublished</span>
        )
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <Button
              onClick={() => handleEditClick(row)}
              className="edit-btn btn-primary btn text-center cursor-pointer d-flex align-items-center counsellorDetailsBtn"
            >
              Details
              <i className="mdi mdi-chevron-right ms-3 mt-1"></i>
            </Button>
            <a
          onClick={() => openDeleteModel(row.user.id)}
          className="text-dark  delete-btn cursor-pointer counsellorDeleteIcon"
        >
          <span className="mx-1 mdi mdi-trash-can-outline font-size-20"></span>
        </a>
          </div>
        )
      },
    },
  ])

  useEffect(() => {
    let viewMore = localStorage.getItem("view_more")
    if (viewMore) {
      viewMore = JSON.parse(viewMore)
      columns.map((item,key)=>{
        if(viewMore[key] && viewMore[key].hidden){
          item.hidden = viewMore[key].hidden
        }else{
          item.hidden = false
        }
          return item
      })
      setColumns(columns)
    }
  }, [])

  const myColumnToggle = df => {
    var newTableColumns = columns.map(val => {
      if (val.dataField === df) {
        val.hidden = !val.hidden
      }
      return val
    })
    localStorage.setItem("view_more", JSON.stringify(newTableColumns))
    setColumns(newTableColumns)
  }
  const handleSync = () => {
    toast.info("Data sync with acuity started")

    syncDataApi().then(res => {
      if (res.data && res.data.SyncDatatoAucityTo.status) {
        setTimeout(() => {
          toast.success('Counsellor synced with acuity. Please reload the page. ')
        }, 5000)
      }
    })
  }

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="m-0">
          {rowIndex + 1 + state.sizePerPage * (state.page - 1)}
        </p>
      </>
    )
  }

  const handleEditClick = row => {
    const encodedId = btoa(row.id);
    history.push(`/counsellors/${encodedId}/edit`)
  }

  const changeHandler = ({
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
  }) => {
    const field = sortField === null ? "created_at" : sortField
    getCounselorListFun(1, sizePerPage, searchText, field, sortOrder)
  }

  const delaySearch = useCallback(debounce(changeHandler, 1000), [])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setState(p => ({
      ...p,
      page,
      sizePerPage,
    }))
    const field = sortField === null ? "created_at" : sortField
    setIsSpinner(true)
    if (type === "search") {
      delaySearch({ page, sizePerPage, searchText, sortField, sortOrder })
    } else {
      getCounselorListFun(page, sizePerPage, searchText, field, sortOrder)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null

  return (
    <React.Fragment>
          {hasPermission() ? (
      <div className="page-content">
        <div className="container-fluid">
          <div className="justify-content-between w-100">
            <div className="p-2 row w-100">
              <h4 className="card-title-main">Counsellors</h4>
              <h5 className={`${style.subtitlemenu} card-para-main`}>
              List of all the counsellors registered with the {orgData?.name ? orgData?.name :"NFC"} and their
              details.
              </h5>
            </div>
            <Col sm={12} md={9} lg={9} xl={6}>
              <div
                className={classNames(
                  "page-title-box gap-2",
                  classes.filterContainer
                )}
              ></div>
            </Col>
          </div>
          <Row className="mg-top-default-ui">
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody className="table-custom-redesign">
                  <Table
                    data={counselors}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    isSearch={true}
                    isDisplayColumnList={true}
                    CustomToggleList={CustomToggleList}
                    myColumnToggle={myColumnToggle}
                    handleSync={handleSync}
                    isStriped={false}
                    isHover={false}
                    isBordereded={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <DeleteCounsellor deleteToggle={deleteToggle} modalDelete={modalDelete} inProgress={inProgress} handleDelete={handleDelete}/>
        </div>
      </div>
      ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  )
}

export default CounselorList
