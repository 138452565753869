import React, { useEffect, useMemo, useState } from "react"
import { Col } from "reactstrap"
import { useLazyQuery } from "@apollo/client"
import Loader from "../../../../components/Common/Loader"
import Preview from "../Preview/Preview"
import { GET_PREVIEW_DATA, GET_PREVIEW_INVOICE_NUMBER } from "../gql/gql"
import { convertLocaltoUTC } from "../../../../functions/functions"
import { useDispatch, useSelector } from "react-redux"
import {
  updateClientAppointmentsDetails,
  updatePreviewAllData,
  updatePreviewData,
} from "../../../../store/actions"
function PreviewInfoInvoice({
  selectedList,
  startDate,
  endDate,
  calendar_id,
  payment_id,
  selectedPreviewID,
  selectedClient,
}) {
  const clientAppointmentsData = useSelector(
    state => state.invoicingManagement.clientAppointments
  )

  const storeData = useMemo(() => {
    let listIndex = clientAppointmentsData.findIndex(
      n => n.id == selectedPreviewID.parent.id
    )
    let list = clientAppointmentsData[listIndex]
    let childIndex =
      list &&
      list.data &&
      list.data.findIndex(
        n => n.calendar_id == selectedPreviewID.child.calendar_id
      )
    return {
      parent: list,
      child: list?.data[childIndex],
      preview: list.preview && list.preview,
    }
  }, [JSON.stringify(clientAppointmentsData)])

  const [receiptData, setReceiptData] = useState({})
  const [isSpinner, setIsSpinner] = useState(true)
  const dispatch = useDispatch()
  const [deleted, setDeleted] = useState([])

  const handleDeleteData = () => {
    let preview = storeData?.preview
    let paymentTypeDataUpdated = storeData?.preview?.paymentTypeData.filter(
      (item, index) => {
        return !deleted.includes(index)
      }
    )
    preview.paymentTypeData = paymentTypeDataUpdated
    dispatch(
      updatePreviewAllData(
        selectedPreviewID.parent.id,
        selectedPreviewID.child.id,
        { changedObj: preview }
      )
    )
    setDeleted([])
  }

  //************************** Receipt ***************************** */

  const [getPreviewData, PreviewDataResponse] = useLazyQuery(GET_PREVIEW_DATA, {
    fetchPolicy: "no-cache",
  })
  const [getInvoiceNumber, InvoiceNumberResponse] = useLazyQuery(
    GET_PREVIEW_INVOICE_NUMBER,
    { fetchPolicy: "no-cache" }
  )

  function getPreviewDataFun() {
    getPreviewData({
      variables: {
        argumentsInput: {
          start_date: startDate
            ? convertLocaltoUTC(startDate, "YYYY-MM-DD HH:mm:ssZ", true)
            : null,
          end_date: endDate
            ? convertLocaltoUTC(endDate, "YYYY-MM-DD HH:mm:ssZ", false, true)
            : null,
          payment_id: parseInt(selectedPreviewID.payment_id),
          calendar_id: parseInt(selectedPreviewID.calendar_id),
          user_id: parseInt(selectedPreviewID.client_id),
        },
      },
    }).then(() => {
      getInvoiceNumberFun()
    })
  }

  function getInvoiceNumberFun() {
    getInvoiceNumber()
  }

  const onExportButtonApi = () => {
    getPreviewDataFun()
  }

  const handleUpdateRate = () => {
    getPreviewDataFun()
  }

  useEffect(() => {
    getPreviewDataFun()
  }, [])

  const onRateupdate = (value, row, index) => {
    let { rate, price, total, gst } = storeData.preview.paymentTypeData[index]
    value = parseFloat(value)
    price = value
    gst = (price * 5) / 100
    total = price + gst
    storeData.preview.paymentTypeData[index].price = price
    storeData.preview.paymentTypeData[index].gst = gst
    storeData.preview.paymentTypeData[index].total = total
    dispatch(
      updatePreviewAllData(
        selectedPreviewID.parent.id,
        selectedPreviewID.child.id,
        { changedObj: storeData.preview }
      )
    )
  }

  useEffect(() => {
    if (
      PreviewDataResponse.data &&
      PreviewDataResponse.data.getInvoicePreview
    ) {
      dispatch(
        updatePreviewData(
          selectedPreviewID.parent.id,
          selectedPreviewID.child.id,
          { changedObj: PreviewDataResponse?.data?.getInvoicePreview?.data }
        )
      )
      setReceiptData(PreviewDataResponse?.data?.getInvoicePreview?.data)
      setIsSpinner(false)
    } else {
      setIsSpinner(true)
      setReceiptData({})
    }
  }, [PreviewDataResponse.data])

  useEffect(() => {
    if (
      InvoiceNumberResponse.data &&
      InvoiceNumberResponse.data.getInvoiceNumber &&
      InvoiceNumberResponse.data.getInvoiceNumber.status &&
      InvoiceNumberResponse.data.getInvoiceNumber.code === 200
    ) {
      setReceiptData(p => {
        return {
          ...p,
          invoice_Number: parseInt(
            InvoiceNumberResponse.data.getInvoiceNumber.data.invoice_Number
          ),
        }
      })
      const changedObj = {
        ...receiptData,
        invoice_Number: parseInt(
          InvoiceNumberResponse.data.getInvoiceNumber.data.invoice_Number
        ),
      }
      dispatch(
        updatePreviewData(
          selectedPreviewID.parent.id,
          selectedPreviewID.child.id,
          { changedObj: changedObj }
        )
      )
    }
  }, [InvoiceNumberResponse.data])

  return (
    <>
      {isSpinner ? (
        <Col className="col-12 user-role-create-height-double ">
          <div className="spinner">
            <Loader />
          </div>
        </Col>
      ) : (
        <Preview
          data={receiptData && receiptData.paymentTypeData}
          preview={receiptData}
          handleUpdateRate={handleUpdateRate}
          selectedPreviewID={selectedPreviewID}
          storeData={storeData}
          onExportButtonApi={onExportButtonApi}
          onRateupdate={onRateupdate}
          setDeleted={setDeleted}
          deleted={deleted}
          handleDeleteData={handleDeleteData}
        />
      )}
    </>
  )
}

export default PreviewInfoInvoice
