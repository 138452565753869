import React from "react"
import "react-toastify/dist/ReactToastify.css"
import { withRouter } from "react-router-dom"
import { reportErrorSlack } from "../functions/functions"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    const { history } = props
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    if (
      process.env.REACT_APP_NODE_ENVIRONMENT &&
      (process.env.REACT_APP_NODE_ENVIRONMENT == "development" ||
        process.env.REACT_APP_NODE_ENVIRONMENT == "production") &&
      !window.location.href.includes("localhost")
    ) {
      let storage = localStorage.getItem("authUser")
      let obj = {
        url: window.location.href,
        message: error.message,
        storage: storage,
      }
      reportErrorSlack(
        JSON.stringify(obj),
        true,
        "functionName",
        "argumentsInput"
      ).then()
    } else {
      console.log("Error:::", error, info.componentStack)
    }
  }
  back = () => {
    return this.props.history.goBack()
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="page-content d-flex justify-content-center align-item-center text-center">
          <div>
            <div className="d-flex align-item-center">
              <img src="errorpage.png" width={"25"} />
              <div className="ml-3" style={{ marginLeft: "10px" }}>
                Oops! Something went wrong
              </div>
            </div>

            <button className="btn btn-primary" onClick={() => this.back()}>
              Go Back
            </button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
export default withRouter(ErrorBoundary)
