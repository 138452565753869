import { useLazyQuery, useMutation } from "@apollo/client"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Button,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import { GET_NOTIFICATIONS, READ_NOTIFICATION } from "./gql/notificationGql"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"
import { Divider } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600,
    fontSize: "20px",
  },

  adminTitle: {
    fontWeight: 600,
    fontSize: "20px",
  },

  notificationContainer: {
    fontSize: "18px",
    fontWeight: 500,
    margin: 0,
  },

  notificationAdminContainer: {
    padding: "10px 0 !important",
    fontSize: "14px",
    fontWeight: 400,
    margin: 0,
  },

  notificationTitle: {
    color: "#1D2939",
  },

  notificationAdminTitle: {
    fontSize: "15px",
    fontWeight: 500,
    margin: 0,
  },

  notificationContent: {
    color: "#1D2939",
    opacity: "50%",
  },

  detailBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "41px",
    background: "#153C27",
    borderRadius: "70px",
    "&:focus": {
      backgroundColor: "#153C27",
    },
    "&:hover": {
      backgroundColor: "#153C27",
    },
  },

  widgetBtn: {
    width: "116px",
    height: "34px",
    border: "1px solid #1D2939",
    borderRadius: "60px",
    background: "#FFF",
    "&:focus": {
      backgroundColor: "#FFF",
    },
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },

  widgetBtnText: {
    color: "#1D2939",
    fontWeight: 500,
    fontSize: "14px",
  },

  detailBtnText: {
    fontWeight: 500,
    fontSize: "16px",
  },
}))

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const classes = useStyles()
  const [notifications, setNotifications] = useState([])
  const params = new URLSearchParams(window.location.search)
  const pageNumber = params.get("page")
  const [state, setState] = useState({
    page: pageNumber ? parseInt(pageNumber) : 1,
    data: notifications,
    sizePerPage: 10,
  })
  const [isSpinner, setIsSpinner] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  // const unreadNotificationCount = useSelector(state => state.Login?.user?.unreadCount);
  const userData = useSelector(state => state.Login?.user?.user)
  let history = useHistory()

  const data = [
    {
      id: 1,
      name: "Tome Maverick",
      desc: "has joined NFC Platform as a Counsellor",
    },
    {
      id: 2,
      name: "Your next appointment",
      desc: "is with johnson family at 4:30 pm",
    },
    {
      id: 3,
      name: "Tome Maverick",
      desc: "Initial report are the clients whose initial screening is going on to detect the treatment",
    },
    {
      id: 4,
      name: "Tome Maverick",
      desc: "Past client are the clients to whom NFC is treated and are cured and not required",
    },
  ]

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        {userData.role.id === 1 && (
          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3 border-bottom">
              <Row className="align-items-center">
                <Col>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className={classNames("m-0", classes.adminTitle)}>
                      Notifications
                    </h6>
                    <img src={refresh} width="24px" height="24px" />
                  </div>
                </Col>
                {userData?.user_type !== "admin" &&
                  notifications &&
                  notifications.length > 0 && (
                    <div className="col-auto">
                      <a
                        onClick={() => handleNotificationRedirectClick()}
                        className="small cp"
                      >
                        {" "}
                        Mark all as read
                      </a>
                    </div>
                  )}
              </Row>
            </div>

            <div className="h325-p20">
              {data &&
                data.map((item, index) => (
                  <React.Fragment key={index}>
                    <a
                      onClick={() => handleNotificationRedirectClick(item)}
                      className="text-reset notification-item cp pb-0"
                    >
                      <div
                        className={classNames(
                          "d-flex align-items-start p-0 m-0"
                        )}
                      >
                        <p
                          className={classNames(
                            "p-4",
                            classes.notificationAdminContainer
                          )}
                        >
                          <b className={classes.notificationAdminTitle}>
                            {item.name}
                          </b>
                          <span className={classes.notificationContent}>
                            {" "}
                            {item.desc}
                          </span>
                        </p>
                      </div>
                    </a>
                    {index !== data.length - 1 ? (
                      <Divider className={`height-1 ${classes.divider}`} />
                    ) : null}
                  </React.Fragment>
                ))}
            </div>

            <div className="p-3 d-flex justify-content-start align-items-center">
              <Button size="sm" className={classes.detailBtn}>
                <span className={classes.detailBtnText}>View All</span>
              </Button>
            </div>
          </DropdownMenu>
        )}

        {userData.role.id !== 1 && (
          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
            <div
              className="p-3 border-bottom"
              style={{
                background: userData.role.id === 1 ? "#D9D9D9" : "#FFF",
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className={classNames("m-0", classes.title)}>
                      Notifications
                    </h6>
                    <img src={refresh} width="24px" height="24px" />
                  </div>
                </Col>
                {userData?.user_type !== "admin" &&
                  notifications &&
                  notifications.length > 0 && (
                    <div className="col-auto">
                      <a
                        onClick={() => handleNotificationRedirectClick()}
                        className="small cp"
                      >
                        {" "}
                        Mark all as read
                      </a>
                    </div>
                  )}
              </Row>
            </div>

            <SimpleBar className="simplebar-h230">
              {data &&
                data.map((item, index) => (
                  <a
                    onClick={() => handleNotificationRedirectClick(item)}
                    className="text-reset notification-item cp"
                    key={index}
                  >
                    <div className={classNames("d-flex align-items-start m-0")}>
                      <p className={classes.notificationContainer}>
                        <b className={classes.notificationTitle}>{item.name}</b>
                        <span className={classes.notificationContent}>
                          {" "}
                          {item.desc}
                        </span>
                      </p>
                    </div>
                  </a>
                ))}
            </SimpleBar>
            <div className="p-2 border-top d-flex justify-content-center align-items-center">
              <Button size="sm" className={classes.widgetBtn}>
                <span className={classes.widgetBtnText}>View Details</span>
              </Button>
            </div>
          </DropdownMenu>
        )}
      </Dropdown>
    </>
  )
}

export default NotificationDropdown
