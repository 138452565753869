import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import moment from "moment"
import style from "../style.module.scss"

//icons
import noApproval from "../../../assets/images/legend-icons/noApproval.png"
import submitted from "../../../assets/images/legend-icons/submitted.svg"
import activatedDeactivated from "../../../assets/images/legend-icons/activate-deactivated.svg"
import oneSessionOrWeek from "../../../assets/images/legend-icons/one-session-week.svg"
import twoSessionOrWeek from "../../../assets/images/legend-icons/two-sessions-week.svg"
import notPaid from "../../../assets/images/legend-icons/not-paid.svg"
import partiallyPaid from "../../../assets/images/legend-icons/partially-paid.svg"
import { useSelector } from "react-redux"

function LegendAction(props) {
  const userData = useSelector(state => state.Login?.user?.user)
  const { cell, row, rowIndex, formatExtraData } = props
  const {
    is_One_Week_Expiry,
    is_approved,
    is_document_submitted,
    is_request,
    is_two_week_expiry,
    is_no_payment,
    partial_paid_ids,
    charged_session,
  } = row.plan
  const isDirectClient = row?.plan?.payment_source?.is_direct
  const isPartiallyPaid = !isDirectClient
    ? partial_paid_ids
      ? partial_paid_ids.length > 0
      : false
    : false
  const isNotPaid = parseInt(row?.plan?.total_sessions) > parseInt(row?.plan?.charged_session)

  const expiryDate = moment(row?.plan?.expiry_date)
  const isICBCClient =
    row?.plan?.payment_source?.name === "ICBC" && row?.plan?.payment_source?.id === 3
  const sessionLeft = parseInt(row?.plan?.approved_sessions - row?.plan?.total_sessions)
  const now = moment()
  let isExpired = false
  let isTwoWeekAndSession = false
  let isOneWeekAndSession = false

  if (
    isICBCClient &&
    !expiryDate.isBefore(now) &&
    expiryDate.diff(now, "days") > 8 &&
    expiryDate.diff(now, "days") < 15
  ) {
    isTwoWeekAndSession = is_two_week_expiry
  } else if (sessionLeft === 2) {
    isTwoWeekAndSession = is_two_week_expiry
  } else if (
    isICBCClient &&
    !expiryDate.isBefore(now) &&
    expiryDate.diff(now, "days") <= 8
  ) {
    isOneWeekAndSession = is_One_Week_Expiry
  } else if (sessionLeft === 1) {
    isOneWeekAndSession = is_One_Week_Expiry
  } else if (
    row?.plan.total_sessions > parseInt(row?.plan.approved_sessions) ||
    (isICBCClient && expiryDate.isBefore(now)) ||
    expiryDate == now
  ) {
    isExpired = is_approved
  }

  return (
    <div className="d-flex gap-1 legend-list">
      {isDirectClient && isExpired && (
        <Tooltip
          placement="right"
          className={style.tooltip}
          title={"Used session > Approved session/Expiry date met"}
          arrow
        >
          <img src={noApproval} width="20px" height="20px" />
        </Tooltip>
      )}
      {isDirectClient && isTwoWeekAndSession && (
        <Tooltip
          placement="right"
          className={style.tooltip}
          title={"Session Expire in 2 week/ 2 Session Left"}
          arrow
        >
          <img src={twoSessionOrWeek} width="20px" height="20px" />
        </Tooltip>
      )}
      {isDirectClient && isOneWeekAndSession && (
        <Tooltip
          placement="right"
          className={style.tooltip}
          title={"Session Expire in 1 week/ 1 Session Left"}
          arrow
        >
          <img src={oneSessionOrWeek} width="20px" height="20px" />
        </Tooltip>
      )}
      {isDirectClient && is_document_submitted && userData.role.id === 1 && (
        <Tooltip
          placement="right"
          className={style.tooltip}
          title={"Paperwork Got Submitted"}
          arrow
        >
          <img src={submitted} width="20px" height="20px" />
        </Tooltip>
      )}
      {!isDirectClient && isNotPaid && (
        <Tooltip
          placement="right"
          className={style.tooltip}
          title={"Used session > Charged session"}
          arrow
        >
          <img src={notPaid} width="20px" height="20px" />
        </Tooltip>
      )}
      {!isDirectClient && isPartiallyPaid && (
        <Tooltip
          placement="right"
          className={style.tooltip}
          title={"Partially Paid"}
          arrow
        >
          <img src={partiallyPaid} width="20px" height="20px" />
        </Tooltip>
      )}
      {is_request && userData.role.id === 1 && (
        <Tooltip
          placement="right"
          className={style.tooltip}
          title={"Request to activate/deactivate"}
          arrow
        >
          <img src={activatedDeactivated} width="20px" height="20px" />
        </Tooltip>
      )}
    </div>
  )
}

export default LegendAction
