import { gql } from "@apollo/client"

export const GET_APPOINTMENTS = gql`
  query GetAppointments($argumentsInput: ArgumentsInput) {
    getAppointments(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        appointments_arr {
          user {
            first_name
            last_name
          }
          appointment {
          id
          appointment_id
          first_name
          last_name
          email
          phone
          canceled
          timezone
          utc_datetime
          end_time
          duration
          paid
          price
          participants
          is_feedback_submitted
          is_client_feedback_submitted
          is_counsellor_feedback_submitted
          recording {
            id
            composition {
              composition_sid
              status
              room_sid
              media_external_location
            }
          }
          payment_type {
            id
            is_direct
            name
          }
          appointment_has_feedback {
            id
            from {
              role {
                id
                role_name
              }
            }
            to {
              role {
                id
                role_name
              }
            }
          }
          
          type_of_appointment {
            id
            name
            color
            call_type
            google_address
          }
        }
      }
        totalCount
      }
    }
  }
`
export const GET_APPOINTMENT = gql`
  query GetAppointment($argumentsInput: ArgumentsInput) {
    getAppointment(argumentsInput: $argumentsInput) {
      code
      message
      data {
        appointment {
          participant {
            user {
              first_name
              last_name
              email
              mobile_number
              id
              age
              created_at
            }
          }
          id
          block_off_calendar {
            room {
              id
              name
              location {
                name
              }
            }
          }
          appointment_id
          first_name
          last_name
          canceled
          transactions {
            id
            payment_intent_id
            mode
            created_at
            extra_data
          }
          timezone
          email
          paid
          end_time
          utc_datetime
          notes
          duration
          participants
          price
          appointment_type
          phone
          type_of_appointment {
            id
            name
            color
            call_type
            google_address
          }
          payment_type {
            id
            is_direct 
          }
          therapist_calendar {
            id
            name
            timezone
            email
          }
          appointmentHasForms {
            id
            name
            forms {
              uid
              id
              value
              name
              field_id
            }
          }
          appointmentHasLabels {
            id
            color
            name
          }
          client {
            id
            age
            mobile_number
            created_at
            first_name
            last_name
            email
          }
        }
      }
      status
    }
  }
`

export const LINKED_ACCOUNT_STATUS = gql`
  query GetStripeAccountStatus($accountId: String!) {
    getStripeAccountStatus(account_id: $accountId) {
      message
      status
      data {
        statusObj
      }
    }
  }
  `

  export const GET_CONPOSITION_STATUS = gql`
  query GetCompositionStatus($compositionId: String!) {
    getCompositionStatus(composition_id: $compositionId) {
      code
      message
      data {
        composition {
          status
        }
        presigned_url
      }
      status
    }
  }
  `
  export const GET_RECORDING_BY_APPOINTMENT = gql`
  query GetRecordingsByAppointmentId($argumentsInput: ArgumentsInput) {
    getRecordingsByAppointmentId(argumentsInput: $argumentsInput) {
      code
      message
      status
      data {
        appointment {
        recording {
          id
          composition {
            composition_sid
              status
              room_sid
              media_external_location
              __typename
            }
          }
        }
        }
      }
    }
  `

  export const MAKE_PAYMENT = gql`
  mutation MakePayment($appointmentId: Float!) {
    makePayment(appointment_id: $appointmentId) {
      data {
        url
      }
    }
  }
`

export const REFUND_PAYMENT = gql`
mutation RefundPayment($paymentIntentId: String!) {
  refundPayment(payment_intent_id: $paymentIntentId) {
    code
    message
    status
    data {
      refundObj
    }
  }
}
`

export const CHECK_PAYMENT_METHOD = gql`
query GetUserPaymentMethod($clientId: String!) {
  getUserPaymentMethod(client_id: $clientId) {
    data {
      userPaymentMethods {
        client_id
        confirmation_method
        created_at
        customer_id
        extra_data
        id
        last4
        exp_month
        exp_year
        brand
        payment_method_id
        updated_at
      }
    }
  }
}
`

export const SAVE_PAYMENT_METHOD = gql`
mutation SaveClientPaymentMethod($paymentMethodId: String!, $clientId: String!) {
  saveClientPaymentMethod(payment_method_id: $paymentMethodId, client_id: $clientId) {
    data {
      userPaymentMethod {
        client_id
        client_secret
        confirmation_method
        created_at
        customer_id
        extra_data
        id
        payment_method_id
        updated_at
      }
    }
    status
    code
    message
  }
}
`

export const GET_STRIPE_TRANSACTION = gql`
query GetStripeTransactionForAppointment($appointmentId: Float!) {
  getStripeTransactionForAppointment(appointment_id: $appointmentId) {
    data {
      client_rate
      gst
      total
      wendy_fee
      clinic_amount
    }
  }
}
`

export const GET_COUNSELORS = gql`
  query Calendars($argumentsInput: ArgumentsInput) {
    getCalendarLegend(argumentsInput: $argumentsInput) {
      data {
        calendars {
          name
          timezone
          id
        }
      }
      code
      status
      message
    }
  }
`

export const GET_BLOCK_TIMESLOT = gql`
query GetBlockOffSlots($argumentsInput: ArgumentsInput) {
  getBlockOffSlots(argumentsInput: $argumentsInput) {
    code
    message
    data {
      block_off_slots {
        appointment_id
        calendar_id
        end_time
        start_time
        id
      }
    }
  }
}
`

export const GET_APPOINTMENT_BY_TYPE = gql`
  query GetAllAppointmentsTypeByCategory($argumentsInput: ArgumentsInput) {
    getAllAppointmentsTypeByCategory(argumentsInput: $argumentsInput) {
      code
      data {
        categoryTypeAppointmentTypes {
          category
          appointmentTypes {
            calendar_appoinement_type_price
            name
            id
            duration
            break_time
            price
            call_type
          }
        }
        categories
      }
      message
      status
    }
  }
`
export const GET_COUNSELLOR_BY_APPOINTMENTTYPE = gql`
  query GetallcalendarbyappointmentTypeid($argumentsInput: ArgumentsInput) {
    getallcalendarbyappointmentTypeid(argumentsInput: $argumentsInput) {
      code
      data {
        calendars {
          id
          name
          timezone
        }
        allAvailablePermission
      }
    }
  }
`
export const GET_ALL_TIMEZONE = gql`
  query GetAllTimeZones {
    getAllTimeZones {
      code
      message
      status
      data {
        allTimeZone {
          display
          zoneName
        }
      }
    }
  }
`

export const GET_AVAILABLITY_FILTER = gql`
  query GetAvailabilityByDatesFilter ($argumentsInput: ArgumentsInput!) {
    getAvailabilityByDatesFilter(argumentsInput: $argumentsInput) {
      code
      data {
        list
      }
      message
      status
    }
  }
`



export const CREATE_APPOINTMENT = gql`
mutation createAppointment($createAppointmentInput: CreateAppointmentDTO!) {
  createAppointment(createAppointmentInput: $createAppointmentInput) {
    code
    message
    status
    data {
      form_id
      appointmentType {
        longitude
        latitude
      }
      appointments {
        id
        utc_datetime
      }
    }
  }
}
`

export const CREATE_ADMIN_USER = gql`
mutation CreateAdminUser($createUserInput: createUserInput!) {
  createAdminUser(createUserInput: $createUserInput) {
    code
    status
    message
    data {
      user {
        id
        first_name
        last_name
        email
        mobile_number
      }
    }
  }
}
`


export const CANCEL_APPOINTMENT = gql`
mutation updateFromAppointment($updateAppointmentInput: UpdateAppointmentInput!) {
  updateFromAppointment(updateAppointmentInput: $updateAppointmentInput) {
    code
    message
    status
  }
}
`
export const UPDATE_APPOINTMENT = gql`
mutation UpdateAppointment($updateAppointmentInput: UpdateAppointmentInput!) {
  updateAppointment(updateAppointmentInput: $updateAppointmentInput) {
    code
    message
    status
  }
}
`


export const GET_AVAIL_TIME = gql`
  query GetAvailabilityForDate($argumentsInput: ArgumentsInput) {
    getAvailabilityForDate(argumentsInput: $argumentsInput) {
      data {
        availabilities  {
          id
          calendar_id
          week_day
          start_time
          end_time
        }
      }
    }
  }
`
export const GET_FORM_FIELDS = gql`
  query FindForms {
    findForms {
      data {
        formbuilders {
          id
          name
          data
        }
      }
    }
  }
`


export const GET_AVAILABLE_ROOMS = gql`
query GetAvailableRoomsForCounsellor($argumentsInput: CreateAvailabilityInputArr) {
  getAvailableRoomsForCounsellor(argumentsInput: $argumentsInput) {
    data {
      rooms {
        override_date
        rooms {
          id
        location {
          name
          id
        }
        name
        }
      }
    }
  }
}
`
export const GET_APPOINTMENT_TYPE = gql`
query GetAppointmentTypeById($getAppointmentTypeByIdId: Int!) {
  getAppointmentTypeById(id: $getAppointmentTypeByIdId) {
    data {
      appointmentType {
        id
        call_type
      }
    }
  }
}
`

export const GET_MONTHLY_AVAILABLITY = gql`
query getMonthlyAvailabilities($argumentsInput: ArgumentsInput) {
  getMonthlyAvailabilities(argumentsInput: $argumentsInput) {
    data {
      categoryMonthWise {
        monthWiseAvailablities {
          date
          end_time
          start_time
        }
        day
      }
    }
  }
}
`
