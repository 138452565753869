import React from "react"
import PropTypes from "prop-types"
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import classNames from "classnames"

function Dialog(props) {
  const {
    open,
    onToggle,
    validation,
    isShowBodyField,
    isChooseFile,
    isDisabled,
    isTitleFieldDisabled = false,
    size = "md",
  } = props

  return (
    <Modal isOpen={open} toggle={onToggle} size={size}>
      <ModalBody>
        <div className="w-100 text-end">
          <button
            type="button"
            className="btn-close"
            onClick={onToggle}
            aria-label="Close"
          ></button>
        </div>
        <Form
          className="needs-validation"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col md={12} className="mt-2 mb-2 relative">
              <Label>Email</Label>
              <Input
                name="email"
                className="form-control default-redesign-input"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation?.values?.email && (
                <img
                  alt="delete-icon"
                  className="delete-icon"
                  src="/delete-btn.svg"
                  onClick={() => validation.setFieldValue("email", "")}
                />
              )}
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={12} className={`mb-${isShowBodyField ? "1" : "3"}`}>
              <Label>Title</Label>
              <Input
                name="title"
                className="form-control default-redesign-input"
                type="text"
                disabled={isTitleFieldDisabled ? true : false}
                invalid={
                  validation.touched.title && validation.errors.title
                    ? true
                    : false
                }
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.title}
              />
              {validation.touched.title && validation.errors.title ? (
                <FormFeedback type="invalid">
                  {validation.errors.title}
                </FormFeedback>
              ) : null}
            </Col>
            {isShowBodyField && (
              <Col md={12} className="mb-2  ">
                <Input
                  className="form-control default-redesign-input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.body}
                  type="textarea"
                  rows="7"
                  name="body"
                  invalid={
                    validation.touched.body && validation.errors.body
                      ? true
                      : false
                  }
                />
                {validation.touched.body && validation.errors.body ? (
                  <FormFeedback type="invalid">
                    {validation.errors.body}
                  </FormFeedback>
                ) : null}
              </Col>
            )}

            {isChooseFile ? (
              <Col md={12} className="mb-2">
                <Label>File</Label>
                <Input
                  name="file"
                  type="file"
                  className={classNames(
                    "form-control default-redesign-input",
                    validation.errors.file && "is-invalid"
                  )}
                  onBlur={validation.handleBlur}
                  onChange={event => {
                    validation.setFieldValue(
                      "file",
                      event.currentTarget.files[0]
                    )
                  }}
                />
                <FormFeedback type="invalid" className="d-blcok">
                  {validation.errors.file}
                </FormFeedback>
              </Col>
            ) : (
              <Col md={12} className="mb-2">
                <Label className="d-flex align-items-center gap-1">
                  File:
                  <i className="uil-file invoice-list-fsize-20"></i>
                  <span>{validation.values.name}</span>
                </Label>
              </Col>
            )}

            <Col md={12} className="mt-2">
              <button
                type="submit"
                className="btn w-100 btn-primary"
                disabled={isDisabled}
              >
                SEND
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  isShowBodyField: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isChooseFile: PropTypes.bool.isRequired,
  validation: PropTypes.object.isRequired,
}

export default Dialog
