import React, { useEffect, useRef, useState } from "react"
import LottieAnimation from "../../components/LottieAnimation"
import { getParticipantNameFromTokenIdentity } from "../../functions/functions"

function RemoteVideo({
  user,
  isVideoEnabled,
  isAudioEnabled,
  appointmentDetails,
  participants,
  ind
}) {
  const audioRef = useRef()
  const videoRefs = useRef([])
  const [audioTracks, setAudioTracks] = useState([])
  const [videoTracks, setVideoTracks] = useState([])
  const [isVideoEnabled2, setIsVideoEnabled2] = useState(isVideoEnabled)
  const [isAudioEnabled2, setIsAudioEnabled2] = useState(isAudioEnabled)

  useEffect(() => {
    const initialAudioTracks = Array.from(user.audioTracks.values())
      .map(publication => publication.track)
      .filter(track => track !== null)
    const initialVideoTracks = Array.from(user.videoTracks.values())
      .map(publication => publication.track)
      .filter(track => track !== null)

    setAudioTracks(initialAudioTracks)
    setVideoTracks(initialVideoTracks)

    const trackSubscribed = track => {
      console.log("Remote - trackSubscribed", user.identity, track)
      if (track.kind === "video") {
        // Function to update or add the track based on its dimensions
        const updateOrAddTrack = () => {
          const existingTrackIndex = videoTracks.findIndex(
            t =>
              t.dimensions &&
              track.dimensions &&
              t.dimensions.width === track.dimensions.width &&
              t.dimensions.height === track.dimensions.height
          );
      
          if (existingTrackIndex !== -1) {
            console.log(
              "found same dimension track... replacing...",
              track.dimensions.width,
              track.dimensions.height
            );
            const newTracks = [...videoTracks];
            newTracks[existingTrackIndex] = track;
            setVideoTracks(newTracks);
          } else {
            console.log(
              "not available same dimension track... adding...",
              track.dimensions.width,
              track.dimensions.height
            );
            setVideoTracks([...videoTracks, track]);
          }
        };
      
        // If dimensions are already available, update or add the track immediately
        if (track.dimensions && track.dimensions.height) {
          updateOrAddTrack();
        } else {
          // Otherwise, wait for the dimensions to become available
          track.on("dimensionsChanged", updateOrAddTrack);
        }
      }
      
      if (track.kind === "audio") setAudioTracks(tracks => [...tracks, track])
    }

    const trackUnsubscribed = track => {
      console.log("Remote - trackUnsubscribed", user.identity, track)
      if (track.kind === "video")
        setVideoTracks(tracks => tracks.filter(t => t !== track))
      if (track.kind === "audio")
        setAudioTracks(tracks => tracks.filter(t => t !== track))
    }

    user.on("trackSubscribed", trackSubscribed)
    user.on("trackUnsubscribed", trackUnsubscribed)

    return () => {
      user.removeAllListeners()
    }
  }, [user, isVideoEnabled])

  useEffect(() => {
    const attachTrackToDOM = (track, ref) => {
      if (track) {
        track.attach(ref.current)
      }
    }

    if (videoTracks.length) {
      setIsVideoEnabled2(true)
      console.log("videoTracks", videoTracks, videoRefs)
      setTimeout(() => {
        for (let i = 0; i < videoTracks.length; i++) {
          videoTracks[i].attach(videoRefs.current[i])
        }
      }, 0)
    } else {
      setIsVideoEnabled2(false)
    }

    if (audioTracks.length) {
      setIsAudioEnabled2(true)
      setTimeout(() => {
        attachTrackToDOM(audioTracks[0], audioRef)
      }, 500)
    } else {
      setIsAudioEnabled2(false)
    }
  }, [audioTracks, videoTracks, videoRefs.current])

  const name = getParticipantNameFromTokenIdentity(user.identity)

  return (
    <>
      {user && (
        <div
          className={`${participants?.length <= 3 ? "remote-participant" : ""}  ${(participants?.length > 3 && participants?.length <= 4) ? "remote-participant2" : ""} ${(participants?.length > 4 && participants?.length <=8) ? "remote-participant3" : ""} ${participants?.length > 8 ? "remote-participant4" : ""} ${
            isAudioEnabled2 ? "audio-enabled" : "audio-disabled"
          } ${isVideoEnabled2 ? "video-enabled" : "video-disabled"}`}
        >
          {isAudioEnabled2 ? <audio ref={audioRef} /> : <></>}
          {isVideoEnabled2 ? (
            <div className="remoteVideo">
            {videoTracks.map((track, index) => (
              index === 0 && (
                <video
                  key={index}
                  ref={el => (videoRefs.current[index] = el)}
                  className={`video-stream remote-video ${
                    videoTracks.length > 1 ? "multiple-videos" : ""
                  } ${track.dimensions.width}X${track.dimensions.height}`}
                />
              )
            ))}
              <div className="mic-status">
                {isAudioEnabled2 ? (
                  <i className="fa fa-microphone"></i>
                ) : (
                  <i className="fa fa-microphone-slash"></i>
                )}
              </div>
            </div>
          ) : (
            <div className={`${participants?.length <= 2 ? "avatarContainer" : ""}  ${(participants?.length > 2 && participants?.length <= 4) ? "avatarContainer2" : ""} ${participants?.length > 4 ? "avatarContainer3" : ""}`}>
              <LottieAnimation
                firstName={name.initials}
                lastName=""
                enableAnimation={isAudioEnabled2}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default RemoteVideo
