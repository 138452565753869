import React from "react"
import { Col, Container, Row, BreadcrumbItem } from "reactstrap"
import { useHistory, useParams, Link } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import _ from "lodash"
import AppointmentInfo from "./AppointmentInfo"

const AppointmentDetails = () => {
  let { id } = useParams()
  const decodedId =id && atob(id);
  id = decodedId
  const history = useHistory()

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={false}>
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    Appointment Details
                  </h4>
                </div>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/appointments">Appointment</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">Appointment Details</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          <AppointmentInfo id={parseInt(id)} showActionButtonOnHead={true} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AppointmentDetails
