/* Client Appointments */

export const GET_CLIENT_APPOINTMENTS = "GET_CLIENT_APPOINTMENTS";
export const GET_CLIENT_APPOINTMENTS_DETAILS = "GET_CLIENT_APPOINTMENTS_DETAILS";
export const SET_CLIENT_APPOINTMENTS = "SET_CLIENT_APPOINTMENTS";
export const UPDATE_CLIENT_APPOINTMENTS_DETAILS = "UPDATE_CLIENT_APPOINTMENTS_DETAILS";

// /* Preview */

export const UPDATE_PREVIEW = "UPDATE_PREVIEW";
export const UPDATE_PREVIEW_ALL_DATA = "UPDATE_PREVIEW_ALL_DATA";

// /* Exported Invoices */

export const SET_EXPORTED_INVOICE = "SET_EXPORTED_INVOICE";
export const DELETE_EXPORTED_INVOICE = "DELETE_EXPORTED_INVOICE";
export const DELETE_ALL_EXPORTED_INVOICE = "DELETE_ALL_EXPORTED_INVOICE";
export const SET_RECIEPT_PREVIEW_DATA = "SET_RECIEPT_PREVIEW_DATA";