import React from "react"
import Lottie from "react-lottie"
import voice_ripple from "../../assets/animations/voice_ripple.json"

function LottieAnimation({ firstName, lastName, enableAnimation = true }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: voice_ripple,

    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div className={enableAnimation ? "postition-relative-lottie" : ""}>
      {enableAnimation && <Lottie options={defaultOptions} />}
      <div className="avatar lottie-avatar">
        <h5 className="avatar-text m-0">
          {firstName.toUpperCase()}
          {lastName.toUpperCase()}
        </h5>
      </div>
    </div>
  )
}

export default LottieAnimation
