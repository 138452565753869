import React from "react"
import { useState } from "react"
import { Button, Card, CardBody, Col, Modal, Row, Spinner } from "reactstrap"
import classNames from "classnames"
import Table from "../../components/Common/Table"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import edit from "../../assets/images/dashboard-icons/edit.svg"
import { useHistory } from "react-router-dom"
import {
  GET_ORGANIZATION,
  DELETE_ORGANIZATION,
  CREATE_ORGANIZATION,
} from "./gql/Gql"
import { useEffect } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import DeleteModal from "../../components/Common/DeleteModal"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Switch from "react-switch"
import moment from "moment"

const Organizations = () => {
  const history = useHistory()
  const [totalCount, setTotalCount] = useState(20)
  const [organizationsList, setOrganizationsList] = useState([])
  const [deleteModalId, setDeleteModalId] = useState(-1)
  const [inProgress, setInProgress] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState({
    id: undefined,
    open: false,
  })

  const [getOrganizations, getOrganizationsList] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [deleteOrganizations, deleteOrganizationsRes] = useMutation(
    DELETE_ORGANIZATION,
    { fetchPolicy: "no-cache" }
  )
  const [updateOrganization, updateOrganizationRes] =
    useMutation(CREATE_ORGANIZATION)

  const handleDelete = async id => {
    try {
      setInProgress(true)
      await deleteOrganizations({
        variables: {
          organizationInput: { id },
        },
      })
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong !")
    } finally {
      setInProgress(false)
      setDeleteModalId(-1)
    }
  }

  useEffect(() => {
    if (
      deleteOrganizationsRes?.data?.removeOrganization?.status &&
      deleteOrganizationsRes?.data?.removeOrganization?.message
    ) {
      getOrganizationsFun()
      toast.success(deleteOrganizationsRes?.data?.removeOrganization?.message)
    } else {
      toast.error(deleteOrganizationsRes?.data?.removeOrganization?.message)
    }
  }, [deleteOrganizationsRes.data])

  useEffect(() => {
    if (
      updateOrganizationRes?.data?.createOrUpdateOrganization?.status &&
      updateOrganizationRes?.data?.createOrUpdateOrganization?.data
    ) {
      toast.success(
        updateOrganizationRes?.data?.createOrUpdateOrganization?.message
      )
      getOrganizationsFun()
      window.location.reload()
      setConfirmationModal(p => ({
        ...p,
        id: undefined,
        open: false,
      }))
    } else {
      toast.error(
        updateOrganizationRes?.data?.createOrUpdateOrganization?.message
      )
    }
  }, [updateOrganizationRes.data])

  const dateFormat = (cell, row, rowIndex, formatExtraData) => {
    const dormatedDate = moment(row?.created_at).format("DD-MMM-yyyy")
    return dormatedDate
  }

  const statusEvent = (cell, row, rowIndex, formatExtraData) => {
    return row?.id !== 1 ? (
      <div className="d-flex align-items-center">
        <Switch
          onChange={() => {
            setConfirmationModal({
              open: true,
              id: row.id,
            })
          }}
          checked={row?.is_active}
          onColor="#153c3c"
          onHandleColor="#2693e6"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          width={40}
          height={20}
        />
      </div>
    ) : (
      <></>
    )
  }

  const handleSwitchChange = () => {
    let orgData = organizationsList.find(elem => {
      return elem.id === confirmationModal?.id
    })

    orgData.is_active = !orgData.is_active

    const newObject = {
      name: orgData?.name,
      logo: orgData?.logo,
      id: orgData?.id,
      is_active: orgData?.is_active,
      primary_color: orgData?.primary_color,
      secondary_color: orgData?.secondary_color,
      font_color: orgData?.font_color,
      favicon: orgData?.favicon,
      domain: orgData?.domain,
      description: orgData?.description,
    }

    updateOrganization({
      variables: {
        organizationInput: newObject,
      },
    })
  }

  const [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    search: "",
  })

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="m-0">
          {rowIndex + 1 + state.sizePerPage * (state.page - 1)}
        </p>
      </>
    )
  }

  const columns = [
    {
      text: "#",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "Name",
      dataField: "name",
      style: {
        minWidth: 130,
      },
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      text: "Link",
      dataField: "domain",
      style: {
        minWidth: 150,
      },
      formatter: (cell, row) => (
        <>
          {row?.is_active ? (
            <a
              href={`${row?.domain}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row?.domain}
            </a>
          ) : (
            <span title="Inactive Organization" className="cursor-pointer">
              {row?.domain}
            </span>
          )}
        </>
      ),
    },
    // {
    //   text: "Status",
    //   dataField: "is_active",
    //   style: {
    //     minWidth: 190,
    //   },
    //   headerStyle: {
    //     cursor: "pointer",
    //   },
    //   formatter: (cell, row) => <p>{cell ? "Active" : "Inactive"}</p>,
    // },
    {
      text: "Created On",
      dataField: "created_at",
      headerStyle: {
        cursor: "pointer",
      },
      formatter: dateFormat,
    },
    {
      text: "Domain Verification",
      dataField: "email",
      formatter: emailEvent,
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      dataField: "is_active",
      text: "Status",
      headerAlign: "left",
      align: "left",
      formatter: statusEvent,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
    },
  ]

  const openCreateOrg = () => {
    history.push(`/organizations/create`)
  }

  const handleEditClick = row => {
    history.push(`/organizations/create/${row.organization_identifier}`)
  }

  const handleEmailClick = row => {
    const encodedId = btoa(row?.id)
    const encodedSes = btoa(row?.sesDomains[0]?.domain)
    if (row?.sesDomains[0]?.domain) {
      history.push(`/organizations/domain-verification/${encodedId}?ses=${encodedSes}`)
    } else {
      history.push(`/organizations/domain-verification/${encodedId}`)
    }
  }

  const handleDeleteModal = row => {
    if (row.is_active) {
      toast.error("Please inactive the organization first to delete it")
    } else {
      setDeleteModalId(Number(row.id))
    }
  }

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        {row.id !== 1 ? (
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary cursor-pointer"
              onClick={() => handleEditClick(row)}
            >
              <img src={edit} />
            </button>
            <a
              onClick={() => handleDeleteModal(row)}
              className="px-3 text-danger delete-btn cursor-pointer"
            >
              <img src={deleteBin} />
            </a>
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }

  function emailEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn btn-primary cursor-pointer"
            onClick={() => handleEmailClick(row)}
            disabled={!row?.is_active}
          >
            <i className="text-white font-weight-bold fas fa-globe" />
          </button>
        </div>
      </>
    )
  }

  const loadingText = "No Data Found"

  const [loading, setLoading] = useState(false)
  const handleTableChange = () => {}

  const getOrganizationsFun = (limit, page, search, sortOrd) => {
    setLoading(true)
    getOrganizations({
      variables: {
        argumentsInput: {
          limit: limit || 20,
          page: page || 1,
          search: search || "",
          sort: sortOrd || "id",
          sort_dir: "DESC",
        },
      },
    })
  }
  useEffect(() => {
    getOrganizationsFun()
  }, [])

  const onChangeSearch = e => {
    const search = e.target.value
    setState(prevState => {
      return {
        ...prevState,
        search: search,
      }
    })
    getOrganizationsFun(state.sizePerPage, state.page, search)
  }
  useEffect(() => {
    if (
      getOrganizationsList?.data?.getOrganizations?.data &&
      getOrganizationsList?.data?.getOrganizations?.data?.organizations
    ) {
      const orgList =
        getOrganizationsList?.data?.getOrganizations?.data?.organizations
      setOrganizationsList(orgList)
      setLoading(false)
    }
  }, [getOrganizationsList.data])

  function handleConfirmationToggle() {
    setConfirmationModal(p => ({
      ...p,
      open: false,
    }))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center mgbottom-appointment-list-50">
            <div className="p-2">
              <h4 className="card-title-main">Organizations</h4>
              <h5 className="card-para-main">List of all the Organizations.</h5>
            </div>
            <div className="ml-auto p-2 w-20">
              <div className="d-inline-flex">
                <Row className="align-items-stretch flex-wrap">
                  <div className="col custom-date-picker-style-appointments mt-0">
                    <Button
                      type="button "
                      className=" btn-primary appointment-list-toggle-button me-3"
                      onClick={() => openCreateOrg()}
                    >
                      <i className="uil uil-plus"></i>
                      <span className="d-inline-block">
                        Create Organization
                      </span>
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
          </div>

          <Row className="mg-top-default-ui">
            <Col className="col-12 ">
              <Card className="light-green-bg-card-table">
                <CardBody>
                  <div>
                    <div className={classNames("d-flex gap-2 flex-wrap")}>
                      <Row className="w-100 flex-direction-column-mobile">
                        <Col className="search-input-search-appointments">
                          {" "}
                          <label className="w-100 search-label-appointments">
                            <input
                              className="form-control default-search-input w-25"
                              value={state.search}
                              type="text"
                              placeholder="Search Organization Name"
                              onChange={onChangeSearch}
                            />
                          </label>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="table-custom-redesign">
                    <Table
                      data={organizationsList}
                      page={state.page}
                      sizePerPage={state.sizePerPage}
                      totalSize={totalCount}
                      onTableChange={handleTableChange}
                      columns={columns}
                      noDataIndication={loadingText}
                      loading={loading}
                      isHover={false}
                      isStriped={false}
                      isBordereded={false}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <DeleteModal
              show={deleteModalId !== -1}
              onDeleteClick={() => {
                handleDelete(deleteModalId)
              }}
              onCloseClick={() => {
                setDeleteModalId(-1)
              }}
              inProgress={inProgress}
            />
          </Row>
        </div>
      </div>
      <Modal
        isOpen={confirmationModal?.open}
        toggle={() => {
          handleConfirmationToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h4 className="text-center">
                  Are you sure you want to change the status?
                </h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => {
                    setConfirmationModal(false)
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!inProgress ? (
                  <Button
                    disabled={inProgress}
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => handleSwitchChange()}
                  >
                    Yes, change it
                  </Button>
                ) : (
                  <Button
                    className="btn btn-primary waves-effect waves-light"
                    disabled
                  >
                    <Spinner
                      type="grow"
                      className="me-1 align-middle spinner-grow-sm "
                      color="light"
                    />
                    Processing...
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Organizations
