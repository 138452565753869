import React from 'react';
import { Spinner } from "reactstrap";

function Loader() {
    return (
        <Spinner animation="border" role="status">
            Loading...
        </Spinner>
    )
}

export default Loader