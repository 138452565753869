import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import useStyles from "../styles"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import Table from "../../../components/Common/Table"
import classNames from "classnames"
import { GET_TOP_COUNSELLOR_DATA } from "./Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import moment from "moment"
import style from "./style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"

const TopUser = () => {
  const CurrentYear = moment().format("YYYY")
  const months = moment.months()
  const currentMonth = parseInt(moment().format("MM"))
  const [getTopCounselor, getTopCounselorResponse] = useMutation(
    GET_TOP_COUNSELLOR_DATA
  )

  const getTopCounselorList = (month, year) => {
    getTopCounselor({
      variables: {
        topPerformingCounsellors: {
          month: parseInt(month),
          year: year,
        },
      },
    })
  }

  useEffect(() => {
    getTopCounselorList(parseInt(currentMonth), CurrentYear)
  }, [])

  useEffect(() => {
    if (
      getTopCounselorResponse.data &&
      getTopCounselorResponse.data.topPerformingCounsellors.code == 200
    ) {
      setTimeout(() => {
        setLoading(false)
      }, 2000)

      let data =
        getTopCounselorResponse.data.topPerformingCounsellors.data.topPerformingCounsellorsData.map(
          (item, index) => {
            return {
              id: index + 1,
              name: item.counsellor_name,
              session: item.count,
            }
          }
        )
      setData(data)
    }
  }, [getTopCounselorResponse.data])

  const classes = useStyles()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(`${moment().format("MMM")} ${CurrentYear}`)

  const columns = [
    {
      text: "#",
      dataField: "id",
      width: 150,
      headerStyle: (colum, colIndex) => {
        return { minWidth: "58px" }
      },
    },
    {
      text: "Name",
      dataField: "name",
      width: 270,
      formatter: NameFun,
      style: {
        minWidth: "150px",
      },
    },
    {
      text: "Session",
      dataField: "session",
      width: 270,
      headerAlign: "end",
      align: "end",
      formatter: RatingFun,
    },
  ]

  function NameFun(rowValue, row) {
    return (
      <div className="d-flex gap-2">
        <div className={`${classes.rowValue} least-user-txt-style`}>
          {rowValue}
        </div>
      </div>
    )
  }

  function RatingFun(rowValue, row) {
    return (
      <div className={`${classes.rowValue} least-user-txt-style`}>
        {rowValue}
      </div>
    )
  }

  const refreshApi = () => {
    setLoading(true)
    getTopCounselorList(currentMonth, CurrentYear)
    setValue(`${moment().format("MMM")} ${CurrentYear}`)
  }

  const selectMonth = e => {
    getTopCounselorList(e.target.dataset.month, e.target.dataset.year)
    setValue(e.target.value)
  }

  return (
    <React.Fragment>
      <Card className="h-100 light-green-bg-card-table">
        <CardBody className="pb-0">
          <div className="d-flex justify-content-between mb-2 pb-2 gap-2">
            <h4 className={classNames("card-title", classes.cardTitle)}>
              Top Performing
              <Tooltip
                className={`${style.tooltip} ul-bg-dark`}
                placement="right"
                title={
                  <div className="invoice-list-fsize-14">
                    This will display list of top 10 counsellor with highest
                    session booked.
                  </div>
                }
                arrow
              >
                <i
                  className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                ></i>
              </Tooltip>
            </h4>
            <div className="d-flex gap-2 custom-date-picker-style bradius-topuser">
              <UncontrolledDropdown className="">
                <DropdownToggle
                  tag="a"
                  className="text-reset bg-light p-2 dropdownInputWhite pointer"
                  id="dropdownMenuButton5"
                  caret
                >
                  <span className="">
                    {value}
                    <i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {months.map((item, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        value={`${item} ${CurrentYear}`}
                        className={`${index < currentMonth ? "" : "d-none"}`}
                        data-month={index + 1}
                        data-year={CurrentYear}
                        onClick={e => selectMonth(e)}
                      >
                        {item} {CurrentYear}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
              <span>
                <img
                  src={refresh}
                  width="24px"
                  height="24px"
                  className={classNames(
                    classes.refreshIcon,
                    loading && classes.refresh
                  )}
                  onClick={() => {
                    return refreshApi()
                  }}
                />
              </span>
            </div>
          </div>
          <div
            className={classNames(
              "table-responsive",
              "table-custom-redesign",
              classes.topUsertable
            )}
          >
            <Table
              data={data}
              isPagination={false}
              columns={columns}
              noDataIndication={"No Data Found"}
              isBordereded={false}
              isStriped={false}
              isHover={false}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopUser
