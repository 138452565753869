import React from "react"

const Alerts = ({ type, message }) => {
  return (
    <>
      <div className={`alert alert-${type} m-0 p-0 px-2 my-2`} role="alert">
        {message}
      </div>
    </>
  )
}

export default Alerts
