import { gql } from "@apollo/client"

export const GET_APPOINTMENT = gql`
query GetAppointment($argumentsInput: ArgumentsInput) {
  getAppointment(argumentsInput: $argumentsInput) {
    code
    message
    data {
      appointment {
        id
        first_name
        last_name
        email
        phone
        utc_datetime
        duration
        type
        participants
        phone
        appointment_type
        is_feedback_submitted
        is_client_feedback_submitted
        is_counsellor_feedback_submitted
        client {
          id
          age
          mobile_number
          created_at
        }
        therapist_calendar {
          id
          name
          email
        }
        appointmentHasForms {
          id
          name
          forms {
            uid
            id
            value
            name
            field_id
          }
        }
        appointmentHasLabels {
          id
          color
          name
        }
      }
    }
  }
}
`;

export const GET_tWILIO_ACCESS_TOKEN = gql`
query GetTwilioAccessToken($argumentsInput: ArgumentsInput) {
    getTwilioAccessToken(argumentsInput: $argumentsInput) {
      code
      message
      data {
       twilio_access_token
       room
       appointment {
        id
       }
      }
    }
  }
`;

export const CREATE_CONSENT = gql`
mutation CreateConsent($createUserInput: ArgumentsInput!) {
  createConsent(createUserInput: $createUserInput) {
    code
    message
    status
  }
}
`

export const STOP_RECORDNING = gql`
mutation StoreRecording($storeRecording: ArgumentsInput!) {
  storeRecording(storeRecording: $storeRecording) {
    code
    message
    status
  }
}
`

export const GET_tWILIO_ACCESS_TOKEN_WITHOUT_AUTH = gql`
query GetTwilioAccessTokenWithUser($argumentsInput: ArgumentsInput) {
  getTwilioAccessTokenWithUser(argumentsInput: $argumentsInput) {
    data {
      twilio_access_token
      room
      appointment {
        id
      }
    }
  }
}
`;

export const GET_ROOM_PARTICIPANTS = gql`
query GetRoomParticipants($argumentsInput: ArgumentsInput) {
  getRoomParticipants(argumentsInput: $argumentsInput) {
    data {
      participants_count
      participants_names
      room_status
      room_name
    }
  }
}
`;