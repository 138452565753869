import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2'

const InputPhone = ({ onChange, ...props }) => {
    const [phoneValue, setPhoneValue] = useState()
    return (
        <>
            <PhoneInput
                specialLabel=''
                placeholder=" "
                country={'ca'}
                enableAreaCodes={true}
                autoFormat="false"
                value={props.value}
                onChange={(value, data, event, formattedValue) => {
                    onChange(formattedValue)
                }}
                inputStyle={{
                    borderColor: (props.error) && "red"
                }}
                invalid={props.invalid}
                onBlur={value=>props.onBlur(value)}
                {...props}
            />

        </>
    );
};

const index = (props) => {
    return (
        <InputPhone
            label={"Mobile Phone"}
            req={true}
            helperText={""}
            error={true}
            isSelect={false}
            {...props.input}
            {...props.custom}
        />
    )
}

export default InputPhone