import { gql } from "@apollo/client"
export const GET_APPOITMENT_TYPE = gql`
  query FindAll($argumentsInput: ArgumentsInput) {
    findAll(argumentsInput: $argumentsInput) {
      data {
        appointmentTypes {
          active
          address
          id
          name
          latitude
          longitude
          duration
          google_address
          color
          call_type
          category
          location {
            address
            name
            latitude
            longitude
          }
        }
        totalCount
      }
      code
      message
      status
    }
  }
`
