import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  BreadcrumbItem,
  UncontrolledTooltip,
} from "reactstrap"
import { useHistory, useLocation, useParams, Link } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import _ from "lodash"
import style from "../feedback.module.scss"
import { GET_FEEDBACK } from "../gql/gql"
import { useLazyQuery } from "@apollo/client"
import { capitalizeFirstLetter } from "../../../functions/functions"
import { useSelector } from "react-redux"
import Loader from "../../../components/Common/Loader"
import RatingTooltip from "react-rating-tooltip"

function FeedbackDetails() {
  let { appointment_id } = useParams()
  const decodedId = appointment_id && atob(appointment_id);
  appointment_id = decodedId
  const location = useLocation()
  const userData = useSelector(state => state.Login?.user?.user)
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const [isSpinner, setIsSpinner] = useState(false)
  const [counsellorFeedback, setCounsellorFeedback] = useState(null)
  const [clientFeedback, setClientFeedback] = useState(null)
  let counsellor_feedback_id = queryParams.get("consellor_feedback")
  const decodedFId = counsellor_feedback_id && atob(counsellor_feedback_id);
  counsellor_feedback_id = decodedFId
  let client_feedback_id = queryParams.get("client_feedback")
  const decodedCId = client_feedback_id && atob(client_feedback_id);
  client_feedback_id = decodedCId

  const starStyle = {}
  const styleConfig = { starContainer: { marginBottom: 0 } }

  /* ################################# Feedback details ############################## */

  const [getFeedbackDetail, feedbackResponce] = useLazyQuery(GET_FEEDBACK, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (appointment_id) {
      getFeedbackDetail({
        variables: {
          viewFeedback: {
            appointment_id: parseInt(appointment_id),
            ...(counsellor_feedback_id
              ? { counsellor_feedback_id: parseInt(counsellor_feedback_id) }
              : {}),
            ...(client_feedback_id
              ? { client_feedback_id: parseInt(client_feedback_id) }
              : {}),
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      appointment_id &&
      feedbackResponce.data &&
      feedbackResponce.data.viewFeedback &&
      feedbackResponce.data.viewFeedback.status &&
      feedbackResponce.data.viewFeedback.code === 200
    ) {
      setIsSpinner(false)
      setCounsellorFeedback(
        feedbackResponce.data.viewFeedback.data.counsellor_feedback
      )
      setClientFeedback(feedbackResponce.data.viewFeedback.data.client_feedback)
    } else {
      setIsSpinner(true)
      setCounsellorFeedback(null)
      setClientFeedback(null)
    }
  }, [feedbackResponce.data])

  /* ################################# Functions ############################## */

  const ratingTypeQuestion = data => {
    const ratingQuestions = data.filter(
      question => question.question.question_type.slug === "rating"
    )
    const otherQuestions = data.filter(
      question => question.question.question_type.slug !== "rating"
    )

    const sortedRatingQuestionData = ratingQuestions.sort((a, b) => a.id - b.id)
    const sortedotherQuestionData = otherQuestions.sort((a, b) => a.id - b.id)

    return (
      <>
        {sortedotherQuestionData.map(feedbackFormdetails => (
          <div key={feedbackFormdetails.id} className="mt-3">
            <div className="d-flex align-items-center m-0">
              <CardTitle className="m-0">
                {capitalizeFirstLetter(
                  feedbackFormdetails.question.description
                )}
              </CardTitle>
            </div>
            {feedbackFormdetails.question.question_has_value.map(
              (ans, index) => (
                <div className="d-flex" key={index}>
                  <p className="card-title-desc mb-0">
                    {capitalizeFirstLetter(ans.value)}
                  </p>
                </div>
              )
            )}
          </div>
        ))}
        {sortedRatingQuestionData.map(feedbackFormdetails => (
          <div key={feedbackFormdetails.id} className="mt-3">
            <div className="d-flex align-items-center m-0">
              <CardTitle className="m-0">
                {capitalizeFirstLetter(
                  feedbackFormdetails.question.description
                )}
              </CardTitle>
            </div>
            {feedbackFormdetails.question.question_has_value.map(
              (ans, index) => (
                <div className="d-flex" key={index}>
                  <p className="card-title-desc mb-0">
                    <RatingTooltip
                      max={5}
                      starStyle={starStyle}
                      styleConfig={styleConfig}
                      defaultRating={Math.floor(parseFloat(ans.value))}
                      disabled={true}
                      ActiveComponent={
                        <i className="mdi mdi-star star-rating-style" />
                      }
                      InActiveComponent={
                        <i className="mdi mdi-star-outline text-muted star-outline-style" />
                      }
                    />
                  </p>
                </div>
              )
            )}
          </div>
        ))}
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={false}>
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={style.headingsubtitle}>Feedback Details</h4>
                </div>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/appointments">Appointment</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">Feedback Details</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          {isSpinner ? (
            <Row>
              <Col className="col-12 user-role-create-height">
                <div className="spinner">
                  <Loader />
                </div>
              </Col>
            </Row>
          ) : (
            <>
              {(userData.role.id === 1 || userData.role.id === 2) && (
                <>
                  {counsellorFeedback &&
                    counsellorFeedback.appointment_has_feedback.length !== 0 &&
                    counsellorFeedback.appointment_has_feedback.map(
                      feedbackDetail => (
                        <Row key={feedbackDetail.id}>
                          <Col xl={12}>
                            <div>
                              {feedbackDetail.feedback_has_data.length !==
                                0 && (
                                <Card>
                                  <CardBody>
                                    <h5>
                                      {userData.role.id === 2
                                        ? "My Feedback"
                                        : "Counsellor Feedback"}
                                    </h5>
                                    <hr />
                                    {ratingTypeQuestion(
                                      feedbackDetail.feedback_has_data
                                    )}
                                  </CardBody>
                                </Card>
                              )}
                            </div>
                          </Col>
                        </Row>
                      )
                    )}
                </>
              )}

              {(userData.role.id === 1 || userData.role.id === 3) && (
                <>
                  {clientFeedback &&
                    clientFeedback.appointment_has_feedback.length !== 0 &&
                    clientFeedback.appointment_has_feedback.map(
                      feedbackDetail => (
                        <Row key={feedbackDetail.id}>
                          <Col xl={12}>
                            <div>
                              {feedbackDetail.feedback_has_data.length !==
                                0 && (
                                <Card>
                                  <CardBody>
                                    <h5>
                                      {userData.role.id === 3
                                        ? "My Feedback"
                                        : "Client Feedback"}
                                    </h5>
                                    <hr />
                                    {ratingTypeQuestion(
                                      feedbackDetail.feedback_has_data
                                    )}
                                  </CardBody>
                                </Card>
                              )}
                            </div>
                          </Col>
                        </Row>
                      )
                    )}
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FeedbackDetails
