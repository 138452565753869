import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import Loader from "../../components/Common/Loader"
import { FocusError } from "focus-formik-error"
import FullCalendar from "@fullcalendar/react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import interactionPlugin from "@fullcalendar/interaction"
import momentPlugin from "@fullcalendar/moment"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import { CalendarChip } from "../User/CalendarChip"
import { useParams, Link, useHistory, useLocation } from "react-router-dom"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import moment from "moment"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  GET_CALENDAR_ROOM,
  GET_MONTH_WISE_ROOM,
  GET_ROOM_BLOKED_TIME,
  GET_ROOMS_BY_LOCATIONS,
  SET_ROOM,
  DELETE_BLOCK_TIME,
  SET_OVERRIDE_HOURS_DATE
} from "./gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-toastify/dist/ReactToastify.css"

const RoomAvailibity = () => {
  let { roomId } = useParams()
  const decodedId = roomId && atob(roomId);
  roomId = decodedId
  const location = useLocation()
  const history = useHistory()

  const locationValue = new URLSearchParams(location.search).get("location")
  const calenderValue = new URLSearchParams(location.search).get("calendar")
  const [isLoader, setIsLoader] = useState(false)

  const [monthlyData, setMonthlyData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isMultipleHoursOpen, setIsMultipleHoursOpen] = useState(false)
  const [dateMultipleHoursOpen, setDateMultipleHoursOpen] = useState(false)
  const [blockTimeData, setBlockTImeData] = useState()
  const [scheduleLimit, setScheduleLimit] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [appointmentTyeList, setAppointmentTyeList] = useState([])
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteBlockId, setDeleteBlockId] = useState()
  const [activeTab, setActiveTab] = useState("block-time")

  const toggleTab = tab => {
    setActiveTab(tab)
  }
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    moment().format("YYYY-MM")
  )
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"))
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM"))
  const [multipleAvailData, setMultipleAvailData] = useState([])
  const [createRoomMutation, createRoomMutationRes] = useMutation(SET_ROOM)
  const [setOverrideHoursDate, setOverrideHoursDateRes] = useMutation(SET_OVERRIDE_HOURS_DATE)
  const [scheduleLimitForm, setScheduleLimitForm] = useState({
    advance_hours: 0,
    future_days: 0,
    appointment_per_slot: 0,
    min_change_notice_hours: 0,
    calendar_id: 0,
    accept_maximum_appointments: false,
    accept_max_appointments: false,
    max_hours_per_day: 0,
    max_number_per_day: 0,
    max_number_per_week: 0,
  })
  const [initialValues, setInitialValues] = useState({
    id: roomId,
    is_closed_all: false,
    availabilities: [],
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().nullable(),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      const availabilityData = values.availabilities.flatMap((dayData, dayIndex) => {
        return dayData.availabilities.map((availability, availabilityIndex) => {
          const availabilityItem = {
            start_time: availability.start_time,
            week_day: availability.week_day,
            is_closed: availability.is_closed,
            end_time: availability.end_time,
            room_id: parseInt(roomId),
          };
      
          if (dayIndex === 0 && availabilityIndex === 0) {
            availabilityItem.is_edit = true;
          }
      
          return availabilityItem;
        });
      });
      
      if (availabilityData) {
        setIsSubmitted(true)
        createRoomMutation({
          variables: {
            createAvailabilityInput: {
              availability: availabilityData,
            },
          },
        })
          .then(res => {
            toast.success("Availability updated successfully ")
            if (locationValue && roomId) {
              getCalendarRoomsFun()
              getmonthlyApi()
            }
            setIsSubmitted(false)
          })
          .catch(err => {
            toast.error("something went wrong !")
            setIsSubmitted(false)
          })
      } else {
        toast.error("Enter room details properly")
        setIsSubmitted(false)
      }
    },
  })

  const [getCalendarRooms, getCalendarRoomsRes] = useLazyQuery(
    GET_CALENDAR_ROOM,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [getRoomsByLocation, getRoomsByLocationRes] = useLazyQuery(
    GET_ROOMS_BY_LOCATIONS,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [
    getMonthWiseAvailabilitiesRoomView,
    getMonthWiseAvailabilitiesRoomViewRes,
  ] = useLazyQuery(GET_MONTH_WISE_ROOM, {
    fetchPolicy: "no-cache",
  })

  const GetRoomBlockedTimeFun = () => {
    GetBlockedTime({
      variables: {
          getRoomBlockedTimeId: parseInt(roomId),
      },
    })
  }

  const [GetBlockedTime, GetBlockedTimeRes] = useLazyQuery(
    GET_ROOM_BLOKED_TIME,
    {
      fetchPolicy: "no-cache",
    }
  )

  useEffect(() => {
    if (locationValue && roomId) {
      getCalendarRoomsFun()
    }
    GetRoomBlockedTimeFun()
  }, [])

  function deleteToggle() {
    setModalDelete(!modalDelete)
    setDeleteBlockId(null)
  }

  const [DeleteBlockedTimes, DeleteBlockedTimesRes] = useMutation(
    DELETE_BLOCK_TIME,
    { fetchPolicy: "no-cache" }
  )

  const handleDeleteBlockTIme = () => {
    DeleteBlockedTimes({
      variables: {
        deleteBlockedTimeRoomId: deleteBlockId,
      },
    })
    setDeleteBlockId(null)
  }

  useEffect(() => {
    if (
      DeleteBlockedTimesRes?.data?.deleteBlockedTimeRoom?.status &&
      DeleteBlockedTimesRes?.data?.deleteBlockedTimeRoom?.message
    ) {
      toast.success(DeleteBlockedTimesRes?.data?.deleteBlockedTimeRoom?.message)
      GetRoomBlockedTimeFun()
      setModalDelete(!modalDelete)
    }
  }, [DeleteBlockedTimesRes.data])

  useEffect(() => {
    if (
      GetBlockedTimeRes?.data?.getRoomBlockedTime) {
      setBlockTImeData(
        GetBlockedTimeRes?.data?.getRoomBlockedTime
      )
    }
  }, [GetBlockedTimeRes.data])

  const getCalendarRoomsFun = () => {
    setIsLoader(true)
    getRoomsByLocation({
      variables: {
        getCalendarRooms: {
          location_id: parseInt(locationValue),
          room_id: parseInt(roomId),
        },
      },
    })

    getMonthWiseAvailabilitiesRoomView({
      variables: {
        argumentsInput: {
          room_id: parseInt(roomId),
          month: moment().format("MMMM"),
          year: parseInt(moment().format("YYYY")),
        },
      },
    })
  }

  const getmonthlyApi = () => {
    getMonthWiseAvailabilitiesRoomView({
      variables: {
        argumentsInput: {
          room_id: parseInt(roomId),
          month: selectedMonth,
          year: selectedYear,
        },
      },
    })
  }

  useEffect(() => {
    if (
      getCalendarRoomsRes?.data?.getCalendarRooms?.data &&
      getCalendarRoomsRes?.data?.getCalendarRooms?.data
        ?.roomavilabilitiesMultiple
    ) {
      setInitialValues(prev => ({
        ...prev,
        availabilities:
          getCalendarRoomsRes?.data?.getCalendarRooms?.data
            ?.roomavilabilitiesMultiple,
        is_closed_all:
          !getCalendarRoomsRes?.data?.getCalendarRooms?.data?.is_closed_all,
      }))
      setIsLoader(false)
    } else if (
      getCalendarRoomsRes?.data?.getCalendarRooms?.data &&
      getCalendarRoomsRes?.data?.getCalendarRooms?.data
        ?.roomavilabilitiesMultiple.length === 0
    ) {
      setIsLoader(false)
    }
  }, [getCalendarRoomsRes.data])

  useEffect(() => {
    if (
      getRoomsByLocationRes?.data?.getRoomsByLocation?.data &&
      getRoomsByLocationRes?.data?.getRoomsByLocation?.data
        ?.roomavilabilitiesMultiple
    ) {
      setInitialValues(prev => ({
        ...prev,
        availabilities:
          getRoomsByLocationRes?.data?.getRoomsByLocation?.data
            ?.roomavilabilitiesMultiple,
        is_closed_all:
          !getRoomsByLocationRes?.data?.getRoomsByLocation?.data?.is_closed_all,
      }))
      setIsLoader(false)
    } else if (
      getRoomsByLocationRes?.data?.getRoomsByLocation?.data &&
      getRoomsByLocationRes?.data?.getRoomsByLocation?.data
        ?.roomavilabilitiesMultiple.length === 0
    ) {
      setIsLoader(false)
    }
  }, [getRoomsByLocationRes.data])

  useEffect(() => {
    if (
      getMonthWiseAvailabilitiesRoomViewRes?.data
        ?.getMonthWiseAvailabilitiesRoomView?.data &&
      getMonthWiseAvailabilitiesRoomViewRes?.data
        ?.getMonthWiseAvailabilitiesRoomView?.data?.roomavilabilitiesMultiple
    ) {
      setMonthlyData(
        getMonthWiseAvailabilitiesRoomViewRes?.data
          ?.getMonthWiseAvailabilitiesRoomView?.data?.roomavilabilitiesMultiple
      )
    }
  }, [getMonthWiseAvailabilitiesRoomViewRes.data])

  function formatTimeTo12Hour(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number)
    const period = hours < 12 ? "AM" : "PM"
    const formattedHours = hours % 12 || 12
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`
  }

  function generateEventsFromMonthlyData(availabilityData) {
    const events = []

    for (const dayAvailability of availabilityData) {
      const date = dayAvailability.date
      //TODO
      const isClosedAllDay = dayAvailability.is_closed_all_for_whole_day
      const availabilities = dayAvailability.availabilities
      // Check if the day is fully closed for the whole day
      if (isClosedAllDay) {
        const event = {
          date: date,
          start_time: "Closed",
          end_time: "", // You can leave this empty for a closed event
          is_closed: true,
          start: `${selectedMonthYear}-${date
            .toString()
            .padStart(2, "0")}T00:00:00`,
          title: "Closed (All Day)",
          id: `${date}_closed_all_day`,
        }

        events.push(event)
      } else {
        for (const availability of availabilities) {
          const event = {
            date: date,
            start_time: availability.start_time,
            end_time: availability.end_time,
            is_closed: availability.is_closed,
            start: `${selectedMonthYear}-${date.toString().padStart(2, "0")}T${
              availability.start_time
            }`,
            title: availability.is_closed
              ? null
              : `${formatTimeTo12Hour(
                  availability.start_time
                )} - ${formatTimeTo12Hour(availability.end_time)}`,
            id: `${date}${availability.start_time.substring(0, 2)}`,
          }

          events.push(event)
        }
      }
    }

    return events
  }

  const handleDateChange = payload => {
    const viewTitle = payload.view.title
    const [currentMonth, currentYear] = viewTitle.split(" ")
    setSelectedMonth(currentMonth)
    setSelectedYear(parseInt(currentYear))

    const monthMappings = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    }

    const formattedMonth = monthMappings[currentMonth] || currentMonth

    const selectedMonthYear = `${currentYear}-${formattedMonth}`
    setSelectedMonthYear(selectedMonthYear)

    if (payload.start) {
      getMonthWiseAvailabilitiesRoomView({
        variables: {
          argumentsInput: {
            room_id: parseInt(roomId),
            month: currentMonth,
            year: parseInt(currentYear),
          },
        },
      })
    }

    // setSelectedMonthYear(moment(payload.start).add(1, "M").format("YYYY-MM"))
    if (payload.start) {
      getMonthWiseAvailabilitiesRoomView({
        variables: {
          argumentsInput: {
            room_id: parseInt(roomId),
            month: currentMonth,
            year: parseInt(moment(payload.start).add(1, "M").format("YYYY")),
          },
        },
      })
    }
  }

  const setMultipleHours = () => {
    const inputDate = new Date(multipleAvailData[0].start)

    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, "0")
    const day = String(inputDate.getDate()).padStart(2, "0")
    const formattedDateString = `${year}-${month}-${day}`
    const selectedMonth = moment(formattedDateString).format("MMMM")
    const selectedDay = moment(formattedDateString).format("dddd")

    const availabilityInputArr = multipleAvailData.map(item => ({
      room_id : parseInt(roomId),
      start_time: item.start_time,
      end_time: item.end_time,
      is_closed: multipleAvailData[0].is_closed ? true : false,
      week_day: item.week_day,
      override_date: formattedDateString,
      month: selectedMonth,
      week_day: selectedDay,
    }))

    const isNotEmpty = availabilityInputArr.every(availability => {
      return (
        availability.start_time.trim() !== "" &&
        availability.end_time.trim() !== ""
      )
    })

    if (isNotEmpty && availabilityInputArr.length >= 0) {
      function validateAvailability(availabilities) {

        const sortedAvailabilities = availabilities.slice().sort((a, b) => {
          return a.start_time.localeCompare(b.start_time)
        })

        for (let i = 0; i < sortedAvailabilities.length; i++) {
          const prevAvailability = sortedAvailabilities[i - 1]
          const currentAvailability = sortedAvailabilities[i]

          let prevEndTime = ""
          if (prevAvailability) {
            prevEndTime = new Date(`1970-01-01T${prevAvailability.end_time}`)
          }
          const currentStartTime = new Date(
            `1970-01-01T${currentAvailability.start_time}`
          )

          const currentEndTime = new Date(
            `1970-01-01T${currentAvailability.end_time}`
          )
          if (currentStartTime >= currentEndTime) {
            return false
          }
          if (prevEndTime) {
            if (prevEndTime >= currentStartTime) {
              return false
            }
          }
        }

        return true
      }
      let isValid = ""

      if (availabilityInputArr.length > 0) {
        isValid = validateAvailability(availabilityInputArr)
      } else {
        isValid = true
      }
      if (isValid) {
        setOverrideHoursDate({
          variables: {
            setOverrideHoursRoomForDate: {
              availability: availabilityInputArr,
            },
          },
        }).then(res => {
          toast.success(res.data.setOverrideHoursRoomForDate.message)
          getmonthlyApi()
          setMultipleAvailData([])
          setIsMultipleHoursOpen(false)
          setDateMultipleHoursOpen(false)
        })
      } else {
        toast.error("Time slots are overriding each other")
      }
    } else {
      toast.error("Select proper time slots")
    }
  }

  const handleScheduleChange = event => {
    const { name, value, type, checked } = event.target
    if (type === "checkbox") {
      if (name === "accept_maximum_appointments") {
        setScheduleLimitForm({
          ...scheduleLimitForm,
          accept_maximum_appointments: checked,
          accept_max_appointments: false,
        })
      } else if (name === "accept_max_appointments") {
        setScheduleLimitForm({
          ...scheduleLimitForm,
          accept_maximum_appointments: false,
          accept_max_appointments: checked,
        })
      }
    } else {
      const parsedValue = parseInt(value)
      const newValue = parsedValue < 0 ? 0 : parsedValue

      setScheduleLimitForm({
        ...scheduleLimitForm,
        [name]: newValue,
      })
    }
  }
  const saveMultipleHours = () => {
    const availabilityInputArr = multipleAvailData.map(item => ({
      start_time: item.start_time,
      end_time: item.end_time,
      is_closed: false,
      week_day: item.week_day,
    }))

    const isNotEmpty = availabilityInputArr.every(availability => {
      return (
        availability.start_time.trim() !== "" &&
        availability.end_time.trim() !== ""
      )
    })

    if (isNotEmpty && availabilityInputArr.length >= 0) {
      function validateAvailability(availabilities) {
        const sortedAvailabilities = availabilities.slice().sort((a, b) => {
          return a.start_time.localeCompare(b.start_time)
        })

        for (let i = 0; i < sortedAvailabilities.length; i++) {
          const prevAvailability = sortedAvailabilities[i - 1]
          const currentAvailability = sortedAvailabilities[i]
          let prevEndTime = ""
          if (prevAvailability) {
            prevEndTime = new Date(`1970-01-01T${prevAvailability.end_time}`)
          }

          const currentStartTime = new Date(
            `1970-01-01T${currentAvailability.start_time}`
          )

          const currentEndTime = new Date(
            `1970-01-01T${currentAvailability.end_time}`
          )
          if (currentStartTime >= currentEndTime) {
            return false
          }

          if (prevEndTime) {
            if (prevEndTime >= currentStartTime) {
              return false
            }
          }
        }

        return true
      }
      let isValid = ""
      if (availabilityInputArr.length > 0) {
        isValid = validateAvailability(availabilityInputArr)
      } else {
        isValid = true
      }

      if (isValid) {
        function updateInitialValue(initialValue, modifiedValue) {
          const updatedValue = [...initialValue]
          let modifiedAvail = []
          for (const modifiedItem of modifiedValue) {
            const dayIndex = updatedValue.findIndex(
              item => item.day === modifiedItem.week_day
            )

            if (dayIndex !== -1) {
              modifiedAvail.push({
                week_day: modifiedItem.week_day,
                start_time: modifiedItem.start_time,
                end_time: modifiedItem.end_time,
                is_closed: false,
              })
            }
          }
          const weekDay = updatedValue.findIndex(
            item => item.day === modifiedValue[0].week_day
          )
          updatedValue[weekDay].availabilities = modifiedAvail
          return updatedValue
        }

        const updatedInitialValue = updateInitialValue(
          validation.values.availabilities,
          availabilityInputArr
        )
        setInitialValues(prev => ({
          ...prev,
          availabilities: updatedInitialValue,
        }))
        handleMultipleHoursModal()
      } else {
        toast.error("Time slots are overriding each other")
      }
    } else {
      toast.error("Select proper time slots")
    }
  }
  const handleEventClick = arg => {
    const events = []

    for (const dayAvailability of monthlyData) {
      const date = dayAvailability.date
      const availabilities = dayAvailability.availabilities

      for (const availability of availabilities) {
        const event = {
          date: date,
          start_time: availability.start_time,
          end_time: availability.end_time,
          is_closed: availability.is_closed,
          start: `${selectedMonthYear}-${date.toString().padStart(2, "0")}T${
            availability.start_time
          }`,
          title: availability.is_closed
            ? null
            : `${availability.start_time.slice(
                0,
                5
              )} - ${availability.end_time.slice(0, 5)}`,
          id:
            availability.id ||
            `${date}${availability.start_time.substring(0, 2)}`, // Use a unique identifier or create one if id is not available
        }

        events.push(event)
      }
    }
    const event = arg.event
    const appointments = events

    const dateToFind = new Date(event.start)
    const date = dateToFind.getDate()
    const modalData = appointments.filter(item => item.date === date)
    setMultipleAvailData(modalData)
    setIsMultipleHoursOpen(true)
    setDateMultipleHoursOpen(true)
  }

  const handleMultiTimeSlot = (e, index, time) => {
    const newArray = [...multipleAvailData]

    newArray[index][time] = e.target.value + ":00"

    setMultipleAvailData(newArray)
  }
  const removeAvailability = index => {
    const updatedAvailabilities = multipleAvailData.filter(
      (availability, ind) => ind !== index
    )
    setMultipleAvailData(updatedAvailabilities)
  }

  const addAvailability = () => {
    if (multipleAvailData.length < 5) {
      setMultipleAvailData([
        ...multipleAvailData,
        {
          start_time: "",
          end_time: "",
          week_day: multipleAvailData[0].week_day,
        },
      ])
    }
  }

  const handleToggle = e => {
    if (e.target.checked) {
      setInitialValues(prev => {
        return {
          ...prev,
          is_closed_all: !prev.is_closed_all,
          availabilities: prev.availabilities.map(item => ({
            ...item,
            availabilities: item.availabilities.map(availability => ({
              ...availability,
              is_closed: !prev.is_closed,
            })),
          })),
        }
      })
    } else {
      setInitialValues(prev => {
        return {
          ...prev,
          is_closed_all: !prev.is_closed_all,
        }
      })
    }
  }

  const handleToggleDynamic = (e, item, index) => {
    let updatedItem = { ...item }
    setInitialValues(prevState => {
      const updatedAvailabilities = [...prevState.availabilities]
      updatedAvailabilities[index].availabilities.map(elem => {
        elem.is_closed = e.target.checked
      })
      return { ...prevState, availabilities: updatedAvailabilities }
    })
  }
  const submitScheduleLimits = () => {
    let {
      advance_hours,
      future_days,
      appointment_per_slot,
      min_change_notice_hours,
      accept_maximum_appointments,
      accept_max_appointments,
      max_hours_per_day,
      max_number_per_day,
      max_number_per_week,
      id,
    } = scheduleLimitForm

    const scheduleLimitData = {
      advance_hours,
      id,
      future_days,
      appointment_per_slot,
      min_change_notice_hours,
      accept_maximum_appointments: accept_maximum_appointments
        ? "Accept_appointments_until_fully_booked"
        : null,
      max_hours_per_day: accept_maximum_appointments ? null : max_hours_per_day,
      max_number_per_day: accept_maximum_appointments
        ? null
        : max_number_per_day,
      max_number_per_week: accept_maximum_appointments
        ? null
        : max_number_per_week,
    }

    if (
      scheduleLimitData.advance_hours >= 0 &&
      scheduleLimitData.future_days >= 0 &&
      scheduleLimitData.appointment_per_slot >= 0 &&
      scheduleLimitData.min_change_notice_hours >= 0 &&
      (scheduleLimitData.accept_maximum_appointments ===
        "Accept_appointments_until_fully_booked" ||
        (accept_max_appointments &&
          scheduleLimitData.max_hours_per_day >= 0 &&
          scheduleLimitData.max_number_per_day >= 0 &&
          scheduleLimitData.max_number_per_week >= 0))
    ) {
      UpdateStoreScheduling({
        variables: {
          updateStoreSchedulingLimit: scheduleLimitData,
        },
      })
    } else {
      setDisableSubmit(true)
    }
  }


  const formattedOverrides = blockTimeData?.map(override => {

    const overrideDate = new Date(override.override_date)
    const startParts = override.start_time.split(":")
    const endParts = override.end_time.split(":")

    // Set the time components
    overrideDate.setHours(
      parseInt(startParts[0], 10),
      parseInt(startParts[1], 10)
    )
    const endDate = new Date(overrideDate)
    endDate.setHours(parseInt(endParts[0], 10), parseInt(endParts[1], 10))

    const formattedStartDate = overrideDate.toLocaleString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })

    const formattedEndDate = endDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })

    const dateObj = {
      date: `${formattedStartDate} - ${formattedEndDate}`,
      id: override.id,
    }

    return dateObj
  })

  const handleMultipleHoursModal = availData => {
    if (availData) {
      setMultipleAvailData(availData)
    }
    setDateMultipleHoursOpen(false)
    setIsMultipleHoursOpen(!isMultipleHoursOpen)
  }
  const handleScheduleLimitModal = () => {
    setScheduleLimit(!scheduleLimit)
    setActiveTab("block-time")
    setDisableSubmit(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4
                    className={`${style.headingsubtitle} card-title-main mt-1 mx-3`}
                  >
                    Set Hours of Availability for Rooms
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 position-relative mb-4">
              {isLoader ? (
                <div className={`${style.spinnerContainer} text-center`}>
                  <Loader />
                </div>
              ) : (
                <Form
                  className="needs-validation"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <FocusError formik={validation} />
                  <Row className="d-flex justify-content-center">
                    <Col xs={12} md={8}>
                      <Col xl="12">
                        <Card className="transparent-bg-card">
                          <CardBody>
                            <Row className="form-custom-white-inputs">
                              <Col md="12" className="mb-2">
                                <div className="form-check form-switch">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input "
                                    id="customSwitch2"
                                    name="is_closed"
                                    checked={validation?.values?.is_closed_all}
                                    onChange={e => handleToggle(e)}
                                    onClick={e => handleToggle(e)}
                                  />
                                  <label
                                    className="form-check-label labelfor-regular-hours-service"
                                    htmlFor="customSwitch2"
                                  >
                                    This service group has regular hours every
                                    week
                                  </label>
                                </div>
                              </Col>
                              <Col
                                md={12}
                                className={
                                  validation?.values?.is_closed_all
                                    ? "d-none"
                                    : "light-green-bg-card mb-3 p-4"
                                }
                              >
                                <div className="menu-item-16px ">
                                  Switching to only Availability for Specific
                                  Days will remove your current Regular Weekly
                                  Hours.
                                </div>

                                <div className="menu-item-16px ">
                                  If you want to have the same availability
                                  every week, select Cancel to go back over to
                                  Regular Weekly Hours and set those instead.
                                </div>
                              </Col>
                            </Row>
                            <div className="mb-4 row">
                              <Col md="3" className="">
                                <div className="form-check form-switch ">
                                  <label className="form-check-label font-weight-bold">
                                    Location :
                                  </label>
                                </div>
                              </Col>

                              <Col md="8" className="custom-date-picker-style">
                                <Input
                                  name="name"
                                  className="form-control w-75"
                                  placeholder="Enter Location Name"
                                  type="text"
                                  disabled={true}
                                  value={
                                    validation?.values?.availabilities[0]?.room
                                      ?.location?.name
                                  }
                                  autoComplete="off"
                                />
                              </Col>
                            </div>

                            <div className="mb-4 row">
                              <Col md="3" className="">
                                <div className="form-check form-switch ">
                                  <label className="form-check-label font-weight-bold">
                                    Room Name :
                                  </label>
                                </div>
                              </Col>

                              <Col md="8" className="custom-date-picker-style">
                                <Input
                                  name="name"
                                  className="form-control w-75"
                                  placeholder="Enter Location Name"
                                  type="text"
                                  disabled={true}
                                  value={
                                    validation?.values?.availabilities[0]?.room
                                      ?.name
                                  }
                                  autoComplete="off"
                                />
                              </Col>
                            </div>
                            <Row
                              className={`${
                                validation?.values?.is_closed_all
                                  ? " align-items-center mb-4 "
                                  : "d-none"
                              }`}
                            >
                              {validation?.values?.availabilities?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <div className="mb-4 row" key={index}>
                                        <Col md="3" className="">
                                          <div className="form-check form-switch ">
                                            <Input
                                              type="checkbox"
                                              className="form-check-input default-search-input"
                                              id={`customSwitch-${index}`}
                                              name={`availabilities[${index}].is_closed`}
                                              checked={
                                                !item?.availabilities[0]
                                                  .is_closed
                                              }
                                              onChange={e =>
                                                handleToggleDynamic(
                                                  e,
                                                  item?.availabilities,
                                                  index
                                                )
                                              }
                                              onClick={e =>
                                                handleToggleDynamic(
                                                  e,
                                                  item?.availabilities,
                                                  index
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`customSwitch-${index}`}
                                            >
                                              {item?.availabilities[0].week_day}
                                            </label>
                                          </div>
                                        </Col>
                                        {item?.availabilities[0]?.is_closed ? (
                                          <Col
                                            md="8"
                                            className="mb-2 menu-item-16px"
                                          >
                                            Closed
                                          </Col>
                                        ) : (
                                          <>
                                            <Col
                                              md="3"
                                              className="custom-date-picker-style"
                                            >
                                              <Input
                                                id="name"
                                                className="form-control default-search-input"
                                                name={`availabilities[${index}].start_time`}
                                                placeholder="From"
                                                type="time"
                                                onChange={e =>
                                                  handleChange(
                                                    e,
                                                    index,
                                                    "start_time"
                                                  )
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  item?.availabilities[0]
                                                    .start_time || ""
                                                }
                                                disabled={
                                                  item?.availabilities[0]
                                                    ?.is_closed
                                                }
                                                invalid={
                                                  validation.touched.name &&
                                                  validation.errors.name
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {item?.availabilities.length >
                                                1 && (
                                                <h6
                                                  className="mt-2 seeMoreTimeSlots"
                                                  onClick={() => {
                                                    handleMultipleHoursModal(
                                                      item?.availabilities
                                                    )
                                                  }}
                                                >
                                                  +
                                                  {item?.availabilities.length -
                                                    1}{" "}
                                                  more
                                                </h6>
                                              )}
                                            </Col>
                                            <Col
                                              md="3"
                                              className="custom-date-picker-style"
                                            >
                                              <Input
                                                id="end_time"
                                                name={`availabilities[${index}].end_time`}
                                                className="form-control default-search-input"
                                                placeholder="To"
                                                type="time"
                                                onChange={e =>
                                                  handleChange(
                                                    e,
                                                    index,
                                                    "end_time"
                                                  )
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  item?.availabilities[0]
                                                    .end_time || ""
                                                }
                                                disabled={
                                                  item?.availabilities[0]
                                                    ?.is_closed
                                                }
                                                invalid={
                                                  validation.touched.name &&
                                                  validation.errors.name
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Col>
                                            <Col md="2">
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleMultipleHoursModal(
                                                    item?.availabilities
                                                  )
                                                }}
                                                className="h-40 btn btn-primary waves-effect waves-light d-flex align-items-center justify-content-center"
                                              >
                                                <i
                                                  className="waves-effect mdi mdi-pencil font-size-20 "
                                                  id="backArrow"
                                                />
                                              </button>
                                            </Col>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )
                                }
                              )}
                            </Row>
                            <Row>
                              <div className="flex-wrap gap-2 text-end mb-3">
                                {true ? (
                                  <>
                                    <button
                                      className="btn btn-primary w-sm waves-effect waves-light"
                                      type="submit"
                                      disabled={isSubmitted}
                                    >
                                      {validation.values.is_closed_all
                                        ? "Save Regular Hours"
                                        : "Save, switch to Specific Days"}
                                    </button>
                                    <button
                                      className={`btn btn-primary w-sm waves-effect waves-light btn-inactive btn ml10 ${
                                        !validation?.values?.is_closed_all
                                          ? "d-none"
                                          : ""
                                      }`}
                                      type="button"
                                      disabled={isSubmitted}
                                      onClick={() => {
                                        handleScheduleLimitModal()
                                      }}
                                    >
                                      Blocked off time
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-primary w-sm waves-effect waves-light"
                                    disabled
                                  >
                                    <Spinner
                                      type="grow"
                                      className="me-1 align-middle spinner-grow-sm "
                                      color="light"
                                    />
                                    Processing...
                                  </button>
                                )}
                                <Button
                                  type="button"
                                  color="secondary"
                                  className={`btn-inactive btn ml10 ${
                                    validation?.values?.is_closed_all
                                      ? "d-none"
                                      : ""
                                  }`}
                                  onClick={e => handleToggle(e)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
            <Row className="d-flex justify-content-center">
                <Col xs={12} md={11}>
                  <FullCalendar
                    plugins={[
                      momentPlugin,
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                    ]}
                    headerToolbar={{
                      left: "",
                      center: "prev title next",
                      right: "",
                    }}
                    initialView="dayGridMonth"
                    editable={false}
                    selectable={false}
                    selectMirror={false}
                    dayMaxEvents={true}
                    weekends={true}
                    events={generateEventsFromMonthlyData(monthlyData)}
                    datesSet={handleDateChange}
                    eventClick={handleEventClick}
                    eventContent={CalendarChip}
                    eventColor="#e2ecdf"
                    eventTimeFormat={{
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: false,
                    }}
                    views={{
                      timeGridWeek: {
                        dayHeaderFormat: {
                          day: "numeric",
                        },
                        dayHeaderContent: function (info) {
                          let s = ""
                          // get the day of the month (number)
                          const dayOfMonth = info?.date?.getDate()
                          // set the ordinal value of the date in s
                          if (dayOfMonth > 3 && dayOfMonth < 21) s = "th"
                          switch (dayOfMonth % 10) {
                            case 1:
                              s = ""
                              break
                            case 2:
                              s = ""
                              break
                            case 3:
                              s = ""
                              break
                            default:
                              s = ""
                          }
                          const locale = "en-US"
                          // get the name of the day (Friday, Saturday ...)
                          const dayName = info?.date?.toLocaleDateString(
                            locale,
                            {
                              weekday: "short",
                            }
                          )
                          return (
                            <div className="d-flex flex-column cursor-pointer-accordion">
                              <div>{"dayName"}</div>
                              <div
                                className={
                                  info?.isToday ? style.todaystyle : ""
                                }
                              >
                                {"dayOfMonth"}
                              </div>
                            </div>
                          )
                        },
                      },
                      timeGridDay: {
                        dayHeaderContent: function (info) {
                          const dayOfMonth = info.date.getDate()
                          const locale = "en-US"
                          const dayName = info.date.toLocaleDateString(locale, {
                            weekday: "short",
                          })
                          return (
                            <div className="d-flex flex-column">
                              <div className="cursor-pointer-accordion">
                                {dayName}
                              </div>
                              <div
                                className={info.isToday ? style.todaystyle : ""}
                              >
                                {dayOfMonth}
                              </div>
                            </div>
                          )
                        },
                      },
                    }}
                  />
                </Col>
               <Modal isOpen={scheduleLimit} size="lg">
                <ModalBody>
                  <div className="w-100 text-end">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => handleScheduleLimitModal()}
                    ></button>
                  </div>
                  <Col md={12}>
                    {appointmentTyeList.length > 1 ? (
                      <h3 className="card-title-main">
                        {appointmentTyeList[0]} +{" "}
                        {appointmentTyeList.length - 1} others
                      </h3>
                    ) : (
                      appointmentTyeList[0]
                    )}
                  </Col>

                  <div className="mb-4 mt-5">
                    {/* <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        className={`px-5 border border-dark btn ${
                          activeTab === "current"
                            ? "btn-primary"
                            : "btn-light"
                        }`}
                        onClick={() => toggleTab("current")}
                      >
                        Calendar Scheduling Limits
                      </button>
                      <button
                        type="button"
                        className={` border border-dark btn px-5 ${
                         activeTab === "block-time"
                            ? "btn-primary"
                            : "btn-light"
                        }`}
                        onClick={() => toggleTab("block-time")}
                      >
                        Blocked Time
                      </button>
                    </div> */}
                  </div>
                  {activeTab === "current" && (
                    <>
                    <h2 className="mx-2">Coming soon</h2>
                    {/* <Form className="needs-validation">
                      <Row>
                        <Col md={6} className="mt-2 mb-2 relative">
                          <div className="mt-4">
                            <p className="f-18-card-title-main ">
                              Do Not Allow Clients to Schedule Fewer Than
                            </p>

                            <div className="mt-3 d-flex align-items-center justify-content-flex-start">
                              <div className="input-select-days-and-time ">
                                <Input
                                  name="advance_hours"
                                  className="border border-dark w-75"
                                  type="number"
                                  value={scheduleLimitForm.advance_hours}
                                  onChange={handleScheduleChange}
                                />
                                {"  "}
                                <span>Hours in advance and more than</span>
                                {scheduleLimitForm.advance_hours &&
                                scheduleLimitForm.advance_hours < 1 &&
                                disableSubmit ? (
                                  <FormFeedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    Nagative value not allowed
                                  </FormFeedback>
                                ) : null}

                                <Input
                                  name="future_days"
                                  className="mt-3 border border-dark w-75"
                                  type="number"
                                  value={scheduleLimitForm.future_days}
                                  onChange={handleScheduleChange}
                                />
                                <span>Days in the future</span>
                                {scheduleLimitForm.future_days &&
                                scheduleLimitForm.future_days < 1 &&
                                disableSubmit ? (
                                  <FormFeedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    Nagative value not allowed
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <p className="f-18-card-title-main ">
                              Appointments Per Time Slot
                            </p>

                            <div className="mt-4 d-flex align-items-center justify-content-flex-start">
                              <div className="input-select-days-and-time ">
                                <Input
                                  name="appointment_per_slot"
                                  className="border border-dark"
                                  type="number"
                                  value={
                                    scheduleLimitForm.appointment_per_slot
                                  }
                                  onChange={handleScheduleChange}
                                />
                                {"  "}
                                <span>Appointments</span>
                                {scheduleLimitForm.appointment_per_slot &&
                                scheduleLimitForm.appointment_per_slot < 1 &&
                                disableSubmit ? (
                                  <FormFeedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    Nagative value not allowed
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="mt-2 mb-2 relative">
                          <div className="mt-4">
                            <p className="f-18-card-title-main ">
                              Do Not Allow Clients to Cancel or Reschedule
                              Appointments Less Than
                            </p>

                            <div className="mt-3 d-flex align-items-center justify-content-flex-start">
                              <div className="input-select-days-and-time ">
                                <Input
                                  name="min_change_notice_hours"
                                  className="border border-dark"
                                  type="number"
                                  value={
                                    scheduleLimitForm.min_change_notice_hours
                                  }
                                  onChange={handleScheduleChange}
                                />
                                {"  "}
                                <span>Hours in advance</span>
                                {scheduleLimitForm.min_change_notice_hours &&
                                scheduleLimitForm.min_change_notice_hours <
                                  1 &&
                                disableSubmit ? (
                                  <FormFeedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    Nagative value not allowed
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <p className="f-18-card-title-main ">
                              Maximum Appointments
                            </p>

                            <div className="mt-3 d-flex align-items-center justify-content-flex-start">
                              <div className="input-select-days-and-time ">
                                <Input
                                  name="accept_maximum_appointments"
                                  className="border border-dark"
                                  type="checkbox"
                                  defaultChecked={
                                    scheduleLimitForm.accept_maximum_appointments
                                  }
                                  isChecked={
                                    scheduleLimitForm.accept_maximum_appointments
                                  }
                                  onChange={handleScheduleChange}
                                />
                                {"  "}
                                <label>
                                  Accept appointments until fully booked
                                </label>
                              </div>
                            </div>
                            {!scheduleLimitForm.accept_maximum_appointments && (
                              <div className="mt-1 ">
                                <div className="input-select-days-and-time ">
                                  <Input
                                    name="accept_max_appointments"
                                    className="border border-dark"
                                    type="checkbox"
                                    isChecked={
                                      scheduleLimitForm.accept_max_appointments
                                    }
                                    defaultChecked={
                                      scheduleLimitForm.accept_max_appointments
                                    }
                                    onChange={handleScheduleChange}
                                  />
                                  {"  "}
                                  <label>
                                    Accept a max number of appointments
                                  </label>
                                </div>

                                {scheduleLimitForm.accept_max_appointments && (
                                  <>
                                    <div className="mt-2 input-select-days-and-time margin-left-10 d-flex  gap-1">
                                      <Input
                                        name="max_hours_per_day"
                                        className="w-25 mx-2 border border-dark"
                                        type="number"
                                        value={
                                          scheduleLimitForm.max_hours_per_day
                                        }
                                        onChange={handleScheduleChange}
                                      />
                                      <label className="mt-2">
                                        Max hours per day
                                      </label>

                                      {"  "}
                                    </div>
                                    {scheduleLimitForm.max_hours_per_day &&
                                    scheduleLimitForm.max_hours_per_day < 1 &&
                                    disableSubmit ? (
                                      <FormFeedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        Nagative value not allowed
                                      </FormFeedback>
                                    ) : null}
                                    <div className="mt-2 input-select-days-and-time margin-left-10 d-flex  gap-1">
                                      <Input
                                        name="max_number_per_day"
                                        className="w-25 mx-2 border border-dark"
                                        type="number"
                                        onChange={handleScheduleChange}
                                        value={
                                          scheduleLimitForm.max_number_per_day
                                        }
                                      />
                                      <label className="mt-2">
                                        Max number per day
                                      </label>

                                      {"  "}
                                    </div>
                                    {scheduleLimitForm.max_number_per_day &&
                                    scheduleLimitForm.max_number_per_day <
                                      1 &&
                                    disableSubmit ? (
                                      <FormFeedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        Nagative value not allowed
                                      </FormFeedback>
                                    ) : null}
                                    <div className="mt-2 input-select-days-and-time margin-left-10 d-flex  gap-1">
                                      <Input
                                        name="max_number_per_week"
                                        className="w-25 mx-2 border border-dark"
                                        type="number"
                                        value={
                                          scheduleLimitForm.max_number_per_week
                                        }
                                        onChange={handleScheduleChange}
                                      />
                                      <label className="mt-2">
                                        Max number per week
                                      </label>

                                      {"  "}
                                    </div>
                                    {scheduleLimitForm.max_number_per_week &&
                                    scheduleLimitForm.max_number_per_week <
                                      1 &&
                                    disableSubmit ? (
                                      <FormFeedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        Nagative value not allowed
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          {!scheduleLimitForm.accept_max_appointments &&
                          !scheduleLimitForm.accept_max_appointments &&
                          !scheduleLimitForm.accept_maximum_appointments &&
                          !scheduleLimitForm.accept_maximum_appointments &&
                          disableSubmit ? (
                            <FormFeedback type="invalid" className="d-block">
                              Please select any of these
                            </FormFeedback>
                          ) : null}
                        </Col>

                        <Col md={12} className="mt-4 align-items-center">
                          <button
                            type="button"
                            className="btn  btn-primary"
                            onClick={submitScheduleLimits}
                          >
                            Save
                          </button>{" "}
                          <button
                            type="button"
                            className="btn  margin-left-10"
                            onClick={() => handleScheduleLimitModal()}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form> */}
                    </>
                  )}

                  {activeTab === "block-time" && (
                    <>
                      {formattedOverrides?.length > 0 ? (
                        <>
                          {" "}
                          <h3 className="my-5 mx-4">Blocked off time</h3>
                          <Row>
                            {formattedOverrides?.map(data => {
                              return (
                                <>
                                  <Col md={12} className="mx-4">
                                    <p>
                                      <a
                                        onClick={() => {
                                          setDeleteBlockId(data.id)
                                          setModalDelete(true)
                                        }}
                                        className="text-danger delete-btn cursor-pointer"
                                      >
                                        <img src={deleteBin} />
                                      </a>{" "}
                                      <span className="mx-4 ">
                                        {data.date}
                                      </span>
                                    </p>
                                  </Col>
                                </>
                              )
                            })}
                          </Row>{" "}
                        </>
                      ) : (
                        <h3 className="mx-3 my-5">No data found</h3>
                      )}
                    </>
                  )}
                </ModalBody>
              </Modal> 
              <Modal isOpen={isMultipleHoursOpen}>
                <ModalBody>
                  <div className="w-100 text-end">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => handleMultipleHoursModal()}
                    ></button>
                  </div>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md={12} className="mt-2 mb-2 relative">
                        {dateMultipleHoursOpen ? (
                          <h4>
                            {" "}
                            Hours for{" "}
                            {moment(multipleAvailData[0]?.start).format(
                              "MMMM DD"
                            )}
                          </h4>
                        ) : (
                          <p className="text-center card-title-main">
                            Select {multipleAvailData[0]?.week_day} timeslots
                          </p>
                        )}
                        {dateMultipleHoursOpen && (
                          <Col md="12" className="mb-2">
                            <div className="form-check form-switch">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitch2"
                                name="is_closed"
                                checked={multipleAvailData[0]?.is_closed}
                                onChange={e => {
                                  const updatedData = [...multipleAvailData]
                                  updatedData[0].is_closed = !e.target.checked
                                  setMultipleAvailData(updatedData)
                                }}
                                onClick={e => {
                                  const updatedData = [...multipleAvailData]
                                  updatedData[0].is_closed = !e.target.checked
                                  setMultipleAvailData(updatedData)
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customSwitch2"
                              >
                                Closed
                              </label>
                            </div>
                          </Col>
                        )}
                        {multipleAvailData[0]?.is_closed &&
                        dateMultipleHoursOpen
                          ? ""
                          : multipleAvailData.map((item, index) => (
                              <div key={index}>
                                <Row className="mt-4 align-items-end">
                                  <Col
                                    md="5"
                                    className="custom-date-picker-style"
                                  >
                                    <Label>Start Time</Label>
                                    <Input
                                      className="form-control default-search-input"
                                      name={`availabilities[${index}].start_time`}
                                      placeholder="From"
                                      type="time"
                                      onChange={e =>
                                        handleMultiTimeSlot(
                                          e,
                                          index,
                                          "start_time"
                                        )
                                      }
                                      value={item.start_time}
                                    />
                                  </Col>
                                  <Col
                                    md="5"
                                    className="custom-date-picker-style"
                                  >
                                    <Label>End Time</Label>
                                    <Input
                                      className="form-control default-search-input"
                                      name={`availabilities[${index}].end_time`}
                                      placeholder="To"
                                      type="time"
                                      onChange={e =>
                                        handleMultiTimeSlot(
                                          e,
                                          index,
                                          "end_time"
                                        )
                                      }
                                      value={item.end_time}
                                    />
                                  </Col>
                                  {multipleAvailData.length > 1 && (
                                    <Col
                                      md="2"
                                      className="d-flex align-items-center justify-content-center"
                                    >
                                      <button
                                        type="button"
                                        onClick={() =>
                                          removeAvailability(index)
                                        }
                                        className="h-40 btn d-flex align-items-center waves-effect waves-light"
                                      >
                                        <i className="waves-effect mdi mdi-close font-size-20" />
                                      </button>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            ))}
                        {multipleAvailData[0]?.is_closed &&
                        dateMultipleHoursOpen
                          ? ""
                          : multipleAvailData.length < 5 && (
                              <Row className="mt-2">
                                <Col
                                  md="5"
                                  className="custom-date-picker-style"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={addAvailability}
                                  >
                                    Add more hours
                                  </button>
                                </Col>
                              </Row>
                            )}
                      </Col>

                      <Col md={12} className="mt-4 align-items-center">
                        {!dateMultipleHoursOpen && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => saveMultipleHours()}
                          >
                            Save
                          </button>
                        )}
                        {dateMultipleHoursOpen && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setMultipleHours()}
                          >
                            Set Hours
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn  margin-left-10"
                          onClick={() => handleMultipleHoursModal()}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <Modal
                isOpen={modalDelete}
                toggle={() => {
                  deleteToggle()
                }}
                centered={true}
              >
                <div className="modal-body">
                  <Row>
                    <Col className="col-12">
                      <div className="d-flex align-items-center flex-column">
                        <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                        <h3>Are you sure?</h3>
                        <h5>{"You won't be able to revert this!"}</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-center">
                        <Button
                          type="button"
                          onClick={() => {
                            deleteToggle()
                          }}
                          className="btn btn-inactive waves-effect m-2"
                          data-dismiss="modal"
                        >
                          No
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => handleDeleteBlockTIme()}
                        >
                          Yes, Delete it
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </Row>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RoomAvailibity
