import React, { useEffect, useState } from "react";
import { Input, FormGroup } from "reactstrap";
import { useSelector } from "react-redux";

function CheckboxField(props) {

    const { rowValue, row, index, field, paymentSourceId, onSelectRow } = props;
    const [value, setValue] = useState(false);
    const selectedClientAppointment = useSelector(state => state.invoicingManagement.selectedClientAppointments);

    useEffect(() => {
        const currentObj = selectedClientAppointment.find(n => n.id == paymentSourceId);
        if (currentObj) {
            if (currentObj.selectedList.includes(index)) {
                setValue(true);
            } else {
                setValue(false);
            }
        }
    }, [selectedClientAppointment]);

    const handleRowSelect = (id, isChecked) => {
        setValue(isChecked);
        onSelectRow(id, isChecked);
    }

    return (
        <div className="d-flex align-items-center justify-content-center">
            <FormGroup check>
                <Input
                    id="exampleCheck"
                    name="check"
                    type="checkbox"
                    checked={value}
                    onChange={(e) => handleRowSelect(index, e.target.checked)}
                />
            </FormGroup>
        </div>
    )
}

export default CheckboxField