import React, { useState, useEffect } from "react"
import feedbackImage from "../../../assets/images/feedback-head.svg"
import style from "../feedback.module.scss"
import classNames from "classnames"
import { SUBMIT_FEEDBACK_FORM } from "../gql/gql"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import Loader from "../../../components/Common/Loader"
import { useHistory } from "react-router-dom"
import { Spinner } from "reactstrap"

function OtherQuestions(props) {
  const {
    isSpinner,
    selectedSessionTypeId,
    handleBack,
    feedbackFormId,
    handleNext,
    ratingQuestionList,
    otherQuestionList,
    setOtherQuestionList,
  } = props

  const history = useHistory()
  const [isValidData, setIsValidData] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  useEffect(() => {
    const isValuesEmpty = otherQuestionList.every(note => note.value !== "")
    setIsValidData(isValuesEmpty)
  }, [otherQuestionList])

  //************************** submit data ***************************** */

  const [submitMutation, submitResponse] = useMutation(SUBMIT_FEEDBACK_FORM)

  const handleSubmit = () => {
    setInProgress(true)
    const mergedArray = [...otherQuestionList, ...ratingQuestionList]
    const sortedArray = mergedArray.sort((a, b) => a.id - b.id)
    const idArray = sortedArray.map(item => item.id)
    const valueArray = sortedArray.map(item => item.value)

    submitMutation({
      variables: {
        fillFeedbackValues: {
          feedback_id: feedbackFormId,
          question_ids: [...idArray],
          values: [...valueArray],
        },
      },
    })
  }

  useEffect(() => {
    if (submitResponse.data && submitResponse.data.fillFeedbackValues) {
      setInProgress(false)
      return history.push("/thank-you")
    } else {
      if (submitResponse.data && submitResponse.data.fillFeedbackValues) {
        toast.error(submitResponse.data.fillFeedbackValues.message)
        setInProgress(false)
      }
    }
  }, [submitResponse.data])

  return (
    <>
      {isSpinner ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center">
            <div>
              <img
                className={`${style.headImage} mg-top-default-ui`}
                src={feedbackImage}
                alt="feedback"
              />
              <h1 className="card-title-main">Post Consultation Feedback</h1>
              {otherQuestionList.length !== 0 && (
                <React.Fragment>
                  {otherQuestionList.map(otherQuestion => (
                    <React.Fragment key={otherQuestion.id}>
                      <h4 className="card-para-main mg-top-default-ui">
                        {otherQuestion.description}
                      </h4>
                      <textarea
                        className={style.feedbackTextArea}
                        key={otherQuestion.id}
                        value={otherQuestion.value}
                        onChange={e =>
                          setOtherQuestionList(prevTextAreaData =>
                            prevTextAreaData.map(prevTextArea =>
                              prevTextArea.id === otherQuestion.id
                                ? { ...prevTextArea, value: e.target.value }
                                : prevTextArea
                            )
                          )
                        }
                      ></textarea>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
              <div className="mt-2 d-flex gap-1">
                {!inProgress ? (
                  <button
                    className={classNames(
                      "btn-lg btn btn-primary waves-effect waves-light"
                    )}
                    disabled={!isValidData}
                    onClick={() => handleSubmit(1)}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className={classNames(
                      "btn-lg btn btn-primary waves-effect waves-light",
                      style.continueBtn
                    )}
                    disabled
                  >
                    <Spinner
                      type="grow"
                      className="me-1 align-middle spinner-grow-sm "
                      color="light"
                    />
                    Processing...
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default OtherQuestions
