import {
    SET_PAYMENT_TYPES,
} from "./actionTypes";

const INIT_STATE = {
    paymentTypes: [],
};

const paymentTypeManage = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PAYMENT_TYPES:
            const list = action.payload.list
            return {
                ...state,
                paymentTypes: list
            };
        default:
            return state;
    }
};

export default paymentTypeManage;