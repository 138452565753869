import React, { useEffect, useState } from "react";
import { Input, FormGroup } from "reactstrap";
import { GET_EXPANDED_DATA } from "../gql/gql";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { getClientAppointmentDetails, setClientSelectedAppointments } from "../../../../store/actions";
import { convertLocaltoUTC } from "../../../../functions/functions";

function AllSelect(props) {

  const { rowValue, row, index, field, selectedClient, startDate, endDate } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(false);
  const selectedClientAppointments = useSelector(state => state.invoicingManagement.selectedClientAppointments);
  const clientAppointmentsData = useSelector(state => state.invoicingManagement.clientAppointments);
  const currentclientAppointmentsDataObj = clientAppointmentsData.find(n => n.id === row.id);
  const currentObj = selectedClientAppointments.find(n => n.id === row.id);
  const currentObjData = currentObj && currentObj.selectedList ? currentObj.selectedList : []

  //************************** appointment type list ***************************** */

  const [getAppointmentDateList, listResponse] = useLazyQuery(GET_EXPANDED_DATA, {
    fetchPolicy: "no-cache",
  });

  function getAppointmentDateListFun() {
    getAppointmentDateList({
      variables: {
        argumentsInput: {
          payment_id: parseInt(row.id),
          start_date: startDate ? convertLocaltoUTC(startDate, "YYYY-MM-DD HH:mm:ssZ", true) : null,
          end_date: endDate ? convertLocaltoUTC(endDate, "YYYY-MM-DD HH:mm:ssZ", false, true) : null,
          ...(selectedClient ? { "user_id": parseInt(selectedClient) } : {}),
        },
      },
    });
  };

  useEffect(() => {
    if (value) {
      getAppointmentDateListFun();
    }
    else {
      dispatch(setClientSelectedAppointments(row.id, []));
    }
  }, [value]);

  useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointmentTypeWithClient &&
      listResponse.data.getAppointmentTypeWithClient.status &&
      listResponse.data.getAppointmentTypeWithClient.code === 200
    ) {
      const dispatchData = {
        id: row.id,
        data: listResponse.data.getAppointmentTypeWithClient.data.paymentTypeData
      }
      dispatch(getClientAppointmentDetails(dispatchData));
      const filteredArray = listResponse.data.getAppointmentTypeWithClient.data.paymentTypeData.map((str, index) => {
        return index;
      });
      dispatch(setClientSelectedAppointments(row.id, filteredArray));
    }
    else if (
      listResponse.data &&
      listResponse.data.getAppointmentTypeWithClient &&
      listResponse.data.getAppointmentTypeWithClient.code !== 200
    ) {
      const dispatchData = {
        id: parentRow.id,
        data: []
      }
      dispatch(getClientAppointmentDetails(dispatchData));
    }
  }, [listResponse.data]);

  useEffect(() => {
    if (currentObj && currentObj.selectedList && currentObj.selectedList.length > 0) {
      if (currentObj.selectedList.length == currentclientAppointmentsDataObj.data.length) {
        setValue(currentObj.selectedList.length == currentclientAppointmentsDataObj.data.length);
      }
      else {
        setValue(currentObj.selectedList.length == currentclientAppointmentsDataObj.data.length);
      }
    }

  }, [currentObjData.length]);

  const handleRowSelect = isChecked => {
    setValue(isChecked);
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <FormGroup check>
        <Input
          id="exampleCheck"
          name="check"
          type="checkbox"
          checked={value}
          onChange={e => handleRowSelect(e.target.checked)}
        />
      </FormGroup>
    </div>
  )
}

export default AllSelect
