import React, { useCallback, useState, useEffect, useMemo } from "react"
import classNames from "classnames"
import { Card, Input, CardTitle, Col, Row } from "reactstrap"
import useStyles from "../../Style"
import PreviewTable from "./PreviewTable"
import "react-datepicker/dist/react-datepicker.css"
import { toast } from "react-toastify"
import moment from "moment"
import Dialog from "./Dialog"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  capitalizeFirstLetter,
  downloadPDF,
} from "../../../../functions/functions"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  GET_INVOICE_NUMBER,
  GET_UPDATE_BILLED,
  UPDATE_INVOICE_NUMBER,
} from "../gql/gql"
import {
  setExportedInvoices,
  updatePreviewAllData,
} from "../../../../store/actions"
import Loader from "../../../../components/Common/Loader"

function Preview({
  data,
  handleUpdateRate,
  selectedPreviewID,
  storeData,
  onExportButtonApi,
  onRateupdate,
  deleted,
  setDeleted,
  handleDeleteData,
}) {
  const classes = useStyles()
  const userData = useSelector(state => state.Login?.user?.user)
  const [isEditableField, setIsEditableField] = useState(false)
  const [pdfData, setPdfData] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [isInvoice, setIsInvoice] = useState(
    userData.role.id === 1 ? true : false
  )

  let { parent, child, preview } = storeData
  const [state, setState] = useState({
    bill_to: child.payment_name,
    paymentType: child.payment_name,
    therapist_name:
      preview &&
      preview?.paymentTypeData &&
      preview?.paymentTypeData?.length > 0
        ? preview?.paymentTypeData[0].calendar
        : "",
    invoice_date: moment().toDate(),
    invoice_number:
      preview.invoice_Number && preview.invoice_Number == "NaN"
        ? 0
        : parseInt(preview.invoice_Number),
    total_billed:
      (preview.total_billed && preview.total_billed) == "NaN"
        ? 0
        : parseInt(preview.total_billed),
    clientName:
      preview &&
      preview?.paymentTypeData &&
      preview?.paymentTypeData?.length > 0
        ? preview?.paymentTypeData[0].client &&
          preview?.paymentTypeData[0].client.name
          ? preview?.paymentTypeData[0].client.name
          : preview?.paymentTypeData[0].client.first_name +
            " " +
            preview?.paymentTypeData[0].client.last_name
        : "",
    client_name:
      preview &&
      preview?.paymentTypeData &&
      preview?.paymentTypeData?.length > 0
        ? preview?.paymentTypeData[0].client &&
          preview?.paymentTypeData[0].client.name
          ? preview?.paymentTypeData[0].client.name
          : `${preview?.paymentTypeData[0].client.first_name} ${preview?.paymentTypeData[0].client.last_name}`
        : "",
    claim_Number: preview.claim_Number,
  })

  useEffect(() => {
    let { parent, child, preview } = storeData
    setState(prev => {
      return {
        ...prev,
        ...{
          invoice_number: preview.invoice_Number,
          total_billed:
            preview.total_billed == "NaN" ? 0 : preview.total_billed,
        },
      }
    })
  }, [preview])

  const onChangeEditableField = () => {
    setIsEditableField(!isEditableField)
  }

  const onUpdateEditableField = () => {
    let { total_billed, claim_Number, invoice_Number, bill_to, client_name } =
      state
    let updatedPreview = preview
    updatedPreview.total_billed =
      state.total_billed == "NaN" ? 0 : parseInt(state.total_billed)
    updatedPreview.claim_Number = state.claim_Number
    updatedPreview.invoice_Number = parseInt(state.invoice_number)
    if (
      updatedPreview &&
      updatedPreview?.paymentTypeData &&
      updatedPreview?.paymentTypeData?.length > 0
    ) {
      if (updatedPreview.paymentTypeData[0].client) {
        updatedPreview.paymentTypeData[0].client = {
          ...updatedPreview.paymentTypeData[0].client,
          ...{ name: client_name },
        }
      } else {
        updatedPreview.paymentTypeData[0].client = {}
        updatedPreview.paymentTypeData[0].client.name = client_name
      }
      if (updatedPreview.paymentTypeData[0].payment_type) {
        updatedPreview.paymentTypeData[0].payment_type.name = bill_to
      }
    } else {
      updatedPreview.client.name = ""
    }
    dispatch(
      updatePreviewAllData(parent.id, child.id, { changedObj: updatedPreview })
    )
    updateInvoiceNumber({
      variables: {
        argumentsInput: {
          invoice_Number: parseInt(state.invoice_number),
        },
      },
    })
    getUpdateBilled({
      variables: {
        argumentsInput: {
          billed_sessions: updatedPreview.total_billed
            ? updatedPreview.total_billed
            : 0,
          calendar_id: parseInt(child.calendar_id),
          client_id: parseInt(child.client_id),
          payment_type_id: parseInt(parent.id),
        },
      },
    }).then(res => {
      setIsEditableField(!isEditableField)
    })
  }

  const handleChange = () => {
    setIsInvoice(!isInvoice)
  }

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null

  //************************** Email ***************************** */

  const [isOpenEmailDialog, setIsOpenEmailDialog] = useState(false)

  const handleEmailClick = () => {
    setIsOpenEmailDialog(!isOpenEmailDialog)
  }

  const [initialValues, setInitialValues] = useState({
    email: "",
    title: "",
    file: "",
    url: "https://pulkit-bucket.s3.ap-south-1.amazonaws.com/Invoice-8021576-April-c3cd480a-acff-41a0-b61a-1f634bdca5d4.pdf",
    name: "NFC-8021576-Apr2023",
  })

  useEffect(() => {
    let total_sessions =
      preview && preview.paymentTypeData && preview.paymentTypeData.length
    let total_Amount = 0
    let total_gst_Amount = 0
    let total_rate = 0
    preview &&
      preview.paymentTypeData &&
      preview.paymentTypeData.forEach(element => {
        total_Amount = total_Amount + parseFloat(parseFloat(element.total).toFixed(2))
        total_gst_Amount = total_gst_Amount + parseFloat(parseFloat(element.gst).toFixed(2))
        total_rate = total_rate + parseFloat(parseFloat(element.price).toFixed(2))
      })
    let dataupdated =
      preview &&
      preview.paymentTypeData &&
      preview.paymentTypeData.map((item, index) => {
        let client = {
          ...item.client,
          first_name: item.first_name,
          last_name: item.last_name,
        }
        item.gst = parseFloat(item.gst)
        item.type = selectedPreviewID.child.name
        return { ...item, ...{ client: client } }
      })
    let pdfname = ""
    if (
      preview &&
      preview.paymentTypeData &&
      preview.paymentTypeData.length > 0
    ) {
      if (
        preview.paymentTypeData[0].client &&
        preview.paymentTypeData[0].client.first_name &&
        preview.paymentTypeData[0].client.last_name
      ) {
        pdfname = `${preview.paymentTypeData[0].client.first_name}${preview.paymentTypeData[0].client.last_name}`
      } else {
        pdfname = `${preview.paymentTypeData[0].name.replace(" ", "_")}`
      }
    }
    let objData = {
      calendar: {
        name: child.name,
        id: 604905,
        email:
          preview?.paymentTypeData.length > 0
            ? preview?.paymentTypeData[0].email
            : "",
        total_billed:
          preview.total_billed == "NaN" ? 0 : parseInt(preview.total_billed),
        claim_Number: preview.claim_Number,
        claim_number: preview.claim_Number,
        is_invoice: true,
        invoice_number: preview.invoice_Number,
        total_sessions: total_sessions,
        total_Amount: parseFloat(total_Amount).toFixed(2),
        total_gst_Amount: parseFloat(total_gst_Amount).toFixed(2),
        total_rate: parseFloat(total_rate).toFixed(2),
        appointments: dataupdated,
        is_receipt: !isInvoice,
      },
    }
    let pdf_name = ""
    let current_month = moment().format("DD-MMM")
    if (
      preview &&
      preview?.paymentTypeData &&
      preview?.paymentTypeData.length > 0
    ) {
      if (
        preview?.paymentTypeData[0].client.first_name &&
        preview?.paymentTypeData[0].client.last_name
      ) {
        pdf_name = `Invoice-${preview.invoice_Number}${
          preview.claim_Number ? "-" + preview.claim_Number : ""
        }-${preview?.paymentTypeData[0].client.first_name}-${
          preview?.paymentTypeData[0].client.last_name
        }.pdf`
      } else if (preview?.paymentTypeData[0].client.name) {
        let arr = preview?.paymentTypeData[0].client.name.split(" ")
        pdf_name = `Invoice-${preview.invoice_Number}${
          preview.claim_Number ? "-" + preview.claim_Number : ""
        }-${arr[0]}-${arr[1]}.pdf`
      }
    }
    objData.calendar.pdf_name = pdf_name
    const dialogData = {
      email:
        preview?.paymentTypeData.length > 0
          ? preview?.paymentTypeData[0].email
          : "",
      title: `${orgData?.name ? orgData?.name : "No Fear Counselling"} Receipt`,
      name: `${pdf_name ? pdf_name : pdfname + ".pdf"}`,
      data: objData,
    }
    setPdfData(dialogData.data)
    setInitialValues(dialogData)
  }, [data, JSON.stringify(preview), selectedPreviewID, isInvoice])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),
      title: Yup.string().required(),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      handleSubmit(values)
    },
  })

  const handleSubmit = values => {
    const counselorObj = {
      ...values.data,
    }
    let url = `${process.env.REACT_APP_API_ENDPOINT}/mail/receiptPdf?email=${values.email}`
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined


    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

      },
      body: JSON.stringify(counselorObj),
    }
    fetch(url, requestOptions).then(res => {
      setIsSubmitted(false)
      if (res.status == "201") {
        handleEmailClick()
        toast.success("The mail has been sent to your email")
      }
    })
  }

  const [getInvoiceData, invoiceDataResponse] = useLazyQuery(
    GET_INVOICE_NUMBER,
    { fetchPolicy: "no-cache" }
  )
  const [getUpdateBilled, UpdateBilledResponse] = useLazyQuery(
    GET_UPDATE_BILLED,
    { fetchPolicy: "no-cache" }
  )
  const [updateInvoiceNumber, invoiceNumberResponse] = useMutation(
    UPDATE_INVOICE_NUMBER
  )

  const dispatch = useDispatch()
  const [isSpinner, setIsSpinner] = useState(false)
  const handleExportPreviw = () => {
    toast.info("Generating PDF, Please wait for some time")
    setIsSpinner(true)
    getInvoiceData({
      variables: {
        argumentsInput: {
          calendar_id: parseInt(child.calendar_id),
          client_id: parseInt(child.client_id),
          payment_type_id: parseInt(parent.id),
          total_billed:
            preview.paymentTypeData && preview.paymentTypeData.length,
          billed_sessions:
            preview.paymentTypeData && preview.paymentTypeData.length, // number of session
        },
      },
    }).then(res => {
      if (res.data && res.data?.exportInvoice?.code == 200) {
        generatePDF()
        const obj = {
          invoice_number: preview.invoice_Number || preview.invoice_number,
          claim_Number: preview.claim_Number || preview.claim_number,
          claim_number: preview.claim_number || preview.claim_Number,
          invoice_date: moment(),
          therapist_name:
            preview.paymentTypeData.length > 0
              ? preview.paymentTypeData[0].calendar
              : "",
          client_name:
            preview &&
            preview?.paymentTypeData &&
            preview?.paymentTypeData?.length > 0
              ? preview?.paymentTypeData[0].client &&
                preview?.paymentTypeData[0].client.name
                ? preview?.paymentTypeData[0].client.name
                : `${preview?.paymentTypeData[0].client.first_name} ${preview?.paymentTypeData[0].client.last_name}`
              : "",
          total: pdfData.calendar.total_Amount,
          number_of_appointment: preview.paymentTypeData.length,
          payment_id: parseInt(parent.id),
          calendar_id: parseInt(child.calendar_id),
          client_id: parseInt(child.client_id),
          child_row: child,
          parent_row: parent,
        }
        dispatch(setExportedInvoices(obj))
        let { total_billed, claim_Number, invoice_Number } = state
        let updatedPreview = preview
        updatedPreview.total_billed =
          parseInt(
            state.total_billed ? state.total_billed : preview.total_billed
          ) + preview.paymentTypeData.length
        updatedPreview.claim_Number = state.claim_Number
        updatedPreview.invoice_Number =
          parseInt(
            state.invoice_number ? state.invoice_number : preview.invoice_number
          ) + 1
        dispatch(
          updatePreviewAllData(parent.id, child.id, {
            changedObj: updatedPreview,
          })
        )
        setTimeout(() => {
          setIsSpinner(false)
        }, 1000)
      }
    })
  }

  const generatePDF = () => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/mail/receiptPdf`
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined


    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

      },
      body: JSON.stringify(pdfData),
    }
    fetch(url, requestOptions)
      .then(res => {
        if (res.status == "201") {
          return res.json()
        }
      })
      .then(res => {
        if (res.data && res.data.Location) {
          downloadPDF(res.data.Location, res.data.Key)
        }
      })
  }

  useEffect(() => {
    if (
      invoiceDataResponse.data &&
      invoiceDataResponse.data?.exportInvoice?.code == 200
    ) {
      onExportButtonApi()
    }
  }, [invoiceDataResponse.data])

  const onChangeTotalBilled = e => {
    const value = e.target.value ? e.target.value.toString() : "0"
    setState(s => ({
      ...s,
      total_billed: e.target.value,
    }))
  }

  const onInvoiceNumberChange = e => {
    const value = e.target.value ? e.target.value : 0
    setState(s => ({
      ...s,
      invoice_number: parseInt(e.target.value),
    }))
  }

  return (
    <>
      <Row className="align-items-center">
        <Col xs={4} sm={6}>
          <CardTitle className="h4 m-0">
            <h5 className="m-0 card-title-main">
              {isInvoice ? "Invoice" : "Receipt"}
            </h5>
          </CardTitle>
        </Col>
        <Col xs={8} sm={6}>
          <div className="d-flex align-items-stretch justify-content-end gap-2">
            {!isInvoice && (
              <button
                type="button"
                className={classNames(
                  `btn btn-primary text-capitalize`,
                  classes.button
                )}
                onClick={() => handleEmailClick()}
              >
                Email
              </button>
            )}
            {userData.role.id === 1 && (
              <>
                <button
                  type="button"
                  className={classNames(
                    `btn btn-primary text-capitalize`,
                    classes.button
                  )}
                  onClick={handleChange}
                >
                  {isInvoice ? "As receipt" : "As Invoice"}
                </button>
                <button
                  type="button"
                  className={classNames(`btn text-capitalize btn-primary`)}
                  onClick={handleExportPreviw}
                >
                  Export
                </button>
                <button
                  type="button"
                  className={classNames(
                    `btn text-capitalize ${
                      deleted.length > 0 ? "btn-primary" : "disabled"
                    }`,
                    classes.button
                  )}
                  disabled={deleted.length > 0 ? false : true}
                  onClick={handleDeleteData}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </Col>
      </Row>

      <Card
        className={classNames(
          "w-100 mt-3 card-green-light",
          classes.invoiceContainer
        )}
      >
        {isSpinner ? (
          <Col className="user-role-create-height-double">
            <div className="spinner">
              <Loader />
            </div>
          </Col>
        ) : (
          ""
        )}
        <div className="gridPreview">
          <div className="fw-bold grid-item margin-top-10px-xs ">Bill To:</div>

          <div className="grid-item margin-top-10px-xs ">
            {isEditableField ? (
              <Input
                id="bill_to"
                name="bill_to"
                className="form-control w70"
                type="text"
                onChange={e =>
                  setState(s => ({
                    ...s,
                    bill_to: e.target.value,
                  }))
                }
                onBlur={validation.handleBlur}
                value={state.bill_to}
                invalid={
                  validation.touched.bill_to && validation.errors.bill_to
                    ? true
                    : false
                }
              />
            ) : (
              <div>{capitalizeFirstLetter(state.bill_to)}</div>
            )}
          </div>
          <div className="fw-bold grid-item margin-top-10px-xs ">
            {" "}
            Supplier Name:
          </div>
          <div className="grid-item margin-top-10px-xs ">
          {orgData?.name ? orgData?.name : "No Fear Counselling Corp"}
          </div>

          <div className="fw-bold grid-item margin-top-10px-xs ">
            Client Name:{" "}
          </div>
          <div className="grid-item margin-top-10px-xs ">
            {isEditableField ? (
              <Input
                id="client_name"
                name="client_name"
                className="form-control  w70"
                type="text"
                onChange={e =>
                  setState(s => ({
                    ...s,
                    client_name: e.target.value,
                  }))
                }
                onBlur={validation.handleBlur}
                value={state.client_name}
                invalid={
                  validation.touched.client_name &&
                  validation.errors.client_name
                    ? true
                    : false
                }
              />
            ) : (
              <div className="grid-item margin-top-10px-xs ">
                {capitalizeFirstLetter(state.client_name)}
              </div>
            )}
          </div>

          <div className="fw-bold grid-item margin-top-10px-xs ">
            Supplier Address:{" "}
          </div>
          <div className="grid-item margin-top-10px-xs ">
            211-7885 6th St, Burnaby, BC, V3N 3N4
          </div>
          <div
            className={`fw-bold grid-item margin-top-10px-xs  ${
              !state.claim_Number ? "red" : ""
            } ${!isInvoice ? "d-none" : ""} ${
              parent.id == "1" ? "d-none" : ""
            }`}
          >
            Claim Number:{" "}
          </div>

          {isEditableField ? (
            <Input
              id="claim_Number"
              name="claim_Number"
              className={`form-control grid-item margin-top-10px-xs  w70 ${
                !isInvoice ? "d-none" : ""
              } ${parent.id == "1" ? "d-none" : ""}`}
              type="text"
              onChange={e =>
                setState(s => ({
                  ...s,
                  claim_Number: e.target.value,
                }))
              }
              onBlur={validation.handleBlur}
              value={state.claim_Number}
              invalid={
                validation.touched.claim_Number &&
                validation.errors.claim_Number
                  ? true
                  : false
              }
            />
          ) : (
            <div
              className={`grid-item margin-top-10px-xs  ${
                !isInvoice ? "d-none" : ""
              } ${parent.id == "1" ? "d-none" : ""}`}
            >
              {state.claim_Number}
            </div>
          )}

          <div className="fw-bold grid-item margin-top-10px-xs ">
            Supplier Phone:{" "}
          </div>
          <div className="grid-item margin-top-10px-xs ">778-288-8361</div>
          <div className="fw-bold grid-item margin-top-10px-xs ">
            Therapist Name:{" "}
          </div>
          <div className="grid-item margin-top-10px-xs ">
            {state.therapist_name}
          </div>
          <div className="fw-bold grid-item margin-top-10px-xs ">
            Supplier FAX:{" "}
          </div>
          <div className="grid-item margin-top-10px-xs ">604-357-5182</div>
          {isInvoice && (
            <div
              className={`fw-bold grid-item margin-top-10px-xs  ${
                parent.id == "1" ? "d-none" : ""
              }`}
            >
              Invoice Number:{" "}
            </div>
          )}
          {isEditableField ? (
            <Input
              id="invoice_number"
              name="invoice_number"
              className={`form-control grid-item margin-top-10px-xs  w70 ${
                !isInvoice ? "d-none" : ""
              } ${parent.id == "1" ? "d-none" : ""}`}
              type="number"
              min={0}
              onChange={onInvoiceNumberChange}
              onBlur={validation.handleBlur}
              value={parseInt(state.invoice_number)}
              invalid={
                validation.touched.invoice_number &&
                validation.errors.invoice_number
                  ? true
                  : false
              }
            />
          ) : (
            <div
              className={`grid-item margin-top-10px-xs  ${
                !isInvoice ? "d-none" : ""
              } ${parent.id == "1" ? "d-none" : ""}`}
            >
              {state.invoice_number ? state.invoice_number : 0}
            </div>
          )}
          <div className="fw-bold grid-item margin-top-10px-xs ">
            {isInvoice ? "Invoice Date:" : "Receipt Date:"}
          </div>
          <div className="grid-item margin-top-10px-xs ">
            {moment(state.invoice_date).format("DD-MMM-yyyy")}
          </div>
          {isInvoice && (
            <div
              className={`fw-bold grid-item margin-top-10px-xs  ${
                parent.id == "1" ? "d-none" : ""
              }`}
            >
              Total Billed:{" "}
            </div>
          )}
          {isEditableField ? (
            <Input
              id="total_billed"
              name="total_billed"
              className={`form-control grid-item margin-top-10px-xs  w70 ${
                !isInvoice && "d-none"
              } ${parent.id == "1" ? "d-none" : ""}`}
              type="number"
              min={0}
              onChange={onChangeTotalBilled}
              onBlur={validation.handleBlur}
              value={parseInt(state.total_billed)}
              invalid={
                validation.touched.total_billed &&
                validation.errors.total_billed
                  ? true
                  : false
              }
            />
          ) : (
            <div
              className={`grid-item margin-top-10px-xs  ${
                !isInvoice && "d-none"
              } ${parent.id == "1" ? "d-none" : ""}`}
            >
              {state.total_billed == "" ? 0 : parseInt(state.total_billed)}
            </div>
          )}
          <div className="fw-bold grid-item margin-top-10px-xs ">
            GST Number:{" "}
          </div>
          <div className=" grid-item margin-top-10px-xs ">738726926 RT0001</div>
          {isInvoice && (
            <div
              className={`fw-bold grid-item margin-top-10px-xs  ${
                parent.id == "1" ? "d-none" : ""
              }`}
            >
              Account Number:{" "}
            </div>
          )}
          {isInvoice && (
            <div
              className={`grid-item margin-top-10px-xs  ${
                parent.id == "1" ? "d-none" : ""
              }`}
            >
              5017518
            </div>
          )}
        </div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-end">
            {isEditableField ? (
              <button
                className="btn btn-primary "
                onClick={() => onUpdateEditableField()}
              >
                {" "}
                Update
              </button>
            ) : (
              <button
                className="btn btn-primary "
                onClick={() => onChangeEditableField()}
              >
                {" "}
                Edit
              </button>
            )}
          </Col>
        </Row>
      </Card>

      <Dialog
        open={isOpenEmailDialog}
        onToggle={handleEmailClick}
        validation={validation}
        isShowBodyField={false}
        isChooseFile={false}
        isDisabled={isSubmitted}
        isTitleFieldDisabled={true}
        pdfData={pdfData}
      />
      {preview?.paymentTypeData?.length !== 0 && (
        <Row className="mt-4">
          <PreviewTable
            data={
              preview && preview.paymentTypeData ? preview.paymentTypeData : []
            }
            handleUpdateRate={handleUpdateRate}
            selectedPreviewID={selectedPreviewID}
            storeData={storeData}
            preview={preview}
            child={child}
            onRateupdate={onRateupdate}
            setDeleted={setDeleted}
          />
        </Row>
      )}
    </>
  )
}

export default Preview
