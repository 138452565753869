import React, { useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-toastify/dist/ReactToastify.css"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Spinner,
  BreadcrumbItem,
  CardSubtitle,
} from "reactstrap"
import Select from "react-select"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  ADD_PERMISSION,
  GET_PERMISSION,
  MODULES,
  USER_LIST,
  GET_ROLES,
} from "./gql/PermissionGql"
import { toast } from "react-toastify"
import { checkPermissions, scrollTop } from "../../functions/functions"
import { useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import style from "./style.module.scss"

const CreateEditPermission = props => {
  let { type, id, name } = props.match.params
  const decodedId =id && atob(id);
  id = decodedId;
  const history = useHistory()
  const [modules, setModules] = useState()
  const [inProgress, setInProgress] = useState(false)
  const [option, setOptions] = useState(type === "user" ? "u" : "r")
  const [modulePermissions, setModulePermissions] = useState([])
  const [roleList, setRoleList] = useState()
  const [role_id, setRoleId] = React.useState(type === "role" ? id : null)
  const [userList, setUserList] = useState()
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 100,
  })
  const userData = useSelector(state => state.Login?.user?.user)
  const [isSubmitted, setIsSubmitted] = useState(false)

  //*************************** Module List  ****************************//

  const [getModuleList, moduleList] = useLazyQuery(MODULES, {
    fetchPolicy: "no-cache",
  })
  React.useEffect(() => {
    getModuleList({
      variables: {
        argumentsInput: {
          page: state.page,
          limit: state.sizePerPage,
          search: "",
        },
      },
    })
  }, [])

  React.useEffect(() => {
    if (
      moduleList.data &&
      moduleList.data.getModules &&
      moduleList.data.getModules.code === 200
    ) {
      setModules(moduleList.data.getModules.data.modules)
    }
  }, [moduleList.data])
  //*******************************************************//

  function selectedRolePermission(e, slug) {
    let permission = [...modulePermissions]
    if (e) {
      permission.push(slug)
    } else {
      permission = permission.filter(item => item !== slug)
    }
    setModulePermissions(permission)
  }

  function selectedRolePermissionForAll(e, list) {
    let permission = [...modulePermissions]
    if (!e) {
      list.map(item => {
        if (modulePermissions.includes(item.slug)) {
          permission = permission.filter(i => i !== item.slug)
        }
      })
    } else {
      list.map(item => {
        if (!modulePermissions.includes(item.slug)) {
          permission.push(item.slug)
        }
      })
    }
    setModulePermissions(permission)
  }

  //************ Role list ************ */

  const [getAllRoleList, roleDetails] = useLazyQuery(GET_ROLES, {
    fetchPolicy: "no-cache",
  })

  React.useEffect(() => {
    if (option === "r") {
      getAllRoleList({
        variables: {
          argumentsInput: {
            page: state.page,
            limit: state.sizePerPage,
            search: "",
            sort: null,
          },
        },
      })
    }
  }, [option])

  React.useEffect(() => {
    if (
      roleDetails.data &&
      roleDetails.data.getRoles &&
      roleDetails.data.getRoles.code === 200
    ) {
      setRoleList(roleDetails.data.getRoles.data.roles)
    } else {
      setRoleList([])
    }
  }, [roleDetails.data])
  //*********************************** */

  //*************************** Users  */
  const [selectedUser, setSelectedUser] = React.useState(
    type === "user" ? { id: id, name: name } : null
  )

  const [getAllUserList, userListDetails] = useLazyQuery(USER_LIST, {
    fetchPolicy: "no-cache",
  })

  React.useEffect(() => {
    if (option === "u") {
      getAllUserList({
        variables: {
          argumentsInput: {
            page: state.page,
            limit: state.sizePerPage,
            search: "",
            sort: "name",
            sort_dir: "ASC",
          },
        },
      })
    }
  }, [option])

  React.useEffect(() => {
    if (
      userListDetails.data &&
      userListDetails.data.getUsers &&
      userListDetails.data.getUsers.code === 200
    ) {
      setUserList(userListDetails.data.getUsers.data.users)
    } else {
      setUserList()
    }
  }, [userListDetails.data])

  const searchUsers = async inputValue => {
    let searchUser = []
    try {
      await userList
        .refetch({
          argumentsInput: {
            page: 1,
            limit: 20,
            search: inputValue,
          },
        })
        .then(res => {
          searchUser = res.data.getUsers.data.users
        })
    } catch (e) {}
    return searchUser
  }

  const [getUserPermission, details] = useLazyQuery(GET_PERMISSION, {
    fetchPolicy: "no-cache",
  })

  React.useEffect(() => {
    if (selectedUser || role_id) {
      getUserPermission({
        variables: {
          argumentsInput: {
            role_id: role_id ? parseInt(role_id) : 0,
          },
        },
      })
    }
  }, [option, selectedUser, role_id])

  React.useEffect(() => {
    if (
      details.data &&
      details.data.getPermission &&
      details.data.getPermission.code === 200
    ) {
      setModulePermissions(details.data.getPermission.data.permission)
    } else {
      setModulePermissions([])
    }
  }, [details.data])
  //*********************************** */

  //*************************** Submit  */
  const handleSubmit = async () => {
    try {
      setInProgress(true)
      setIsSubmitted(true)
      await submitMutation({
        variables: {
          permissionInput: {
            role_id: parseInt(role_id) || 0,
            methods: modulePermissions,
          },
        },
      })
    } catch (e) {
      setInProgress(false)
      setIsSubmitted(false)
    }
  }

  const [submitMutation, submitDetail] = useMutation(ADD_PERMISSION)
  React.useEffect(() => {
    setInProgress(false)
    if (submitDetail.data && submitDetail.data.addPermission.code === 200) {
      toast.success(submitDetail.data.addPermission.message)
      scrollTop()
      setTimeout(() => {
        if (option === "u") {
          props.history.push(`/user-permission-list`)
        } else {
          props.history.push(`/group-permissions`)
        }
      }, 5000)
    } else {
      if (submitDetail.data && submitDetail.data.createContest) {
        toast.error(submitDetail.data.addPermission.message)
      }
      setIsSubmitted(false)
    }
  }, [submitDetail.data])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={style.headingsubtitle}>
                    {id ? "Edit Role Permission" : "Add Role Permission"}
                  </h4>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/group-permissions">Role Permissions</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">
                        {id ? "Edit Role Permission" : "Add Role Permission"}
                      </Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <a>
                  <i className="waves-effect mdi mdi-arrow-left font-size-22" id="backArrow" onClick={() => history.goBack()} />
                </a>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Role Permission</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{id ? "Edit Role Permission" : "Create Role Permission" }</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className={style.cardCenter}>
            <Col lg={2}></Col>
            <Col lg={8}>
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <h4>Role Base Permission </h4>
                      {!name ? (
                        <>
                          <Row className="mb-3">
                            {roleList && (
                              <Col md="3">
                                <div className="mb-3">
                                  {/* <Label>Role Base</Label> */}
                                  <Select
                                    value={roleList.filter(
                                      option => option.id === role_id
                                    )}
                                    options={roleList}
                                    name="role_id"
                                    classNamePrefix="select"
                                    getOptionLabel={option => option.role_name}
                                    getOptionValue={option => option.id}
                                    onChange={data => setRoleId(data.id)}
                                  />
                                </div>
                              </Col>
                            )}
                          </Row>
                        </>
                      ) : (
                        <div className="mt-4">
                          <div>
                            <h6 className="font-size-14 border-bottom pb-3 mt-3">
                              Role Name :{" "}
                              <span className="font-size-14 fw-normal text-muted ">
                                {name}
                              </span>
                            </h6>
                          </div>
                        </div>
                      )}
                      <div className={`mb-3 text-capitalize ${style.alllinks}`}>
                        <Row className="row-cols-xxl-6 row-cols-xl-5 row-cols-md-3 row-cols-sm-2 row-cols-1">
                          {modules &&
                            modules.map((module, index) => (
                              <Col md="3" key={index}>
                                <CardTitle>
                                  <div className="">
                                    <div>
                                      <h6 className="font-size-16 text-dark mb-0">
                                        {" "}
                                        {module.name}
                                      </h6>
                                      {/* <CardSubtitle className={`mb-3 ${style.subTitleStyle}`}>Assign rights to perform a specific action on the admin panel by this group</CardSubtitle> */}
                                    </div>
                                    <div className="my-3 mb-0">
                                      <div className="form-check form-checkbox form-checkbox-lg">
                                        <Input
                                          type="checkbox"
                                          id={module.name}
                                          name={module.name}
                                          className="form-check-input"
                                          checked={
                                            modulePermissions &&
                                            modulePermissions.length
                                              ? module.methods.every(i =>
                                                  modulePermissions.includes(
                                                    i.slug
                                                  )
                                                )
                                              : false
                                          }
                                          onClick={() =>
                                            selectedRolePermissionForAll(
                                              !module.methods.every(i =>
                                                modulePermissions.includes(
                                                  i.slug
                                                )
                                              ),
                                              module.methods
                                            )
                                          }
                                        />
                                        <Label
                                          htmlFor={module.name}
                                          className="mb-0 font-size-15 text-muted"
                                        >
                                          Select All
                                        </Label>
                                      </div>
                                    </div>
                                  </div>
                                </CardTitle>
                                <div className="mt-3">
                                  <Row className="row-cols-1">
                                    {module.methods &&
                                      module.methods.map((method, index) => (
                                        <Col className="py-1" key={index}>
                                          <div className="form-check ps-sm-auto ps-0">
                                            <div className="form-check form-checkbox form-checkbox-lg">
                                              <Input
                                                type="checkbox"
                                                name={method.slug}
                                                id={method.slug}
                                                checked={
                                                  modulePermissions &&
                                                  modulePermissions.includes(
                                                    method.slug
                                                  )
                                                }
                                                onClick={() =>
                                                  selectedRolePermission(
                                                    !modulePermissions.includes(
                                                      method.slug
                                                    ),
                                                    method.slug
                                                  )
                                                }
                                              />
                                              <Label
                                                className="mb-0"
                                                htmlFor={method.slug}
                                              >
                                                {method.name}
                                              </Label>
                                            </div>
                                          </div>
                                        </Col>
                                      ))}
                                  </Row>
                                </div>
                              </Col>
                            ))}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="text-end">
                    {!inProgress ? (
                      <button
                        className="btn btn-primary w-sm waves-effect waves-light"
                        type="button"
                        onClick={handleSubmit}
                        disabled={isSubmitted}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary w-sm waves-effect waves-light"
                        disabled
                      >
                        <Spinner
                          type="grow"
                          className="me-1 align-middle spinner-grow-sm "
                          color="light"
                        />
                        Processing...
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateEditPermission
