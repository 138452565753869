import { useLazyQuery, useMutation } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { getUrlParams } from "../../../functions/functions"
import {
  GET_CLIENTS,
  GET_Deactivated_Clients_DATA,
  GET_INITIAL_DATA,
  GET_NO_SHOW_DATA,
  GET_PAST_CLIENT_DATA,
  GET_UPDATE_COMPLETED_DATA,
  GET_UPDATE_DEAVTIVE_COMPLETED_DATA,
  SUBMIT_CLIENT,
} from "./Gql"
import "./dashboard.scss"
import { Card, Col, Input, Row } from "reactstrap"
import moment from "moment"
import Loader from "../../../components/Common/Loader"

const DashboardDetails = () => {
  const type = getUrlParams("type")
  const history = useHistory()
  const [isLoader, setIsLoader] = useState(true)
  const [completedTask, setCompletedTask] = useState([])
  const [subTextContent, setSubTextContent] = useState("")

  const [getNoshow, getNowShowResponse] = useLazyQuery(GET_NO_SHOW_DATA, {
    fetchPolicy: "no-cache",
  })

  const [getDeactivatedClients, getDeactivatedClientsResponse] = useLazyQuery(
    GET_Deactivated_Clients_DATA,
    { fetchPolicy: "no-cache" }
  )

  const [getCLients, getClientsResponse] = useLazyQuery(GET_CLIENTS, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(SUBMIT_CLIENT)

  const [getInitial, getInitialResponse] = useLazyQuery(GET_INITIAL_DATA, {
    fetchPolicy: "no-cache",
  })

  const [getPastClients, getPastClientsResponse] = useLazyQuery(
    GET_PAST_CLIENT_DATA,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [getCompleted, getcompletedResponse] = useMutation(
    GET_UPDATE_COMPLETED_DATA
  )

  const [getDeactiveCompleted, getDeactivecompletedResponse] = useMutation(
    GET_UPDATE_DEAVTIVE_COMPLETED_DATA
  )

  useEffect(() => {
    if (
      getNowShowResponse.loading ||
      getDeactivatedClientsResponse.loading ||
      getInitialResponse.loading ||
      getPastClientsResponse.loading ||
      getcompletedResponse.loading ||
      getDeactivecompletedResponse.loading ||
      getClientsResponse.loading
    ) {
      setIsLoader(true)
    } else {
      setIsLoader(false)
    }
  }, [
    getNowShowResponse,
    getDeactivatedClientsResponse,
    getInitialResponse,
    getPastClientsResponse,
    getcompletedResponse,
    getDeactivecompletedResponse,
    getClientsResponse,
  ])

  const [nodata, getNoData] = useState([])

  const getNoshowApi = () => {
    getNoshow({
      variables: {
        dashboardInput: {
          start_date: null,
          is_no_show_complete_task: false,
        },
      },
    })
  }

  const getDeactivatedClientsApi = () => {
    getDeactivatedClients({
      variables: {
        dashboardInput: {
          start_date: null,
          is_deactivate_complete_task: false,
        },
      },
    })
  }

  const getInitialApi = () => {
    getInitial({
      variables: {
        dashboardInput: {
          start_date: null,
          is_initial_report_complete_task: false,
        },
      },
    })
  }

  const getPastClientsApi = () => {
    getPastClients({
      variables: {
        dashboardInput: {
          is_past_appointment_complete_task: false,
          start_date: null,
        },
      },
    })
  }

  const getClientsApi = async () => {
    try {
      const tempData = await getCLients({
        variables: {
          createPlanInput: {},
        },
      })
      const { data, code, status } =
        tempData?.data?.getDeactivateActivateRequests
      if (code == 200 && status && data) {
        if (data?.request) {
          const activeClient = data?.request.filter(
            d => d.remarks === "for active"
          )
          const deactiveClient = data?.request.filter(
            d => d.remarks !== "for active"
          )
          console.log("data", deactiveClient);
          getNoData(deactiveClient)
          setCompletedTask(activeClient)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const submitClient = (id, type) => {
    submitMutation({
      variables: {
        createPlanInput: {
          id,
          remarks: "for " + type,
          request_type: "approval_status_update",
          session_status: type,
        },
      },
    }).then(res => {
        getClientsApi()
      })
      .catch(err => {
        console.error(err)
      })
  }

  const callApis = type => {
    if (type == "No-Show") {
      getNoshowApi()
    } else if (type == "Inactive-Clients") {
      getClientsApi()
    } else if (type == "Initial-Reports") {
      getInitialApi()
    } else if (type == "Past-Clients") {
      getPastClientsApi()
    }
  }

  useEffect(() => {
    callApis(type)
  }, [type])

  useEffect(() => {
    if (
      getNowShowResponse.data &&
      getNowShowResponse.data.ViewNoShowAppointments &&
      getNowShowResponse.data.ViewNoShowAppointments
    ) {
      getNoData(getNowShowResponse.data.ViewNoShowAppointments.data.groupedData)
      setCompletedTask(
        getNowShowResponse.data.ViewNoShowAppointments.data.completed_task_obj
      )
    }
  }, [getNowShowResponse])

  useEffect(() => {
    if (
      getDeactivatedClientsResponse.data &&
      getDeactivatedClientsResponse.data.ViewDeactivatedClients &&
      getDeactivatedClientsResponse.data.ViewDeactivatedClients
    ) {
      getNoData(
        getDeactivatedClientsResponse.data.ViewDeactivatedClients.data
          .groupedDataUsers
      )
      setCompletedTask(
        getDeactivatedClientsResponse.data.ViewDeactivatedClients.data
          .completed_task_obj_users
      )
    }
  }, [getDeactivatedClientsResponse])

  useEffect(() => {
    if (
      getInitialResponse.data &&
      getInitialResponse.data.ViewInitialReports &&
      getInitialResponse.data.ViewInitialReports
    ) {
      getNoData(getInitialResponse.data.ViewInitialReports.data.groupedData)
      setCompletedTask(
        getInitialResponse.data.ViewInitialReports.data.completed_task_obj
      )
    }
  }, [getInitialResponse])

  useEffect(() => {
    if (
      getPastClientsResponse.data &&
      getPastClientsResponse.data.ViewPastClients &&
      getPastClientsResponse.data.ViewPastClients
    ) {
      getNoData(getPastClientsResponse.data.ViewPastClients.data.groupedData)
      setCompletedTask(
        getPastClientsResponse.data.ViewPastClients.data.completed_task_obj
      )
    }
  }, [getPastClientsResponse])

  const handleCheck = (e, item, isChecked) => {
    let InputKey = ""
    let SubKey = ""
    if (type == "No-Show") {
      InputKey = "is_no_show_complete_task"
      SubKey = ""
    } else if (type == "Inactive-Clients") {
      InputKey = "is_deactivate_complete_task"
      SubKey = ""
    } else if (type == "Initial-Reports") {
      InputKey = "is_initial_report_complete_task"
      SubKey = ""
    } else if (type == "Past-Clients") {
      InputKey = "is_past_appointment_complete_task"
      SubKey = ""
    }

    if (InputKey == "is_deactivate_complete_task") {
      getDeactiveCompleted({
        variables: {
          updateUserInput: {
            id: item.id,
            [InputKey]: !isChecked,
          },
        },
      }).then(res => {
        callApis(type)
      })
    } else {
      getCompleted({
        variables: {
          updateAppointmentInput: {
            id: item.id,
            [InputKey]: !isChecked,
          },
        },
      }).then(res => {
        callApis(type)
      })
    }
  }

  useEffect(() => {
    if (type == "No-Show") {
      setSubTextContent(
        "No-Show are the clients who booked the appointment but didn't showed up."
      )
    } else if (type == "Inactive-Clients") {
      setSubTextContent(
        "Deactivated are the clients who are requested to deactivate by the counsellor."
      )
    } else if (type == "Initial-Reports") {
      setSubTextContent(
        "Initial report are the ICBC clients who didn't booked appointment within 14 days after the first session."
      )
    } else if (type == "Past-Clients") {
      setSubTextContent(
        "Past clients are the clients who booked appointments on the past date from acuity."
      )
    }
  }, [type])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-2">
            <i
              className="waves-effect mdi card-title-main mdi-arrow-left font-size-22"
              id="backArrow"
              onClick={() => history.push("/dashboard")}
            />
            <div className="mt-1">
              <h4 className="card-title-main">{type.replace("-", " ")}</h4>
              <h5 className="sub-title-menu card-para-main">
                {subTextContent}
              </h5>
            </div>
          </div>
        </div>

        {isLoader ? (
          <Row className="mg-top-default-ui">
            <Col className="col-12 user-role-create-height">
              <div className="spinner">
                <Loader />
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="mg-top-default-ui">
            {nodata.length === 0 && completedTask.length === 0 ? (
              <Col
                sm={12}
                className="d-flex align-items-stretch user-role-create-height"
              >
                <Card className="light-green-bg-card w-100 d-flex align-items-center justify-content-center">
                  No Data Found
                </Card>
              </Col>
            ) : (
              <>
                {nodata.length > 0 && type !== "Inactive-Clients" && (
                  <Col sm={6} xs={6}>
                    <Card className="light-green-bg-card">
                      <ul className="no-show">
                        {nodata.map((data, index) => {
                          return (
                            <>
                              <h4
                                className="card-title-main mt-4"
                                key={`first_${index}`}
                              >
                                {data.date_created}
                                {data.appointment_date_created}
                              </h4>
                              {data.data.map((item, index) => {
                                return (
                                  <li key={`second_${index}`}>
                                    <div className="name d-flex justify-content-between">
                                      <div>
                                        {item.first_name} {item.last_name} (
                                        {moment(item.date).format(
                                          "MMM DD, YYYY"
                                        )}
                                        )
                                      </div>
                                      <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={item.id}
                                        onClick={e =>
                                          handleCheck(e, item, false)
                                        }
                                        checked={false}
                                      />
                                    </div>
                                  </li>
                                )
                              })}
                            </>
                          )
                        })}
                      </ul>
                    </Card>
                  </Col>
                )}
                {completedTask && completedTask.length > 0 && type !== "Inactive-Clients" && (
                  <Col sm={6} xs={6} className="h-100">
                    <Card className="light-green-bg-card">
                      <ul className="no-show">
                        <h4 className="card-title mt-4">Completed Task</h4>
                        {completedTask.map((data, index) => {
                          return (
                            <>
                              {data.data.map((item, index) => {
                                return (
                                  <li key={`four_${index}`}>
                                    <div
                                      className="createdDate"
                                      key={`third_${index}`}
                                    >
                                      {data.date_created}
                                      {data.appointment_date_created}
                                    </div>
                                    <div className="name d-flex justify-content-between">
                                      <div>
                                        {item.first_name} {item.last_name} (
                                        {moment(item.date).format(
                                          "MMM DD, YYYY"
                                        )}
                                        )
                                      </div>
                                      <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={item.id}
                                        name={item.id}
                                        onClick={e =>
                                          handleCheck(e, item, true)
                                        }
                                        checked={true}
                                      />
                                    </div>
                                  </li>
                                )
                              })}
                            </>
                          )
                        })}
                      </ul>
                    </Card>
                  </Col>
                )}
                {type === "Inactive-Clients" &&
                  (nodata.length > 0 || completedTask.length > 0) && (
                    <>
                      <Col sm={6} xs={6} className="h-100">
                        <Card className="light-green-bg-card">
                          <ul className="no-show">
                            <h4 className="card-title mt-4">For Deactivate</h4>
                            <div className="createdDate">
                              {nodata.map((item, index) => (
                                <li key={`second_${index}`}>
                                  <div className="name d-flex justify-content-between">
                                    <div> {item?.plan?.client?.name}</div>
                                    <button
                                      className="btn btn-secondary"
                                      onClick={() => {
                                        submitClient(item?.plan?.id, "deactive")
                                      }}
                                    >
                                      Deactivate
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </div>
                          </ul>
                        </Card>
                      </Col>
                      <Col sm={6} xs={6} className="h-100">
                        <Card className="light-green-bg-card">
                          <ul className="no-show">
                            <h4 className="card-title mt-4">For Activate</h4>
                            <div className="createdDate">
                              {completedTask.map((item, index) => (
                                <li key={`second_${index}`}>
                                  <div className="name d-flex justify-content-between">
                                    <div>
                                      {" "}
                                      {item?.plan?.client?.name}{" "}
                                      ({item?.requestor?.name})
                                    </div>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        submitClient(item?.plan?.id, "active")
                                      }}
                                    >
                                      Activate
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </div>
                          </ul>
                        </Card>
                      </Col>
                    </>
                  )}
              </>
            )}
          </Row>
        )}
      </div>
    </div>
  )
}
export default DashboardDetails
