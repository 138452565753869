import React, { useState, useEffect } from "react"
import Table from "../../../../components/Common/Table"
import { GET_EXPANDED_DATA } from "../gql/gql"
import { useLazyQuery } from "@apollo/client"
import CheckboxField from "./CheckboxField"
import moment from "moment"
import { Input, FormGroup, Col } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Loader from "../../../../components/Common/Loader"
import {
  capitalizeFirstLetter,
  convertLocaltoUTC,
} from "../../../../functions/functions"
import PaymentType from "./PaymentType"
import {
  getClientAppointmentDetails,
  setClientSelectedAppointments,
} from "../../../../store/actions"
import ExportInvoice from "./ExportInvoice"

function ExpandedInvoiceRow(props) {
  const {
    parentRow,
    selectedClient,
    startDate,
    endDate,
    handleShowPreviewTab,
    setSelectedPreviewID,
  } = props
  const paymentSourceId = parentRow.id
  const clientAppointmentsData = useSelector(
    state => state.invoicingManagement.clientAppointments
  )
  const childData = clientAppointmentsData.find(n => n.id == parentRow.id)
  const selectedClientAppointments = useSelector(
    state => state.invoicingManagement.selectedClientAppointments
  )
  const currentObj = selectedClientAppointments.find(
    n => n.id == paymentSourceId
  )

  const dispatch = useDispatch()
  const [isSpinner, setIsSpinner] = useState(true)
  const [selectedId, setselectedId] = useState({})
  const [selectedList, setSelectedList] = useState([])

  const handlClickPreviewTab = row => {
    handleShowPreviewTab(true)
    setSelectedPreviewID({
      payment_id: parentRow.id,
      calendar_id: row.calendar_id,
      client_id: row.client_id,
      appointment_type_id: row.id,
      child: row,
      parent: parentRow,
    })
  }

  useEffect(() => {
    if (currentObj.selectedList.length > 0) {
      const arr = currentObj.selectedList
      const obj = {}
      arr.forEach(val => {
        obj[val] = true
      })
      setselectedId(obj)
      setSelectedList([...arr])
    } else {
      setselectedId({})
      setSelectedList([])
    }
  }, [currentObj.selectedList.length])

  //************************** appointment type list ***************************** */

  const [getAppointmentDateList, listResponse] = useLazyQuery(
    GET_EXPANDED_DATA,
    {
      fetchPolicy: "no-cache",
    }
  )

  function getAppointmentDateListFun() {
    getAppointmentDateList({
      variables: {
        argumentsInput: {
          payment_id: parseInt(parentRow.id),
          start_date: startDate
            ? convertLocaltoUTC(startDate, "YYYY-MM-DD HH:mm:ssZ", true)
            : null,
          end_date: endDate
            ? convertLocaltoUTC(endDate, "YYYY-MM-DD HH:mm:ssZ", false, true)
            : null,
          ...(selectedClient ? { user_id: parseInt(selectedClient) } : {}),
        },
      },
    })
  }

  useEffect(() => {
    getAppointmentDateListFun()
  }, [])

  useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointmentTypeWithClient &&
      listResponse.data.getAppointmentTypeWithClient.status &&
      listResponse.data.getAppointmentTypeWithClient.code === 200
    ) {
      setIsSpinner(false)
      const dispatchData = {
        id: parentRow.id,
        data: listResponse.data.getAppointmentTypeWithClient.data
          .paymentTypeData,
      }
      dispatch(getClientAppointmentDetails(dispatchData))
    } else if (
      listResponse.data &&
      listResponse.data.getAppointmentTypeWithClient &&
      listResponse.data.getAppointmentTypeWithClient.code !== 200
    ) {
      setIsSpinner(false)
      const dispatchData = {
        id: parentRow.id,
        data: [],
      }
      dispatch(getClientAppointmentDetails(dispatchData))
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  //************************** Selected Data ***************************** */

  const onSelectAppointment = (index, isSelected) => {
    setselectedId(prevSelectedRows => ({
      ...prevSelectedRows,
      [index]: isSelected,
    }))
  }

  useEffect(() => {
    const keys = Object.keys(selectedId)
    const filteredObj = keys.filter(key => {
      return selectedId[key]
    })
    const filteredArray = filteredObj.map(str => {
      return Number(str)
    })
    setSelectedList(filteredArray)
  }, [selectedId])

  useEffect(() => {
    if (!isSpinner) {
      dispatch(setClientSelectedAppointments(paymentSourceId, selectedList))
    }
  }, [selectedList])

  //************************** Table Data ***************************** */

  const expandColumns = [
    {
      text: "",
      dataField: "client_id",
      formatter: rowSelectedFun,
      style: {
        width: 20,
      },
    },
    {
      text: "",
      dataField: "name",
      formatter: appointmentTypeFun,
      style: {
        minWidth: 300,
      },
    },
    {
      text: "",
      dataField: "first_name",
      formatter: fullNameFun,
      style: {
        minWidth: 150,
      },
    },
    {
      text: " ",
      dataField: "payment_name",
      style: {
        minWidth: 300,
      },
      formatExtraData: { columnId: "payment_name" },
      formatter: paymentTypeFun,
    },
    {
      text: " ",
      dataField: "export",
      formatter: exportedDataFun,
    },
  ]

  function rowSelectedFun(value, row, index, field) {
    return (
      <CheckboxField
        rowValue={value}
        row={row}
        index={index}
        field={field}
        paymentSourceId={paymentSourceId}
        onSelectRow={onSelectAppointment}
      />
    )
  }

  function appointmentTypeFun(value, row, index, field) {
    return (
      <a
        onClick={() => handlClickPreviewTab(row)}
        className="pointer expanded-invoice-row-link"
      >{`(${row.appointmentcount}) ${value + " "} `}</a>
    )
  }

  function fullNameFun(value, row, index, field) {
    const firstName = row?.first_name ? row?.first_name : " "
    const lastName = row?.last_name ? row?.last_name : " "
    return (
      capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName)
    )
  }

  function paymentTypeFun(value, row, index, field) {
    return (
      <PaymentType
        rowValue={value}
        row={row}
        index={index}
        field={field}
        parentRow={parentRow}
      />
    )
  }

  function exportedDataFun(value, row, index, field) {
    return (
      <ExportInvoice
        rowValue={value}
        row={row}
        index={index}
        field={field}
        parentRow={parentRow}
        startDate={startDate}
        endDate={endDate}
      />
    )
  }

  return (
    <div className="expand-table">
      {isSpinner ? (
        <Col className="col-12">
          <div className="text-center">
            <Loader />
          </div>
        </Col>
      ) : (
        <Table
          data={childData.data}
          columns={expandColumns}
          noDataIndication={"No Data Found"}
          keyField="name"
          loading={false}
          isSearch={false}
          isStriped={false}
          isPagination={false}
          isBordereded={false}
          isHover={false}
        />
      )}
    </div>
  )
}

export default ExpandedInvoiceRow
