import React from 'react'
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, BreadcrumbItem } from "reactstrap"

function CounselorPayDetails() {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              Test page details
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment >
  )
}

export default CounselorPayDetails