import React, { useCallback, useEffect, useState } from "react"
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import { useHistory } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import DeleteModal from "../../components/Common/DeleteModal"
import edit from "../../assets/images/dashboard-icons/edit.svg"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import {
  GET_CALENDAR_ROOM,
  GET_ROOMS_BY_LOCATIONS,
  DELETE_ROOM,
} from "./gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { debounce } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { getLocationName } from "../../store/actions"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"


const Rooms = ({activeTab}) => {
  let { locationId } = useParams()
  const decodedLId = locationId && atob(locationId);
  locationId = decodedLId
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = decodedId
  const dispatch = useDispatch()
  const location = useLocation()
  const locationValue = new URLSearchParams(location.search).get("location")
  const locationName = useSelector(state => state.calendar.location)
  let history = useHistory()
  const [deleteModalId, setDeleteModalId] = useState(-1)
  const [inProgress, setInProgress] = useState(false)
  let [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    totalCount: 0,
  })
  const [searchText, setSearchText] = useState("")
  let [list, setList] = useState([])
  let [isSpinner, setIsSpinner] = useState(false)

  const openRooms = ind => {
    const encodedId = btoa(ind);

    if (id) {
      history.push(`/roomAvail/${encodedId}?calendar=${id}`)
    } else {
      history.push(`/roomAvailibity/${encodedId}?location=${locationId}`)
    }
  }

  const dynamicColumns = [
    {
      text: "#",
      dataField: "id",
      style: {
        minWidth: 100,
      },
    },
    {
      text: "Room name",
      dataField: "name",
      style: {
        maxWidth: 500,
      },
    },
    {
      text: "Action",
      dataField: "action",
      style: {
        minWidth: 150,
      },
      formatter: (cell, row) => (
        <div>
          <button
            className="btn btn-primary cursor-pointer"
            onClick={() => openRooms(row.id)}
          >
            <img src={edit} alt="Edit" />
          </button>
          {!id && (
            <a
              onClick={() => setDeleteModalId(Number(row.id))}
              className="px-3 text-danger delete-btn cursor-pointer"
            >
              <img src={deleteBin} alt="Delete" />
            </a>
          )}
        </div>
      ),
    },
  ]

  if (id) {
    dynamicColumns.splice(1, 0, {
      text: "Location",
      dataField: "location",
      style: {
        maxWidth: 500,
      },
    })
  }

  const [columns, setColumns] = useState(dynamicColumns)

  const [getRoomsByLocation, getRoomsByLocationList] = useLazyQuery(
    GET_ROOMS_BY_LOCATIONS,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [getCalendarRooms, getCalendarRoomsRes] = useLazyQuery(
    GET_CALENDAR_ROOM,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [deleteRoom, deleteRoomRes] = useMutation(DELETE_ROOM)

  const handleDelete = async id => {
    try {
      setInProgress(true)
      deleteRoom({
        variables: {
          deleteRoomId: id,
        },
      })
        .then(res => {
          toast.success(res.data.deleteRoom.message)
          setInProgress(false)
          setTimeout(() => {
            if (locationId) {
              setIsSpinner(true)
              getRoomsByLocation({
                variables: {
                  getCalendarRooms: {
                    location_id: parseInt(locationId),
                  },
                },
              })
            }
            if (id) {
              setIsSpinner(true)
              getCalendarRoomsFun()
            }
          }, 1000)
        })
        .catch(err => {
          toast.error("something went wrong !")
          setInProgress(false)
        })
    } catch (error) {
      setInProgress(false)
      toast.error("Something went wrong !")
    } finally {
      setInProgress(false)

      setDeleteModalId(-1)
    }
  }
  const getCalendarRoomsFun = () => {
    getCalendarRooms({
      variables: {
        getCalendarRooms: {
          calendar_id: parseInt(id),
        },
      },
    })
  }

  const handleAddRoom = () => {
    if (list.length < 20) {
      const encodedId = btoa(locationId);
      history.push(`/addRoom/${encodedId}?location=${locationValue}`)
    } else {
      toast.error(
        "You’ve reached the maximum limit to create the room for this location."
      )
    }
  }

  useEffect(() => {
    if (
      getCalendarRoomsRes?.data?.getCalendarRooms?.data &&
      getCalendarRoomsRes?.data?.getCalendarRooms?.data
        ?.roomavilabilitiesMultiple
    ) {
      const uniqueIds = new Set()
      const roomArray =
        getCalendarRoomsRes?.data?.getCalendarRooms?.data?.roomavilabilitiesMultiple
          .filter(item => {
            if (!uniqueIds.has(item.room.id)) {
              uniqueIds.add(item.room.id)
              return true
            }
            return false
          })
          .map(item => ({
            id: item.room.id,
            name: item.room.name,
            location: item.room.location.name,
          }))
      setList(roomArray)
      setIsSpinner(false)
    }
  }, [getCalendarRoomsRes.data])

  useEffect(() => {
    if (
      getRoomsByLocationList.data &&
      getRoomsByLocationList.data.getRoomsByLocation.data
    ) {
      const uniqueIds = new Set()
      const roomArray =
        getRoomsByLocationList.data.getRoomsByLocation.data.roomavilabilitiesMultiple
          .filter(item => {
            if (!uniqueIds.has(item.room.id)) {
              uniqueIds.add(item.room.id)
              return true
            }
            return false
          })
          .map(item => ({
            id: item.room.id,
            name: item.room.name,
            location: item.room.location.name,
          }))
          if(roomArray[0]?.location){
            dispatch(getLocationName(roomArray[0]?.location))
          }
      setList(roomArray)
      setIsSpinner(false)
    }
  }, [getRoomsByLocationList.data])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setIsSpinner(true)
    getCalendarRooms({
      variables: {
        getCalendarRooms: {
          calendar_id: parseInt(id),
          limit: sizePerPage,
          page: page,
          sort:"id"
        },
      },
    })
    // getAppoitmentType({
    //   variables: {
    //     argumentsInput: {
    //       limit: sizePerPage,
    //       page: page,
    //     },
    //   },
    // })
  }

  const handleSearch = e => {
    setIsSpinner(true)
    setState(p => ({
      ...p,
      page: 1,
    }))
    if (locationId) {
      getRoomsByLocation({
        variables: {
          getCalendarRooms: {
            location_id: parseInt(locationId),
            search: e || "",
          },
        },
      })
    }
    if (id) {
      getCalendarRooms({
        variables: {
          getCalendarRooms: {
            calendar_id: parseInt(id),
            search: e || "",
          },
        },
      })
    }
  }

  const delaySearch = useCallback(debounce(handleSearch, 1000), [])

  const handleSearchText = search => {
    setIsSpinner(true)
    setSearchText(search)
    delaySearch(search)
  }

  useEffect(() => {
    if (locationId) {
      setIsSpinner(true)
      getRoomsByLocation({
        variables: {
          getCalendarRooms: {
            location_id: parseInt(locationId),
          },
        },
      })
    }
    if (id) {
      setIsSpinner(true)
      getCalendarRoomsFun()
    }
  }, [locationId])

  return (
    <div className={`page-content ${activeTab ? "p-0" : ""}`}>
      <div className="container-fluid">
        {!activeTab && <div>
          <div className="flex align-item-center">
            <i
              className="waves-effect color-dark-green  mdi mdi-arrow-left font-size-22 mr-2"
              id="backArrow"
              onClick={() => history.goBack()}
            />
            <div className="px-4">
              <h4 className="card-title-main">Rooms</h4>
              <h5 className="card-para-main">List of all the Rooms.</h5>
            </div>
          </div>
        </div>}

        <Row className="mg-top-default-ui">
          <Col>
            <Card className="light-green-bg-card-table">
              <CardBody>
                {!id && <Row className="d-flex align-item-center justify-content-between mb-2 mt-4">
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="align-self-center"
                  >
                    <p className="h5 font-weight-bold">{locationName || locationValue }</p>
                  </Col>
                </Row>}
                <Row className="d-flex align-item-center justify-content-between mb-2 mt-4">
                  <Col
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    className="align-self-center"
                  >
                    <Input
                      value={searchText}
                      onChange={e => handleSearchText(e.target.value)}
                      placeholder="Search"
                      className="default-search-input"
                    />
                  </Col>
                  {locationId && (
                    <Col sm={12} md={9} lg={9} xl={6} className="text-end">
                      <button
                        className="btn btn-primary margin-top-10px-xs "
                        onClick={() => {
                          handleAddRoom()
                        }}
                      >
                        <i className="uil uil-plus "></i>
                        <span className="mx-3">Add Room</span>
                      </button>
                    </Col>
                  )}
                </Row>
                <div className="table-custom-redesign">
                  <Table
                    data={list}
                    page={state?.page}
                    sizePerPage={state?.sizePerPage}
                    totalSize={state?.totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    keyField={"id"}
                    isHover={false}
                    isStriped={false}
                    isBordereded={false}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <DeleteModal
          show={deleteModalId !== -1}
          onDeleteClick={() => {
            handleDelete(deleteModalId)
          }}
          onCloseClick={() => {
            setDeleteModalId(-1)
          }}
          inProgress={inProgress}
        />
      </div>
    </div>
  )
}

export default Rooms
