import React from "react"
import feedbackImage from "../../../assets/images/feedback-head.svg"
import style from "../feedback.module.scss"
import classNames from "classnames"

const FeedbackSubmitted = ({ handleBack }) => {
  const handleClick = () => {
    window.close()
    window.opener.location.reload()
  }

  return (
    <div>
      <div className="d-flex justify-content-center mg-top-default-ui">
        <div className={classNames("text-center")}>
          <img className={style.headImage} src={feedbackImage} alt="feedback" />
          <h1 className="card-title-main">Thank you!</h1>
          <p className={`card-para-main`}>
            Thank you for sharing you thoughts.
            <br />
            We appreciate your feedback!
          </p>
          <div className="mt-4 d-flex gap-1 justify-content-center session-type-mgtop-40">
            <button
              className={classNames(
                "mt-0 btn-lg btn-primary btn waves-effect waves-light text-center"
              )}
              onClick={handleClick}
            >
              Back To Home
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedbackSubmitted
