import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Table from "../../components/Common/Table"
import { capitalizeFirstLetter } from "../../functions/functions"
import moment from "moment"
import DatePicker from "react-datepicker"
import "react-date-picker/dist/DatePicker.css"
import "react-calendar/dist/Calendar.css"
import Select from "react-select"
import { GET_BAD_FIT_CLIENTS, UPDATE_BAD_FIT } from "./gql/userGql"
import { useLazyQuery, useMutation } from "@apollo/client"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Tooltip from "@material-ui/core/Tooltip"
import style from "../../../src/pages/Client/style.module.scss"
import PageNotFound from "../404"
import useStyles from "../Invoicing-Receipt-Management/Style"
import { useHistory } from "react-router-dom"
import Remarks from "./Remarks"

const Badfit = () => {
  const [isSpinner, setIsSpinner] = useState(false)
  const pageNum = parseInt(new URLSearchParams(location.search).get("page"))
  const startDt = new URLSearchParams(location.search).get("start")
  const storedStartDate = startDt ? new Date(startDt) : null
  const endDt = new URLSearchParams(location.search).get("end")
  const storedEndDate = endDt ? new Date(endDt) : null
  const defaultStartDate = moment()
    .subtract(2, "months")
    .subtract(1, "day")
    .format("YYYY-MM-DD")
  const defaultEndDate = moment().format("YYYY-MM-DD")
  const formattedDefaultEndDate = new Date(moment().format("YYYY-MM-DD"))
  const formattedDefaultStartDate = new Date(
    moment().subtract(2, "months").subtract(1, "day").format("YYYY-MM-DD")
  )
  const [state, setState] = useState({
    page: pageNum ? pageNum : 1,
    limit: 20,
    sort: "last_appointment_date",
    sort_dir: "DESC",
    search: "",
    startDate: null,
    endDate: null,
  })
  useEffect(() => {
    if (state.page) {
      currentUrl.searchParams.set("page", state.page)
      window.history.replaceState({}, document.title, currentUrl.href)
    }
  }, [state.page])
  const classes = useStyles()
  const [totalCount, setTotalCount] = useState(20)
  const [badFitData, setBadFitData] = useState([])
  const [goodFitModal, setGoodFitModal] = useState(false)
  const [goodFitModalDelete, setGoodFitDeleteModal] = useState(false)
  const [selectedReasone, setSelectedReasone] = useState(null)
  let currentUrl = new URL(window.location.href)
  let badfitReasone = new URLSearchParams(location.search).get("badfit")
  const [remarkId, setRemarkId] = useState(undefined)
  const [getBadfitClients, getBadfitClientsRes] = useLazyQuery(
    GET_BAD_FIT_CLIENTS,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [updateBadFit, updateBadFitRes] = useMutation(UPDATE_BAD_FIT)
  const history = useHistory()

  const badfitOptios = [
 
    {
      name: "First Cancellation, No Follow Up",
      label: (
        <span className="font-size-15">
          {" "}
          First Cancellation, No Follow Up{" "}
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={
              <p className="m-0 p-0 h6 text-white">
                First cancelled appointment occurred 7 days ago followed by no appointment
              </p>
            }
            arrow
          >
            <i
              className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
            ></i>
          </Tooltip>
        </span>
      ),
      value: "first_cancellation_no_follow_up",
    },
    {
      name: "First Session Booking but No Follow-Up",
      label: (
        <span>
          {" "}
          First Session Booking but No Follow-Up
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={
              <p className="m-0 p-0 h6 text-white">
                First appointment occurred 7 days ago followed by no appointment
              </p>
            }
            arrow
          >
            <i
              className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
            ></i>
          </Tooltip>
        </span>
      ),
      value: "first_session_booking_with_no_followup",
    },
    {
      name: "Min. Sessions, No Follow up",
      label: (
        <span>
          {" "}
          Min. Sessions, No Follow up
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={
              <p className="m-0 p-0 h6 text-white">
                Less than 6 appointments concluded 14 days prior followed by no appointment
              </p>
            }
            arrow
          >
            <i
              className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
            ></i>
          </Tooltip>
        </span>
      ),
      value: "less_than_six_sessions",
    },
  
    {
      name: "Recent Cancellation",
      label: (
        <span>
          {" "}
          Recent Cancellation{" "}
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={
              <p className="m-0 p-0 h6 text-white">
                Cancelled appointment occurred 14 days ago and have more than one appointment
              </p>
            }
            arrow
          >
            <i
              className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
            ></i>
          </Tooltip>
        </span>
      ),
      value: "recent_cancellation",
    },
  ]

  const getBadfitClientsFun = (
    page,
    limit,
    reasone,
    sort,
    sort_dir,
    search,
    startDate,
    endDate
  ) => {
    setIsSpinner(true)
    getBadfitClients({
      variables: {
        argumentsInput: {
          search: search || "",
          reason: reasone || null,
          start_date: startDate
            ? moment(startDate).toDate()
            : null,
          end_date: endDate ? moment(endDate).add(1, "days").toDate() : null,
          limit: limit || 20,
          page: page || pageNum || 1,
          sort: sort || "last_appointment_date",
          sort_dir: sort_dir || "DESC",
        },
      },
    })
  }

  useEffect(() => {
    if (pageNum) {
      setState(prevState => {
        return {
          ...prevState,
          page: pageNum,
        }
      })
    }
    setState(prevState => {
      return {
        ...prevState,
        startDate: storedStartDate ? storedStartDate : formattedDefaultStartDate,
        endDate: storedEndDate ? storedEndDate : formattedDefaultEndDate,
      }
    })
    if (!storedStartDate) {
      currentUrl.searchParams.set("start", defaultStartDate)
      window.history.replaceState({}, document.title, currentUrl.href)
    }
    if (!storedEndDate) {
      currentUrl.searchParams.set("end", defaultEndDate)
      window.history.replaceState({}, document.title, currentUrl.href)
    }
    if (badfitReasone) {
      getBadfitClientsFun(
        pageNum ? pageNum : state.page,
        state.limit,
        badfitReasone,
        state.sort,
        state.sort_dir,
        state.search,
        storedStartDate ? storedStartDate : formattedDefaultStartDate,
        storedEndDate ? storedEndDate : formattedDefaultEndDate
      )
      const defaultReason = badfitOptios.filter(elem => {
        return elem.value == badfitReasone
      })
      setSelectedReasone(defaultReason[0])
    } else {
      getBadfitClientsFun(
        state.page,
        state.limit,
        null,
        state.sort,
        state.sort_dir,
        state.search,
        storedStartDate ? storedStartDate : formattedDefaultStartDate,
        storedEndDate ? storedEndDate : formattedDefaultEndDate
      )
    }
  }, [])

  useEffect(() => {
    if (
      getBadfitClientsRes.data &&
      getBadfitClientsRes.data?.getBadFitClients?.data?.badFitUsers
    ) {
      const total = getBadfitClientsRes.data?.getBadFitClients?.data?.totalCount
      setTotalCount(total)
      const badfitArray =
        getBadfitClientsRes.data?.getBadFitClients?.data?.badFitUsers
      if (badfitArray) {
        setIsSpinner(false)
        setBadFitData(badfitArray)
      } else {
        setBadFitData([])
        setIsSpinner(false)
      }
    }
  }, [getBadfitClientsRes.data])

  function fullNameFun(value, row, index, field) {
    const firstName = row?.user?.first_name ? row?.user?.first_name : " "
    const lastName = row?.user?.last_name ? row?.user?.last_name : " "

    return (
      <span className="cursor-pointer">
        {capitalizeFirstLetter(firstName) +
          " " +
          capitalizeFirstLetter(lastName)}
      </span>
    )
  }
  function fullNameFun(value, row, index, field) {
    const firstName = row?.user?.first_name ? row?.user?.first_name : " "
    const lastName = row?.user?.last_name ? row?.user?.last_name : " "

    return (
      <span className="">
        {capitalizeFirstLetter(firstName) +
          " " +
          capitalizeFirstLetter(lastName)}
      </span>
    )
  }
  const openAppointments = row => {
    let sessionParams = sessionStorage.getItem("appointmentParams")
    const firstName = row?.user?.first_name ? row?.user?.first_name : ""
    const lastName = row?.user?.last_name ? row?.user?.last_name : ""
    const fullName = `${firstName} ${lastName}`
    if (sessionParams) {
      let paramsObject = JSON.parse(sessionParams)
      paramsObject.search = `${fullName}`
      paramsObject.startDate = undefined
      paramsObject.endDate = undefined
      let updatedSessionParams = JSON.stringify(paramsObject)
      sessionStorage.setItem("appointmentParams", updatedSessionParams)
    } else {
      const newData = {
        page: 1,
        sizePerPage: 20,
        sortBy: "utc_datetime",
        search: "",
        message: "",
        startDate: "",
        endDate: "",
      }
      newData.search = fullName
      let updatedSessionParams = JSON.stringify(newData)
      sessionStorage.setItem("appointmentParams", updatedSessionParams)
    }
    if (row?.calendar?.id) {
      history.push(
        `appointments?tab=list&counsellor=${row?.calendar?.id}&client=${fullName}`
      )
    }
  }
  function counsellorFullNameFun(value, row, index, field) {
    const firstName = row?.calendar?.user?.first_name
      ? row?.calendar?.user?.first_name
      : " "
    const lastName = row?.calendar?.user?.last_name
      ? row?.calendar?.user?.last_name
      : " "

    return (
      <span className="cursor-pointer" onClick={() => openAppointments(row)}>
        {capitalizeFirstLetter(firstName) +
          " " +
          capitalizeFirstLetter(lastName)}
      </span>
    )
  }
  const addGoodFit = data => {
    setGoodFitModal(data)
  }
  const addGoodFitFun = data => {
    updateBadFit({
      variables: {
        argumentsInput: {
          badfit_Ids: [data.id],
          is_good_fit: true,
        },
      },
    })
  }
  const deleteBadfit = data => {
    setGoodFitDeleteModal(data)
  }
  const deleteBadfitFun = data => {
    updateBadFit({
      variables: {
        argumentsInput: {
          badfit_Ids: [data.id],
          is_deleted: true,
        },
      },
    })
  }
  function actionEvent(value, row, index, field) {
    return (
      <>
        <div>
          <button className="btn btn-Good-fit" onClick={() => addGoodFit(row)}>
            Good Fit <i className="uil uil-thumbs-up"></i>
          </button>
          <a className=" delete-btn cursor-pointer counsellorDeleteIcon">
          <span className="mx-1 mdi mdi-trash-can-outline font-size-20" onClick={() => deleteBadfit(row)}></span>
            {/* <img src={deleteBin}  /> */}
          </a>
        </div>
      </>
    )
  }

  const handleCheck = (e, data) => {
    updateBadFit({
      variables: {
        argumentsInput: {
          badfit_Ids: [data.id],
          is_grey: e.target.checked,
        },
      },
    })
  }
  useEffect(() => {
    if (
      updateBadFitRes.data?.updateBadFit?.status &&
      updateBadFitRes.data?.updateBadFit?.code === 200
    ) {
      getBadfitClientsFun(
        state.page,
        state.limit,
        selectedReasone?.value,
        state.sort,
        state.sort_dir,
        state.search,
        state.startDate,
        state.endDate
      )
      toast.success(updateBadFitRes.data?.updateBadFit?.message)
      setGoodFitModal(undefined)
      setGoodFitDeleteModal(undefined)
    }
  }, [updateBadFitRes.data])

  function checkIsGrey(value, row, index, field) {
    return (
      <FormGroup check className="direct-client-list-blanket-cursor-txt">
        <Input
          id="exampleCheck"
          name="check"
          type="checkbox"
          style={{ cursor: "pointer" }}
          className="direct-client-list-blanket-cursor-txt"
          checked={row?.is_grey}
          onClick={(e) => {
          e.stopPropagation(); 
        }}
          onChange={e => {
            handleCheck(e, row)
          }}
        />
      </FormGroup>
    )
  }

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="m-0">{rowIndex + 1 + state.limit * (state.page - 1)}</p>
      </>
    )
  }
  function badFitReasons(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        {row?.bad_fit_reason === "recent_cancellation" && (
          <p className="m-0">Recent Cancellation</p>
        )}
        {row?.bad_fit_reason === "less_than_six_sessions" && (
          <p className="m-0">Min. Sessions no Follow up</p>
        )}
        {row?.bad_fit_reason === "first_session_booking_with_no_followup" && (
          <p className="m-0">First Session Booking but No Follow-Up</p>
        )}
        {row?.bad_fit_reason === "first_cancellation_no_follow_up" && (
          <p className="m-0">First Cancellation No Follow Up</p>
        )}
      </>
    )
  }
  const [expanded, setExpanded] = useState([0, 1])
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (expanded.length > 0) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [expanded])
  const handleBtnClick = () => {
    if (!expanded.includes(2)) {
      setExpanded([...expanded, 2])
    } else {
      setExpanded(expanded.filter(x => x !== 2))
    }
  }
  const handleOnExpand = useCallback(
    (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        setExpanded([...expanded, row.id])
      } else {
        setExpanded(expanded.filter(x => x !== row.id))
      }
    },
    [expanded]
  )

  const rowStyle = (row, rowIndex) => {
    const style = {}
    if (row.is_grey) {
      style.backgroundColor = "#ccc"
    } else {
      style.backgroundColor = ""
    }

    return style
  }

  const expandRow = {
    renderer: row => (
      <Row
        className={`slide-in  ${isActive ? "active border border-dark" : ""}`}
      >
        <Col lg={12} className="remark_container">
          <Remarks
            username={row?.user?.id}
            getBadfitClientsFun={getBadfitClientsFun}
            badfitState={state}
            selectedReasone={selectedReasone}
            startDate={state.startDate}
            endDate={state.endDate}
          />
        </Col>
      </Row>
    ),
    expanded: expanded,
    onExpand: handleOnExpand,
    expandByColumnOnly: true,
    showExpandColumn: true,
    expandColumnPosition: "right",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <span></span>
      }
      return <span></span>
    },
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded) {
        return (
          <div className={classes.btnAlign}>
            <button type="button" className="btn btn-sm btn-primary">
              <i className="uil-angle-up"></i>
            </button>
          </div>
        )
      }
      return (
        <div className={classes.btnAlign}>
          <button type="button" className="btn btn-sm btn-primary">
            <i className="uil-angle-down"></i>
          </button>
        </div>
      )
    },
  }
  function remarksEvent(value, row, index, field) {
    const remarkList = row?.user?.remarks
    const remarkDateString = row?.user?.remarks[0]
    const localDate = moment.utc(remarkDateString?.created_at).local()
    const relativeTime = localDate.fromNow()
    return (
      <>
        {remarkList && remarkList.length > 0 && (
          <div className="d-flex flex-column">
            <span className="m-0 p-0">
              {remarkList[0]?.description.substring(0, 80)}
              {remarkList[0]?.description.length >= 80 && "..."}
            </span>
            <small className="font-size-14">{relativeTime}</small>
          </div>
        )}
      </>
    )
  }

  const viewRemarks = userId => {
    if (userId) {
      setRemarkId(userId)
    } else {
      setRemarkId(undefined)
      getBadfitClientsFun(
        state.page,
        state.limit,
        selectedReasone?.value,
        state.sort,
        state.sort_dir,
        state.search,
        state.startDate,
        state.endDate
      )
    }
  }
  const viewRemarkBtn = (value, row, index, field) => {
    return (
      <>
        <Button
          className="btn btn-primary"
          onClick={() => viewRemarks(row?.user)}
        >
          View <i className='uil uil-pen '></i>
        </Button>
      </>
    )
  }

  const columns = [
    {
      text: "",
      dataField: "is_grey",
      formatter: checkIsGrey,
    },
    
    {
      text: "#",
      dataField: "id",
      headerStyle: {
        cursor: "pointer",
      },
      formatter: idFormatFun,
    },
    {
      text: (
        <span>
          Date
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={<>Last Session Date</>}
            arrow
          >
            <i
              className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
            ></i>
          </Tooltip>
        </span>
      ),
      dataField: "last_appointment_date",
      headerStyle: {
        cursor: "pointer",
      },
      sort: true,
      formatter: (value, row, rowIndex, formatExtraData) => {
        return <p className="m-0">{moment(value).format("DD-MMM-yyyy")}</p>
      },
    },
    {
      text: "Client Name",
      dataField: "client",
      formatter: fullNameFun,
      style: (cell, row, rowIndex) => ({
        maxWidth: 100,
      }),
      headerStyle: {
        cursor: "pointer",
      },
      sort: true,
    },
    {
      text: "Counsellor Name",
      dataField: "counsellor",
      sort: true,
      formatter: counsellorFullNameFun,
      style: (cell, row, rowIndex) => ({
        maxWidth: 100,

      }),
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      text: "Bad Fit Reason",
      dataField: "bad_fit_reason",
      style: (cell, row, rowIndex) => ({
        maxWidth: 150
      }),
      formatter: badFitReasons,
    },
   
   
    // {
    //   text: "Remarks",
    //   dataField: "remarks",
    //   formatter: remarksEvent,
    //   style: (cell, row, rowIndex) => ({
    //     maxWidth: 250,
    //   }),
    // },
    
    {
      text: "Notes",
      dataField: "remarksbtn",
      formatter: viewRemarkBtn,
    },
    {
      text: "Action",
      dataField: "action",
      formatter: actionEvent,
      headerStyle: {
        cursor: "pointer",
      },
      style: (cell, row, rowIndex) => ({
        maxWidth: 300,
      }),
    },
  ]
  const handleSearch = e => {
    const searchValue = e.target.value
    setState(prevState => {
      return {
        ...prevState,
        search: searchValue,
        page: 1,
      }
    })
  }

  const onChangeSearch = e => {
    handleSearch(e)
    getBadfitClientsFun(
      state.page,
      state.limit,
      badfitReasone,
      state.sort,
      state.sort_dir,
      e.target.value,
      state.startDate,
      state.endDate
    )
  }
  const changeDate = (type, value) => {
    if (type == "start") {
      setState(prevState => {
        return {
          ...prevState,
          startDate: value,
        }
      })
    }
    if (type == "end") {
      setState(prevState => {
        return {
          ...prevState,
          endDate: value,
        }
      })
    }
  }

  const handleSearchClick = () => {
    getBadfitClientsFun(
      state.page,
      state.limit,
      selectedReasone?.value,
      state.sort,
      state.sort_dir,
      state.search,
      state.startDate,
      state.endDate
    )
    currentUrl.searchParams.set("start", moment(state.startDate).format("YYYY-MM-DD"))
    window.history.replaceState({}, document.title, currentUrl.href)
    currentUrl.searchParams.set(
      "end",
      moment(state.endDate).format("YYYY-MM-DD")
    )
    window.history.replaceState({}, document.title, currentUrl.href)
  }

  const handleClear = () => {
    setSelectedReasone(null)
    setState({
      page: 1,
      limit: 20,
      sort: "last_appointment_date",
      sort_dir: "DESC",
      search: "",
      startDate: formattedDefaultStartDate,
      endDate: formattedDefaultEndDate,
    })

    currentUrl.searchParams.delete("badfit")
    window.history.replaceState({}, document.title, currentUrl.href)
    currentUrl.searchParams.set("start", defaultStartDate)
    window.history.replaceState({}, document.title, currentUrl.href)
    currentUrl.searchParams.set("end", defaultEndDate)
    window.history.replaceState({}, document.title, currentUrl.href)
    getBadfitClientsFun(
      1,
      20,
      null,
      "last_appointment_date",
      "DESC",
      "",
      formattedDefaultStartDate,
      formattedDefaultEndDate
    )
  }

  const handleFilterChange = val => {
    setSelectedReasone(val)
    getBadfitClientsFun(
      1,
      20,
      val.value,
      state.sort,
      state.sort_dir,
      state.search,
      state.startDate,
      state.endDate
    )
    currentUrl.searchParams.set("badfit", val.value)
    currentUrl.searchParams.set("page", 1)
    setState(prevState => {
      return {
        ...prevState,
        page: 1,
      }
    })
    window.history.replaceState({}, document.title, currentUrl.href)
  }

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setState(prevState => {
      return {
        ...prevState,
        page: page,
        limit: sizePerPage,
        sort: sortField ? sortField : "last_appointment_date",
        sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
      }
    })
    getBadfitClientsFun(
      page,
      sizePerPage,
      selectedReasone?.value,
      sortField ? sortField : state.sort,
      sortOrder ? sortOrder?.toUpperCase() : state.sort_dir,
      state.search,
      state.startDate,
      state.endDate
    )
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  const userData = localStorage.getItem("userPermission")
  let userPermission = userData ? JSON.parse(userData) : []
  let hasPermission = userPermission.includes("bad-fit")
  return !hasPermission ? (
    <PageNotFound />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid"></div>
        <Row className="justify-content-between">
          <Col className=" " xl={6}>
            <div>
              <div className="py-3">
                <h4 className="card-title-main">Bad Fit List</h4>
                <h5 className="card-para-main">List of all clients.</h5>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mg-top-default-ui">
          <Col className="col-12">
            <Card className="light-green-bg-card-table">
              <Row className="mt-3 ms-3">
                <Col md={3} lg={3} xl={3} className={`${classes.selectFilter} m-0 `}>
                  <Select
                    className=" default-search-input p-0"
                    options={badfitOptios}
                    placeholder="Select Bad Fit Filter"
                    value={selectedReasone}
                    onChange={value => handleFilterChange(value)}
                  />
                </Col>
                <Col md={3} lg={3} xl={3}  className="m-0">
                  <input
                    className="form-control default-search-input"
                    value={state.search}
                    type="text"
                    placeholder="Search Client Name"
                    onChange={onChangeSearch}
                  />
                </Col>

                <Col md={3} lg={3} xl={2}    className="m-0">
                  <DatePicker
                    selected={state.startDate}
                    onChange={value => changeDate("start", value)}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="DD-MM-YYYY"
                    className={`form-control pointer`}
                    maxDate={
                      state.endDate ? state.endDate : formattedDefaultEndDate
                    }
                  />
                </Col>

                <Col md={3} lg={3} xl={2}   className="m-0">
                  <DatePicker
                    selected={state.endDate}
                    onChange={value => changeDate("end", value)}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="DD-MM-YYYY"
                    className={`form-control pointer`}
                    minDate={
                      state.startDate ? state.startDate : formattedDefaultStartDate
                    }
                  />
                </Col>
                <Col md={2} className="m-0  mt-md-3 mt-lg-0 mt-xl-0 mt-xxl-0">
                  <div className="m-0 p-0 d-flex align-items-center justify-content-start">
                    <Button
                      type="button"
                      className="btn btn-primary text-capitalize p-2"
                      onClick={handleSearchClick}
                    >
                      Search
                    </Button>
                    <button
                      type="button"
                      className="btn-close-extra-toolkit mx-2"
                      onClick={handleClear}
                    >
                      <i className="mdi mdi-close  btn-close-extra-toolkit-icon"></i>
                    </button>
                  </div>
                </Col>
              </Row>

              <CardBody className="table-custom-redesign">
                <Table
                  data={badFitData}
                  page={state.page}
                  sizePerPage={state.limit}
                  totalSize={totalCount}
                  onTableChange={handleTableChange}
                  columns={columns}
                  noDataIndication={"No Data Found"}
                  loading={isSpinner}
                  keyField={"id"}
                  isHover={false}
                  isStriped={false}
                  isBordereded={false}
                  rowStyle={rowStyle}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={goodFitModal}
          toggle={() => {
            setGoodFitModal(undefined)
          }}
          centered={true}
        >
          <div className="modal-body">
            <Row>
              <Col className="col-12">
                <div className="d-flex align-items-center flex-column">
                  <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                  <h3>Are you sure?</h3>
                  <h5>{"You won't be able to revert this!"}</h5>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <button
                    type="button"
                    onClick={() => {
                      setGoodFitModal(undefined)
                    }}
                    className="btn btn-inactive waves-effect m-2"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => addGoodFitFun(goodFitModal)}
                  >
                    Yes
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          isOpen={goodFitModalDelete}
          toggle={() => {
            setGoodFitDeleteModal(undefined)
          }}
          centered={true}
        >
          <div className="modal-body">
            <Row>
              <Col className="col-12">
                <div className="d-flex align-items-center flex-column">
                  <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                  <h3>Are you sure?</h3>
                  <h5>{"You won't be able to revert this!"}</h5>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <Button
                    type="button"
                    onClick={() => {
                      setGoodFitDeleteModal(undefined)
                    }}
                    className="btn btn-inactive waves-effect m-2"
                    data-dismiss="modal"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => deleteBadfitFun(goodFitModalDelete)}
                  >
                    Yes, Delete it
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal isOpen={remarkId} centered={true} size={"lg"}>
          <ModalHeader toggle={() => viewRemarks()} tag="h4">
            <span className="mx-2 font-weight-bold d-flex align-items-center">
              <i className="mdi mdi-account mx-2 icon-default-light-bg-primary-green"></i>

              <>
                {remarkId?.first_name} {remarkId?.last_name} - Notes
              </>
            </span>
          </ModalHeader>
          <ModalBody className="m-0 p-0 px-3">
            <Row className={`slide-in  ${isActive ? "active " : ""}`}>
              <Col lg={12} className="remark_container">
                <div className="d-flex px-2 mt-3">
                  <span className="font-weight-bold d-flex align-items-center">
                    <i className="mdi mdi-email font-size-18 m-0  mx-2 icon-default-light-bg-primary-green"></i>
                    {remarkId?.email}
                  </span>
                  {remarkId?.mobile_number && (
                    <span className="mx-2 font-weight-bold d-flex align-items-center">
                      <i className="mdi mdi-phone font-size-18   m-0 mx-2 icon-default-light-bg-primary-green"></i>
                      {remarkId?.mobile_number}
                    </span>
                  )}
                </div>
                <Remarks
                  username={remarkId?.id}
                  getBadfitClientsFun={true}
                  startDate={state.startDate}
                  endDate={state.endDate}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Badfit
