import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  ORG_DETAILS
} from "./actionTypes"

export const loginUser = (user, history,nexturl) => {
  return {
    type: LOGIN_USER,
    payload: { user, history ,nexturl},
  }
}

export const orgDetails = (status) => {
  return {
    type : ORG_DETAILS,
    payload: status,
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

 