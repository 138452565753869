import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_LOCATION, SUBMIT_LOCATION } from "./gql/Gql"
import * as Yup from "yup"
import { useFormik } from "formik"
import { validate } from "graphql"
import Loader from "../../components/Common/Loader"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import { fetchApi } from "../../common/api"
import style from "./style.module.scss"

const AddLocations = () => {
  let history = useHistory()
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [subLocation, setSubLocation] = useState({})
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = decodedId;
  const [getLocation, getLocationList] = useLazyQuery(GET_LOCATION, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(SUBMIT_LOCATION)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoader, setIsLoader] = useState(true)
  const [initialValues, setInitialValues] = useState({
    name: "",
    status: false,
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Please enter name"),
    }),
    onSubmit: values => {
      setIsSubmit(true)
      const location_ids =
        values.counselling_locations &&
        values.counselling_locations.map(s => s.id)
      let obj = {
        name: values.name,
        status: values.status ? "active" : "inactive",
        location_ids: location_ids || [],
      }
      if (id) {
        obj.id = parseInt(id)
      }
      submitMutation({
        variables: {
          createAndUpdateLocationGroupInput: obj,
        },
      })
        .then(res => {
          toast.success(`Area successfully ${id ? "updated" : "created"}`)
          setTimeout(() => {
            if (id) {
              setIsSubmit(false)
            }else{
              history.push("/areas")
            }
          }, 1500)
        })
        .catch(err => {
          toast.error("something went wrong !")
          setIsSubmit(false)
        })
    },
  })
  useEffect(() => {
    if (id) {
      setIsLoader(true)
      getLocation({
        variables: {
          getLocationGroupInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [id])
  useEffect(() => {
    if (getLocationList.data && getLocationList.data?.getLocationGroup?.name) {
      setInitialValues({
        name: getLocationList.data.getLocationGroup.name,
        status:
          getLocationList.data.getLocationGroup.status == "active"
            ? true
            : false,
        counselling_locations:
          getLocationList.data.getLocationGroup.subLocation || [],
      })
      settoggleSwitch(
        getLocationList.data.getLocationGroup.status == "active" ? true : false
      )
      setIsLoader(false)
    } else {
      setIsLoader(false)
    }
  }, [getLocationList.data])

  const handleToggle = e => {
    setInitialValues(prev => {
      return { ...validation.values, status: !validation.values.status }
    })
  }

  const getSubLocationApi = async search => {
    try {
      let { data, code, status } = await fetchApi(
        `locationGroup/locations`,
        "GET"
      )
      if (code == 200 && status && data) {
        const { limit, page, totalCount } = data
        const locationGroup = data?.locationGroup?.map((data, i) => ({
          ...data,
          index: ((page || 1) - 1) * (limit || 1) + i + 1,
          value: data.id,
          label: data.name,
        }))
        setSubLocation(locationGroup)
      } else {
        setIsSpinner(false)
      }
    } catch (error) {
      console.log(error)
      setIsSpinner(false)
    }
  }
  useEffect(() => {
    getSubLocationApi()
  }, [])

  return (
    <div className="page-content">
      {isLoader ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div>
              <div className="py-3">
                <div className="flex align-item-center">
                  <i
                    className="waves-effect mdi color-dark-green mdi-arrow-left font-size-22 mr-2"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className="mt-1 mx-2 card-title-main">
                    {" "}
                    {id ? "" : "Create New "} Area
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={6}>
              <Card className="transparent-bg-card">
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="form-custom-white-inputs">
                      <Col xl={12} md={12}>
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && (
                          <div className={style.warnText}>
                            {validation.errors.name}
                          </div>
                        )}
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label" htmlFor="customSwitch2">
                          Locations
                        </Label>
                        <CustomMultiSelect
                          className="input"
                          onChange={value =>
                            validation.setFieldValue(
                              "counselling_locations",
                              value
                            )
                          }
                          placeholder="Select Locations"
                          value={validation.values?.counselling_locations}
                          options={subLocation}
                          isCloseMenuOnSelect={false}
                          isMulti
                          // onBlur={() => {
                          //   validation.setFieldTouched(
                          //     "counselling_locations",
                          //     true
                          //   )
                          // }}
                        />
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label" htmlFor="customSwitch2">
                          Publish
                        </Label>
                        <div className="form-check form-switch">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch2"
                            checked={validation.values.status}
                            onClick={e => handleToggle(e)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col xs={12} className="text-end">
                        <button
                          className="btn btn-inactive mx-2"
                          type="button"
                          // disabled={isSubmitted}
                          onClick={() => {
                            history.push("/areas")
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary "
                          type="submit"
                          disabled={isSubmit}
                        >
                          {id ? "Update" : "Add"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
export default AddLocations
