import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const GeneralNoteModal = ({
  generalNoteShow,
  openGeneralModal,
  remark,
  setRemark,
  handleAddUserRemark,
}) => {
  return (
    <>
      <Modal isOpen={generalNoteShow} size="md" centered={true}>
        <ModalHeader toggle={() => openGeneralModal()} tag="h4">
          Add general note
        </ModalHeader>
        <ModalBody>
          <div className="">
            <textarea
              className="w-100 form-control mb-3"
              rows={10}
              placeholder="Type remark"
              value={remark}
              onChange={e => {
                setRemark(e.target.value)
              }}
            />
            <button
              className="btn btn-primary w-sm waves-effect waves-light"
              onClick={() => handleAddUserRemark()}
              type="button"
              disabled={!Boolean(remark)}
            >
              Add Note
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default GeneralNoteModal
