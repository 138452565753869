import { gql } from "@apollo/client"
export const GET_CONCERN = gql`
  query GetConcern($createConcernInput: createConcernInput!) {
    getConcern(createConcernInput: $createConcernInput) {
      article
      created_at
      id
      updated_at
      url
      type_of_concern
      status
      slug
      name
    }
  }
`
export const SUBMIT_CONCERN = gql`
  mutation CreateAndUpdateConcern($createConcernInput: createConcernInput!) {
    createAndUpdateConcern(createConcernInput: $createConcernInput) {
      article
      created_at
      id
      updated_at
      url
      type_of_concern
      status
      name
    }
  }
`
export const DEL_CONCERN = gql`
  mutation RemoveConcern($createConcernInput: createConcernInput!) {
    removeConcern(createConcernInput: $createConcernInput) {
      name
    }
  }
`
