import { gql } from "@apollo/client"

export const GET_SESSION_TYPE = gql`
query GetSessionTypes {
    getSessionTypes {
      data {
        sessionTypes {
          slug
          id
          status
        }
      }
      status
      message
      code
    }
  }
`

export const GET_QUESTIONS = gql`
query GetQuestions($getQuestions: FeedbackInput!) {
  getQuestions(getQuestions: $getQuestions) {
    code
    message
    status
    data {
      ratingQuestions {
        description
        id
         question_type {
          slug
          id
        }
      }
      otherQuestions {
        description
        id
         question_type {
          slug
          id
        }
      }
      feedback {
        id
      }
    }
  }
}
`

export const SUBMIT_FEEDBACK_FORM = gql`
mutation FillFeedbackValues($fillFeedbackValues: FeedbackInput!) {
  fillFeedbackValues(fillFeedbackValues: $fillFeedbackValues) {
    code
    message
  }
}
`

export const GET_FEEDBACK = gql`
query ViewFeedback($viewFeedback: FeedbackInput!) {
  viewFeedback(viewFeedback: $viewFeedback) {
    data {
      client_feedback {
          appointment_has_feedback {
          id
          feedback_has_data {
            id
            question {
              id
              description
              question_type {
                slug
              }
              role {
                id
                slug
              }
              question_has_value {
                value
              }
            }
          }
          from {
            id
            name
            first_name
            last_name
            
            role {
              id
            }
            calendar {
              user {
                role {
                  id
                }
              }
            }
          }
          to {
            id
            name
            role {
              id
            }
            calendar {
              id
            }
          
            first_name
            last_name
          }
        }
      }
      counsellor_feedback {
        appointment_has_feedback {
          id
          feedback_has_data {
            id
            question {
              id
              description
              question_type {
                slug
              }
              role {
                id
                slug
              }
              question_has_value {
                value
              }
            }
          }
          from {
            id
            name
            first_name
            last_name
            role {
              id
            }
            calendar {
              user {
                role {
                  id
                }
              }
            }
          }
          to {
            id
            name
            role {
              id
            }
            calendar {
              id
            }
            first_name
            last_name
          }
        }
      }
      appointment {
        id
        is_feedback_submitted
      }
    }
    status
    code
    message
  }
}
`
