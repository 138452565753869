import React, { useEffect, useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import style from "./style.module.scss"
import {
  AddUserRemarks,
  GetUserRemarks,
  RemoveUserRemarks,
} from "./gql/userGql"
import DeleteModal from "../../components/Common/DeleteModal"
import { useLazyQuery, useMutation } from "@apollo/client"
import moment from "moment"
import "react-toastify/dist/ReactToastify.css"
import { toast } from "react-toastify"
import Loader from "../../components/Common/Loader"
import GeneralNoteModal from "./GeneralNoteModal"
import SessionNotesModal from "./SessionNotesModal"

const Remarks = ({ username, startDate, endDate, user }) => {
  const [remark, setRemark] = useState()
  const [addUserRemark] = useMutation(AddUserRemarks)
  const [sessionEditInfo, setSessionEditInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const handleAddUserRemark = () => {
    addUserRemark({
      variables: {
        remark: {
          description: remark,
          user_id: +username,
        },
      },
      onCompleted({ addUserRemark }) {
        handleRetriveUserRemarks()
        openGeneralModal()
        setRemark("")
        toast.success(addUserRemark?.message)
        setAccOpen()
        setsessionAcc()
        setIntakeAcc()
      },
    })
  }

  // Remove user remark

  const [remarkDeleteModal, setRemarkDeleteModal] = useState({
    open: false,
    id: undefined,
  })

  const [removeUserRemark, removeUserRemarkRes] = useMutation(RemoveUserRemarks)

  const handleRemoveRemark = () => {
    removeUserRemark({
      variables: {
        remarkInput: {
          id: remarkDeleteModal?.id,
        },
      },
      onCompleted({ removeRemark }) {
        handleRetriveUserRemarks()
        toast.success(removeRemark?.message)
        setRemarkDeleteModal({
          ...remarkDeleteModal,
          open: false,
          id: undefined,
        })
      },
    })
  }

  const [getUserRemarks] = useLazyQuery(GetUserRemarks, {
    fetchPolicy: "no-cache",
  })

  const [remarkList, setRemarkList] = useState([])
  const [additionalInformation, setAdditionalInformation] = useState([])
  const [intakeInformation, setIntakeInformation] = useState([])
  const handleRetriveUserRemarks = () => {
    const startDt = moment(startDate).add(1, "day")
    const formattedStartDate = startDt.format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
    const endDt = moment(endDate)
    const formattedEndDate = endDt.format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")

    setIsLoading(true)
    getUserRemarks({
      variables: {
        argumentsInput: {
          id: +username,
          start_date: startDate ? formattedStartDate : undefined,
          end_date: endDate ? formattedEndDate : undefined,
        },
      },
      onCompleted({ getUserRemarks }) {
        setIsLoading(false)
        if (getUserRemarks?.data?.user?.appointments.length > 0) {
          setAdditionalInformation(getUserRemarks?.data?.user?.appointments)
          setIntakeInformation(getUserRemarks?.data?.user?.appointments)
        } else {
          setAdditionalInformation([])
        }
        if (getUserRemarks?.data?.user?.remarks.length > 0) {
          setRemarkList(getUserRemarks?.data?.user?.remarks)
        } else {
          setRemarkList([])
        }
      },
    })
  }

  React.useEffect(() => {
    handleRetriveUserRemarks()
  }, [])

  const [activeTab, setActiveTab] = useState("remarks")

  const toggleTab = tab => {
    setActiveTab(tab)
  }

  const [noteOptionShow, setNoteOptionShow] = useState(false)
  const [generalNoteShow, setGeneralNoteShow] = useState(false)
  const [sessionNoteShow, setSessionNoteShow] = useState(false)
  const showNoteOption = () => {
    setNoteOptionShow(!noteOptionShow)
  }

  const openGeneralModal = () => {
    setNoteOptionShow(false)
    setGeneralNoteShow(!generalNoteShow)
  }
  const openSessionModal = () => {
    setNoteOptionShow(false)
    setSessionNoteShow(!sessionNoteShow)
    setSessionEditInfo(null)
  }

  const [accOpen, setAccOpen] = useState()
  const toggleAccordian = id => {
    if (accOpen === id) {
      setAccOpen()
    } else {
      setAccOpen(id)
    }
  }
  const [sessionAcc, setsessionAcc] = useState()
  const toggleSessionAccordian = id => {
    if (sessionAcc === id) {
      setsessionAcc()
    } else {
      setsessionAcc(id)
    }
  }
  const [intakeAcc, setIntakeAcc] = useState()
  const toggleIntakeAccordian = id => {
    if (intakeAcc === id) {
      setIntakeAcc()
    } else {
      setIntakeAcc(id)
    }
  }

  return (
    <div className="counselor-pay-section pt-1">
      <Card className="light-green-bg-card-white  m-0 px-3 ">
        <CardBody>
          <Col md={12}>
            <Row>
              <Col md={12} className="d-flex justify-content-end ">
                <span
                  className="btn btn-primary d-flex flex-row mt-3"
                  onClick={() => {
                    showNoteOption()
                  }}
                >
                  <div className="me-2">
                    <i className="bx bx-plus-medical  p-1 font-size-14" />
                  </div>
                  Add new note
                </span>
              </Col>
              {noteOptionShow && (
                <Col md={12} className={`noteOptionsContainer`}>
                  <div className="noteOptions">
                    <p
                      className="m-0 p-0 cursor-pointer h5 py-2 px-2"
                      onClick={openGeneralModal}
                    >
                      General note
                    </p>
                    {user?.role_id == 1 || user?.role_id == 2 ? (
                      <></>
                    ) : (
                      <p
                        className="m-0 p-0 cursor-pointer border-top border-dark  h5 py-2 px-2"
                        onClick={openSessionModal}
                      >
                        Session note
                      </p>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </Col>

          <div className="mt-2">
            <div className="d-flex flex-column gap-3">
              {isLoading ? (
                <>
                  <div className="remarkLoader d-flex align-items-center justify-content-center">
                    <Loader />
                  </div>
                </>
              ) : (
                <div className={`d-flex flex-column`}>
                  <div className="light-green-bg-card-white border-light-shadow p-3">
                    <p className="h4">General notes</p>
                    <>
                      {remarkList && remarkList?.length > 0 ? (
                        remarkList?.map((item, index) => {
                          return (
                            <>
                              <Accordion
                                className="green-accordion my-3"
                                open={accOpen}
                                toggle={toggleAccordian}
                              >
                                <AccordionItem>
                                  <AccordionHeader targetId={index}>
                                    <span className="defaultText h6  m-0">
                                      {moment(item?.created_at).format(
                                        "DD-MMM-YYYY hh:mm A"
                                      )}{" "}
                                      {item?.created_by?.first_name && (
                                        <span className="text-capitalize">
                                          by {item?.created_by?.first_name}{" "}
                                          {item?.created_by?.last_name}
                                        </span>
                                      )}
                                    </span>
                                  </AccordionHeader>
                                  <AccordionBody accordionId={index}>
                                    <div className="d-flex justify-content-between">
                                      <span className="h6 ">
                                        {item?.description}
                                      </span>
                                      <div
                                        className={`${style.remove_container} cursor-pointer`}
                                      >
                                        <img
                                          src={deleteBin}
                                          alt="Delete"
                                          className={style.remove}
                                          onClick={() =>
                                            setRemarkDeleteModal({
                                              ...remarkDeleteModal,
                                              open: true,
                                              id: item?.id,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>
                            </>
                          )
                        })
                      ) : (
                        <div className="d-flex justify-content-center align-items-center mt-3">
                          <p className="defautText">No General notes found!</p>
                        </div>
                      )}
                    </>
                  </div>
                  {user?.role_id == 1 || user?.role_id == 2 ? (
                    <></>
                  ) : (
                    <div className="light-green-bg-card-white border-light-shadow my-4 p-3">
                      <p className="h5">Session notes</p>
                      <>
                        {additionalInformation.length > 0 ? (
                          additionalInformation.map((elem, index) => {
                            return (
                              <>
                                <Accordion
                                  className="green-accordion my-3"
                                  open={sessionAcc}
                                  toggle={toggleSessionAccordian}
                                >
                                  <AccordionItem>
                                    <AccordionHeader targetId={index}>
                                      <span className="defaultText h6 font-weight-bold m-0">
                                        <span className="h6 font-weight-bold">
                                          {elem?.type_of_appointment?.name}
                                        </span>
                                        {" - "}
                                        {moment(elem?.utc_datetime).format(
                                          "DD-MMM-YYYY hh:mm A"
                                        )}
                                        {elem?.created_by?.first_name && (
                                          <span className="text-capitalize">
                                            by {elem?.created_by?.first_name}{" "}
                                            {elem?.created_by?.last_name}
                                          </span>
                                        )}
                                      </span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId={index}>
                                      <Row>
                                        <Col className="col-11">
                                          <p className="mb-3 h5 font-weight-bold">
                                            Topics discussed :{" "}
                                            <span className="h6">
                                              {elem?.appointment_notes?.topics}
                                            </span>
                                          </p>

                                          <p className="mb-3 h5 font-weight-bold">
                                            Interventions :{" "}
                                            <span className="h6">
                                              {
                                                elem?.appointment_notes
                                                  ?.interventions
                                              }
                                            </span>
                                          </p>
                                          <p className="mb-3 h5 font-weight-bold">
                                            Plans :
                                            <span className="h6">
                                              {elem?.appointment_notes?.plans}
                                            </span>
                                          </p>
                                        </Col>
                                        <Col className="col-1">
                                          <button
                                            className="waves-effect mdi mdi-pencil btn btn-inactive p-0 py-1 px-2 font-size-20 "
                                            onClick={() => {
                                              setSessionEditInfo(elem)
                                              setSessionNoteShow(true)
                                            }}
                                          ></button>
                                        </Col>
                                      </Row>
                                    </AccordionBody>
                                  </AccordionItem>
                                </Accordion>
                              </>
                            )
                          })
                        ) : (
                          <div className="d-flex justify-content-center align-items-center mt-3">
                            <p className="defautText">
                              No Session notes found!
                            </p>
                          </div>
                        )}
                      </>
                    </div>
                  )}
                  {user?.role_id == 1 || user?.role_id == 2 ? (
                    <></>
                  ) : (
                    <div className="light-green-bg-card-white border-light-shadow my-4 p-3">
                      <p className="h5">Intake info</p>
                      <>
                        {intakeInformation.length > 0 ? (
                          intakeInformation.map((elem, index) => {
                            return (
                              elem?.appointmentHasForms[0]?.forms.length >
                                0 && (
                                <>
                                  <Accordion
                                    className="green-accordion my-3"
                                    open={intakeAcc}
                                    toggle={toggleIntakeAccordian}
                                  >
                                    <AccordionItem>
                                      <AccordionHeader targetId={index}>
                                        <span className="defaultText h6 font-weight-bold m-0">
                                          <span className="h6 font-weight-bold">
                                            {elem?.type_of_appointment?.name}
                                          </span>
                                          {" - "}
                                          {moment(elem?.utc_datetime).format(
                                            "DD-MMM-YYYY hh:mm A"
                                          )}
                                          {elem?.created_by?.first_name && (
                                            <span className="text-capitalize">
                                              by {elem?.created_by?.first_name}{" "}
                                              {elem?.created_by?.last_name}
                                            </span>
                                          )}
                                        </span>
                                      </AccordionHeader>
                                      <AccordionBody accordionId={index}>
                                        <Row>
                                          <Col className="col-12">
                                            {elem?.appointmentHasForms[0]?.forms?.map(
                                              elem => {
                                                return (
                                                  <>
                                                    <p
                                                      className="mb-3 h6 font-weight-bold"
                                                      key={elem?.id}
                                                    >
                                                      {elem?.name} :{" "}
                                                      <span className="h6">
                                                        {elem?.value}
                                                      </span>
                                                    </p>
                                                  </>
                                                )
                                              }
                                            )}
                                          </Col>
                                        </Row>
                                      </AccordionBody>
                                    </AccordionItem>
                                  </Accordion>
                                </>
                              )
                            )
                          })
                        ) : (
                          <div className="d-flex justify-content-center align-items-center mt-3">
                            <p className="defautText">
                              No Intake info found!
                            </p>
                          </div>
                        )}
                      </>
                    </div>
                  )}
                </div>
              )}

              <GeneralNoteModal
                generalNoteShow={generalNoteShow}
                openGeneralModal={openGeneralModal}
                remark={remark}
                setRemark={setRemark}
                handleAddUserRemark={handleAddUserRemark}
              />

              <SessionNotesModal
                sessionNoteShow={sessionNoteShow}
                openSessionModal={openSessionModal}
                username={username}
                additionalInformation={additionalInformation}
                sessionEditInfo={sessionEditInfo}
                handleRetriveUserRemarks={handleRetriveUserRemarks}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <DeleteModal
        show={remarkDeleteModal?.open}
        onDeleteClick={() => {
          handleRemoveRemark()
        }}
        onCloseClick={() => {
          setRemarkDeleteModal({
            ...remarkDeleteModal,
            id: undefined,
            open: false,
          })
        }}
      />
    </div>
  )
}

export default Remarks
