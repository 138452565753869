import PropTypes from "prop-types"
import React, { useState } from "react"

import { connect } from "react-redux"

import { Link } from "react-router-dom"
import MenuIcon from "../../assets/images/dashboard-icons/menu-icon.svg"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logoLight from "../../assets/images/logo-light.png"
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import { Greeting } from "../../functions/functions"
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)

  const userData = useSelector(state => state.Login?.user?.user)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle(event) {
    event.preventDefault()
    var body = document.body
    if (!body.classList.contains("sidebar-enable")) {
      // sidebar is open
      body.classList.remove("vertical-collpsed")
      body.classList.add("sidebar-enable")
    } else {
      // sidebar is close
      body.classList.add("vertical-collpsed")
      body.classList.remove("sidebar-enable")
    }
  }

  const [menu, setMenu] = useState(false)
  const storedData = localStorage.getItem("multiOrg")
  const multipleOrg = storedData ? JSON.parse(storedData) : null
  const orgData = localStorage.getItem("orgData")
  const selectedOrg = orgData ? JSON.parse(orgData) : null

  const selectedStyle = {
    border: `2px solid ${
      selectedOrg ? selectedOrg.secondary_color : "#007BFF"
    } `,
    borderRadius: "  ",
    backgroundColor: "#d3d2d2",
  }
  const changeOrganization = orgData => {
    if (orgData.id !== 1) {
      localStorage.setItem("orgData", JSON.stringify(orgData))
      window.location.href = orgData.domain
    } else {
      window.location.href = orgData.domain
      localStorage.removeItem("orgData")
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement("link")
        link.rel = "icon"
        document.getElementsByTagName("head")[0].appendChild(link)
      }
      link.href = "/favicon.png"
      document.title = "No Fear Counselling"
    }
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box">
              <Link onClick={tToggle} to="#" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={MenuIcon} className="hamburger-icon" />
                  <img
                    src="/NFC-logo.png"
                    className="logo-small-hidden-mobile"
                    alt=""
                    height="40"
                  />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/NFC-logo.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="20" />
                </span>
              </Link>
            </div>
            <div className="topbar-goodmorning-admin">
              <h2>
                {Greeting()}
                {`${userData.role.role_name == "Admin" ? " Admin" : ""}`}
                {`${userData.role.role_name.toLowerCase() == "superadmin" ? " Super Admin" : ""}`}
              </h2>
              <p>Have an in-depth look of what’s going on</p>
            </div>
          </div>

          <div className="d-flex align-items-center profile-menu-right-mobile ">
            <NotificationDropdown />
            <div>
              {multipleOrg?.length > 1 && (
                <Dropdown
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                  className="d-inline-block me-5"
                >
                  <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                  >
                    <img
                      data-dz-thumbnail=""
                      height="30"
                      className="rounded"
                      alt={"transfer"}
                      src={"transfer.png"}
                    />
                  </DropdownToggle>
                  {/* <DropdownMenu>
                    {multipleOrg.map((elem, index) => (
                      <>
                        <a
                          key={index}
                          className="dropdown-item cp menu-item-16px"
                          onClick={() => changeOrganization(elem?.organization)}
                        >
                          <img
                            data-dz-thumbnail=""
                            height="30"
                            className="avatar-sm rounded"
                            alt={elem.name}
                            src={
                              elem?.organization?.favicon
                                ? elem?.organization?.favicon
                                : "favicon.png"
                            }
                          />
                          <span className="mx-3">
                            {" "}
                            {elem?.organization?.name}
                          </span>
                        </a>
                        <div className="dropdown-divider" />
                      </>
                    ))}
                  </DropdownMenu> */}
                </Dropdown>
              )}
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(Header)
