import React from "react"
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import style from "./style.module.scss"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const PriceModal = ({
  onPriceToggle,
  handleSelectType,
  handleEnterPrice,
  submitPrice,
  isPricemodalOpen,
  selectedappointmentTypesOpt,
  priceInProgress,
  appointmentTypesPrice,
  getCounselorDetail={getCounselorDetail}
}) => {

  let history = useHistory()


  return (
    <Row className={`${style.cardcenter} counselor-pay-section pt-1`}>
      <Col xl="12">
        <Card className="light-green-bg-card-white border-light-shadow mt-5">
          <CardBody>
            <Card className="transparent-bg-card m-0">
              <CardBody>
                <h5 className="font-weight-bold  mb-4">
                  Appointment Type Price
                </h5>
                <Row className="form-custom-white-inputs d-flex flex-column">
                  <Col md="6">
                    <div className="mb-2">
                      <Label className="form-label">Appointment Type</Label>

                      <Select
                        placeholder="Select Appointment Type"
                        isMulti={false}
                        className="custom-date-picker-style-bradius"
                        options={appointmentTypesPrice}
                        onChange={selectedOptions =>
                          handleSelectType(selectedOptions)
                        }
                        value={selectedappointmentTypesOpt}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-2">
                      <Label className="form-label">Price (in $)</Label>

                      <Input
                        placeholder="Enter Price"
                        className="custom-date-picker-style-bradius"
                        type="number"
                        onChange={price => handleEnterPrice(price)}
                        value={selectedappointmentTypesOpt?.price}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <div className="text-start mx-3 px-1">
              {!priceInProgress ? (
                <button
                  className="btn btn-primary w-sm waves-effect waves-light"
                  onClick={submitPrice}
                  // disabled={isSubmittedCpay}
                >
                  {"Update"}
                </button>
              ) : (
                <button
                  className="btn btn-primary w-sm waves-effect waves-light"
                  disabled
                >
                  <Spinner
                    type="grow"
                    className="me-1 align-middle spinner-grow-sm "
                    color="light"
                  />
                  Processing...
                </button>
              )}
              <button
                  className="waves-effect waves-light btn  btn-light ms-2"
                  onClick={()=> history.goBack()}
                >
                  Cancel
                </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PriceModal
