import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import useStyles from "../styles"
import classNames from "classnames"
import style from "./style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"
import millify from "millify"

// Icons
import clients from "../../../assets/images/dashboard-icons/counselor-dashboard/clients.svg"
import appointments from "../../../assets/images/dashboard-icons/counselor-dashboard/appointments.svg"
import badFit from "../../../assets/images/dashboard-icons/counselor-dashboard/bad-fit.svg"
import billable from "../../../assets/images/dashboard-icons/counselor-dashboard/billable.svg"

function MiniWidget(props) {
  const { dashboardData } = props
  const classes = useStyles()

  const reports = [
    {
      id: 1,
      arrowIcon: "mdi mdi-arrow-up-bold",
      title: "Clients",
      icon: clients,
      value: dashboardData?.total_clients ? dashboardData.total_clients : 0,
      prefix: "",
      suffix: "",
      decimal: 0,
      desc: "This will display total no of clients treated.",
    },
    {
      id: 2,
      arrowIcon: "mdi mdi-arrow-down-bold",
      title: "Appointments",
      icon: appointments,
      value: dashboardData?.total_appoinments
        ? dashboardData.total_appoinments
        : 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      desc: "This will display total no of appointments booked.",
    },
    {
      id: 4,
      arrowIcon: "mdi mdi-arrow-up-bold",
      title: "Revenue",
      icon: billable,
      value: dashboardData?.total_billable_count
        ? `${Math.floor(dashboardData.total_billable_count)}`
        : 0,
      decimal: 2,
      prefix: "$",
      suffix: "",
      desc: "This will display total amount paid by client.",
    },
  ]

  return (
    <React.Fragment>
      {dashboardData &&
        reports.map((report, index) => (
          <Col key={index}>
            <Card>
              <CardBody className={classes.CardPadding}>
                <div className="d-flex flex-column gap-2 flex-wrap">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4
                        className="mt-1 mb-0"
                        title={report.value?.toLocaleString("en-IN")}
                      >
                        <span className="mini-widget-report-prefix">
                          {`${report.prefix}${millify(report.value)}`}
                        </span>
                      </h4>
                      <p
                        className={classNames(
                          "mt-1 " + classes.counselorWidgetTitle
                        )}
                      >
                        {report.title}
                        <Tooltip
                          className={style.tooltip}
                          placement="right"
                          title={
                            <div className="invoice-list-fsize-14">
                              {report.desc}
                            </div>
                          }
                          arrow
                        >
                          <i
                            className={`uil uil-info-circle ${style.circleinfo}`}
                          ></i>
                        </Tooltip>
                      </p>
                    </div>
                    <img src={report.icon} width={64} height={64} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
    </React.Fragment>
  )
}

export default MiniWidget
