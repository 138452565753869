import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
// import images
import logo from "../assets/images/logo.png"
import logolight from "../assets/images/logo-light.png"

const PageNotFound = () => {
  let history = useHistory();
const pathName = history.location.pathname.replace(/^\/|\/$/g, '')

  return (
    <React.Fragment>
      <Container>
        <Row className="align-items-center justify-content-center m-0 p-0" >
          <Col md={12} lg={12} xl={12} className="m-0 p-0">
            <Card className="container-fluid vh-100">
              <CardBody>
                
                <Row>
                  <Col className="col-12">
                    <div className="d-flex align-items-center flex-column">
                      <i className="uil uil-exclamation-triangle text-primary fa-5x"></i>
                      <h1 className="display-1">404</h1>
                      <h1>Ooops!!</h1>
                      <p className="text-center">You Don&apos;t Have Access Of <span className="text-capitalize">{pathName}</span> Page</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button className="btn btn-primary w-sm waves-effect waves-light" type="button"
                        onClick={() => history.goBack()}>
                        Go Back
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default PageNotFound;
