const { makeStyles } = require("@material-ui/styles");

export const useStyles = makeStyles((theme) => ({
    filterContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        "@media (max-width: 768px)": {
            flexDirection: "column"
        }
    },
    datePickerFilter: {
        "@media (max-width: 768px)": {
            minWidth: "100%"
        },
        "@media (max-width: 568px)": {
            minWidth: "100%"
        }
    },
}));

export default useStyles;