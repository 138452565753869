import React, { useState, useEffect } from "react"
import TableInput from "../../../../components/Common/TableInput"
import { useSelector, useDispatch } from "react-redux"
import { updateClientAppointmentsDetails } from "../../../../store/actions"
import AsyncSelect from "react-select/async"
import { useLazyQuery } from "@apollo/client"
import { GET_PAYMENT_OPTIONS } from "./gql"

function PaymentType(props) {
  const { parentRow, rowValue, row, index, field } = props
  const dispatch = useDispatch()
  const [value, setValue] = useState(rowValue)
  const [selectedOpt, setSelectedOpt] = useState({
    label: rowValue,
    value: rowValue,
  })
  const [isEditing, setisEditing] = useState(false)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [getPaymentOpt, getPaymentOptList] = useLazyQuery(GET_PAYMENT_OPTIONS, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    getPaymentOpt()
  }, [])
  useEffect(() => {
    if (getPaymentOptList?.data?.getPaymentType?.data?.paymentTypes?.length) {
      const options =
        getPaymentOptList?.data?.getPaymentType?.data?.paymentTypes.map(d => ({
          label: d.name,
          value: d.name,
        }))
      setPaymentOptions(options)
    }
  }, [getPaymentOptList.data])

  const getPaymentApi = async (issearch, callback) => {
    try {
      const tempData = await getPaymentOpt()
      const { data, code, status } = tempData?.data?.getPaymentType
      if (code == 200 && status && data) {
        if (data?.paymentTypes?.length) {
          const options = data?.paymentTypes.map(d => ({
            label: d.name,
            value: d.name,
          }))
          setPaymentOptions(options)
          if (issearch) {
            callback(options)
          }
        }
      } else {
        callback([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loadOptions = (inputSelect, callback) => {
    getPaymentApi(inputSelect, callback)
  }

  const handleEdit = () => {
    setisEditing(true)
  }

  const onhandleCancel = () => {
    setSelectedOpt({ label: value, value })
    setisEditing(false)
  }

  const onhandleSuccess = index => {
    setValue(selectedOpt?.value)
    setisEditing(false)
    dispatch(
      updateClientAppointmentsDetails(parseInt(parentRow.id), index, {
        [field.columnId]: selectedOpt?.value,
      })
    )
  }

  const customStyles = {
    menuPortal: provided => ({
      ...provided,
      zIndex: 9999, // Make sure the menu appears on top of other elements
    }),
    menu: provided => ({
      ...provided,
      overflowY: "auto", // Enable vertical scrolling
    }),
  }

  return (
    <>
      {isEditing ? (
        <div
          key="table-inp"
          className="d-flex gap-2 p-1 align-items-center justify-content-center height-40px-extra-toolkit"
        >
          <div className="payment-type-flex-70">
            <AsyncSelect
              styles={customStyles}
              menuPortalTarget={document.body}
              cacheOptions
              placeholder="Select Name"
              loadOptions={loadOptions}
              defaultOptions={paymentOptions}
              value={selectedOpt}
              onChange={selectedOption => {
                setSelectedOpt(selectedOption)
              }}
            />
          </div>
          <button
            key="submit"
            type="button"
            className="btn btn-success btn-sm d-flex align-items-center justify-content-center"
            onClick={e => onhandleSuccess(index)}
          >
            <i className="bx bx-check font-size-18 text-white"></i>
          </button>

          <button
            key="cancel"
            className="btn btn-dark btn-sm d-flex align-items-center justify-content-center"
            onClick={() => onhandleCancel(row.price, row.id, row)}
          >
            <i className="bx bx-x font-size-18 text-white"></i>
          </button>
        </div>
      ) : (
        <div>
          {value}
          <i className="uil uil-pen ms-2 pointer" onClick={handleEdit}></i>
        </div>
      )}
    </>
  )
}

export default PaymentType
