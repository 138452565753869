import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
query GetAppointmentReceiptManagement($getAppointmentReceiptManagement: ReceiptManagementInput!) {
  getAppointmentReceiptManagement(getAppointmentReceiptManagement: $getAppointmentReceiptManagement) {
    data {
      appointments {
        first_name
        last_name
        client_id
        name
      }
    }
    status
    code
  }
}
`

export const GET_APPOINTMENTS = gql`
query GetAppointmentReceiptManagement($getAppointmentReceiptManagement: ReceiptManagementInput!) {
  getAppointmentReceiptManagement(getAppointmentReceiptManagement: $getAppointmentReceiptManagement) {
    data {
      appointments {
        first_name
        last_name
        count
        client_id
        name
      }
      total_records
      total_appointments_count 
    }
    status
    code
  }
}
`

export const GET_EXPANDED_DATA = gql`
query GetAppointmentReceiptManagement($getAppointmentReceiptManagement: ReceiptManagementInput!) {
  getAppointmentReceiptManagement(getAppointmentReceiptManagement: $getAppointmentReceiptManagement) {
    data {
      appointments {
        date
        appointment_id
      } 
    }
    status
    code
  }
}
`

export const GENERATE_RECEIPT = gql`
query GetReceiptView($getReceiptView: ReceiptManagementInput!) {
  getReceiptView(getReceiptView: $getReceiptView) {
    data {
      calendar {
        name
        id
        email
        total_sessions
        total_Amount
        total_gst_Amount
        total_rate
        receipt_name
        appointments {
          date
          id
          type
          price
          counsellor_rate
          gst
          total
          appointment_type
          appointment_id
          name
          first_name
          last_name
          email
          client {
            first_name
            last_name
            name
            email
          }
          id
          total
          calendar_id
          date
          amount_paid
        }
      }
    }
    code
    message
    status
  }
}
`

export const UPDATE_RATE = gql`
mutation UpdateAppointment($updateAppointmentInput: UpdateAppointmentInput!) {
  updateAppointment(updateAppointmentInput: $updateAppointmentInput) {
    code
    message
    status
  }
}
`

export const INCREMENT_BILLED_SESSION = gql`
query UpdateReceiptBilledSessions($argumentsInput: ArgumentsInput) {
  updateReceiptBilledSessions(argumentsInput: $argumentsInput) {
    message
    status
    code
  }
}
`