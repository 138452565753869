import PropTypes from "prop-types"
import React from "react"
import { Button, Col, Modal, ModalBody, Row } from "reactstrap"

const DeleteModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  inProgress = false,
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
              <h3>Are you sure?</h3>
              <h5>{"You won't be able to revert this!"}</h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <Button
                type="button"
                disabled={inProgress}
                className="btn btn-primary  ms-2"
                onClick={onDeleteClick}
              >
                Yes, delete it!
              </Button>
              <Button
                type="button"
                className="btn btn-inactive btn-lg ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default DeleteModal
