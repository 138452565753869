import React from "react"
import { useDispatch } from "react-redux"
import { useQuery } from "@apollo/client"

import { SIGNIN_GQL } from "./gql/appGql"
import { loginUser } from "./store/actions"
import { setPermission } from "./functions/getLoginData"

function Auth(props) {
  const dispatch = useDispatch()

  const [waitAuthCheck, setWaitAuthCheck] = React.useState(true)

  const token = localStorage.getItem("token")

  const { data, isLoading, error, refetch } = useQuery(SIGNIN_GQL)

  React.useEffect(() => {
    if (data && data.signInInfo && data.signInInfo.status) {
      const userDetails = data.signInInfo.data
      const allData = {
        token:userDetails?.token,
        user:{
          name:userDetails?.user?.calendar?.name,
          calendarId:userDetails?.user?.calendar?.id,
          email:userDetails?.user?.email,
          first_name:userDetails?.user?.first_name,
          id:userDetails?.user?.id,
          is_active:userDetails?.user?.is_active,
          last_name:userDetails?.user?.last_name,
          mobile_number:userDetails?.user?.mobile_number,
          role:userDetails?.user?.role,
        }
      }
      dispatch(loginUser(allData, null))
      // setPermission(data.signInInfo.data.permission)
      // localStorage.setItem("is_password_reset", data.signInInfo.data.user.is_password_reset)
      setWaitAuthCheck(false)
    } else {
      if (data !== undefined && data?.signInInfo !== undefined) {
        setWaitAuthCheck(false)
        localStorage.removeItem("token")
        localStorage.removeItem("is_password_reset");
        localStorage.removeItem("authUser")
      }
      if (token) {
        refetch()
      } else {
        setWaitAuthCheck(false)
        localStorage.removeItem("token")
        localStorage.removeItem("is_password_reset");
        localStorage.removeItem("authUser")
        // history.push('/login')
      }
    }

  }, [data]);

  return waitAuthCheck ? <></> : <>{props.children}</>;
}

export default Auth;
