import React, { useEffect, useState } from "react"
import style from "../style.module.scss"
import { Col, Row } from "reactstrap"
import DirectClientList from "../DirectClient/DirectClientList"
import NonDirectClientList from "../NonDirectClient/NonDirectClientList"
import { useLocation } from "react-router-dom"
import classNames from "classnames"
import notPaid from "../../../assets/images/legend-icons/not-paid.svg"
import activatedDeactivated from "../../../assets/images/legend-icons/activate-deactivated.svg"
import partiallyPaid from "../../../assets/images/legend-icons/partially-paid.svg"
import { makeStyles } from "@material-ui/core"
import noApproval from "../../../assets/images/legend-icons/noApproval.png"
import submitted from "../../../assets/images/legend-icons/submitted.svg"
import oneSessionOrWeek from "../../../assets/images/legend-icons/one-session-week.svg"
import twoSessionOrWeek from "../../../assets/images/legend-icons/two-sessions-week.svg"

const DirectNonDirect = () => {
  const [selectedCounsellor, setSelectedCounsellor] = useState([])
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const getCounsellorId = parseInt(queryParams.get("counsellor"))
  useEffect(() => {
    setSelectedCounsellor(getCounsellorId)
  }, [getCounsellorId])

  const useStyles = makeStyles(theme => ({
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      "@media (max-width: 1400px)": {
        justifyContent: "start",
        flexDirection: "column",
      },
    },

    conatiner: {
      width: "500px",
      textAlign: "end",
      alignSelf: "end",
      "@media (max-width: 1400px)": {
        textAlign: "start",
        alignSelf: "start",
        width: "fit-content",
        padding: 0,
      },
    },

    legendContainer: {
      justifyContent: "start",
      "@media (max-width: 576px)": {
        justifyContent: "start",
      },
    },

    legendAlign: {
      "@media (max-width: 576px)": {
        flexDirection: "column",
      },
    },

    legendAlignEnd: {
      alignItems: "end",
      "@media (max-width: 576px)": {
        alignItems: "start",
      },
    },
  }))
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="p-2">
            <h4 className="ps-2 card-title-main">
              Direct x Non-Direct Clients
            </h4>
            <h5 className={`${style.subtitlemenu} ps-2  card-para-main`}>
              List of all the direct and non direct clients assigned to each
              counsellor with their details.
            </h5>
          </div>
          <Row className="m-0 p-0">
            <div className={classNames("w-100", classes.conatiner)}>
              <div className="d-inline-flex">
                <Row>
                  <div className="col mt-0">
                    <div
                      className={classNames(
                        "card-title p-1 d-flex m-0",
                        classes.legendContainer
                      )}
                    >
                      <div className="py-2">
                        <div className={`d-flex text-black legend-list `}>
                          <div
                            className={classNames(
                              `d-flex flex-wrap justify-content-end`,
                              classes.legendAlign
                            )}
                          >
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={noApproval}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>No Approval</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={oneSessionOrWeek}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>1 Session/1 Week Left</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={twoSessionOrWeek}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>2 Sessions/2 Weeks Left</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={submitted}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>Submitted</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={notPaid}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>No Payment</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={activatedDeactivated}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>Request to activate/deactivate</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={partiallyPaid}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>Partial Charge</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Row>

          <Row>
            <Col md={12}>
              <DirectClientList
                combinedPage={true}
                counsellorChange={selectedCounsellor}
              />
            </Col>
            <Col md={12}>
              <NonDirectClientList
                combinedPage={true}
                counsellorChange={selectedCounsellor}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DirectNonDirect
