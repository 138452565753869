import React, { useState, useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody } from "reactstrap"
import useStyles from "../styles"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import classNames from "classnames"
import style from "../Admin/style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"

function PieChart(props) {
  const {
    title,
    height,
    colors,
    donutSize,
    data,
    loading,
    refreshData,
    setIsLoader,
  } = props
  const classes = useStyles()
  const [label, setLabel] = useState([])
  const [series, setSeries] = useState([])

  useEffect(() => {
    if (data && data.CounselordashboardGraph.code === 200) {
      setIsLoader(false)
      let appointmentData = data?.CounselordashboardGraph?.data?.appointment_type_graph
      let arr = appointmentData?.length > 0 ? appointmentData : [{
        "appointments_type": "No Appointments",
        "percentage": 100,
      }];
      let labels = []
      let serArr = []
      arr.forEach(obj => {
        labels.push(obj.appointments_type)
        let degit = parseFloat(obj.percentage).toFixed(2)
        serArr.push(parseFloat(degit))
      })
      setLabel(labels)
      setSeries(serArr)
    }
  }, [data])

  const options = {
    labels: label,
    colors: colors,
    legend: {
      show: !0,
      position: "bottom",
      horizontalAlign: "left",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + "%"
        },
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: donutSize,
        },
      },
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex] + "%"
      },
    },
  }
  const refreshApi = () => {
    setIsLoader(true)
    return refreshData()
  }

  return (
    <Card body className="light-green-bg-card-table">
      <CardBody className="p-0">
        <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
          <div className="d-flex">
            <h4 className={classNames("card-title", classes.cardTitle)}>
              {title}
            </h4>
            <Tooltip
              className={style.tooltip}
              placement="right"
              title={
                <div className="invoice-list-fsize-14">
                  This will display the ratio of appointment type booked most by
                  the clients
                </div>
              }
              arrow
            >
              <i
                className={`uil ul-bg-dark uil-info-circle ${style.circleinfo}`}
              ></i>
            </Tooltip>
          </div>
          <img
            onClick={() => refreshApi()}
            src={refresh}
            width="24px"
            height="24px"
            className={classNames(
              classes.refreshIcon,
              loading && classes.refresh
            )}
          />
        </div>
        <div className="pie-chart-mgtop-30px ">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height={height}
            className="apex-charts"
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default PieChart
