import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import {
  userRoutes,
  authRoutes,
  videoCallRoutes,
  withoutUserMiddlewareRoutes,
} from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/styles.css"

// Import Fack Backend
import fakeBackend from "./helpers/AuthType/fakeBackend"
import ErrorBoundary from "./components/ErrorBoundary"

import Auth from "./auth"
import { generateDarkAndLightColors } from "./functions/functions"

// Activating fake backend
fakeBackend()

const App = props => {
  const storedData = localStorage.getItem("orgData")
  const customColor = storedData ? JSON.parse(storedData) : null

  const userData = localStorage.getItem("userPermission")
  let userPermission = userData ? JSON.parse(userData) : []

  // function for generating light and dark color
  const primaryShadesColor = generateDarkAndLightColors(
    customColor?.primary_color
  )

  // const secondaryShadesColor = generateDarkAndLightColors(
  //   customColor?.secondary_color
  // )

  const defaultColors = {
    "--background-color": "#F4F7FE",
    "--text-color-dark": "#000",
    "--text-dark": "#1e1e1e",
    "--text-light": "#ffffff",
    "--chart-text": "#878e94",
    "--text-color-inactive-links": "#172426",
    "--dark-green": "#22696D",
    "--btn-disabled": "#7FA8AA",
    "--green-color-light": "#84a17d",
    "--green-color-light-table": "#84a17d",
    "--green-color-bg-card": "#488386",
    "--green-color-bg-card-th": "#488386",
    "--green-color-bg-card-tbl": "white",
    "--bs-body-font-size": "1rem",
    "--bs-nav-link-color": "var(--dark-green)",
    "--bs-nav-link-hover-color": "var(var(--green-color-light))",
    "--bs-btn-color": "#fff",
    "--bs-btn-bg": "#22696D",
    "--bs-btn-border-color": "#22696D",
    "--bs-btn-hover-color": "#fff",
    "--bs-btn-hover-bg": "#488386",
    "--bs-btn-hover-border-color": "#488386",
    "--bs-btn-focus-shadow-rgb": "95, 106, 187",
    "--bs-btn-active-color": "#fff",
    "--bs-btn-active-bg": "#22696D",
    "--bs-btn-active-border-color": "#22696D",
    "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
    "--bs-btn-disabled-color": "#fff",
    "--bs-btn-disabled-bg": "#2e4338",
    "--bs-btn-disabled-border-color": "#2e4338",
    "--bs-card-green-light": "#cddbc9",
    "--bs-card-green-light-tr": "#cddbc9"
  }

  const customColors = customColor?.primary_color
    ? {
        "--dark-green": primaryShadesColor?.dark,
        "--text-color-dark": customColor?.font_color,
        "--text-dark": customColor?.font_color,
        "--text-light": customColor?.font_color,
        "--chart-text": customColor?.font_color,
        "--text-color-inactive-links": customColor?.font_color,
        "--btn-disabled": customColor?.primary_color,
        "--green-color-light": primaryShadesColor?.light,
        "--green-color-bg-card": customColor?.primary_color,
        "--bs-nav-link-color": customColor?.primary_color,
        "--bs-nav-link-hover-color": customColor?.secondary_color,
        "--bs-btn-color": "#fff",
        "--bs-btn-bg": customColor?.primary_color,
        "--bs-btn-border-color": customColor?.secondary_color,
        "--bs-btn-hover-color": "#fff",
        "--bs-btn-hover-bg": customColor?.secondary_color,
        "--bs-btn-hover-border-color": customColor?.primary_color,
        "--bs-btn-focus-shadow-rgb": "95, 106, 187",
        "--bs-btn-active-color": " #fff",
        "--bs-btn-active-bg": customColor?.primary_color,
        "--bs-btn-active-border-color": customColor?.primary_color,
        "--bs-btn-active-shadow": `inset 0 3px 5px ${customColor?.primary_color}`,
        "--bs-btn-disabled-color": "#fff",
        "--bs-btn-disabled-bg": customColor?.primary_color,
        "--bs-btn-disabled-border-color": customColor?.secondary_color,
        "--bs-card-green-light": primaryShadesColor?.light,
      }
    : {}

  const allColors = { ...defaultColors, ...customColors }

  Object.entries(allColors).forEach(([property, value]) => {
    document.documentElement.style.setProperty(property, value)
  })

  useEffect(() => {
    if (customColor?.favicon) {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement("link")
        link.rel = "icon"
        document.getElementsByTagName("head")[0].appendChild(link)
      }
      link.href = customColor?.favicon
      document.title = customColor?.name
    }
  }, [customColor])

  return (
    <React.Fragment>
      <Router>
        <Auth>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                userPermission={userPermission}
                isAuthProtected={true}
                exact
              />
            ))}

            {videoCallRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}

            {withoutUserMiddlewareRoutes.map((route, idx) => (
              <Route
                exact
                path={route.path}
                component={route.component}
                key={`${idx}withoutmiddleware`}
              />
            ))}
          </Switch>
        </Auth>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
