import React, { useCallback, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Row,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import Table from "../../components/Common/Table"
import {
  capitalizeFirstLetter,
  checkPermissions,
  hasPermission,
  sortCaret,
} from "../../functions/functions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_USER_ROLE, DELETE_USER_ROLE } from "./gql/userGql"
import debounce from "lodash.debounce"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import moment from "moment"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import Loader from "../../components/Common/Loader"
import edit from "../../assets/images/dashboard-icons/edit.svg"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import PageNotFound from "../404"

const UserRoleList = () => {
  let history = useHistory()
  const [isSpinner, setIsSpinner] = useState(false)
  const [totalCount, setTotalCount] = useState(20)
  const [users, setUsers] = useState([])
  const params = new URLSearchParams(window.location.search)
  const pageNumber = params.get("page")
  const [state, setState] = useState({
    page: 1,
    data: users,
    sizePerPage: 20,
    sortBy: "id",
    sortOrder: "DESC",
  })
  const userData = useSelector(state => state.Login?.user?.user)
  const handleEditClick = row => {
    history.push(`/user-groups/${row.id}/edit`)
  }

  const [inProgress, setInProgress] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteRole, setDeleteRole] = useState({
    id: null,
  })

  const [getUserRoleList, listResponse] = useLazyQuery(GET_USER_ROLE, {
    fetchPolicy: "no-cache",
  })

  function getUserRoleListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder
  ) {
    getUserRoleList({
      variables: {
        argumentsInput: {
          page: page,
          limit: sizePerPage,
          search: searchText,
          sort: sortField,
          sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        },
      },
    })
      .then(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          searchText,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
      .catch(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          searchText,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
  }

  React.useEffect(() => {
    getUserRoleListFun(
      state.page,
      state.sizePerPage,
      "",
      state.sortBy,
      state.sortOrder
    )
  }, [])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getRoles &&
      listResponse.data.getRoles.code === 200
    ) {
      setIsSpinner(false)
      setUsers(listResponse.data.getRoles.data.roles)
      setTotalCount(listResponse.data.getRoles.data.totalCount)
      setState(s => ({ ...s, data: listResponse.data.getRoles.data.roles }))
    } else if (
      listResponse.data &&
      listResponse.data.getRoles &&
      !listResponse.data.getRoles.status &&
      listResponse.data.getRoles.code !== 200
    ) {
      setIsSpinner(false)
      setUsers()
      setTotalCount(0)
      setState(s => ({ ...s, data: [] }))
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  const columns = [
    {
      text: "#",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "Name",
      dataField: "role_name",
      width: 270,
      sort: true,
      formatter: cell => capitalizeFirstLetter(cell),
      headerStyle: {
        cursor:"pointer"
      },
    },
    {
      text: "Created On",
      dataField: "created_at",
      width: 200,
      formatter: dateFormatFun,
    },

  ]

  function dateFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="">
        <p className="p-0 m-0">
          {moment(row.created_at).format("DD-MMM-yyyy")}
        </p>
      </div>
    )
  }

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="p-0 m-0">
          {rowIndex + 1 + state.sizePerPage * (state.page - 1)}
        </p>
      </>
    )
  }

  function statusEvent(cell, row, rowIndex, formatExtraData) {
    let color = "success"
    const { is_active } = row
    if (!is_active) {
      color = "danger"
    }
    let chipClass = "rounded-pill p-2 px-3 bg-soft-" + color
    return (
      <div className="d-flex">
        <Badge className={chipClass}>
          {row.is_active ? "Active" : "In-active"}
        </Badge>
      </div>
    )
  }
  function actionEvent(cell, row, rowIndex, formatExtraData) {
    const restrictedIds = [1, 2, 3].includes(row.id)
    return (
      <div className="d-flex justify-content-left align-items-center">
        {!restrictedIds && (
          <button
            className="btn btn-primary cursor-pointer"
            onClick={() => handleEditClick(row)}
          >
            <img src={edit} className="" />
          </button>
        )}
        {!restrictedIds && (
          <a
            onClick={() => openDeleteModel(row.slug)}
            className="px-3 text-center text-danger delete-btn cursor-pointer-accordion "
          >
            <img src={deleteBin} />
          </a>
        )}
      </div>
    )
  }

  const changeHandler = ({
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
  }) => {
    const field = sortField === null ? "id" : sortField
    getUserRoleListFun(1, sizePerPage, searchText, field, sortOrder)
  }

  const delaySearch = useCallback(debounce(changeHandler, 1000), [])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    const field = sortField === null ? "id" : sortField
    setState(p => ({
      ...p,
      page,
      sizePerPage,
    }))
    setIsSpinner(true)
    if (type === "search") {
      delaySearch({ page, sizePerPage, searchText, sortField, sortOrder })
    } else {
      getUserRoleListFun(page, sizePerPage, searchText, field, sortOrder)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  //******************************************************** */

  function deleteToggle() {
    setModalDelete(!modalDelete)
  }

  const openDeleteModel = async id => {
    setModalDelete(!modalDelete)
    setDeleteRole({
      id,
    })
  }
  const [deleteMutation, deleteResponse] = useMutation(DELETE_USER_ROLE)

  const handleDelete = async () => {
    try {
      setInProgress(true)
      await deleteMutation({
        variables: {
          roleInput: {
            slug: deleteRole.id,
          },
        },
      })
    } catch (e) {
      setInProgress(false)
      console.log(e)
    }
    setDeleteRole({
      id: null,
    })
    deleteToggle()
  }
  React.useEffect(() => {
    setInProgress(false)
    if (deleteResponse?.data && deleteResponse?.data?.deleteRole?.status) {
      toast.success(deleteResponse?.data?.deleteRole?.message)
      getUserRoleListFun(
        state.page,
        state.sizePerPage,
        "",
        state.sortBy,
        state.sortOrder
      )
    } else {
      if (deleteResponse?.data && deleteResponse?.data?.deleteRole) {
        toast.error(deleteResponse?.data?.deleteRole?.message)
        getUserRoleListFun(
          state.page,
          state.sizePerPage,
          "",
          state.sortBy,
          state.sortOrder
        )
      }
    }
  }, [deleteResponse.data])

  return (
    <React.Fragment>
{hasPermission() ? (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between flex-wrap-mobile-only">
            <div className="p-2">
              <h4 className="card-title-main">User Groups</h4>
              <h5 className="card-para-main">
                List of all the user groups created for assigning access rights.
              </h5>
            </div>
            <div className="p-2">
              {/* <Link to="/user-groups/create" className="btn btn-primary">
                <span>Add User Group</span>
                <i className="bx bx-plus-medical mx-2" />
              </Link> */}
            </div>
          </div>
          <Row className="mg-top-default-ui">
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody className="table-custom-redesign">
                  <Table
                    data={state.data}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    isSearch={true}
                    isHover={false}
                    isStriped={false}
                    isBordereded={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setModalDelete(false)
                    setDeleteRole({ id: null })
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => handleDelete()}
                >
                  Yes, delete it
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      </>
      ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  )
}

export default UserRoleList
