import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import style from "./style.module.scss"
import { fetchApi } from "../../common/api"
import styles from "../Counselor/style.module.scss"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import Tooltip from "@material-ui/core/Tooltip"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_COUNSELLORS } from "../Counselor/gql/Gql"
import Dropzone from "react-dropzone"
import Soinner from "../../assets/images/Loader.svg"
import Select from "react-select"
import {
  CREATE_EDIT_APPOINTMENT_TYPE,
  GET_APPOINTMENT_BY_ID,
  GET_CATEGORIES,
} from "./gql/userGql"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import Loader from "../../components/Common/Loader"

const CreateAppointmentType = props => {
  let { id } = useParams()
  const decodedId = id && atob(id)
  id = decodedId
  let history = useHistory()

  const [formData, setFormData] = useState({
    name: "",
    description: undefined,
    duration: undefined,
    price: 0,
    color: "#1972EB",
    category: "",
    picture: undefined,
    location: undefined,
    confirmation_message: undefined,
    private: true,
    call_type: "in person",
    calendars: [],
    priceArr: [],
    break_time : 0
  })
  const [showAddCategory, setShowAddCategory] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [specificPriceCheck, setSpecificPriceCheck] = useState(false)
  const [showConfirmMessage, setShowConfirmMessage] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showCustomColor, setShowCustomColor] = useState(false)
  const [counsellorList, setCounsellorList] = useState([])
  const [category, setCategory] = useState([])
  const [selectedCounsellors, setSelectedCounsellors] = useState([])
  const [locationGroups, setLocationGroupsList] = useState([])
  const [categoriesList, setCategoriesList] = useState([])
  const [allCalendarPrice, setAllCalendarPrice] = useState([])
  const [emptyFields, setEmptyFields] = useState([])
  const [previousSelection, setPreviousSelection] = useState([])
  const [getCounsellorList, getCounsellorListRes] = useLazyQuery(
    GET_COUNSELLORS,
    { fetchPolicy: "no-cache" }
  )

  const [getCategories, getCategoriesRes] = useLazyQuery(GET_CATEGORIES, {
    fetchPolicy: "no-cache",
  })
  const [getAppointmentTypeById, getAppointmentTypeByIdRes] = useLazyQuery(
    GET_APPOINTMENT_BY_ID,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [CreateAppointmentType, CreateAppointmentTypeRes] = useMutation(
    CREATE_EDIT_APPOINTMENT_TYPE
  )

  useEffect(() => {
    if (
      CreateAppointmentTypeRes?.data &&
      CreateAppointmentTypeRes?.data?.createAndUpdateAppointmentType?.status
    ) {
      if (id) {
        toast.success(
          CreateAppointmentTypeRes?.data?.createAndUpdateAppointmentType
            ?.message
        )
        setIsSubmitted(false)
      } else {
        toast.success(
          CreateAppointmentTypeRes?.data?.createAndUpdateAppointmentType
            ?.message
        )
        setTimeout(() => {
          history.goBack()
        }, 2000)
      }
    } else {
      toast.error(
        CreateAppointmentTypeRes?.data?.createAndUpdateAppointmentType?.message
      )
      setIsSubmitted(false)
    }
  }, [CreateAppointmentTypeRes.data])

  const getLocationsApi = async search => {
    try {
      let { data, code, status } = await fetchApi(
        `locationGroup/locations?is_all=trusearch=${search}`,
        "GET"
      )
      if (code == 200 && status && data) {
        const locationGroup = data?.locationGroup?.map((data, i) => ({
          ...data,
        }))
        const formattedLocations = locationGroup.map(location => ({
          name: location.name,
          label: location.name,
          id: location.id,
          value: location.id,
        }))
        setLocationGroupsList(formattedLocations)
      } else {
      }
    } catch (error) {
      console.log(error)
      setIsSpinner(false)
    }
  }

  useEffect(() => {
    getCategories({
      variables: {
        search: "",
      },
    })
    if (id) {
      setIsLoading(true)
      getAppointmentTypeById({
        variables: {
          getAppointmentTypeByIdId: parseInt(id),
        },
      })
    }
    getLocationsApi("")
  }, [])
  useEffect(() => {
    if (
      getAppointmentTypeByIdRes?.data &&
      getAppointmentTypeByIdRes?.data?.getAppointmentTypeById?.data &&
      getAppointmentTypeByIdRes?.data?.getAppointmentTypeById?.data
        ?.appointmentType
    ) {
      let getData =
        getAppointmentTypeByIdRes?.data?.getAppointmentTypeById?.data
          ?.appointmentType
      const categoriesOption = {
        value: getData?.category,
        name: getData?.category,
        label: getData?.category,
      }
      setCategory(categoriesOption)
      const calendarOption = getData?.calendars?.map(elem => ({
        value: elem?.id,
        id: elem?.id,
        key: elem?.id,
        name: elem?.name,
        label: elem?.name,
      }))
      setPreviousSelection(calendarOption)
      setSelectedCounsellors(calendarOption)
      const locationOption = {
        value: getData?.location?.id,
        id: getData?.location?.id,
        name: getData?.location?.name,
        label: getData?.location?.name,
      }
      setSpecificPriceCheck(true)
      const setCounsellorPrice = getData?.appointment_type_has_calendars?.map(
        elem => {
          return {
            id: elem?.calendar_id,
            name: elem?.calendar?.name,
            price: elem?.price ? elem?.price : 0,
          }
        }
      )
      setAllCalendarPrice(setCounsellorPrice)
      const picture_url = getData?.image_url && {
        state: "uploaded",
        url: getData.image_url,
      }
      if (getData?.description) {
        setShowDescription(true)
      }
      if (getData?.confirmation_message) {
        setShowConfirmMessage(true)
      }

      if (!colorOptions.includes(getData?.color)) {
        setShowCustomColor(true)
      }
      setSelectedColor(getData?.color)

      setFormData(prevData => ({
        ...prevData,
        ...getData,
        category: categoriesOption,
        picture: picture_url,
        location: locationOption.id ? locationOption : null,
      }))
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [getAppointmentTypeByIdRes.data])

  useEffect(() => {
    if (
      getCategoriesRes?.data &&
      getCategoriesRes?.data?.getCategories &&
      getCategoriesRes?.data?.getCategories?.data
    ) {
      const allCategories =
        getCategoriesRes?.data?.getCategories?.data?.categories
      const categoriesOption = allCategories?.map(item => {
        return {
          value: item,
          name: item,
          label: item,
        }
      })
      setCategoriesList(categoriesOption)
    }
  }, [getCategoriesRes.data])

  const getCounsellorListFun = async search => {
    await getCounsellorList({
      variables: {
        argumentsInput: {
          search: search || "",
          sort: "created_at",
          sort_dir: "DESC",
        },
      },
    })
  }

  useEffect(() => {
    getCounsellorListFun("")
  }, [])

  useEffect(() => {
    if (
      getCounsellorListRes.data &&
      getCounsellorListRes.data?.getCalendarLegend &&
      getCounsellorListRes.data?.getCalendarLegend?.data.calendars &&
      getCounsellorListRes.data?.getCalendarLegend?.data
    ) {
      const newCounsellorList = [
        ...getCounsellorListRes.data.getCalendarLegend.data.calendars.map(
          elem => ({
            value: elem.name,
            name: elem.name,
            id: elem.id,
            label: elem.name,
          })
        ),
      ]
      setCounsellorList(newCounsellorList)
    }
  }, [getCounsellorListRes.data])

  const showNewCategory = () => {
    setShowAddCategory(!showAddCategory)
  }
  const closeNewCategory = () => {
    setFormData(prevData => ({
      ...prevData,
      category: category,
    }))
    setShowAddCategory(!showAddCategory)
  }

  const showAddDescription = () => {
    setShowDescription(!showDescription)
  }

  const showAddMessage = () => {
    setShowConfirmMessage(!showConfirmMessage)
  }

  const showCustomColorFun = () => {
    setShowCustomColor(!showCustomColor)
  }

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    if (name === "duration") {
      if (value <= 9999 && value >= 0) {
        setFormData(prevData => ({
          ...prevData,
          duration: value,
        }))
      }
    } else if(name === "break_time"){
      const decimalRegex = /^[1-9][0-9]*$/;
    
      const newTime = value.startsWith("0") && value.length > 1 && !decimalRegex.test(value)
        ? value.slice(1)
        : value;
        
      if (newTime <= 60 && newTime >= 0) {
        setFormData(prevData => ({
          ...prevData,
          break_time: newTime,
        }))
      }
    }else if (name === "price") {
      const inputValue = value;
      const decimalRegex = /^[0-9]+\.[0-9]*$/; 
      const newPrice = inputValue.startsWith("0") && inputValue.length > 1 && !decimalRegex.test(inputValue)
        ? inputValue.slice(1)
        : inputValue;
      if (newPrice <= 1000 && newPrice >= 0) {
        setFormData(prevData => ({
          ...prevData,
          price: newPrice,
        }))
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === "checkbox" ? !checked : value,
      }))
    }
  }

  const handleCounsellorChange = val => {
    setSelectedCounsellors(val)
    if (specificPriceCheck) {
      const addedCounsellors = val.filter(
        item => !previousSelection.includes(item)
      )
      const removedCounsellors = previousSelection.filter(
        item => !val.includes(item)
      )
      setPreviousSelection(val)
      if (removedCounsellors.length > 0) {
        let newList = allCalendarPrice.filter(elem => {
          return elem.id !== removedCounsellors[0].id
        })
        setAllCalendarPrice(newList)
      }
      if (addedCounsellors.length > 0) {
        setAllCalendarPrice(prevData => [
          ...prevData,
          {
            id: addedCounsellors[0].id,
            name: addedCounsellors[0].name,
            price: 0,
          },
        ])
      }
    }
  }

  const handleImg = async (acceptedFiles, field) => {
    let maxFileSize
    let supportedFormats

    if (field === "logo") {
      maxFileSize = 5 * 1024 * 1000 // 5 MB for logo
      supportedFormats = ["image/jpeg", "image/png"]
    } else {
      maxFileSize = 0
      supportedFormats = []
    }

    if (acceptedFiles[0].size > maxFileSize) {
      toast.error(
        `Max File Upload Size is Upto ${
          maxFileSize / (1024 * 1024)
        } MB, try with smaller size`
      )
      return
    }

    if (supportedFormats.indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }
    setFormData(prevData => ({
      ...prevData,
      picture: preview,
    }))

    let payload = new FormData()
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get(
      "orgId"
    )
    const decodedOrgIdFromUrl = orgIdValueFromUrl
      ? decodeURIComponent(orgIdValueFromUrl)
      : undefined

    payload.set("file", acceptedFiles[0])
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        {
          method: "POST",
          body: payload,
          headers: {
            organization:
              decodedOrgIdFromUrl ||
              orgData?.organization_identifier ||
              process.env.REACT_APP_MULTITENENT,
          },
        }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        setFormData(prevData => ({
          ...prevData,
          picture: preview,
        }))
      } else {
        setFormData(prevData => ({
          ...prevData,
          picture: undefined,
        }))
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      setFormData(prevData => ({
        ...prevData,
        picture: undefined,
      }))
      toast.error("Failed to upload cover image")
    }
  }
  const [selectedColor, setSelectedColor] = useState("#1972EB") // Default color

  const handleColorChange = color => {
    setSelectedColor(color)
    setFormData(prevData => ({
      ...prevData,
      color: color,
    }))
  }
  const colorOptions = [
    "#1972EB",
    "#C60D0D",
    "#F08300",
    "#FFE767",
    "#04863B",
    "#8339B0",
    "#AC53B4",
    "#6FCF97",
    "#F7F993",
    "#F4BF00",
    "#ED7087",
    "#73C1ED",
  ]

  const handleLocationChange = val => {
    setFormData(prevData => ({
      ...prevData,
      location: val,
    }))
  }

  const handlePrivateChangeChange = e => {
    const privateValue = e.target.value === "public" ? false : true
    setFormData(prevData => ({
      ...prevData,
      private: privateValue,
    }))
  }

  const handleCategoriesChange = val => {
    setCategory(val)
    setFormData(prevData => ({
      ...prevData,
      category: val,
    }))
  }

  const CreateAppointmentTypeFun = () => {
    const validateData = data => {
      const requiredFields = [
        "name",
        "duration",
        "priceArr",
        "color",
        "category",
        "call_type",
      ]
      if (formData?.call_type === "in person") {
        requiredFields.push("location")
      }

      if (specificPriceCheck) {
        requiredFields.push("priceArr")
        delete requiredFields.price
      }

      const missingFields = requiredFields.filter(field => !data[field])
      setEmptyFields(missingFields)
      if (missingFields.length > 0) {
        window.scrollTo({ top: 150, behavior: "smooth" })
        return false
      }

      return true
    }

    let priceArray = specificPriceCheck
      ? allCalendarPrice.map(elem => {
          return elem.price.toString()
        })
      : Array.from({ length: selectedCounsellors.length }, () =>
          formData.price.toString()
        )

    const appointmentData = {
      name: formData?.name,
      active: true,
      description: formData?.description,
      confirmation_message: formData?.confirmation_message,
      duration: formData?.duration ? (Math.round(parseFloat(formData?.duration))).toString() : null,
      priceArr: priceArray,
      color: formData?.color,
      category: showAddCategory
        ? formData?.category
        : formData?.category?.value,
      picture: formData?.picture,
      location: formData?.location?.id,
      call_type: formData?.call_type,
      calendars: selectedCounsellors?.map(elem => elem.id),
      private: formData?.private,
      break_time: (parseInt(formData?.break_time, 10)) ? JSON.stringify(parseInt(formData?.break_time, 10)) : "0",
      
    }
    const createAppointmentTypeData = {
      ...appointmentData,
      location_id:
        formData?.call_type === "in person" ? appointmentData?.location : null,
      calendarIDs: appointmentData?.calendars,
      image_url: appointmentData?.picture?.url,
      calendars: undefined,
      picture: undefined,
      location: undefined,
    }

    if (validateData(appointmentData)) {
      if(!appointmentData.priceArr.includes("")){
        if (id) {
          createAppointmentTypeData["id"] = parseInt(id)
          setIsSubmitted(true)
          CreateAppointmentType({
            variables: {
              createAppointmentTypeInput: createAppointmentTypeData,
            },
          })
        } else {
          setIsSubmitted(true)
          CreateAppointmentType({
            variables: {
              createAppointmentTypeInput: createAppointmentTypeData,
            },
          })
        }
      }else{
        toast.error("Please enter all calendar price")
      }
    } else {
      setIsSubmitted(false)
    }
  }
  const handleToggle = e => {
    setSpecificPriceCheck(!e.target.checked)
    if (!e.target.checked) {
      const setCounsellorPrice = selectedCounsellors.map((elem, ind) => {
        return {
          id: elem.id,
          name: elem.name,
          price: allCalendarPrice[ind]?.price || 0,
        }
      })
      setAllCalendarPrice(setCounsellorPrice)
    } else {
      setFormData(prevData => ({
        ...prevData,
        price: 0,
      }))
    }
  }

  const handlePriceChange = (e, cId) => {
    const inputValue = e.target.value;
    const decimalRegex = /^[0-9]+\.[0-9]*$/; 
    const newPrice = inputValue.startsWith("0") && inputValue.length > 1 && !decimalRegex.test(inputValue)
      ? inputValue.slice(1)
      : inputValue;
  
    if (newPrice <= 1000 && newPrice >= 0) {
      setAllCalendarPrice(prevCounsellorList =>
        prevCounsellorList.map(counsellor =>
          counsellor.id === cId
            ? { ...counsellor, price: newPrice }
            : counsellor
        )
      )
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    {id ? "Edit Appointment Type" : "New Type of Service"}
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-lg-12 col-sm-12 position-relative mb-4">
              {isLoading ? (
                <Loader />
              ) : (
                <Row className="d-flex justify-content-center">
                  <Col xs={12} md={8}>
                    <Card className="transparent-bg-card">
                      <CardBody>
                        <Form className="form-horizontal" onSubmit="">
                          <Row className="form-custom-white-inputs">
                            <Col xl={12} md={12}>
                              <Label className="form-label">Name</Label>
                              <Tooltip
                                placement="right"
                                title={
                                  <div className="invoice-list-fsize-14 ">
                                    This name will appear on your client facing
                                    scheduling page for clients to choose from
                                    when booking an appointment.
                                  </div>
                                }
                                arrow
                              >
                                <i
                                  className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                ></i>
                              </Tooltip>
                              <Input
                                name="name"
                                className="form-control"
                                placeholder="ex. Aroma Therapy"
                                type="text"
                                value={formData?.name}
                                onChange={handleChange}
                              />
                              {emptyFields.includes("name") &&
                                !formData.name && (
                                  <span className="text-danger mx-1">
                                    Please enter appointment type name
                                  </span>
                                )}
                            </Col>

                            <Col xl={12} md={12} className="mt-3">
                              {!showDescription && (
                                <span
                                  className="color-dark-green border-bottom border-dark mx-1 cursor-pointer"
                                  onClick={showAddDescription}
                                >
                                  Add a longer description...
                                </span>
                              )}
                              {showDescription && (
                                <>
                                  <Label className="form-label">
                                    Description
                                  </Label>
                                  <Tooltip
                                    placement="right"
                                    title={
                                      <div className="invoice-list-fsize-14 ">
                                        Include an Appointment Description, Let
                                        clients know more about what this
                                        appointment entails.
                                      </div>
                                    }
                                    arrow
                                  >
                                    <i
                                      className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                    ></i>
                                  </Tooltip>
                                  <Input
                                    name="description"
                                    className="form-control"
                                    placeholder="Write some info about this appointment type"
                                    type="textarea"
                                    value={formData?.description}
                                    onChange={handleChange}
                                  />
                                </>
                              )}
                            </Col>

                            <Col xl={12} md={12} className="mt-3">
                              {!showConfirmMessage && (
                                <span
                                  className="color-dark-green border-bottom border-dark mx-1 cursor-pointer"
                                  onClick={showAddMessage}
                                >
                                  Show a message after scheduling...
                                </span>
                              )}
                              {showConfirmMessage && (
                                <>
                                  <Label className="form-label">
                                    Confirmation Message
                                  </Label>
                                  <Tooltip
                                    placement="right"
                                    title={
                                      <div className="invoice-list-fsize-14 ">
                                        Enter in text here to display on the
                                        client final appointment confirmation
                                        page after they complete scheduling this
                                        appointment from your scheduling page.
                                      </div>
                                    }
                                    arrow
                                  >
                                    <i
                                      className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                    ></i>
                                  </Tooltip>
                                  <Input
                                    name="confirmation_message"
                                    className="form-control"
                                    placeholder="Write confirmation message about this appointment type"
                                    type="textarea"
                                    value={formData?.confirmation_message}
                                    onChange={handleChange}
                                  />
                                </>
                              )}
                            </Col>

                            <Col xl={6} md={6} className="mt-3">
                              <Label className="form-label">Duration</Label>
                              <Tooltip
                                placement="right"
                                title={
                                  <div className="invoice-list-fsize-14 ">
                                    <p>
                                      Each appointment must have a set duration.
                                      These durations are fixed.
                                    </p>
                                    <p>
                                      If your appointments have multiple
                                      durations, create a separate appointment
                                      type for each
                                    </p>
                                  </div>
                                }
                                arrow
                              >
                                <i
                                  className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                ></i>
                              </Tooltip>
                              <div className="d-flex align-items-center">
                                <Input
                                  name="duration"
                                  className="form-control"
                                  placeholder="In Minutes"
                                  type="number"
                                  value={formData?.duration}
                                  onChange={handleChange}
                                />
                              </div>
                              {emptyFields.includes("duration") &&
                                !formData.duration && (
                                  <span className="text-danger mx-1">
                                    Please enter appointment type duration
                                  </span>
                                )}
                            </Col>
                            <Col xl={6} md={6} className="mt-3">
                              <Label className="form-label">Break Time</Label>
                              <Tooltip
                                placement="right"
                                title={
                                  <div className="invoice-list-fsize-14 ">
                                    This break time will fall between your two appointments
                                  </div>
                                }
                                arrow
                              >
                                <i
                                  className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                ></i>
                              </Tooltip>
                              <div className="d-flex align-items-center">
                                <Input
                                  name="break_time"
                                  className="form-control"
                                  placeholder="In Minutes"
                                  type="number"
                                  value={formData?.break_time}
                                  onChange={handleChange}
                                />
                              </div>
                              {emptyFields.includes("duration") &&
                                !formData.duration && (
                                  <span className="text-danger mx-1">
                                    Please enter appointment type duration
                                  </span>
                                )}
                            </Col>

                            <Col xl={12} md={12} className="mt-3">
                              <Label className="form-label">Color</Label>
                              <Tooltip
                                placement="right"
                                title={
                                  <div className="invoice-list-fsize-14 ">
                                    This is the color the scheduled appointment
                                    block will show as on your main overview
                                    calendar. This is not visible to clients.
                                  </div>
                                }
                                arrow
                              >
                                <i
                                  className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                ></i>
                              </Tooltip>
                              <div className="controls col-xs-10">
                                {!showCustomColor && (
                                  <div className="color-choices d-flex">
                                    {colorOptions.map((color, index) => (
                                      <div
                                        key={index}
                                        className="color-choice-container mx-1"
                                      >
                                        <input
                                          type="radio"
                                          name="color"
                                          value={color}
                                          onChange={() =>
                                            handleColorChange(color)
                                          }
                                          checked={selectedColor === color}
                                          className="visually-hidden cursor-pointer"
                                        />
                                        <div
                                          className={`color-choice cursor-pointer ${
                                            selectedColor === color
                                              ? "color-choice-selected border border-dark my-2 "
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleColorChange(color)
                                          }
                                          style={{
                                            background: color,
                                            height: "20px",
                                            width: "20px",
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {!showCustomColor && (
                                  <span
                                    className="color-dark-green border-bottom border-dark mx-1 cursor-pointer"
                                    onClick={showCustomColorFun}
                                  >
                                    Custom..
                                  </span>
                                )}

                                {showCustomColor && (
                                  <div className="d-flex align-item-center ">
                                    <input
                                      type="color"
                                      size="8"
                                      className="form-control inline-field w-50 mt-1 me-2 cursor-pointer"
                                      placeholder={selectedColor}
                                      name="color"
                                      id="color"
                                      data-testid="custom-color-input"
                                      value={formData?.color}
                                      onChange={handleChange}
                                    />
                                    <span
                                      className="btn btn-sm btn-dark"
                                      onClick={showCustomColorFun}
                                    >
                                      X
                                    </span>
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col xl={12} md={12} className="mt-3">
                              <Label className="form-label">Category</Label>
                              <Tooltip
                                placement="right"
                                title={
                                  <div className="invoice-list-fsize-14 ">
                                    <p>
                                      Use categories to organize a long list of
                                      appointment types.
                                    </p>
                                    <p>
                                      You can share a select group of
                                      appointments with your clients by using a
                                      direct link to the category.
                                    </p>
                                  </div>
                                }
                                arrow
                              >
                                <i
                                  className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                ></i>
                              </Tooltip>
                              <div className="form-custom-white-inputs">
                                {!showAddCategory && (
                                  <Select
                                    className="input my-2"
                                    onChange={handleCategoriesChange}
                                    name="category"
                                    placeholder="Select Category"
                                    value={formData?.category}
                                    options={categoriesList}
                                    isCloseMenuOnSelect={false}
                                  />
                                )}

                                {!showAddCategory && (
                                  <span
                                    className="color-dark-green border-bottom border-dark mx-1 cursor-pointer "
                                    onClick={showNewCategory}
                                  >
                                    Create a new category
                                  </span>
                                )}
                                {showAddCategory && (
                                  <div className="d-flex align-items-center mt-2">
                                    <Input
                                      name="category"
                                      className="form-control me-2"
                                      placeholder="Create a new category"
                                      type="text"
                                      onChange={handleChange}
                                    />
                                    <Button
                                      className="btn btn-primary h-40 "
                                      onClick={closeNewCategory}
                                    >
                                      X
                                    </Button>
                                  </div>
                                )}
                              </div>
                              {emptyFields.includes("category") &&
                                !formData.category && (
                                  <span className="text-danger mx-1">
                                    Please add appointment type category
                                  </span>
                                )}
                            </Col>

                            <Col xl={12} md={12} className="mt-3">
                              <div className="form-custom-white-inputs">
                                <Label className="form-label">Calendars </Label>
                                <Tooltip
                                  placement="right"
                                  title={
                                    <div className="invoice-list-fsize-14 ">
                                      Select the the Calendar names this
                                      appointment type can be booked on
                                    </div>
                                  }
                                  arrow
                                >
                                  <i
                                    className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                  ></i>
                                </Tooltip>
                                <div>
                                  <CustomMultiSelect
                                    className={`custom-date-picker-style-bradius`}
                                    name="selectCounsellors"
                                    placeholder="Select Calendars"
                                    value={selectedCounsellors}
                                    options={counsellorList}
                                    isCloseMenuOnSelect={false}
                                    isMulti
                                    onChange={value =>
                                      handleCounsellorChange(value)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xl={12} md={12} className="mt-3">
                              <Label className="form-label">Price ($)</Label>
                              <Tooltip
                                placement="right"
                                title={
                                  <div className="invoice-list-fsize-14 ">
                                    This is the price for selected counsellors
                                  </div>
                                }
                                arrow
                              >
                                <i
                                  className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                ></i>
                              </Tooltip>
                              <div className="mt-2">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch2"
                                  // value={specificPriceCheck}
                                  checked={specificPriceCheck}
                                  onChange={e => handleToggle(e)}
                                  onClick={e => handleToggle(e)}
                                  disabled={!selectedCounsellors?.length > 0}
                                />
                                {!specificPriceCheck ? (
                                  <span className="mx-2">
                                    Check if you want to add specific amount of
                                    price for selected Calendars?
                                  </span>
                                ) : (
                                  <span className="mx-2">
                                    Uncheck if you want to add same amount of
                                    price for selected Calendars?
                                  </span>
                                )}
                              </div>
                              <div className="d-flex align-items-center mt-2">
                                {specificPriceCheck ? (
                                  <>
                                    <Row>
                                      {allCalendarPrice.map((elem, ind) => {
                                        return (
                                          <>
                                            <Col
                                              md={6}
                                              className="my-1"
                                              key={elem?.id}
                                            >
                                              <Input
                                                name="cName"
                                                className="form-control"
                                                placeholder="Enter Counsellor"
                                                type="text"
                                                value={elem?.name}
                                                disabled={true}
                                              />
                                            </Col>
                                            <Col md={6} className="my-1  d-flex align-items-center">
                                              <Input
                                                name="cName"
                                                className="form-control"
                                                placeholder="Enter Price"
                                                type="number"
                                                value={elem?.price}
                                                onChange={e =>
                                                  handlePriceChange(e, elem.id)
                                                }
                                              />
                                            </Col>
                                          </>
                                        )
                                      })}
                                    </Row>
                                  </>
                                ) : (
                                  <Input
                                    name="price"
                                    className="form-control w-50"
                                    placeholder="Enter Price"
                                    type="number"
                                    value={formData?.price}
                                    onChange={handleChange}
                                    disabled={!selectedCounsellors?.length > 0}
                                  />
                                )}
                              </div>
                            </Col>
                            <Col xl={12} md={12} className="mt-3">
                              <Label className="form-label">Picture</Label>

                              <div className="form-custom-white-inputs">
                                <div className="d-flex align-items-center mt-2 w-50">
                                  <Dropzone
                                    multiple={false}
                                    onDrop={file => handleImg(file, "logo")}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="orgDropzone">
                                        <input {...getInputProps()} />

                                        {!formData?.picture ? (
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <div className="">
                                              <i className="display-4 uil uil-cloud-upload" />
                                            </div>
                                            <h5>Drop JPEG, JPG or PNG.</h5>
                                            <h6>
                                              It can be square, landscape or
                                              portrait. Upto 5MB file size.
                                            </h6>
                                          </div>
                                        ) : (
                                          <>
                                            {formData?.picture?.state ===
                                              "uploading" && (
                                              <img
                                                className="needsclick rounded bg-light w-100 h-auto"
                                                alt="primary"
                                                src={Soinner}
                                              />
                                            )}
                                            {formData?.picture?.state ===
                                              "uploaded" && (
                                              <div>
                                                <img
                                                  {...getRootProps()}
                                                  className="needsclick rounded bg-light w-100"
                                                  alt="primary"
                                                  src={formData?.picture?.url}
                                                />
                                                <button
                                                  onClick={() => {
                                                    setFormData(prevData => ({
                                                      ...prevData,
                                                      picture: undefined,
                                                    }))
                                                  }}
                                                  className={`${styles.removeButton} mdi mdi-close`}
                                                  type="button"
                                                  aria-label="Close"
                                                ></button>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </Dropzone>
                                </div>
                              </div>
                            </Col>
                            <Col xl={12} md={12} className="mt-3">
                              <Label className="form-label">Call Type</Label>
                              <Tooltip
                                placement="right"
                                title={
                                  <div className="invoice-list-fsize-14 ">
                                    <p>
                                      Select the preferred mode for appointments
                                      - in person, phone call, or online video.
                                    </p>
                                  </div>
                                }
                                arrow
                              >
                                <i
                                  className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                ></i>
                              </Tooltip>
                              <div className="form-custom-white-inputs">
                                <div className=" mt-2">
                                  <div>
                                    <div className="d-flex align-items-center">
                                      <input
                                        name="call_type"
                                        type="radio"
                                        value="in person"
                                        id="IN_PERSON"
                                        onClick={handleChange}
                                        checked={
                                          formData?.call_type?.toLowerCase() ===
                                          "in person"
                                        }
                                      />
                                      <Label
                                        className="form-label mx-2 mb-0"
                                        for="IN_PERSON"
                                      >
                                        In Person
                                      </Label>
                                    </div>
                                    <p>
                                      Choose this option for appointments
                                      conducted face-to-face
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <input
                                      name="call_type"
                                      type="radio"
                                      value="phone"
                                      id="PHONE_CALL"
                                      onClick={handleChange}
                                      checked={
                                        formData?.call_type?.toLowerCase() ===
                                        "phone"
                                      }
                                    />

                                    <Label
                                      className="form-label mx-2 mb-0"
                                      for="PHONE_CALL"
                                    >
                                      Phone Call
                                    </Label>
                                  </div>
                                  <p>
                                    Select this option for appointments
                                    conducted over a phone call
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <input
                                      name="call_type"
                                      type="radio"
                                      value="online video"
                                      id="ONLINE_VIDEO"
                                      onClick={handleChange}
                                      checked={
                                        formData?.call_type?.toLowerCase() ===
                                        "online video"
                                      }
                                    />

                                    <Label
                                      className="form-label mx-2 mb-0"
                                      for="ONLINE_VIDEO"
                                    >
                                      Online Video
                                    </Label>
                                  </div>
                                  <p>
                                    Opt for this option for appointments
                                    conducted through online video conferencing
                                  </p>
                                </div>
                              </div>
                            </Col>
                            {formData?.call_type === "in person" && (
                              <Col xl={12} md={12} className="mt-3">
                                <div className="form-custom-white-inputs">
                                  <Label className="form-label">
                                    Location{" "}
                                  </Label>
                                  <Tooltip
                                    placement="right"
                                    title={
                                      <div className="invoice-list-fsize-14 ">
                                        Select the the location names for this
                                        appointment type
                                      </div>
                                    }
                                    arrow
                                  >
                                    <i
                                      className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                    ></i>
                                  </Tooltip>
                                  <div>
                                    <Select
                                      className="input"
                                      onChange={handleLocationChange}
                                      name="location"
                                      placeholder="Select city"
                                      value={formData?.location}
                                      options={locationGroups}
                                      isCloseMenuOnSelect={false}
                                    />
                                  </div>
                                </div>
                                {emptyFields.includes("location") &&
                                  !formData.location && (
                                    <span className="text-danger mx-1">
                                      Please add appointment type location
                                    </span>
                                  )}
                              </Col>
                            )}
                            <Col xl={12} md={12} className="mt-3">
                              <Label className="form-label">Access</Label>
                              <Tooltip
                                placement="right"
                                title={
                                  <div className="invoice-list-fsize-14 ">
                                    <p>
                                      Public appointments will appear on your
                                      Client Scheduling Page.
                                    </p>
                                    <p>
                                      Private appointments are hidden from your
                                      main scheduling page link and only
                                      available for admins on the account to
                                      schedule from the admin side.
                                    </p>
                                  </div>
                                }
                                arrow
                              >
                                <i
                                  className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                                ></i>
                              </Tooltip>
                              <div className="mt-2">
                                <input
                                  name="access"
                                  type="radio"
                                  id="private"
                                  value="private"
                                  onClick={handlePrivateChangeChange}
                                  checked={formData?.private}
                                />

                                <Label
                                  className="form-label mx-2 mb-0"
                                  for="private"
                                >
                                  Private
                                </Label>
                              </div>
                              <div className="mt-2">
                                <input
                                  name="access"
                                  type="radio"
                                  id="private"
                                  value="public"
                                  onClick={handlePrivateChangeChange}
                                  checked={!formData?.private}
                                />

                                <Label
                                  className="form-label mx-2 mb-0"
                                  for="private"
                                >
                                  Public
                                </Label>
                              </div>
                            </Col>

                            <Col xl={12} md={12} className="mt-5">
                              <Button
                                className="btn btn-primary"
                                onClick={CreateAppointmentTypeFun}
                                disabled={isSubmitted}
                              >
                                {id ? "Update" : "Create"} Appointment Type
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateAppointmentType
