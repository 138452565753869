import { gql } from "@apollo/client";

export const GET_COUNSELORS = gql`
query Calendars($argumentsInput: ArgumentsInput) {
  getCalendarLegend(argumentsInput: $argumentsInput) {
    data {
      calendars {
        name
        id
        item_id
      }
    }
    code
    status
    message
  }
}`

export const GET_USER_ORG = gql`
query GetUserOrganizations($argumentsInput: ArgumentsInput) {
  getUserOrganizations(argumentsInput: $argumentsInput) {
    data {
      user {
        organizations {
          organization_id
          token
          organization {
            logo
            name
            favicon
            sub_domain
            domain
            id
          }
        }
      }
    }
  }
}`