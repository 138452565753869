import React, { useCallback, useState, useEffect } from "react"
import { Button, Col, Row } from "reactstrap"
import classNames from "classnames"
import useStyles from "../../Style"
import ExpandedReceiptRow from "./ExpandedReceiptRow"
import Table from "../../../../components/Common/Table"
import AllSelect from "./AllSelect"
import GenerateReceipt from "./GenerateReceipt"

function ClientAppointments(props) {
  const {
    state,
    totalCount,
    setState,
    handleShowPreviewTab,
    setPreviewTabData,
    getAppointmentListFun,
  } = props
  const classes = useStyles()
  const [selectedClientList, setSelectedClientList] = useState([])

  //************************** Table Data ***************************** */

  const columns = [
    {
      text: " ",
      dataField: "client_id",
      headerAttrs: {
        hidden: true,
      },
      formatter: allCheckedRowFun,
      style: {
        width: 50,
      },
    },
    {
      text: "Client Name",
      dataField: "first_name",
      headerAttrs: {
        hidden: true,
      },
      formatter: clientNameFun,
      style: {
        minWidth: 250,
      },
    },
    {
      text: "Export",
      dataField: "last_name",
      headerAlign: "end",
      align: "end",
      formatter: exportFun,
      headerAttrs: {
        hidden: true,
      },
      style: {
        minWidth: 150,
      },
    },
  ]

  function exportFun(value, row, index, field) {
    return (
      <GenerateReceipt
        rowValue={value}
        row={row}
        index={index}
        field={field}
        setPreviewTabData={setPreviewTabData}
        handleShowPreviewTab={handleShowPreviewTab}
      />
    )
  }

  function clientNameFun(value, row, index, field) {
    return (
      (row?.first_name ? row?.first_name + " " + row?.last_name : row?.name) +
      ` (${row?.count ? row?.count : 0})`
    )
  }

  function allCheckedRowFun(value, row, index, field) {
    return (
      <AllSelect
        rowValue={value}
        row={row}
        index={index}
        field={field}
        selectedClientList={selectedClientList}
      />
    )
  }

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
    }
  }

  const handleExpandColumnRenderer = ({ expanded }) => {
    if (expanded) {
      return (
        <div className={classes.btnAlign}>
          <button type="button" className="btn btn-sm btn-primary">
            <i className="uil-angle-up"></i>
          </button>
        </div>
      )
    }
    return (
      <div className={classes.btnAlign}>
        <Button type="button" className="btn btn-sm btn-primary">
          <i className="uil-angle-down"></i>
        </Button>
      </div>
    )
  }

  const expandRow = {
    renderer: row => (
      <ExpandedReceiptRow
        parentRow={row}
        setSelectedClientList={setSelectedClientList}
      />
    ),
    onExpand: handleOnExpand,
    expandColumnRenderer: handleExpandColumnRenderer,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: "right",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return " "
      }
      return " "
    },
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded) {
        return (
          <div className={classes.btnAlign}>
            <button type="button" className="btn btn-sm btn-primary">
              <i className="uil-angle-up"></i>
            </button>
          </div>
        )
      }
      return (
        <div className={classes.btnAlign}>
          <Button type="button" className="btn btn-sm btn-primary">
            <i className="uil-angle-down"></i>
          </Button>
        </div>
      )
    },
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setState(s => ({ ...s, page: page, limit: sizePerPage }))
    getAppointmentListFun(page, sizePerPage)
  }

  return (
    <Row>
      <Col sm={12} md={12} lg={12} xl={12}>
        <div
          className={classNames(
            classes.hideTableHeader,
            "table-custom-redesign"
          )}
        >
          <Table
            data={state.data}
            page={state.page}
            sizePerPage={state.limit}
            totalSize={totalCount}
            onTableChange={handleTableChange}
            columns={columns}
            noDataIndication={"No Data Found"}
            loading={false}
            keyField={"client_id"}
            isHover={false}
            isStriped={false}
            isBordereded={false}
            expandRow={expandRow}
          />
        </div>
      </Col>
    </Row>
  )
}

export default ClientAppointments
