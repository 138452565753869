import React, { useCallback, useEffect, useState } from "react"
import { GET_ORGANIZATION, GET_TRANSACTION } from "./gql/Gql"
import { useLazyQuery } from "@apollo/client"
import CustomSelect from "../../components/CustomSelect"
import Table from "../../components/Common/Table"
import Tooltip from "@material-ui/core/Tooltip"
import style from "../../../src/pages/Client/style.module.scss"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import AppointmentInfo from "../Appointments/AppointmentInfo"
import { useSelector } from "react-redux"
import { convertUTCToLocal } from "../../functions/functions"
import moment from "moment"
import { debounce } from "lodash"

const Transaction = ({username}) => {

  const userData = useSelector(state => state.Login?.user?.user)
  const [modal, setModal] = useState(undefined)
  const [idModal, setIdModal] = useState(undefined)
  const [organizationsList, setOrganizationsList] = useState([])
  const [organizationOption, setOrganizationOption] = useState([])
  const [selectedOrg, setSelectedOrg] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(20)
  let currentUrl = new URL(window.location.href)
  const selectedPage = Number(new URLSearchParams(location.search).get("page"))
  const selectedOrgId = Number(new URLSearchParams(location.search).get("org"))
  const [state, setState] = useState({
    page: selectedPage ? selectedPage : 1,
    limit: 20,
    sort: "created_at",
    sort_dir: "DESC",
    search:"",
  })
  const [getOrganizations, getOrganizationsList] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: "no-cache",
    }
  )

  const getOrganizationsFun = (limit, page, search) => {
    setIsLoading(true)
    getOrganizations({
      variables: {
        argumentsInput: {
          limit: limit || 20,
          page: page || 1,
          search: search || "",
        },
      },
    })
  }

  useEffect(() => {
    if (
      getOrganizationsList?.data?.getOrganizations?.data &&
      getOrganizationsList?.data?.getOrganizations?.data?.organizations
    ) {
      const orgList =
        getOrganizationsList?.data?.getOrganizations?.data?.organizations
      setOrganizationsList(orgList)
      if (selectedOrgId) {
        const selectedOrg = orgList.filter(elem => {
          return elem.id === selectedOrgId
        })
        setSelectedOrg(selectedOrg)
      }

      setIsLoading(false)
    }
  }, [getOrganizationsList.data])

  useEffect(() => {
    if (userData.role.id === 1) {
      const storedId = localStorage.getItem("orgId")
      const orgId = storedId ? parseInt(atob(JSON.parse(storedId))) : null
      setSelectedOrg({
        id: orgId,
      })
      getTransactionsFun(20, 1, orgId)
    }
  }, [])

  useEffect(() => {
    if (organizationsList.length > 0) {
      const option = organizationsList?.map(item => {
        return {
          key: item?.organization_identifier,
          id: item?.id,
          value: item?.organization_identifier,
          name: item?.name,
          label: item?.name,
        }
      })
      setOrganizationOption(option)
    }
  }, [organizationsList])

  const handleChangeOrganization = e => {
    currentUrl.searchParams.set("page", 1)
    currentUrl.searchParams.set("org", e.id)
    window.history.replaceState({}, document.title, currentUrl.href)
    setIsLoading(true)
    setSelectedOrg(e)
    setState(s => ({
      ...s,
      page: 1,
      limit: 20,
    }))
    getTransactionsFun(state.limit, state.page, e.id)
  }

  function appointmentActionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="">
        <Button
          className="btn btn-primary cursor-pointer"
          onClick={() => setModal(row?.appointment)}
        >
          View
        </Button>
      </div>
    )
  }

  function idActionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="">
        <Button
          className="btn btn-primary cursor-pointer"
          onClick={() => setIdModal(row)}
        >
          <i className="far fa-id-card"></i>
        </Button>
      </div>
    )
  }

  const clearOrg = () => {
    setState(s => ({
      ...s,
      page: 1,
      limit: 20,
      search:""
    }))
    setSelectedOrg()
    getTransactionsFun(20, 1, undefined)
    currentUrl.searchParams.delete("org")
    currentUrl.searchParams.delete("page")
    window.history.replaceState({}, document.title, currentUrl.href)
  }

  const columns = [
    {
      text: "#",
      dataField: "id",
      formatter: (cell,row,rowIndex)=>(
        <>
        <p className="m-0">{rowIndex + 1 + state.limit * (state.page - 1)}</p>
        </>
      ),
    },
    {
      text: "Clients",
      dataField: "appointment",
      align: "left",
      headerAlign: "left",
      formatter: (cell, row) => (
        <>
          <span>
            {row?.appointment?.client?.first_name}{" "}
            {row?.appointment?.client?.last_name}
          </span>
        </>
      ),
    },
    {
      text: <span>Client Rate <Tooltip
      className={style.tooltip}
      placement="right"
      title={
        <>
        Counsellor Appointment type rate + Payment type rate (Excluding GST)
        </>
      }
      arrow
    >
      <i
        className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
      ></i>
    </Tooltip></span>,
      dataField: "appointment",
      align: "left",
      headerAlign: "left",
      formatter: (cell, row) => (
        <>
          <span>${row?.client_rate}</span>
        </>
      ),
    },
    {
      text: <span>GST Rate <Tooltip
      className={style.tooltip}
      placement="right"
      title={
        <>
        5% on Client Rate
        </>
      }
      arrow
    >
      <i
        className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
      ></i>
    </Tooltip></span>,
      dataField: "fixed_tax_on_appointment",
      align: "left",
      headerAlign: "left",
      formatter: (cell, row) => (
        <>
          <span>${parseFloat(row?.fixed_tax_on_appointment).toFixed(2)}</span>
        </>
      ),
    },

    {
      text: <span>Client Total Rate <Tooltip
      className={style.tooltip}
      placement="right"
      title={
        <>
        Client Rate + GST Rate
        </>
      }
      arrow
    >
      <i
        className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
      ></i>
    </Tooltip></span>,
      dataField: "amount_total",
      align: "left",
      headerAlign: "left",
      formatter: (cell, row) => (
        <>
          <span>${row?.amount_total}</span>
        </>
      ),
    },
    {
      text: <span>Platform Fees  <Tooltip
      className={style.tooltip}
      placement="right"
      title={
        <>
          Platform fees + Tax on platform fees + 0.3 Stripe fees + Stripe percentage tax
        </>
      }
      arrow
    >
      <i
        className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
      ></i>
    </Tooltip></span>,
      dataField: "platform_fee_percentage",
      align: "left",
      headerAlign: "left",
      formatter: (cell, row) => (
        <>
          <span>${row?.platform_fee_percentage}</span>
        </>
      ),
    },
    {
      text: <span>Paid to Clinic <Tooltip
      className={style.tooltip}
      placement="right"
      title={
        <>
          Client rate + GST - Platform fees
        </>
      }
      arrow
    >
      <i
        className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
      ></i>
    </Tooltip></span>,
      dataField: "seller_amount",
      align: "left",
      headerAlign: "left",
      formatter: (cell, row) => (
        <>
          <span>${row?.seller_amount}</span>
        </>
      ),
    },
    {
      text: "Date",
      dataField: "created_at",
      style: {
        minWidth: 120,
      },
      formatter: (cell, row) => {
        const date = convertUTCToLocal(row.created_at)
        return <p className="m-0">{moment(date).format("DD-MMM-yyyy")}</p>
      },
      headerStyle: {
        cursor: "pointer",
      },
      sort: true,
    },
    
    {
      text: "Mode",
      align: "left",
      headerAlign: "left",
      dataField: "mode",
      formatter: (cell, row) => {
        return (
          <div>
            <span className="text-capitalize">{row?.mode}</span>
          </div>
        )
      },
    },
    {
      text: "Status",
      align: "left",
      headerAlign: "left",
      dataField: "status",
      formatter: (cell, row) => {
        return (
            <span className="text-capitalize">{row?.status}</span>
        )
      },
    },
        {
      dataField: "id",
      text: "IDs",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: idActionEvent,
    },
    {
      dataField: "appointment",
      text: "Appointments",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: appointmentActionEvent,
    },
  ]

  const handleCopyClick = async text => {
    try {
      await navigator.clipboard.writeText(text)
    } catch (error) {
      console.error("Unable to copy to clipboard", error)
    }
  }

  const [transactions, setTrasaction] = useState([])
  const [getTransactions, getTransactionsList] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: "no-cache",
  })

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {

    setState(s => ({
      ...s,
      page: page,
      limit: sizePerPage,
      sort: sortField ? sortField : "created_at",
      sort_dir: sortOrder ? sortOrder.toUpperCase() : "DESC",
    }))

    getTransactionsFun(sizePerPage, page, selectedOrg && selectedOrg[0]?.id, sortField, sortOrder, state.search)
    currentUrl.searchParams.set("page", page)
    window.history.replaceState({}, document.title, currentUrl.href)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const getTransactionsFun = (limit, page, orgId, sortField, sortOrder, search) => {
    setIsLoading(true)
    getTransactions({
      variables: {
        argumentsInput: {
          limit: limit || 20,
          page: page || 1,
          organization_id: orgId || undefined,
          search: search || "",
          sort: sortField ? sortField : "created_at",
          sort_dir: sortOrder ? sortOrder.toUpperCase() : "DESC",
          client_id:username ? parseInt(username) : undefined,
        },
      },
    })
  }

  useEffect(() => {
    if (userData.role.id !== 1) {
      getTransactionsFun(
        state.limit,
        selectedPage ? parseInt(selectedPage) : state.page,
        selectedOrgId ? parseInt(selectedOrgId) : undefined
      )
      getOrganizationsFun()
    }
  }, [])

  useEffect(() => {
    if (
      getTransactionsList.data?.getTransactions?.data &&
      getTransactionsList.data?.getTransactions?.data?.transactions
    ) {
      const allTransaction =
        getTransactionsList.data?.getTransactions?.data?.transactions
      setTrasaction(allTransaction)
      setTotalCount(getTransactionsList.data?.getTransactions?.data?.totalCount)
      setIsLoading(false)
    }
  }, [getTransactionsList.data])
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      getTransactionsFun(state.limit, state.page, selectedOrg && selectedOrg[0]?.id, state.sort, state.sort_dir, searchValue)
    }, 1000),
    []
  )
const handleSearch = (e) => {
  setState(s => ({
    ...s,
    search:e.target.value
  }))
}
  const onChangeSearch = e => {
    handleSearch(e)
    debouncedSearch(e.target.value)
  }

  

  return (
    <React.Fragment>
    <div className={`page-content ${username ? "p-0" : ""}`}>
        <div className="container-fluid">
          {!username && <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center">
            <div className="p-2">
              <h4 className="card-title-main">Organization Transactions</h4>
              <h5 className="card-para-main">List of all the Transactions.</h5>
            </div>
          </div>}
          <Row className="mg-top-default-ui">
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody className="table-custom-redesign">
                    <Row className="d-flex align-items-center">
                    {!username && <Col md={4}>
                    <input
                        className="form-control default-search-input"
                        value={state.search}
                        type="text"
                        placeholder="Search Client Name"
                        onChange={onChangeSearch}
                      />
                      </Col>}
                  {userData.role.id !== 1 && (
                    <>
                      <Col md={4}>
                        <CustomSelect
                          className="input default-search-input"
                          placeholder="Select Organization"
                          options={organizationOption}
                          onChange={handleChangeOrganization}
                          value={selectedOrgId || selectedOrg}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          className="btn btn-sm btn-primary"
                          onClick={clearOrg}
                        >
                          Clear
                        </Button>
                      </Col>
                     </>
                  )}

                    </Row>
                  <Table
                    data={transactions}
                    page={state.page}
                    sizePerPage={state.limit}
                    totalSize={totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isLoading}
                    isStriped={false}
                    isHover={false}
                    isBordereded={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal isOpen={modal} size="lg">
          <ModalHeader toggle={() => setModal(undefined)} tag="h4">
            Appointment Details
          </ModalHeader>
          <ModalBody>
            <AppointmentInfo
              id={parseInt(modal?.id)}
              isModel={modal}
              setModal={setModal}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={idModal} size="lg">
          <ModalHeader toggle={() => setIdModal(undefined)} tag="h4">
            Transaction IDs
          </ModalHeader>
          <ModalBody>
            <Card className="light-green-bg-card">
              <CardBody>
                <div>
                  <CardTitle className="h4 m-0 ">
                    <h5 className="m-0 card-title-appointments">
                      Transaction IDs
                    </h5>
                  </CardTitle>
                </div>
                <hr style={idModal ? { marginTop: "8px" } : null} />
                <div className="d-flex align-items-center justify-content-between my-2 ">
                  <p className="h5">Stripe Transaction ID : </p>
                  <p className="h6">
                    {idModal?.payment_intent_id}{" "}
                    <i
                      className="fas fa-copy mx-2 cursor-pointer"
                      onClick={() =>
                        handleCopyClick(idModal?.payment_intent_id)
                      }
                    />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between my-2 ">
                  <p className="h5">Stripe Customer ID : </p>
                  <p className="h6">
                    {idModal?.stripe_customer_id}{" "}
                    <i
                      className="fas fa-copy mx-2 cursor-pointer"
                      onClick={() =>
                        handleCopyClick(idModal?.stripe_customer_id)
                      }
                    />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between my-2 ">
                  <p className="h5">Session ID : </p>
                  <p className="h6">
                    {idModal?.session_id}
                    <i
                      className="fas fa-copy mx-2 cursor-pointer"
                      onClick={() => handleCopyClick(idModal?.session_id)}
                    />
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between my-2 ">
                  <p className="h5">Confirmation method : </p>
                  <p className="h6 text-capitalize mx-2">
                  {idModal?.confirmation_method ? idModal?.confirmation_method : "Manual"}
                  </p>
                </div>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Transaction
