import React from "react"
import Select from "react-select"
import styles from "./CustomSelect.scss"

const CustomSelect = ({
  onChange,
  options,
  value,
  placeholder,
  className,
  error,
  touched,
  isMulti,
  onBlur,
  disabled,
  required,
  isborder = false,
}) => {
  const defaultValue = (options, value) => {
    return options && value ? options.find(option => option.id === value) : ""
  }

  return (
    <div className={className}>
      <Select
        placeholder={placeholder ? placeholder : "Select..."}
        className={`${
          error && touched ? "invalidSelect" : ""
        } custom-date-picker-style pointer`}
        value={defaultValue(options, value)}
        onChange={value => {
          onChange(value)
        }}
        isDisabled={disabled ? true : false}
        onBlur={onBlur}
        options={options}
        isMulti={isMulti ? true : false}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        required={required}

        styles={{
          control: (provided, state) => ({
            ...provided,
            border: isborder && "none",
            borderRadius: "8px",
            minHeight: "41px",
            border: "1px solid #ced4da",
            cursor: "pointer"
          }),
        }}
      />
      {error && touched && (
        <div className="candidate-multi-select-error">{error}</div>
      )}
    </div>
  )
}

export default CustomSelect
