import React, { useState, useEffect } from "react"
import Table from "../../../components/Common/Table"
import "react-toastify/dist/ReactToastify.css"
import { capitalizeFirstLetter } from "../../../functions/functions"
import { useHistory } from "react-router-dom"

const ClientList = ({ appointments }) => {
  let history = useHistory()
  const [clientList, setClientList] = useState([])

  const columns = [
    {
      text: "ID",
      dataField: "id",
      width: 150,
    },
    {
      text: "Client Name",
      dataField: "client",
      width: 270,
      formatter: fullNameFun,
    },
    {
      text: "Email Address",
      dataField: "email",
      width: 270,
    },
    {
      text: "Mobile Number",
      dataField: "mobile_number",
      width: 270,
    },
    {
      text: "Status",
      dataField: "is_active",
      width: 270,
      headerAlign: "center",
      align: "center",
      formatter: statusFun,
    },
  ]

  function fullNameFun(value, row, index, field) {
    const firstName = row?.first_name ? row?.first_name : " "
    const lastName = row?.last_name ? row?.last_name : " "
    return (
      capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName)
    )
  }

  function statusFun(value, row, index, field) {
    return value === true ? "Active" : "Deactivated"
  }

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="d-flex justify-content-left">
        <a
          onClick={() => handleShowDetailsClick(row)}
          className="px-3 edit-btn cursor-pointer"
        >
          View
        </a>
      </div>
    )
  }

  const handleShowDetailsClick = row => {
    history.push(`/clients/${row?.id}`)
  }

  return (
    <div className="table-custom-redesign">
      <Table
        data={appointments}
        columns={columns}
        noDataIndication={"No Data Found"}
        isPagination={false}
        isStriped={false}
        isHover={false}
        isBordereded={false}
      />
    </div>
  )
}

export default ClientList
