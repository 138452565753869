import { useLazyQuery, useMutation } from "@apollo/client"
import React, { useEffect, useState } from "react"
import {
  GET_ORGANIZATION,
  GET_PERMISSIONS,
  GET_PERMISSIONS_LIST,
  SAVE_PERMISSION,
} from "./gql/Gql"
import {
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
} from "reactstrap"
import { useParams, Link, useHistory } from "react-router-dom"
import style from "../UserRole/style.module.scss"
import CustomSelect from "../../components/CustomSelect"
import Loader from "../../components/Common/Loader"
import Tooltip from "@material-ui/core/Tooltip"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const EditUserPermission = () => {
  const history = useHistory()
  let { roleId } = useParams()
  const decodedId =roleId && atob(roleId);
  roleId = decodedId;
  const [organizationOption, setOrganizationOption] = useState([])
  const [selectedOrg, setSelectedOrg] = useState()
  const [organizationsList, setOrganizationsList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [moduleList, setModuleList] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)

  const [GetAllPermission, GetAllPermissionList] = useLazyQuery(
    GET_PERMISSIONS,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getOrganizations, getOrganizationsList] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [getPermissionOrgWise, getPermissionOrgWiseList] = useLazyQuery(
    GET_PERMISSIONS_LIST,
    {
      fetchPolicy: "no-cache",
    }
  )

  const getOrganizationsFun = (limit, page, search) => {
    setIsLoading(true)
    getOrganizations({
      variables: {
        argumentsInput: {
          limit: limit || 20,
          page: page || 1,
          search: search || "",
        },
      },
    })
  }

  useEffect(() => {
    GetAllPermission({
      variables: {
        roleId: Number(roleId) ,
      },
    })
    getOrganizationsFun()
  }, [])

  useEffect(() => {
    if (
      getOrganizationsList?.data?.getOrganizations?.data &&
      getOrganizationsList?.data?.getOrganizations?.data?.organizations
    ) {
      const orgList =
        getOrganizationsList?.data?.getOrganizations?.data?.organizations
      setOrganizationsList(orgList)
      setIsLoading(false)
    }
  }, [getOrganizationsList.data])

  useEffect(() => {
    if (
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data &&
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role &&
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role
        ?.rolePermissions
    ) {
      const moduleList =
        getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role
          ?.rolePermissions
      const slugArray = moduleList.map(permission => permission.method.slug)
      setIsLoading(false)
      setCheckedItems(slugArray)
    } else {
      setIsLoading(false)
      setCheckedItems([])
    }
  }, [getPermissionOrgWiseList.data])

  useEffect(() => {
    if (
      GetAllPermissionList?.data &&
      GetAllPermissionList?.data?.getAllExistingPermission?.data &&
      GetAllPermissionList?.data?.getAllExistingPermission?.data
        ?.allAvailablePermission
    ) {
      const allModuleList =
        GetAllPermissionList?.data?.getAllExistingPermission?.data
          ?.allAvailablePermission
      setModuleList(allModuleList)
    }
  }, [GetAllPermissionList.data])

  useEffect(() => {
    if (organizationsList.length > 0) {
      const option = organizationsList?.map(item => {
        return {
          key: item?.id,
          id: item?.id,
          value: item?.id,
          name: item?.name,
          label: item?.name,
        }
      })

      setOrganizationOption(option)
      setIsLoading(false)
      setSelectedOrg(option[0]?.id)
      getPermissionOrgWise({
        variables: {
          argumentsInput: {
            role_id: Number(roleId),
            organization_id: option[0]?.id,
          },
        },
      })
    }
  }, [organizationsList])

  const handleChangeOrganization = e => {
    setIsLoading(true)
    setSelectedOrg(e.id)
    getPermissionOrgWise({
      variables: {
        argumentsInput: {
          role_id: Number(roleId),
          organization_id: e.id,
        },
      },
    })
  }

  const handleCheckboxChange = item => {
    if (checkedItems.includes(item)) {
      setCheckedItems(checkedItems.filter(checkedItem => checkedItem !== item))
    } else {
      setCheckedItems([...checkedItems, item])
    }
  }

  const [savePermissionMutation, savePermissionMutationRes] =
    useMutation(SAVE_PERMISSION)

  const savePermission = () => {
    setIsDisabled(true)
    savePermissionMutation({
      variables: {
        permissionInput: {
          methods: checkedItems,
          role_id: Number(roleId),
          id: Number(roleId),
          organization_id: selectedOrg,
        },
      },
    })
  }
  
  const checkAll = () => {
    if(moduleList.length === checkedItems.length){
      setCheckedItems([])
    }else{
      setCheckedItems(moduleList)
    }
  }

  useEffect(() => {
    if (
      savePermissionMutationRes?.data &&
      savePermissionMutationRes?.data?.addPermission?.status
    ) {
      toast.success("Permission updated successfully ")
      setTimeout(() => {
        setIsDisabled(false)
      }, 1500)
      getPermissionOrgWise({
        variables: {
          argumentsInput: {
            role_id: Number(roleId),
            organization_id: selectedOrg,
          },
        },
      })
    } else {
      setIsDisabled(false)
    }
  }, [savePermissionMutationRes.data])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    Edit Role permission
                  </h4>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/user-permission">Role Permission</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">Edit Role permission</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody>
                  <div className={`col col-md-3`}>
                    <CustomSelect
                      className="input default-search-input"
                      placeholder="Select Organization"
                      options={organizationOption}
                      onChange={handleChangeOrganization}
                      value={selectedOrg}
                    />
                  </div>

                  <div className={`col col-md-12 mt-4`}>
                    <h4>
                      Modules{" "}
                      <Tooltip
                        className={style.tooltip}
                        placement="right"
                        title=" List of all modules. Select the module that you want to show for this role's login"
                        arrow
                      >
                        <i
                          className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                        ></i>
                      </Tooltip>
                    </h4>
                    <div className="d-flex align-items-center my-2">
                      <Input
                        name={`check-all`}
                        type="checkbox"
                        className="form-check-input"
                        onClick={() => checkAll()}
                        checked={moduleList.length === checkedItems.length}
                      />
                      <label
                        name={`check-all`}
                        className={`mx-2 mt-1 form-check-label ${
                          moduleList.length === checkedItems.length
                            ? "font-weight-bold"
                            : ""
                        }`}
                      >
                        {moduleList.length === checkedItems.length ? "Uncheck All" : "Check All"}
                      </label>
                    </div>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="d-flex flex-row flex-wrap">
                          {moduleList.map((item, index) => (
                            <div
                              key={index}
                              className="form-check moduleListStyle"
                            >
                              <Input
                                name={`${item}`}
                                type="checkbox"
                                className="form-check-input"
                                onClick={() => handleCheckboxChange(item)}
                                checked={checkedItems.includes(item)}
                              />
                              <label
                                htmlFor={`${item}`}
                                className={`form-check-label ${
                                  checkedItems.includes(item)
                                    ? "font-weight-bold"
                                    : ""
                                }`}
                              >
                                {item}
                              </label>
                            </div>
                          ))}
                        </div>

                        <div className="mt-5">
                          <Button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              savePermission()
                            }}
                            disabled={isDisabled}
                          >
                            Save Permission
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditUserPermission
