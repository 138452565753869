import { gql } from "@apollo/client"

export const GET_USERS = gql`
  query GetUsers($argumentsInput: ArgumentsInput) {
    getUsers(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        users {
          first_name
          last_name
          name
          id
          email
          created_at
          is_active
          organizations {
            created_at
            role{
              id
              role_name
            }
            organization {
              name
              id
              favicon
            }
          }
          mobile_number
          role {
            role_name
            id
          }
        }
        totalCount
      }
    }
  }
`

export const GET_CATEGORIES = gql`
  query GetCategories($search: String) {
    getCategories(search: $search) {
      data {
        categories
      }
    }
  }
`
export const GET_APPOINTMENT_BY_ID = gql`
  query GetAppointmentTypeById($getAppointmentTypeByIdId: Int!) {
    getAppointmentTypeById(id: $getAppointmentTypeByIdId) {
      data {
        appointmentType {
          id
          name
          description
          duration
          price
          break_time
          appointment_type_has_calendars {
            price
            calendar_id
            calendar {
              id
              name
            }
          }
          color
          category
          image_url
          location {
            id
            name
          }
          calendars {
            id
            name
          }
          confirmation_message
          private
          call_type
        }
      }
    }
  }
`

export const GET_BAD_FIT_CLIENTS = gql`
  query GetBadFitClients($argumentsInput: ArgumentsInput) {
    getBadFitClients(argumentsInput: $argumentsInput) {
      data {
        totalCount
        badFitUsers {
          id
          user_id
          organization_id
          bad_fit_reason
          status
          deleted_at
          good_fit_date
          created_at
          updated_at
          calendar_id
          last_appointment_date
          calendar {
            id
            user {
              first_name
              last_name
            }
          }
          user {
            first_name
            last_name
            mobile_number
            email
            id
            remarks {
              id
              created_at
              description
            }
          }
          organization {
            name
            id
          }
          is_grey
        }
      }
    }
  }
`

export const CREATE_EDIT_APPOINTMENT_TYPE = gql`
  mutation CreateAndUpdateAppointmentType(
    $createAppointmentTypeInput: CreateAppointmentTypeInput
  ) {
    createAndUpdateAppointmentType(
      createAppointmentTypeInput: $createAppointmentTypeInput
    ) {
      code
      message
      status
    }
  }
`
export const UPDATE_BAD_FIT = gql`
  mutation UpdateBadFit($argumentsInput: ArgumentsInput!) {
    updateBadFit(argumentsInput: $argumentsInput) {
      code
      message
      status
    }
  }
`

export const GET_CLIENT_APPOINTMENT = gql`
  query GetAppointments($argumentsInput: ArgumentsInput) {
    getAppointments(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        appointments_arr {
          appointment {
          id
          organization{
            favicon
            name
            id
          }
          appointment_id
          first_name
          last_name
          email
          phone
          canceled
          timezone
          utc_datetime
          end_time
          duration
          is_feedback_submitted
          is_client_feedback_submitted
          is_counsellor_feedback_submitted
          appointment_has_feedback {
            id
            from {
              role {
                id
                role_name
                __typename
              }
              __typename
            }
            to {
              role {
                id
                role_name
                __typename
              }
              __typename
            }
            __typename
          }
          therapist_calendar {
            id
            name
            timezone
            email
          }
          type_of_appointment {
            id
            name
            color
            call_type
            google_address
            __typename
          }
          __typename
        }
      }
        totalCount
        __typename
      }
      __typename
    }
  }
`

export const CREATE_EDIT_USER = gql`
  mutation CreateAdminUser($createUserInput: createUserInput!) {
    createAdminUser(createUserInput: $createUserInput) {
      code
      status
      message
      data {
        user {
          email
          mobile_number
        }
      }
    }
  }
`

export const GET_USER_BY_USER = gql`
query GetUserByEmail($argumentsInput: ArgumentsInput) {
  getUserByEmail(argumentsInput: $argumentsInput) {
    code
    message
    status
    data {
      is_exist
      user {
      id
      email
      name
      first_name
      last_name
      mobile_number
      }
    }
  }
}`

export const GET_USER = gql`
  query Query($argumentsInput: ArgumentsInput) {
    getUser(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        upcomingAppointments
        totalAppointments
        daysSinceLastAppointment
        user {
          id
          name
          first_name
          last_name
          email
          gender
          zipcode
          pronouns
          province
          city
          emergency_contact_number
          date_of_birth
          country
          address
          is_email_confirmed
          organizations {
            role{
              id
              role_name
            }
            organization {
              name
              id
            }
          }
          calendar {
            name
          }
          mobile_number
          age
          role {
            id
            role_name
            slug
          }
        }
      }
    }
  }
`

// export const MODULES = gql`
//     query getModules($argumentsInput:ArgumentsInput){
//         getModules(argumentsInput:$argumentsInput){
//             code
//             status
//             message
//             data{
//                 modules{
//                     id
//                     name
//                     description
//                     slug
//                     created_at
//                     methods{
//                         id
//                         name
//                         slug
//                     }
//                 }
//             }
//         }
//     }
// `

export const GET_USER_ROLE = gql`
  query GetRoles($argumentsInput: ArgumentsInput) {
    getRoles(argumentsInput: $argumentsInput) {
      code
      status
      data {
        roles {
          id
          created_at
          updated_at
          deleted_by
          created_by
          role_name
          slug
          is_active
        }
        totalCount
      }
    }
  }
`

export const GET_USER_ATTACHMENTS = gql`
query GetUserAttachments($argumentsInput: ArgumentsInput) {
  getUserAttachments(argumentsInput: $argumentsInput) {
    code
    message
    status
    data {
      user {
        attachments {
          id
          description
          url
        }
      }
    }
  }
}
`
export const ADD_USER_ATTACHMENTS = gql`
mutation AddUserAttachment($attachment: ArgumentsInput!) {
  addUserAttachment(attachment: $attachment) {
    code
    message
    status
  }
}
`
export const REMOVE_USER_ATTACHMENTS = gql`
mutation RemoveAttachment($attachmentInput: ArgumentsInput!) {
  removeAttachment(attachmentInput: $attachmentInput) {
    code
    message
    status
  }
}
`
export const DELETE_USER = gql`
  mutation Delete($argumentsInput: ArgumentsInput!) {
    deleteUser(argumentsInput: $argumentsInput) {
      code
      status
      message
    }
  }
`

export const CREATE_CALENDAR = gql`
  mutation CreateCalendar($createCalendarInput: UpdateCalendarInput!) {
    CreateCalendar(createCalendarInput: $createCalendarInput) {
      data {
        calendar {
          id
        }
      }
      code
      message
      status
    }
  }
`
export const UPDATE_CALENDER_APPOINTMENT = gql`
  mutation UpdateCounsellorPay($updateCalendarInput: UpdateCalendarInput!) {
    updateCounsellorPay(updateCalendarInput: $updateCalendarInput) {
      message
    }
  }
`
export const EDIT_APPOINTMENT_TYPE_PRICE = gql`
  mutation EditAppointementTypePrice(
    $createAppointmentTypeInput: CreateAppointmentTypeInput
  ) {
    editAppointementTypePrice(
      createAppointmentTypeInput: $createAppointmentTypeInput
    ) {
      code
      message
      status
    }
  }
`

export const GetCategoryTypeAppointmentTypes = gql`
  query CategoryTypeAppointmentTypes($argumentsInput: ArgumentsInput) {
    getAllAppointmentsTypeByCategory(argumentsInput: $argumentsInput) {
      code
      data {
        totalCount
        categoryTypeAppointmentTypes {
          category
          appointmentTypes {
            id
            name
          }
        }
      }
    }
  }
`
export const GetCounsellorAvailabilities = gql`
  query GetCounsellorAvailabilities($argumentsInput: ArgumentsInput) {
    getCounsellorAvailabilities(argumentsInput: $argumentsInput) {
      data {
        is_closed_all
        avilabilitiesMultiple {
          day
          availabilities {
            id
            calendar_id
            week_day
            start_time
            end_time
            is_closed
            room {
              id
              name
              location {
                name
              }
            }
          }
        }
      }
      code
      message
      status
    }
  }
`

export const CreateAvailability = gql`
  mutation CreateAvailability(
    $createAvailabilityInput: CreateAvailabilityInputArr!
  ) {
    createAvailability(createAvailabilityInput: $createAvailabilityInput) {
      calendar_id
      end_time
      id
      start_time
      week_day
      is_closed
    }
  }
`
export const UpdateRegularAvailability = gql`
  mutation UpdateRegularAvailability(
    $updateAvailabilityInput: CreateAvailabilityInputArr!
  ) {
    updateRegularAvailability(
      updateAvailabilityInput: $updateAvailabilityInput
    ) {
      code
      message
      status
      data {
        availability {
          id
          userId
          calendar_id
          week_day
          start_time
          end_time
        }
      }
    }
  }
`
export const GetMonthlyAvailabilities = gql`
  query GetMonthlyAvailabilities($argumentsInput: ArgumentsInput) {
    getMonthlyAvailabilities(argumentsInput: $argumentsInput) {
      code
      data {
        overAllMonthWise {
          date
          start_time
          end_time
          is_closed
        }
        #   categoryMonthWise {
        #     day
        #     monthWiseAvailablities {
        #       date
        #       end_time
        #       start_time
        #       is_closed
        #     }
        #   }
      }
    }
  }
`

export const getMonthWiseAvailabilitiesCalendarView = gql`
  query getMonthWiseAvailabilitiesCalendarView(
    $argumentsInput: ArgumentsInput
  ) {
    getMonthWiseAvailabilitiesCalendarView(argumentsInput: $argumentsInput) {
      data {
        avilabilitiesCalendarView {
          date
          is_closed_all_for_whole_day
          availabilities {
            id
            is_closed
            start_time
            end_time
            room {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GetCounsellorSchedulingSetting = gql`
  query GetCounsellorSchedulingSetting($argumentsInput: ArgumentsInput) {
    getCounsellorSchedulingSetting(argumentsInput: $argumentsInput) {
      data {
        scheduling {
          id
          advance_hours
          future_days
          appointment_per_slot
          min_change_notice_hours
          calendar_id
          accept_maximum_appointments
          max_hours_per_day
          max_number_per_day
          max_number_per_week
        }
      }
    }
  }
`

export const GetCounsellorBlockedTime = gql`
  query GetCounsellorBlockedTime($argumentsInput: ArgumentsInput) {
    getCounsellorBlockedTime(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        overrides {
          id
          end_time
          month
          override_date
          start_time
          week_day
          is_blocked
        }
      }
    }
  }
`

export const GET_ROOMS_DROPDOWN = gql`
  query GetAvailableRoomsDropdown(
    $getAvailableRoomsDropdown: CreateAvailabilityInput
  ) {
    getAvailableRoomsDropdown(
      getAvailableRoomsDropdown: $getAvailableRoomsDropdown
    ) {
      data {
        rooms {
          id
          name
          location {
            name
          }
        }
      }
    }
  }
`

export const UpdateStoreSchedulingLimit = gql`
  mutation UpdateStoreSchedulingLimit(
    $updateStoreSchedulingLimit: CreateAvailabilityInput!
  ) {
    updateStoreSchedulingLimit(
      updateStoreSchedulingLimit: $updateStoreSchedulingLimit
    ) {
      message
      status
      code
    }
  }
`

export const DeleteBlockedTime = gql`
  mutation DeleteBlockedTime($deleteBlockedTimeId: Int!) {
    deleteBlockedTime(id: $deleteBlockedTimeId) {
      code
      message
      status
    }
  }
`

export const SetOverrideForDate = gql`
  mutation SetOverrideForDate($setOverrideForDate: CreateAvailabilityInputArr) {
    setOverrideForDate(setOverrideForDate: $setOverrideForDate) {
      message
      status
      code
    }
  }
`
export const CREATE_NOTE = gql`
mutation CreateOrUpdateNote($updateAppointmentInput: UpdateAppointmentInput!) {
  createOrUpdateNote(updateAppointmentInput: $updateAppointmentInput) {
    code
    message
    status
  }
}
`

export const GetUserRemarks = gql`
  query GetUserRemarks($argumentsInput: ArgumentsInput) {
    getUserRemarks(argumentsInput: $argumentsInput) {
      data {
        user {
          appointments {
            id
            notes
            utc_datetime
            appointment_notes {
              id
              interventions
              plans
              topics
              created_by{
                first_name
                last_name
              }
            }
            appointmentHasForms {
              id
              name
              forms {
                uid
                id
                value
                name
                field_id
              }
            }
            type_of_appointment{
              id
              name
            }
          }
          remarks {
            id
            description
            created_at
            created_by{
              first_name
              last_name
            }
            updated_at
          }
        }
      }
    }
  }
`

export const AddUserRemarks = gql`
  mutation AddUserRemark($remark: ArgumentsInput!) {
    addUserRemark(remark: $remark) {
      message
      code
      status
    }
  }
`

export const RemoveUserRemarks = gql`
  mutation RemoveRemark($remarkInput: ArgumentsInput!) {
    removeRemark(remarkInput: $remarkInput) {
      code
      message
      status
    }
  }
`
