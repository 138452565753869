import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import style from "./style.module.scss"

const CounsellorProfile = ({ user, setActiveTab }) => {
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null

  return (
    <>
      <Row className={`${style.cardcenter} counselor-pay-section pt-1 mt-5`}>
        <Col md={12}>
          <Card className="light-green-bg-card-white  m-0 px-3 border-light-shadow">
            <CardBody>
              <Row>
                <Col
                  md={12}
                  className="d-flex align-items-center justify-content-between"
                >
                  <p className="p-0 m-0 h5 font-weight-bold ">
                    Basic Information
                  </p>
                </Col>
                <Col md={8} className="mt-4">
                  <Row>
                    <Col md={12} className="">
                      <div className="d-flex flex-wrap align-items-center my-2 w-100 justify-content-between ">
                        <div className="d-flex align-items-center ">
                          <span
                            className={`mdi mdi-account icon-default-light-bg-primary-green ${
                              orgId == 1 ? "text-dark" : "text-primary"
                            } m-0 h2`}
                          ></span>
                          <p className="h6 p-0 m-0 mx-3 text-capitalize">
                            {user?.user?.first_name} {user?.user?.last_name}
                          </p>
                        </div>
                        <div>
                          <span
                            className={`small ${
                              orgId == 1 ? "text-dark" : "text-primary"
                            }`}
                          >
                            Role
                          </span>
                          <p className="h6 ">Counsellor</p>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-center my-2  w-100 justify-content-between ">
                        <div className="d-flex flex-wrap align-items-center">
                          <span
                            className={`mdi mdi-email icon-default-light-bg-primary-green ${
                              orgId == 1 ? "text-dark" : "text-primary"
                            } m-0 h2`}
                          ></span>
                          <p className="h6 p-0 m-0 mx-3 ">{user?.email}</p>
                        </div>
                      </div>
                      {user?.user?.mobile_number && (
                        <div className="d-flex align-items-center my-2  w-100 justify-content-between">
                          <div className="d-flex align-items-center  ">
                            <span
                              className={`mdi mdi-phone icon-default-light-bg-primary-green ${
                                orgId == 1 ? "text-dark" : "text-primary"
                              } m-0 h2`}
                            ></span>
                            <p className="h6 p-0 m-0 mx-3 text-capitalize">
                              {user?.user?.mobile_number}
                            </p>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12} className="mt-3">
          <Card className="light-green-bg-card-white border-light-shadow m-0 px-3">
            <CardBody>
              <Row>
                <Col
                  md={12}
                  className="d-flex align-items-center justify-content-between"
                >
                  <p className="p-0 m-0 h5 font-weight-bold ">
                    Additional Information
                  </p>
                </Col>
                <Col md={8} className="mt-4">
                  <Row>
                    <Col md={12} className="">
                      <div className="d-flex align-items-center my-2 w-100 justify-content-between ">
                        <div className="d-flex align-items-center ">
                          <div>
                            <span
                              className={` small ${
                                orgId == 1 ? "text-dark" : "text-primary"
                              }`}
                            >
                              Calendar Name
                            </span>
                            <p className="h6 ">{user?.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center my-2 w-100 justify-content-between ">
                        <div className="d-flex align-items-center ">
                          <div>
                            <span
                              className={`small ${
                                orgId == 1 ? "text-dark" : "text-primary"
                              }`}
                            >
                              Timezone
                            </span>
                            <p className="h6 ">
                              {user?.timezone ? (
                                user?.timezone
                              ) : (
                                <>
                                  <span className="mdi mdi-cancel"></span>{" "}
                                  {"Not Available"}{" "}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CounsellorProfile
