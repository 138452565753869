import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  FormGroup,
  Button,
  Spinner,
} from "reactstrap"
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  CardTitle,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { ToastContainer, toast } from "react-toastify"
import logoNew from "../../assets/images/nfcld.png"
import loginAccent from "../../assets/images/accent-login.svg"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import { useHistory } from "react-router-dom"

// action
import { registerUser, apiError } from "../../store/actions"

// Redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
// import images
// import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logo from "../../assets/images/logo.png"
import CustomSelect from "../../components/CustomSelect"
import classnames from "classnames"
import PhoneNoField from "./PhoneNoField"
import { CREATE_ADMIN_USER, REGISTER_USER, VARIFY_EMAIL } from "./gql/loginGql"
import { useLazyQuery, useMutation } from "@apollo/client"

import { SEND_OTP } from "./gql/loginGql"

import { loginUser } from "../../store/actions"


// icons
import { MdEmail , MdCall , MdOutlineRefresh} from "react-icons/md";

const Register = props => {
  const dispatch = useDispatch()

  const [isSpinner, setIsSpinner] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [validEmail, setValidEmail] = useState("")
  const [inProgress, setInProgress] = useState(false)

  const [verifyEmail, setVerifyEmail] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isGoToDashboard, setIsGoToDashboard] = useState(false)
  const [currentComponent, setCurrentComponent] = useState("emailLogin") // Initially, show the EmailForm
  const [email, setEmail] = useState("")
  const [getOtp, setGetOtp] = useState("")
  const [otpLoading, setOtpLoading] = useState(false)
  const [CreateAcc, setCreateAcc] = useState(false)
  const [isRegisteredUser, setIsRegisteredUser] = useState(false)

  const history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)

  const storedData = localStorage.getItem("orgData")
  const customColor = storedData ? JSON.parse(storedData) : null

  const notRegistered = localStorage.getItem("notRegistered")
  const notRegisteredEmail = notRegistered ? JSON.parse(notRegistered) : null
  useEffect(() => {
    if(notRegisteredEmail){
      setEmail(notRegisteredEmail)
      setCurrentComponent("otpRequested")
    }
  }, [])
  useEffect(() => {
    const removeDefaultEmail = () => {
      localStorage.removeItem("notRegistered")
    };

    window.addEventListener('beforeunload', removeDefaultEmail);

    return () => {
      window.removeEventListener('beforeunload', removeDefaultEmail);
    };
  }, []);
  if (customColor?.id !== 1) {
     if (customColor?.favicon) {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement("link")
        link.rel = "icon"
        document.getElementsByTagName("head")[0].appendChild(link)
      }
      link.href = customColor?.favicon
      document.title = customColor?.name
     }
  }

  const defaultColors = {
    "--background-color": "#f3f7f2",
    "--text-color-dark": "#3f4e50",
    "--text-color-inactive-links": "#172426",
    "--dark-green": "#153c27",
    "--btn-disabled": "#2e4338",
    "--green-color-light": "#84a17d",
    "--green-color-bg-card": "#e2ecdf",
    "--bs-body-font-size": "1rem",
    "--bs-nav-link-color": "var(--dark-green)",
    "--bs-nav-link-hover-color": "var(var(--green-color-light))",
    "--bs-btn-color": "#fff",
    "--bs-btn-bg": "#153c27",
    "--bs-btn-border-color": "#153c27",
    "--bs-btn-hover-color": "#fff",
    "--bs-btn-hover-bg": "#84a17d",
    "--bs-btn-hover-border-color": "#84a17d",
    "--bs-btn-focus-shadow-rgb": "95, 106, 187",
    "--bs-btn-active-color": "#fff",
    "--bs-btn-active-bg": "#153c27",
    "--bs-btn-active-border-color": "#153c27",
    "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
    "--bs-btn-disabled-color": "#fff",
    "--bs-btn-disabled-bg": "#2e4338",
    "--bs-btn-disabled-border-color": "#2e4338",
    "--bs-card-green-light": "#cddbc9",
  }

  const customColors = customColor?.primary_color
    ? {
        "--dark-green": customColor?.primary_color,
        "--text-color-dark": customColor?.font_color,
        "--text-color-inactive-links": customColor?.font_color,
        "--dark-green": customColor?.primary_color,
        "--btn-disabled": customColor?.primary_color,
        "--green-color-light": customColor?.secondary_color,
        "--green-color-bg-card": customColor?.primary_color,
        "--bs-nav-link-color": customColor?.primary_color,
        "--bs-nav-link-hover-color": customColor?.secondary_color,
        "--bs-btn-color": "#fff",
        "--bs-btn-bg": customColor?.primary_color,
        "--bs-btn-border-color": customColor?.secondary_color,
        "--bs-btn-hover-color": "#fff",
        "--bs-btn-hover-bg": customColor?.secondary_color,
        "--bs-btn-hover-border-color": customColor?.primary_color,
        "--bs-btn-focus-shadow-rgb": "95, 106, 187",
        "--bs-btn-active-color": " #fff",
        "--bs-btn-active-bg": customColor?.primary_color,
        "--bs-btn-active-border-color": customColor?.primary_color,
        "--bs-btn-active-shadow": `inset 0 3px 5px ${customColor?.primary_color}`,
        "--bs-btn-disabled-color": "#fff",
        "--bs-btn-disabled-bg": customColor?.primary_color,
        "--bs-btn-disabled-border-color": customColor?.secondary_color,
        "--bs-card-green-light": customColor?.secondary_color,
      }
    : {}

  const allColors = { ...defaultColors, ...customColors }

  Object.entries(allColors).forEach(([property, value]) => {
    document.documentElement.style.setProperty(property, value)
  })

  const validate = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed

    enableReinitialize: true,

    initialValues: {
      email: "",
      getOtp: "",
      firstName: "",
      phone: "",
      lastName: "",
      age: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),
      getOtp: Yup.string().required("Please enter your OTP"),
      firstName: Yup.string().required("Please enter your first name"),
      gender: Yup.string().required("Please select your gender"),
      age: Yup.string().required("Please enter your age"),
    }),
    onSubmit: values => {
      setErrorMsg()
      setIsSubmitted(true)
      verifyEmailFunc(values.email)
    },
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: validEmail,
      otp: "",
      firstName: "",
      phone: "",
      lastName: "",
      age: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),
      getOtp: Yup.string().required("Please enter your otp"),
      firstName: Yup.string()
      .required("Please enter your first name")
      .min(3, "A minimum of 3 characters is required for the first name field")
      .max(50, "The system restricts the first name input to a maximum of 50 characters"),
      lastName: Yup.string()
      .required("Please enter your last name")
      .min(3, "A minimum of 3 characters is required for the last name field")
      .max(50, "The system restricts the last name input to a maximum of 50 characters"),
      age: Yup.string().required("Please enter your age"),
    }),
    onSubmit: values => {
      let mobile_number = "+" + values?.mobile_number
      mobile_number = mobile_number?.replaceAll("-", "")
      mobile_number = mobile_number?.replaceAll(" ", "")
      mobile_number = mobile_number?.replaceAll("(", "")
      mobile_number = mobile_number?.replaceAll(")", "")
      setIsGoToDashboard(true)
      userRegisterFun(
        values.email,
        values?.otp.trim(),
        values.password,
        mobile_number
      )
    },
  })

  useEffect(() => {
    dispatch(apiError(""))
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  }, [dispatch])

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const [CreateAdminUser, CreateAdminUserData] = useMutation(
    CREATE_ADMIN_USER,
    { fetchPolicy: "no-cache" }
  )
  function verifyEmailFunc(email) {
    CreateAdminUser({
      variables: {
        createUserInput: {
          email: email,
          role_id: 2,
        },
      },
    })
  }

  
  React.useEffect(() => {
    setIsSpinner(false)
    setIsSubmitted(false)
    if (
      CreateAdminUserData.data &&
      CreateAdminUserData.data.createAdminUser &&
      CreateAdminUserData.data.createAdminUser.code == 200 &&
      CreateAdminUserData.data.createAdminUser.status == true
    ) {
      setactiveTab("2")
      setVerifyEmail(true)
      setValidEmail(CreateAdminUserData.data.createAdminUser.data.user.email)
    } else {
      if (
        CreateAdminUserData.data &&
        CreateAdminUserData.data.createAdminUser
      ) {
        setErrorMsg(CreateAdminUserData.data.createAdminUser.message)
      }
    }
  }, [CreateAdminUserData.data])

  //***************************************************************** */

  const [userRegister, response] = useLazyQuery(REGISTER_USER, {
    fetchPolicy: "no-cache",
  })
  function userRegisterFun(username, otp, password, mobile_number) {
    userRegister({
      variables: {
        loginInput: {
          username: username,
          otp: otp,
          password: password,
          mobile_number: mobile_number?.length > 9 ? mobile_number?.replaceAll(" ", "").replaceAll("-", "") : null,
        },
      },
    }).then(res => {
      setIsGoToDashboard(false)
    })
  }

  React.useEffect(() => {
    setIsSpinner(false)
    // console.log(response)
    if (
      response.data &&
      response.data.signIn &&
      response.data.signIn.code == 200
    ) {
      dispatch(loginUser(response.data.signIn.data, props.history))
      // setPermission(response.data.signIn.data.permission)
    } else {
      if (
        response.data &&
        response.data.signIn &&
        response.data.signIn.code == 401
      ) {
        toast.error(response.data.signIn.message)
        // setErrorMsg(response.data.signIn.message)
      }
    }
  }, [response.data])

  const [sendOTP, { loading, data }] = useMutation(SEND_OTP)
  const handleSendOTP = () => {
    sendOTP({ variables: { createUserInput: { email } } })
  }

  useEffect(() => {
    if (data?.sendEmailOtp?.data && data?.sendEmailOtp?.code === 200) {
      if (data.sendEmailOtp.data.is_registered === true) {
        setIsRegisteredUser(true)
        toast.success(data.sendEmailOtp.message)
        setCurrentComponent("otpRequested")
        localStorage.removeItem("notRegistered")
      } else if (data.sendEmailOtp.data.is_registered === null) {
        toast.error("Please Enter Email Properly ")
      } else {
        toast.success(data.sendEmailOtp.message)
        setCurrentComponent("otpRequested")
        localStorage.removeItem("notRegistered")
      }
    } else if (data?.sendEmailOtp?.data && data?.sendEmailOtp?.code === 201) {
      toast.error(data.sendEmailOtp.message)
    } else if (!data?.sendEmailOtp?.data && data?.sendEmailOtp?.code === 400) {
      toast.error(data.sendEmailOtp.message)
    }else{
      toast.error(data?.sendEmailOtp?.message)
    }
  }, [data])

  //******************************************************************************************** */

  function OtpRequested() {
    const inputRefs = [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ]
    // Function to handle input change and move focus to the next/previous input field
    const handleInputChange = (e, index) => {
      const inputValue = e.target.value

      // Use a regular expression to allow only numeric input
      const numericInput = inputValue.replace(/\D/g, "") // Remove non-numeric characters

      // Update the input field value
      e.target.value = numericInput

      // If the input value is not empty and there's a next input field, focus on it
      if (numericInput !== "" && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus()
      } else if (numericInput === "" && index > 0) {
        // If the input value is empty (backspace) and there's a previous input field, focus on it
        inputRefs[index - 1].current.focus()
      }
    }
    return (
      <div className="mb-2 form-div-login-page">
        <h3 className="enterOTPheading">Enter OTP</h3>
        <p className="para-desc-login">
          We’ve sent you a 6-digit one time password on your e-mail address.
        </p>

        <div className="d-flex align-items-center gap-devices-login-otp mt-4">
          {inputRefs.map((ref, index) => (
            <input
              key={index}
              name={`otp${index}`}
              ref={ref}
              className="form-control default-redesign-input text-center"
              inputMode="numeric"
              type="text"
              maxLength={1}
              onChange={e => handleInputChange(e, index)}
            />
          ))}
        </div>
      </div>
    )
  }

  function UserInputs() {
    return (
      <>
        <div className="mb-3 form-div-login-page">
          <Label className="form-label form-label-login">First Name</Label>
          <Input
            name="firstName"
            className="form-control default-redesign-input"
            placeholder="Please enter your First name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.firstName}
            invalid={
              validation.touched.firstName && validation.errors.firstName
                ? true
                : false
            }
          />
          {/* validation.setFieldValue("firstName", e.target.value) */}
          {validation.touched.firstName && validation.errors.firstName ? (
            <FormFeedback type="invalid">
              {validation.errors.firstName}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3 form-div-login-page">
          <Label className="form-label form-label-login">Phone Number</Label>
          <Input
            name="phone"
            className="form-control default-redesign-input"
            placeholder="Please enter your phone number"
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            onBlur={validation.handleBlur}
            value={validation.phone}
            invalid={
              validation.touched.phone && validation.errors.phone ? true : false
            }
          />
          {validation.touched.phone && validation.errors.phone ? (
            <FormFeedback type="invalid">
              {validation.errors.phone}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3 form-div-login-page">
          <Label className="form-label">Gender</Label>
          <CustomMultiSelect
            className="gender"
            onChange={e => handelGender(e)}
            placeholder="Select your gender"
            options={genderList}
            isCloseMenuOnSelect={true}
            value={selectedGender}
            invalid={
              validation.touched.gender && validation.errors.gender
                ? true
                : false
            }
          />
          {validation.touched.gender && validation.errors.gender ? (
            <FormFeedback type="invalid">
              {validation.errors.gender}
            </FormFeedback>
          ) : null}
        </div>

        {/* handelAge */}
        <div className="mb-3 form-div-login-page">
          <Label className="form-label">Age</Label>
          <CustomMultiSelect
            className="age"
            onChange={e => handelAge(e)}
            placeholder="Please select your age"
            options={ageList}
            isCloseMenuOnSelect={true}
            value={validation.age}
            invalid={
              validation.touched.age && validation.errors.age ? true : false
            }
          />
          {validation.touched.age && validation.errors.age ? (
            <FormFeedback type="invalid">{validation.errors.age}</FormFeedback>
          ) : null}
        </div>
      </>
    )
  }

  const [VarifyEmail, VarifyEmailData] = useMutation(VARIFY_EMAIL, {
    fetchPolicy: "no-cache",
  })

  const [CteateAccount, CteateAccountData] = useMutation(VARIFY_EMAIL, {
    fetchPolicy: "no-cache",
  })

  function verifyOtpFunc(email, otp) {
    VarifyEmail({
      variables: {
        createUserInput: {
          email: email,
          password: otp,
        },
      },
    })
  }

  const handleNextButtonClick = () => {
    if (getOtp.length === 6) {
      if (email && getOtp) {
        verifyOtpFunc(email, getOtp)
        setOtpLoading(true)
      } else {
        toast.error("Please Enter Otp")
      }
    } else {
      toast.error("Please Enter Proper Otp")
    }
  }

  function createAccountFunc(email, otp, firstName, phone, lastName, age) {
    CteateAccount({
      variables: {
        createUserInput: {
          email: email,
          password: otp,
          first_name: firstName,
          age: age,
          last_name: lastName,
          mobile_number: phone?.length > 8 ? phone : null,
        },
      },
    })
  }

  const handleCreateAccount = () => {
    const firstName = validation.values.firstName
    const phone = validation.values.phone
    const lastName = validation.values.lastName
    const age = validation.values.age
    if (email && getOtp && firstName.length >= 3 && firstName.length <= 50 && lastName.length >= 3 && lastName.length <= 50 && age) {
      setCreateAcc(true)
      createAccountFunc(email, getOtp, firstName, phone, lastName, age)
    } else {
      toast.error("Please enter details properly")
    }
  }

  React.useEffect(() => {
    if (
      VarifyEmailData.data &&
      VarifyEmailData?.data?.verifyEmailOtp &&
      VarifyEmailData?.data?.verifyEmailOtp.code == 200 &&
      VarifyEmailData?.data?.verifyEmailOtp.status == true
    ) {
      if (VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0].token) {
        localStorage.setItem(
          "token",
          VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0].token
        )
      }
      setOtpLoading(false)
      if (isRegisteredUser) {
        localStorage.setItem(
          "token",
          VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0].token
        )
        localStorage.setItem(
          "authUser",
          JSON.stringify(VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0])
        )
        const moduleList =
          VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0]?.role
            ?.rolePermissions
        const slugArray = moduleList?.map(
          permission => permission?.method?.slug
        )
        if (slugArray) {
          localStorage.setItem("userPermission", JSON.stringify(slugArray))
        }
        toast.success(VarifyEmailData?.data?.verifyEmailOtp.message)
        setTimeout(() => {
          history.push("/dashboard")
        }, 2000)
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        setCurrentComponent("UserInputs")
      }
    } else {
      setOtpLoading(false)
      toast.error(VarifyEmailData?.data?.verifyEmailOtp.message)
    }
  }, [VarifyEmailData.data])

  React.useEffect(() => {
    if (
      CteateAccountData.data &&
      CteateAccountData?.data?.verifyEmailOtp &&
      CteateAccountData?.data?.verifyEmailOtp.code == 200 &&
      CteateAccountData?.data?.verifyEmailOtp.status == true
    ) {
      if (CteateAccountData?.data?.verifyEmailOtp?.data?.users[0].token) {
        localStorage.setItem(
          "token",
          CteateAccountData?.data?.verifyEmailOtp?.data?.users[0].token
        )
        localStorage.setItem(
          "authUser",
          JSON.stringify(VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0])
        )
      }
      setCreateAcc(false)
      toast.success(CteateAccountData?.data?.verifyEmailOtp.message)
      setTimeout(() => {
        history.push("/dashboard")
      }, 2000)
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } else {
      setCreateAcc(false)
      toast.error(CteateAccountData?.data?.verifyEmailOtp.message)
    }
  }, [CteateAccountData.data])

  const handleEmail = e => {
    const lowercaseEmail = e.target.value.toLowerCase().trim();
    validation.setFieldValue("email", lowercaseEmail)
    setEmail(lowercaseEmail)
  }

  const handleOtp = e => {
    const enteredOtp = e.target.value

    if (/^\d{0,6}$/.test(enteredOtp)) {
      setGetOtp(enteredOtp)
    }
  }
  const handelAge = e => {
    const enteredAge = e.target.value

    if (/^\d{0,3}$/.test(enteredAge)) {
      validation.setFieldValue("age", enteredAge)
    }
  }

  useEffect(() => {
    if (userData) {
      return history.push("/dashboard")
    }
  }, [userData])

  const storedId = localStorage.getItem("orgId")
  const orgId = storedId ? parseInt(atob(JSON.parse(storedId))) : null

  return (
    <React.Fragment>
      <ToastContainer />
      {userData ? (
        <></>
      ) : (
        <div className="account-pages  login-account-pages">
          <Container>
            <Row className="height-100vh align-items-center justify-content-center w-100 margin-zero-small">
              <Col
                xl={6}
                lg={9}
                md={12}
                className="align-items-center justify-content-space-between"
              >
                <Row className="align-items-center justify-content-center">
                  <Col md={8} lg={9} xl={9}>
                    <Card className="custom-card d-flex align-items-center p-4">
                      <CardBody className="p-2 w-75">
                        <div className="text-center">
                          <div className="text-center">
                          {customColor?.logo && (
                              <img
                                src={customColor?.logo}
                                alt="Logo"
                                className="logo-center"
                              />
                            )}
                            {orgId === 1 && (
                              <img
                                src={logoNew}
                                alt="Logo"
                                className="logo-center"
                              />
                            )}
                          </div>
                        </div>
                        <div className="mt-4 margin-top-login-page">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              // validation.handleSubmit()
                              return false
                            }}
                          >
                            {errorMsg ? (
                              <Alert color="danger">{errorMsg}</Alert>
                            ) : null}

                            {currentComponent === "emailLogin" ? (
                              <div className="mb-2 form-div-login-page">
                                <Label className="form-label form-label-login">
                                  Enter email to register
                                </Label>
                                <Input
                                  name="email"
                                  className="form-control default-redesign-input"
                                  placeholder="Enter your email address"
                                  type="text"
                                  onChange={e => handleEmail(e)}
                                  onBlur={validation.handleBlur}
                                  value={email}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            ) : null}

                            {currentComponent === "otpRequested" ? (
                              <>
                                <Label className="form-label form-label-login">
                                  Enter your OTP
                                </Label>
                                <Input
                                  name="getOtp"
                                  className="form-control default-redesign-input"
                                  placeholder="Enter your OTP"
                                  type="text"
                                  inputMode="numeric"
                                  pattern="[0-9]*"
                                  onChange={e => handleOtp(e)}
                                  onBlur={validation.handleBlur}
                                  value={getOtp}
                                  invalid={
                                    validation.touched.getOtp &&
                                    validation.errors.getOtp
                                      ? true
                                      : false
                                  }
                                />
                              </>
                            ) : null}

                            {currentComponent === "UserInputs" ? (
                              <>
                                <div className="mb-3 form-div-login-page">
                                  <Label className="form-label form-label-login">
                                    First Name
                                  </Label>
                                  <Input
                                    name="firstName"
                                    className="form-control default-redesign-input"
                                    placeholder="Please enter your first name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstName}
                                    invalid={
                                      validation.touched.firstName &&
                                      validation.errors.firstName
                                        ? true
                                        : false
                                    }
                                  />
                                  {/* validation.setFieldValue("firstName", e.target.value) */}
                                  {validation.touched.firstName &&
                                  validation.errors.firstName ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.firstName}
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3 form-div-login-page">
                                  <Label className="form-label form-label-login">
                                    Last Name
                                  </Label>
                                  <Input
                                    name="lastName"
                                    className="form-control default-redesign-input"
                                    placeholder="Please enter your last name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastName}
                                    invalid={
                                      validation.touched.lastName &&
                                      validation.errors.lastName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.lastName &&
                                  validation.errors.lastName ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.lastName}
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3 form-div-login-page">
                                  <Label className="form-label form-label-login">
                                    Phone Number
                                  </Label>

                                  <PhoneNoField
                                    className="phoneinpute"
                                    name="phone"
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone}
                                    onChange={value => {
                                      if (!value.startsWith("+")) {
                                        value = "+" + value
                                      }
                                      validation.setFieldValue("phone", value)
                                    }}
                                    invalid={
                                      validation.touched.phone &&
                                      validation.errors.phone
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.phone &&
                                  validation.errors.phone ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.phone}
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3 form-div-login-page">
                                  <Label className="form-label form-label-login">
                                    Age
                                  </Label>
                                  <Input
                                    name="age"
                                    className="form-control default-redesign-input"
                                    placeholder="Please enter your age"
                                    onChange={e => handelAge(e)}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.age}
                                    type="text"
                                    inputMode="numeric"
                                    invalid={
                                      validation.touched.age &&
                                      validation.errors.age
                                        ? true
                                        : false
                                    }
                                    min="1" // Set the minimum age to 1
                                    max="99" // Set the maximum age to 100
                                  />
                                  {validation.touched.age &&
                                  validation.errors.age ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.age}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </>
                            ) : null}

                            {!inProgress ? (
                              <>
                               
                                <div className="mb-2 d-flex align-items-stretch  gap-1">
                                  {/* {currentComponent === "emailLogin" ? (
                                  ""
                                ) : (
                                  <button className="btn back-icon-fa btn-cancel-light-green-bg">
                                    <i
                                      className="waves-effect align-middle  mdi color-dark-green mdi-chevron-left  font-size-18 "
                                      id="backArrow"
                                      onClick={() => history.goBack()}
                                    />
                                  </button>
                                )} */}
                                  {currentComponent === "emailLogin" && (
                                    <button
                                      className=" btn btn-primary w-100 waves-effect waves-light mt-3 "
                                      onClick={handleSendOTP}
                                      disabled={loading}
                                      type="submit"
                                    >
                                      {currentComponent === "emailLogin" &&
                                      loading
                                        ? "Processing..."
                                        : "Next"}
                                    </button>
                                  )}
                                  {currentComponent === "otpRequested" && (
                                    <button
                                      className=" btn btn-primary w-100 waves-effect waves-light mt-4"
                                      onClick={handleNextButtonClick}
                                      type="submit"
                                      disabled={otpLoading}
                                    >
                                      {currentComponent === "otpRequested" &&
                                      otpLoading
                                        ? "Processing..."
                                        : "Next"}
                                    </button>
                                  )}
                                  {currentComponent === "UserInputs" && (
                                    <button
                                      className="button-default-login-page btn btn-primary w-100 waves-effect waves-light "
                                      type="submit"
                                      onClick={handleCreateAccount}
                                      disabled={CreateAcc}
                                    >
                                      {currentComponent === "UserInputs" &&
                                      CreateAcc
                                        ? "Processing..."
                                        : "Create Acoount"}
                                    </button>
                                  )}
                                </div>
                              </>
                            ) : (
                              <div className="my-4">
                                <Button
                                  className="mt-4 button-default-login-page btn btn-primary w-100 waves-effect waves-light"
                                  type="submit"
                                  disabled
                                >
                                  <Spinner
                                    type="grow"
                                    className="me-1 align-middle spinner-grow-sm "
                                    color="light"
                                  />
                                  Processing...
                                </Button>
                              </div>
                              
                            )}
                            <div>
                                  {currentComponent === "otpRequested" ? (
                                    <p
                                      className="bottom-cta-register-account "
                                      onClick={() => {
                                        handleSendOTP()
                                      }}
                                    >
                                      
                                      {loading ? (
                                        "Sending OTP..."
                                      ) : (
                                        <Link>Request new OTP <MdOutlineRefresh /></Link>
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                            <div className="mb-3 mt-4 form-div-login-page">
                              <p className="text-center create-account-link">
                                Already have an account?{" "}
                                <Link to="/login">Login</Link>
                              </p>
                            </div>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}
export default withRouter(Register)

Register.propTypes = {
  registerUser: PropTypes.func,
  // registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}
