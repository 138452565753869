import React, { useEffect, useState } from "react"
import Table from "../../../components/Common/Table"
import "react-toastify/dist/ReactToastify.css"
import style from "../style.module.scss"
import { meetingType } from "../../../functions/functions"

const CounselorList = ({ appointmentTypes }) => {
  const [appointmentTypeList, setAppointmentTypeList] = useState([])

  useEffect(() => {
    setAppointmentTypeList([...appointmentTypes])
  }, [appointmentTypes])

  const columns = [
    {
      text: "#",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "Name",
      dataField: "name",
      width: 270,
    },
    {
      text: "Address",
      dataField: "address",
      width: 270,
      formatter: addressFormatter,
    },
    {
      text: "Duration (in mins)",
      dataField: "duration",
      width: 270,
      headerAlign: "center",
      align: "center",
    },
    {
      text: "Price",
      dataField: "price",
      width: 270,
      headerAlign: "center",
      align: "center",
      formatter: (value, row, index, field) => {
        return `$${value}`
      },
    },

    {
      text: "Color",
      dataField: "color",
      width: 270,
      headerAlign: "center",
      align: "center",
      formatter: colorFormatter,
    },
    {
      text: "Meeting Type",
      dataField: "call_type",
      width: 270,
      headerAlign: "center",
      align: "center",
      formatter: remoteFormatter,
    },
  ]

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="m-0">{rowIndex + 1}</p>
      </>
    )
  }

  function addressFormatter(cell, row, rowIndex, formatExtraData) {
    return cell === null || cell === undefined ? (
      <div className="text-center">-</div>
    ) : (
      cell
    )
  }

  function colorFormatter(value, row, index, field) {
    return (
      <>
        <div
          style={{ background: row.color }}
          className={style.colorstyle}
        ></div>
      </>
    )
  }

  function remoteFormatter(value, row, index, field) {
    return <p className="m-0">{value ? meetingType(value) : ""}</p>
  }

  return (
    <div className="table-custom-redesign">
      <Table
        data={appointmentTypeList}
        columns={columns}
        noDataIndication={"No Data Found"}
        isPagination={false}
        isStriped={false}
        isHover={false}
        isBordereded={false}
      />
    </div>
  )
}

export default CounselorList
