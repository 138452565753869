import { useLazyQuery, useMutation } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { GET_USER_ATTACHMENTS, REMOVE_USER_ATTACHMENTS } from "./gql/userGql"
import { Card, CardBody, Col, Row } from "reactstrap"
import Loader from "../../components/Common/Loader"
import AttachmentModal from "./AttachmentModal"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import { toast } from "react-toastify"
import style from "./style.module.scss"
import DeleteModal from "../../components/Common/DeleteModal"
const UserAttachments = ({ username }) => {
  const [getUserAttachments, getUserAttachmentsRes] = useLazyQuery(
    GET_USER_ATTACHMENTS,
    { fetchPolicy: "no-cache" }
  )
  const [attachmentsList, setAttachmentsList] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [modal, setModal] = useState(false)

  const getUserAttachmentsFun = () => {
    setSpinner(true)
    getUserAttachments({
      variables: {
        argumentsInput: {
          id: parseInt(username),
        },
      },
    })
  }
  useEffect(() => {
    if (username) {
      getUserAttachmentsFun()
    }
  }, [])

  useEffect(() => {
    if (
      getUserAttachmentsRes?.data &&
      getUserAttachmentsRes?.data?.getUserAttachments?.code === 200 &&
      getUserAttachmentsRes?.data?.getUserAttachments?.status
    ) {
      const allAttachments =
        getUserAttachmentsRes?.data?.getUserAttachments?.data?.user?.attachments
      setAttachmentsList(allAttachments)
      setSpinner(false)
    } else {
      setSpinner(false)
    }
  }, [getUserAttachmentsRes?.data])

  const addAttachmentToggle = () => {
    setModal(!modal)
  }

  const [RemoveAttachment, RemoveAttachmentRes] = useMutation(
    REMOVE_USER_ATTACHMENTS,
    { fetchPolicy: "no-cache" }
  )

  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [deleteId, setDeleteId] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const deletAttachment = id => {
    setDeleteModalShow(!deleteModalShow)
    if (id) {
      setDeleteId(id)
    }
  }

  const deleteAttachmentFun = () => {
    setInProgress(true)
    RemoveAttachment({
      variables: {
        attachmentInput: {
          id: deleteId,
        },
      },
    })
  }

  useEffect(() => {
    if (
      RemoveAttachmentRes.data &&
      RemoveAttachmentRes.data?.removeAttachment?.code === 200 &&
      RemoveAttachmentRes.data?.removeAttachment?.status
    ) {
      toast.success(RemoveAttachmentRes.data?.removeAttachment?.message)
      getUserAttachmentsFun()
      setDeleteModalShow(false)
      setInProgress(false)
    }
  }, [RemoveAttachmentRes.data])

  return (
    <>
      <div className="d-flex justify-content-end mt-4 w-100 ">
        <button
          className="btn btn-primary d-flex flex-row"
          onClick={() => {
            addAttachmentToggle()
          }}
        >
          <div className="me-2">
            <i className="bx bx-plus-medical  p-1 font-size-14" />
          </div>
          Add File
        </button>
      </div>
      <div>
        {spinner ? (
          <div className="mainLoader">
            <Loader />
          </div>
        ) : attachmentsList?.length > 0 ? (
          attachmentsList.map((elem, index) => (
            <Card
              key={index}
              className="light-green-bg-card-white m-0 px-3 my-3"
            >
              <CardBody>
                <Row>
                  <Col
                    md={12}
                    className="d-flex align-items-center  justify-content-between my-3 "
                  >
                    <p className="h5 d-flex font-weight-bold align-items-center m-0 p-0">
                      Description : {elem?.description}
                    </p>
                    <p className="m-0 p-0">
                      <img
                        src={deleteBin}
                        alt="Delete"
                        className={`m-0 p-0 cursor-pointer ${style.remove} `}
                        onClick={() => deletAttachment(elem?.id)}
                      />
                    </p>
                  </Col>

                  <Col md={12} className="w-100">
                    <img
                      src={elem?.url}
                      alt={`Attachment ${index}`}
                      className="image-file-upload-user"
                      height="100%"
                      width="100%"
                    />
                    
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))
        ) : (
          <Card className="light-green-bg-card-white m-0 px-3 my-3">
            <CardBody>
              <p className="h4 text-center">No Files Found!</p>
            </CardBody>
          </Card>
        )}
      </div>
      <AttachmentModal
        addAttachmentToggle={() => addAttachmentToggle()}
        modal={modal}
        getUserAttachmentsFun={getUserAttachmentsFun}
        username={username}
      />
      <DeleteModal
        show={deleteModalShow}
        onDeleteClick={deleteAttachmentFun}
        onCloseClick={deletAttachment}
        inProgress={inProgress}
      />
    </>
  )
}

export default UserAttachments
