import * as moment from 'moment';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

export function setAllAvailablePermissions(allAvailablePermissions) {
    var encodedString = btoa(JSON.stringify(allAvailablePermissions));
    // cookies.set('__ap', encodedString, { path: '/', maxAge: 1000000 });
    localStorage.setItem('__ap', encodedString)
}

export function getAllAvailablePermissions() {
    let allAvailablePermissions = localStorage.getItem('__ap');
    if (allAvailablePermissions) {
        let data = JSON.parse(atob(allAvailablePermissions));
        return data;
    }
    else {
        return null
    }
}


export function setPermission(permission) {
     var encodedString = btoa(JSON.stringify(permission));
    // cookies.set('__p', encodedString, { path: '/', maxAge: 1000000 });
    localStorage.setItem('__p', encodedString)

}

export function getPermission() {
    let permission = localStorage.getItem('__p');
    if (permission) {
        let data = JSON.parse(atob(permission));
        return data;
    }
    else {
        return null
    }
}

 