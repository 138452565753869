import React from "react"
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
const CustomModal = props => {
  const {
    open,
    onToggle,
    validation,
    isShowBodyField,
    isChooseFile,
    isDisabled,
    isTitleFieldDisabled = false,
    size = "md",
    AddModalBody,
  } = props

  return (
    <Modal isOpen={open} toggle={onToggle} size={size}>
      <ModalBody>
        <div className="w-100 text-end">
          <button
            type="button"
            className="btn-close"
            onClick={onToggle}
            aria-label="Close"
          ></button>
        </div>
        <Form
          className="needs-validation"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xl={12} md={12}>
              <div className="form-group custom-date-picker-style">
                <Label className="form-label">Description</Label>
                <Input
                  name="description"
                  className="form-control"
                  placeholder="Enter description"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xl={12} md={12}>
              <div className="form-group custom-date-picker-style mt-3">
                <Label className="form-label">Total</Label>
                <Input
                  name="total"
                  className="form-control"
                  placeholder="Enter total"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.total || ""}
                  invalid={
                    validation.touched.total && validation.errors.total
                      ? true
                      : false
                  }
                />
                {validation.touched.total && validation.errors.total ? (
                  <FormFeedback type="invalid">
                    {validation.errors.total}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-2">
              <button
                type="submit"
                className="btn w-100 btn-primary"
                disabled={isDisabled}
              >
                Add
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}
export default CustomModal
