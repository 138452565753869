import React, { useState, useEffect } from "react"
import feedbackImage from "../../../assets/images/feedback-head.svg"
import style from "../feedback.module.scss"
import classNames from "classnames"
import RatingTooltip from "react-rating-tooltip"
import Loader from "../../../components/Common/Loader"

function RatingQuestions(props) {
  const {
    isSpinner,
    handleNext,
    handleBack,
    ratingQuestionList,
    setRatingQuestionList,
  } = props

  const starStyle = {}
  const styleConfig = { starContainer: { marginBottom: "15px" } }
  const [isValidData, setIsValidData] = useState(false)

  useEffect(() => {
    const isValuesEmpty = ratingQuestionList.every(note => note.value !== "")
    setIsValidData(isValuesEmpty)
  }, [ratingQuestionList])

  const handleRatingValueChange = (value, _, ratingTooltipIndex) => {
    const updatedRatingQuestionList = ratingQuestionList.map(ratingQuestion => {
      if (ratingQuestion.id === ratingTooltipIndex) {
        return {
          ...ratingQuestion,
          value: value.toString(),
        }
      } else {
        return ratingQuestion
      }
    })
    setRatingQuestionList(updatedRatingQuestionList)
  }

  return (
    <>
      {isSpinner ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center">
            <div>
              <img
                className={`${style.headImage} mg-top-default-ui`}
                src={feedbackImage}
                alt="feedback"
              />
              <h1 className="card-title-main">Post Consultation Feedback</h1>
              {ratingQuestionList.length !== 0 && (
                <>
                  {ratingQuestionList.map(ratingQuestion => (
                    <React.Fragment key={ratingQuestion.id}>
                      <h4 className="card-para-main mg-top-default-ui">
                        {ratingQuestion.description}
                      </h4>
                      <div className={style.ratingContainer}>
                        <RatingTooltip
                          max={5}
                          starStyle={starStyle}
                          styleConfig={styleConfig}
                          onChange={(value, _, ratingTooltipIndex) =>
                            handleRatingValueChange(value, _, ratingQuestion.id)
                          }
                          ActiveComponent={
                            <i className="mdi mdi-star star-rating-style" />
                          }
                          InActiveComponent={
                            <i className="mdi mdi-star-outline text-muted star-outline-style" />
                          }
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </>
              )}

              <div className="mt-2 d-flex gap-1">
                <button
                  className={classNames(
                    "btn-lg btn waves-effect waves-light btn-primary"
                  )}
                  disabled={!isValidData}
                  onClick={() => handleNext(1)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default RatingQuestions
