import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import style from "./style.module.scss"
import { GET_APPOINTMENTS } from "../Appointments/gql/Gql"
import { useLazyQuery } from "@apollo/client"
import moment from "moment"
import AppointmentInfo from "../Appointments/AppointmentInfo"

const ClientProfile = ({ user, setActiveTab, profileData }) => {
  const [firstAppointment, setFirstAppointment] = useState([])
  const [getAppointmentList, listResponse] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: "no-cache",
  })
  const [modal, setModal] = useState(false)
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null

  useEffect(() => {
    if (user?.id) {
      getAppointmentList({
        variables: {
          argumentsInput: {
            current_appointment: true,
            device_type: "web",
            page: 1,
            limit: 5,
            client_id: user?.role_id == 3 ? user?.id : undefined,
            search: "",
            sort: "utc_datetime",
            sort_dir: "ASC",
            start_date: moment()
              .startOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss[Z]"),
            end_date: moment()
              .add(1, "month")
              .startOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss[Z]"),
          },
        },
      })
    }
  }, [])
  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointments &&
      listResponse.data.getAppointments.code === 200 &&
      listResponse.data.getAppointments.status
    ) {
      if (listResponse.data.getAppointments.data) {
        const appointments =
          listResponse.data.getAppointments.data.appointments_arr?.map(elem => {
            return elem?.appointment
          })
        setFirstAppointment(appointments[0])
      }
    } else if (!listResponse.data) {
      setFirstAppointment({})
    } else {
      setFirstAppointment({})
    }
  }, [listResponse.data])

  return (
    <>
      <Row className={`${style.cardcenter} counselor-pay-section pt-1 my-5`}>
        <Col md={user?.role_id === 3 ? 7 : 12}>
          <Card className="m-0 mb-3 border-light-shadow light-green-bg-card-white">
            <CardBody className="clientProfileCard pt-4">
              <div className="d-flex justify-content-between align-items-center flex-row px-3 flex-wrap">
                <div className="col-md-3 col-sm-12 d-flex flex-column  justify-content-start mb-2">
                  <p className="h3 font-weight-bold">
                    {profileData?.totalAppointments}
                  </p>
                  <p className="m-0 p-0">Total</p>
                  <p className="m-0 p-0">booking</p>
                </div>
                <div className="col-md-3 col-sm-12 d-flex flex-column justify-content-start mb-2">
                  <p className="h3 font-weight-bold">
                    {profileData?.upcomingAppointments}
                  </p>
                  <p className="m-0 p-0">Upcoming</p>
                  <p className="m-0 p-0">appointments</p>
                </div>
                <div className="col-md-3 col-sm-12 d-flex flex-column  justify-content-start mb-2">
                  <p className="h3 font-weight-bold">
                    {profileData?.daysSinceLastAppointment}
                  </p>
                  <p className="m-0 p-0">Day since</p>
                  <p className="m-0 p-0">last appointment</p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={5} className={`${user?.role_id === 3 ? "" : "d-none"}`}>
          <Card className="m-0 mb-3 border-light-shadow light-green-bg-card-white">
            <CardBody className="clientProfileCard">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="">
                  <p className="h5 font-weight-bold">Upcoming Appointment </p>
                  <p className="h6 mt-4 ">
                    {firstAppointment?.utc_datetime
                      ? moment(firstAppointment?.utc_datetime).format(
                          "dddd, MMMM Do, h:mm A"
                        )
                      : "No Upcoming Appointments"}
                  </p>
                </div>
                <div className="">
                  <button
                    className="waves-effect mdi mdi-chevron-right btn btn-light"
                    onClick={() => setModal(firstAppointment)}
                  ></button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card className="border-light-shadow light-green-bg-card-white m-0 px-3">
            <CardBody>
              <Row>
                <Col
                  md={12}
                  className="d-flex align-items-center justify-content-between"
                >
                  <p className="p-0 m-0 h5 font-weight-bold">
                    Basic Information
                  </p>
                </Col>
                <Col md={12} className="mt-4">
                  <Row>
                    <Col md={12} className="">
                      <Row className="d-flex align-items-center my-2 w-100 justify-content-between ">
                        <Col md={7} className="d-flex align-items-center ">
                          <span
                            className={`mdi mdi-account ${
                              orgId == 1 ? "text-dark" : "text-primary"
                            } m-0 icon-default-light-bg-primary-green h2`}
                          ></span>
                          <p className="h5 p-0 m-0 mx-3 text-capitalize">
                            {user?.first_name} {user?.last_name}
                          </p>
                        </Col>
                        <Col md={5}>
                          <span
                            className={`small ${
                              orgId == 1 ? "text-dark" : "text-primary"
                            }`}
                          >
                            Age
                          </span>
                          <p className="h6 font-weight-bold">
                            {user?.age
                              ? `${user.age} Years old`
                              : "Not Available"}
                          </p>
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center my-3  w-100 justify-content-between ">
                        <Col md={7} className="d-flex align-items-center">
                          <span
                            className={`mdi mdi-email ${
                              orgId == 1 ? "text-dark" : "text-primary"
                            } m-0 icon-default-light-bg-primary-green h2`}
                          ></span>
                          <p className="h5 p-0 m-0 mx-3">{user?.email}</p>
                        </Col>
                        {user?.gender?.name && (
                          <Col md={5}>
                            <span
                              className={`small ${
                                orgId == 1 ? "text-dark" : "text-primary"
                              }`}
                            >
                              Sex
                            </span>
                            <p className="h6 font-weight-bold">
                              {user?.gender?.name}
                            </p>
                          </Col>
                        )}
                      </Row>
                      <Row className="d-flex align-items-center my-2  w-100 justify-content-between">
                        <Col md={7} className="d-flex align-items-center  ">
                          <span
                            className={`mdi mdi-phone ${
                              orgId == 1 ? "text-dark" : "text-primary"
                            }  m-0 icon-default-light-bg-primary-green h2 `}
                          ></span>
                          <p className="h5 p-0 m-0 mx-3 text-capitalize">
                            {user?.mobile_number
                              ? `${user.mobile_number}`
                              : "Not Available"}{" "}
                            {user?.emergency_contact_number
                              && ` / ${user.emergency_contact_number}`
                              }
                          </p>
                        </Col>
                        {user?.date_of_birth && (
                          <Col md={5}>
                            <span
                              className={`small ${
                                orgId == 1 ? "text-dark" : "text-primary"
                              }`}
                            >
                              Date of birth
                            </span>
                            <p className="h6 font-weight-bold">
                              {moment(user?.date_of_birth).format(
                                "DD-MMM-yyyy"
                              )}
                            </p>
                          </Col>
                        )}
                      </Row>
                      {user?.city?.name ||
                      user?.province?.name ||
                      user?.country?.name ? (
                        <div className="d-flex align-items-center my-2  w-100 justify-content-between">
                          <div className="d-flex align-items-center  ">
                            <span
                              className={`mdi mdi-home ${
                                orgId == 1 ? "text-dark" : "text-primary"
                              }  m-0 icon-default-light-bg-primary-green h2 `}
                            ></span>
                            <p className="h5 p-0 m-0 mx-3 text-capitalize">
                              {user?.city?.name} , {user?.province?.name} ,{" "}
                              {user?.country?.name}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {user?.address ? (
                        <div className="d-flex align-items-center my-2  w-100 justify-content-between">
                          <div className="d-flex align-items-center  ">
                            <span
                              className={`mdi mdi-map-marker ${
                                orgId == 1 ? "text-dark" : "text-primary"
                              }  m-0 icon-default-light-bg-primary-green h2 `}
                            ></span>
                            <p className="h5 p-0 m-0 mx-3 text-capitalize">
                              {user?.address}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modal} size="lg">
        <ModalHeader toggle={() => setModal(undefined)} tag="h4">
          Appointment Details
        </ModalHeader>
        <ModalBody>
          <AppointmentInfo
            id={parseInt(modal?.id)}
            isModel={modal}
            setModal={setModal}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default ClientProfile
