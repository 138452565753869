import React from "react"
import { Label } from "reactstrap"

const Permission = ({
  title,
  name,
  setSelectedPermissions,
  selectedPermissions,
}) => {
  const [checked, setChecked] = React.useState({
    [name + "_list"]: false,
    [name + "_add"]: false,
    [name + "_edit"]: false,
    [name + "_view"]: false,
  })

  React.useEffect(() => {
    if (selectedPermissions) {
      setChecked({
        [name + "_list"]: selectedPermissions.indexOf(name + "_list") !== -1,
        [name + "_add"]: selectedPermissions.indexOf(name + "_add") !== -1,
        [name + "_edit"]: selectedPermissions.indexOf(name + "_edit") !== -1,
        [name + "_view"]: selectedPermissions.indexOf(name + "_view") !== -1,
      })
    }
  }, [selectedPermissions])

  const onChangeCheckbox = checkName => {
    const isSelected = selectedPermissions.includes(checkName)
    if (isSelected) {
      setSelectedPermissions(arr => arr.filter(obj => obj !== checkName))
    } else {
      setSelectedPermissions(arr => [...arr, checkName])
    }
  }

  const changeSelectAll = () => {
    const names = [
      name + "_list",
      name + "_add",
      name + "_edit",
      name + "_view",
    ]
    const condtion = Object.keys(checked).every(i => checked[i] === true)
    if (condtion) {
      setSelectedPermissions(items =>
        items.filter(obj => names.indexOf(obj) === -1)
      )
    } else {
      setSelectedPermissions(items => [...items, ...names])
    }
  }

  return (
    <div className={`mb-3 position-relative user-group-details-table`}>
      <Label className="pm-component-label-style" htmlFor="validationTooltip01">
        {title}
      </Label>
      <div onClick={changeSelectAll} className={`form-check`}>
        <input
          type="checkbox"
          className="form-check-input"
          checked={Object.keys(checked).every(i => checked[i] === true)}
        />
        Select All
      </div>
      <div
        onClick={e => onChangeCheckbox(`${name}_list`)}
        className="form-check"
      >
        <input
          name={`${name}_list`}
          type="checkbox"
          className="form-check-input"
          checked={checked[`${name}_list`]}
        />
        List
      </div>
      <div
        onClick={() => onChangeCheckbox(`${name}_edit`)}
        className="form-check"
      >
        <input
          name={`${name}_edit`}
          type="checkbox"
          className="form-check-input"
          checked={checked[`${name}_edit`]}
        />
        Edit
      </div>
      <div
        onClick={() => onChangeCheckbox(`${name}_view`)}
        className="form-check"
      >
        <input
          name={`${name}_view`}
          type="checkbox"
          className="form-check-input"
          checked={checked[`${name}_view`]}
        />
        View
      </div>
      <div
        onClick={() => onChangeCheckbox(`${name}_add`)}
        className="form-check"
      >
        <input
          name={`${name}_add`}
          type="checkbox"
          className="form-check-input"
          checked={checked[`${name}_add`]}
        />
        Add
      </div>
    </div>
  )
}

export default Permission
