import React, { useState, useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, CardTitle } from "reactstrap"
import classNames from "classnames"
import useStyles from "../styles"
import moment from "moment"
import { GET_ANALYTICS_CHART_DETAILS } from "../Counselor/gql/gql"
import { useMutation } from "@apollo/client"
import { convertUTCToLocal } from "../../../functions/functions"
import { formatMonthSeriesData, formatYearSeriesData } from "../function"
import style from "./style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"
import DashboardDateFilters from "../date-filter"

const AnalyticsChart = ({ setIsLoader }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("Monthly")
  const [endDate, setEndDate] = useState(moment().toDate())
  const [startDate, setstartDate] = useState(
    moment(endDate).startOf("isoWeek").toDate()
  )

  const handleMonthYear = value => {
    setstartDate(value)
  }

  const [series, setSeries] = useState([
    {
      name: "Appointment",
      type: "area",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ],
    },
  ])

  const [options, setOptions] = useState({
    chart: {
      stacked: !1,
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [2, 2, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
        borderRadius: 8,
      },
    },
    colors: ["#153C27"],
    borderRadius: 8,
    fill: {
      opacity: [0.25, 1, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
    },
    yaxis: {
      title: {
        text: "Number of total appointment scheduled",
        style: {
          fontSize: "11px",
          fontWeight: "Medium",
          color: "#3F4E50",
        },
      },
      style: {
        fontSize: "11px",
        fontWeight: "Medium",
        color: "#3F4E50",
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0)
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#E2ECDF",
    },
    zoom: {
      enabled: false,
      type: "x",
      autoScaleYaxis: false,
    },
  })

  //************************** Get Data ***************************** */

  const [getChartDetails, chartResponse] = useMutation(
    GET_ANALYTICS_CHART_DETAILS
  )

  function getChartDetailsFun() {
    getChartDetails({
      variables: {
        dashboardGraph: {
          ...(value == "Monthly"
            ? { month: parseInt(moment(startDate).format("MM")) }
            : null),
          year: moment(value == "Monthly" ? startDate : endDate).format("YYYY"),
        },
      },
    })
  }

  useEffect(() => {
    getChartDetailsFun()
  }, [value, startDate, endDate])

  useEffect(() => {
    if (
      chartResponse.data &&
      chartResponse.data.dashboardGraph &&
      chartResponse.data.dashboardGraph.code === 200
    ) {
      setLoading(false)
      setIsLoader(false)

      if (value == "Monthly") {
        let appointmentMonthData =
          chartResponse.data.dashboardGraph.data.month_wise_appointment_data.map(
            (item, i) => {
              item.date = item.day
              item.day = convertUTCToLocal(item.day, "MM/DD/YYYY")
              return item
            }
          )
        let billableMonthData =
          chartResponse.data.dashboardGraph.data.monthly_billable_count.map(
            (item, i) => {
              item.date = item.day
              item.day = convertUTCToLocal(item.day, "MM/DD/YYYY")
              return item
            }
          )

        let appointmentObj = formatMonthSeriesData(appointmentMonthData)
        let billableObj = formatMonthSeriesData(billableMonthData)
        let categories = []
        let appointmentData = []
        let billableData = []
        let startDateString = parseInt(
          moment(startDate).startOf("month").format("DD")
        )
        let endDateString = parseInt(
          moment(startDate).endOf("month").format("DD")
        )
        let currentMonth = moment(startDate).format("MMM")
        for (let i = startDateString; i <= endDateString; i++) {
          //  x axis for month
          categories.push(`${currentMonth} ${i} `) // x axis for month
          if (appointmentObj[i]) {
            appointmentData.push(parseInt(appointmentObj[i])) // for all count client register data
          } else {
            appointmentData.push(0)
          }
          if (billableObj[i]) {
            billableData.push(billableObj[i]) // for all count client register data
          } else {
            billableData.push(0)
          }
        }
        setOptions({
          ...options,
          ...{
            xaxis: {
              categories: categories,
            },
          },
        })
        let newSeries = series
        newSeries[0].data = appointmentData
        setSeries([...newSeries])
      } else {
        let categories = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ]
        setOptions({
          ...options,
          ...{
            xaxis: {
              categories: categories,
            },
          },
        })

        let appointmentYearData =
          chartResponse.data.dashboardGraph.data.year_wise_appointment_data.map(
            (item, i) => {
              item.month = item.month
              item.month = convertUTCToLocal(item.month, "MM/DD/YYYY")
              return item
            }
          )
        let billableYearData =
          chartResponse.data.dashboardGraph.data.yearly_billable_count.map(
            (item, i) => {
              item.month = item.month
              item.month = convertUTCToLocal(item.month, "MM/DD/YYYY")
              return item
            }
          )
        let billalbeYearsobj = {}
        billableYearData.forEach((item, i) => {
          let key = parseInt(moment(`1-${item.month_name}`).format("MM"))
          billalbeYearsobj[key] = item.count
        })

        let appointmentObj = formatYearSeriesData(appointmentYearData)
        let billalbeObj = formatYearSeriesData(billableYearData)
        let appointmentData = []
        let billableData = []
        categories.forEach((item, i) => {
          if (appointmentObj[i + 1]) {
            appointmentData.push(parseInt(appointmentObj[i + 1]))
          } else appointmentData.push(0)

          if (billalbeYearsobj[i + 1]) {
            billableData.push(parseInt(billalbeYearsobj[i + 1]))
          } else billableData.push(0)
        })

        let newSeries = series
        newSeries[0].data = appointmentData

        setSeries(newSeries)
      }
    } else {
      setLoading(true)
    }
  }, [chartResponse.data])

  const refreshAPI = () => {
    setLoading(true)
    setIsLoader(true)
    getChartDetailsFun()
  }

  return (
    <React.Fragment>
      <Card className="light-green-bg-card-table">
        <CardBody>
          <div className="float-end d-flex gap-2 align-items-center">
            <DashboardDateFilters
              startDate={startDate}
              setStartDate={setstartDate}
              loading={loading}
              refreshAPI={refreshAPI}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              value={value}
              handleMonthYear={handleMonthYear}
            />
          </div>
          <CardTitle className={classNames("mb-4 h4", classes.cardTitle)}>
            {"Total Appointment Scheduled"}
            <Tooltip
              className={style.tooltip}
              placement="right"
              title={
                <div className="invoice-list-fsize-14">
                  This will display the total appointments scheduled by client.
                </div>
              }
              arrow
            >
              <i
                className={`uil ul-bg-dark uil-info-circle ${style.circleinfo}`}
              ></i>
            </Tooltip>
          </CardTitle>
          <div className="mt-3 this-month">
            <ReactApexChart
              options={options}
              series={series}
              height="450"
              type="line"
              className="apex-charts"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default AnalyticsChart
