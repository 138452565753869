import React, { useState } from "react"
import { Col, Card, CardBody, Button } from "reactstrap"
import useStyles from "../styles"
import classNames from "classnames"
import eye from "../../../assets/images/dashboard-icons/eye.svg"
import close from "../../../assets/images/dashboard-icons/close.svg"
import contact from "../../../assets/images/dashboard-icons/contact.svg"
import file from "../../../assets/images/dashboard-icons/file.svg"
import eyeicon from "../../../assets/images/dashboard-icons/noShowIcon.svg"
import InActiveClientIcon from "../../../assets/images/dashboard-icons/InActiveClientIcon.png"
import initialReportsIcon from "../../../assets/images/dashboard-icons/InitiallReportsIcon.png"
import pastClientsIcon from "../../../assets/images/dashboard-icons/PastClientsIcon.png"
import { useHistory } from "react-router"

const DetailsWidget = props => {
  const {
    showDescription = true,
    showDetailsButton = true,
    showBagdeValue = true,
    iconHeight = "48px",
    iconWidth = "48px",
    dashboardData,
  } = props

  const [isStateUpdate, setSateUpdate] = useState(false)
  let history = useHistory()

  const [data, setData] = useState([
    {
      id: 1,
      arrowIcon: "mdi mdi-arrow-up-bold",
      title: "No Show",
      icon: eyeicon,
      value: dashboardData.no_show_counts ? dashboardData.no_show_counts : 0,
      prefix: "",
      suffix: "",
      badgeValue: dashboardData.no_show_percentage
        ? `${dashboardData.no_show_percentage}%`
        : "0%", //%
      decimal: 0,
      color: "success",
      desc: "No-Show are the clients who booked the appointment but didn't showed up",
    },
    {
      id: 2,
      arrowIcon: "mdi mdi-arrow-down-bold",
      title: "Inactive Clients",
      icon: InActiveClientIcon,
      value: dashboardData.deleted_clients ? dashboardData.deleted_clients : 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      badgeValue: dashboardData.deleted_clientPercentage
        ? `${dashboardData.deleted_clientPercentage}%`
        : "0%",
      color: "danger",
      desc: "Deactivated are the clients who are requested to deactivate by the counsellor.",
    },
    {
      id: 3,
      arrowIcon:
        dashboardData.initial_report_percentage_status == "Negative"
          ? "mdi mdi-arrow-up-bold"
          : "mdi mdi-arrow-down-bold",
      title: "Initial Reports",
      icon: initialReportsIcon,
      value: dashboardData.initial_report_count
        ? `${dashboardData.initial_report_count}`
        : "0",
      decimal: 0,
      prefix: "",
      suffix: "",
      badgeValue: dashboardData.initial_report_percentage
        ? `${dashboardData.initial_report_percentage}%`
        : "0%",
      color:
        dashboardData.initial_report_percentage_status == "Negative"
          ? "success"
          : "danger",
      desc: "Initial report are the ICBC clients who didn't booked appointment within 14 days after the first session",
    },
    {
      id: 4,
      arrowIcon: "mdi mdi-arrow-up-bold",
      title: "Past Clients",
      icon: pastClientsIcon,
      value: 12.58,
      decimal: 2,
      prefix: "$",
      suffix: "",
      badgeValue: "10.51%",
      color: "success",
      desc: "Past clients are the clients who booked appointments on the past date from acuity",
    },
  ])

  const classes = useStyles()

  React.useEffect(() => {
    setData([
      {
        id: 1,
        arrowIcon:
          dashboardData.no_show_percentage_status == "Negative"
            ? "mdi mdi-arrow-down-bold"
            : "mdi mdi-arrow-up-bold",
        title: "No Show ",
        icon: eyeicon,
        value: dashboardData.no_show_counts ? dashboardData.no_show_counts : 0,
        prefix: "",
        suffix: "",
        badgeValue: dashboardData.no_show_percentage
          ? `${dashboardData.no_show_percentage}%`
          : "0%", //%
        decimal: 0,
        color:
          dashboardData.no_show_percentage_status == "Negative"
            ? "danger"
            : "success",
        desc: "No-Show are the clients who booked the appointment but didn't showed up.",
      },
      {
        id: 2,
        arrowIcon:
          dashboardData.deactivate_clients_percentage_status == "Negative"
            ? "mdi mdi-arrow-down-bold"
            : "mdi mdi-arrow-up-bold",
        title: "Inactive Clients",
        icon: InActiveClientIcon,
        value: dashboardData.deleted_clients
          ? dashboardData.deleted_clients
          : 0,
        decimal: 0,
        prefix: "",
        suffix: "",
        badgeValue: dashboardData.deleted_clientPercentage
          ? `${dashboardData.deleted_clientPercentage}%`
          : "0%",
        color:
          dashboardData.deactivate_clients_percentage_status == "Negative"
            ? "danger"
            : "success",
        desc: "Deactivated are the clients who are requested to deactivate by the counsellor.",
      },
      {
        id: 3,
        arrowIcon:
          dashboardData.initial_report_percentage_status == "Negative"
            ? "mdi mdi-arrow-down-bold"
            : "mdi mdi-arrow-up-bold",
        title: "Initial Reports",
        icon: initialReportsIcon,
        value: dashboardData.initial_report_count
          ? `${dashboardData.initial_report_count}`
          : "0",
        decimal: 0,
        prefix: "",
        suffix: "",
        badgeValue: dashboardData.initial_report_percentage
          ? `${dashboardData.initial_report_percentage}%`
          : "0%",
        color:
          dashboardData.initial_report_percentage_status == "Negative"
            ? "danger"
            : "success",
        desc: "Initial report are the ICBC clients who didn't booked appointment within 14 days after the first session.",
      },
      {
        id: 4,
        arrowIcon:
          dashboardData.past_appointments_percentage_status == "Negative"
            ? "mdi mdi-arrow-down-bold"
            : "mdi mdi-arrow-up-bold",
        title: "Past Clients",
        icon: pastClientsIcon,
        value: dashboardData.past_appointments_count
          ? `${dashboardData.past_appointments_count}`
          : "0",
        decimal: 2,
        prefix: "$",
        suffix: "",
        badgeValue: dashboardData.past_appointments_percentage
          ? `${dashboardData.past_appointments_percentage}%`
          : "0%",
        color:
          dashboardData.past_appointments_percentage_status == "Negative"
            ? "danger"
            : "success",
        desc: "Past clients are the clients who booked appointments on the past date from acuity.",
      },
    ])
    setSateUpdate(!isStateUpdate)
  }, [dashboardData.deleted_clients, dashboardData.no_show_counts])

  return (
    <>
      {data.map((report, index) => (
        <Col md={6} xl={6} xxl={3} key={index} className="mb-4">
          <Card className="h-100 light-green-bg-card">
            <CardBody className={classes.CardPadding}>
              <div className="d-flex flex-column gap-2 flex-wrap">
                <div className="d-flex justify-content-between align-items-center client-details-card-flex">
                  <div className="client-details-card">
                    <p className={classNames("mt-1 " + classes.title)}>
                      {report.title}
                    </p>
                    <h4 className="mt-1 mb-0">
                      <span className="details-widget-h4-span">
                        {report.value}
                      </span>
                    </h4>
                  </div>
                  <img
                    src={report.icon}
                    width={iconWidth}
                    height={iconHeight}
                  />
                </div>
                {showDescription && (
                  <p
                    className={"client-card-description " + classes.description}
                  >
                    {report.desc}
                  </p>
                )}
                {/* {showDetailsButton && (
                  <Button
                    className="button-client-cards"
                    onClick={() => {
                      history.push(
                        `/dashboard-details?type=${report.title.replace(
                          " ",
                          "-"
                        )}`
                      )
                    }}
                  >
                    View Details
                  </Button>
                )} */}
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  )
}

export default DetailsWidget
