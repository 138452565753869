import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  textFilter,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import { makeStyles } from "@material-ui/core"
import classNames from "classnames"
import { Spinner } from "reactstrap"
// import overlayFactory from 'react-bootstrap-table2-overlay';

const useStyles = makeStyles(theme => ({
  conatiner: {
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      marginBottom: "10px",
    },
  },

  boxContainer: {
    flex: "50%",
    "@media (max-width: 768px)": {
      flex: "100%",
    },
  },

  width: {
    width: "400px",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },

  searchContainer: {
    maxWidth: "250px",
    "@media (max-width: 768px)": {
      flex: "40%",
      width: "100%",
    },
  },

  filterContainer: {
    width: "250px",
    "@media (max-width: 768px)": {
      flex: "60%",
      width: "100%",
    },
  },
}))

const RemotePagination = props => {
  const {
    data,
    page,
    sizePerPage,
    onTableChange,
    dropup,
    totalSize,
    columns,
    noDataIndication,
    loading,
    isPagination = true,
    isSearch,
    isFilter,
    selectOptions,
    onSelectChange,
    selectValue,
    placeholder,
    isShowCustomDropdown = false,
    CustomDropdown,
    rowStyle,
    rowClasses,
    isBordered,
  } = props
  const [myload, setMyload] = useState(false)

  const { SearchBar } = Search
  const classes = useStyles()
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} to {to} of {size} Results
    </span>
  )
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPage: sizePerPage,
    page: page,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    totalSize: totalSize,
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      // , {
      //   text: 'All', value: totalSize
      // }
    ], // A numeric array is also available. the purpose of above example is custom the text
  }
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
        })}
      >
        {({ paginationTableProps }) => (
          <div>
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={data}
              loading={loading}
              search
            >
              {toolkitprops => (
                <div>
                  <div className={classes.conatiner}>
                    <div
                      className={classNames(
                        "d-flex gap-2",
                        classes.boxContainer
                      )}
                    >
                      {isSearch && <MySearch {...toolkitprops.searchProps} />}
                      {isFilter && (
                        <div className={classes.filterContainer}>
                          <Select
                            className="input default-search-input"
                            onChange={onSelectChange}
                            placeholder={placeholder}
                            value={selectValue}
                            options={selectOptions}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderRadius: "8px",
                                minHeight: "41px",
                                border: "1px solid #ced4da",
                                cursor:"pointer"
                              }),
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {isShowCustomDropdown && (
                      <div
                        className={classNames(
                          "d-flex w-100 justify-content-end ",
                          classes.boxContainer
                        )}
                      >
                        <div className={classes.width}>{CustomDropdown}</div>
                      </div>
                    )}
                  </div>
                  {loading && <Spinner className="data-table-spinner" />}
                  <div className={loading ? "data-table-wrap" : ""}>
                    <BootstrapTable
                      noDataIndication={noDataIndication}
                      remote
                      keyField="id"
                      striped={false}
                      onTableChange={onTableChange}
                      rowStyle={rowStyle}
                      rowClasses={rowClasses}
                      {...paginationTableProps}
                      {...toolkitprops.baseProps}
                      {...(isPagination && {
                        pagination: paginationFactory(options),
                      })}
                      bordered={isBordered}
                      wrapperClasses="table-responsive"
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        )}
      </PaginationProvider>
    </div>
  )
}

export default RemotePagination

const MySearch = props => {
  let input
  const handleClick = event => {
    const { value } = event.target
    props.onSearch(value)

    // setTimeout(() => {
    //   props.mysearch(false);
    // }, 3000);
    // props.mysearch(true);
  }
  const classes = useStyles()

  return (
    <label className={classes.searchContainer}>
      <input
        className="form-control default-search-input"
        ref={n => (input = n)}
        onChange={handleClick}
        type="text"
        placeholder="Search"
      />
    </label>
  )
}
