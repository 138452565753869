import React, { useEffect, useState } from "react"
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import Tooltip from "@material-ui/core/Tooltip"
import Loader from "../../components/Common/Loader"
import style from "./style.module.scss"
import PhoneNoField from "../User/PhoneNoField"
import { CREATE_EDIT_USER, GET_USER } from "../User/gql/userGql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Alerts from "../../components/Alerts"
import { useSelector } from "react-redux"
const EditClientModal = ({
  editClietnModal,
  setEditClientModal,
  getAppointmentFun,
}) => {
  const [clientDetails, setClientDetails] = useState()
  const [originalEmail, setOriginalEmail] = useState(false)
  const [isEmailChanges, setIsEmailChanges] = useState(false)
  const orgData = useSelector(state => state.Login?.orgDetails)
  const [isLoading, setIsLoading] = useState(false)
  const [getUserDetail, details] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(CREATE_EDIT_USER)

  useEffect(() => {
    if (editClietnModal) {
      setIsLoading(true)
      getUserDetail({
        variables: {
          argumentsInput: {
            id: parseInt(editClietnModal),
          },
        },
      })
    }
  }, [editClietnModal])

  React.useEffect(() => {
    if (
      details.data &&
      details.data?.getUser &&
      details.data?.getUser?.status
    ) {
      let data = {
        email: details.data?.getUser?.data?.user.email
          ? details.data?.getUser?.data?.user.email?.trim()
          : "",
        mobile_number: details.data?.getUser?.data?.user?.mobile_number || "",
      }
      setOriginalEmail(data?.email)
      setClientDetails(data)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [details?.data])

  const handlePhoneChange = e => {
    const data = {
      ...clientDetails,
      mobile_number: e,
    }
    setClientDetails(data)
  }

  const handleEmailChange = e => {
    const email = e.target.value.toLowerCase().replace(/\s/g, "")
    const data = {
      ...clientDetails,
      email: email,
    }
    setClientDetails(data)
  }

  const updateClientInfo = () => {
    function isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailPattern.test(email)
    }
    function isValidPhone(mobile_number) {
      return mobile_number?.length > 8 || mobile_number?.length == 0
    }
    if (
      isValidEmail(clientDetails?.email) &&
      isValidPhone(clientDetails?.mobile_number)
    ) {
      const userInput = {
        id: editClietnModal,
        email: clientDetails?.email,
        mobile_number: clientDetails?.mobile_number.includes("+")
          ? clientDetails?.mobile_number
          : clientDetails?.mobile_number.length > 8 ? `+` + clientDetails?.mobile_number : null,
      }
      submitMutation({
        variables: {
          createUserInput: userInput,
        },
      })
    } else {
      toast.error("Please enter valid details!")
    }
  }

  React.useEffect(() => {
    if (
      submitResponse?.data &&
      submitResponse?.data?.createAdminUser?.status &&
      submitResponse?.data?.createAdminUser?.code === 200
    ) {
      toast.success(submitResponse.data.createAdminUser.message)
      setEditClientModal(undefined)
      getAppointmentFun()
    }else{
      toast.error(submitResponse?.data?.createAdminUser?.message)
    }
  }, [submitResponse.data])

  useEffect(() => {
    if(clientDetails?.email !== originalEmail){
      setIsEmailChanges(true)
    }else{
      setIsEmailChanges(false)
    }
  }, [clientDetails?.email])

  return (
    <Modal isOpen={editClietnModal} size="md">
      <ModalHeader toggle={() => setEditClientModal(undefined)} tag="h4">
        Edit Client
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
            <div className="d-flex justify-content-center">
            <Loader />
            </div>
        ) : (
          <>
            <Label className="form-label">
              Email
            </Label>
            <Input
              id="email"
              name="email"
              className="form-control"
              placeholder="Enter client email"
              type="email"
              onChange={event => {
                handleEmailChange(event)
              }}
              value={clientDetails?.email}
            />
            {orgData?.is_acuity_integration && isEmailChanges  && 
            <Alerts type={"warning"} message={"Kindly make sure to update the email address on Acuity as well. It's not synced automatically."}/>}
            <Label className="form-label">Phone</Label>
            <PhoneNoField
              className="phoneinpute"
              name="mobile_number"
              onChange={event => {
                handlePhoneChange(event)
              }}
              value={clientDetails?.mobile_number}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <div>
          <Button className="btn btn-primary" onClick={updateClientInfo}>
            Update
          </Button>
          <Button className="btn btn-inactive waves-effect m-2" onClick={()=>setEditClientModal(undefined)}>Cancel</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default EditClientModal
