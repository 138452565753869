import React from "react"
import { Card, CardBody, Col } from "reactstrap"
import useStyles from "../styles"

function GoogleMap() {
  
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
  const classes = useStyles()
  return (
    <Col xs={12}>
      {orgId === 1 && <Card className="light-green-bg-card">
       <CardBody className={classes.CardPadding}>
          <h4 className="card-title-main  mb-4">
            Client Base Of No Fear Counselling Across Canada
          </h4>
          <iframe
            src="https://www.google.com/maps/d/embed?mid=12aEYVRl21NRaAt-TwDWULaUBMID5BnJP&ehbc=2E312F"
            width="100%"
            className={`gmaps-height-400 ${classes.map}`}
          ></iframe>
        </CardBody>
      </Card>}
    </Col>
  )
}

export default GoogleMap
