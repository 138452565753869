import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody, Card, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Components
import CounselorDashboard from "./Counselor/index";
import AdminDashboard from "./Admin/index";

const Dashboard = () => {
    const history = useHistory()
    const userData = useSelector(state => state.Login?.user?.user);
    
    useEffect(()=>{
        if(userData && userData.role.id == 3){
            return history.push('/appointments')  
        }
        if(userData && userData.role.slug.toLowerCase() === "superadmin"){
            return history.push('/organizations')  
        }
    },[userData])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {userData.role.id === 1 ? <AdminDashboard /> : <CounselorDashboard />}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;