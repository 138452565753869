import { Card, CardBody, Col, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import style from "./style.module.scss"
import { useSelector } from "react-redux"
const OutStandingList = ({ outStanding, handleOutstaningList }) => {
  const userRole = useSelector(state => state.Login?.user?.user?.role)
  const columns = [
    {
      text: "",
      dataField: "checkbox",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            <input
              type="checkbox"
              defaultChecked
              data-title={row.id}
              onClick={e => handleOutstaningList(e, row, false)}
            />
          </span>
        )
      },
      hidden: userRole.role_name == "Admin" ? false : true,
    },
    {
      text: "Client",
      dataField: "first_name",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            {`${
              row?.client && row?.client.name
                ? row?.client.name
                : row?.client?.first_name && row?.client?.last_name
                ? `${row?.client?.first_name} ${row?.client?.last_name}`
                : `${row.first_name} ${row.last_name}`
            }`}
          </span>
        )
      },
    },
    {
      text: "Rate",
      dataField: "price",
      width: 150,
      formatter: (cell, row) => {
        return <span>${Number(cell).toFixed(2)}</span>
      },
    },
    {
      text: "Counsellor Rate",
      dataField: "counsellor_rate",
      width: 150,
      formatter: (cell, row) => {
        return <span>${Number(cell).toFixed(2)}</span>
      },
    },
    {
      text: "Date",
      dataField: "date",
      width: 150,
      formatter: (cell, row) => {
        return <span>{moment(cell).format("DD-MMM-YYYY")}</span>
      },
    },
    {
      text: "Description",
      dataField: "type",
      width: 150,
      formatter: (cell, row) => {
        return <span>{cell}</span>
      },
    },
    {
      text: "Category",
      dataField: "Category",
      width: 150,
      formatter: (cell, row) => {
        return <span>{row?.plan?.payment_source.name}</span>
      },
    },
    {
      text: "Comment",
      dataField: "Comment",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            These sessions will show up on your future invoice once sessions
            have been charged.
          </span>
        )
      },
    },
  ]
  return (
    <React.Fragment>
      {outStanding && outStanding.length > 0 && (
        <>
          <div className={`${style.titleInvoice} basic-card-title`}>
            Outstanding items from current invoice
          </div>
          <Row>
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody className="table-custom-redesign">
                  <Table
                    striped={false}
                    data={outStanding || []}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    isPagination={false}
                    isSearch={false}
                    isStriped={false}
                    isHover={false}
                    isBordereded={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}
export default OutStandingList
