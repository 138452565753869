const { makeStyles } = require("@material-ui/styles");

export const useStyles = makeStyles((theme) => ({

    //index page

    headerDetails: {
        minWidth: "125px",
        paddingRight: "10px",
        marginBottom: 0,
        fontSize: "20px !important",
        fontWeight: 600,
        "@media (max-width: 600px)": {
            width: "165px"
        }
    },

    filterContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        "@media (max-width: 768px)": {
            flexDirection: "column"
        }
    },

    selectFilter: {
        // d-flex align-items-center justify-content-end
        minWidth: "200px",
        "& .css-yk16xz-control": {
            borderRadius: 0
        },
        "@media (max-width: 768px)": {
            minWidth: "100%"
        },
        "@media (max-width: 568px)": {
            minWidth: "100%"
        }
    },

    datePickerFilter: {
        "@media (max-width: 768px)": {
            minWidth: "100%"
        },
        "@media (max-width: 568px)": {
            minWidth: "100%"
        }
    },

    CardPadding: {
        padding: "24px 20px"
    },

    counselorCardPadding: {
        padding: "16px 24px"
    },

    cardTitle: {
        fontSize: "20px",
        lineHeight: "26px",
        margin: 0
        // "@media (max-width: 950px)": {
        //     fontSize: "14px",
        // },
    },

    chartAlign: {
        display: "flex",
    },

    chartHeight: {
        minHeight: "100%",
        "@media (max-width: 1199px)": {
            minHeight: "auto",
        }
    },

    pieChartHeight: {
        height: "430px",
        "@media (max-width: 600px)": {
            height: "auto",
        }
    },

    refreshIcon: {
        cursor: "pointer"
    },

    refresh: {
        animation: "spin 4s linear infinite",
        animationDirection: "reverse"
    },

    boldFont: {
        fontWeight: 600,
        color: "#1D2939"
    },

    normalFontsize: {
        fontSize: "18px",
    },

    tableHeader: {
        fontWeight: 500
    },

    map: {
        height: "635px",
        "& .i4ewOd-pzNkMb-haAclf": {
            display: "none !important"
        },
        "@media (max-width: 600px)": {
            height: "400px",
        }

    },

    counselorWidgetTitle: {
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "IBM Plex Sans",
        margin: 0,
        opacity: "50%"
    },

    //details-widget

    CountUp: {
        fontWeight: 600,
        fontSize: "24px",
        fontFamily: "IBM Plex Sans Condensed",
        lineHeight: "31px"
    },
    title: {
        fontFamily: "IBM Plex Sans",
        fontWeight: 500,
        fontSize: "14px",
        LineHeight: "20.8px",
        color: "#74788D",
        margin: 0,
        fontStyle: "normal",
        margin: "0 0 0 0",
        "@media (max-width: 1425px)": {
            fontSize: "14px",
        }
        // whiteSpace: "nowrap",
    },
    // content: {
    //     display: "flex",
    //     flexDirection: "column",
    //     gap: "15px",
    //     flexWrap: "wrap"
    // },
    // content: {
    //     "& p": {
    //         flex: "1 0 auto"
    //     },
    // },
    description: {
        display: "-webkit-box",
        fontFamily: "IBM Plex Sans",
        fontSize: "14.4px",
        fontStyle: "normal",
        fontWeight: 400,
        color: "#74788D",
        maxWidth: "100%",
        minHeight: "30px",
        margin: "0 0",
        lineHeight: "19px",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"

    },
    viewDetails: {
        borderRadius: "70px",
        width: "160px",
        backgroundColor: "#FFFFFF",
        marginTop: "10px",
        color: "#74788D",
        "&:focus": {
            backgroundColor: "#074BB6",
        },
        "&:hover": {
            backgroundColor: "#074BB6",
        }
    },

    //session - statistic

    roundShape: {
        height: "",
        width: ""
    },
    CountUp: {
        fontWeight: 600,
        fontSize: "1.5rem",
        fontFamily: "IBM Plex Sans Condensed",
        lineHeight: "31px"
    },
    value: {
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        fontSize: "2rem",
        LineHeight: "41.6px",
        margin: 0
    },
    sessionTitle: {
        fontFamily: "IBM Plex Sans",
        fontSize: "14.4px",
        fontWeight: 500,
        color: "#74788D",
        lineHeight: "19px",
        margin: " 0 0 0"
    },
    dot: {
        height: "18.55px",
        width: "18.55px",
        borderRadius: "50%",
        display: "inline-block",
        marginTop: "15px"
    },
    sessionContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginTop: "20px"
    },
    sessionItem: {
        display: "flex",
        gap: "10px",
        "@media (max-width: 1100px)": {
            width: "50%",
            marginBottom: "20px"
        },
        "@media (max-width: 600px)": {
            width: "50%",
            minWidth: "175px",
            alignSelf: "center",
            marginBottom: "20px"
        }
    },
    divider: {
        '&::nth-last-child()': {
            display: "none !important"
        },
        "@media (max-width: 1100px)": {
            display: "none"
        },
        "@media (max-width: 600px)": {
            display: "none"
        }
    },

    //top User

    topUsertable: {
        "& table thead": {
            borderBottom: "2px solid #F5F6F8"
        },
        "& table tbody": {
            "&>:nth-of-type(4n + 1)": {
                "& $avatar": {
                    background: "#FF6361",
                }
            },
            "&>:nth-of-type(4n + 2)": {
                "& $avatar": {
                    background: "#4095E4",
                }
            },
            "&>:nth-of-type(4n + 3)": {
                "& $avatar": {
                    background: "#FFA600",
                }
            },
            "&>:nth-child(4n + 0)": {
                "& $avatar": {
                    background: "#AFCD5B",
                }
            },
        }
    },

    avatar: {
        width: "25px",
        height: "25px",
        borderRadius: "100%",
        textAlign: "center",
        display: "flex",
        background: "red",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1rem",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-2px",
    },

    rowValue: {
        fontFamily: "IBM Plex Sans",
        fontWeight: 400
    },

    avatarCircle: {
        width: "25px",
        height: "25px",
        backgroundColor: "black",
        textAlign: "center",
        borderRadius: "50%",
    },
    initials: {
        position: "relative",
        top: "25px", /* 25% of parent */
        fontSize: "20px", /* 50% of parent */
        lineHeight: "20px", /* 50% of parent */
        color: "#fff",
        fontFamily: "IBM Plex Sans",
        fontWeight: "bold"

    },

    // upcoming-appointment

    bigAvatar: {
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        textAlign: "center",
        display: "flex",
        background: "red",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1rem",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center"
    },

    scrollbarTable: {
        "& .table-responsive": {
            height: 430,
            '&::-webkit-scrollbar': {
                width: "3px",
                background: "gray",

            },
            '&::-webkit-scrollbar-track': {
                // background: "gray",
                // height: "50px",

            },
            '&::-webkit-scrollbar-thumb': {
                background: "#383F45",
            },
        },
        "& thead": {
            position: "sticky",
            background: "#FFF",
            top: 0
        },
        "@media (max-width: 768px)": {
            "& .table-responsive": {
                height: 430,
                '&::-webkit-scrollbar': {
                    display: "none"
                },
                '&::-webkit-scrollbar-track': {
                    // background: "gray",
                    // height: "50px",

                },
                '&::-webkit-scrollbar-thumb': {
                    background: "#383F45",
                },
            },
        },
    },

    appointmentTable: {
        "& table thead tr th": {
            fontWeight: 500
        },
        "& table tbody": {
            height: "200px",
            color: "#818992",
            "&>:nth-of-type(5n + 1)": {
                "& $bigAvatar": {
                    background: "#FF6361",
                }
            },
            "&>:nth-of-type(5n + 2)": {
                "& $bigAvatar": {
                    background: "#93C3B3",
                }
            },
            "&>:nth-of-type(5n + 3)": {
                "& $bigAvatar": {
                    background: "#BC5090",
                }
            },
            "&>:nth-child(5n + 4)": {
                "& $bigAvatar": {
                    background: "#FFA600",
                }
            },
            "&>:nth-child(5n + 0)": {
                "& $bigAvatar": {
                    background: "#7262A1",
                }
            },
        }
    },

    // appointmentTable: {
    //     "& table tbody": {
    //         height: "500px",
    //         overflowY: "scroll"
    //     }
    // },

    tableFont: {
        fontWeight: 500,
    },

    // appointmentTable: {
    //     "& table tbody": {
    //         color: "#818992"
    //     }
    // },

    // appointment-location

    locationContainer: {
        "@media (max-width: 768px)": {
            flexDirection: "column"
        },
    },

    locationTitle: {
        fontFamily: "IBM Plex Sans",
        fontWeight: 600,
        fontSize: "18px",
        LineHeight: "20.8px",
        color: "#1D2939",
        margin: 0,
        fontStyle: "normal",
        margin: "0 0 0 0",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        "@media (max-width: 1425px)": {
            fontSize: "14px",
        }
    },

    locationItems: {
        // d-flex gap-2 justify-content-between align-items-center
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
        margin: "30px",
        flexDirection: "column",
        // "@media (max-width: 500px)": {
        //     flexDirection: "row",
        //     justifyContent: "flex-start",
        //     gap: "20px",
        // }
    },

    appointmentLocation: {
        height: "90%"
    },

    // appointment-stats

    dataTitle: {
        fontSize: "18px",
        color: "#1D2939",
        fontWeight: 600,
        alignSelf: "center"
    },

    widgetDiscription: {
        fontSize: "19px",
        fontWeight: 400,
        color: "#9DA3BF"
    },

    widgetBtn: {
        width: "116px",
        height: "34px",
        border: "1px solid #1D2939",
        borderRadius: "60px",
        background: "#FFF",
        "&:focus": {
            backgroundColor: "#FFF",
        },
        "&:hover": {
            backgroundColor: "#FFF",
        }
    },

    widgetBtnText: {
        color: "#1D2939",
        fontWeight: 500,
        fontSize: "14px"
    },

    widgetCount: {
        fontSize: "2rem",
        fontWeight: 600,
        "@media (max-width: 1400px)": {
            fontSize: "1.5rem",
            fontWeight: 600,
        },
        // "@media (max-width: 600px)": {
        //     width: "50%",
        //     minWidth: "175px",
        //     alignSelf: "center",
        //     marginBottom: "20px"
        // }
    },

    widgetBadgeValue: {
        fontSize: "1.5rem",
        fontWeight: 600,
        "@media (max-width: 1400px)": {
            fontSize: "1rem",
            fontWeight: 600,
        }
    },

    // total-client

    totalClientContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "@media (max-width: 750px)": {
            flexDirection: "column",
            alignItems: "unset"
        },
    },

    totalClientTitle: {
        flex: "40%",
        fontWeight: 600,
        fontSize: "1rem",
        "@media (max-width: 950px)": {
            fontSize: "14px",
        },
        "@media (max-width: 750px)": {
            fontSize: "12px",
        },
    },

    totalClientProgress: {
        flex: "60%",
        display: "flex",
    },

    progressbar: {
        height: "15px",
        borderRadius: "10px",
        flex: "80%"
        // "@media (max-width: 750px)": {
        //     height: "10px",
        // },
    },

    totalClientTotal: {
        flex: "20%",
        textAlign: "end",
        fontWeight: 600,
        fontSize: "1rem",
        "@media (max-width: 950px)": {
            fontSize: "14px",
        },
        "@media (max-width: 750px)": {
            fontSize: "12px",
        },
    },

    progressColor: {
        margin: "10px",
        "& .progress-bar": {
            backgroundColor: "#FF6361",
        }
    },

    // Next Appoinement Card

    cardImg: {
        height: "555px",
        "@media (max-width: 1100px)": {
            height: "100%",
        },
    },

    appointmentCardContainer: {
        "@media (max-width: 1200px)": {
            height: "600px"
        },
    },

    appointmentDetailsContainer: {
        fontSize: "20px",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
    },


    appointmentDetailsFooter: {
        fontSize: "20px",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    appointmentCard: {
        color: "#FFF",
    },

    appointmentTitle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
        fontSize: "20px",
        padding: "20px",
        borderBottom: "1px solid #FFF"
    },

    appointmentDot: {
        height: "16px",
        width: "16px",
        borderRadius: "50%",
        display: "inline-block",
        backgroundColor: "#FFFFFF"
    },

    detailsTitle: {
        marginTop: "10px",
        fontSize: "30px",
        fontWeight: 500
    },

    detailsDate: {
        fontWeight: 500,
        fontSize: "20px"

    },

    joinBtn: {
        borderRadius: "70px",
        border: "none",
        width: "167px",
        backgroundColor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // padding: "16px 64px",
        height: "58px",
        color: "#29ABE2",
        "&:focus": {
            backgroundColor: "#FFFFFF",
            color: "#29ABE2",
        },
        "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#29ABE2",
        },
        "&:active": {
            backgroundColor: "#FFFFFF",
            color: "#29ABE2",
        }
    },

    joinBtnText: {
        fontSize: "20px",
        fontWeight: 500
    },

    appointmentAvater: {
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        border: "2px solid #FFFFFF",
        textAlign: "center",
        display: "flex",
        background: "red",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1rem",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        "@media (max-width: 500px)": {
            width: "40px",
            height: "40px",
        },
    },

    overlapItem: {
        marginRight: "-15px",
        // border: "2px solid #fff"
    },

    dotBorder: {
        position: "absolute",
        height: "92%",
        top: "10px",
        width: "2px",
        right: "7px",
        borderRight: "2px dashed"
    },


    editableField: {
        cursor: 'pointer',
        "& i": {
            display: 'none',
            marginLeft: '5px',
        },
        "&:hover i": {
            display: "inline-block"
        }
    },

    dataTableSpinner: {
        opacity: 1,
        zIndex: 9999,
        position: "absolute",
        top: "33%",
        left: "50%",
    },

    appoinementAvatar: {
        marginTop: "40px",
        "&>:nth-of-type(5n + 1)": {
            background: "#FF6361",
        },
        "&>:nth-of-type(5n + 2)": {
            background: "#93C3B3",
        },
        "&>:nth-of-type(5n + 3)": {
            background: "#BC5090",
        },
        "&>:nth-child(5n + 4)": {
            background: "#FFA600",
        },
        "&>:nth-child(5n + 0)": {
            background: "#7262A1",
        },
    }
}));

export default useStyles;