import { gql } from "@apollo/client"

export const GET_DASHBOARD_WIDGET_GQL = gql`
  query CounselordashboardGraph($dashboardInput: DashboardInput!) {
    CounselordashboardGraph(dashboardInput: $dashboardInput) {
      code
      data {
        non_direct_deactive_client
        non_direct_active_client
        deactive_other
        active_other
        active_icbc
        deactive_icbc
        deleted_clients
        deleted_clientPercentage
        no_show_counts
        no_show_percentage
        total_clients
        total_appoinments
        appointment_type_graph {
          appointments_type
          percentage
        }
      }
    }
  }
`

export const LINK_ACCOUNT = gql`
  mutation CreateStripeAccount(
    $email: String!
    $organizationIdentifier: String!
  ) {
    createStripeAccount(
      email: $email
      organization_identifier: $organizationIdentifier
    ) {
      data {
        organizationAccount {
          created_at
          external_account_id
          external_link
          external_platform
          organization_id
          id
        }
      }
    }
  }
`
export const DELETE_STRIPE_ACCOUNT = gql`
  mutation DeleteStripeAccount($accountId: String!) {
    deleteStripeAccount(accountId: $accountId) {
      code
      status
      message
    }
  }
`

export const LINKED_ACCOUNT_STATUS = gql`
  query GetStripeAccountStatus($accountId: String!) {
    getStripeAccountStatus(account_id: $accountId) {
      message
      status
      data {
        statusObj
      }
    }
  }
`
