import React, { useCallback, useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import useStyles from "../Dashboard/styles"
import { fetchApi } from "../../common/api"
import { useHistory } from "react-router-dom"
import { debounce } from "lodash"
import { formatToCommonDate, hasPermission } from "../../functions/functions"
import { DEL_COUNSELLING } from "./gql/Gql"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import DeleteModal from "../../components/Common/DeleteModal"

import edit from "../../assets/images/dashboard-icons/edit.svg"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import PageNotFound from "../404"

const CounsellorType = () => {
  const classes = useStyles()
  let history = useHistory()
  let [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    totalCount: 0,
  })
  let [list, setList] = useState([])
  let [isSpinner, setIsSpinner] = useState(true)
  const [searchText, setSearchText] = useState("")
  const [deleteModalId, setDeleteModalId] = useState(-1)
  const [submitMutation, submitResponse] = useMutation(DEL_COUNSELLING)
  const [inProgress, setInProgress] = useState(false)

  const getCounsellingApi = async (limit, page, search) => {
    try {
      let { data, code, status } = await fetchApi(
        `counselling-type?is_all=true&limit=${limit}&page=${page}&search=${search}`,
        "GET"
      )
      if (code == 200 && status && data) {
        const { limit, page, totalCount } = data
        setState({
          page: Number(page),
          sizePerPage: Number(limit),
          totalCount,
        })
        const CounsellingType = data?.CounsellingType?.map((data, i) => ({
          ...data,
          index: (page - 1) * limit + i + 1,
        }))
        setList(CounsellingType)
        setIsSpinner(false)
      } else {
        setIsSpinner(false)
      }
    } catch (error) {
      console.log(error)
      setIsSpinner(false)
    }
  }
  useEffect(() => {
    const { sizePerPage, page } = state
    getCounsellingApi(sizePerPage, page, searchText)
  }, [])

  const [columns, setColumns] = useState([
    {
      text: "ID",
      dataField: "id",
      keyField: "id",
      hidden: true,
    },
    {
      text: "#",
      dataField: "index",
    },
    {
      text: "Name",
      dataField: "name",
    },
    {
      text: "Slug",
      dataField: "slug",
    },
    {
      text: "Image",
      dataField: "url",
      formatter: cell => {
        return cell ? (
          <img src={cell} height={100} className="blogs-img-styling " />
        ) : (
          ""
        )
      },
    },
    {
      text: "Article",
      dataField: "article",
      formatter: cell => {
        return (
          <div
            className="eclipes"
            dangerouslySetInnerHTML={{ __html: cell }}
          ></div>
        )
      },
    },
    {
      text: "Status",
      dataField: "status",
      formatter: (cell, row) =>
        cell && cell == "active" ? (
          <span className="rounded-pill bg-info badge bg-secondary">
            Published
          </span>
        ) : (
          <span className="rounded-pill bg-danger badge bg-secondary">
            Unpublished
          </span>
        ),
    },
    {
      text: "Modified",
      dataField: "updated_at",
      width: 270,
      formatter: (cell, row) =>
        cell && formatToCommonDate(cell, "DD-MMM-YYYY HH:mm"),
    },
    {
      text: "Action",
      dataField: "action",
      formatter: (cell, row) => {
        const encodedId = btoa(row.id);
       return ( <div className="d-flex align-items-center ">
          <Button
            className="btn btn-primary cursor-pointer"
            onClick={() => history.push(`/counsellor-type/${encodedId}`)}
          >
            <img src={edit} />
          </Button>
          <a
            onClick={() => setDeleteModalId(Number(row.id))}
            className="px-3 text-danger delete-btn cursor-pointer"
          >
            <img src={deleteBin} />
          </a>
        </div>
      )},
    },
  ])

  const delaySearch = useCallback(
    debounce(search => {
      getCounsellingApi(state?.sizePerPage, 1, search)
    }, 2000),
    []
  )

  const handleSearchText = search => {
    setIsSpinner(true)
    setSearchText(search)
    delaySearch(search)
  }
  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setIsSpinner(true)
    getCounsellingApi(sizePerPage, page, searchText)
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  const handleDelete = async id => {
    try {
      setInProgress(true)
      await submitMutation({
        variables: {
          updateCounsellingInput: { id },
        },
      })
      const { sizePerPage, page } = state
      await getCounsellingApi(sizePerPage, page, searchText)
      setTimeout(() => {
        toast.success("Record deleted successfully")
      }, 1500)
    } catch (error) {
      console.log(error)
      toast.error("something went wrong !")
    } finally {
      setInProgress(false)
      setDeleteModalId(-1)
    }
  }

  return (
    <div className="page-content">
          {hasPermission() ? (
      <div className="container-fluid">
        <div>
          <div className="py-3">
            <h4 className="card-title-main">Counselling Type</h4>
            <h5 className={`card-para-main`}>
              List of all the counselling types in dashboard.
            </h5>
          </div>
        </div>

        <Row className="mg-top-default-ui">
          <Col>
            <Card className="light-green-bg-card-table">
              <CardBody>
                <Row className="d-flex align-item-center justify-content-between mb-2 mt-4">
                  <Col
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    className="align-self-center"
                  >
                    <Input
                      value={searchText}
                      onChange={e => handleSearchText(e.target.value)}
                      placeholder="Search"
                      className="default-search-input"
                    />
                  </Col>
                  <Col sm={12} md={9} lg={9} xl={6} className="text-end">
                    <Button
                      className="btn btn-primary margin-top-10px-xs "
                      onClick={() => {
                        history.push(`/counsellor-type/add`)
                      }}
                    >
                      <span>
                        <i className="mdi mdi-plus" />
                        Add
                      </span>
                    </Button>
                  </Col>
                </Row>
                <div className="table-custom-redesign">
                  <Table
                    data={list}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    keyField={"id"}
                    isHover={false}
                    isStriped={false}
                    isBordereded={false}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <DeleteModal
          show={deleteModalId !== -1}
          onDeleteClick={() => {
            handleDelete(deleteModalId)
          }}
          onCloseClick={() => {
            setDeleteModalId(-1)
          }}
          inProgress={inProgress}
        />
      </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  )
}
export default CounsellorType
