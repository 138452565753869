import React, { Fragment } from "react"
import { Button } from "reactstrap"
import Tooltip from "@material-ui/core/Tooltip"
import style from "./style.module.scss"
import { isColorDark } from "../../functions/functions"

export const CalendarChip = eventInfo => {
  // "dayGridMonth"

  if (eventInfo.view.type === "timeGridWeek") {
    return (
      <div
        title={eventInfo.event.extendedProps.appointmentName}
        className={`pr-${eventInfo.backgroundColor}`}
      >{`${eventInfo.timeText} ${eventInfo.event.title}`}</div>
    )
  }

  if (eventInfo.view.type === "timeGridDay") {
    return (
      <div
        title={eventInfo.event.extendedProps.appointmentName}
        className={`pr-${eventInfo.backgroundColor}`}
      >{`${eventInfo.event.title} ${eventInfo.timeText}`}</div>
    )
  }

  return (
    <Fragment>
      <div
        title={eventInfo.event.extendedProps.appointmentName}
        // arrow
        style={{
          padding: "5px",
          cursor: "pointer",
          borderRadius: "5px",
          border: `1px solid ${eventInfo.backgroundColor}`,
          color: "#fff",
          backgroundColor: eventInfo.backgroundColor,
        }}
      >
        <div style={{ color:isColorDark(eventInfo.backgroundColor) ? "#fff" :'#000'}} className="d-flex justify-content-between">
        <div>
        <div><strong>{eventInfo.event.title}</strong></div>
          <div>{eventInfo.timeText}</div>
        </div>
          {!eventInfo.event.extendedProps.is_direct && <i className="fas fa-dollar-sign"></i>}
        </div>
      </div>
    </Fragment>
  )
}
