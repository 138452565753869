import React, { useEffect, useState, useCallback, useRef } from "react"
import TwilioVideo from "twilio-video"
import {
  createLocalVideoTrack,
  createLocalAudioTrack,
  connect,
} from "twilio-video"
import Video from "./Video"
import { useParams, useHistory } from "react-router-dom"
import Tabs from "./Tabs"
import {
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap"
import classNames from "classnames"
import useStyles from "./style"
import {
  GET_APPOINTMENT,
  GET_tWILIO_ACCESS_TOKEN_WITHOUT_AUTH,
  GET_tWILIO_ACCESS,
  GET_tWILIO_ACCESS_TOKEN,
  GET_ROOM_PARTICIPANTS,
  CREATE_CONSENT,
  STOP_RECORDNING,
} from "./gql/twillioGql"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  convertUTCToLocal,
  calculateDurationBetweenTwoTimes,
  capitalizeFirstLetter,
  calculateDuration,
  generateDarkAndLightColors,
} from "../../functions/functions"
import moment from "moment"
import RemoteVideo from "./RemoteVideo"
import ConsultationFeedback from "../FeedBack-Form/Consultationfeedback"
import { useSelector } from "react-redux"

//icons
import videoTurnOff from "../../assets/images/dashboard-icons/twilio/video-turn-off.svg"
import videoTurnOn from "../../assets/images/dashboard-icons/twilio/video-turn-on.svg"
import leaveCall from "../../assets/images/dashboard-icons/twilio/leave-call.svg"
import unmuteMic from "../../assets/images/dashboard-icons/twilio/unmute.svg"
import muteMic from "../../assets/images/dashboard-icons/twilio/mute.svg"
import lobbyMuteMic from "../../assets/images/dashboard-icons/twilio/mute-mic.svg"
import lobbyUnmuteMic from "../../assets/images/dashboard-icons/twilio/unmute-mic.svg"
import videoOff from "../../assets/images/dashboard-icons/twilio/video-off.svg"
import videoOn from "../../assets/images/dashboard-icons/twilio/video-on.svg"
import ShareIcon from "../../assets/images/dashboard-icons/twilio/share-icon.svg"
import Recording from "../../assets/images/dashboard-icons/twilio/recording.svg"
import Remarks from "../User/Remarks"
import { GET_ORGANIZATION_BY_SLUG } from "../Organizations/gql/Gql"

const VideoRoom = () => {
  const classes = useStyles()
  let { appointment_id } = useParams()
  let history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)
  const [id, setId] = useState(null)
  const [clientFeedbackSubmitted, setClientFeedbackSubmitted] = useState(false)
  const [counsellorFeedbackSubmitted, setCounsellorFeedbackSubmitted] =
    useState(false)

  /* ################################# room   ############################## */

  const [isConnecting, setIsConnecting] = useState(false)
  const [room, setRoom] = useState(null)
  const [participants, setParticipants] = useState([])
  const [isLobbyVideo, setLobbyVideo] = useState({ localStream: null })
  const [isLobbyVideoEnabled, setLobbyVideoEnabled] = useState(true)
  const [isLobbyAudioEnabled, setIsLobbyAudioEnabled] = useState(false)
  const [isVideoEnabled, setIsVideoEnabled] = useState(true)
  const [isAudioEnabled, setIsAudioEnabled] = useState(false)
  const [isRemoteVideoEnabled, setIsRemoteVideoEnabled] = useState(true)
  const [isSupportLocalVideo, setIsSupportLocalVideo] = useState(false)
  const [isSupportLocalAudio, setIsSupportLocalAudio] = useState(false)
  const [isSupportErrorMessage, setIsSupportErrorMessage] = useState("")
  const [isScreenShareOn, setScreenShareOn] = useState(null)
  const [participantsList, setparticipantsList] = useState([])
  let sessionUserData = sessionStorage.getItem("videocallUser")
  let sessionUserParams = sessionUserData ? JSON.parse(sessionUserData) : null
  /* ################################# Menu  ############################## */

  const [openMenu, setOpenMenu] = useState(false)

  const [CreateConsent, CreateConsentRes] = useMutation(CREATE_CONSENT)
  const [StopRecording, StopRecordingRes] = useMutation(STOP_RECORDNING)

  const stopRecordingFun = participant => {
    if (participant && showConsentMessage) {
      const stopRecordingVars = {
        appointment_id: JSON.stringify(appointmentDetails?.id),
        first_name: userData ? userData?.first_name : firstName,
        last_name: userData ? userData?.last_name : lastName,
        user_id: userData ? userData?.id : null,
        room_sid: showRoomData?.sid,
      }
      StopRecording({
        variables: { storeRecording: stopRecordingVars },
      })
    }
  }
  const handleMenuClick = () => {
    setOpenMenu(!openMenu)
  }
  const [getOrganizationBySlug, getOrganizationBySlugList] = useLazyQuery(
    GET_ORGANIZATION_BY_SLUG,
    {
      fetchPolicy: "no-cache",
    }
  )

  useEffect(() => {
    if (!userData) {
      let currentPath = window.location.origin + "/"
      getOrganizationBySlug({
        variables: {
          organizationInput: {
            domain: currentPath,
          },
        },
      }).then(res => {
        if (res?.data?.getOrganizationBySlug?.data?.organization) {
          const orgData = res?.data?.getOrganizationBySlug?.data?.organization
          if (orgData?.account) {
            let accData = orgData?.account
            const orgIdentifier = orgData?.organization_identifier
            if (accData && orgIdentifier) {
              accData = { ...accData, organization_identifier: orgIdentifier }
            }
            const encodedData = btoa(JSON.stringify(accData))
            localStorage.setItem("accountData", encodedData)
          } else {
            localStorage.removeItem("accountData")
          }
          if (orgData?.id) {
            const encodedId = btoa(orgData?.id)
            localStorage.setItem("orgId", JSON.stringify(encodedId))
          }
          if (orgData.id !== 1) {
            localStorage.setItem("orgData", JSON.stringify(orgData))
            if (orgData?.favicon) {
              let link = document.querySelector("link[rel~='icon']")
              if (!link) {
                link = document.createElement("link")
                link.rel = "icon"
                document.getElementsByTagName("head")[0].appendChild(link)
              }
              link.href = orgData?.favicon
              document.title = orgData?.name
            }

            const primaryShadesColor = generateDarkAndLightColors(
              orgData?.primary_color
            )
            const defaultColors = {
              "--background-color": "#F4F7FE",
              "--text-color-dark": "#000",
              "--text-dark": "#1e1e1e",
              "--text-light": "#ffffff",
              "--chart-text": "#878e94",
              "--text-color-inactive-links": "#172426",
              "--dark-green": "#22696D",
              "--btn-disabled": "#7FA8AA",
              "--green-color-light": "#84a17d",
              "--green-color-light-table": "#84a17d",
              "--green-color-bg-card": "#488386",
              "--green-color-bg-card-th": "#488386",
              "--green-color-bg-card-tbl": "white",
              "--bs-body-font-size": "1rem",
              "--bs-nav-link-color": "var(--dark-green)",
              "--bs-nav-link-hover-color": "var(var(--green-color-light))",
              "--bs-btn-color": "#fff",
              "--bs-btn-bg": "#22696D",
              "--bs-btn-border-color": "#22696D",
              "--bs-btn-hover-color": "#fff",
              "--bs-btn-hover-bg": "#488386",
              "--bs-btn-hover-border-color": "#488386",
              "--bs-btn-focus-shadow-rgb": "95, 106, 187",
              "--bs-btn-active-color": "#fff",
              "--bs-btn-active-bg": "#22696D",
              "--bs-btn-active-border-color": "#22696D",
              "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
              "--bs-btn-disabled-color": "#fff",
              "--bs-btn-disabled-bg": "#2e4338",
              "--bs-btn-disabled-border-color": "#2e4338",
              "--bs-card-green-light": "#cddbc9",
              "--bs-card-green-light-tr": "#cddbc9",
            }

            const customColors = orgData?.primary_color
              ? {
                  "--dark-green": primaryShadesColor?.dark,
                  "--text-color-dark": orgData?.font_color,
                  "--text-dark": orgData?.font_color,
                  "--text-light": orgData?.font_color,
                  "--chart-text": orgData?.font_color,
                  "--text-color-inactive-links": orgData?.font_color,
                  "--btn-disabled": orgData?.primary_color,
                  "--green-color-light": primaryShadesColor?.light,
                  "--green-color-light-table": "white",
                  "--green-color-bg-card": orgData?.primary_color,
                  "--green-color-bg-card-th": "white",
                  "--green-color-bg-card-tbl": "white",
                  "--bs-nav-link-color": orgData?.primary_color,
                  "--bs-nav-link-hover-color": orgData?.secondary_color,
                  "--bs-btn-color": "#fff",
                  "--bs-btn-bg": orgData?.primary_color,
                  "--bs-btn-border-color": orgData?.secondary_color,
                  "--bs-btn-hover-color": "#fff",
                  "--bs-btn-hover-bg": orgData?.secondary_color,
                  "--bs-btn-hover-border-color": orgData?.primary_color,
                  "--bs-btn-focus-shadow-rgb": "95, 106, 187",
                  "--bs-btn-active-color": " #fff",
                  "--bs-btn-active-bg": orgData?.primary_color,
                  "--bs-btn-active-border-color": orgData?.primary_color,
                  "--bs-btn-active-shadow": `inset 0 3px 5px ${orgData?.primary_color}`,
                  "--bs-btn-disabled-color": "#fff",
                  "--bs-btn-disabled-bg": orgData?.primary_color,
                  "--bs-btn-disabled-border-color": orgData?.secondary_color,
                  "--bs-card-green-light": primaryShadesColor?.light,
                  "--bs-card-green-light-tr": "#eee",
                }
              : {}

            const allColors = { ...defaultColors, ...customColors }

            Object.entries(allColors).forEach(([property, value]) => {
              document.documentElement.style.setProperty(property, value)
            })
          } else {
            localStorage.removeItem("orgData")
            if (history.location.pathname === "/lookup") {
              document.title = "Wendy Lookup"
            } else {
              document.title = "NFC Connect"
            }
          }
        } else {
          localStorage.removeItem("orgData")
          if (history.location.pathname === "/lookup") {
            document.title = "Wendy Lookup"
          } else {
            document.title = "NFC Connect"
          }
        }
      })
    }
  }, [])

  /* ################################# Access Token ############################## */

  const [userName, setUserName] = useState("")
  const [roomName, setRoomName] = useState("")
  const [token, setToken] = useState("")
  const [initials, setInitials] = useState("")
  const [showError, setShowError] = useState(null)
  const [showConsentMessage, setShowConsentMessage] = useState(false)
  const [showRoomData, setShowRoomData] = useState(null)

  const [getTwilioAccesToken, accessTokenResponse] = useLazyQuery(
    GET_tWILIO_ACCESS_TOKEN,
    { fetchPolicy: "no-cache" }
  )
  const [getTwilioAccesTokenWithAuth, accessTokenResponseWithAuth] =
    useLazyQuery(GET_tWILIO_ACCESS_TOKEN_WITHOUT_AUTH, {
      fetchPolicy: "no-cache",
    })

  useEffect(() => {
    if (appointment_id) {
      if (userData && userData.role && userData.role.id) {
        getTwilioAccesToken({
          variables: {
            argumentsInput: {
              id: parseInt(appointment_id),
            },
          },
        })
      }
    }
  }, [])

  React.useEffect(() => {
    if (
      accessTokenResponse.data &&
      accessTokenResponse.data?.getTwilioAccessToken
    ) {
      setToken(
        accessTokenResponse.data?.getTwilioAccessToken?.data
          ?.twilio_access_token
      )
      setShowConsentMessage(
        accessTokenResponse.data?.getTwilioAccessToken?.data?.room
          ?.recordParticipantsOnConnect
      )
      setShowRoomData(
        accessTokenResponse.data?.getTwilioAccessToken?.data?.room
      )
      setAppointmentId(
        accessTokenResponse.data?.getTwilioAccessToken?.data?.appointment?.id
      )
      setRoomName(`room-${appointment_id}`)
    } else if (
      accessTokenResponseWithAuth.data &&
      accessTokenResponseWithAuth.data?.getTwilioAccessTokenWithUser
    ) {
      setButtonLoading(false)
      setToken(
        accessTokenResponseWithAuth.data?.getTwilioAccessTokenWithUser?.data
          ?.twilio_access_token
      )
      setShowConsentMessage(
        accessTokenResponseWithAuth.data?.getTwilioAccessTokenWithUser?.data
          ?.room?.recordParticipantsOnConnect
      )
      setShowRoomData(
        accessTokenResponseWithAuth.data?.getTwilioAccessTokenWithUser?.data
          ?.room
      )
      setAppointmentId(
        accessTokenResponseWithAuth.data?.getTwilioAccessTokenWithUser?.data
          ?.appointment?.id
      )
      setRoomName(`room-${appointment_id}`)
    } else {
      setToken("")
    }
  }, [accessTokenResponse.data, accessTokenResponseWithAuth.data])

  const [getRoomParticipants, GetRoomParticipantsList] = useLazyQuery(
    GET_ROOM_PARTICIPANTS,
    {
      fetchPolicy: "no-cache",
    }
  )

  const getCurrentList = () => {
    getRoomParticipants({
      variables: {
        argumentsInput: {
          id: parseInt(appointment_id),
        },
      },
    })
  }

  useEffect(() => {
    if (
      GetRoomParticipantsList.data?.getRoomParticipants?.data &&
      GetRoomParticipantsList.data?.getRoomParticipants?.data
        ?.participants_names
    ) {
      const allParticipants =
        GetRoomParticipantsList.data?.getRoomParticipants?.data
          ?.participants_names
      const removeDisconnected = participants.filter(value =>
        allParticipants.includes(value.identity)
      )
      const participantsList = allParticipants.map(elem => {
        const identitySplit = elem?.split(":")
        const firstName = identitySplit[identitySplit.length - 2]
        const lastName = identitySplit[identitySplit.length - 1]
        return { firstName, lastName }
      })
      setparticipantsList(participantsList)
      if (participants.length !== removeDisconnected.length) {
        setParticipants(removeDisconnected)
      }
    }
  }, [GetRoomParticipantsList.data])

  /* ################################# appoinement details ############################## */

  const [appoinementId, setAppointmentId] = useState(0)
  const [issidebar, setIssidebar] = useState(true)
  const [appointmentDetails, setAppointmentDetails] = useState(null)
  const [appointmentForms, setAppointmentForms] = useState([])
  const [durationRemaining, setDurationRemaining] = useState(0)

  useEffect(() => {
    let therapistFirstName = "",
      therapistLastName = ""

    if (userData) {
      therapistFirstName = userData.first_name.charAt(0)
      therapistLastName = userData.last_name.charAt(0)
    } else {
      if (!appointmentDetails || !appointmentDetails.id) return

      setAppointmentId(appointmentDetails?.id)

      therapistFirstName = appointmentDetails?.first_name.charAt(0)
      therapistLastName = appointmentDetails?.last_name.charAt(0)
    }
    if (userData) {
      setInitials(
        therapistFirstName?.toLocaleUpperCase() +
          therapistLastName.toLocaleUpperCase()
      )
    } else {
      setInitials(
        firstName?.charAt(0).toLocaleUpperCase() +
          lastName?.charAt(0).toLocaleUpperCase()
      )
    }
  }, [appointmentDetails, userData])

  const [getAppointmentDetail, appointmentResponse] = useLazyQuery(
    GET_APPOINTMENT,
    { fetchPolicy: "no-cache" }
  )

  React.useEffect(() => {
    if (appoinementId) {
      getAppointmentDetail({
        variables: {
          argumentsInput: {
            id: parseInt(appoinementId),
          },
        },
      })
    }
  }, [roomName, token])
  const isLiveMeeting = appointment => {
    const startTime = appointment?.utc_datetime
      ? convertUTCToLocal(appointment.utc_datetime)
      : null
    let durationTimeInMin = calculateDuration(moment(), moment(startTime))
    setDurationRemaining(durationTimeInMin)
    if (durationTimeInMin <= 0) {
      return "Live"
    } else {
      return `In Next ${durationTimeInMin} min`
    }
  }

  React.useEffect(() => {
    if (
      appointment_id &&
      appointmentResponse.data &&
      appointmentResponse.data?.getAppointment
    ) {
      isLiveMeeting(appointmentResponse.data?.getAppointment?.data?.appointment)
      setId(appointmentResponse.data?.getAppointment?.data?.appointment?.id)
      setClientFeedbackSubmitted(
        appointmentResponse.data?.getAppointment?.data?.appointment
          ?.is_client_feedback_submitted
      )
      setCounsellorFeedbackSubmitted(
        appointmentResponse.data?.getAppointment?.data?.appointment
          ?.is_counsellor_feedback_submitted
      )
      setAppointmentDetails(
        appointmentResponse.data?.getAppointment?.data?.appointment
      )
      setAppointmentForms(
        appointmentResponse.data?.getAppointment?.data?.appointment
          ?.appointmentHasForms
      )
    } else {
      setAppointmentDetails({})
    }
  }, [appointmentResponse.data])

  /* ################################# Lobby  ############################## */

  const [preDesignScreen, setPreDesignScreen] = useState(false)

  const handleJoinVideoCall = () => {
    connectWithToken()
  }

  const startTime = appointmentDetails?.utc_datetime
    ? convertUTCToLocal(appointmentDetails.utc_datetime)
    : null
  const endTime = appointmentDetails?.duration
    ? moment(startTime).add(appointmentDetails.duration, "minutes")
    : null

  /* ################################# Room ############################## */

  function connectWithToken() {
    setIsConnecting(true)
    const participantConnected = participant => {
      setParticipants(participants => [...participants, participant])
      participant.tracks.forEach(publication => {
        if (publication.isSubscribed) {
          const track = publication.track
        }
      })
      participant.on("trackSubscribed", track => {
        if (track.kind === "video") {
          setIsRemoteVideoEnabled(track.isEnabled)
          track.on("disabled", () => {
            setIsRemoteVideoEnabled(false)
          })
          track.on("enabled", () => {
            setIsRemoteVideoEnabled(true)
          })
        }
      })
    }

    const participantDisconnected = participant => {
      setParticipants(participants =>
        participants.filter(p => p !== participant)
      )
      if (participants.length === 0) {
        // All remote participants have left the room
        // Do something here
        setIsRemoteVideoEnabled(false)
      }
    }

    getCurrentList()
    setInterval(getCurrentList, 5000)
    token &&
      TwilioVideo.connect(token, {
        video: isRemoteVideoEnabled,
        audio: isLobbyAudioEnabled,
        name: roomName,
      })
        .then(connectedRoom => {
          connectedRoom.on("participantConnected", participantConnected)
          connectedRoom.on("participantDisconnected", participantDisconnected)
          connectedRoom.participants.forEach(participantConnected)
          setRoom(connectedRoom)
          setIsConnecting(false)
          setPreDesignScreen(false)
          setReJoinScreenScreen(false)
        })
        .catch(error => {
          setShowError(error.message)
          console.error(
            `Unable to connect to Room \n
                [${error.message}] \n
                Try checking that your webcam is plugged in or that you have given your browser the correct permissions`
          )
        })
  }

  function leaveChat() {
    if (room) {
      if (room.localParticipant.state === "connected") {
        room.localParticipant.tracks.forEach(function (trackPublication) {
          trackPublication.track.stop()
        })
      }
      if (isLobbyVideo.localStream !== null) {
        disableLobbyVideo()
      }
      room.removeAllListeners()
      room.disconnect()
      setRoom(null)
      setParticipants([])
      setReJoinScreenScreen(true)
      setPreDesignScreen(false)
      if (userData?.role?.id == 2 || userData?.role?.id == 1) {
        stopRecordingFun(room.localParticipant)
      }
    }
  }

  //  /* ################################# Rejoin screen  ############################## */

  const [reJoinScreen, setReJoinScreenScreen] = useState(false)
  const [feedbackScreen, setFeedbackScreen] = useState(false)
  const [screenTrack, setScreenTrack] = useState(null)

  function handleRejoin() {
    // setReJoinScreenScreen(false)
    // setPreDesignScreen(true)
    window.location.reload(false)
  }

  function handleFeedback() {
    setReJoinScreenScreen(false)
    setFeedbackScreen(true)
  }

  const handleExit = () => {
    const unloadCallback = event => {
      event.preventDefault()
      event.returnValue = ""
      return ""
    }
    setReJoinScreenScreen(false)
    window.close()
    window.removeEventListener("beforeunload", unloadCallback)
    window.history.back()
  }

  /* ################################# Lobby Video Ref ############################## */

  const videoRef = useRef(null)

  useEffect(() => {
    getVideo()
  }, [videoRef])

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: true,
      })
      .then(stream => {
        setLobbyVideo({ localStream: stream })
        setLobbyVideoEnabled(true)
        setPreDesignScreen(true)
        setReJoinScreenScreen(false)
      })
      .catch(err => {
        console.error("error:", err)
      })
  }

  const handleVideo = useCallback(
    video => {
      if (video) {
        video.srcObject = isLobbyVideo.localStream
      }
    },
    [isLobbyVideo]
  )

  /* ################################# All Lobby buttons  ############################## */

  const handleLobbyUnmuteAudio = () => {
    setIsLobbyAudioEnabled(true)
    setIsAudioEnabled(true)
  }

  const handleLobbyMuteAudio = () => {
    setIsLobbyAudioEnabled(false)
    setIsAudioEnabled(false)
  }

  function disableLobbyVideo() {
    isLobbyVideo.localStream.getTracks().forEach(track => {
      track.stop()
    })
    setLobbyVideo({ localStream: null })
    setLobbyVideoEnabled(false)
  }

  function enableLobbyVideo() {
    getVideo()
  }

  /* ################################# All Video Room buttons  ############################## */

  function handleUnmuteAudio() {
    if (room) {
      if (room.localParticipant.state === "connected") {
        createLocalAudioTrack()
          .then(localAudioTrack => {
            return room.localParticipant.publishTrack(localAudioTrack, {
              name: "audio",
            })
          })
          .then(publication => {
            setIsAudioEnabled(publication.isTrackEnabled)
          })
      }
    }
  }

  function handleMuteAudio() {
    if (room) {
      if (room.localParticipant.state === "connected") {
        room.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable()
          publication.track.stop()
          publication.unpublish()
          setIsAudioEnabled(publication.isTrackEnabled)
        })
      }
    }
  }

  function disableVideo() {
    if (room) {
      if (room.localParticipant.state === "connected") {
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable()
          publication.track.stop()
          publication.unpublish()
          setIsVideoEnabled(publication.isTrackEnabled)
          if (isLobbyVideo.localStream !== null) {
            disableLobbyVideo()
          }
        })
      }
    }
  }

  function enableVideo() {
    if (room) {
      if (room.localParticipant.state === "connected") {
        createLocalVideoTrack()
          .then(localVideoTrack => {
            return room.localParticipant.publishTrack(localVideoTrack, {
              name: "video",
            })
          })
          .then(publication => {
            setIsVideoEnabled(publication.isTrackEnabled)
          })
      }
    }
  }

  useEffect(() => {
    if (isLobbyVideoEnabled) {
      enableVideo()
    } else {
      disableVideo()
    }
  }, [room, isLobbyVideoEnabled])

  useEffect(() => {
    if (isLobbyAudioEnabled) {
      handleUnmuteAudio()
    } else {
      handleMuteAudio()
    }
  }, [room, isLobbyAudioEnabled])

  const handleSidebar = () => {
    setIssidebar(!issidebar)
    setUserRemarks(true)
    setShowParticipants(true)
  }

  function isFirefox() {
    var mediaSourceSupport =
      !!navigator.mediaDevices.getSupportedConstraints().mediaSource
    var matchData = navigator.userAgent.match(/Firefox\/(\d+)/)
    var firefoxVersion = 0
    if (matchData && matchData[1]) {
      firefoxVersion = parseInt(matchData[1], 10)
    }
    return mediaSourceSupport && firefoxVersion >= 52
  }

  function isChrome() {
    return "chrome" in window
  }

  function canScreenShare() {
    return isChrome || isFirefox
  }

  function getUserScreen() {
    if (!canScreenShare()) {
      return
    }

    const displayMediaOptions = {
      video: {
        displaySurface: "window",
      },
      audio: false,
    }

    return navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
  }

  const handleScreenShare = () => {
    if (!isScreenShareOn) {
      // turn off video sharing because of the limitaiton of the UI
      // next if block is not required if we can handle a view with video + screenshare both for the same participant
      if (isVideoEnabled) disableVideo()

      getUserScreen()
        .then(function (stream) {
          const screenTrack = stream.getVideoTracks()[0]

          // Listen for the 'ended' event on the screenTrack
          screenTrack.addEventListener("ended", () => {
            // Unpublish the track if it has ended
            room.localParticipant.unpublishTrack(screenTrack)
            setScreenShareOn(null)
          })

          room.localParticipant.publishTrack(screenTrack, {
            name: "screen",
          })
          setScreenShareOn(screenTrack)
        })
        .catch(error => {
          console.error("Error capturing screen:", error)
        })
    } else {
      // Unpublish the track and stop it
      room.localParticipant.unpublishTrack(isScreenShareOn)
      isScreenShareOn.stop()
      setScreenShareOn(null)
    }
  }

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ])
  const [isShare, setIsShare] = useState(false)
  const [isCopyClick, setIsCopyClick] = useState(false)
  const handleShare = () => {
    setIsShare(!isShare)
    setIsCopyClick(false)
  }
  const urlRef = useRef(null)

  const copyURL = () => {
    urlRef.current.select()
    document.execCommand("copy")
    setIsCopyClick(true)
  }
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])
  const [userRemarks, setUserRemarks] = useState(true)
  const [showParticipants, setShowParticipants] = useState(true)
  const showUsers = () => {
    setShowParticipants(!showParticipants)
    setUserRemarks(true)
    setIssidebar(true)
  }
  const showRemarks = () => {
    setIssidebar(true)
    setUserRemarks(!userRemarks)
    setShowParticipants(true)
  }
  const [firstName, setFirstName] = useState(
    sessionUserParams ? sessionUserParams?.first_name : ""
  )
  const [lastName, setlLastName] = useState(
    sessionUserParams ? sessionUserParams?.last_name : ""
  )

  const handleName = (e, type) => {
    const regex = /^[a-zA-Z]*$/
    if (
      type === "fName" &&
      e.target.value.length <= 15 &&
      regex.test(e.target.value)
    ) {
      setFirstName(e.target.value.trim())
    }
    if (
      type === "lName" &&
      e.target.value.length <= 15 &&
      regex.test(e.target.value)
    ) {
      setlLastName(e.target.value.trim())
    }
  }
  const [buttonLoading, setButtonLoading] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [consentRes, setConsentRes] = useState(false)

  const [consentCheck, setConsentCheck] = useState(false)
  const handleConsentCheckToggle = e => {
    const ifChecked = e.target.checked
    setConsentCheck(ifChecked)
    if (ifChecked) {
      const consentData = {
        appointment_id: JSON.stringify(appointmentDetails?.id),
        first_name: userData ? userData?.first_name : firstName,
        last_name: userData ? userData?.last_name : lastName,
        user_id: userData ? userData?.id : null,
        extra_data: JSON.stringify(showRoomData),
        room_sid: showRoomData?.sid,
      }
      setSpinner(true)
      CreateConsent({
        variables: {
          createUserInput: consentData,
        },
      })
    }
  }

  useEffect(() => {
    if (
      CreateConsentRes?.data &&
      CreateConsentRes?.data?.createConsent?.status &&
      CreateConsentRes?.data?.createConsent?.code === 200
    ) {
      setSpinner(false)
      setConsentRes(true)
    }
  }, [CreateConsentRes?.data])

  const enterUser = () => {
    setButtonLoading(true)
    const videocallUser = {
      first_name: firstName,
      last_name: lastName,
    }
    sessionStorage.setItem("videocallUser", JSON.stringify(videocallUser))
    getTwilioAccesTokenWithAuth({
      variables: {
        argumentsInput: {
          id: parseInt(appointment_id),
          first_name: firstName.toUpperCase(),
          last_name: lastName.toUpperCase(),
        },
      },
    })
  }

  return (
    <>
      {/* check clint is login before meeting then display messgae for client */}

      {/* {(userData?.role?.role_name =='Client' && durationRemaining >= 0) || !userData ?
      <div className="d-flex justify-content-center align-items-center w-100 please-wait">
<div className="text-center m-3"> Please wait counsellor will join at {startTime && moment(startTime).format("h:mmA")}</div>
      </div>
      
      : */}
      <>
        {/* ################################# Lobby ##############################  */}
        {preDesignScreen && (
          <React.Fragment>
            <div className="account-pages my-5">
              <Container className="d-flex justify-content-center">
                <Row className="d-flex align-items-center justify-content-center gap-4 flex-column w-100">
                  <Col md={8} lg={6} xl={5}>
                    <h3 className="topbar-goodmorning-admin text-center">
                      {appointmentDetails?.type}
                    </h3>
                    <div
                      className={`${classes.preDesignContent} card-para-main`}
                    >
                      {startTime && moment(startTime).format("h:mmA")}
                      {endTime && " - "}
                      {endTime && moment(endTime).format("h:mmA")}
                      <span></span>
                      {startTime && " · "}
                      {startTime &&
                        calculateDurationBetweenTwoTimes(startTime, endTime)}
                      {startTime && userData?.role?.role_name == "Client" && (
                        <>
                          <div>Please wait, Counsellor will join</div>
                        </>
                      )}
                      {startTime &&
                        userData?.role?.role_name == "Counsellor" && (
                          <>
                            <div>Please wait, Client will join</div>
                          </>
                        )}
                    </div>
                  </Col>
                  {!token && !userData && (
                    <Col md={8} lg={6} xl={5}>
                      <Row>
                        <Col md={12}>
                          <p>
                            Welcome! Since you&apos;re not logged in, kindly
                            fill in the following details to join.
                          </p>
                        </Col>
                        <Col md={5}>
                          <input
                            name="first_name"
                            className="form-control default-redesign-input bg-light"
                            placeholder="Enter First Name"
                            type="text"
                            onChange={e => handleName(e, "fName")}
                            value={firstName}
                            autoComplete="off"
                          />
                        </Col>
                        <Col md={5}>
                          <input
                            name="last_name"
                            className="form-control default-redesign-input bg-light"
                            placeholder="Enter Last Name"
                            type="text"
                            onChange={e => handleName(e, "lName")}
                            value={lastName}
                            autoComplete="off"
                          />
                        </Col>
                        <Col xl={2}>
                          <button
                            disabled={!firstName || !lastName || buttonLoading}
                            onClick={enterUser}
                            className="btn btn-primary w-100"
                          >
                            Enter
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col
                    md={8}
                    lg={6}
                    xl={5}
                    className="d-flex justify-content-center"
                  >
                    <div className="video-container-lobby avatar-container-design">
                      {isLobbyVideo.localStream ? (
                        // <div
                        // className={`${classes.preDesignAvaterContainer} video-container avatar-container-design`}
                        // >
                        <video
                          className="video-room"
                          autoPlay
                          ref={handleVideo}
                        />
                      ) : (
                        // </div>
                        <div className="avatarContainer">
                          <div className="avatar avatar-icon">
                            <h5 className="avatar-text m-0">{initials}</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md={8} lg={6} xl={5}>
                    <ul className="d-flex justify-content-center align-items-cemter p-0 gap-2">
                      <li className="d-flex flex-column gap-1 cursor-pointer ">
                        <img
                          src={
                            isLobbyAudioEnabled ? lobbyMuteMic : lobbyUnmuteMic
                          }
                          width="48px"
                          height="48px"
                          onClick={
                            isLobbyAudioEnabled
                              ? handleLobbyMuteAudio
                              : handleLobbyUnmuteAudio
                          }
                        />
                      </li>
                      <li className="d-flex flex-column gap-1 cursor-pointer">
                        <img
                          src={isLobbyVideo.localStream ? videoOn : videoOff}
                          width="48px"
                          height="48px"
                          onClick={
                            isLobbyVideo.localStream
                              ? disableLobbyVideo
                              : enableLobbyVideo
                          }
                        />
                      </li>
                    </ul>
                  </Col>
                  {showError && (
                    <Col md={8} lg={6} xl={5}>
                      <p className="alert alert-warning">{showError}</p>
                    </Col>
                  )}
                  {showConsentMessage && (
                    <>
                      <Col
                        md={8}
                        lg={6}
                        xl={5}
                        className="d-flex gap-3 align-items-center mx-2"
                      >
                        <div>
                          {spinner ? (
                            <div>
                              <Spinner />
                            </div>
                          ) : (
                            <input
                              type="checkbox"
                              className="form-check-input p-2"
                              checked={consentCheck}
                              disabled={consentRes}
                              onClick={e => handleConsentCheckToggle(e)}
                            />
                          )}
                        </div>
                        <p className="m-0 p-0 ms-2 font-weight-bold">
                          Kindly note that this video call could be recorded.
                          Your consent is necessary before we proceed.
                        </p>
                      </Col>
                    </>
                  )}
                  {!showError && (
                    <Col md={8} lg={6} xl={5} className="mb-5">
                      <button
                        disabled={
                          !token ||
                          isConnecting ||
                          (!consentCheck && showConsentMessage)
                        }
                        onClick={handleJoinVideoCall}
                        className="btn btn-primary w-100"
                      >
                        {isConnecting ? "Joining..." : "Join Video Call"}
                      </button>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
          </React.Fragment>
        )}
      </>
      {/* } */}

      {/* ################################# Room ##############################  */}

      {!!room && (
        <div className={classes.app}>
          <main className={classes.main}>
            <div className="c-video">
              <div
                className={classes.webcam}
                style={{ gap: issidebar ? "0" : "10px" }}
              >
                <div
                  className={`${classes.webcamContainer} ${
                    !userData?.role
                      ? classes.w100
                      : issidebar
                      ? classes.w100
                      : windowSize[0] < 1079
                      ? classes.w100
                      : classes.w70
                  }`}
                >
                  {consentCheck && (
                    <div className="recordStatus">
                      <img src={Recording} height={30} width={70} />
                    </div>
                  )}
                  {participants && participants.length > 0 ? (
                    ""
                  ) : (
                    <div className="wait-for-other-participents">
                      <span>Waiting for other participant...</span>
                    </div>
                  )}

                  {issidebar ? (
                    <i
                      className={classNames(
                        `mdi mdi-alert-circle m-0 px-1 cursor-pointer 669 ${
                          !userData?.role ? "d-none" : ""
                        } `,
                        classes.info
                      )}
                      onClick={() => handleSidebar()}
                    />
                  ) : (
                    <i
                      className={classNames(
                        `mdi mdi-alert-circle m-0 px-1 cursor-pointer 679 ${
                          !userData?.role ? "d-none" : ""
                        }`,
                        classes.info
                      )}
                      onClick={() => handleSidebar()}
                    />
                  )}
                  {/* <div className={classes.webcamHeader}>
                    <i
                      className="mdi mdi-alert-circle m-0 px-1 cursor-pointer 690"
                      onClick={handleMenuClick}
                    ></i>
                  </div> */}
                  <div className={classes.videoControls}>
                    <ul className={classes.videoControlsAlign}>
                      <li className="d-flex flex-column gap-1">
                        <img
                          src={isAudioEnabled ? muteMic : unmuteMic}
                          className={classes.videoControl}
                          onClick={
                            isAudioEnabled ? handleMuteAudio : handleUnmuteAudio
                          }
                        />
                        <span className={classes.textControls}>
                          {isAudioEnabled ? "Mute" : "Unmute"}
                        </span>
                      </li>

                      <li className="d-flex flex-column gap-1">
                        <img
                          src={isVideoEnabled ? videoTurnOn : videoTurnOff}
                          className={classes.videoControl}
                          onClick={isVideoEnabled ? disableVideo : enableVideo}
                        />
                        <span className={classes.textControls}>
                          {isVideoEnabled ? "Video" : "Video"}
                        </span>
                      </li>
                      <li
                        className={`d-flex flex-column gap-1 `}
                        onClick={() => showUsers()}
                      >
                        <span
                          className={`mdi mdi-account-multiple text-white ${classes.videoControl}`}
                        ></span>
                        <span className={classes.textControls}>
                          {"Participants"}
                        </span>
                      </li>
                      {/* {canScreenShare() && (
                        <li
                          className="d-flex flex-column gap-1"
                          onClick={() => handleScreenShare()}
                        >
                          <div className={classes.videoControl}>
                            <i className={`mdi mdi-monitor-screenshot`}></i>
                          </div>
                          <span className={classes.textControls}>
                            {isScreenShareOn ? "Stop" : "Screen"}
                          </span>
                        </li>
                      )} */}

                      <li
                        className={`d-flex flex-column gap-1 ${
                          userData?.role?.id < 3 ? "" : "d-none"
                        }`}
                        onClick={() => handleShare()}
                      >
                        <img src={ShareIcon} className={classes.videoControl} />

                        <span className={classes.textControls}>{"Invite"}</span>
                      </li>
                      <li
                        className={`d-flex flex-column gap-1 ${
                          userData?.role?.id === 2 || userData?.role?.id === 1
                            ? ""
                            : "d-none"
                        }`}
                        onClick={() => showRemarks()}
                      >
                        <span
                          className={`fas fa-clipboard text-white ${classes.videoControl}`}
                        ></span>
                        <span className={classes.textControls}>
                          {"Remarks"}
                        </span>
                      </li>

                      <li className="d-flex flex-column gap-1">
                        <img
                          src={leaveCall}
                          className={classes.videoControl}
                          onClick={leaveChat}
                        />
                        <span className={classes.textControls}>{"Leave"}</span>
                      </li>
                    </ul>
                  </div>
                  <Card
                    className={`twilio-video-room-meeting-ready-card d-flex justify-content-center align-item-center p-3 ${
                      isShare ? "" : "d-none"
                    }`}
                  >
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="invoice-list-fsize-14 mb-1 font-weight-bold">
                          Your Meeting&apos;s Ready
                        </div>
                        <div onClick={() => handleShare()}>
                          <i className="mdi mdi-close pointer twilio-video-room-icon-style-fsize20 font20"></i>
                        </div>
                      </div>
                      <div className="invoice-list-fsize-14 mb-1">
                        Send this to a person you want to meet with. Please note
                        that this link can accommodate up to{" "}
                        {appointmentDetails.participants == 2 && "2"}{" "}
                        {appointmentDetails.participants == 10 && "10"}{" "}
                        {appointmentDetails.participants == 50 && "50"}{" "}
                        participants at maximum.
                      </div>
                      <div className="d-flex align-items-center twilio-video-room-fsize-14-link m-0">
                        <div className="cl-green" onClick={() => copyURL()}>
                          <i
                            className={`mdi mdi-content-copy pointer twilio-video-room-icon-style-fsize20 font20 ${
                              isCopyClick ? "text-primary" : ""
                            }`}
                          />
                        </div>
                        <div className="twilio-video-room-margin-left20">
                          <span>{window?.location?.href}</span>
                        </div>
                        <input
                          ref={urlRef}
                          type="text"
                          value={window?.location?.href}
                          readOnly
                          className="twilio-video-room-pos-abs-left"
                        />
                      </div>
                    </div>
                  </Card>
                  <div
                    className={`overflow-hidden ${classes.webcamVideo} ${
                      participants?.length <= 3 && "video-container"
                    }  ${
                      participants?.length > 3 &&
                      participants?.length <= 4 &&
                      "video-container2"
                    } ${
                      (!issidebar || !userRemarks || !showParticipants) &&
                      participants?.length > 3 &&
                      participants?.length <= 4
                        ? "w-75"
                        : ""
                    } ${participants?.length > 4 && "video-container3"}`}
                  >
                    {!!participants && !!participants.length ? (
                      <>
                        {participants.map((participant, ind) => (
                          <RemoteVideo
                            key={participant.sid}
                            user={participant}
                            isVideoEnabled={isRemoteVideoEnabled}
                            isAudioEnabled={isAudioEnabled}
                            appointmentDetails={appointmentDetails}
                            participants={participants}
                            ind={ind}
                          ></RemoteVideo>
                        ))}
                      </>
                    ) : (
                      <Video
                        key={room.localParticipant.sid}
                        user={room.localParticipant}
                        isVideoEnabled={isVideoEnabled}
                        isAudioEnabled={isAudioEnabled}
                        appointmentDetails={appointmentDetails}
                      ></Video>
                    )}
                  </div>
                  {participants.length > 0 && (
                    <div
                      className={`${classes.webcamLocalVideo} mini-video video-container`}
                    >
                      <Video
                        key={room.localParticipant.sid}
                        user={room.localParticipant}
                        isVideoEnabled={isVideoEnabled}
                        isAudioEnabled={isAudioEnabled}
                        appointmentDetails={appointmentDetails}
                      ></Video>
                    </div>
                  )}
                  {/* <div
                    className={classNames(
                      classes.overlay,
                      classes.activeOverlay
                    )}
                  >
                    <Tabs
                      appointmentForms={appointmentForms}
                      appointmentDetails={appointmentDetails}
                    />
                  </div> */}
                </div>
                <Card
                  className={`${classes.card} ${
                    issidebar ? classes.w0 : classes.w30
                  } ${!userData?.role ? "d-none" : ""}`}
                >
                  <Tabs
                    appointmentForms={appointmentForms}
                    appointmentDetails={appointmentDetails}
                    handleSidebar={handleSidebar}
                  />
                </Card>
                <Card
                  className={`${classes.card} ${
                    userRemarks ? classes.w0 : `${classes.w40} `
                  } ${!userData?.role ? "d-none" : ""}`}
                >
                  <Remarks username={appointmentDetails?.client?.id} />
                </Card>
                <Card
                  className={`${classes.card} ${
                    showParticipants ? classes.w0 : `${classes.w40} `
                  }`}
                >
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <p className="h4 font-weight-bold ms-2">Participants</p>
                    <p
                      className="btn h6 me-3 h5 font-weight-bold"
                      onClick={() => showUsers()}
                    >
                      X
                    </p>
                  </div>
                  <div className="mt-3">
                    {participantsList.map(elem => {
                      return (
                        <>
                          <p className="border border-dark p-2 rounded m-1 my-3 h6 text-capitalize font-size-18">
                            {elem.firstName.toLowerCase()}{" "}
                            {elem.lastName.toLowerCase()}
                          </p>
                        </>
                      )
                    })}
                  </div>
                </Card>
              </div>
            </div>
          </main>
        </div>
      )}

      {/* ################################# Rejoin screen ##############################  */}

      {reJoinScreen && (
        <React.Fragment>
          <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
            <Container>
              <Row className="d-flex align-items-center justify-content-center gap-4 flex-column w-100">
                <Col md={8} lg={6} xl={5}>
                  <h3 className={`appointment-card-name text-center`}>
                    You Left the Meeting
                  </h3>
                  <div className={`${classes.preDesignContent} card-para-main`}>
                    {appointmentDetails?.type}
                  </div>
                </Col>
                <Col md={12} lg={9} xl={8}>
                  <div className="d-flex flex-wrap justify-content-center align-item-center gap-3">
                    {!clientFeedbackSubmitted && userData?.role?.id === 2 && (
                      <button
                        className={classNames(
                          `btn btn-primary w-25 search-input-search-appointments`
                        )}
                        onClick={handleFeedback}
                      >
                        <span>Submit Feedback</span>
                      </button>
                    )}
                    {!counsellorFeedbackSubmitted &&
                      userData?.role?.id === 3 && (
                        <button
                          className={classNames(
                            `btn btn-primary search-input-search-appointments`,
                            classes.feedbackBtn
                          )}
                          onClick={handleFeedback}
                        >
                          <span className={classes.preJoinBtnText}>
                            Submit Feedback
                          </span>
                        </button>
                      )}
                    <button
                      className="btn btn-inactive w-25 search-input-search-appointments"
                      onClick={handleRejoin}
                    >
                      <span>Rejoin</span>
                    </button>

                    <button
                      className="btn btn-disabled w-25 search-input-search-appointments"
                      onClick={handleExit}
                    >
                      <span>Exit</span>
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      )}

      {/* ################################# Feedback Form ##############################  */}

      {feedbackScreen && (
        <ConsultationFeedback id={id} isJoinFromVideoCall={true} />
      )}
    </>
  )
}

export default VideoRoom
