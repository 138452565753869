import { useLazyQuery } from "@apollo/client"
import React, { useCallback, useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"
import { GET_ORGANIZATION } from "../Organizations/gql/Gql"
import { debounce } from "lodash"
import Loader from "../../components/Common/Loader"

const LookUp = () => {
  const storedData = localStorage.getItem("orgData")
  const orgDetails = storedData ? JSON.parse(storedData) : null
  const [search, setSearch] = useState("")
  const [organizationsList, setOrganizationsList] = useState([])
  const [loading, setLoading] = useState(false)

  const [getOrganizations, getOrganizationsList] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: "no-cache",
    }
  )
  const openDomain = url => {
    window.open(url, "_blank")
  }
  useEffect(() => {
    if (
      getOrganizationsList?.data?.getOrganizations?.data &&
      getOrganizationsList?.data?.getOrganizations?.data?.organizations
    ) {
      const orgList =
        getOrganizationsList?.data?.getOrganizations?.data?.organizations
      setOrganizationsList(orgList)
      setLoading(false)
    }
  }, [getOrganizationsList.data])

  const getOrganizationFun = search => {
    if (search) {
      setLoading(true)
      getOrganizations({
        variables: {
          argumentsInput: {
            limit: 100,
            page: 1,
            search: search || "",
            sort: "id",
            sort_dir: "DESC",
          },
        },
      })
    }
  }

  const delaySearch = useCallback(
    debounce(search => {
      getOrganizationFun(search)
    }, 1000),
    []
  )
  const handlesearch = e => {
    setSearch(e.target.value.toLowerCase())
    delaySearch(e.target.value.toLowerCase())
  }
  return (
    <React.Fragment>
      <div>
        <div className="container-fluid p-0 m-0">
          <div className={orgDetails ? "lookUpHeader" : "lookUpHead"}>
            <img
              src={orgDetails?.favicon ? orgDetails?.favicon : "/wendyImg.jpg"}
              height="40"
            />
          </div>
          <Row className="align-items-center justify-content-center w-100 margin-zero-small">
            <Col md={12}>
              <div className="lookUpBody">
                <p className="h2">Lookup a Wendy Account</p>
                <p className="h4">
                  Search for Therapy Practice below to look up.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center flex-column w-100 margin-zero-small">
            <Col md={4}>
              <div className="lookUpSearch">
                <Input
                  name="organizationName"
                  className="form-control default-redesign-input bg-light"
                  placeholder="Enter Therapy Practice"
                  type="text"
                  onChange={e => handlesearch(e)}
                  value={search}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={4}>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              ) : (
                <div className="lookUpResults">
                  {organizationsList.length > 0 ? (
                    <>
                      {organizationsList.map((elem, index) => (
                        <div
                          key={index}
                          className="searchElement py-2 my-2 px-2"
                        >
                          <Row>
                            <Col className="col-2 d-flex align-items-center ">
                              <img
                                src={
                                  elem?.id === 1
                                    ? "/favicon.png"
                                    : elem?.favicon
                                }
                                height="50"
                                width="50"
                                className="rounded-circle"
                              />
                            </Col>
                            <Col className="col-7 p-0 m-0 ">
                              <p className="font-weight-bold orgTitle text-capitalize p-0 m-0">
                                {elem?.name}
                              </p>
                              <span className="font-weight-bold h6 p-0 m-0">
                                {elem?.domain}
                              </span>
                            </Col>
                            <Col className="col-3 d-flex align-items-center justify-content-center   p-0 m-0 ">
                              <Button
                                className="btn btn-inactive waves-effect waves-light"
                                onClick={() => openDomain(elem?.domain)}
                              >
                                Open
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </>
                  ) : search.length > 0 ? (
                    <div className="text-center">
                      <p className="mt-2">
                        Nope, couldn&lsquo;t find anything.
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LookUp
