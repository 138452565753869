import React, { useState, useEffect } from "react";
import SesssionType from "./tabs/SesssionType";
import FeedbackSubmitted from "./tabs/FeedbackSubmitted";
import OtherQuestions from "./tabs/OtherQuestions";
import RatingQuestions from "./tabs/RatingQuestions";
import { GET_QUESTIONS } from "./gql/gql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";

function FeedbackConsultation(props) {

  let { appointment_id } = useParams();
  const { id } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedSessionTypeId, setSelectedSessionTypeId] = useState(null);
  const [feedbackFormId, setFeedbackFormId] = useState(null);
  const [otherQuestionList, setOtherQuestionList] = useState([]);
  const [ratingQuestionList, setRatingQuestionList] = useState([]);
  const [isSpinner, setIsSpinner] = useState(false);

  const handleNext = (nextStep) => {
    setActiveStep((prevActiveStep) => prevActiveStep + nextStep);
  };

  const handleBack = (nextStep) => {
    setActiveStep((prevActiveStep) => prevActiveStep - nextStep);
  };

  //************************** get session type ***************************** */

  const [getQuestions, questionsDetailResponse] = useLazyQuery(GET_QUESTIONS, { fetchPolicy: "no-cache" });

  function getQuestionsListFun(selectedId) {
    getQuestions({
      variables: {
        getQuestions: {
          id: selectedId,
          appointment_id: parseInt(id ? id : appointment_id)
        }
      }
    });
  }

  useEffect(() => {
    if (
      questionsDetailResponse.data &&
      questionsDetailResponse.data.getQuestions &&
      questionsDetailResponse.data.getQuestions.status &&
      questionsDetailResponse.data.getQuestions.code === 200
    ) {
      setIsSpinner(false);
      setFeedbackFormId(questionsDetailResponse.data.getQuestions.data.feedback.id);
      const otherQuestionsData = questionsDetailResponse.data.getQuestions.data.otherQuestions.map((obj) => {
        return {
          id: obj.id,
          value: "",
          description: obj.description
        };
      });
      const sortedOtherQuestionsData = otherQuestionsData.sort((a, b) => a.id - b.id);
      setOtherQuestionList([...otherQuestionsData]);
      if (selectedSessionTypeId === 4) {
        const ratingQuestionData = questionsDetailResponse.data.getQuestions.data.ratingQuestions.map((obj) => {
          return {
            id: obj.id,
            value: "",
            description: obj.description
          };
        });
        const sortedRatingQuestionData = ratingQuestionData.sort((a, b) => a.id - b.id);
        setRatingQuestionList([...sortedRatingQuestionData]);
      }
    }
    else {
      setIsSpinner(true);
      setOtherQuestionList([]);
      setRatingQuestionList([]);
    }
  }, [questionsDetailResponse.data]);

  return (
    <div>
      {activeStep === 0 &&
        <SesssionType
          handleNext={handleNext}
          selectedSessionTypeId={selectedSessionTypeId}
          setSelectedSessionTypeId={setSelectedSessionTypeId}
          getQuestionsListFun={getQuestionsListFun}
        />
      }

      {activeStep === 1 &&
        <RatingQuestions
          isSpinner={isSpinner}
          handleNext={handleNext}
          handleBack={handleBack}
          ratingQuestionList={ratingQuestionList}
          setRatingQuestionList={setRatingQuestionList}
        />
      }

      {activeStep === 2 &&
        <OtherQuestions
          isSpinner={isSpinner}
          handleNext={handleNext}
          handleBack={handleBack}
          feedbackFormId={feedbackFormId}
          selectedSessionTypeId={selectedSessionTypeId}
          ratingQuestionList={ratingQuestionList}
          otherQuestionList={otherQuestionList}
          setOtherQuestionList={setOtherQuestionList}
        />
      }

      {activeStep === 3 &&
        <FeedbackSubmitted
          handleBack={handleBack}
        />
      }
    </div>
  )
}

export default FeedbackConsultation