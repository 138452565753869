import { gql } from "@apollo/client"
export const GET_COUNSELLING = gql`
  query GetCounselling($getCounsellingInput: CreateCounsellingInputDto!) {
    getCounselling(getCounsellingInput: $getCounsellingInput) {
      id
      name
      item_id
      created_at
      updated_at
      deleted_at
      status
      slug
      article
      sidebar_heading
      main_heading
      url
      subtype
    }
  }
`
export const SUBMIT_COUNSELLING = gql`
  mutation CreateOrUpdateCounselling(
    $updateCounsellingInput: CreateCounsellingInputDto!
  ) {
    createOrUpdateCounselling(updateCounsellingInput: $updateCounsellingInput) {
      id
      article
      created_at
      main_heading
      name
      sidebar_heading
      status
      subtype
      updated_at
      url
    }
  }
`
export const DEL_COUNSELLING = gql`
  mutation RemoveCounselling(
    $updateCounsellingInput: CreateCounsellingInputDto!
  ) {
    removeCounselling(updateCounsellingInput: $updateCounsellingInput) {
      name
    }
  }
`
