import { useQuery, gql } from "@apollo/client";


export const SIGNIN_GQL = gql`
query Query {
  signInInfo {
    code
    status
    data {
        token
      user {
        id
        name
        email
        mobile_number
        is_active
        first_name
        last_name
        calendar {
          name
          id
        }
        role {
          role_name,
          slug
          id
        }
      }
    }
  }
}
`;

