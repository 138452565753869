import { gql } from "@apollo/client"

export const CREATE_EDIT_USER_ROLE = gql`
mutation CreateAdminUser($roleInput: RoleInput) {
  createEditRole(roleInput: $roleInput) {
    code
    status
    data {
      role {
        id       
        role_name
        slug
      }
    }
  }
}
`;

export const GET_USER = gql`
query GetUser($userInput: UserInput){
  getUser(userInput: $userInput) {
    code
    status
    message
    data {
      permission
      user {
        id
        name
        email
        mobile_number
        role_id
        is_active
        designation
        role {
          id
          role_name
        }
      }
    }
  }
}
`;

export const GET_USER_ROLE = gql`
query GetRoles($argumentsInput: ArgumentsInput) {
  getRoles(argumentsInput: $argumentsInput) {
    code
    status
    data {
      roles {
        id
        created_at
        updated_at
        deleted_by
        created_by
        role_name
        slug
        is_active
        description
      }
      totalCount
    }
  }
}
`

export const MODULES = gql`
    query getModules($argumentsInput:ArgumentsInput){
        getModules(argumentsInput:$argumentsInput){
            code
            status
            message
            data{
                modules{
                    id
                    name
                    description
                    slug
                    created_at
                    methods{
                        id
                        name
                        slug
                    }
                }
            }
        }
    }
`
export const GET_PERMISSION = gql`
    query GetPermission($argumentsInput: ArgumentsInput!) {
        getPermission(argumentsInput: $argumentsInput) {
            code
            status
            message
            data {
                permission
            }
        }
    }
`
export const GET_SINGLE_ROLE = gql`
  query($argumentsInput: ArgumentsInput) {
    getRole(argumentsInput: $argumentsInput) {
      code
      data {
        role {
          permission
          is_active
          role_name
          description
        }
      }
    }
  }
`

export const DELETE_USER_ROLE = gql`
mutation Mutation($roleInput: RoleInput) {
  deleteRole(roleInput: $roleInput) {
    code
    status
    message
  }
}
`
