import React from "react"
import { Card, CardBody, Button, Progress } from "reactstrap"
import useStyles from "../styles"
import classNames from "classnames"
import style from "./style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"
function TotalClient(props) {
  const { dashboardData } = props
  const classes = useStyles()

  const totalClients = [
    {
      id: 1,
      title: "Direct Active Clients - ICBC",
      color: "#FF6361",
      value: dashboardData?.active_icbc ? dashboardData?.active_icbc : 0,
      total: 100,
    },
    {
      id: 2,
      title: "Direct Deactivated Clients - ICBC",
      color: "#93C3B3",
      value: dashboardData?.deactive_icbc ? dashboardData?.deactive_icbc : 0,
      total: 100,
    },
    {
      id: 3,
      title: "Direct Active Clients - Other Direct",
      color: "#7262A1",
      value: dashboardData?.active_other ? dashboardData?.active_other : 0,
      total: 100,
    },
    {
      id: 4,
      title: "Direct Deactivated Client - Other Direct",
      color: "#BC5090",
      value: dashboardData?.deactive_other ? dashboardData?.deactive_other : 0,
      total: 100,
    },
    {
      id: 5,
      title: "Non Direct Active Clients",
      color: "#FFA600",
      value: dashboardData?.non_direct_active_client
        ? dashboardData?.non_direct_active_client
        : 0,
      total: 100,
    },
    {
      id: 6,
      title: "Non Direct Deactive Clients",
      color: "#29ABE2",
      value: dashboardData?.non_direct_deactive_client
        ? dashboardData?.non_direct_deactive_client
        : 0,
      total: 100,
    },
  ]

  return (
    <Card className="light-green-bg-card-table">
      <CardBody>
        <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
          <h4 className={classNames("card-title", classes.cardTitle)}>
            Total Clients
          </h4>
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={
              <div className="invoice-list-fsize-14">
                This will reflect the bifurcation of clients that the counsellor
                have.
              </div>
            }
            arrow
          >
            <i
              className={`color-dark-green ul-bg-dark uil uil-info-circle ${style.circleinfo}`}
            ></i>
          </Tooltip>
        </div>
        <div className="d-flex flex-column gap-3 mt-3">
          {dashboardData &&
            totalClients.map((n, index) => (
              <div
                key={index}
                className={classNames(classes.totalClientContainer)}
              >
                <div className={classes.totalClientTitle}>{n.title}</div>
                <div className={classes.totalClientProgress}>
                  <div className={classNames("progress", classes.progressbar)}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={n.value}
                      aria-valuemin="0"
                      aria-valuemax="50"
                      style={{
                        width: `${n.value}%`,
                        backgroundColor: "#153c27",
                        borderRadius: "10px",
                      }}
                    >
                      <span>{n.value}%</span>
                    </div>
                  </div>
                  {/* <div className={classes.totalClientTotal}>{n.total}</div> */}
                </div>
              </div>
            ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default TotalClient
