import PropTypes from "prop-types"
import Gleap from "gleap"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap"

// Redux
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser } from "../../store/actions"

// import images
// import logo from "../../assets/images/logo.png"
// import logolight from "../../assets/images/logo-light.png"
import { useLazyQuery, useMutation } from "@apollo/client"

import { ON_BOARD, SIGNIN_GQL } from "./gql/loginGql"
import { setPermission } from "../../functions/getLoginData"

import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"

import logoNew from "../../assets/images/nfcld.png"
import loginAccent from "../../assets/images/accent-login.svg"

const OnBoard = props => {
  const dispatch = useDispatch()
  const [inProgress, setInProgress] = useState(false)
  const [user, setUser] = useState({})
  const [isMobileLogin, setIsMobileLogin] = useState(true)
  const [errorMsg, setErrorMsg] = useState(false)
  let history = useHistory()

  let fName =
    localStorage.getItem("first_name") !== "null" &&
    localStorage.getItem("first_name")
  let lName =
    localStorage.getItem("last_name") !== "null" &&
    localStorage.getItem("last_name")
  let email =
    localStorage.getItem("email") !== "null" && localStorage.getItem("email")
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: fName || "",
      last_name: lName || "",
      age: "",
      email: email || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please enter your first name"),
      last_name: Yup.string().required("Please enter your last name"),
      age: Yup.string()
        .required("Please enter your age")
        .max(3, "age digit must be 3 caracter only"),
      email: Yup.string().required("Please enter your email"),
    }),
    onSubmit: values => {
      signInUserFun(
        values.first_name,
        values.last_name,
        values.age,
        values.email
      )
      localStorage.removeItem("first_name")
      localStorage.removeItem("last_name")
      localStorage.removeItem("email")
    },
  })

  const { error } = useSelector(state => ({ error: state.Login.error }))

  useEffect(() => {
    let userData = localStorage.getItem("onboard")
    if (userData) {
      setUser(JSON.parse(userData))
    }
  }, [])
  useEffect(() => {
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })

  //***************************************************************** */

  const [onBoard, onBoardResponse] = useMutation(ON_BOARD, {
    fetchPolicy: "no-cache",
  })
  function signInUserFun(first_name, last_name, age, email) {
    setInProgress(true)
    onBoard({
      variables: {
        updateUserInput: {
          id: user.user.id,
          first_name: first_name,
          last_name: last_name,
          email: email,
          age: age.toString(),
          is_active: true,
          // "device_token": "abcd"
        },
      },
    })
  }
  React.useEffect(() => {
    setInProgress(false)
    if (
      onBoardResponse.data &&
      onBoardResponse.data.UpdateClient.code == 200 &&
      onBoardResponse.data.UpdateClient.data
    ) {
      // user identification
      const userDetails = user.user
      toast.success(onBoardResponse.data.UpdateClient.message)
      setTimeout(() => {
        Gleap.identify(userDetails.id, {
          name: userDetails.name,
          email: userDetails.email,
          phone: userDetails.mobile_number,
        })
        user.user.first_name =
          onBoardResponse.data.UpdateClient.data.user.first_name
        user.user.last_name =
          onBoardResponse.data.UpdateClient.data.user.last_name
        dispatch(
          loginUser(user, props.history, history.location.state?.from?.pathname)
        )
        setPermission(user.permission)
        localStorage.removeItem("onBoard")
      }, 3000)
    }
  }, [onBoardResponse.data])
  const handleWithOtpButton = () => {
    history.push("/login")
    setIsMobileLogin(true)
  }

  //******************************************************************************************** */
  return (
    <React.Fragment>
      <div className="account-pages  login-account-pages pt-sm-5">
        <ToastContainer />
        <Container>
          <Row className="align-items-center">
            <Col
              lg={6}
              xl={5}
              className="align-items-center justify-content-space-between login-left-section"
            >
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logoNew}
                    alt=""
                    height="80"
                    className="logo logo-dark"
                  />
                </Link>
              </div>
              <img
                src={loginAccent}
                height="120"
                className="accent-login-image"
              />
            </Col>
            <Col
              xl={7}
              lg={6}
              className="align-items-center justify-content-space-between"
            >
              <Row className="align-items-center justify-content-center">
                <Col md={8} lg={9} xl={9}>
                  <Card className="custom-card">
                    <CardBody className="p-2">
                      <div className="mt-2">
                        <h1 className="mb-4 h1-login-details">
                          Welcome on board !
                        </h1>
                        <p className="text-muted">
                          Your account has been created successfully. Now
                          let&apos;s build your profile.
                        </p>
                      </div>
                      <div className="mt-4 margin-top-login-page">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {/* {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null} */}

                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <Input
                              id="first_name"
                              name="first_name"
                              className="form-control"
                              placeholder="Enter first name"
                              type="text"
                              onChange={e =>
                                validation.setFieldValue(
                                  "first_name",
                                  e.target.value.charAt(0).toUpperCase() +
                                    e.target.value.slice(1)
                                )
                              }
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              invalid={
                                validation.touched.first_name &&
                                validation.errors.first_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.first_name &&
                            validation.errors.first_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.first_name}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              id="last_name"
                              name="last_name"
                              value={validation.values.last_name || ""}
                              type="text"
                              placeholder="Enter last name"
                              onChange={e =>
                                validation.setFieldValue(
                                  "last_name",
                                  e.target.value.charAt(0).toUpperCase() +
                                    e.target.value.slice(1)
                                )
                              }
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.last_name &&
                                validation.errors.last_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.last_name &&
                            validation.errors.last_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 form-div-login-page">
                            <Label className="form-label form-label-login">
                              Email
                            </Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter your email address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Age</Label>
                            <Input
                              name="age"
                              value={validation.values.age || ""}
                              type="number"
                              placeholder="Enter age"
                              min={18}
                              max={70}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.age && validation.errors.age
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.age && validation.errors.age ? (
                              <FormFeedback type="invalid">
                                {validation.errors.age}
                              </FormFeedback>
                            ) : null}
                          </div>
                          {!inProgress ? (
                            <div className="my-4">
                              <button
                                className="button-default-login-page btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Next
                              </button>
                            </div>
                          ) : (
                            <div className="my-4">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                                disabled
                              >
                                <Spinner
                                  type="grow"
                                  className="me-1 align-middle spinner-grow-sm "
                                  color="light"
                                />
                                Processing...
                              </button>
                            </div>
                          )}
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(OnBoard)

OnBoard.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
