import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import { debounce } from "lodash"
import { formatToCommonDate, hasPermission } from "../../functions/functions"
import { GET_PAYMENT_TYPES } from "./gql/Gql"
import { useLazyQuery } from "@apollo/client"
import Loader from "../../components/Common/Loader"
import Select from "react-select"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setPaymentType } from "../../store/actions"
import PaymentList from "./PaymentLists"
import PageNotFound from "../404"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
const PaymentTypes = () => {
  const dispatch = useDispatch()
  let paymentStore = useSelector(state => {
    return state?.paymentTypeManage?.paymentTypes?.list || []
  })
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
  })
  const [getPaymentTypes, getPaymentTypesRes] = useLazyQuery(
    GET_PAYMENT_TYPES,
    { fetchPolicy: "no-cache" }
  )
  let history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [list, setList] = useState([])
  const [isSpinner, setIsSpinner] = useState(true)
  const [editable, setEditable] = useState(true)
  const [totalCount , setTotalCount] = useState(20)
  const getPaymentTypesApi = async (limit, page, search) => {
    getPaymentTypes({
      variables: {
        argumentsInput: {
          search: search.trim(),
          limit:limit || 20,
          page:page,
        },
      },
    })
  }
  useEffect(() => {
    const { sizePerPage, page } = state
    getPaymentTypesApi(sizePerPage, page, searchText).catch(e =>
      setIsSpinner(false)
    )
  }, [])
  const callApi = () => {
    const { sizePerPage, page } = state
    getPaymentTypesApi(sizePerPage, page, searchText).catch(e =>
      setIsSpinner(false)
    )
  }

  const delaySearch = useCallback(
    debounce(search => {
      getPaymentTypesApi(20, 1, search).catch(e =>
        setIsSpinner(false)
      )
      setState(s => ({
        ...s,
        page: 1,
        sizePerPage: 20,
      }))
    }, 1500),
    []
  )

  const handleSearchText = search => {
    setIsSpinner(true)
    setSearchText(search)
    delaySearch(search)
  }

  useEffect(() => {
    if (
      getPaymentTypesRes.data &&
      getPaymentTypesRes.data?.paymentSourceDropdown?.code == 200 &&
      getPaymentTypesRes.data?.paymentSourceDropdown?.data
    ) {
      const data =
        getPaymentTypesRes.data?.paymentSourceDropdown?.data.paymentSources
      setList(data ?? [])
      dispatch(setPaymentType({ list: data }))
      setIsSpinner(false)
      setTotalCount(getPaymentTypesRes.data?.paymentSourceDropdown?.data.totalCount)
    }
  }, [getPaymentTypesRes.data])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setState(s => ({
      ...s,
      page: page,
      sizePerPage: sizePerPage,
    }))
    getPaymentTypesApi(sizePerPage, page, searchText).catch(e =>
      setIsSpinner(false)
    )
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  const openPaymentType = () => {
    history.push(`/create-payment-type`)
}

  return (
    <div className="page-content">
      {hasPermission() ? (
        <div className="container-fluid">
          <div>
            <div className="py-3">
              <h4>Payment Types</h4>
              <h5 className="sub-title-menu"></h5>
            </div>
          </div>

          <Row>
            <Col className="mb-2" xs={8} md={3}>
              <Input
                value={searchText}
                className="form-control default-search-input"
                onChange={e => handleSearchText(e.target.value)}
                placeholder="Search"
              />
            </Col>
            <Col md={9} className="mb-3 d-flex justify-content-end">
              <Button
                onClick={() => openPaymentType()}
                className="btn btn-primary mb-1"
              >
                Create Payment Type
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="light-green-bg-card-table">
                <CardBody>
                  <div className="table-custom-redesign">
                    <PaymentList
                      paymentStore={paymentStore}
                      state={state}
                      isSpinner={isSpinner}
                      editable={editable}
                      setEditable={setEditable}
                      setList={setList}
                      list={list}
                      callApi={callApi}
                      onTableChange={handleTableChange}
                      totalCount={totalCount}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  )
}
export default PaymentTypes
