import React, { useEffect, useState } from "react";
import { Input, FormGroup } from "reactstrap";

function SelectedExportInvoice(props) {

    const { rowValue, row, onSelectRow } = props;
    const [value, setValue] = useState(false);

    const handleRowSelect = (id, isChecked) => {
        setValue(isChecked);
        onSelectRow(id, isChecked);
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-center">
                <FormGroup check>
                    <Input
                        id="exampleCheck"
                        name="check"
                        type="checkbox"
                        checked={value}
                        onChange={(e) => handleRowSelect(row.invoice_number, e.target.checked)}
                    />
                </FormGroup>
            </div>
        </>
    )
}

export default SelectedExportInvoice