import React, { useState, useEffect } from "react"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import useStyles from "../styles"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import Table from "../../../components/Common/Table"
import classNames from "classnames"
import { useLazyQuery } from "@apollo/client"
import { GET_APPOINTMENTS } from "./gql/gql"
import moment from "moment"
import { useHistory, useLocation } from "react-router-dom"
import {
  capitalizeFirstLetter,
  convertUTCToLocal,
  formatPhoneNumber,
  meetingType,
} from "../../../functions/functions"

import style from "./style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"

const UpComingAppointment = ({ setIsLoader }) => {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [totalCount, setTotalCount] = useState(20)
  const [appointmentsData, setAppointmentsData] = useState([])

  //************************** Appointment Data ***************************** */

  const [getAppointmentList, listResponse] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: "no-cache",
  })

  function getAppointmentListFun(page, sizePerPage) {
    getAppointmentList({
      variables: {
        argumentsInput: {
          limit: sizePerPage,
          page: page || 1,
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().add("month", 1).format("YYYY-MM-DD"),
          sort_dir: "ASC",
          device_type:"web",
        },
      },
    })
  }

  const [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().add("month", 1).format("YYYY-MM-DD"),
    sortOrder: "ASC",
  })

  useEffect(() => {
    getAppointmentListFun(
      1,
      state.sizePerPage,
      state.sortOrder,
      state.start_date,
      state.end_date
    )
  }, [])

  const handleTableChange = (
    type,
    { page, sizePerPage}
  ) => {
    setState(s => ({ ...s, page: page, sizePerPage:sizePerPage }))

    getAppointmentListFun(
      page,
      sizePerPage,
    )
    window.scrollTo({ top: 500, behavior: 'smooth' });

  }

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointments &&
      listResponse.data.getAppointments.code === 200 &&
      listResponse.data.getAppointments.status
    ) {
      setTimeout(() => {
        setLoading(false)
        setIsLoader(false)
      }, 500)
      if (listResponse.data.getAppointments.data) {
        const appointments = listResponse.data.getAppointments.data.appointments_arr?.map((elem)=>{
          return elem?.appointment
        })
        setAppointments(appointments)
      }
      setTotalCount(listResponse.data.getAppointments.data.totalCount)

    } else {
      setLoading(true)
      setTotalCount(0)
      setAppointments([])
    }
  }, [listResponse.data])

  React.useEffect(() => {
    setAppointmentsData(
      appointments.filter(elem => {
        return new Date(convertUTCToLocal(elem.utc_datetime)) > new Date()
      })
    )
  }, [appointments])

  const refreshAPI = () => {
    setLoading(true)
    setIsLoader(true)
    getAppointmentListFun()
  }

  //************************** Table ***************************** */

  const handleShowDetailsClick = row => {
    const encodedId = btoa(row.id);
    history.push(`/appointments/${encodedId}`)
  }
  const columns = [
    {
      text: "Name",
      dataField: "first_name",
      formatter: nameFun,
      style: {
        minWidth: 150,
      },
    },
    {
      text: "Meeting Type",
      dataField: "type_of_appointment",
      align: "center",
      headerAlign: "center",
      style: {
        minWidth: 150,
      },
      formatter: meetingTypeFun,
    },
    {
      text: "Time",
      dataField: "time",
      align: "center",
      headerAlign: "center",
      style: {
        minWidth: 150,
      },
      formatter: (cell, row) => {
        const startTime = row?.utc_datetime
          ? convertUTCToLocal(row?.utc_datetime)
          : null
        return moment(startTime).format("hh:mm A")
      },
    },
    {
      text: "Date",
      dataField: "utc_datetime",
      align: "center",
      headerAlign: "center",
      style: {
        minWidth: 150,
      },
      formatter: (cell, row) => {
        const startDate = row?.utc_datetime
          ? convertUTCToLocal(row?.utc_datetime)
          : null
        return moment(startDate).format("DD-MMM-yyyy")
      },
    },
    {
      text: "View",
      dataField: "action",
      headerAlign: "end",
      align: "end",
      formatter: actionEvent,
    },
  ]

  function nameFun(value, row) {
    return (
      <div className="d-flex gap-2 align-items-center">
        <div className="d-flex flex-column">
          <span
            className={classNames(
              "text-capitalize",
              classes.boldFont,
              classes.normalFontsize
            )}
          >
            {row?.first_name} {row?.last_name}
          </span>
          <div>{formatPhoneNumber(row?.phone)}</div>
        </div>
      </div>
    )
  }

  function meetingTypeFun(value, row) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {value && value.call_type ? meetingType(value.call_type) : ""}
      </div>
    )
  }

  function actionEvent(value, row) {
    return (
      <div className="d-flex justify-content-end align-items-center">
        <a
          className="px-2 waves-effect color-dark-green waves-light"
          onClick={() => handleShowDetailsClick(row)}
        >
          <i
            className="mdi mdi-eye font-size-24 color-dark-green"
            id="detailTooltip"
          />
          <UncontrolledTooltip placement="top" target="detailTooltip">
            Appointment Details
          </UncontrolledTooltip>
        </a>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Card body className="light-green-bg-card-table">
        <CardBody className="p-0">
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2 commonTables">
            <h4 className={classNames("card-title", classes.cardTitle)}>
              Upcoming Appointments
              <Tooltip
                className={style.tooltip}
                placement="right"
                title={
                  <div className="invoice-list-fsize-14">
                    {" "}
                    This will display upcoming appointments scheduled .
                  </div>
                }
                arrow
              >
                <i
                  className={`uil ul-bg-dark uil-info-circle ${style.circleinfo}`}
                ></i>
              </Tooltip>
            </h4>
            <img
              src={refresh}
              width="24px"
              height="24px"
              className={classNames(
                classes.refreshIcon,
                loading && classes.refresh
              )}
              onClick={refreshAPI}
            />
          </div>
          <div
            className={classNames(
              " commonTables table-custom-redesign",
              classes.appointmentTable
            )}
          >
            <Table
              data={appointmentsData}
              page={state.page}
              sizePerPage={state.sizePerPage}
              columns={columns}
              noDataIndication={"No Data Found"}
              isBordereded={false}
              isStriped={false}
              isHover={false}
              totalSize={totalCount}
              loading={listResponse.loading}
              isPagination={true}
              onTableChange={handleTableChange}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default UpComingAppointment
