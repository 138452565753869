import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button } from "reactstrap"

const CounsellorsTabs = ({ activeTab, setActiveTab }) => {
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
  const [bottonArr, setBottonArr] = useState([
    {
      name: "Profile",
      type: "profile",
    },
    {
      name: "Edit/Settings",
      type: "settings",
    },
    {
      name: "Availability",
      type: "availability",
    },
    {
      name: "Session & Appointments",
      type: "sessions",
    },
    {
      name: "Pricing",
      type: "pricing",
    },
    {
      name: "Rooms",
      type: "rooms",
    },
    {
      name: "Notes",
      type: "notes",
    },
  ])

  const userData = useSelector(state => state.Login?.user?.user)

  useEffect(() => {
    if (userData?.role?.id !== 1) {
      const allTabs = [
        {
          name: "Profile",
          type: "profile",
        },
        {
          name: "Edit/Settings",
          type: "settings",
        },
        {
          name: "Session & Appointments",
          type: "sessions",
        },
      ]
      if (orgId != 1) {
        allTabs.push({
          name: "Clients",
          type: "clients",
        })
      }
      setBottonArr(allTabs)
    }
  }, [])

  const showDetails = data => {
    setActiveTab(data)
  }

  return (
    <>
      <div className=" btn-group flex-wrap btn-group-counsellors">
        {bottonArr.map(elem => (
          <Button
            key={elem.type}
            className={`border ${
              orgId == 1 ? "border-dark " : "border-primary "
            }  font-weight-bold  btn mx-2 h6 ${
              activeTab.type === elem.type
                ? "btn-primary rounded text-white"
                : "btn-light rounded"
            }`}
            onClick={() => showDetails(elem)}
          >
            {elem.name}
          </Button>
        ))}
      </div>
    </>
  )
}

export default CounsellorsTabs
