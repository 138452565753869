import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Row,
  UncontrolledTooltip,
  Spinner,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import Table from "../../components/Common/Table"
import {
  checkPermissions,
  sortCaret,
  capitalizeFirstLetter,
  formatPhoneNumber,
  convertUTCToLocal,
  meetingType,
  hasPermission,
} from "../../functions/functions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_USERS, DELETE_USER, GET_CLIENT_APPOINTMENT } from "./gql/userGql"
import debounce from "lodash.debounce"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import moment from "moment"

import edit from "../../assets/images/dashboard-icons/edit.svg"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import classNames from "classnames"
import AppointmentInfo from "../Appointments/AppointmentInfo"
import PageNotFound from "../404"

const UserList = ({isClientList}) => {
  let history = useHistory()
  const location = useLocation()
  const selectedBadFitModule = new URLSearchParams(location.search).get(
    "badFit"
  )
  window.addEventListener("beforeunload", function () {
    const keyToRemove = "batFitSelect"
    localStorage.removeItem(keyToRemove)
  })

  const [isSpinner, setIsSpinner] = useState(false)
  const [isAppSpinner, setIsAppSpinner] = useState(false)
  const [totalCount, setTotalCount] = useState(20)
  const [totalAppCount, setTotalAppCount] = useState(20)
  const [viewApp, setViewApp] = useState(false)
  const [clientId, setClientId] = useState()
  const [counsellorId, setCounsellorId] = useState()
  const [appointmentData, setAppointmentData] = useState([])

  const [users, setUsers] = useState([])
  const [state, setState] = useState({
    page: 1,
    data: users,
    sizePerPage: 20,
    sortBy: "created_at",
    sortOrder: "DESC",
  })
  const [appState, setAppState] = useState({
    page: 1,
    sizePerPage: 20,
    sortBy: "utc_datetime",
    sortOrder: "DESC",
  })
  const modalStyle = {
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "hidden",
  }

  const userData = useSelector(state => state.Login?.user?.user)
  const handleEditClick = row => {
    const encodedId = btoa(row.id);
    history.push(`/users/${encodedId}/edit`)
  }

  const [inProgress, setInProgress] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [modal, setModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({
    id: null,
  })
  const modalRef = useRef()
  const [getUserList, listResponse] = useLazyQuery(GET_USERS, {
    fetchPolicy: "no-cache",
  })
  const [getClientAppointment, clientAppointmentRes] = useLazyQuery(
    GET_CLIENT_APPOINTMENT,
    {
      fetchPolicy: "no-cache",
    }
  )

  function getUserListFun(page, sizePerPage, searchText, sortField, sortOrder) {
    const argumentsInput = {
      page: page,
      limit: sizePerPage,
      search: searchText,
      sort: sortField,
      sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
      calendar_id: isClientList ? userData.calendarId : undefined
    }

    let betFitItem = localStorage.getItem("batFitSelect")
    if (betFitItem || selectedBadFitModule) {
      argumentsInput[betFitItem || selectedBadFitModule] = true
    }

    getUserList({
      variables: {
        argumentsInput: argumentsInput,
      },
    })
      .then(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          searchText,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
      .catch(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          searchText,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
  }

  React.useEffect(() => {
    const selectedPage = new URLSearchParams(location.search).get("page")
    let page = state.page
    if (selectedPage) {
      page = Number(selectedPage)
    }

    getUserListFun(page, state.sizePerPage, "", state.sortBy, state.sortOrder)
  }, [])
  React.useEffect(() => {
    const selectedPage = new URLSearchParams(location.search).get("page")
    let page = state.page
    if (selectedPage) {
      page = Number(selectedPage)
    }
    setState(p => ({
      ...p,
      page: selectedPage || 1,
    }))
    getUserListFun(
      selectedPage ? page : 1,
      state.sizePerPage,
      "",
      state.sortBy,
      state.sortOrder
    )
  }, [selectedBadFitModule])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getUsers &&
      listResponse.data.getUsers.status &&
      listResponse.data.getUsers.code === 200
    ) {
      setIsSpinner(false)
      const updatedUsers = listResponse.data.getUsers.data.users.map(user => ({
        ...user,
        role: user.role !== null ? user.role : { id: 3, role_name: "Client" },
      }))
      setUsers(updatedUsers)
      setTotalCount(listResponse.data.getUsers.data.totalCount)
      setState(s => ({ ...s, data: updatedUsers }))
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])
  const roleNameShow = (cell, row, rowIndex, formatExtraData) => {
        return (
        <>
          <p className="m-0">
            {row?.organizations[0]?.role?.role_name}
          </p>
        </>
      )
  }

  const columns = [
    {
      text: "#",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "Name",
      dataField: "first_name",
      formatter: fullNameFun,
      sort: true,
      headerStyle: {
        cursor: "pointer",
      },
      style: {
        maxWidth: 150,
      },
    },
    !isClientList && {
      text: "Role",
      dataField: "role.role_name",
      width: 200,
      formatter: roleNameShow,
    },
    {
      text: "Email Address",
      dataField: "email",
      width: 70,
      style: {
        maxWidth: 150,
      },
    },
    {
      text: "Created On",
      dataField: "created_at",
      sort: false,
      formatter: dateFormatter,
    },
    {
      dataField: "View Appointments",
      text: "Appointments",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: viewAppointment,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
    },
  ]

  function fullNameFun(value, row) {
    const firstName = row.first_name ? row.first_name : " "
    const lastName = row.last_name ? row.last_name : " "
    return (
      capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName)
    )
  }

  function meetingTypeFun(value, row) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {value && value?.call_type ? meetingType(value?.call_type) : ""}
      </div>
    )
  }
  const AppointmentColumn = [
    {
      text: "ID",
      dataField: "id",
      style: {
        minWidth: 50,
      },
    },
    {
      text: "Client Name",
      dataField: "first_name",
      formatter: fullNameFun,
      style: {
        minWidth: 130,
      },
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      text: "Counsellor Name",
      dataField: "therapist_calendar",
      style: {
        minWidth: 130,
      },
      headerStyle: {
        cursor: "pointer",
      },
      formatter: (cell, row) => {
        return (
          <p className="m-0">{row?.therapist_calendar?.name}</p>
        )
      },
      
    },
    {
      text: "Meeting Type",
      dataField: "type_of_appointment",
      style: {
        minWidth: 150,
      },
      formatter: meetingTypeFun,
    },
    {
      text: "Date",
      dataField: "utc_datetime",
      style: {
        minWidth: 190,
      },
      formatter: (cell, row) => {
        const date = convertUTCToLocal(row.utc_datetime)
        return (
          <p className="m-0">{moment(date).format("DD-MMM-yyyy hh:mm A")}</p>
        )
      },
      headerStyle: {
        cursor: "pointer",
      },
      sort: true,
    },
    {
      text: "Duration (in mins)",
      dataField: "duration",
      style: {
        minWidth: 50,
      },
      sortCaret: sortCaret,
    },
    {
      dataField: "canceled",
      text: "Status",
      sort: false,
      headerAlign: "left",
      align: "left",
      style: {
        minWidth: 100,
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
            return (
              row?.canceled ? 
              <span>Cancelled</span> : <span>Active</span>
            )
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "center",
      align: "center",
      style: {
        minWidth: 100,
      },
      formatter: appointmentActionEvent,
    },
  ]

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="m-0">
          {rowIndex + 1 + state.sizePerPage * (state.page - 1)}
        </p>
      </>
    )
  }

  function dateFormatter(value, row, index, field) {
    return value === null ? " " : moment(row?.organizations[0]?.created_at).format("DD-MMM-yyyy")
  }
  const [infoModal, setInfoModal] = useState(false)
  const handleNameClick = row => {
    setInfoModal(row)
  }

  function fullNameFun(value, row, index, field) {
    const firstName = row?.first_name ? row?.first_name : " "
    const lastName = row?.last_name ? row?.last_name : " "

    return (
      <span onClick={() => handleNameClick(row)} className="cursor-pointer">
        {capitalizeFirstLetter(firstName) +
          " " +
          capitalizeFirstLetter(lastName)}
      </span>
    )
  }

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="d-flex align-items-center">
        <Button
              onClick={() => handleEditClick(row)}
              className="edit-btn btn-primary btn text-center cursor-pointer d-flex align-items-center counsellorDetailsBtn"
            >
              Details
              <i className="mdi mdi-chevron-right ms-3 mt-1"></i>
            </Button>
        <a
          onClick={() => openDeleteModel(row.id)}
          className="text-dark delete-btn cursor-pointer counsellorDeleteIcon"
        >
          <span className="mx-1 mdi mdi-trash-can-outline font-size-20"></span>
        </a>
      </div>
    )
  }
  function appointmentActionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="">
        <Button
          className="btn btn-primary cursor-pointer"
          onClick={()=>setModal(row)}
        >
          View
        </Button>
      </div>
    )
  }

  function viewAppointment(cell, row, rowIndex, formatExtraData) {
    return (
      row?.organizations[0]?.role?.id === 3 ? (
      <div className="d-flex align-items-center">
        <Button
          className="btn btn-primary cursor-pointer"
          onClick={() => handleViewAppointment(row)}
        >
          View
        </Button>
      </div> ): <></>
    )
  }

  const changeHandler = ({
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
  }) => {
    const field = sortField === null ? "id" : sortField
    getUserListFun(1, sizePerPage, searchText, field, sortOrder)
  }

  const delaySearch = useCallback(debounce(changeHandler, 1000), [])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setState(p => ({
      ...p,
      page,
      sizePerPage,
    }))
    let currentUrl = new URL(window.location.href)

    currentUrl.searchParams.set("page", page)
    window.history.replaceState({}, document.title, currentUrl.href)
    const field = sortField === null ? "id" : sortField
    setIsSpinner(true)
    if (type === "search") {
      delaySearch({ page, sizePerPage, searchText, sortField, sortOrder })
    } else {
      getUserListFun(page, sizePerPage, searchText, field, sortOrder)
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleAppTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setAppState(p => ({
      ...p,
      page,
      sizePerPage,
      sortOrder
    }))

    getClientAppointment({
      variables: {
        argumentsInput: {
          page: page || 1,
          limit: sizePerPage || 20,
          client_id: clientId ? clientId : undefined ,
          counsellorId: counsellorId ? counsellorId : undefined ,
          search: "",
          sort: "utc_datetime",
          sort_dir: sortOrder?.toUpperCase() || "DESC",
          start_date: "",
          end_date: "",
          device_type:"web",
        },
      },
    })


  }

  //******************************************************** */

  function deleteToggle() {
    setModalDelete(!modalDelete)
  }

  const openDeleteModel = async id => {
    setModalDelete(!modalDelete)
    setDeleteRecord({
      id,
    })
  }

  const getClientAppointmentFun = (uId, role) => {
    getClientAppointment({
      variables: {
        argumentsInput: {
          page: appState.page,
          limit: appState.sizePerPage,
          client_id:role === "client" ? uId : undefined,
          calendar_id:role === "counsellor" ? uId : undefined,
          search: "",
          sort: "utc_datetime",
          sort_dir: "DESC",
          start_date: "",
          end_date: "",
          device_type:"web",
        },
      },
    })
  }
  const handleViewAppointment = user => {
    setViewApp(!viewApp)
    if (user) {
      setIsAppSpinner(true)
      if(user.role.id === 2){
        setCounsellorId(user?.id)
        setClientId(undefined)
        getClientAppointmentFun(user?.id, "counsellor")
      }
      if(user.role.id === 3){
        setClientId(user?.id)
        setCounsellorId(undefined)
        getClientAppointmentFun(user?.id, "client")
      }
    } else {
      setClientId(undefined)
    }
  }

  useEffect(() => {
    if (
      clientAppointmentRes?.data &&
      clientAppointmentRes?.data?.getAppointments &&
      clientAppointmentRes?.data?.getAppointments?.status &&
      clientAppointmentRes?.data?.getAppointments?.code === 200
    ) {
      setIsAppSpinner(false)
      const appointments = clientAppointmentRes?.data?.getAppointments?.data?.appointments_arr?.map((elem)=>{
        return elem?.appointment
      })
      setAppointmentData(
        appointments
      )
      setTotalAppCount(
        clientAppointmentRes?.data?.getAppointments?.data?.totalCount
      )
      modalRef?.current?.scroll({ top: 0, behavior: 'smooth' });

    }
  }, [clientAppointmentRes.data])

  const handleDelete = async () => {
    const storedData = localStorage.getItem("orgId")
    const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
    try {
      setInProgress(true)
      await deleteMutation({
        variables: {
          argumentsInput: {
            id: deleteRecord.id,
            organization_id: orgId ? orgId : undefined
          },
        },
      })
    } catch (e) {
      setInProgress(false)
      console.log(e)
    }
    setDeleteRecord({
      id: null,
    })
    deleteToggle()
  }
  const [deleteMutation, deleteResponse] = useMutation(DELETE_USER)
  React.useEffect(() => {
    if (deleteResponse?.data && deleteResponse?.data?.deleteUser?.status) {
      toast.success(deleteResponse?.data?.deleteUser?.message)
      getUserListFun(
        state.page,
        state.sizePerPage,
        "",
        state.sortBy,
        state.sortOrder
      )
      setInProgress(false)
    } else {
      if (deleteResponse?.data && deleteResponse?.data?.deleteUser) {
        toast.error(deleteResponse?.data?.deleteUser?.message)
        getUserListFun(
          state.page,
          state.sizePerPage,
          "",
          state.sortBy,
          state.sortOrder
        )
      }
      setInProgress(false)
    }
  }, [deleteResponse.data])

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null
  return (
    <React.Fragment>
    {hasPermission() || isClientList ? (
    <>
      <div className={isClientList ? "" : "page-content"}>
        <div className="container-fluid">
          {!isClientList && <div className="d-flex flex-wrap-mobile-only justify-content-between">
            <div className="p-2 w-75">
              <h4 className="card-title-main">Users</h4>
              <h5 className="card-para-main ">
                List of all the admin users, counsellors and clients registered
                with the {orgData?.name ? orgData?.name : "No Fear Counselling"}.
              </h5>
            </div>
          </div>}
          <Row className="mg-top-default-ui">
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody className="table-custom-redesign">
                  <Table
                    data={state.data}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    isSearch={true}
                    isHover={false}
                    isStriped={false}
                    isBordereded={false}
                    isDisplayUserAdd={isClientList ? false : true}
                    location={location.pathname}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setModalDelete(false)
                    setDeleteRecord({ id: null })
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>

                {!inProgress ? (
                  <Button
                    disabled={inProgress}
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => handleDelete()}
                  >
                    Yes, delete it
                  </Button>
                ) : (
                  <Button
                    className="btn btn-primary waves-effect waves-light"
                    disabled
                  >
                    <Spinner
                      type="grow"
                      className="me-1 align-middle spinner-grow-sm "
                      color="light"
                    />
                    Processing...
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        isOpen={viewApp}
        toggle={() => {
          handleViewAppointment()
        }}
        centered={true}
        size="xl"
      >
        <ModalHeader toggle={() => setViewApp(!viewApp)} tag="h4">
          Client Appointments
        </ModalHeader>
        <ModalBody >
        <div style={modalStyle} ref={modalRef}>

          <Row className="">
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody className="table-custom-redesign">
                  <Table
                    data={appointmentData}
                    page={appState.page}
                    sizePerPage={appState.sizePerPage}
                    totalSize={totalAppCount}
                    onTableChange={handleAppTableChange}
                    columns={AppointmentColumn}
                    noDataIndication={"No Data Found"}
                    loading={isAppSpinner}
                    isHover={false}
                    isSearch={false}
                    isStriped={false}
                    isBordereded={false}
                    isViewAppointment={viewApp}
                    location={location.pathname}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        </ModalBody>
      </Modal>
      <Modal isOpen={infoModal} size="lg">
        <ModalHeader toggle={() => setInfoModal(undefined)} tag="h4">
          User Details
        </ModalHeader>
        <ModalBody className={style.modalstyle}>
          <Card className="light-green-bg-card">
            <CardBody>
              <div className={style.headerContainer}>
                <CardTitle className="h4 m-0 ">
                  <h5 className="m-0 card-title-appointments">User Details</h5>
                </CardTitle>
              </div>
              <hr style={!infoModal ? { marginTop: "8px" } : null} />
              <div>
                <div className="card-title-appointments">
                  {infoModal?.first_name
                    ? capitalizeFirstLetter(infoModal.first_name)
                    : " "}{" "}
                  {infoModal?.last_name
                    ? capitalizeFirstLetter(infoModal.last_name)
                    : " "}
                </div>
              </div>
              <div
                className={classNames(
                  `${infoModal ? "flex-column" : null}`,
                  style.patientDetails
                )}
              >
                <div className={style.patientDetailsInfo}>
                  {infoModal?.email && (
                    <div className="d-flex gap-2">
                      <i className="mdi mdi-email font-size-20 m-0 mt-2 color-dark-green"></i>
                      <p className="card-para-main m-0 mt-2">
                        {infoModal.email}
                      </p>
                    </div>
                  )}

                  {infoModal?.mobile_number && (
                    <div className="d-flex gap-2">
                      <i className="mdi mdi-phone font-size-20 color-dark-green  m-0 mt-1"></i>
                      <p className="card-para-main m-0 mt-2">
                        {formatPhoneNumber(infoModal?.mobile_number)}
                      </p>
                    </div>
                  )}
                </div>

                <div className={style.patientDetailsInfo}>
                  {infoModal?.created_at && (
                    <div className="d-flex gap-2 align-items-center">
                      <i className="mdi mdi-calendar font-size-20 color-dark-green  m-0 mt-1"></i>
                      <p className="card-para-main m-0 mt-2">
                        {moment(infoModal?.created_at).format("DD-MMM-yyyy")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} size="lg" >
              <ModalHeader toggle={() => setModal(undefined)} tag="h4">
                Appointment Details
              </ModalHeader>
              <ModalBody className={style.modalstyle}>
                <AppointmentInfo id={parseInt(modal?.id)} isModel={modal} setModal={setModal} />
              </ModalBody>
              
            </Modal>
            </>
            ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  )
}

export default UserList