import React, { useEffect, useState } from "react"
import Table from "../../components/Common/Table"
import Select from "react-select"
import { setPaymentType } from "../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { formatToCommonDate } from "../../functions/functions"
import editIcon from "../../assets/images/dashboard-icons/edit.png"
import edit from "../../assets/images/dashboard-icons/edit.svg"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useLazyQuery, useMutation } from "@apollo/client"
import { SUBMIT_RATES, DELETE_PAYMENT_TYPE } from "./gql/Gql"
import { useHistory } from "react-router-dom"
import DeleteModal from "../../components/Common/DeleteModal"
const PaymentLists = ({
  paymentStore,
  state,
  isSpinner,
  editable,
  setEditable,
  list,
  setList,
  callApi,
  onTableChange,
  totalCount,
}) => {
  const dispatch = useDispatch()
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [IsSubmitted, setIsSubmitted] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [selectedAppointmentRateType, setSelectedAppointmentRateType] =
    useState({})
  const [deleteModalId, setDeleteModalId] = useState(-1)

  let history = useHistory()

  const handleEditPaymentType = row => {
    const encodedId = btoa(row.id)
    history.push(`/create-payment-type/${encodedId}`)
  }

  const [deletePaymentType, deletePaymentTypeRes] =
    useLazyQuery(DELETE_PAYMENT_TYPE)

  const handleDeleteModal = row => {
    setDeleteModalId(Number(row.id))
  }

  const handleDelete = async id => {
    try {
      setInProgress(true)
      await deletePaymentType({
        variables: {
          deletePaymentTypeId: id,
        },
      })
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong !")
    } finally {
      setInProgress(false)
      setDeleteModalId(-1)
      callApi()
    }
  }

  const [initialValues, setInitialValues] = useState({
    appointment_rate_type: "",
    rate: "",
  })
  const columns = [
    {
      text: "#",
      dataField: "id",
      keyField: "id",
      formatter: (cell, row, index) => {
        return index + 1
      },
    },
    {
      text: "Payment Source",
      dataField: "name",
    },
    {
      text: "Type",
      dataField: "is_direct",
      formatter: (cell, row) => {
        return cell ? "Direct" : "Non Direct"
      },
    },
    {
      text: "Appointment Rate",
      dataField: "appointment_rate_type",
      formatter: (cell, row) => {
        let value = "-"
        if (cell && cell == "set_rate") {
          value = "Set Rate"
        } else if (cell && cell == "no_change") {
          value = "No Change"
        } else if (cell && cell.value == "set_rate") {
          value = "Set Rate"
        } else if (cell && cell.value == "no_change") {
          value = "No Change"
        }
        return <div> {value}</div>
      },
      style: {
        textAlign: "center",
      },
    },
    {
      text: "Rate",
      dataField: "rate",
      formatter: (cell, row) => {
        return (
          <>
            <div>
              {row.appointment_rate_type &&
              row.appointment_rate_type == "set_rate"
                ? (row.rate && `$${row.rate}`) || "-"
                : "-"}
            </div>
          </>
        )
      },
    },
    {
      text: "Coup. Rate",
      dataField: "appointment_rate_couple_counselling_type",
      formatter: (cell, row) => {
        return (
          <>
            <div>
              {row.appointment_rate_couple_counselling_type
                ? `$${row.appointment_rate_couple_counselling_type}`
                : "-"}
            </div>
          </>
        )
      },
    },
    {
      text: "Modified",
      dataField: "updated_at",
      formatter: (cell, row) =>
        cell && formatToCommonDate(cell, "DD-MMM-YYYY HH:mm"),
    },
    {
      text: "Rate Type",
      dataField: "rateType",
      style: {
        width: "100px",
      },
      formatter: (cell, row) => {
        return (
          <Button
            onClick={e => handleEditClick(e, cell, row, validation)}
            className="edit-btn btn-primary btn text-center cursor-pointer"
          >
            <img src={editIcon}></img>
            Edit
          </Button>
        )
      },
    },
    {
      text: "Action",
      dataField: "action",
      style: {
        width: "100px",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <Button
              className="btn btn-primary cursor-pointer"
              onClick={() => handleEditPaymentType(row)}
            >
              <img src={edit} />
            </Button>
            <a
              onClick={() => handleDeleteModal(row)}
              className="px-3 text-danger delete-btn cursor-pointer"
            >
              <img src={deleteBin} />
            </a>
          </div>
        )
      },
    },
  ]
  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      appointment_rate_type: Yup.object().test(
        "notEmptyObj",
        "Please select appointment rate type",
        value => {
          return value.value
        }
      ),
      rate: Yup.string().max(6, "Rate should be 6 digits "),
      appointment_rate_couple_counselling_type: Yup.string()
      .nullable()
      .max(
        6,
        "Couple Counselling Rate should be 6 digits "
      ),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      handleSubmit(values)
    },
  })
  const handleSubmit = values => {
    submitRates({
      variables: {
        updateRate: {
          id: values.id,
          rate: values.rate.toString(),
          appointment_rate_type: values.appointment_rate_type.value,
          appointment_rate_couple_counselling_type:
          values?.appointment_rate_couple_counselling_type > 0 ? values?.appointment_rate_couple_counselling_type?.toString() : null,
        },
      },
    }).then(res => {
      setIsSubmitted(false)
      onToggle()
      callApi() // recall api after update Rates
    })
  }
  const handleAppointmentRate = (value, cell, row) => {
    setEditable(prev => ({
      ...prev,
      [row.id]: {
        selected: value,
      },
    }))
    let updatePaymentStore = paymentStore.map(item => {
      if (item.id == row.id) {
        item.isEdit = true
      }
      return item
    })
    setList(updatePaymentStore)
    dispatch(setPaymentType({ list: updatePaymentStore }))
  }

  const dispayRates = (cell, row) => {
    return (
      <>
        <Select
          placeholder={"Select"}
          style={{ width: "135px !important" }}
          onChange={value => {
            handleAppointmentRate(value, cell, row)
          }}
          options={[
            { label: "No Change", value: "no_change" },
            { label: "Set Rate", value: "set_rate" },
          ]}
        />
        {editable[row.id] ? "edit" : "-"}
        {row.isEdit ? "editable" : ""}
      </>
    )
  }
  const handleEditClick = (e, cell, row, validation) => {
    let appointment_rate_type = {}
    if (row && row.appointment_rate_type) {
      if (row.appointment_rate_type == "set_rate") {
        appointment_rate_type = {
          label:
            row.appointment_rate_type == "set_rate" ? "Set Rate" : "No Change",
          value:
            row.appointment_rate_type == "set_rate" ? "set_rate" : "no_change",
        }
      } else {
        appointment_rate_type = {
          label:
            row.appointment_rate_type.value == "set_rate"
              ? "Set Rate"
              : "No Change",
          value:
            row.appointment_rate_type.value == "set_rate"
              ? "set_rate"
              : "no_change",
        }
      }
      setSelectedAppointmentRateType(appointment_rate_type)
    }
    row.appointment_rate_type = appointment_rate_type
    row.rate = row.rate || 0
    row.appointment_rate_couple_counselling_type =
      row.appointment_rate_couple_counselling_type || 0
    validation.setFieldValue("appointment_rate_type", appointment_rate_type)
    setInitialValues(row)
    setModalOpen(!isModalOpen)
  }
  const onToggle = () => {
    setModalOpen(!isModalOpen)
  }
  const handleSelect = (value, validation) => {
    if (value.value) {
      validation.setFieldValue("appointment_rate_type", value)
    } else {
      validation.setFieldValue("appointment_rate_type", {})
    }
    setSelectedAppointmentRateType(value)
  }
  const [submitRates, submitRatesRes] = useMutation(SUBMIT_RATES)
  const handleModelClose = validation => {
    onToggle()
    validation.setFieldValue(
      "appointment_rate_type",
      initialValues.appointment_rate_type
    )
  }
  return (
    <>
      <Table
        data={list}
        page={state?.page}
        sizePerPage={state?.sizePerPage}
        totalSize={totalCount}
        columns={columns}
        noDataIndication={"No Data Found"}
        loading={isSpinner}
        keyField={"id"}
        isHover={false}
        isStriped={false}
        isBordereded={false}
        onTableChange={onTableChange}
      />
      <DeleteModal
        show={deleteModalId !== -1}
        onDeleteClick={() => {
          handleDelete(deleteModalId)
        }}
        onCloseClick={() => {
          setDeleteModalId(-1)
        }}
        inProgress={inProgress}
      />
      <Modal isOpen={isModalOpen} toggle={onToggle}>
        <ModalBody>
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="w-100 text-end">
              <button
                type="button"
                className="btn-close"
                onClick={() => handleModelClose(validation)}
                aria-label="Close"
              ></button>
            </div>

            <Row>
              <Col md={12} className="mt-2 mb-2 relative">
                <Label>Appointment Rate Type</Label>
                <Select
                  placeholder={"Select"}
                  style={{ width: "135px !important" }}
                  name={"appointment_rate_type"}
                  className="default-search-input"
                  value={selectedAppointmentRateType}
                  onChange={value => handleSelect(value, validation)}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.appointment_rate_type &&
                    validation.errors.appointment_rate_type
                      ? true
                      : false
                  }
                  options={[
                    { label: "No Change", value: "no_change" },
                    { label: "Set Rate", value: "set_rate" },
                  ]}
                />
                {validation.touched.appointment_rate_type &&
                validation.errors.appointment_rate_type ? (
                  <FormFeedback type="invalid" className="d-block">
                    {validation.errors.appointment_rate_type}
                  </FormFeedback>
                ) : null}
              </Col>
              {validation.values &&
                validation?.values?.appointment_rate_type?.value ==
                  "set_rate" && (
                  <>
                    <Col md={12} className="mt-2 mb-2 relative">
                      <Label>Rate (Excluding GST 5%)</Label>
                      <Input
                        name="rate"
                        value={validation.values.rate}
                        className="form-control default-redesign-input"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.rate && validation.errors.rate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.rate && validation.errors.rate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.rate}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    {validation.values && (
                      <Col md={12} className="mt-2 mb-2 relative">
                        <Label>Couple Counselling Rate</Label>
                        <Input
                          name="appointment_rate_couple_counselling_type"
                          value={
                            validation.values
                              .appointment_rate_couple_counselling_type
                          }
                          className="form-control default-redesign-input"
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched
                              .appointment_rate_couple_counselling_type &&
                            validation.errors
                              .appointment_rate_couple_counselling_type
                              ? true
                              : false
                          }
                        />
                        {validation.touched
                          .appointment_rate_couple_counselling_type &&
                        validation.errors
                          .appointment_rate_couple_counselling_type ? (
                          <FormFeedback type="invalid">
                            {
                              validation.errors
                                .appointment_rate_couple_counselling_type
                            }
                          </FormFeedback>
                        ) : null}
                      </Col>
                    )}
                  </>
                )}
              <Col md={12} className="mt-2">
                <button type="submit" className="btn w-100 btn-primary">
                  Save
                </button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
export default PaymentLists
