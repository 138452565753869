import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_CONCERN, SUBMIT_CONCERN } from "./gql/Gql"
import * as Yup from "yup"
import { useFormik } from "formik"
import { validate } from "graphql"
import Loader from "../../components/Common/Loader"
import RichTextBox from "../../common/RichTextBox"
import Select from "react-select"
import Dropzone from "react-dropzone"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import Spinner from "../../assets/images/Loader.svg"

const AddConcern = () => {
  let history = useHistory()
  const [toggleSwitch, settoggleSwitch] = useState(true)
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = decodedId
  const [getConcern, getConcernList] = useLazyQuery(GET_CONCERN, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(SUBMIT_CONCERN)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoader, setIsLoader] = useState(true)
  const concernTypeOptions = [
    { name: "General", value: "General" },
    { name: "Couples and Family", value: "CouplesandFamily" },
    { name: "Children", value: "Children" },
  ]
  const [initialValues, setInitialValues] = useState({
    name: "",
    article: "",
    type_of_concern: "",
    status: false,
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Please enter concern name"),
      type_of_concern: Yup.mixed().required("Please enter type of concern"),
    }),
    onSubmit: values => {
      setIsSubmit(true)
      let obj = {
        name: values.name,
        type_of_concern: values.type_of_concern?.value ?? "",
        article: values.article,
        status: values.status ? "active" : "inactive",
        url: values.img?.url ?? "",
      }
      if (id) {
        obj.id = parseInt(id)
      }
      submitMutation({
        variables: {
          createConcernInput: obj,
        },
      })
        .then(res => {
          toast.success(`Concern successfully ${id ? "updated" : "created"}`)
          setTimeout(() => {
            if (id) {
              setIsSubmit(false)
            }else{
              history.push("/concern")
            }
          }, 1500)
        })
        .catch(err => {
          toast.error("something went wrong !")
          setIsSubmit(false)
        })
    },
  })

  useEffect(() => {
    if (id) {
      setIsLoader(true)
      getConcern({
        variables: {
          createConcernInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [id])

  useEffect(() => {
    if (getConcernList.data && getConcernList.data?.getConcern?.name) {
      setInitialValues({
        name: getConcernList.data.getConcern.name,
        type_of_concern: {
          name: getConcernList.data.getConcern.type_of_concern,
          value: getConcernList.data.getConcern.type_of_concern,
        },
        article: getConcernList.data.getConcern.article,
        img: getConcernList.data.getConcern.url
          ? {
              state: "uploaded",
              url: getConcernList.data.getConcern.url,
            }
          : undefined,
        status:
          getConcernList.data.getConcern.status == "active" ? true : false,
      })
      settoggleSwitch(
        getConcernList.data.getConcern.status == "active" ? true : false
      )
      setIsLoader(false)
    } else {
      setIsLoader(false)
    }
  }, [getConcernList.data])

  const handleToggle = e => {
    setInitialValues(prev => {
      return { ...validation.values, status: !validation.values.status }
    })
  }

  const handleImg = async acceptedFiles => {
    if (acceptedFiles[0].size > 5 * 1024 * 1000) {
      toast.error("Max File Upload Size is Upto 5 MB, try with smaller size")
      return
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }
    validation.setFieldValue("img", preview)

    let payload = new FormData()
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined
    payload.set("file", acceptedFiles[0])
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        { method: "POST", body: payload,
      headers:{
        organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

      }
      }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        validation.setFieldValue("img", preview)
      } else {
        validation.setFieldValue("img", undefined)
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      console.log(e)
      validation.setFieldValue("img", undefined)
      toast.error("Failed to upload cover image")
    }
  }

  return (
    <div className="page-content">
      {isLoader ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div>
              <div className="py-3">
                <div className="flex align-item-center">
                  <i
                    className="waves-effect color-dark-green mdi mdi-arrow-left font-size-22 mr-2"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className="mt-1 mx-2 card-title-main">
                    {" "}
                    {id ? "" : "Create New "} Concern
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={8}>
              <Card className="transparent-bg-card">
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="form-custom-white-inputs">
                      <Col xl={12} md={12}>
                        <Label className="form-label">Concern</Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Concern Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && (
                          <div className={style.warnText}>
                            {validation.errors.name}
                          </div>
                        )}
                      </Col>
                      <Col xl={6} md={6} className="mt-3">
                        <label className="form-label" htmlFor="custom-select">
                          Type of concern
                        </label>
                        <Select
                          name="type_of_concern"
                          placeholder={"Select type of concern"}
                          className={`
                          default-search-input
                          ${
                            validation.touched.type_of_concern &&
                            validation.errors.type_of_concern
                              ? "invalidSelect"
                              : ""
                          }`}
                          
                          onChange={value => {
                            validation.setFieldValue("type_of_concern", value)
                          }}
                          value={validation.values.type_of_concern || ""}
                          options={concernTypeOptions}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.value}
                          isMulti={false}
                          onBlur={validation.handleBlur}
                        />
                        {validation.errors.type_of_concern && (
                          <div className={style.warnText}>
                            {validation.errors.type_of_concern}
                          </div>
                        )}
                      </Col>
                      <Col xl={1} md={1} />
                      <Col xl={5} md={5} className="my-5">
                        <div className="form-check form-switch">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch2"
                            checked={validation.values.status}
                            onChange={e => handleToggle(e)}
                            onClick={e => handleToggle(e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitch2"
                          >
                            Publish
                          </label>
                        </div>
                      </Col>

                      <Col xl={12} md={12}>
                        <Label className="form-label">Cover Image</Label>
                        <Dropzone multiple={false} onDrop={handleImg}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <input {...getInputProps()} />

                              {!validation?.values?.img ? (
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload" />
                                  </div>
                                  <h4>Drop JPEG, JPG or PNG.</h4>
                                  <span className="defaultText">
                                    It can be square, landscape or portrait.
                                    Upto 5MB file size.
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {validation?.values?.img?.state ===
                                    "uploading" && (
                                    <img
                                      className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                      alt="primary"
                                      src={Spinner}
                                    />
                                  )}
                                  {validation?.values?.img?.state ===
                                    "uploaded" && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          validation.setFieldValue(
                                            "img",
                                            undefined
                                          )
                                        }}
                                        className={`${style.removeButton} mdi mdi-close`}
                                        type="button"
                                        aria-label="Close"
                                      ></button>

                                      <img
                                        {...getRootProps()}
                                        className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                        alt="primary"
                                        src={validation?.values?.img?.url}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </Col>

                      <Col xl={12} md={12}>
                        <div className="my-3">
                          <Label className="form-label">Article</Label>
                          <RichTextBox
                            name="article"
                            value={initialValues.article || ""}
                            onChange={value =>
                              validation.setFieldValue("article", value)
                            }
                            validation={validation}
                            onBlur={() => {
                              validation.setFieldTouched("article", true)
                            }}
                            error={validation.errors?.article}
                            touched={validation.touched?.article}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col xs={12} className="text-end">
                        <button
                          className="btn btn-inactive mx-2"
                          type="button"
                          onClick={() => {
                            history.push("/concern")
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary "
                          type="submit"
                          disabled={isSubmit}
                        >
                          {id ? "Update" : "Add"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
export default AddConcern
