import React from "react"
import { Col, Row } from "reactstrap"

const WarningMessage = ({ isAppointmentCancelled, setIsAppointmentCancelled }) => {

  return (
    <div className={`popup-container ${isAppointmentCancelled ? "open" : ""}`}>
      <div className="alert alert-warning p-2">
        <Row className="d-flex flex-row justify-content-center align-items-center">
          <Col xs={12} md={10}>
          <p className="h5 p-0 m-0">
          This Appointment has been canceled
          </p>
          </Col>
          <Col
            xs={12}
            md={1}
            className="close-button d-flex justify-content-end align-items-center"
          >
            <i
              className="waves-effect mdi mdi-close font-size-20 text-dark"
              onClick={() => setIsAppointmentCancelled(false)}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default WarningMessage
