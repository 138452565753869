import { gql } from "@apollo/client"
export const SUBMIT_THERAPY = gql`
  mutation CreateTherapy($createTherapyInput: CreateTherapyInput!) {
    createTherapy(createTherapyInput: $createTherapyInput) {
      id
      name
      slug
      status
      created_at
      deleted_at
    }
  }
`
export const GET_THERAPY = gql`
  query GetTherapyDetails($getThearpyInput: CreateTherapyInput!) {
    getTherapyDetails(getThearpyInput: $getThearpyInput) {
      article
      counselling {
        name
        id
      }
      created_at
      deleted_at
      id
      image
      item_id
      main_image
      name
      slug
      status
      updated_at
    }
  }
`
export const DEL_THERAPY = gql`
  mutation RemoveTherapy($updateTherapyInput: UpdateTherapyInput!) {
    removeTherapy(updateTherapyInput: $updateTherapyInput) {
      name
    }
  }
`
