import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

function RichTextBox({ name, value, onChange }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    const blocksFromHTML = convertFromHTML(value)
    const contentState = blocksFromHTML
      ? ContentState.createFromBlockArray(blocksFromHTML.contentBlocks)
      : null
    const convertedValue = contentState
      ? EditorState.createWithContent(contentState)
      : EditorState.createEmpty()
    setEditorState(convertedValue)
  }, [value])

  const handleEditorChange = value => {
    setEditorState(value)
    let draftedHTML = draftToHtml(convertToRaw(value.getCurrentContent())) ?? ""
    draftedHTML = draftedHTML.replaceAll("<p></p>", "<div>&shy;</div>")
    onChange(draftedHTML)
  }

  return (
    <>
      <Editor
        height={100}
        name={name}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        editorStyle={{ height: "200px" }}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "link",
          ],
        }}
      >
        {" "}
      </Editor>
    </>
  )
}

RichTextBox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default RichTextBox
