import moment from "moment";

export const formatMonthSeriesData = (data) => {
    let obj = {}
    if (data.length == 1) {
        // sort not working on 1 data length so we here manage manual clientObj
        obj[data[0].day.split('/')[1]] = data[0].count;
    }
    else {
        data.sort((a, b) => {
            obj[b.day.split('/')[1]] = b.count
            obj[a.day.split('/')[1]] = a.count
            var aa = a.day.split('/').reverse().join();
            var bb = b.day.split('/').reverse().join();
            return aa < bb ? -1 : (aa > bb ? 1 : 0);
        });
    }
    const updatedObj = Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
            const updatedKey = key.replace(/^0+/, ''); // remove leading zeros
            return [updatedKey, value];
        })
    );
    return updatedObj;
}

export const formatYearSeriesData = (data) => {
    let obj = {}
    if (data.length == 1) {
        // sort not working on 1 data length so we here manage manual clientObj
        obj[data[0].month.split('/')[0]] = data[0].count;
    }
    else {
        data.sort((a, b) => {
            obj[b.month.split('/')[0]] = b.count
            obj[a.month.split('/')[0]] = a.count
            var aa = a.month.split('/').reverse().join();
            var bb = b.month.split('/').reverse().join();
            return aa < bb ? -1 : (aa > bb ? 1 : 0);
        })
    }
    const updatedObj = Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
            const updatedKey = key.replace(/^0+/, ''); // remove leading zeros
            return [updatedKey, value];
        })
    );
    return updatedObj;
}


export const generateTimeSlots = () => {
    const startTime = moment().startOf('day').hour(9); // Set your desired start time here

    const endTime = moment().startOf('day').hour(17); // Set your desired end time here

    const timeSlots = [];

    let currentTime = moment(startTime);

    while (currentTime.isBefore(endTime)) {
      const timeSlot = currentTime.format('h:mm A'); // Format the time slot as desired
      timeSlots.push(timeSlot);

      currentTime.add(30, 'minutes'); // Add 30 minutes to the current time
    }

    return timeSlots;
    }