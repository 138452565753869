import React from "react"
import logo from "../../assets/images/logo.png"
import "./counsellorInvoice.scss"
const CounsellorInvoice = () => {
  return (
    <>
      <div className="page-content ">
        <div className="brand-section">
          <div>
            <img src="nfcld.png" height="60" />
          </div>
          <div className="brand">Counsellor Invoice</div>
        </div>

        <div className="top-section flex counsellor-invoice">
          <div className="b-box">
            <div className="bill-to-section">
              <div className="left ">
                <div>Bill To :</div>
                <div>Therapist Name :</div>
                <div>Bill Date :</div>
                <div>Invoice Date Range :</div>
              </div>

              <div className="right">
                <div>ariel</div>
                <div>test</div>
                <div>11/09/1994</div>
                <div>11/09/1994 to 11/09/1994</div>
              </div>
            </div>
          </div>

          <div className="totalsection">
            <div className="summary-lable">Summary</div>
            <div className="summary">
              <div className="summary-wrap flex">
                <div className="left">
                  <div>Sessions :</div>
                  <div>Sub Total :</div>
                  <div>Service Fee :</div>
                  <div>GST :</div>
                  <div className="total">Total :</div>
                </div>

                <div className="right">
                  <div>1</div>
                  <div>0</div>
                  <div>0</div>
                  <div>0</div>
                  <div className="total">0</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="nfc-location template">
          <div className="lable">Contractor Location</div>
          <table>
            <thead>
              <th>Client</th>
              <th>Sessions</th>
              <th>Rate</th>
              <th>Counsellor Rate</th>
              <th>GST</th>
              <th>Total</th>
              <th>Description</th>
              <th>Category</th>
              <th>Date</th>
            </thead>
            <tbody>
              <tr className="item index%2 === 0 ? '' : ' odd'">
                <td>item?.client?.first_name item?.client?.last_name</td>
                <td>item?.session</td>
                <td>$Number(item?.price).toFixed(2)</td>
                <td>$Number(item?.counsellor_rate).toFixed(2)</td>
                <td>Number(item?.gst).toFixed(2)</td>
                <td>$Number(item?.total).toFixed(2)</td>
                <td>item?.type</td>
                <td>item?.plan?.payment_source.name</td>
                <td>item.utc_datetime &&</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="nfc-location template">
          <div className="lable Misc">Misc / Resolve</div>
          <div className="line"></div>
          <table className="w-100">
            <thead>
              <th>Client</th>
              <th>Rate</th>
            </thead>
            <tbody>
              <tr className="item index%2 === 0 ? '' : ' odd'">
                <td>item?.client?.first_name item?.client?.last_name</td>
                <td>$Number(item?.price).toFixed(2)</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="nfc-location template">
          <div className="lable">Outstanding items from current invoice</div>
          <table>
            <thead>
              <th>Client</th>
              <th>Rate</th>
              <th>Counsellor Rate</th>
              <th>Description</th>
              <th>Category</th>
              <th>Comment</th>
            </thead>
            <tbody>
              <tr className="item index%2 === 0 ? '' : ' odd'">
                <td>item?.client?.first_name item?.client?.last_name</td>
                <td>$Number(item?.price).toFixed(2)</td>
                <td>$Number(item?.counsellor_rate).toFixed(2)</td>
                <td>item?.type</td>
                <td>item?.plan?.payment_source.name</td>
                <td>
                  These sessions will show up on your future invoice once
                  sessions have been charged.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="nfc-location template">
          <div className="lable">Future paid list</div>
          <table>
            <thead>
              <th>Client</th>
              <th>Rate</th>
              <th>Counsellor Rate</th>
              <th>Appointment type</th>
              <th>Comment</th>
            </thead>
            <tbody>
              <tr className="item ">
                <td>item?.client?.first_name item?.client?.last_name</td>
                <td>$Number(item?.price).toFixed(2)</td>
                <td>$Number(item?.counsellor_rate).toFixed(2)</td>
                <td>item.plan && item.plan.payment_source </td>
                <td>
                  item.plan && item.plan.payment_source &&
                  item.plan.payment_source.is_direct{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default CounsellorInvoice
