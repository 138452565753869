import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2'

const InputPhone = (props) => {
    const [phoneValue, setPhoneValue] = useState()

    return (
        <>
            <PhoneInput
                specialLabel={''}
                country={'ca'}
                value={props.value}
                onChange={value => {
                    props.onChange(value)
                }}
                inputStyle={{
                    borderColor: (props.error) && "red"
                }}
                {...props}
            />

        </>
    );
};

const index = (props) => {
    return (
        <InputPhone
            label={"Mobile Phone"}
            req={true}
            helperText={""}
            error={true}
            isSelect={false}
            {...props.input}
            {...props.custom}
        />
    )
}

export default InputPhone