import { gql } from "@apollo/client"

export const GET_ORGANIZATION = gql`
  query GetOrganizations($argumentsInput: ArgumentsInput) {
    getOrganizations(argumentsInput: $argumentsInput) {
      data {
        organizations {
          name
          id
          description
          organization_identifier
          logo
          created_at
          domain
          sesDomains {
            id
            domain
          }
          is_active
          primary_color
          secondary_color
          font_color
          favicon
        }
      }
    }
  }
`
export const CREATE_ORGANIZATION = gql`
  mutation CreateOrUpdateOrganization(
    $organizationInput: AddOrUpdateOrganizationInput!
  ) {
    createOrUpdateOrganization(organizationInput: $organizationInput) {
      data {
        organization {
          id
        }
      }
      message
      status
      code
    }
  }
`

export const DELETE_ORGANIZATION = gql`
  mutation RemoveOrganization(
    $organizationInput: AddOrUpdateOrganizationInput!
  ) {
    removeOrganization(organizationInput: $organizationInput) {
      message
      status
      code
    }
  }
`

export const CREATE_ORG_INPUT = gql`
  mutation CreateOrUpdateOrganization(
    $organizationInput: AddOrUpdateOrganizationInput!
  ) {
    createOrUpdateOrganization(organizationInput: $organizationInput) {
      data {
        organization {
          id
        }
      }
      message
      status
      code
    }
  }
`

export const GET_ORG = gql`
  query GetOrganization($organizationInput: AddOrUpdateOrganizationInput!) {
    getOrganization(organizationInput: $organizationInput) {
      data {
        organization {
          name
          logo
          id
          is_active
          primary_color
          secondary_color
          font_color
          slug
          helpline_number
          organization_identifier
          favicon
          sub_domain
          meta_data {
            id
            key
            value
            is_required
          }
          domain
          description
        }
      }
    }
  }
`

export const GET_PERMISSIONS = gql`
  query GetAllExistingPermission($roleId: Int!) {
    getAllExistingPermission(role_id: $roleId) {
      data {
        allAvailablePermission
      }
    }
  }
`

export const GET_PERMISSIONS_LIST = gql`
  query GetRolePermissionList($argumentsInput: ArgumentsInput!) {
    getRolePermissionList(argumentsInput: $argumentsInput) {
      message
      status
      data {
        role {
          id
          rolePermissions {
            method {
              slug
            }
          }
        }
      }
    }
  }
`
export const SAVE_PERMISSION = gql`
  mutation AddPermission($permissionInput: PermissionInput!) {
    addPermission(permissionInput: $permissionInput) {
      code
      status
      message
      __typename
    }
  }
`

export const GET_ORGANIZATION_BY_SLUG = gql`
  query GetOrganizationBySlug(
    $organizationInput: AddOrUpdateOrganizationInput!
  ) {
    getOrganizationBySlug(organizationInput: $organizationInput) {
      data {
        organization {
          name
          account {
            id
            external_platform
            external_account_id
          }
          logo
          id
          is_acuity_integration
          is_active
          primary_color
          secondary_color
          font_color
          slug
          organization_identifier
          favicon
          meta_data {
            id
            key
            value
          }
          domain
          description
        }
      }
    }
  }
`
export const CHECK_DOMAIN_STATUS = gql`
  query CheckDkimVerificationStatus(
    $organizationInput: AddOrUpdateOrganizationInput!
  ) {
    checkDkimVerificationStatus(organizationInput: $organizationInput) {
      message
      status
      code
      data {
        ses_domain {
          id
          domain
          dkim_keys
          status
          created_at
          updated_at
        }
      }
    }
  }
`

export const ADD_DOMAIN = gql`
  mutation AddDomainDkim($organizationInput: AddOrUpdateOrganizationInput!) {
    addDomainDkim(organizationInput: $organizationInput) {
      message
      status
      code
    }
  }
`

export const VERIFY_DOMAIN = gql`
  mutation VerifyDomain($organizationInput: AddOrUpdateOrganizationInput!) {
    verifyDomain(organizationInput: $organizationInput) {
      data {
        ses_domain {
          created_at
          dkim_keys
          domain
          id
          status
          updated_at
        }
      }
    }
  }
`

export const REMOVE_DOMAIN = gql`
  mutation RemoveDomain($organizationInput: AddOrUpdateOrganizationInput!) {
    removeDomain(organizationInput: $organizationInput) {
      code
      message
      status
    }
  }
`

export const GET_KEYS = gql`
query GetKeys {
  getKeys {
    code
    message
    status
    data {
      keys {
        id
        key
        created_at
        updated_at
        is_required
      }
    }
  }
}
`

export const GET_TRANSACTION = gql`
query GetTransactions($argumentsInput: ArgumentsInput) {
  getTransactions(argumentsInput: $argumentsInput) {
    data {
      transactions {
        id
        session_id
        mode
        confirmation_method
        payment_intent_id
        client_rate
        extra_data
        status
        stripe_customer_id
        event
        amount_subtotal
        amount_total
        total_tax_on_appointment
        seller_amount
        stripe_fee_percentage
        stripe_fee_fixed
        appointment_id
        fixed_tax_on_appointment
        tax_on_payment_type
        organization_id
        organization_account_id
        platform_fee
        platform_fee_percentage
        platform_fee_tax
        created_at
        updated_at
        deleted_at
        appointment {
          id
          price
          paid
          type
          client{
            id
            first_name
            last_name
          }
          organization {
            id
            name
          }
        }
      }
      totalCount
    }
  }
}
`