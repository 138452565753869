import { gql } from "@apollo/client"

export const GET_CLIENTS = gql`
  query GetUsers($argumentsInput: ArgumentsInput) {
    getUsers(argumentsInput:$argumentsInput) {
      code 
      status
      message
      data {
        users {
          first_name
          last_name
          name
          id
        }
      }
    }
  }
`

export const GET_INVOICE_DATA = gql`
query GetInvoicedata($argumentsInput: ArgumentsInput) {
  getInvoicedata(argumentsInput: $argumentsInput) {
    code
    data {
      paymentTypeData {
        name
        appointmentcount
        id
      }
    }
    message
    status
  }
}
`

export const GET_EXPANDED_DATA = gql`
query GetAppointmentTypeWithClient($argumentsInput: ArgumentsInput) {
  getAppointmentTypeWithClient(argumentsInput: $argumentsInput) {
    code
    data {
      paymentTypeData {
        id
        name
        client_id
        appointmentcount
        first_name
        last_name
        calendar_id
        payment_name
        payment_id
      }
    }
    message
    status
  }
}`

export const GENERATE_RECEIPT = gql`
query GetReceiptView($getReceiptView: ReceiptManagementInput!) {
  getReceiptView(getReceiptView: $getReceiptView) {
    data {
      calendar {
        name
        id
        email
        total_sessions
        total_Amount
        total_gst_Amount
        total_rate
        appointments {
          date
          id
          type
          price
          counsellor_rate
          gst
          total
          appointment_type
          appointment_id
          name
          client {
            first_name
            last_name
            name
            email
          }
          id
          total
          calendar_id
          date
          amount_paid
        }
      }
    }
    code
    message
    status
  }
}`

export const UPDATE_RATE = gql`
mutation UpdateAppointment($updateAppointmentInput: UpdateAppointmentInput!) {
  updateAppointment(updateAppointmentInput: $updateAppointmentInput) {
    code
    message
    status
  }
}`

export const GET_PREVIEW_DATA = gql`
query getInvoicePreview($argumentsInput: ArgumentsInput) {
  getInvoicePreview(argumentsInput: $argumentsInput) {
    data {
      paymentTypeData {
        id
        name
        first_name
        last_name
        date
        price
        payment_name
        calendar
        client_id
        payment_type {
          name
          id
        }
        gst
        total
        client {
          id
          name
          first_name
          last_name
          email
        }
        calendar_id
        appointmentcount
        email
      }
      total_billed
      invoice_Number
      claim_Number
    }
    code
    status
  }
}
`

export const GET_PREVIEW_INVOICE_NUMBER = gql`
query GetInvoiceNumber {
  getInvoiceNumber {
  data {
    invoice_Number
    id
  }
    message
    status
    code
  }
}
`

export const GET_INVOICE_NUMBER = gql`
query ExportInvoice($argumentsInput: ArgumentsInput) {
  exportInvoice(argumentsInput: $argumentsInput) {
    code
    message
    status
  }
}`

export const GET_UPDATE_BILLED = gql`query Updatetotalbilled($argumentsInput: ArgumentsInput) {
  updatetotalbilled(argumentsInput: $argumentsInput) {
    data {
      total_billed
    }
    code
    status
  }
}`

export const UPDATE_INVOICE_NUMBER = gql`
mutation UpdateInvoiceNumber($argumentsInput: ArgumentsInput) {
  updateInvoiceNumber(argumentsInput: $argumentsInput) {
    message
  }
}`


