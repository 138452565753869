import { useLazyQuery } from '@apollo/client';

export const useCommonLazyQuery = (query, options = { fetchPolicy: "no-cache" }) => {
  const [lazyQuery, { loading, error, data }] = useLazyQuery(query, options);

  const executeQuery = (variables) => {
    lazyQuery({ variables:variables });
  };

  return {
    executeQuery,
    loading,
    error,
    data
  };
};

export const useCommonMutation = (query, options = {}) => {
    const [lazyQuery, { loading, error, data }] = useLazyQuery(query, options);
    const executeQuery = (variables) => {
      lazyQuery({ variables });
    };
    return {
      executeQuery,
      loading,
      error,
      data
    };
  };
  export const fetchApi=async (url,method='POST',body='',header)=>{
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined



    if(!header){
      header= {
        "Content-Type": "application/json",
        organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

      }
    }
    const requestOptions = {
      method,
       headers:header,
       body: body ? JSON.stringify(body) : null
    }
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, requestOptions)
     return await response.json()
  }