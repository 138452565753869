import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import { OTP_VALIDATE } from "./gql/loginGql"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// import images

import logoNew from "../../assets/images/nfcld.png"
import loginAccent from "../../assets/images/accent-login.svg"

const ValidateOtpPage = props => {
  const [inProgress, setInProgress] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [initValidateOtpInput, setInitValidateOtpInput] = useState({
    otp: "",
    new_password: "",
    confirm_password: "",
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: initValidateOtpInput,
    validationSchema: Yup.object({
      otp: Yup.string().required("Please Enter OTP."),
      new_password: Yup.string()
        .required("Please Enter New Password")
        .min(6, "Must be 6 characters or more"),
      // .matches(/[a-z]+/, "One lowercase character")
      // .matches(/[A-Z]+/, "One uppercase character")
      // .matches(/[@$!%*#?&]+/, "One special character")
      // .matches(/\d+/, "One number"),
      confirm_password: Yup.string()
        .required("Please Enter Confirm Password")
        .min(6, "Must be 6 characters or more")
        // .matches(/[a-z]+/, "One lowercase character")
        // .matches(/[A-Z]+/, "One uppercase character")
        // .matches(/[@$!%*#?&]+/, "One special character")
        // .matches(/\d+/, "One number")
        .oneOf([Yup.ref("new_password"), null], "New passwords must match"),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      handleSubmit(values)
    },
  })

  const handleSubmit = async values => {
    let confirmEmailInput = {
      email: localStorage.getItem("reset_password_email"),
      reset_password_otp: values.otp.toString(),
      password: values.new_password,
    }
    try {
      console.log(confirmEmailInput)
      setInProgress(true)
      await submitMutation({
        variables: {
          confirmEmailInput: confirmEmailInput,
        },
      })
      validation.resetForm()
    } catch (e) {
      setInProgress(false)
      setIsSubmitted(false)
      console.log(e)
    }
  }

  const [submitMutation, submitResponse] = useMutation(OTP_VALIDATE)
  React.useEffect(() => {
    setInProgress(false)
    if (
      submitResponse.data &&
      submitResponse.data.resetPassword.status &&
      submitResponse.data &&
      submitResponse.data.resetPassword.status == 401
    ) {
      toast.error(submitResponse.data.resetPassword.message)
      setIsSubmitted(false)
    } else if (
      submitResponse.data &&
      submitResponse.data.resetPassword.code == 200
    ) {
      toast.success(submitResponse.data.resetPassword.message)
      setTimeout(() => {
        props.history.push(`/login`)
      }, 5000)
    } else {
      if (submitResponse.data && submitResponse.data.resetPassword) {
        toast.error(submitResponse.data.resetPassword.message)
        setIsSubmitted(false)
      }
    }
  }, [submitResponse.data])

  useEffect(() => {
    if (!localStorage.getItem("reset_password_email")) {
      props.history.push(`/forgot-password`)
    }
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="account-pages  login-account-pages pt-sm-5">
        <Container>
          <Row className="align-items-center">
            <Col
              lg={6}
              xl={5}
              className="align-items-center justify-content-space-between login-left-section"
            >
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logoNew}
                    alt=""
                    height="80"
                    className="logo logo-dark"
                  />
                </Link>
              </div>
              <img
                src={loginAccent}
                height="120"
                className="accent-login-image"
              />
            </Col>
            <Col
              xl={7}
              lg={6}
              className="align-items-center justify-content-space-between"
            >
              <Row className="align-items-center justify-content-center">
                <Col md={8} lg={9} xl={9}>
                  <Card className="custom-card">
                    <CardBody className="p-2">
                      <div className=" mt-2">
                        <h1 className="mb-4 h1-login-details">
                          Set New Password
                        </h1>
                        <p className="text-muted">
                          Validate OTP and update password.
                        </p>
                      </div>

                      <div className="mt-4 margin-top-login-page">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">
                              OTP (From the Email)
                            </Label>
                            <Input
                              name="otp"
                              className="form-control"
                              placeholder="Enter OTP"
                              type="number"
                              minLength={6}
                              maxLength={6}
                              min={0}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.otp || ""}
                              invalid={
                                validation.touched.otp && validation.errors.otp
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.otp && validation.errors.otp ? (
                              <FormFeedback type="invalid">
                                {validation.errors.otp}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">New password</Label>
                            <Input
                              name="new_password"
                              className="form-control"
                              placeholder="Enter new password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.new_password || ""}
                              invalid={
                                validation.touched.new_password &&
                                validation.errors.new_password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.new_password &&
                            validation.errors.new_password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.new_password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Confirm password
                            </Label>
                            <Input
                              name="confirm_password"
                              className="form-control"
                              placeholder="Enter confirm password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirm_password || ""}
                              invalid={
                                validation.touched.confirm_password &&
                                validation.errors.confirm_password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.confirm_password &&
                            validation.errors.confirm_password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirm_password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row className="mb-0">
                            <Col xs={12} className="text-end">
                              {!inProgress ? (
                                <button
                                  className="button-default-login-page btn btn-primary w-100 waves-effect waves-light"
                                  type="submit"
                                  disabled={isSubmitted}
                                >
                                  Update
                                </button>
                              ) : (
                                <button
                                  className="button-default-login-page btn btn-primary w-100 waves-effect waves-light"
                                  disabled
                                >
                                  <Spinner
                                    type="grow"
                                    className="me-1 align-middle spinner-grow-sm "
                                  />
                                  Processing...
                                </button>
                              )}
                            </Col>
                          </Row>
                          <div className="mt-3 form-div-login-page">
                            <p className="bottom-cta-register-account">
                              Remember It ? <Link to="/login"> Log In </Link>
                            </p>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ValidateOtpPage)
