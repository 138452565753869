import {
  GET_COUNSELLOR_PAY, STORE_COUNSELLOR_PAY
} from "./actionTypes"

const INIT_STATE = {
  counsellor_pay: {},
}

const CounsellorPay = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNSELLOR_PAY:
      console.log(action.payload)
      return {
        ...state,
        counsellor_pay: action.payload,
      }
      break;
      case STORE_COUNSELLOR_PAY:
      console.log(action.payload)
      return {
        ...state,
        counsellor_pay: action.payload.data,
      }
      break;
    default:
      return state
  }
}

export default CounsellorPay
