import React, { useEffect, useState } from "react";
import { Input, FormGroup } from "reactstrap";
import { GET_EXPANDED_DATA } from "../gql/gql";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { setClientSelectedAppointments } from "../../../../store/actions";

function AllSelect(props) {

    const { rowValue, row, index, field, selectedClientList } = props;
    const dispatch = useDispatch();
    const [value, setValue] = useState(false);
    const [appointmentList, setAppointmentList] = useState([]);
    const selectedClientAppointments = useSelector(state => state.receiptManagement.selectedClientAppointments);

    const [getAppointmentDateList, listResponse] = useLazyQuery(GET_EXPANDED_DATA, {
        fetchPolicy: "no-cache",
    });

    function getAppointmentDateListFun() {
        getAppointmentDateList({
            variables: {
                getAppointmentReceiptManagement: {
                    "client_id": parseInt(row.client_id),
                }
            },
        });
    }

    useEffect(() => {
        if (value) {
            getAppointmentDateListFun();
        }
        else {
            dispatch(setClientSelectedAppointments(row.client_id, []));
        }
    }, [value]);

    useEffect(() => {
        if (listResponse.data && listResponse.data.getAppointmentReceiptManagement && listResponse.data.getAppointmentReceiptManagement.status && listResponse.data.getAppointmentReceiptManagement.code === 200) {
            setAppointmentList(listResponse.data.getAppointmentReceiptManagement.data.appointments);
            const filteredArray = listResponse.data.getAppointmentReceiptManagement.data.appointments.map(str => {
                return Number(str.appointment_id);
            });
            dispatch(setClientSelectedAppointments(row.client_id, filteredArray));
        }
        else if (listResponse.data && listResponse.data.getAppointmentReceiptManagement && listResponse.data.getAppointmentReceiptManagement.status && listResponse.data.getAppointmentReceiptManagement.code !== 200) {
            setAppointmentList([]);
        }
        else {
            setAppointmentList([]);
        }
    }, [listResponse.data]);

    useEffect(() => {
        const currentObj = selectedClientAppointments.find(n => n.id === row.client_id);
        if (currentObj) {
            if (currentObj.selectedList.length == row.count) {
                setValue(currentObj.selectedList.length == row.count);
            }
            else {
                setValue(currentObj.selectedList.length == row.count);
            }
        }
    }, [selectedClientAppointments]);

    const handleRowSelect = (isChecked) => {
        setValue(isChecked);
    }

    return (
        <div className="d-flex align-items-center justify-content-center">
            <FormGroup check>
                <Input
                    id="exampleCheck"
                    name="check"
                    type="checkbox"
                    checked={value}
                    onChange={(e) => handleRowSelect(e.target.checked)}
                />
            </FormGroup>
        </div>
    )
}

export default AllSelect