import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import * as Yup from "yup"
import { useFormik } from "formik"
import Loader from "../../components/Common/Loader"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { GET_THERAPY, SUBMIT_THERAPY } from "./gql"
import Dropzone from "react-dropzone"
import RichTextBox from "../../common/RichTextBox"
import style from "./style.module.scss"
import AsyncSelect from "react-select/async"
import { fetchApi } from "../../common/api"
import Spinner from "../../assets/images/Loader.svg"

const AddTherapies = () => {
  let history = useHistory()
  const [toggleSwitch, settoggleSwitch] = useState(true)
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = decodedId
  const [getTherapies, getTherapiesList] = useLazyQuery(GET_THERAPY, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(SUBMIT_THERAPY)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoader, setIsLoader] = useState(true)
  const [initialValues, setInitialValues] = useState({
    name: "",
    main_image: undefined,
    image: undefined,
    article: "",
    counselling: [],
    status: false,
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Please enter therapy name"),
    }),
    onSubmit: values => {
      setIsSubmit(true)
      let obj = {
        name: values.name,
        article: values.article,
        image: values.image?.url ?? "",
        main_image: values.main_image?.url ?? "",
        counselling_ids: values?.counselling?.map(d => d.value) ?? [],
        status: values.status ? "active" : "inactive",
      }
      if (id) {
        obj.id = parseInt(id)
      }
      submitMutation({
        variables: {
          createTherapyInput: obj,
        },
      }).then(res => {
        toast.success(`Therapy successfully ${id ? "updated" : "created"}`)
        setTimeout(() => {
          if (id) {
            setIsSubmit(false)
          }else{
          history.push("/therapies")
          }
        }, 1500).catch(err => {
          toast.error("something went wrong !")
          setIsSubmit(false)
        })
      })
    },
  })
  useEffect(() => {
    if (id) {
      setIsLoader(true)
      getTherapies({
        variables: {
          getThearpyInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [id])
  useEffect(() => {
    if (
      getTherapiesList.data &&
      getTherapiesList.data?.getTherapyDetails?.name
    ) {
      setInitialValues({
        name: getTherapiesList.data.getTherapyDetails.name,
        main_image: getTherapiesList.data.getTherapyDetails.main_image
          ? {
              state: "uploaded",
              url: getTherapiesList.data.getTherapyDetails.main_image,
            }
          : undefined,
        image: getTherapiesList.data.getTherapyDetails.image
          ? {
              state: "uploaded",
              url: getTherapiesList.data.getTherapyDetails.image,
            }
          : undefined,
        article: getTherapiesList.data.getTherapyDetails.article,
        counselling: getTherapiesList.data.getTherapyDetails.counselling
          ? getTherapiesList.data.getTherapyDetails.counselling.map(
              ({ id, name, ...d }) => ({
                value: id,
                label: name,
              })
            )
          : [],
        status:
          getTherapiesList.data.getTherapyDetails.status == "active"
            ? true
            : false,
      })
      settoggleSwitch(
        getTherapiesList.data.getTherapyDetails.status == "active"
          ? true
          : false
      )
      setIsLoader(false)
    } else {
      setIsLoader(false)
    }
  }, [getTherapiesList.data])

  const handleToggle = e => {
    validation.setFieldValue("status", !validation.values.status)
  }

  const [counsellingList, setCounsellingList] = useState([])
  let [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    totalCount: 0,
  })
  const [searchText, setSearchText] = useState("")

  const getCounsellingApi = async (limit, page, search, issearch, callback) => {
    try {
      let { data, code, status } = await fetchApi(
        `counselling-type?is_all=true&limit=${limit}&page=${page}&search=${search}`,
        "GET"
      )
      if (code == 200 && status && data) {
        const { limit, page, totalCount } = data
        setState({
          page: Number(page),
          sizePerPage: Number(limit),
          totalCount,
        })
        const couselling = data?.CounsellingType?.map(({ id, name, ...d }) => ({
          value: id,
          label: name,
        }))

        setCounsellingList(couselling)
        if (issearch) {
          callback(couselling)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    const { sizePerPage, page } = state
    getCounsellingApi(sizePerPage, page, searchText)
  }, [])
  const loadOptions = (inputSelect, callback) => {
    const { sizePerPage, page } = state
    getCounsellingApi(sizePerPage, page, inputSelect, true, callback)
  }

  const handleImg = async (acceptedFiles, field) => {
    if (acceptedFiles[0].size > 5 * 1024 * 1000) {
      toast.error("Max File Upload Size is Upto 5 MB, try with smaller size")
      return
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }
    validation.setFieldValue(field, preview)

    let payload = new FormData()
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined
    payload.set("file", acceptedFiles[0])
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        { method: "POST", body: payload,
        headers: { 
          organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

        }
       }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        validation.setFieldValue(field, preview)
      } else {
        validation.setFieldValue(field, undefined)
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      console.log(e)
      validation.setFieldValue(field, undefined)
      toast.error("Failed to upload cover image")
    }
  }

  return (
    <div className="page-content">
      {isLoader ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div>
              <div className="py-3">
                <div className="flex align-item-center">
                  <i
                    className="waves-effect color-dark-green mdi mdi-arrow-left font-size-22 mr-2"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className="mt-1 mx-2 card-title-main">
                    {" "}
                    {id ? "" : "Create New "} Therapy
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={8}>
              <Card className="transparent-bg-card">
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="form-custom-white-inputs">
                      <Col xl={12} md={12}>
                        <Label className="form-label">Therapy</Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Therapy Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && (
                          <div className={style.warnText}>
                            {validation.errors.name}
                          </div>
                        )}
                      </Col>

                      <Col xl={6} md={12} className="mt-3">
                        <Label className="form-label">Main Image</Label>
                        <Dropzone
                          multiple={false}
                          onDrop={file => handleImg(file, "main_image")}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <input {...getInputProps()} />

                              {!validation?.values?.main_image ? (
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload" />
                                  </div>
                                  <h4>Drop JPEG, JPG or PNG.</h4>
                                  <span className="defaultText">
                                    It can be square, landscape or portrait.
                                    Upto 5MB file size.
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {validation?.values?.main_image?.state ===
                                    "uploading" && (
                                    <img
                                      className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                      alt="primary"
                                      src={Spinner}
                                    />
                                  )}
                                  {validation?.values?.main_image?.state ===
                                    "uploaded" && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          validation.setFieldValue(
                                            "main_image",
                                            undefined
                                          )
                                        }}
                                        className={`${style.removeButton} mdi mdi-close`}
                                        type="button"
                                        aria-label="Close"
                                      ></button>

                                      <img
                                        {...getRootProps()}
                                        className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                        alt="primary"
                                        src={
                                          validation?.values?.main_image?.url
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Dropzone>
                        {validation.errors?.main_image && (
                          <div className="candidate-multi-select-error">
                            {validation.errors?.main_image}
                          </div>
                        )}
                      </Col>

                      <Col xl={6} md={12} className="mt-3">
                        <Label className="form-label">Thumbnail Image</Label>
                        <Dropzone
                          multiple={false}
                          onDrop={file => handleImg(file, "image")}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <input {...getInputProps()} />

                              {!validation?.values?.image ? (
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload" />
                                  </div>
                                  <h4>Drop JPEG, JPG or PNG.</h4>
                                  <span className="defaultText">
                                    It can be square, landscape or portrait.
                                    Upto 5MB file size.
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {validation?.values?.image?.state ===
                                    "uploading" && (
                                    <img
                                      className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                      alt="primary"
                                      src={Spinner}
                                    />
                                  )}
                                  {validation?.values?.image?.state ===
                                    "uploaded" && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          validation.setFieldValue(
                                            "image",
                                            undefined
                                          )
                                        }}
                                        className={`${style.removeButton} mdi mdi-close`}
                                        type="button"
                                        aria-label="Close"
                                      ></button>

                                      <img
                                        {...getRootProps()}
                                        className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                        alt="primary"
                                        src={validation?.values?.image?.url}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Dropzone>
                        {validation.errors?.image && (
                          <div className="candidate-multi-select-error">
                            {validation.errors?.image}
                          </div>
                        )}
                      </Col>

                      <Col xl={12} md={12} className="mt-3">
                        <div className="my-3">
                          <Label className="form-label">Article</Label>
                          <RichTextBox
                            name="article"
                            value={initialValues.article || ""}
                            onChange={value =>
                              validation.setFieldValue("article", value)
                            }
                            validation={validation}
                            onBlur={() => {
                              validation.setFieldTouched("article", true)
                            }}
                            error={validation.errors?.article}
                            touched={validation.touched?.article}
                          />
                        </div>
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label">Counselling</Label>
                        <AsyncSelect
                          cacheOptions
                          isMulti
                          placeholder="Select Counselling"
                          loadOptions={loadOptions}
                          defaultOptions={counsellingList}
                          value={validation?.values?.counselling}
                          onChange={selectedOption => {
                            validation?.setFieldValue(
                              "counselling",
                              selectedOption
                            )
                          }}
                        />
                        <small className="defaultText">Add couselling type to therapy tab</small>
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label" htmlFor="customSwitch2">
                          Publish
                        </Label>
                        <div className="form-check form-switch">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch2"
                            checked={validation.values.status}
                            onClick={e => handleToggle(e)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col xs={12} className="text-end">
                        <button
                          className="btn btn-inactive mx-2"
                          type="button"
                          onClick={() => {
                            history.push("/therapies")
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary "
                          type="submit"
                          disabled={isSubmit}
                        >
                          {id ? "Update" : "Add"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
export default AddTherapies
