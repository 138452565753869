import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_APPOITMENT_TYPE } from "./gql/Gql"
import Table from "../../components/Common/Table"
import useStyles from "../Dashboard/styles"
import style from "./style.module.scss"
import { toast } from "react-toastify"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import edit from "../../assets/images/dashboard-icons/edit.svg"
import plane from "../../assets/images/dashboard-icons/plane.svg"
import { useCallback } from "react"
import { debounce } from "lodash"
import { hasPermission } from "../../functions/functions"
import PageNotFound from "../404"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"
import DeleteModal from "../../components/Common/DeleteModal"
import { CREATE_EDIT_APPOINTMENT_TYPE } from "../User/gql/userGql"

const AppointmentType = () => {
  const classes = useStyles()
  let history = useHistory()

  let [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    totalCount: 0,
    search: "",
  })
  let [list, setList] = useState([])
  let [isSpinner, setIsSpinner] = useState(true)
  const [deleteModalId, setDeleteModalId] = useState(-1)
  const [inProgress, setInProgress] = useState(false)

  const [getAppoitmentType, getAppoitmentTypeList] = useLazyQuery(
    GET_APPOITMENT_TYPE,
    { fetchPolicy: "no-cache" }
  )
  const getAppoitmentTypeFun = () => {
    getAppoitmentType({
      variables: {
        argumentsInput: {
          limit: state.sizePerPage,
          page: state.page,
          sort: "id",
          sort_dir: "DESC",
        },
      },
    })
  }
  useEffect(() => {
    getAppoitmentTypeFun()
  }, [])
  useEffect(() => {
    if (
      getAppoitmentTypeList.data &&
      getAppoitmentTypeList.data.findAll &&
      getAppoitmentTypeList.data.findAll.code == 200 &&
      getAppoitmentTypeList.data.findAll.data
    ) {
      setIsSpinner(false)
      setList(getAppoitmentTypeList.data.findAll.data.appointmentTypes)
      setState(prev => {
        return {
          ...prev,
          ...{ totalCount: getAppoitmentTypeList.data.findAll.data.totalCount },
        }
      })
    }
    if (
      getAppoitmentTypeList.data &&
      getAppoitmentTypeList.data.findAll &&
      getAppoitmentTypeList.data.findAll.code !== 200 &&
      getAppoitmentTypeList.data.findAll.data
    ) {
      setIsSpinner(false)
    }
  }, [getAppoitmentTypeList.data])

  const [deleteAppointmentType, deleteAppointmentTypeRes] = useMutation(
    CREATE_EDIT_APPOINTMENT_TYPE
  )

  const handleEditClick = row => {
    const encodedId = btoa(row.id)
    history.push(`/create-appointment-type/${encodedId}`)
  }

  const handleDeleteModal = row => {
    setDeleteModalId(Number(row.id))
  }

  const handleDelete = async id => {
    const deleteData = {
      id,
      active: false,
    }
    try {
      await deleteAppointmentType({
        variables: {
          createAppointmentTypeInput: deleteData,
        },
      })
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong !")
    } finally {
      setInProgress(false)
      setDeleteModalId(-1)
      getAppoitmentTypeFun()
    }
  }

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        {row.id !== 1 ? (
          <div className="d-flex align-items-center">
            <Button
              className="btn btn-primary cursor-pointer"
              onClick={() => handleEditClick(row)}
            >
              <img src={edit} />
            </Button>
            <a
              onClick={() => handleDeleteModal(row)}
              className="px-3 text-danger delete-btn cursor-pointer"
            >
              <img src={deleteBin} />
            </a>
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null

  const [columns, setColumns] = useState([
    {
      text: "ID",
      dataField: "id",
      keyField: "id",
      hidden: true,
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      text: "Category",
      dataField: "category",
      headerStyle: {
        cursor: "pointer",
      },
      style: (cell, row, rowIndex) => ({
        maxWidth: 200,
      }),
    },
    {
      text: "Call type",
      dataField: "call_type",
      headerStyle: {
        cursor: "pointer",
      },
      formatter: (cell, row) => {
        return <span className="">{row?.call_type ? row.call_type : ""}</span>
      },
    },
    {
      text: "Address",
      dataField: "address",
      headerStyle: {
        cursor: "pointer",
      },
      formatter: (cell, row) => {
        return (
          <span className="">
            {row?.location?.name ? row.location.name : ""}
          </span>
        )
      },
    },
    {
      text: "Location",
      dataField: "call_type",
      formatter: (cell, row) => {
        if (row.google_address && row.call_type == "in person") {
          return (
            <Button className={` btn btn-primary `}>
              <a
                className="bg-direc-button"
                href={`${row.google_address}`}
                target="blank"
              >
                <span
                  className={`${style.joinBtnText} d-flex align-items-center`}
                >
                  <img src={plane} className="mx-1" /> Directions
                </span>
              </a>
            </Button>
          )
        } else {
          return ""
        }
      },
    },
    {
      text: "Color",
      dataField: "color",
      formatter: (cell, row) => {
        return (
          <div
            style={{
              backgroundColor: `${cell}`,
            }}
            className="appointment-type-w50-bradius"
          ></div>
        )
      },
    },
    {
      text: "Duration",
      dataField: "duration",
      sort: true,
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
    },
  ])
  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setState(p => ({
      ...p,
      page,
      sizePerPage,
    }))
    setIsSpinner(true)
    getAppoitmentType({
      variables: {
        argumentsInput: {
          limit: sizePerPage,
          page: page,
          sort: sortField,
          sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
          search: state.search || "",
        },
      },
    })
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleReSyncApi = () => {
    toast.info("Data sync with acuity started")
    let url = `${process.env.REACT_APP_API_ENDPOINT}/event/create-appointment-types`
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get(
      "orgId"
    )
    const decodedOrgIdFromUrl = orgIdValueFromUrl
      ? decodeURIComponent(orgIdValueFromUrl)
      : undefined

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        organization:
          decodedOrgIdFromUrl ||
          orgData?.organization_identifier ||
          process.env.REACT_APP_MULTITENENT,
      },
    }
    fetch(url, requestOptions).then(res => {
      if (res.status == "200") {
        return res.json()
      }
    })
  }
  const handleSearch = e => {
    setIsSpinner(true)
    setState(p => ({
      ...p,
      page: 1,
      search: e.target.value,
    }))
    getAppoitmentType({
      variables: {
        argumentsInput: {
          limit: state.sizePerPage,
          page: state.page,
          search: e.target.value,
        },
      },
    })
  }
  const delaySearch = useCallback(debounce(handleSearch, 1000), [])
  const localData = localStorage.getItem("orgData")
  const orgData = localData ? JSON.parse(localData) : null


  const openAppointmentType = () => {
      history.push(`/create-appointment-type`)
  }

  return (
    <div className="page-content">
      {hasPermission() ? (
        <div className="container-fluid">
          <Row className="justify-content-between">
            <Col className=" " xl={6}>
              <div>
                <div className="py-3">
                  <h4 className="card-title-main">Appointment Types</h4>
                  <h5 className="card-para-main">
                    List of all the appointment types in{" "}
                    {orgData?.name ? orgData?.name : "NFC"}.
                  </h5>
                </div>
              </div>
            </Col>

            <Col className="text-end " xl={6}>
              {orgId === 1 && (
                <Button
                  className="btn btn-primary"
                  onClick={() => handleReSyncApi()}
                >
                  Sync With Acuity
                </Button>
              )}
            </Col>
          </Row>

          <Row className="mg-top-default-ui">
            <Col md={4} className="mb-3 d-flex">
              <Input
                type="text"
                className="default-search-input "
                placeholder="Search appointment type"
                onChange={e => delaySearch(e)}
              />
            </Col>
            <Col md={8} className="mb-3 d-flex justify-content-end">
              <Button
                onClick={() => openAppointmentType()}
                to="/create-appointment-type"
                className="btn btn-primary mb-1"
              >
                Create Appointment Type
              </Button>
            </Col>

            <Col md={12}>
              <Card className="light-green-bg-card-table">
                <CardBody className="table-custom-redesign">
                  <Table
                    data={list}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    keyField={"id"}
                    isHover={false}
                    isStriped={false}
                    isBordereded={false}
                  />
                </CardBody>
              </Card>
            </Col>
            <DeleteModal
              show={deleteModalId !== -1}
              onDeleteClick={() => {
                handleDelete(deleteModalId)
              }}
              onCloseClick={() => {
                setDeleteModalId(-1)
              }}
              inProgress={inProgress}
            />
          </Row>
        </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  )
}
export default AppointmentType
