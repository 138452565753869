import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Button, Row, Modal, Label } from "reactstrap"
import { CANCEL_APPOINTMENT, GET_APPOINTMENT, LINKED_ACCOUNT_STATUS, MAKE_PAYMENT, GET_STRIPE_TRANSACTION, REFUND_PAYMENT, UPDATE_APPOINTMENT } from "./gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import "react-toastify/dist/ReactToastify.css"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import {
  capitalizeFirstLetter,
  convertUTCToLocal,
  formatPhoneNumber,
  meetingType,
} from "../../functions/functions"
import style from "./style.module.scss"
import moment from "moment"
import _ from "lodash"
import Loader from "../../components/Common/Loader"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"

// images
import clientCreationDate from "../../assets/images/details-icons/creation-date.png"
import meetingTypeImg from "../../assets/images/details-icons/meeting-type.png"
import meetingColor from "../../assets/images/details-icons/meeting-color.png"
import appointmentTypeName from "../../assets/images/details-icons/appointment-type-name.png"
import clientAge from "../../assets/images/details-icons/client-age.png"
import counsellorLocation from "../../assets/images/details-icons/counsellor-location.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import CardWarning from "./CardWarning"
import WarningMessage from "./WarningMessage"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import EditClientModal from "./EditClientModal"

const useStyles = makeStyles(theme => ({
  joinBtnText: {
    fontSize: "17px",
    fontWeight: 500,
  },
}))

function deleteToggle() {
  setModalDelete(!modalDelete)
}

function refundModalToggle() {
  setRefunModalDelete(!refunModalDelete)
}

const PopupActionButton = ({ appointmentDetails }) => {
  const { latitude, longitude } =
    (appointmentDetails && appointmentDetails?.type_of_appointment) || {}
  const classes = useStyles()

  const checkLiveAppointments = data => {
    let appointmentDate = moment(data.utc_datetime)
      .startOf("day")
      .local()
      .format("YYYY-MM-DD HH:mm:ssZ")
    let appointmentEndDate = moment(data.utc_datetime)
      .endOf("day")
      .local()
      .format("YYYY-MM-DD HH:mm:ssZ")
    let Today = moment()
    if (
      Today.startOf("day").isBefore(appointmentEndDate) &&
      Today.endOf("day").isAfter(appointmentDate)
    ) {
      return true
    } else {
      return false
    }
  }

  const joinVideoCall = () => {
    const win = window.open(
      `/appointments/join/${
        appointmentDetails && appointmentDetails?.appointment_id
      }`,
      "_blank"
    )
    win.focus()
  }

  if (appointmentDetails && appointmentDetails == undefined) return null
  const isLive = checkLiveAppointments(appointmentDetails)

  let endTiming = ""
  if (appointmentDetails) {
    const utcDate = new Date(appointmentDetails?.utc_datetime)

    const endTime = new Date(utcDate.getTime() + appointmentDetails?.duration * 60000)

    const localEndString = endTime.toLocaleString("en-US", {
      timeZone: appointmentDetails?.timezone,
    })

    function isCurrentTimeBefore(targetDateTimeString) {
      const targetDateTime = new Date(targetDateTimeString)

      const currentDateTime = new Date()

      return targetDateTime > currentDateTime
    }

    endTiming = isCurrentTimeBefore(localEndString)
  }

  if (
    appointmentDetails &&
    appointmentDetails?.type_of_appointment?.call_type == "online video" && endTiming
  ) {
    return (
      <Button
        color="primary"
        onClick={joinVideoCall}
        className={`btn btn-primary btn-rounded waves-effect waves-light ${style.typebutton}`}
      >
        <a className="join-video-btn-color" href="#">
          <span className={classes.joinBtnText}>
            <i className="uil-video"></i> Join Video
          </span>
        </a>
      </Button>
    )
  }

  if (
    appointmentDetails &&
    appointmentDetails?.type_of_appointment?.call_type == "in person" &&
    appointmentDetails?.type_of_appointment?.google_address
  ) {
    return (
      <Button
        color={"primary"}
        className={`btn btn-primary btn-rounded waves-effect waves-light ${style.typebutton}`}
      >
        <a
          className="join-video-btn-color"
          href={`${appointmentDetails?.type_of_appointment?.google_address}`}
          target="blank"
        >
          <span className={classes.joinBtnText}>
            <i className="uil-corner-up-right-alt"></i> Direction
          </span>
        </a>
      </Button>
    )
  }
}

function AppointmentInfo(props) {
  const { id, isModel = false, showActionButtonOnHead = false, setModal } = props
  const [modalDelete, setModalDelete] = useState(false)
  const [refunModalDelete, setRefunModalDelete] = useState(false)
  const history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)
  const [appointmentDetails, setAppointmentDetails] = useState({})
  const [appointmentForms, setAppointmentForms] = useState([])
  const [isSpinner, setIsSpinner] = useState(false)
  const clientPage = new URLSearchParams(location.search).get("client")
  const [accountDetailsStatus, setAccountDetailsStatus] = useState()
  const [isDirect, setIsDirect] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)
  const [isAppointmentCancelled, setIsAppointmentCancelled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentIntentId, setPaymentIntentId] = useState(null)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [IsRefunded, setIsRefunded] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [stripeTransaction, setStripeTransaction] = useState()
    const encodedAccountData = localStorage.getItem("accountData")
  const accountStatus = encodedAccountData
    ? JSON.parse(atob(encodedAccountData))
    : null

  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])

  const [MakePayment, MakePaymentResponse] =
    useMutation(MAKE_PAYMENT)

    const [RefundPayment, RefundPaymentResponse] =
    useMutation(REFUND_PAYMENT)
    const handleRefund = () => {
      const id = paymentIntentId?.payment_intent_id
    RefundPayment({
      variables: {
        paymentIntentId: id,
      },
    })
    }

  const [editEnable, setEditEnable] = useState(false)
  const [selectedRoomSize, setSelectedRoomSize] = useState([])
  const [updatededRoomSize, setUpdatedRoomSize] = useState(null)
  const [preRoomSize, setPreRoomSize] = useState(null)
  const [updateRoomSize, updateRoomSizeRes] =
  useMutation(UPDATE_APPOINTMENT)

  useEffect(() => {

    if (
      updateRoomSizeRes.data &&
      updateRoomSizeRes.data?.updateAppointment?.status &&
      updateRoomSizeRes.data?.updateAppointment?.code === 200
    ){
      toast.success("Room size updated successfully")
      setIsSpinner(true)
      getAppointmentDetail({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
      editEnableFun()
    }else if(updateRoomSizeRes.data?.updateAppointment?.status &&
      updateRoomSizeRes.data?.updateAppointment?.code !== 200){
      toast.error("Something went wrong")
      }
  }, [updateRoomSizeRes.data])
  
  const [roomSizeList, setRoomSizeList] = useState([
    {
      label: "Individual Counselling",
      name: "Individual Counselling",
      value: 2,
      id: 1,
    },
    {
      label: "Couples/Family Counselling",
      name: "Couples/Family Counselling",
      value: 10,
      id: 2,
    },
    {
      label: "Group Counselling",
      name: "Group Counselling",
      value: 50,
      id: 3,
    },
  ])

  useEffect(() => {
    const hasMScAndSupervisedBy = str => {
      return str?.includes("MA") && str?.includes("Supervised by Ofir Vaisman RCC #11281")
    }
      if (
        hasMScAndSupervisedBy(appointmentDetails?.therapist_calendar?.name)
      ) {
        const groupRoomSize = {
          label: "Group Counselling",
          name: "Group Counselling",
          value: 50,
          id: 3,
        }
        setRoomSizeList([groupRoomSize])
        setSelectedRoomSize(groupRoomSize)
      }else{
        const groupRoomSize = [
          {
            label: "Individual Counselling",
            name: "Individual Counselling",
            value: 2,
            id: 1,
          },
          {
            label: "Couples/Family Counselling",
            name: "Couples/Family Counselling",
            value: 10,
            id: 2,
          },
          {
            label: "Group Counselling",
            name: "Group Counselling",
            value: 50,
            id: 3,
          },
        ]
        setRoomSizeList(groupRoomSize)
        setSelectedRoomSize(groupRoomSize[0])
    }
  }, [appointmentDetails?.therapist_calendar?.name])

    const editEnableFun= () => {
      setEditEnable(!editEnable)
      const filteredRoomSizeList = roomSizeList.filter(room => 
        appointmentDetails?.participants == room.value
      );
      setSelectedRoomSize(filteredRoomSizeList[0])
      setPreRoomSize(filteredRoomSizeList[0])
    }
    const handleRoomSize = value => {
      setSelectedRoomSize(value)
      setUpdatedRoomSize(value)
    }

  const changeRoomSize = () => {
    const update_data = {
     participants: selectedRoomSize.value,
     id: appointmentDetails?.id
    }
    if(selectedRoomSize.value){
      try {
        updateRoomSize({
         variables: {
          updateAppointmentInput: update_data,
         },
       })
     } catch (e) {
       console.log("err", e)
     }
    }
    
  }
  useEffect(() => {
      if(RefundPaymentResponse?.data &&
        RefundPaymentResponse?.data?.refundPayment
        ){
        toast.success(RefundPaymentResponse?.data?.refundPayment?.message)
        setRefunModalDelete(false)
        getAppointmentDetail({
          variables: {
            argumentsInput: {
              id: parseInt(id),
            },
          },
        })
      }
    }, [RefundPaymentResponse?.data])
      

  const makePaymentFun = (id) => {
    if(id){
      setIsLoading(true)
    MakePayment({
      variables: {
          appointmentId: id,
      },
    })
  }
}

const [hideVideoSize, setHideVideoSize] = useState(false)
useEffect(() => {
  if (appointmentDetails) {
    const utcDate = new Date(appointmentDetails?.utc_datetime)

    const endTime = new Date(utcDate.getTime() + appointmentDetails?.duration * 60000)

    const localEndString = endTime.toLocaleString("en-US", {
      timeZone: appointmentDetails?.timezone,
    })

    function isCurrentTimeBefore(targetDateTimeString) {
      const targetDateTime = new Date(targetDateTimeString)

      const currentDateTime = new Date()

      return targetDateTime > currentDateTime
    }

    setHideVideoSize(isCurrentTimeBefore(localEndString))
  }
}, [appointmentDetails])


  useEffect(() => {
    if(MakePaymentResponse.data?.makePayment?.data &&
      MakePaymentResponse.data?.makePayment?.data?.url
      ){
        const paymentUrl = MakePaymentResponse.data?.makePayment?.data?.url
        window.location.href = paymentUrl
      }else{
      setIsLoading(false)
      toast.error(MakePaymentResponse.data?.makePayment?.message)
      }
  }, [MakePaymentResponse.data])
  

  const [getAppointmentDetail, appointmentResponse] = useLazyQuery(
    GET_APPOINTMENT,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getStripeTransactionDetail, transactionResponse] = useLazyQuery(
    GET_STRIPE_TRANSACTION,
    {
      fetchPolicy: "no-cache",
    }
  )

  useEffect(() => {
      if(transactionResponse.data?.getStripeTransactionForAppointment &&
        transactionResponse.data?.getStripeTransactionForAppointment?.data
        ){
        const allTransactions = transactionResponse.data?.getStripeTransactionForAppointment?.data
        setStripeTransaction(allTransactions)
      }
  }, [transactionResponse.data])
  
  const [cancelAppointmentMutation, cancelAppointmentRes] =
    useMutation(CANCEL_APPOINTMENT)
  function convertUTCToClient(utcDatetime, timezone) {
    return moment.utc(utcDatetime).tz(timezone).format("YYYY-MM-DD HH:mm");
  }
  const startTime =
    userData.role.id === 3
      ? appointmentDetails?.utc_datetime
        ? convertUTCToClient(
            appointmentDetails?.utc_datetime,
            appointmentDetails.timezone
          )
        : null
      : appointmentDetails?.utc_datetime
      ? convertUTCToLocal(appointmentDetails?.utc_datetime)
      : null
  const endTime =
    userData.role.id === 3
      ? appointmentDetails?.duration && startTime
        ? moment(startTime)
            .add(appointmentDetails?.duration, "minutes")
            .tz(appointmentDetails.timezone)
        : null
      : appointmentDetails?.duration
      ? moment(startTime).add(appointmentDetails?.duration, "minutes")
      : null

  const handleCancel = async () => {
    var appointmentIds = [id.toString()]

    try {
      await cancelAppointmentMutation({
        variables: {
          updateAppointmentInput: {
            appointmentIds: appointmentIds,
          },
        },
      })
      if(paymentIntentId && !IsRefunded){
        handleRefund()
      }
    } catch (e) {
      console.log("err", e)
    }
      }

  const handleEditClick = () => {
    const encodedId = btoa(id);
    history.push(`/create-appointment/${encodedId}/edit`)
  }

  React.useEffect(() => {
    if (
      cancelAppointmentRes.data &&
      cancelAppointmentRes.data.updateFromAppointment.status &&
      cancelAppointmentRes.data.updateFromAppointment.code === 200
    ) {
      toast.success("Appointment Cancelled Successfully")
      if (isModel) {
        setModal(undefined)
      } else {
        setTimeout(() => {
          window.history.back()
        }, 3000)
      }
    } else {
      if (
        cancelAppointmentRes.data &&
        cancelAppointmentRes.data.updateFromAppointment
      ) {
        toast.error(cancelAppointmentRes.data.updateFromAppointment.message)
      }
    }
  }, [cancelAppointmentRes.data])

  const getAppointmentFun = () => {
    if(id){
      getAppointmentDetail({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
    }
 
  }

  React.useEffect(() => {
    if (id) {

      getAppointmentDetail({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
      getStripeTransactionDetail({
        variables: {
          appointmentId: parseInt(id),
        },
      })
    }
  }, [])

  React.useEffect(() => {
    if (
      id &&
      appointmentResponse.data &&
      appointmentResponse.data?.getAppointment
    ) {
      setIsSpinner(false)
      setAppointmentDetails(
        appointmentResponse.data?.getAppointment?.data?.appointment
      )
            setIsDirect(
        appointmentResponse.data?.getAppointment?.data?.appointment?.payment_type?.is_direct
      )
      setIsCancelled(
        appointmentResponse.data?.getAppointment?.data?.appointment?.canceled
      )
      setIsAppointmentCancelled(
        appointmentResponse.data?.getAppointment?.data?.appointment?.canceled
      )
      const intentId = appointmentResponse.data?.getAppointment?.data?.appointment?.transactions?.filter((elem)=>{
        return elem.mode.toLowerCase() == "payment"
      })
      setPaymentIntentId(intentId[0])
      setPaymentDetails(intentId)
      const refundedAppointment = appointmentResponse.data?.getAppointment?.data?.appointment?.transactions?.filter((elem)=>{
        return elem.mode.toLowerCase() == "refund"
      })
      setIsRefunded(refundedAppointment[0])
      setAppointmentForms(
        appointmentResponse.data?.getAppointment?.data?.appointment
          ?.appointmentHasForms
      )
    } else {
      setIsSpinner(true)
      setAppointmentDetails({})
    }
  }, [appointmentResponse.data])

  const [editClietnModal, setEditClientModal] = useState(null)
  const editClientDetails = (id) => {
    if(id){
    setEditClientModal(id)
  }
}

  return isSpinner ? (
    <Row >
      <Col
        className="col-12"
        style={
          isModel ? { height: "300px" } : { height: "calc(100vh - 264px)" }
        }
      >
        <div className="spinner">
          <Loader />
        </div>
      </Col>
    </Row>
  ) : (
    <Row className={`m-0 w-100 ${style.cardcenter}`}>
      <Col xl={12}>
        {appointmentDetails && appointmentDetails.therapist_calendar && (
          <>
          
          <div className="w-100 d-flex justify-content-end mb-5">

          {userData.role.id !== 3 && !isCancelled && userData.role.id !== 2 && appointmentDetails?.paid == "yes" && !IsRefunded && (accountStatus && accountDetailsStatus) && <Button
                type="button "
                className="  btn button-delete  delete-btn text-white cursor-pointer "
                onClick={() => setRefunModalDelete(true)}
              >
                <span><span className="mdi mdi-cash-refund me-2"></span>Refund </span>
              </Button>}
          {userData.role.id === 1 && 
            !moment(moment(startTime).format("DD-MMM-yyyy"), "DD-MMM-yyyy").isBefore(moment()) &&
            !clientPage &&
            !isCancelled &&
             <>
              <Button
                type="button "
                className=" button-delete mx-2 "
                onClick={() => setModalDelete(true)}
              >
                <span>Cancel </span>
              </Button>
           
              <Button type="button " className=" btn-primary mx-2" onClick={handleEditClick}>
                <span>Reschedule</span>
              </Button>
              </>  } 
            </div> 
            {
              isCancelled && 
            <div className={`p-0 warning-message ${isAppointmentCancelled ? " open" : ""}`}>
              <WarningMessage isAppointmentCancelled={isAppointmentCancelled} setIsAppointmentCancelled={setIsAppointmentCancelled}/>
              </div>
            }
            {!isDirect && (accountStatus && accountDetailsStatus) &&
            <div className={`p-0 warning-section ${isOpen ? " open" : ""}`}>
            <CardWarning isOpen={isOpen} setIsOpen={setIsOpen} appointmentDetails={appointmentDetails} userId={userData.role.id}/>
            </div>
            }
           
            <Card className="light-green-bg-card border-light-shadow">
              <CardBody>
                {/* #################################Patient Details############################## */}

                <div className={style.headerContainer}>
                  <CardTitle className="h4 m-0">
                    <h5 className="m-0 font-weight-bold h5">
                      Client Details
                    </h5>
                  </CardTitle>
                  {userData.role.id !== 1 && !isCancelled && (
                    <>
                      {!isModel && (
                        <>
                          {showActionButtonOnHead && (
                            <div className="text-end flex-fill">
                              <PopupActionButton
                                appointmentDetails={appointmentDetails}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <hr style={!isModel ? { marginTop: "8px" } : null} />
               {appointmentDetails?.participant?.map((elem)=>{
                  return <>
                <div className="mt-3">
                <div className="font-weight-bold h5  d-flex align-items-center">
                <>
                    {elem?.user?.first_name
                      ? capitalizeFirstLetter(elem?.user?.first_name)
                      : " "}{" "}
                    {elem?.user?.last_name
                      ? capitalizeFirstLetter(elem?.user?.last_name)
                      : " "}
                      </>
                    {userData.role.id !== 3 &&  <span className="mdi mdi-pencil  cursor-pointer text-dark mx-2" onClick={()=>editClientDetails(elem?.user?.id)}></span>}
                  </div>
                </div>
                <div
                  className={classNames(
                    `${isModel ? "flex-column" : null}`,
                    style.patientDetails
                  )}
                >
                  <div className={style.patientDetailsInfo}>
                    {appointmentDetails.email && (
                      <div className="d-flex gap-2 align-items-center">
                        <i className="mdi mdi-email font-size-20 m-0 mt-2 text-primary"></i>
                        <p className="h6 m-0 mt-2 w-75">
                          {elem?.user?.email}
                        </p>
                      </div>
                    )}

                    {elem?.user?.mobile_number && (
                      <div className="d-flex gap-2 align-items-center">
                        <i className="mdi mdi-phone font-size-20 text-primary  m-0 mt-1"></i>
                        <p className="h6 m-0 mt-2">
                          {formatPhoneNumber(
                            elem?.user?.mobile_number
                          )}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className={style.patientDetailsInfo}>
                    { elem?.user?.age && (
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          src={clientAge}
                          height={20}
                          width={20}
                          className="mt-2"
                        />
                        <p className="h6 m-0 mt-2">
                          { elem?.user?.age}
                          {" Years"}
                        </p>
                      </div>
                    )}
                    {appointmentDetails?.client?.created_at && (
                      <div className="d-flex gap-2 align-items-center">
                        <i className="mdi mdi-calendar font-size-20 text-primary  m-0 mt-1"></i>

                        <p className="h6 m-0 mt-2">
                          {moment(
                            elem?.user?.created_at
                          ).format("DD-MMM-yyyy")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                  </>
                })}
              </CardBody>
            </Card>

            {/* ##########################################Therapist  Details############################# */}

            <Card className="light-green-bg-card border-light-shadow">
              <CardBody>
                <h5 className="font-weight-bold h5">Counsellor Details</h5>{" "}
                <hr />
                <div>
                  <div className="font-weight-bold h5">
                    {" "}
                    {appointmentDetails.therapist_calendar.name}
                  </div>
                </div>
                {appointmentDetails.therapist_calendar.email && (
                  <div className="d-flex gap-2 align-items-center">
                    <i className="mdi mdi-email text-primary font-size-20  m-0 mt-2"></i>
                    <p className="h6 m-0 mt-2 w-75">
                      {appointmentDetails.therapist_calendar.email}
                    </p>
                  </div>
                )}
              </CardBody>
            </Card>
          </>
        )}

        {appointmentDetails?.notes && <Card className="light-green-bg-card border-light-shadow">
              <CardBody>
                <h5 className="font-weight-bold h5">Plans</h5>{" "}
                <hr />
                <div>
                  <div className="font-weight-bold h5">
                    {" "}
                    {appointmentDetails?.notes}
                  </div>
                </div>
               
              </CardBody>
            </Card>}

        {/* ######################################### Appointment Details ############################# */}

        <Card className="light-green-bg-card border-light-shadow">
          <CardBody>
            <h5 className="font-weight-bold h5">Appointment Details</h5>{" "}
            <hr />
            <div className={style.patientDetails}>
              <div className={style.patientDetailsInfo}>
                <div className="d-flex gap-2 align-items-center">
                  <i className="mdi mdi-calendar font-size-20 m-0 mt-2 text-primary"></i>
                  <p className="h6 m-0 mt-2">
                    {moment(startTime).format("DD-MMM-yyyy")}
                  </p>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <i className="mdi mdi-alarm-check font-size-20 m-0 mt-1 text-primary"></i>
                  <p className="h6 m-0 mt-2">
                    {moment(startTime).format("hh:mm A")} -{" "}
                    {moment(endTime).format("hh:mm A")}
                  </p>
                </div>
              </div>
            </div>
            {appointmentDetails?.type_of_appointment && (
              <>
                <div className="mt-4 font-weight-bold h5">
                  Appointment Types
                </div>
                <hr className="mt-1" />
                <div className={style.patientDetails}>
                  <div
                    className={classNames(
                      "d-flex flex-column gap-2",
                      style.patientDetailsInfo
                    )}
                  >
                    {appointmentDetails?.type_of_appointment?.name && (
                      <div className={`${style.appointmentType} align-items-center`}>
                        <i className="mdi mdi-account font-size-20 m-0 mt-1 text-primary"></i>

                        <p className="h6 m-0 mt-2">
                          {appointmentDetails?.type_of_appointment?.name}
                        </p>
                      </div>
                    )}

                    {appointmentDetails?.type_of_appointment?.color && (
                      <div className={`${style.appointmentType} align-items-center`}>
                        <i className="mdi mdi-chart-pie font-size-20 m-0 mt-1 text-primary"></i>

                        <p className="h6 m-0 mt-2">
                          <div
                            style={{
                              background:
                                appointmentDetails?.type_of_appointment?.color,
                            }}
                            className={style.colorstyle}
                          ></div>
                        </p>
                      </div>
                    )}

<div className={`${style.appointmentType} align-items-center`}>
                      <i className="mdi mdi-message-video font-size-20 m-0 mt-1 text-primary"></i>

                      <p className="h6 m-0 mt-1">
                        {appointmentDetails?.type_of_appointment?.call_type
                          ? meetingType(
                              appointmentDetails?.type_of_appointment?.call_type
                            )
                          : ""}
                      </p>
                    </div>
                    {appointmentDetails?.type_of_appointment?.call_type == "online video" && <> <div className={`${style.appointmentType} d-flex align-items-center`}>
                    <i className="mdi mdi-account-multiple  font-size-20 m-0 mt-1 text-primary"></i>

                      <p className="h6 m-0 ">
                        {appointmentDetails?.participants
                          ? 
                              `Up to ${appointmentDetails?.participants} Participants`
                                                      : ""}
                      </p>
                      <div className="cursor-pointer">
                      {(userData.role.id !== 3 && hideVideoSize) &&  <span className="mdi mdi-pencil text-dark" onClick={editEnableFun}></span>}
                      </div>

                    </div>
                    {editEnable && 
                      <>
                      <Col md="12">
                        <div className="mb-3 form-custom-white-inputs ">
                          <Label className="form-label mb-3">
                            {" "}
                           Update Video Room Size
                          </Label>
                          <CustomMultiSelect
                            className="input "
                            onChange={value =>
                              handleRoomSize(value)
                            }
                            placeholder="Select Video Room Size"
                            value={selectedRoomSize}
                            options={roomSizeList}
                            isMulti={false}
                          />
                        </div>
                        <p className="form-label alert alert-warning p-2 m-0 ">
                        <span className="font-weight-bold">Please note: </span>If the room size is modified, any participant currently in the meeting will need to leave and rejoin in order to adjust to the new configuration.
                        </p>
                      </Col>
                      
                      <Col md={12}>
                        <button className="btn btn-primary mt-2" onClick={changeRoomSize} disabled={!updatededRoomSize || (preRoomSize?.value === updatededRoomSize?.value )}>
                          Save
                        </button>
                      </Col>
                      </>
                    }
                    <div>
                    </div>
                    </>
                    }
                  </div>
                </div>
              </>
            )}
            {appointmentDetails?.block_off_calendar && (
              <>
             {appointmentDetails?.block_off_calendar[0]?.room && (<>                
             <div className="mt-4 font-weight-bold h5">
                  Room Details
                </div>
                <hr className="mt-1" />
                <div className={style.patientDetails}>
                  <div
                    className={classNames(
                      "d-flex flex-column gap-2",
                      style.patientDetailsInfo
                    )}
                  >
                    {appointmentDetails?.type_of_appointment?.name && (
                      <div className={style.appointmentType}>
                        <i className="mdi mdi-map-marker font-size-20 m-0 mt-1 text-primary"></i>

                        <p className="h6 m-0 mt-2">
                      {appointmentDetails?.block_off_calendar[0]?.room?.location?.name}

                        </p>
                      </div>
                    )}


                    <div className={style.appointmentType}>
                      <i className="mdi mdi-office-building font-size-20 m-0 mt-1 text-primary"></i>

                      <p className="h6 m-0 mt-2">
                      {appointmentDetails?.block_off_calendar[0]?.room?.name}

                       
                      </p>
                    </div>
                  </div>
                </div></>)}
              </>
            )}
          </CardBody>
        </Card>

        {/* ######################################### Appointment Types ############################# */}

        {appointmentForms &&
          appointmentForms.map(appointmentForm => (
            <div key={appointmentForm.id}>
              {appointmentForm?.forms?.length !== 0 && (
                <Card className="light-green-bg-card border-light-shadow">
                  <CardBody>
                    <h5 className="font-weight-bold h5">
                      Phone Consultation Intake Form
                    </h5>
                    <hr />
                    {appointmentForm.forms &&
                      appointmentForm.forms.map(appointmentFormdetails => (
                        <div key={appointmentFormdetails.id} className="mt-3">
                          <div className="d-flex align-items-center m-0">
                            <CardTitle className="m-0 font-size-16">
                              {capitalizeFirstLetter(
                                appointmentFormdetails.name
                              )}
                            </CardTitle>
                          </div>
                          <div className="d-flex">
                            <p className="h6 mb-2">
                              {appointmentFormdetails.value}
                            </p>
                          </div>
                        </div>
                      ))}
                  </CardBody>
                </Card>
              )}
            </div>
          ))}
          {paymentDetails?.length > 0 &&
          <Card className="light-green-bg-card border-light-shadow">
          <CardBody>
            <h5 className="font-weight-bold h5">Transactions Details</h5>{" "}
            <hr />
           
            <Row className="d-flex flex-column justify-content-between">
              <Col md={12} >
               <p className="h5">
               Payment Transactions
               </p>  
            <hr />
            {paymentDetails.map((elem)=>{
              return <> <div className="d-flex align-items-center gap-2">
                  <i className="mdi mdi-calendar font-size-20 m-0 mt-2 text-primary"></i>
                  <p className="h6 m-0 mt-2">
                    {moment(elem?.created_at).format("DD-MMM-yyyy hh:mm A")}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="mdi mdi-credit-card-outline font-size-20 m-0 mt-2 text-primary"></span>
                  <p className="h6 m-0 mt-2 text-capitalize">{JSON.parse(elem?.extra_data)?.payment_method_types[0]}</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="mdi mdi-currency-usd font-size-20 m-0 mt-2 text-primary"></span>
                  <p className="h6 m-0 mt-2 text-capitalize">{stripeTransaction?.total}</p>
                </div>
                <hr />
                </>
            })}
               </Col>
            </Row>
            {IsRefunded && <Row className="d-flex flex-column justify-content-between">
              <Col md={12} >
               <p className="h5">
               Refund Transactions
               </p>  
            <hr />
             <div className="d-flex align-items-center gap-2">
                  <i className="mdi mdi-calendar font-size-20 m-0 mt-2 text-primary"></i>
                  <p className="h6 m-0 mt-2">
                    {moment(IsRefunded?.created_at).format("DD-MMM-yyyy hh:mm A")}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="mdi mdi-credit-card-outline font-size-20 m-0 mt-2 text-primary"></span>
                  <p className="h6 m-0 mt-2 text-capitalize">{JSON.parse(IsRefunded?.extra_data).destination_details?.type}</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="mdi mdi-currency-usd font-size-20 m-0 mt-2 text-primary"></span>
                  <p className="h6 m-0 mt-2 text-capitalize">{stripeTransaction?.total}</p>
                </div>
               </Col>
            </Row>}
          </CardBody>
        </Card>}
         {(accountStatus && accountDetailsStatus) && <Card className="light-green-bg-card border-light-shadow">
          <CardBody>
            <h5 className="font-weight-bold h5">Payment Details</h5>{" "}
            <hr />
           
            <Row className="d-flex flex-column justify-content-between">
              <Col md={12} >
              {(userData.role.id === 2 || userData.role.id === 3) && 
              <>
              <div className="d-flex justify-content-between my-1"><p className="h6">Client Rate </p><p className="h6"> CA$ {stripeTransaction?.client_rate}</p></div>         
              <div className="d-flex justify-content-between my-1"><p className="h6">GST </p><p className="h6">+ CA$ {stripeTransaction?.gst}</p></div>         
              <p className="border border-dark"></p>
              <div className="d-flex justify-content-between my-1"><p className="h6">Total</p><p className="h6">CA$ <span className="h6 font-weight-bold">{stripeTransaction?.total}</span></p></div>       
              </>
              } 
              {(userData.role.role_name.toLowerCase() === "admin" || userData.role.role_name.toLowerCase() === "superadmin") && 
              <>
              <div className="d-flex justify-content-between my-1"><p className="h6">Client Rate </p><p className="h6"> CA$ {stripeTransaction?.client_rate}</p></div>  
              <div className="d-flex justify-content-between my-1"><p className="h6">GST </p><p className="h6">+ CA$ {stripeTransaction?.gst}</p></div>         
              <div className="d-flex justify-content-between my-1"><p className="h6">Platform Fees </p><p className="h6">- CA$ {stripeTransaction?.wendy_fee}</p></div>         
              <p className="border border-dark"></p>
              <div className="d-flex justify-content-between my-1"><p className="h6">Paid To Clinic </p><p className="h6">CA$ <span className="h6 font-weight-bold">{stripeTransaction?.clinic_amount}</span></p></div>         
              </>
              }
            
              </Col>
              <Col md={6} className="mt-3">

              {(accountStatus && accountDetailsStatus) && 
               (appointmentDetails?.paid?.toLowerCase() === "no" && !isDirect && userData.role.id === 3 && appointmentDetails?.payment_type && <Button className="btn btn-primary text-dark" disabled={isLoading} onClick={()=> makePaymentFun(appointmentDetails?.id)}> <span className="mdi mdi-credit-card-outline me-2"></span>Make payment</Button>)}
               {(accountStatus && accountDetailsStatus) && 
               (appointmentDetails?.paid?.toLowerCase() === "yes"  && !isDirect && <span className="border border-dark rounded p-2 text-dark font-weight-bold"> <span className="mdi mdi-check-all me-2"></span>Payment Done</span>)
               }
               {(accountStatus && accountDetailsStatus) && 
               (appointmentDetails?.paid?.toLowerCase() === "no" && !isDirect && userData.role.id !== 3 && <span className="border border-dark rounded text-dark p-2 font-weight-bold"> <span className="mdi mdi-timer-outline me-2"></span>Payment Pending</span>)
               }
               </Col>
            </Row>
          </CardBody>
        </Card>}
          <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setModalDelete(false)
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => handleCancel()}
                >
                  Yes, Cancel it
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        isOpen={refunModalDelete}
        toggle={() => {
          refundModalToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setRefunModalDelete(false)
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => handleRefund()}
                >
                  Yes, Refund it
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      </Col>
      <EditClientModal setEditClientModal={setEditClientModal} editClietnModal={editClietnModal} getAppointmentFun={getAppointmentFun}/>
    </Row>
  )
}

AppointmentInfo.propTypes = {
  id: PropTypes.number.isRequired,
}

export default AppointmentInfo
