import React, { useState } from "react"
import { useHistory, withRouter } from "react-router-dom"
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row
} from "reactstrap"

import { map } from "lodash"
import { useLazyQuery, useMutation } from "@apollo/client"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { GET_NOTIFICATIONS, READ_NOTIFICATION } from "./gql/notificationGql"

const NotificationList = props => {

    const [isSpinner, setIsSpinner] = useState(false)
    const [notifications, setNotifications] = useState([])
    let history = useHistory()
    const [totalCount, setTotalCount] = useState(0)
    const params = new URLSearchParams(window.location.search)
    const pageNumber = params.get('page')
    const [state, setState] = useState({
        page: pageNumber ? parseInt(pageNumber) : 1,
        data: notifications,
        sizePerPage: 10
    })

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    //*******************Notification List*********************** */
    const [getNotifications, response] = useLazyQuery(GET_NOTIFICATIONS, { fetchPolicy: "no-cache" });
    function getNotificationsFun(page, sizePerPage) {
        getNotifications({
            variables: {
                "argumentsInput": {
                    "page": page,
                    "limit": sizePerPage,
                }
            }
        })
    }

    React.useEffect(() => {
        getNotificationsFun(state.page, state.sizePerPage)
    }, []);


    React.useEffect(() => {
        if (response.data && response.data.getNotifications && response.data.getNotifications.status) {
            setIsSpinner(false)
            setNotifications(response.data.getNotifications.data.notifications)
            setTotalCount(response.data.getNotifications.data.totalCount)
            if (response.data.getNotifications.data.totalCount > 0) {
                setTotalPage(Math.ceil(response.data.getNotifications.data.totalCount / state.sizePerPage))
            }
        }
        else {
            setIsSpinner(true)
            // console.log(response.data);
            if (response.data && response.data.getNotifications) {
                setNotifications(response.data.getNotifications.data.notifications)
                setIsSpinner(false)
            }
        }

    }, [response.data]);
    //********************************************************************* */

    const handlePageClick = page => {
        setPage(page);
        getNotificationsFun(page, state.sizePerPage)
    };

    //********************************************************************* */

    const handleNotificationRedirectClick = async (notificationData) => {
        try {
            await submitReadNotification({
                variables: {
                    "notificationInput": {
                        "id": parseInt(notificationData?.id)
                    }
                }
            });
        } catch (e) {
            console.log(e)
        }
        // console.log("notificationData", notificationData);
        if (notificationData?.redirect_url) {
            history.push(`/${notificationData?.redirect_url}`);
        }
    };

    const [submitReadNotification, readNotificationResponse] = useMutation(READ_NOTIFICATION);
    React.useEffect(() => {
        if (readNotificationResponse?.data && readNotificationResponse?.data?.readNotification?.status) {
            // toast.success(readNotificationResponse?.data?.readNotification?.message)
            getNotificationsFun(state.page, state.sizePerPage)
        } else {
            if (readNotificationResponse?.data && readNotificationResponse?.data?.readNotification) {
                // toast.error(readNotificationResponse?.data?.readNotification?.message)
                getNotificationsFun(state.page, state.sizePerPage)
            }
        }
    }, [readNotificationResponse.data]);

    //********************************************************************* */

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Notifications" breadcrumbItem="Notifications List" />
                    <Row>
                        <Col lg="12" xl="12">
                            {(notifications && notifications.length > 0) ? "":
                                <Card>
                                    <CardBody>
                                        <div>
                                            <Row>
                                                <Col md="12">
                                                    <div className="d-flex align-items-center flex-column">
                                                        <i className="uil uil-bell text-primary fa-5x"></i>
                                                        {isSpinner === true ?
                                                            <>
                                                                <h4>Loading...</h4>
                                                            </> : <>
                                                                <h4>No Notifications Found</h4>
                                                                <p className="text-center">You have no any notifications.</p>
                                                            </>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            }
                            {notifications && notifications.map((item, index) =>
                                <a onClick={() => handleNotificationRedirectClick(item) } className="cp" key={index}>
                                    <Card>
                                        <CardBody>
                                            <div>
                                                <Row>
                                                    <Col md="10">
                                                        <div className="d-flex align-items-start">
                                                            <div className="avatar-xs me-3">
                                                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                                                    {/* <i className="uil-shopping-basket"></i> */}
                                                                    <img className="p-2" src={item?.icon_url} />
                                                                </span>
                                                            </div>
                                                            <div className="flex-1">
                                                                <h6 className={"mt-0 mb-1 " + (item?.is_read === true ? 'text-muted' : 'text-black')}>
                                                                    {item?.title}
                                                                </h6>
                                                                <div className={"font-size-12 " + (item?.is_read === true ? 'text-muted' : 'text-black')}>
                                                                    <p className="mb-1">
                                                                        {item?.message}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="form-inline float-md-end">
                                                            <div className="ml-2">
                                                                <div className="position-relative">
                                                                    <span className={(item?.is_read === true ? 'text-muted' : 'text-black')}>
                                                                        <i className="mdi mdi-clock-outline"></i>{" "}
                                                                        {item?.created_on}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/*
                                                <Row>
                                                    <Col md="7">
                                                        <h5>{item?.title}</h5>
                                                    </Col>

                                                    <Col md="5">
                                                        <div className="form-inline float-md-end">
                                                            <div className="ml-2">
                                                                <div className="position-relative">
                                                                    <span className="text-muted">
                                                                        <i className="mdi mdi-clock-outline"></i>{" "}
                                                                        {item?.created_on}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row> */}

                                                {/* <Row>
                                                    <Col md="12"> */}
                                                        {/* <h6>{item?.title}</h6> */}
                                                        {/* <p className="text-muted mb-0">{item?.message}</p> */}
                                                    {/* </Col> */}
                                                {/* </Row> */}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </a>
                            )}
                        </Col>
                    </Row>
                    {(notifications && notifications.length > 0) ?
                    <Row className="mt-4 align-items-center">
                        <Col sm="6">
                            <div className="mb-3">
                                <p className="mb-sm-0">Showing {(state.sizePerPage*(page-1))+1} to {(page === totalPage) ? totalCount : state.sizePerPage*page} of {totalCount} entries</p>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="float-sm-end">
                                <Pagination className="mb-sm-0">
                                    <PaginationItem disabled={page === 1}>
                                        <PaginationLink
                                            previous
                                            to="#"
                                            onClick={() => handlePageClick(page - 1)}
                                        />
                                    </PaginationItem>
                                    {map(Array(totalPage), (item, i) => (
                                        <PaginationItem active={i + 1 === page} key={"_pagination_" + i}>
                                            <PaginationLink
                                                onClick={() => handlePageClick(i + 1)}
                                                to="#"
                                            >
                                                {i + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    ))}
                                    <PaginationItem disabled={page === totalPage}>
                                        <PaginationLink
                                            next
                                            to="#"
                                            onClick={() => handlePageClick(page + 1)}
                                        />
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </Col>
                    </Row>
                    :""}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(NotificationList)
