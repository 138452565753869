import React from "react"
import {
  Card,
  Col,
  Row,
  ModalBody,
  Modal,
  ModalHeader,
  Spinner,
} from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentPlugin from "@fullcalendar/moment"
import { useLazyQuery } from "@apollo/client"
import { GET_APPOINTMENTS } from "./gql/Gql"
import { useEffect, useState } from "react"
import moment from "moment"
import style from "./style.module.scss"
import { Button } from "reactstrap"
import { makeStyles } from "@material-ui/core"
import { CalendarChip } from "./CalendarChip"
import AppointmentInfo from "./AppointmentInfo"
import { useSelector } from "react-redux"

const useStyles = makeStyles(theme => ({
  joinBtnText: {
    fontSize: "17px",
    fontWeight: 500,
  },

  // calendar

  calendarSpinner: {
    opacity: 1,
    position: "absolute",
    zIndex: 999,
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const PopupActionButton = ({ modal }) => {
  const classes = useStyles()
  const checkLiveAppointments = data => {
    let appointmentDate = moment(data.utc_datetime)
      .startOf("day")
      .local()
      .format("YYYY-MM-DD HH:mm:ssZ")
    let appointmentEndDate = moment(data.utc_datetime)
      .endOf("day")
      .local()
      .format("YYYY-MM-DD HH:mm:ssZ")
    let Today = moment()
    if (
      Today.startOf("day").isBefore(appointmentEndDate) &&
      Today.endOf("day").isAfter(appointmentDate)
    ) {
      return true
    } else {
      return false
    }
  }

  const joinVideoCall = () => {
    const win = window.open(
      `/appointments/join/${modal?.appointment_id}`,
      "_blank"
    )
    win.focus()
  }
  let endTiming = ""
  if (modal) {
    const utcDate = new Date(modal?.utc_datetime)

    const endTime = new Date(utcDate.getTime() + modal?.duration * 60000)

    const localEndString = endTime.toLocaleString("en-US", {
      timeZone: modal?.timezone,
    })

    function isCurrentTimeBefore(targetDateTimeString) {
      const targetDateTime = new Date(targetDateTimeString)

      const currentDateTime = new Date()

      return targetDateTime > currentDateTime
    }

    endTiming = isCurrentTimeBefore(localEndString)
  }
  if (modal == undefined) return null
  const isLive = checkLiveAppointments(modal)
  if (modal?.type_of_appointment?.call_type == "online video" && endTiming) {
    return (
      <Button
        color="primary"
        onClick={joinVideoCall}
        className={`btn btn-primary btn-rounded waves-effect waves-light ${style.typebutton}`}
        // disabled={!isLive}
      >
        <a className="white-color-calendar" href="#">
          <span className={classes.joinBtnText}>
            <i className="uil-video"></i> Join Video
          </span>
        </a>
      </Button>
    )
  }

  if (
    modal?.type_of_appointment?.call_type == "in person" &&
    modal?.type_of_appointment?.google_address
  ) {
    return (
      <Button
        color="#153c27"
        className={`btn btn-primary btn-rounded waves-effect waves-light ${style.typebutton}`}
      >
        <a
          className="white-color-calendar"
          href={`${modal.type_of_appointment.google_address}`}
          target="blank"
        >
          <span className={classes.joinBtnText}>
            <i className="uil-corner-up-right-alt"></i> Direction
          </span>
        </a>
      </Button>
    )
  }
}

function AppointmentCalendat(props) {
  const classes = useStyles()
  const userData = useSelector(state => state.Login?.user?.user)
  const [modal, setModal] = useState(false)
  const [calender, setCalender] = useState([])
  const [selectedFilter, setSelecteFilter] = useState({
    startDate: undefined,
    endDate: undefined,
    isdayOrMonth: "month",
  })
  const [getAppointmentList, listResponse] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: "no-cache",
  })

  const getEventBackgroundColor = (type, time, type_of_appointment) => {
    let color = "custom-secondary"
    const expiryDate = moment(time)
    const now = moment()
    return type
  }

  useEffect(() => {
    if (
      listResponse?.data &&
      listResponse?.data?.getAppointments &&
      listResponse?.data?.getAppointments.code == 200 &&
      listResponse?.data?.getAppointments.data
    ) {
      const appointments = listResponse.data.getAppointments.data.appointments_arr.filter((elem)=>{
        return !elem?.appointment?.canceled
      })
      const arr = []
      
        appointments?.forEach(item => {
          arr.push({
            id: item?.appointment?.id,
            title: item?.appointment?.first_name,
            start: moment
              .utc(item?.appointment.utc_datetime)
              .local()
              .format("YYYY-MM-DD HH:mm"),
            end: moment(item?.appointment.utc_datetime)
              .local()
              .add(item?.appointment.duration, "minutes")
              .format("YYYY-MM-DD HH:mm:ss"),
            backgroundColor: item?.appointment?.type_of_appointment?.color,
            appointmentName: item?.appointment?.type_of_appointment?.name,
            is_direct:item?.appointment?.payment_type?.is_direct
          })
        })
      setCalender(arr)
      setSpinner(false)
    } else {
      setSpinner(false)
    }
  }, [listResponse.data])

  const [spinner, setSpinner] = useState(false)

  useEffect(() => {
    if (props.counselors) {
      setSpinner(true)
    }
    if (
      !selectedFilter.endDate ||
      !selectedFilter.startDate ||
      !props.counselors
    )
      return
    setTimeout(() => {
      let variables = {
        variables: {
          argumentsInput: {
            page: 1,
            limit: 500,
            device_type:"web",
            start_date: moment(selectedFilter.startDate)
              .utc()
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            end_date: moment(selectedFilter.endDate)
              .utc()
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        },
      }
      if (userData.role.id !== 3) {
        variables.variables.argumentsInput.calendar_id = props.counselors
      }
      getAppointmentList(variables)
    }, 1000)
  }, [selectedFilter, props.counselors, modal])

  const loadingFunction = isLoading => {
    if (!isLoading) {
      setSpinner(false)
    }
  }

  const handleDateChange = payload => {
    setSelecteFilter(s => ({
      ...s,
      startDate: payload.startStr,
      endDate: payload.endStr,
      isdayOrMonth: payload.view.type == "dayGridMonth" ? "month" : "day",
    }))
  }

  const handleEventClick = arg => {
    const event = arg.event
    const appointments = listResponse?.data?.getAppointments?.data?.appointments_arr?.map((elem)=>{
      return elem?.appointment
    })
    const modalData = appointments.find(item => item.id == event.id)
    setModal(modalData)
  }

  return (
    <>
      <Row>
        <Col>
          <Card className="position-relative transparent-bg-card">
            {spinner && (
              <div className={classes.calendarSpinner}>
                <Spinner />
              </div>
            )}
            <div style={{ opacity: spinner ? 0.2 : 1 }}>
              <FullCalendar
                plugins={[
                  momentPlugin,
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                ]}
                headerToolbar={{
                  left: "today",
                  center: "prev title next",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                initialView="timeGridWeek"
                loading={loadingFunction}
                editable={false}
                selectable={false}
                selectMirror={false}
                dayMaxEvents={true}
                weekends={true}
                events={calender}
                datesSet={handleDateChange}
                eventClick={handleEventClick}
                eventContent={CalendarChip}
                eventColor="#153c27"
                eventTimeFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  meridiem: "high",
                }}
                views={{
                  timeGridWeek: {
                    dayHeaderFormat: {
                      day: "numeric",
                    },
                    dayHeaderContent: function (info) {
                      let s = ""
                      // get the day of the month (number)
                      const dayOfMonth = info.date.getDate()
                      // set the ordinal value of the date in s
                      if (dayOfMonth > 3 && dayOfMonth < 21) s = "th"
                      switch (dayOfMonth % 10) {
                        case 1:
                          s = ""
                          break
                        case 2:
                          s = ""
                          break
                        case 3:
                          s = ""
                          break
                        default:
                          s = ""
                      }
                      const locale = "en-US"
                      // get the name of the day (Friday, Saturday ...)
                      const dayName = info.date.toLocaleDateString(locale, {
                        weekday: "short",
                      })
                      return (
                        <div className="d-flex flex-column cursor-pointer-accordion">
                          <div>{dayName}</div>
                          <div className={info.isToday ? style.todaystyle : ""}>
                            {dayOfMonth}
                          </div>
                        </div>
                      )
                    },
                  },
                  timeGridDay: {
                    dayHeaderContent: function (info) {
                      const dayOfMonth = info.date.getDate()
                      const locale = "en-US"
                      const dayName = info.date.toLocaleDateString(locale, {
                        weekday: "short",
                      })
                      return (
                        <div className="d-flex flex-column">
                          <div className="cursor-pointer-accordion">
                            {dayName}
                          </div>
                          <div className={info.isToday ? style.todaystyle : ""}>
                            {dayOfMonth}
                          </div>
                        </div>
                      )
                    },
                  },
                }}
              />
            </div>

            <Modal isOpen={modal} size="lg" className={props.className}>
              <ModalHeader toggle={() => setModal(undefined)} tag="h4">
                Meeting Details
              </ModalHeader>
              <ModalBody className={style.modalstyle}>
                <AppointmentInfo id={parseInt(modal?.id)} isModel={modal} setModal={setModal} />
              </ModalBody>
              {userData.role.id !== 1 && !modal?.canceled && (
                <div className={style.Buttonstyle}>
                  <PopupActionButton modal={modal} />
                </div>
              )}
            </Modal>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AppointmentCalendat
