import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import classNames from "classnames"
import Table from "../../components/Common/Table"
import Switch from "react-switch"
import { CREATE_ORGANIZATION, GET_ORGANIZATION } from "./gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import moment from "moment"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const Organizationstatus = () => {
  const [totalCount, setTotalCount] = useState(20)

  const [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    search: "",
  })
  const [organizationsStatusList, setOrganizationsStatusList] = useState([])

  const [getOrganizations, getOrganizationsList] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [createOrganization, createOrganizationRes] =
    useMutation(CREATE_ORGANIZATION)

  const getOrganizationsFun = (limit, page, search) => {
    setLoading(true)
    getOrganizations({
      variables: {
        argumentsInput: {
          limit: limit || 20,
          page: page || 1,
          search: search || "",
        },
      },
    })
  }

  useEffect(() => {
    getOrganizationsFun()
  }, [])

  useEffect(() => {
    if (
      getOrganizationsList?.data?.getOrganizations?.data &&
      getOrganizationsList?.data?.getOrganizations?.data?.organizations
    ) {
      const orgList =
        getOrganizationsList?.data?.getOrganizations?.data?.organizations
      setOrganizationsStatusList(orgList)
      setLoading(false)
    }
  }, [getOrganizationsList.data])

  const dateFormat = (cell, row, rowIndex, formatExtraData) => {
    const dormatedDate = moment(row?.created_at).format("DD-MMM-yyyy")
    return dormatedDate
  }

  const actionEvent = (cell, row, rowIndex, formatExtraData) => {
    return (
      row?.id !== 1 ?  
      <div className="d-flex align-items-center">
        <Switch
          onChange={() => handleSwitchChange(row?.id)}
          checked={row?.is_active}
          onColor="#153c3c"
          onHandleColor="#2693e6"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          width={40}
          height={20}
        />
      </div>:<></>
    )
  }

  const columns = [
    {
      text: "ID",
      dataField: "id",
      style: {
        minWidth: 50,
      },
    },
    {
      text: "Name",
      dataField: "name",
      style: {
        minWidth: 130,
      },
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      text: "Created On",
      dataField: "created_at",
      style: {
        minWidth: 190,
      },
      headerStyle: {
        cursor: "pointer",
      },
      formatter: dateFormat,
    },
    {
      dataField: "is_active",
      text: "Action",
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
    },
  ]

  const handleSwitchChange = org => {
    let orgData = organizationsStatusList.find(elem => {
      return elem.id === org
    })

    orgData.is_active = !orgData.is_active

    const newObject = {
      name: orgData?.name,
      logo: orgData?.logo,
      id: orgData?.id,
      is_active: orgData?.is_active,
      primary_color: orgData?.primary_color,
      secondary_color: orgData?.secondary_color,
      font_color: orgData?.font_color,
      favicon: orgData?.favicon,
      domain: orgData?.domain,
      description: orgData?.description,
    }
    
    createOrganization({
      variables: {
        organizationInput: newObject,
      },
    })
  }

  const onChangeSearch = e => {
    const search = e.target.value
    setState(prevState => {
      return {
        ...prevState,
        search: search,
      }
    })
    getOrganizationsFun(state.sizePerPage, state.page, search)
  }

  useEffect(() => {
    if (
      createOrganizationRes?.data?.createOrUpdateOrganization?.status &&
      createOrganizationRes?.data?.createOrUpdateOrganization?.data
    ) {
      toast.success(
        createOrganizationRes?.data?.createOrUpdateOrganization?.message
      )
      getOrganizationsFun()
    } else {
      toast.error(
        createOrganizationRes?.data?.createOrUpdateOrganization?.message
      )
    }
  }, [createOrganizationRes.data])

  const loadingText = "No Data Found"

  const [loading, setLoading] = useState(false)
  const handleTableChange = () => {}

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <div className="pt-2">
              <h4 className="card-title-main">Organization Status</h4>
              <h5 className="card-para-main">
                List of all the Organizations Status.
              </h5>
            </div>
          </div>
          <Row className="mg-top-default-ui">
            <Col className="col-12 ">
              <Card className="light-green-bg-card-table">
                <CardBody>
                  <div>
                    <div className={classNames("d-flex gap-2 flex-wrap")}>
                      <Row className="w-100 flex-direction-column-mobile">
                        <Col className="search-input-search-appointments">
                          {" "}
                          <label className="w-100 search-label-appointments">
                            <input
                              className="form-control default-search-input w-25"
                              value={state.search}
                              type="text"
                              placeholder="Search"
                                onChange={onChangeSearch}
                            />
                          </label>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="table-custom-redesign">
                    <Table
                      data={organizationsStatusList}
                      page={state.page}
                      sizePerPage={state.sizePerPage}
                      totalSize={totalCount}
                      onTableChange={handleTableChange}
                      columns={columns}
                      noDataIndication={loadingText}
                      loading={loading}
                      isHover={false}
                      isStriped={false}
                      isBordereded={false}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Organizationstatus
