import PropTypes from "prop-types"
import Gleap from "gleap"
import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap"

// Redux
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser } from "../../store/actions"

import logoNew from "../../assets/images/nfcld.png"

import "../../assets/scss/login-styles.css"

import { useLazyQuery, useMutation } from "@apollo/client"


// Icons
import { MdEmail , MdCall , MdOutlineRefresh} from "react-icons/md";

import {
  SIGNIN_GQL,
  OTP_VERIFY,
  VARIFY_EMAIL,
  GET_USER_BY_MOBILE,
  SEND_EMAIL_OTP,
} from "./gql/loginGql"
import { SEND_OTP } from "./gql/loginGql"
import { setPermission } from "../../functions/getLoginData"
import PhoneNoField from "./PhoneNoField"

import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"

import Loader from "../../components/Common/Loader"
import moment from "moment"

const Login = props => {
  const dispatch = useDispatch()
  let history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)

  const storedData = localStorage.getItem("orgData")
  const customColor = storedData ? JSON.parse(storedData) : null

  const defaultColors = {
    "--background-color": "#f3f7f2",
    "--text-color-dark": "#3f4e50",
    "--text-dark": "#000000",
    "--text-light": "#ffffff",
    "--chart-text": "#878e94",
    "--text-color-inactive-links": "#172426",
    "--dark-green": "#153c27",
    "--btn-disabled": "#2e4338",
    "--green-color-light": "#84a17d",
    "--green-color-bg-card": "#e2ecdf",
    "--bs-body-font-size": "1rem",
    "--bs-nav-link-color": "var(--dark-green)",
    "--bs-nav-link-hover-color": "var(var(--green-color-light))",
    "--bs-btn-color": "#fff",
    "--bs-btn-bg": "#153c27",
    "--bs-btn-border-color": "#153c27",
    "--bs-btn-hover-color": "#fff",
    "--bs-btn-hover-bg": "#84a17d",
    "--bs-btn-hover-border-color": "#84a17d",
    "--bs-btn-focus-shadow-rgb": "95, 106, 187",
    "--bs-btn-active-color": "#fff",
    "--bs-btn-active-bg": "#153c27",
    "--bs-btn-active-border-color": "#153c27",
    "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
    "--bs-btn-disabled-color": "#fff",
    "--bs-btn-disabled-bg": "#2e4338",
    "--bs-btn-disabled-border-color": "#2e4338",
    "--bs-card-green-light": "#cddbc9",
  }

  const customColors = customColor?.primary_color
    ? {
        "--dark-green": customColor?.primary_color,
        "--text-color-dark": customColor?.font_color,
        "--text-light": customColor?.font_color,
        "--text-dark": customColor?.font_color,
        "--chart-text": customColor?.font_color,
        "--text-color-inactive-links": customColor?.font_color,
        "--dark-green": customColor?.primary_color,
        "--btn-disabled": customColor?.primary_color,
        "--green-color-light": customColor?.secondary_color,
        "--green-color-bg-card": customColor?.primary_color,
        "--bs-nav-link-color": customColor?.primary_color,
        "--bs-nav-link-hover-color": customColor?.secondary_color,
        "--bs-btn-color": "#fff",
        "--bs-btn-bg": customColor?.primary_color,
        "--bs-btn-border-color": customColor?.secondary_color,
        "--bs-btn-hover-color": "#fff",
        "--bs-btn-hover-bg": customColor?.secondary_color,
        "--bs-btn-hover-border-color": customColor?.primary_color,
        "--bs-btn-focus-shadow-rgb": "95, 106, 187",
        "--bs-btn-active-color": " #fff",
        "--bs-btn-active-bg": customColor?.primary_color,
        "--bs-btn-active-border-color": customColor?.primary_color,
        "--bs-btn-active-shadow": `inset 0 3px 5px ${customColor?.primary_color}`,
        "--bs-btn-disabled-color": "#fff",
        "--bs-btn-disabled-bg": customColor?.primary_color,
        "--bs-btn-disabled-border-color": customColor?.secondary_color,
        "--bs-card-green-light": customColor?.secondary_color,
      }
    : {}

  const allColors = { ...defaultColors, ...customColors }

  Object.entries(allColors).forEach(([property, value]) => {
    document.documentElement.style.setProperty(property, value)
  })

  let [init, setInit] = useState(false)
  let [isFirebaseApiCall, setIsFirebaseApiCall] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [isLoginApicall, setIsLoginApicall] = useState(true)
  const [isMobileLogin, setIsMobileLogin] = useState(true)
  const [errorMsg, setErrorMsg] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [phoneLoginLoad, setPhoneLoginLoad] = useState(false)

  const [currentComponent, setCurrentComponent] = useState("emailLogin") // Initially, show the EmailForm
  // const [currentComponent, setCurrentComponent] = useState("multipleAccounts") // Initially, show the EmailForm
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [isEmailRegistered, setIsEmailRegistered] = useState()

  const [getOtp, setGetOtp] = useState("")
  const [otpLoading, setOtpLoading] = useState(false)
  const [accLoading, setAccLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState("")
  const [multipleUser, setMultipleUser] = useState([])
  const [hidePhoneLogin, setHidePhoneLogin] = useState(false)
  const [phoneLoading, setPhoneLoading] = useState(false)
  const [organizationData, setOrganizationData] = useState([])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      phone: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Please enter your number"),
      password: Yup.string().required("Please enter your password"),
    }),
    onSubmit: values => {
      signInUserFun(values.email, values.password)
    },
  })

  const { error } = useSelector(state => ({ error: state.Login.error }))

  useEffect(() => {
    localStorage.removeItem("reset_password_email")
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })

  //***************************************************************** */

  useEffect(() => {
    if (customColor?.favicon) {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement("link")
        link.rel = "icon"
        document.getElementsByTagName("head")[0].appendChild(link)
      }
      link.href = customColor?.favicon
      document.title = customColor?.name
    }
  }, [customColor])

  const removeDefaultEmail = () => {
    localStorage.removeItem("notRegistered")
  }

  const [signIn, signInData] = useLazyQuery(SIGNIN_GQL, {
    fetchPolicy: "no-cache",
  })

  const [sendOTP, { loading, data }] = useMutation(SEND_OTP)
  const [sendEmailOTPForLogin, sendEmailOTPForLoginRes] =
    useMutation(SEND_EMAIL_OTP)

  const [GetUserByMobileNumber, GetUserByMobileNumberRes] = useLazyQuery(
    GET_USER_BY_MOBILE,
    {
      fetchPolicy: "no-cache",
    }
  )

  const handleSendOTP = () => {
    if (isEmailRegistered === false || isEmailRegistered === null) {
      setPhoneLoginLoad(true)
      sendEmailOTPForLogin({
        variables: {
          createUserInput: {
            email: email,
            mobile_number: phone,
          },
        },
      })
    } else {
      sendOTP({ variables: { createUserInput: { email } } })
    }
  }

  const handlePhone = () => {
    setPhoneLoading(true)
    if (phone.length > 11) {
      GetUserByMobileNumber({
        variables: {
          argumentsInput: {
            mobile_number: phone,
          },
        },
      })
    } else {
      setPhoneLoading(false)
      toast.error("Enter Valid Number")
    }
  }

  useEffect(() => {
    if (
      sendEmailOTPForLoginRes?.data?.sendEmailOtpForlogin &&
      sendEmailOTPForLoginRes?.data?.sendEmailOtpForlogin.status
    ) {
      toast.success(sendEmailOTPForLoginRes?.data?.sendEmailOtpForlogin.message)
      setCurrentComponent("otpRequested")
      setPhoneLoginLoad(false)
    } else {
      toast.success(sendEmailOTPForLoginRes?.data?.sendEmailOtpForlogin.message)
      setPhoneLoginLoad(false)
    }
  }, [sendEmailOTPForLoginRes.data])

  useEffect(() => {
    if (
      GetUserByMobileNumberRes?.data?.getUserByMobileNumber &&
      GetUserByMobileNumberRes?.data?.getUserByMobileNumber.data.user
    ) {
      toast.success(
        GetUserByMobileNumberRes?.data?.getUserByMobileNumber.message
      )

      if (
        GetUserByMobileNumberRes?.data?.getUserByMobileNumber?.data?.user?.email
      ) {
        setEmail(
          GetUserByMobileNumberRes?.data?.getUserByMobileNumber?.data?.user
            ?.email
        )
        setIsEmailRegistered(true)
      }
      setCurrentComponent("emailLogin")
      setHidePhoneLogin(true)
      setPhoneLoading(false)
    } else if (
      GetUserByMobileNumberRes?.data?.getUserByMobileNumber &&
      !GetUserByMobileNumberRes?.data?.getUserByMobileNumber.data.user
    ) {
      setHidePhoneLogin(false)
      toast.error(
        GetUserByMobileNumberRes?.data?.getUserByMobileNumber.message +
          ", Please register yourself"
      )
      setPhoneLoading(false)
      setTimeout(() => {
        history.push("/register")
      }, 2000)
    }
  }, [GetUserByMobileNumberRes.data])

  const [VarifyEmail, VarifyEmailData] = useMutation(VARIFY_EMAIL, {
    fetchPolicy: "no-cache",
  })

  let currentPath = window.location.host
  // let currentPath = "nfc.qa.nfcconnect.app/"
  const path = currentPath.split(".")[1] ? currentPath.split(".")[0] : null

  const storedId = localStorage.getItem("orgId")
  const orgId = storedId ? parseInt(atob(JSON.parse(storedId))) : null

  useEffect(() => {
    if (data?.sendEmailOtp?.data && data?.sendEmailOtp?.code === 200) {
      if (data?.sendEmailOtp?.data.is_registered) {
        // const multipleOrg = data?.sendEmailOtp?.data?.users[0]?.organizations
        // if (multipleOrg?.length > 1) {
        //   localStorage.setItem("multiOrg", JSON.stringify(multipleOrg))
        // }
        //   const orgData =
        //     data?.sendEmailOtp?.data?.users[0]?.organizations[0]?.organization
        // if (orgData.id !== 1) {
        //   localStorage.setItem("orgData", JSON.stringify(orgData))
        //   setOrganizationData(orgData)
        // } else {
        //   localStorage.removeItem("orgData")
        //   let link = document.querySelector("link[rel~='icon']")
        //   if (!link) {
        //     link = document.createElement("link")
        //     link.rel = "icon"
        //     document.getElementsByTagName("head")[0].appendChild(link)
        //   }
        //   link.href = "/favicon.png"
        //   document.title = "No Fear Counselling"
        // }
        const orgData = data?.sendEmailOtp?.data?.users[0]
        if (
          orgData?.role?.slug.toLowerCase() == "superadmin" &&
          path == "admin"
        ) {
          toast.success(data.sendEmailOtp.message)
          setCurrentComponent("otpRequested")
        } else if (
          orgData?.role?.slug.toLowerCase() != "superadmin" &&
          path != "admin"
        ) {
          toast.success(data.sendEmailOtp.message)
          setCurrentComponent("otpRequested")
        } else {
          toast.error("Not an existing user")
        }
      } else if (data?.sendEmailOtp?.data.is_registered === false) {
        if (path == "admin") {
          toast.error("Not an existing user")
        } else {
          toast.success("OTP has been sent to your email address")
          localStorage.setItem("notRegistered", JSON.stringify(email))
          setTimeout(() => {
            history.push("/register")
          }, 1000)
        }
      }
    } else if (data?.sendEmailOtp?.data && data?.sendEmailOtp?.code === 404) {
      toast.error(data.sendEmailOtp.message)
      setTimeout(() => {
        history.push("/register")
      }, 3000)
    } else if (data?.sendEmailOtp?.data && data?.sendEmailOtp?.code === 201) {
      toast.error(data.sendEmailOtp.message)
    } else if (!data?.sendEmailOtp?.data && data?.sendEmailOtp?.code === 400) {
      toast.error(data.sendEmailOtp.message)
    } else {
      toast.error(data?.sendEmailOtp?.message)
    }
  }, [data])

  function signInUserFun(username, password) {
    setInProgress(true)
    setIsLoginApicall(true)
    username = username.toLowerCase().replace(/\s/g, "")
    signIn({
      variables: {
        loginInput: {
          username: username,
          password: password,
        },
      },
    })
  }

  function verifyOtpFunc(email, otp) {
    VarifyEmail({
      variables: {
        createUserInput: {
          email: email,
          password: otp,
        },
      },
    })
  }

  const handleNextButtonClick = () => {
    if (getOtp.length === 6) {
      if (email && getOtp) {
        verifyOtpFunc(email, getOtp)
        setOtpLoading(true)
      } else {
        toast.error("Please Enter Otp")
      }
    } else {
      toast.error("Please Enter Proper Otp")
    }
  }

  const loginAccount = () => {
    const selectedAcc = multipleUser.find(item => item.id === selectedUser)
    localStorage.setItem("token", selectedAcc.token)
    localStorage.setItem("authUser", JSON.stringify(selectedAcc))
    Gleap.identify(selectedAcc.id, {
      name: selectedAcc.first_name,
      email: selectedAcc.email,
    })
    setAccLoading(true)
    setTimeout(() => {
      history.push("/dashboard")
      currentUrl.searchParams.delete("orgId")
    }, 2000)
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  React.useEffect(() => {
    if (
      VarifyEmailData.data &&
      VarifyEmailData?.data?.verifyEmailOtp &&
      VarifyEmailData?.data?.verifyEmailOtp.code == 200 &&
      VarifyEmailData?.data?.verifyEmailOtp.status == true
    ) {
      if (VarifyEmailData?.data?.verifyEmailOtp?.data?.users.length > 1) {
        const allUser = VarifyEmailData?.data?.verifyEmailOtp?.data?.users
        setMultipleUser(allUser)
        setOtpLoading(false)
        setCurrentComponent("multipleAccounts")
      } else {
        if(VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0]?.token){
          localStorage.setItem(
            "token",
            VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0]?.token
          )
          const moduleList =
            VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0]?.role
              ?.rolePermissions
          const slugArray = moduleList?.map(
            permission => permission?.method?.slug
          )
          if (slugArray) {
            localStorage.setItem("userPermission", JSON.stringify(slugArray))
          }
  
          localStorage.setItem(
            "authUser",
            JSON.stringify(VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0])
          )
  
          toast.success(VarifyEmailData?.data?.verifyEmailOtp.message)
          const userDetails =
            VarifyEmailData?.data?.verifyEmailOtp?.data?.users[0]
          Gleap.identify(userDetails.id, {
            name: userDetails.first_name,
            email: userDetails.email,
          })
          setOtpLoading(false)
          setTimeout(() => {
            history.push("/dashboard")
          }, 2000)
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }else{
          toast.error("oops! Something went wrong. Please try again")
          setTimeout(() => {
            history.push("/login")
          }, 2000)
        }      
        }
    } else {
      toast.error(VarifyEmailData?.data?.verifyEmailOtp.message)
      setOtpLoading(false)
    }
  }, [VarifyEmailData.data])

  React.useEffect(() => {
    setInProgress(false)
    if (
      signInData.data &&
      signInData.data.signIn &&
      signInData.data.signIn.code == 200 &&
      signInData.data.signIn.data
    ) {
      // user identification
      const userDetails = signInData.data.signIn.data.user
      // check user is inactive then display notification
      if (!userDetails.is_active) {
        // setModalDelete(true)
        localStorage.setItem(
          "onboard",
          JSON.stringify(signInData.data.signIn.data)
        )
        localStorage.setItem("token", signInData.data.signIn.data.token)
        history.push("/on-board")
        // toast.error("Kindly finish your sign up on mobile app")
      } else {
        Gleap.identify(userDetails.id, {
          name: userDetails.first_name,
          email: userDetails.email,
        })
        dispatch(
          loginUser(
            signInData.data.signIn.data,
            props.history,
            history.location.state?.from?.pathname
          )
        )
        setPermission(signInData.data.signIn.data.permission)
      }
      setIsLoginApicall(true)
    } else {
      if (signInData.data && signInData.data.signIn) {
        setErrorMsg(signInData.data.signIn.message)
      }
      setIsLoginApicall(false)
    }
  }, [signInData.data])
  const handleWithOtpButton = () => {
    history.push("/login")
    setIsMobileLogin(true)
  }

  const handleEmail = e => {
    const lowercaseEmail = e.target.value.toLowerCase().trim()
    validation.setFieldValue("email", lowercaseEmail)
    setEmail(lowercaseEmail)
  }

  const handlePhoneInput = value => {
    validation.setFieldValue("phone", value)
    setPhone(value)
  }

  const handleOtp = e => {
    const enteredOtp = e.target.value

    if (/^\d{0,6}$/.test(enteredOtp)) {
      setGetOtp(enteredOtp)
    }
  }

  //******************************************************************************************** */
  function OtpRequested() {
    const inputRefs = [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ]

    // State to store the concatenated OTP
    const [otp, setOtp] = useState("")

    // Function to handle input change and move focus to the next/previous input field
    const handleInputChange = (e, index) => {
      const inputValue = e.target.value

      // Use a regular expression to allow only numeric input
      const numericInput = inputValue.replace(/\D/g, "") // Remove non-numeric characters

      // Update the input field value
      e.target.value = numericInput

      // Concatenate the OTP as it's entered
      const updatedOtp = [...otp] // Convert OTP string to an array
      updatedOtp[index] = numericInput // Update the specific digit
      setOtp(updatedOtp.join("")) // Join the array and update the state

      // If the input value is not empty and there's a next input field, focus on it
      if (numericInput !== "" && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus()
      } else if (numericInput === "" && index > 0) {
        // If the input value is empty (backspace) and there's a previous input field, focus on it
        inputRefs[index - 1].current.focus()
      }
    }

    // Function to handle OTP submission (you can trigger this when needed)
    const handleSubmit = () => {
      console.log("Entered OTP:", otp)
      // Add your logic here for handling the OTP, e.g., sending it to a server
    }
    return (
      <div className="mb-2 form-div-login-page">
        <h3 className="enterOTPheading">Enter OTP</h3>
        <p className="para-desc-login">
          We’ve sent you a 6-digit one-time password on your email address.
        </p>

        <div className="d-flex align-items-center gap-devices-login-otp mt-4">
          {inputRefs.map((ref, index) => (
            <input
              key={index}
              name={`otp${index}`}
              ref={ref}
              className="form-control default-redesign-input text-center"
              inputMode="numeric"
              type="text"
              maxLength={1}
              onChange={e => handleInputChange(e, index)}
            />
          ))}
        </div>
        <Button
          className="btn btn-primary mt-3"
          onClick={handleSubmit}
          disabled={otp.length !== 6} // Enable the button when OTP is complete
        >
          Submit OTP
        </Button>
      </div>
    )
  }

  const handleUserSelect = userId => {
    setSelectedUser(userId)
  }

  useEffect(() => {
    if (userData) {
      return history.push("/dashboard")
    }
  }, [userData])

  return (
    <React.Fragment>
      <ToastContainer />
      {userData ? (
        <></>
      ) : (
        <div className="account-pages login-account-pages  auth">
          <Container>
            <Row className="height-100vh align-items-center justify-content-center w-100 margin-zero-small">
              <Col
                xl={6}
                lg={9}
                md={12}
                className="align-items-center justify-content-space-between"
              >
                {isFirebaseApiCall ? (
                  <Loader />
                ) : (
                  <Row className="align-items-center justify-content-center">
                    <Col md={12} lg={9} xl={9}>
                      <Card className="custom-card d-flex align-items-center p-4">
                        <CardBody className="p-2 w-75">
                          {isLoginApicall ? (
                            <div className="loader-overlay">
                              <div>
                                <Loader />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="text-center">
                            {customColor?.logo && (
                              <img
                                src={customColor?.logo}
                                alt="Logo"
                                className="logo-center"
                              />
                            )}
                            {orgId === 1 && (
                              <img
                                src={logoNew}
                                alt="Logo"
                                className="logo-center"
                              />
                            )}
                          </div>

                          <div className="mt-4 margin-top-login-page">
                            <Form
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              {errorMsg ? (
                                <Alert color="danger">{errorMsg}</Alert>
                              ) : null}

                              {currentComponent === "phoneLogin" ? (
                                <div className="mb-2 form-div-login-page">
                                  <Label className="form-label form-label-login">
                                    Phone Number
                                  </Label>

                                  <PhoneNoField
                                    className="phoneinpute"
                                    name="phone"
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone}
                                    type="text"
                                    onChange={value => {
                                      if (!value.startsWith("+")) {
                                        value = "+" + value
                                      }
                                      handlePhoneInput(value)
                                    }}
                                    invalid={
                                      validation.touched.phone &&
                                      validation.errors.phone
                                        ? true
                                        : false
                                    }
                                  />

                                  

                                  {validation.touched.phone &&
                                  validation.errors.phone ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.phone}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              ) : null}


                              {currentComponent === "emailLogin" ? (
                                <div className="mb-2 form-div-login-page">
                                  <Label className="form-label form-label-login">
                                    Email address
                                  </Label>
                                  <Input
                                    name="email"
                                    className="form-control default-redesign-input"
                                    placeholder="Enter your email address"
                                    type="text"
                                    onChange={e => handleEmail(e)}
                                    value={email}
                                    disabled={isEmailRegistered}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.email &&
                                      validation.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  
                                  {validation.touched.email &&
                                  validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.email}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              ) : null}
                              {currentComponent === "otpRequested" ? (
                                <>
                                  <Label className="form-label form-label-login">
                                    Enter your OTP
                                  </Label>
                                  <Input
                                    name="getOtp"
                                    className="form-control default-redesign-input mb-2"
                                    placeholder="Enter your OTP"
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    onChange={e => handleOtp(e)}
                                    onBlur={validation.handleBlur}
                                    value={getOtp}
                                    autoFocus={true}
                                    invalid={
                                      validation.touched.getOtp &&
                                      validation.errors.getOtp
                                        ? true
                                        : false
                                    }
                                  />
                                </>
                              ) : null}
                              {currentComponent === "multipleAccounts" ? (
                                <>
                                  <div className="mb-2 form-div-login-page">
                                    <h3 className="enterOTPheading">
                                      There are multiples account linked with
                                      this e-mail address
                                    </h3>
                                    <p className="para-desc-login">
                                      Please choose an account to continue
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-center flex-column mt-4">
                                    {multipleUser.map(user => {
                                      return (
                                        <div
                                          className="card-profile-selection d-flex gap-2 d-flex align-items-center  mb-2"
                                          key={user.id}
                                        >
                                          <input
                                            type="radio"
                                            className="form-check-input checkbox"
                                            id="checkbox_1"
                                            name={user.id}
                                            checked={selectedUser === user.id}
                                            onClick={() => {
                                              handleUserSelect(user.id)
                                            }}
                                          />
                                          <div className="profile-content-login">
                                            <h4>
                                              {user.first_name} {user.last_name}
                                            </h4>
                                            <p><span className="font-weight-bold">{user.role.role_name} - </span><small>{moment(user?.created_at).format("DD-MMM-yyyy")}</small>
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </>
                              ) : null}

                              {!inProgress ? (
                                <>                               
                                  <div className=" mb-2 d-flex align-items-stretch  gap-1">
                                    {currentComponent === "phoneLogin" && (
                                      <button
                                        className="button-default-login-page btn btn-primary w-100 waves-effect waves-light mt-3 "
                                        onClick={handlePhone}
                                      >
                                        {currentComponent === "phoneLogin" &&
                                        phoneLoading
                                          ? "Processing..."
                                          : "Next" }
                                      </button>
                                    )}
                                    
                                    {currentComponent === "emailLogin" && (
                                      <button
                                        className=" btn btn-primary w-100 waves-effect waves-light mt-3"
                                        onClick={handleSendOTP}
                                        disabled={loading}
                                      >
                                        {(currentComponent === "emailLogin" &&
                                          loading) ||
                                        phoneLoginLoad
                                          ? "Processing..."
                                          : "Next"}
                                      </button>
                                    )}
                                    {currentComponent === "otpRequested" && (
                                      <button
                                        className="btn btn-primary w-100 waves-effect waves-light mt-3"
                                        onClick={handleNextButtonClick}
                                        disabled={otpLoading}
                                      >
                                        {currentComponent === "otpRequested" &&
                                        otpLoading
                                          ? "Processing..."
                                          : "Next"}
                                      </button>
                                    )}
                                    {currentComponent ===
                                      "multipleAccounts" && (
                                      <button
                                        className="button-default-login-page btn btn-primary w-100 waves-effect waves-light "
                                        type="submit"
                                        onClick={loginAccount}
                                        disabled={loading}
                                      >
                                        {currentComponent ===
                                          "multipleAccounts" && accLoading
                                          ? "Processing..."
                                          : "Login"}
                                      </button>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div className="my-4">
                                  <Button
                                    className="mt-4 button-default-login-page btn btn-primary w-100 waves-effect waves-light"
                                    type="submit"
                                    disabled
                                  >
                                    <Spinner
                                      type="grow"
                                      className="me-1 align-middle spinner-grow-sm "
                                      color="light"
                                    />
                                    Processing...
                                  </Button>
                                </div>
                              )}
                              {currentComponent === "emailLogin" &&
                                      !hidePhoneLogin ? (
                                        <p
                                          className="bottom-cta-register-account text-right mb-2"
                                          onClick={() => {
                                            setCurrentComponent("phoneLogin")
                                          }}
                                        >
                                          <Link>Login with phone <MdCall /></Link>
                                        </p>
                                      ) : null}
                                      <div>
                                    {currentComponent === "phoneLogin" ? (
                                      <p
                                        className="bottom-cta-register-account text-right mt-4"
                                        onClick={() => {
                                          setCurrentComponent("emailLogin")
                                        }}
                                      >
                                        <Link>Login with email <MdEmail /></Link>
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="mb-2">
                                    {currentComponent === "otpRequested" ? (
                                      <p
                                        className="bottom-cta-register-account "
                                        onClick={() => {
                                          handleSendOTP()
                                        }}
                                      >
                                        
                                        {loading ? (
                                          "Sending OTP..."
                                        ) : (
                                          <Link>Request a new OTP <MdOutlineRefresh /></Link>
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                              {/* {path != "admin" && (
                                <div className="mb-3 mt-4 form-div-login-page">
                                  <p
                                    className="text-center create-account-link"
                                    onClick={removeDefaultEmail}
                                  >
                                    Need an account?{" "}
                                    <Link to="/register">
                                      Create a new account
                                    </Link>
                                  </p>
                                </div>
                              )} */}
                              
                            </Form>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
