import { Link } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import style from "../Counselor/style.module.scss"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Tooltip from "@material-ui/core/Tooltip"
import {
  BreadcrumbItem,
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { useParams } from "react-router-dom"
import { useFormik } from "formik"
import Loader from "../../components/Common/Loader"
import { FocusError } from "focus-formik-error"
import * as Yup from "yup"
import Dropzone from "react-dropzone"
import Soinner from "../../assets/images/Loader.svg"
import { CREATE_ORGANIZATION, GET_ORG } from "./gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useHistory } from "react-router-dom"
import PhoneNoField from "../Authentication/PhoneNoField"

const CreateOrganization = () => {
  let { id } = useParams()
  let history = useHistory()

  const [isSpinner, setIsSpinner] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  let [initialValues, setInitialValues] = useState({
    name: "",
    is_active: true,
    description: "",
    logo: "",
    helpline_number:"",
    favicon:"",
    sub_domain:"",
    primary_color: "#000000",
    secondary_color: "#000000",
    font_color: "#000000",
    domain: "",
  })

  const [createOrganization, createOrganizationRes] =
    useMutation(CREATE_ORGANIZATION)

  const [getOrganizations, getOrganizationsRes] = useLazyQuery(GET_ORG, {
    fetchPolicy: "no-cache",
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter Organization name"),
      primary_color: Yup.string().required("Please select primary color"),
      secondary_color: Yup.string().required("Please select secondary color"),
      font_color: Yup.string().required("Please select font color"),
      sub_domain: Yup.string().required("Please enter sub domain"),
    }),
    onSubmit: values => {
      const reserved_domains = ["www", "api", "qa", "accounts", "help"];
      const isDomainAllowed = (domain) => {
        const domainParts = domain.split('.');
        for (const part of domainParts) {
          if (reserved_domains.includes(part.toLowerCase())) {
            return false;
          }
        }
        return true; 
      };
      if (values.logo && values.favicon && isDomainAllowed(values.sub_domain) && values.helpline_number) {
        setIsSubmitted(true)
        handleSubmit(values)
      } else {
        setIsSubmitted(false)
        if(!values.logo){
          toast.error("Please add organization Logo")
        }
        if(!values.favicon){
          toast.error("Please add organization Favicon")
        }
        if(!isDomainAllowed(values.sub_domain)){
          toast.error("Please enter valid Domain")
        }
        if(!values.helpline_number){
          toast.error("Please enter helpline number")
        }
       
      }
    },
  })
  const handleSubmit = data => {
    const orgData = {
      ...data,
      logo: data.logo.url,
      favicon: data.favicon.url
    }
    if(id){
      orgData["uuid"]= id
      orgData["is_active"]= undefined
      setIsSubmitted(true)
      createOrganization({
        variables: {
          organizationInput: orgData,
        },
      })    
    }else{
      setIsSubmitted(true)
        createOrganization({
          variables: {
           organizationInput: orgData,
      },
    })
    }  
  }

  useEffect(() => {
    if (
      createOrganizationRes?.data?.createOrUpdateOrganization?.status &&
      createOrganizationRes?.data?.createOrUpdateOrganization?.data
    ) {
      toast.success(
        createOrganizationRes?.data?.createOrUpdateOrganization?.message
      )
      setTimeout(() => {
        if(id){
          setIsSubmitted(false)
        }else{
          history.goBack()
        }
      }, 2000)
    } else {
      toast.error(
        createOrganizationRes?.data?.createOrUpdateOrganization?.message
      )
    }
  }, [createOrganizationRes.data])

  const handleImg = async (acceptedFiles, field) => {
    let maxFileSize;
    let supportedFormats;
  
    if (field === 'logo') {
      maxFileSize = 5 * 1024 * 1000; // 5 MB for logo
      supportedFormats = ["image/jpeg", "image/png"];
    } else if (field === 'favicon') {
      maxFileSize = 1 * 1024 * 1024; // 1 MB for favicon
      supportedFormats = ["image/jpeg", "image/png"];
    } else {
      maxFileSize = 0;
      supportedFormats = [];
    }
  
    if (acceptedFiles[0].size > maxFileSize) {
      toast.error(`Max File Upload Size is Upto ${maxFileSize / (1024 * 1024)} MB, try with smaller size`);
      return;
    }
  
    if (supportedFormats.indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png");
      return;
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }
    validation.setFieldValue(field, preview)

    let payload = new FormData()
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined

    payload.set("file", acceptedFiles[0])
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        {
          method: "POST",
          body: payload,
          headers: {
      organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

          },
        }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        validation.setFieldValue(field, preview)
      } else {
        validation.setFieldValue(field, undefined)
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      validation.setFieldValue(field, undefined)
      toast.error("Failed to upload cover image")
    }
  }
  const handleDomain = (e) =>{
    const domainName = e.target.value.toLowerCase().trim().replace(/\s/g, "");
    validation.setFieldValue("sub_domain", domainName)
    generateDomain(domainName)
  }

  useEffect(() => {
    if (id) {
      setIsSpinner(true)
      getOrganizations({
        variables: {
          organizationInput: {
            uuid: id,
          },
        },
      })
    }
  }, [id])

  const handlePhoneInput = (val) => {
    validation.setFieldValue("helpline_number",val)
  } 
  useEffect(() => {
    if (getOrganizationsRes?.data?.getOrganization?.data?.organization) {
      setIsSpinner(false)
      const data = getOrganizationsRes?.data?.getOrganization?.data?.organization
      setInitialValues({
        name: data.name,
        id:data.id,
        is_active: data.is_active,
        description: data.description,
        logo:  data.logo
        ? {
            state: "uploaded",
            url: data.logo,
          }
        : undefined,
        favicon:  data?.favicon
        ? {
            state: "uploaded",
            url: data?.favicon,
          }
        : undefined,
        primary_color: data.primary_color,
        secondary_color: data.secondary_color,
        font_color: data.font_color,
        domain: data.domain,
        sub_domain: data.sub_domain,
        helpline_number:data.helpline_number,
      })
      generateDomain(data.sub_domain)
    }
  }, [getOrganizationsRes.data])

  let timeoutId;
  const [autoGeneratedUrl, setAutoGeneratedUrl] = useState(`https://${process.env.REACT_APP_REDIRECT_URL}/`)
  function generateDomain(input) {
  clearTimeout(timeoutId);
  
  timeoutId = setTimeout(() => {
    const generatedDomain = `https://${input}${process.env.REACT_APP_REDIRECT_URL}/`;
    setAutoGeneratedUrl(generatedDomain)
    validation.setFieldValue("domain", generatedDomain)

  }, 500);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    {id ? "Edit Organizations" : "Add Organizations"}
                  </h4>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/organizations">Organizations</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">
                        {id ? "Edit Organizations" : "Add Organizations"}
                      </Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12 user-role-create-height position-relative">
            {isSpinner ? (
            <Row>
              <Col className="col-12 user-role-create-height">
                <div className="spinner">
                  <Loader />
                </div>
              </Col>
            </Row>
          ) : (
              <Form
                className="needs-validation"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <FocusError formik={validation} />
                {/* *****************  Organization details  *****************/}
                <Row className={style.cardCenter}>
                  <Col lg={2}></Col>
                  <Col lg={10}>
                    <Row>
                      <Col xl="10">
                        <h4 className="card-title-main mb-4">
                          Organization Details
                        </h4>
                        <p></p>

                        <Row className="form-custom-white-inputs">
                        <Row className="d-flex flex-column">
                          <Col xl={6} lg={12} md={12} className="mb-2">
                            <Label className="form-label">Logo </Label> <span className="logoText">(Aspect ratio to upload this image is 25:7)</span>
                            <Dropzone
                              multiple={false}
                              onDrop={file => handleImg(file, "logo")}
                              onChange={validation.handleChange}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="orgDropzone">
                                  <input {...getInputProps()} />

                                  {!validation?.values?.logo ? (
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <div className="">
                                        <i className="display-4 uil uil-cloud-upload" />
                                      </div>
                                      <h5>Drop JPEG, JPG or PNG.</h5>
                                      <h6>
                                        It can be square, landscape or portrait.
                                      
                                      </h6>
                                      <h6>  Upto 5MB file size.</h6>
                                    </div>
                                  ) : (
                                    <>
                                      {validation?.values?.logo?.state ===
                                        "uploading" && (
                                        <img
                                          className="needsclick rounded bg-light w-100 h-auto"
                                          alt="primary"
                                          src={Soinner}
                                        />
                                      )}
                                      {validation?.values?.logo?.state ===
                                        "uploaded" && (
                                        <div>
                                          <img
                                            {...getRootProps()}
                                            className="needsclick rounded bg-light w-100"
                                            alt="primary"
                                            src={validation?.values?.logo?.url}
                                          />
                                          <button
                                            onClick={() => {
                                              validation.setFieldValue(
                                                "logo",
                                                undefined
                                              )
                                            }}
                                            className={`${style.removeButton} mdi mdi-close`}
                                            type="button"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </Col>
                          <Col xl={6} lg={12} md={12} className="mb-2">
                            <Label className="form-label">Favicon</Label>
                            <Dropzone
                              multiple={false}
                              onDrop={file => handleImg(file, "favicon")}
                              onChange={validation.handleChange}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="orgDropzone">
                                  <input {...getInputProps()} />

                                  {!validation?.values?.favicon ? (
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <div className="">
                                        <i className="display-4 uil uil-cloud-upload" />
                                      </div>
                                      <h5>Drop JPEG, JPG or PNG.</h5>
                                      <h6>
                                        It can be square, landscape or portrait.
                                      
                                      </h6>
                                      <h6>  Upto 1MB file size.</h6>
                                    </div>
                                  ) : (
                                    <>
                                      {validation?.values?.favicon?.state ===
                                        "uploading" && (
                                        <img
                                          className="needsclick rounded bg-light w-100 h-auto"
                                          alt="primary"
                                          src={Soinner}
                                        />
                                      )}
                                      {validation?.values?.favicon?.state ===
                                        "uploaded" && (
                                        <div>
                                          <img
                                            {...getRootProps()}
                                            className="needsclick rounded bg-light w-100"
                                            alt="primary"
                                            src={validation?.values?.favicon?.url}
                                          />
                                          <button
                                            onClick={() => {
                                              validation.setFieldValue(
                                                "favicon",
                                                undefined
                                              )
                                            }}
                                            className={`${style.removeButton} mdi mdi-close`}
                                            type="button"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </Col>
                        </Row>
                          <Col md="12">
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                id="name"
                                name="name"
                                className="form-control"
                                placeholder="Enter your organization name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                                
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="mb-3">
                              <Label className="form-label">Sub Domain</Label>
                              
                              <div className="input-group mb-3">
                              <span className="input-group-text text-dark font-weight-bold">https://</span>
                                <Input
                                id="sub_domain"
                                name="sub_domain"
                                className="form-control rounded-1 domainInput"
                                placeholder="Enter your sub domain"
                                type="text"
                                disabled={Boolean(id)}
                                onChange={(e)=>handleDomain(e)}
                                onBlur={validation.handleBlur}
                                value={validation.values.sub_domain || ""}
                                invalid={
                                  validation.touched.sub_domain &&
                                  validation.errors.sub_domain
                                    ? true
                                    : false
                                }
                              />
                            <span className="input-group-text text-dark font-weight-bold">{`${process.env.REACT_APP_REDIRECT_URL}`}</span>
                              </div>
                              {validation.touched.sub_domain &&
                              validation.errors.sub_domain ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sub_domain}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {/* <Col md="6">
                            <div className="mb-3">
                              <Label className="form-label">Domain URL</Label>
                              <Input
                                id="domain"
                                name="domain"
                                className="form-control"
                                placeholder="Enter your domain"
                                type="text"
                                disabled={true}
                                value={autoGeneratedUrl}
                              />
                          
                            </div>
                          </Col> */}
                          <Col md="12">
                          <div className="mb-3">
                              <Label className="form-label">Helpline number <Tooltip
                                   className={style.tooltip}
                                    placement="right"
                            title={
                                          <>
                                          This helpline number will be added to the organization email footer.
                           </>
                                 }
                            arrow
                              >
                            <i
                               className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
                              ></i>
                      </Tooltip></Label>
                              
                        <PhoneNoField
                                    className="phoneinpute"
                                    name="helpline_number"
                                    onBlur={validation.handleBlur}
                                    value={validation?.values?.helpline_number}
                                    type="text"
                                    onChange={value => {
                                      if (!value.startsWith("+")) {
                                        value = "+" + value
                                      }
                                      handlePhoneInput(value)
                                    }}
                                  />
                              </div>
                                  </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="form-label">
                                Primary Color
                              </Label>
                              <Input
                                id="primary_color"
                                name="primary_color"
                                className="form-control"
                                type="color"
                                onChange={validation.handleChange}
                                value={validation.values.primary_color || ""}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.primary_color &&
                                  validation.errors.primary_color
                                    ? true
                                    : false
                                }
                                
                              />
                              {validation.touched.primary_color &&
                              validation.errors.primary_color ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.primary_color}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="form-label">
                                Secondary Color
                              </Label>
                              <Input
                                id="secondary_color"
                                name="secondary_color"
                                className="form-control"
                                type="color"
                                onChange={validation.handleChange}
                                value={validation.values.secondary_color || ""}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.secondary_color &&
                                  validation.errors.secondary_color
                                    ? true
                                    : false
                                }
                                
                              />
                              {validation.touched.secondary_color &&
                              validation.errors.secondary_color ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.secondary_color}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="form-label">Font Color</Label>
                              <Input
                                id="font_color"
                                name="font_color"
                                className="form-control"
                                type="color"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.font_color || ""}
                                invalid={
                                  validation.touched.font_color &&
                                  validation.errors.font_color
                                    ? true
                                    : false
                                }
                                
                              />
                              {validation.touched.font_color &&
                              validation.errors.font_color ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.font_color}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col md="12">
                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <textarea
                                id="description"
                                name="description"
                                className="form-control"
                                type="color"
                                rows="5"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className="flex-wrap gap-2 text-end mb-3">
                          {!inProgress ? (
                            <button
                              className="btn btn-primary w-sm waves-effect waves-light"
                              type="submit"
                              disabled={isSubmitted}
                            >
                              {id ? "Update" : "Submit"}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary w-sm waves-effect waves-light"
                              disabled
                            >
                              <Spinner
                                type="grow"
                                className="me-1 align-middle spinner-grow-sm "
                                color="light"
                              />
                              Processing...
                            </button>
                          )}
                          &nbsp; &nbsp; &nbsp;
                          <Button
                            type="button"
                            color="secondary"
                            className="btn-inactive btn  w-sm waves-effect waves-light"
                            disabled={isSubmitted}
                            onClick={() => {
                              history.goBack()
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>)}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateOrganization
