import React, { useState, useEffect } from "react"
import { Container, Row, Col, CardBody, Card, CardTitle } from "reactstrap"
import DatePicker from "react-datepicker"

//Import Components
import MiniWidget from "./mini-widget"
import TopUser from "./topuser"
import AnalyticsChart from "./analytics-chart"
import SessionStatistic from "./session-statistic"
import DetailsWidget from "./details-widget"
import useStyles from "../styles"
import BarChart from "./bar-chart"
import LeastUser from "./leastuser"
import GoogleMap from "./google-map"
import CustomSelect from "../../../components/CustomSelect"
import moment from "moment"
import classNames from "classnames"
import { useLazyQuery } from "@apollo/client"
import {
  GET_DASHBOARD_ANALYTICS,
  GET_LEAST_COUNSELLOR_DATA,
  GET_TOP_COUNSELLOR_DATA,
} from "./Gql"
import Loader from "../../../components/Common/Loader"
import BillableClientRegister from "./BillableClientRegister"
import { convertLocaltoUTC } from "../../../functions/functions"
import Billable from "./billable"
import Tooltip from "@material-ui/core/Tooltip"
import style from "./style.module.scss"
import { LINKED_ACCOUNT_STATUS } from "./gql/dashboardAdminGql"
import { Link } from "@material-ui/core"
import { useHistory } from "react-router-dom"

const Dashboard = () => {
  const classes = useStyles()
  let history = useHistory()
  const [endDate, setEndDate] = useState("")
  const [startDate, setstartDate] = useState("")
  const [value, setValue] = useState(2)
  const [dashboardData, setDashboardData] = useState([])
  const [isLoader, setIsLoader] = useState(true)
  const [accountStatusData, setAccountStatusData] = useState(false)
  const [accountDetailsStatus, setAccountDetailsStatus] = useState(true)
  const [filterDate, setFilterDate] = useState({
    start_date: null,
    end_date: null,
  })
  const target = window.document.getElementById(
    window.location.hash?.replace("#", "")
  )

  const data = [
    {
      id: 2,
      name: "Monthly",
      value: moment(endDate).startOf("month").toDate(),
    },
    {
      id: 3,
      name: "Yearly",
      value: moment(endDate).startOf("year").toDate(),
    },
  ]

  const onDateChange = data => {
    setValue(data.id)
    setstartDate("")
    setstartDate("")
  }

  const handleDateClick = () => {
    setIsLoader(true)
    getDashboardList()
  }

  const [getDashboardAnalytics, dashboardAnalyticsResponse] = useLazyQuery(
    GET_DASHBOARD_ANALYTICS,
    { fetchPolicy: "no-cache" }
  )

  const getDashboardList = () => {
    getDashboardAnalytics({
      variables: {
        dashboardGraph: filterDate,
      },
    })
  }

  React.useEffect(() => {
    getDashboardList()
  }, [])

  React.useEffect(() => {
    if (target) {
      let headerOffset = 20
      let elementPosition = target.getBoundingClientRect().top
      let offsetPosition = elementPosition - headerOffset

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      })
      history.replaceState(null, null, " ")
    }
  }, [target])

  React.useEffect(() => {
    if (
      dashboardAnalyticsResponse &&
      dashboardAnalyticsResponse.data &&
      dashboardAnalyticsResponse.data.dashboardAnalytics.code == 200
    ) {
      const data = dashboardAnalyticsResponse?.data?.dashboardAnalytics?.data
      setDashboardData(data !== null ? data : [])
      setIsLoader(false)
    }
  }, [dashboardAnalyticsResponse.data])

  const callMainApi = status => {
    getDashboardList()
    setIsLoader(status)
  }

  useEffect(() => {
    if (value == 2 && startDate) {
      setFilterDate({
        start_date: convertLocaltoUTC(
          moment(startDate).startOf("month").format("YYYY-MM-DD")
        ),
        end_date: convertLocaltoUTC(
          moment(startDate).endOf("month").format("YYYY-MM-DD")
        ),
        month: parseInt(moment(startDate).endOf("month").format("MM")),
        year: moment(startDate).endOf("month").format("YYYY"),
      })
    } else if (value == 3 && endDate) {
      setFilterDate({
        start_date: convertLocaltoUTC(
          moment(endDate).startOf("year").format("YYYY-MM-DD")
        ),
        end_date: convertLocaltoUTC(
          moment(endDate).endOf("year").format("YYYY-MM-DD")
        ),
        month: null,
        year: moment(endDate).endOf("month").format("YYYY"),
      })
    }
  }, [startDate, endDate])

  const encodedAccountData = localStorage.getItem("accountData")
  const accountStatus = encodedAccountData
    ? JSON.parse(atob(encodedAccountData))
    : null

  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountStatusData(allData)
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])

  function handleAccountLink() {
    history.push(`/linkAccount`)
  }
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null

  return (
    <>
      {isLoader && (
        <div className="counselor-dashboard-div-abs">
          <div className="counselor-dashboard-div-fixed ">
            <Loader />
          </div>
        </div>
      )}
      <Row>
        <Col sm={12} md={3} lg={3} xl={6} className="align-self-center">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h2 className={`${classes.headerDetails} card-title-main`}>
              Dashboard
            </h2>
          </div>
        </Col>
      </Row>

      {!accountStatus && (
        <Row>
          <Col sm={12} md={12} lg={12} xl={12} className="align-self-center">
            <div className="acoountWaring p-2">
              <p className="h5 p-0 m-0">
                <Tooltip
                  className={style.tooltip}
                  placement="right"
                  title={
                    <div className="invoice-list-fsize-14 ">
                      This process can be done by following steps. Click on
                      profile menu &gt; Account details
                    </div>
                  }
                  arrow
                >
                  <i className="fas fa-exclamation-circle text-danger cursor-pointer mx-2"></i>
                </Tooltip>
                Payment account details have not been provided yet, Kindly provide your payment details. <Link to="#" className="cursor-pointer" onClick={handleAccountLink}>Click here</Link>
              </p>
            </div>
          </Col>
        </Row>
      )}
      {accountDetailsStatus ? (
        <></>
      ) : (
        <Row>
          <Col sm={12} md={12} lg={12} xl={12} className="align-self-center mt-3">
            <div className="acoountWaring p-2">
              <p className="h5 p-0 m-0">
                <Tooltip
                  className={style.tooltip}
                  placement="right"
                  title={
                    <div className="invoice-list-fsize-14 ">
                      This process can be done by following steps. Click on
                      profile menu &gt; Account details
                    </div>
                  }
                  arrow
                >
                  <i className="fas fa-exclamation-circle text-danger cursor-pointer mx-2"></i>
                </Tooltip>
                You have not entered payment details properly, <Link to="#" className="cursor-pointer" onClick={handleAccountLink}> Click here</Link> to proceed
              </p>
            </div>
          </Col>
        </Row>
      )}

      <Row className="mg-top-default-ui">
        <Col xl={12} lg={12}>
          <AnalyticsChart setIsLoader={setIsLoader} />
        </Col>
        <Col xl={12} lg={12}>
          <Billable setIsLoader={setIsLoader} />
        </Col>
        <Col xl={12}>
          <BillableClientRegister setIsLoader={setIsLoader} />
        </Col>
      </Row>

      <Row className="align-center">
        <Col sm={12} md={3} lg={3} xl={6}>
          <div className="page-title-box d-flex align-items-center">
            <h4 className={`${classes.headerDetails} basic-card-title`}>
              Client Details
            </h4>
          </div>
        </Col>
        <Col sm={12} md={9} lg={9} xl={6}>
          <div
            className={classNames(
              "page-title-box gap-2",
              classes.filterContainer
            )}
          >
            <div className={classes.selectFilter}>
              <CustomSelect
                className="input default-redesign-input pointer"
                onChange={value => onDateChange(value)}
                placeholder="Select..."
                value={value}
                options={data}
              />
            </div>
            <div
              className={classNames(
                "d-flex align-items-stretch custom-date-picker-style",
                classes.datePickerFilter
              )}
            >
              {value == 2 ? (
                <DatePicker
                  selected={startDate}
                  className="form-control default-redesign-input pointer"
                  onChange={value => setstartDate(value)}
                  dateFormat="MMM-yyyy"
                  placeholderText="MMM-YYYY"
                  showMonthYearPicker={value == 2 ? true : false}
                  customInput={
                    <input
                      type="text"
                      id="validationCustom01"
                      placeholder="Select Date"
                      className=""
                    />
                  }
                />
              ) : (
                <DatePicker
                  selected={endDate}
                  className="form-control pointer"
                  dateFormat="yyyy"
                  placeholderText="YYYY"
                  onChange={value => setEndDate(value)}
                  showYearPicker={value == 3 ? true : false}
                  customInput={
                    <input
                      type="text"
                      id="validationCustom01"
                      placeholder="Date"
                    />
                  }
                />
              )}
              <button
                type="button"
                className="btn btn-primary ms-2 pointer"
                onClick={handleDateClick}
              >
                <i className="mdi mdi-magnify"></i>
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <SessionStatistic
            dashboardData={dashboardData}
            isLoader={isLoader}
            callMainApi={callMainApi}
          />
        </Col>
      </Row>
      {orgId === 1 && <Row id="client-details" height="150vh">
        <DetailsWidget dashboardData={dashboardData} isLoader={isLoader} />
      </Row>}

      <Row>
        <Col xl={12}>
          <div className="page-title-box d-flex align-items-center">
            <h4 className={`${classes.headerDetails} basic-card-title`}>
              Counsellors
            </h4>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={6} className="mb-4">
          <TopUser />
        </Col>
        <Col xl={6} className="mb-4">
          <LeastUser />
        </Col>
      </Row>

      <Row>
        <GoogleMap />
      </Row>
    </>
  )
}

export default Dashboard
