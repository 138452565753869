import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"
import useStyles from "../Style"
import Table from "../../../components/Common/Table"
import classNames from "classnames"
import TextboxField from "./TextboxField"
import { formatCurrency } from "../../../functions/functions"
import moment from "moment"
import { toast } from "react-toastify"
import { useLazyQuery, useMutation } from "@apollo/client"
import { UPDATE_RATE } from "../ReceiptManagement/gql/gql"

function PreviewTable({ data, handleUpdateRate, isRateUpdate,previewData,list,storeAppointments }) {
  const classes = useStyles()

  const [submitMutation, submitResponse] = useMutation(UPDATE_RATE)
  const [isLoad,IsLoad] = useState(isRateUpdate)
  useEffect(()=>{
    IsLoad(isRateUpdate)
  },[isRateUpdate])

  const successAPI = (value, row,index) => {
    handleUpdateRate(value, row,index)
  }
  const onhandleSuccessApi = (formattedValue, row,index)=>{
    successAPI(formattedValue, row,index)

  }
  useEffect(() => {
    if (
      submitResponse.data &&
      submitResponse.data.updateAppointment.status &&
      submitResponse.data.updateAppointment.code === 200
    ) {
      toast.success("Rate updated successfully")
      handleUpdateRate()
    } else {
      if (submitResponse.data && submitResponse.data.updateAppointment) {
        toast.error("Something went wrong")
      }
    }
  }, [submitResponse.data])

  const columns = [
    {
      text: "Description",
      dataField: "type",
      footer: " ",
      key: "type",
      style: {
        minWidth: 380,
      },
    },
    {
      text: "Date",
      dataField: "date",
      key: "date",
      formatter: (cell, row, index, field) => {
        return moment(cell).format("DD-MMM-yyyy")
      },
      footer: columnData => (
        <div>
          {parseInt(data.total_sessions ? data.total_sessions : 0)} Sessions
        </div>
      ),
      style: {
        minWidth: 120,
      },
    },
    {
      text: "Rate",
      key: "price",
      dataField: "price",
      formatter: (cell, row, index, field) => {
        return (
          <TextboxField
            rowValue={cell}
            row={row}
            index={index}
            field={field}
            successAPI={successAPI}
            onhandleSuccessApi={onhandleSuccessApi}
          />
        )
      },
      footer: columnData => {
        return formatCurrency(data.total_rate ? data.total_rate : 0)
      },
    },
    {
      text: "GST",
      key: "gst",
      dataField: "gst",
      formatter: (cell, row, index, field) => {
        return cell && `$${formatCurrency(parseFloat(cell).toFixed(2))}`
      },
      footer: columnData => {
        return formatCurrency(data.total_gst_Amount ? data.total_gst_Amount : 0)
      },
    },
    {
      text: "Total",
      key: "total",
      dataField: "total",
      formatter: (cell, row, index, field) => {
        return formatCurrency(cell)
      },
      footer: columnData => {
        return formatCurrency(data.total_Amount ? data.total_Amount : 0)
      },
    },
  ]

  return (
    <Col sm={12} md={12} lg={12} xl={12}>
      <div
        className={`table-custom-redesign ${classNames(
          classes.hideTableBorder
        )}`}
      >
        <Table
          data={previewData?.calendar?.appointments || []}
          columns={columns}
          noDataIndication={"No Data Found"}
          loading={false}
          isSearch={false}
          isStriped={false}
          isHover={false}
          isBordereded={false}
          isPagination={false}
          keyField={"id"}
        />
      </div>
    </Col>
  )
}

export default PreviewTable
