import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logo from "../../assets/images/logo.png"
import logolight from "../../assets/images/logo-light.png"

import { useMutation } from '@apollo/client';
import { UPDATE_PASSWORD } from "./gql/loginGql"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdatePasswordPage = props => {
    const [inProgress, setInProgress] = useState(false)
    const [initUpdatePasswordInput, setInitUpdatePasswordInput] = useState({
        new_password: '',
        confirm_password: '',
    })

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: initUpdatePasswordInput,
        validationSchema: Yup.object({
            new_password: Yup.string()
                .required("Please Enter New Password").min(6, "Must be 6 characters or more"),
                // .matches(/[a-z]+/, "One lowercase character")
                // .matches(/[A-Z]+/, "One uppercase character")
                // .matches(/[@$!%*#?&]+/, "One special character")
                // .matches(/\d+/, "One number"),
            confirm_password: Yup.string()
                .required("Please Enter Confirm Password").min(6, "Must be 6 characters or more")
                // .matches(/[a-z]+/, "One lowercase character")
                // .matches(/[A-Z]+/, "One uppercase character")
                // .matches(/[@$!%*#?&]+/, "One special character")
                // .matches(/\d+/, "One number")
                .oneOf([Yup.ref('new_password'), null], 'New passwords must match'),
        }),
        onSubmit: (values) => {
            console.log(values);
            handleSubmit(values)
        }
    });

    const handleSubmit = async (values) => {

        let passwordInput = {
            "new_password": values.new_password,
        }
        try {
            console.log(passwordInput)
            setInProgress(true)
            await submitMutation({
                variables: {
                    "passwordInput": passwordInput
                }
            });
            validation.resetForm()
        } catch (e) {
            setInProgress(false)
            console.log(e)
        }
    }

    const [submitMutation, submitResponse] = useMutation(UPDATE_PASSWORD);
    React.useEffect(() => {
        setInProgress(false)
        if (submitResponse.data && submitResponse.data.updatePassword.status) {
            toast.success(submitResponse.data.updatePassword.message)
            localStorage.setItem("is_password_reset", true)
            props.history.push(`/dashboard`)
        } else {
            if (submitResponse.data && submitResponse.data.updatePassword) {
                toast.error(submitResponse.data.updatePassword.message)
            }
        }
    }, [submitResponse.data]);

    useEffect(() => {
        document.body.className = "authentication-bg";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>
            <div className="account-pages my-5  pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <a href="/" className="mb-5 d-block auth-logo">
                                <img src={logo} alt="" height="40" width="80" className="logo logo-dark" />
                                <img src={logolight} alt="" height="22" className="logo logo-light" />
                            </a>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Update Password</h5>
                                    </div>
                                    <div className="p-2 mt-4">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">New password</Label>
                                                <Input
                                                    name="new_password"
                                                    className="form-control"
                                                    placeholder="Enter new password"
                                                    type="password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.new_password || ""}
                                                    invalid={
                                                        validation.touched.new_password && validation.errors.new_password ? true : false
                                                    }
                                                />
                                                {validation.touched.new_password && validation.errors.new_password ? (
                                                    <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Confirm password</Label>
                                                <Input
                                                    name="confirm_password"
                                                    className="form-control"
                                                    placeholder="Enter confirm password"
                                                    type="password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.confirm_password || ""}
                                                    invalid={
                                                        validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                    }
                                                />
                                                {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                    <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <Row className="mb-0">
                                                <Col xs={12} className="text-end">
                                                    <button
                                                        className="btn btn-primary w-md waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Save
                                                    </button>
                                                </Col>
                                            </Row>
                                            {/* <div className="mt-4 text-center">
                                                <p className="mb-0">Remember It ? <Link to="/login" className="fw-medium text-primary"> Signin </Link></p>
                                            </div> */}
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

UpdatePasswordPage.propTypes = {
    forgetError: PropTypes.any,
    forgetSuccessMsg: PropTypes.any,
    history: PropTypes.object,
    userForgetPassword: PropTypes.func
}

export default withRouter(UpdatePasswordPage);