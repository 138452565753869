import React, { useState } from "react"
import moment from "moment"
import _ from "lodash"
// import moment from "moment-timezone";
import { getAllAvailablePermissions, getPermission } from "./getLoginData"
import { writeFile, write, utils } from "xlsx"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import { useHistory } from "react-router-dom"
import { Country, State, City } from "country-state-city"

export const Greeting = () => {
  const currentTime = moment()
  const currentHour = currentTime.hour()
  let greeting = ""
  if (currentHour < 12) {
    greeting = "Good Morning"
  } else if (currentHour < 18) {
    greeting = "Good Afternoon"
  } else {
    greeting = "Good Evening"
  }
  return greeting
}
export function checkPermissions(methods) {
  if (getPermission()) {
    return getPermission().find(item => item === methods)
  } else {
    return false
  }
}

export const getAllCountry = () => {
  const allCountry = Country.getAllCountries()
  const allCountryList = allCountry.map(elem => {
    return {
      name: elem.name,
      id: elem.name,
      value:elem.isoCode,
      label:elem.name
    }
  })
  return allCountryList
}

export const getAllStates = (countryCode) => {
  const allState = State.getStatesOfCountry(countryCode)
  const allStateList = allState.map(elem => {
    return {
      name: elem.name,
      id: elem.name,
      value:elem.isoCode,
      label:elem.name
    }
  })

  return allStateList
}

export const getAllCity = (countryCode, stateCode) => {
  const allCity = City.getCitiesOfState(countryCode, stateCode)
  const allCityList = allCity.map(elem => {
    return {
      name: elem.name,
      id: elem.name,
      value:elem.name,
      label:elem.name
    }
  })

  return allCityList
}

export const hasPermission = () => {
  let history = useHistory()
  const pathName = history.location.pathname.replace(/^\/|\/$/g, "")
  const allPermission = localStorage.getItem("userPermission")
  let userPermission = allPermission ? JSON.parse(allPermission) : []
  return userPermission.includes(pathName)
}


export function checkIsOrganizationStatus() {
  // console.log("user", JSON.parse(localStorage.getItem("authUser")))
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj.isOrganizationMember
  } else {
    return false
  }
}

export const sortCaret = (order, column) => {
  if (!order)
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ms-1 svg-icon-sort">
        <Sort />
      </span>
    )
  else if (order === "asc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ms-1">
        <Up />
      </span>
    )
  else if (order === "desc")
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ms-1">
        <Down />
      </span>
    )
  return null
}

const Sort = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>Stockholm-icons / Shopping / Sort1</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="Stockholm-icons-/-Shopping-/-Sort1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="bound" x="0" y="0" width="24" height="24"></rect>
      <rect
        id="Rectangle-8"
        fill="#000000"
        x="4"
        y="5"
        width="16"
        height="3"
        rx="1.5"
      ></rect>
      <path
        d="M7.5,11 L16.5,11 C17.3284271,11 18,11.6715729 18,12.5 C18,13.3284271 17.3284271,14 16.5,14 L7.5,14 C6.67157288,14 6,13.3284271 6,12.5 C6,11.6715729 6.67157288,11 7.5,11 Z M10.5,17 L13.5,17 C14.3284271,17 15,17.6715729 15,18.5 C15,19.3284271 14.3284271,20 13.5,20 L10.5,20 C9.67157288,20 9,19.3284271 9,18.5 C9,17.6715729 9.67157288,17 10.5,17 Z"
        id="Combined-Shape"
        fill="#000000"
        opacity="0.3"
      ></path>
    </g>
  </svg>
)

const Up = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>Stockholm-icons / Navigation / Up-2</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="Stockholm-icons-/-Navigation-/-Up-2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
      <rect
        id="Rectangle"
        fill="#000000"
        opacity="0.3"
        x="11"
        y="10"
        width="2"
        height="10"
        rx="1"
      ></rect>
      <path
        d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z"
        id="Path-94"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

const Down = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>Stockholm-icons / Navigation / Down-2</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="Stockholm-icons-/-Navigation-/-Down-2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
      <rect
        id="Rectangle"
        fill="#000000"
        opacity="0.3"
        x="11"
        y="4"
        width="2"
        height="10"
        rx="1"
      ></rect>
      <path
        d="M6.70710678,19.7071068 C6.31658249,20.0976311 5.68341751,20.0976311 5.29289322,19.7071068 C4.90236893,19.3165825 4.90236893,18.6834175 5.29289322,18.2928932 L11.2928932,12.2928932 C11.6714722,11.9143143 12.2810586,11.9010687 12.6757246,12.2628459 L18.6757246,17.7628459 C19.0828436,18.1360383 19.1103465,18.7686056 18.7371541,19.1757246 C18.3639617,19.5828436 17.7313944,19.6103465 17.3242754,19.2371541 L12.0300757,14.3841378 L6.70710678,19.7071068 Z"
        id="Path-94"
        fill="#000000"
        fillRule="nonzero"
        transform="translate(12.000003, 15.999999) scale(1, -1) translate(-12.000003, -15.999999) "
      ></path>
    </g>
  </svg>
)

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" })
}

export const capitalizeFirstLetter = string => {
  return string?.charAt(0).toUpperCase() + string?.slice(1)
}

export const ordinalSuffixOf = i => {
  var j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return i + "st"
  }
  if (j == 2 && k != 12) {
    return i + "nd"
  }
  if (j == 3 && k != 13) {
    return i + "rd"
  }
  return i + "th"
}

export function convertUTCToLocal(utcDate, format = null) {
  // const local = _moment(utcDate).local().format(format);
  if (format) {
    return moment(utcDate).local().format(format)
  }
  return moment(utcDate).local()
}

export const formatToCommonDate = (utcDate, format) => {
  const momentInstance = moment(utcDate).local()
  return (
    <div title={momentInstance.format(format)}>{momentInstance.fromNow()}</div>
  )
}

export function convertLocaltoUTC(
  localdate,
  format = "YYYY-MM-DD HH:mm:ssZ",
  isStartOf = false,
  isEndOf = false,
  isStartString = "day"
) {
  // const local = _moment(utcDate).local().format(format);
  let date = moment(localdate)
  if (isStartOf) {
    date = date.startOf(isStartString)
  }
  if (isEndOf) {
    date = date.endOf(isStartString)
  }
  if (format) {
    return date.utc().format(format)
  }
  return date.utc()
}

export const sessionStore = (key, value) => {
  return sessionStorage.setItem(key, value)
}
export const getSessionStore = key => {
  return sessionStorage.getItem(key)
}
export const getUrlParams = paramName => {
  const params = new URL(document.location).searchParams
  return params.get(paramName)
}
export const calculateDuration = (startTime, endTime, diffIn = "minutes") => {
  if (endTime && startTime) {
    const minuts = moment(endTime).diff(moment(startTime), diffIn)
    // const seconds = moment(endTime).diff(moment(startTime), "seconds");
    // const test = seconds / 60;
    return minuts
  }
  return 0
}
export const removeZero = obj => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const updatedKey = key.replace(/^0+/, "") // remove leading zeros
      return [updatedKey, value]
    })
  )
}
export const dateDayFormat = data => {
  return data.map((item, i) => {
    item.date = item.day
    item.day = convertUTCToLocal(item.day, "MM/DD/YYYY")
    return item
  })
}
export const sortByDay = data => {
  let obj = {}
  if (data.length === 1) {
    obj[data[0].day.split("/")[1]] = data[0].count
  }
  let sortData = data.sort((a, b) => {
    obj[b.day.split("/")[1]] = b.count
    obj[a.day.split("/")[1]] = a.count
    var aa = a.day.split("/").reverse().join()
    var bb = b.day.split("/").reverse().join()
    return aa < bb ? -1 : aa > bb ? 1 : 0
  })
  return { obj, sortData }
}

export const getParticipantNameFromTokenIdentity = identity => {
  const identitySplit = identity.split(":")
  return {
    firstName: identitySplit[identitySplit.length - 2],
    lastName: identitySplit[identitySplit.length - 1],
    fullName:
      identitySplit[identitySplit.length - 2] +
      " " +
      identitySplit[identitySplit.length - 1],
    initials:
      identitySplit[identitySplit.length - 2]?.charAt(0) +
      identitySplit[identitySplit.length - 1]?.charAt(0),
  }
}

export const calculateDurationBetweenTwoTimes = (start, end) => {
  if (end && start) {
    const startTime = moment(start, "HH:mm:ss a")
    const endTime = moment(end, "HH:mm:ss a")

    // calculate total duration
    const duration = moment.duration(endTime.diff(startTime))

    // duration in hours
    const hours = parseInt(duration.asHours())

    // duration in minutes
    const minutes = parseInt(duration.asMinutes()) % 60
    return `${hours > 0 ? `${hours}h ` : ""}${minutes} Mins`
  }
  return `0h 0 Mins`
}

export const compareObjects = (firstObj, secondObj) => {
  const isEqual = _.isEqual(firstObj, secondObj)
  return isEqual
}

export const formatCurrency = value => {
  const formattedValue = value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formattedValue
}

export const addLeadingZero = number => {
  return number < 10 ? "0" + number.toString() : number.toString()
}

export function formatPhoneNumber(phoneNumber) {
  let formattedPhoneNumber = phoneNumber ? phoneNumber : ""
  if (formattedPhoneNumber) {
    const number = phoneNumber.includes("+") ? phoneNumber : `+` + phoneNumber
    const parsedPhoneNumber = parsePhoneNumberFromString(number)
    formattedPhoneNumber = parsedPhoneNumber
      ? parsedPhoneNumber.formatInternational()
      : phoneNumber
  }
  return formattedPhoneNumber
}

export const downloadPDF = (url, name, extention = ".pdf") => {
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${name}${extention}`)
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export const hexToRgba = (hex, alpha) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const r = parseInt(result[1], 16)
  const g = parseInt(result[2], 16)
  const b = parseInt(result[3], 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha || 1})`
}

export const isColorDark = color => {
  // Remove any leading # character if present
  color = color.replace("#", "")

  // Extract RGB components from the color string
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)

  // Calculate relative luminance using the formula
  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255

  // Choose a threshold value (0.5 is commonly used) to determine darkness
  const threshold = 0.5

  // Return true if the color is considered dark, false otherwise
  return luminance <= threshold
}

export function fitToColumn(arrayOfArray) {
  // get maximum character of each column
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(
      ...arrayOfArray.map(a2 => (a2[i] ? a2[i].toString().length + 5 : 0))
    ),
  }))
}

export const downloadExcel = (data, name) => {
  // Convert the data array to a sheet object
  // const sheet = utils.aoa_to_sheet(data);

  const sheet = utils.aoa_to_sheet(data)
  sheet["!cols"] = fitToColumn(data)

  // Create a new workbook
  const workbook = {
    SheetNames: ["Sheet1"],
    Sheets: {
      Sheet1: sheet,
    },
  }

  // Convert the workbook to an XLSX file
  const excelBuffer = write(workbook, { bookType: "xlsx", type: "buffer" })

  // Create a Blob from the buffer
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })

  // Create a download link and trigger the download
  const downloadLink = document.createElement("a")
  downloadLink.href = URL.createObjectURL(blob)
  downloadLink.download = name
  downloadLink.click()
}

// export async function webflowLive() {
//   try {
//     const Webflow = require("webflow-api")
//     const webflows = new Webflow({
//       token: "c47a3b30127db9272f4a8a1a68067a4d4d6385ed443be04cd3ff3b8fafd87598",
//     })
//     const sites = await webflows.get("/sites")
//     const result = await webflows.post(
//       "/sites/" + sites.data[0]._id + "/publish",
//       {
//         domains: ["www.nofearcounselling.com"],
//       }
//     )
//   } catch (error) {
//     console.log("erroror", JSON.stringify(error))
//   }
// }

export const meetingType = value => {
  if (value === "online video") {
    return "Video Call"
  } else if (value === "in person") {
    return "In Person"
  } else if (value === "phone") {
    return "Phone Call"
  } else {
    return capitalizeFirstLetter(value)
  }
}
export const reportErrorSlack = async (
  error = "any",
  exception = true,
  functionName = "",
  params = null
) => {
  let messageBody = null
  if (exception) {
    messageBody = {
      username: "NFC Exception", // This will appear as user name who posts the message
      text: "Hands Up.! We found a new issue NFC Frontend", // text
      icon_emoji: ":boom:", // User icon, you can also use custom icons here
      attachments: [
        {
          // this defines the attachment block, allows for better layout usage
          color: "#FF0000", // color of the attachments sidebar.
          fields: [
            // actual fields
            {
              title: "Environment", // Custom field
              value: process.env.REACT_APP_NODE_ENVIRONMENT || "Development", // Custom value
              short: true, // long fields will be full width
            },
            {
              title: "Platform",
              value: "FrontEnd",
              short: true,
            },
            {
              title: "Function",
              value: functionName,
              short: true,
            },
            {
              title: "Message",
              value: `${error.message}`,
              short: true,
            },
            {
              title: "Summary",
              value: error || "Not Getting",
              short: false,
            },
            {
              title: "Params",
              value: `${JSON.stringify(params)}`,
              short: false,
            },
          ],
        },
      ],
    }
    const value = sendTOSlack(messageBody).then()
  }
  return true
}
export const sendTOSlack = async messageBody => {
  const webhookURL =
    "https://hooks.slack.com/services/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"
  // make sure the incoming message body can be parsed into valid JSON
  try {
    messageBody = JSON.stringify(messageBody)
  } catch (e) {
    throw new Error("Failed to stringify messageBody")
  }

  // Promisify the https.request
  return new Promise((resolve, reject) => {
    // general request options, we defined that it's a POST request and content is JSON
    const requestOptions = {
      method: "POST",
      header: {
        "Content-Type": "application/json",
      },
      body: messageBody,
    }

    // actual request
    const req = fetch(webhookURL, requestOptions).then(res => {
      let response = ""
    })
  })
}
export const convertTo12Hour = time => {
  // Split the time string into [hours, minutes, seconds]
  const [hours, minutes, seconds] = time.split(":")

  // Convert the hour component to a 12-hour format
  let period = "AM"
  let hour = parseInt(hours)

  if (hour >= 12) {
    period = "PM"
  }

  if (hour > 12) {
    hour -= 12
  } else if (hour === 0) {
    hour = 12
  }

  // Construct and return the new time string
  return `${hour}:${minutes} ${period}`
}

export const generateDarkAndLightColors = hexColor => {
  hexColor = hexColor?.replace(/^#/, "")
  const bigint = parseInt(hexColor, 16)
  const [red, green, blue] = [
    (bigint >> 16) & 255,
    (bigint >> 8) & 255,
    bigint & 255,
  ]

  // Calculate factors for lightening and darkening the color
  const lightFactor = 0.2
  const darkFactor = 0.2

  // Helper function to adjust color component
  const adjustColorComponent = (comp, factor) =>
    Math.min(255, Math.max(0, Math.round(comp * (1 + factor))))

  // Generate dark color
  const darkRed = adjustColorComponent(red, -darkFactor)
  const darkGreen = adjustColorComponent(green, -darkFactor)
  const darkBlue = adjustColorComponent(blue, -darkFactor)
  const darkColor = `#${((darkRed << 16) | (darkGreen << 8) | darkBlue)
    .toString(16)
    .padStart(6, "0")}`

  // Generate light color
  const lightRed = adjustColorComponent(red, lightFactor)
  const lightGreen = adjustColorComponent(green, lightFactor)
  const lightBlue = adjustColorComponent(blue, lightFactor)
  const lightColor = `#${((lightRed << 16) | (lightGreen << 8) | lightBlue)
    .toString(16)
    .padStart(6, "0")}`

  return { dark: darkColor, light: lightColor }
}
