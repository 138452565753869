import React, { useEffect } from "react"
import { useHistory } from "react-router"
import Loader from "../../components/Common/Loader"
import { getUrlParams } from "../../functions/functions"
const MeetRiderect = () => {
  let email = getUrlParams("email")
  let time = getUrlParams("time")
  console.log(email, time)
  let history = useHistory()

  React.useEffect(() => {
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined

    var requestOptions = {
      method: "GET",
      headers:{
        organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

      }
    }
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/meet?email=${email}&time=${time}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result && result.code == 200) {
          history.push(`/user-login?appointment=${result.data.appointment_id}`)
        }
      })
      .catch(error => console.log("error", error))
  }, [])
  return (
    <div className="text-center mt-5">
      <Loader />
      <div>Please wait for start this meeting.</div>
    </div>
  )
}
export default MeetRiderect
