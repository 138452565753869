import { gql } from "@apollo/client"
export const GET_BLOGS = gql`
  query Data($argumentsInput: ArgumentsInput) {
    blogs(argumentsInput: $argumentsInput) {
      data {
        totalCount
        blogs {
          id
          name
          created_at
          updated_at
          status
          is_approved
          is_requested
          item_id
          post_body
          post_summary
          main_image
          thumbnail_image
          slug
          therapist_calendar {
            id
            name
            url
          }
        }
      }
    }
  }
`
export const GET_BLOG = gql`
  query Blog($argumentsInput: CreateBlogInput) {
    blog(argumentsInput: $argumentsInput) {
      id
      name
      created_at
      updated_at
      status
      item_id
      post_body
      post_summary
      main_image
      thumbnail_image
      slug
    }
  }
`
export const SUBMIT_BLOG = gql`
mutation CreateAndUpdateBlog($createBlogInput: CreateBlogInput!) {
  createAndUpdateBlog(createBlogInput: $createBlogInput) {
    id
    name
    status
    item_id
    post_body
    post_summary
    main_image
    thumbnail_image
    slug
  }
}
`
export const DEL_BLOGS = gql`
  mutation RemoveBlog($createBlogInput: CreateBlogInput!) {
    removeBlog(createBlogInput: $createBlogInput) {
      name
    }
  }
`

export const GET_COUNSELORS = gql`
query Calendars($argumentsInput: ArgumentsInput) {
  getCalendarLegend(argumentsInput: $argumentsInput) {
    data {
      calendars {
        name
        id
        item_id
      }
    }
    code
    status
    message
  }
}`
