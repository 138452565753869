import React, { useCallback, useState, useEffect } from "react"
import { Button, Col, Row } from "reactstrap"
import classNames from "classnames"
import useStyles from "../../Style"
import Table from "../../../../components/Common/Table"
import AllSelect from "./AllSelect"
import ExpandedInvoiceRow from "./ExpandedInvoiceRow"
import { useDispatch, useSelector } from "react-redux"
import MultipleExportedInvoice from "./MultipleExportedInvoice"
import Loader from "../../../../components/Common/Loader"

function ClientAppointments(props) {
  const {
    selectedClient,
    startDate,
    endDate,
    handleShowPreviewTab,
    setPreviewTabData,
    setSelectedPreviewID,
    expandenedRow,
    setExpandenedRow,
  } = props
  const classes = useStyles()
  const [isLoader, setIsLoader] = useState(false)

  const clientAppointmentsData = useSelector(
    state => state.invoicingManagement.clientAppointments
  )

  //************************** Table Data ***************************** */

  const columns = [
    {
      text: " ",
      dataField: "checkbox",
      formatter: allCheckedRowFun,
      style: {
        width: 50,
      },
    },
    {
      text: "Client Name",
      dataField: "name",
      headerAttrs: {
        hidden: true,
      },
      formatter: clientNameFun,
      style: {
        minWidth: 250,
      },
    },
    {
      text: "Export",
      dataField: "id",
      headerAlign: "end",
      align: "end",
      formatter: exportFun,
      headerAttrs: {
        hidden: true,
      },
      style: {
        minWidth: 150,
      },
    },
  ]

  function exportFun(value, row, index, field) {
    return (
      <MultipleExportedInvoice
        rowValue={value}
        row={row}
        index={index}
        field={field}
        startDate={startDate}
        endDate={endDate}
        setIsLoader={setIsLoader}
      />
    )
  }

  function clientNameFun(value, row, index, field) {
    return` (${row?.appointmentcount ? row?.appointmentcount : 0}) ` +  row?.name 
  }

  function allCheckedRowFun(value, row, index, field) {
    return (
      <AllSelect
        rowValue={value}
        row={row}
        index={index}
        field={field}
        startDate={startDate}
        endDate={endDate}
        selectedClient={selectedClient}
      />
    )
  }

  const [expandedIDs, setExpandedIDs] = useState([])

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      // nothing to do...
    }
  }

  const handleExpandButton = (expanded, rowKey, expandable) => {
    if (expanded) {
      setExpandenedRow(prev => prev.filter(item => item !== rowKey))
    } else {
      setExpandenedRow(prev => [...prev, rowKey])
    }
  }

  const expandRow = {
    renderer: row => (
      <ExpandedInvoiceRow
        parentRow={row}
        selectedClient={selectedClient}
        startDate={startDate}
        endDate={endDate}
        handleShowPreviewTab={handleShowPreviewTab}
        setSelectedPreviewID={setSelectedPreviewID}
      />
    ),
    onExpand: handleOnExpand,
    expanded: expandenedRow,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: "right",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return " "
      }
      return " "
    },
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      if (expanded) {
        return (
          <div className={classes.btnAlign}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => handleExpandButton(expanded, rowKey, expandable)}
            >
              <i className="uil-angle-up"></i>
            </button>
          </div>
        )
      }
      return (
        <div className={classes.btnAlign}>
          <Button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => handleExpandButton(expanded, rowKey, expandable)}
          >
            <i className="uil-angle-down"></i>
          </Button>
        </div>
      )
    },
  }

  const handleTableChange = () => {}

  return (
    <Row className="position-relative">
      {isLoader ? (
        <div className="loader-overlayFix">
          <div>
            <Loader />
          </div>
        </div>
      ) : (
        ""
      )}
      <Col sm={12} md={12} lg={12} xl={12}>
        <div
          className={classNames(
            classes.hideTableHeader,
            "table-custom-redesign"
          )}
        >
          <Table
            data={clientAppointmentsData}
            columns={columns}
            noDataIndication={"No Data Found"}
            loading={false}
            keyField={"id"}
            isSearch={false}
            isStriped={false}
            isHover={false}
            expandRow={expandRow}
            isBordereded={false}
            isPagination={false}
          />
        </div>
      </Col>
    </Row>
  )
}

export default ClientAppointments
