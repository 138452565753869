import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

function GenerateReceipt(props) {
  const {
    rowValue,
    row,
    index,
    field,
    setPreviewTabData,
    handleShowPreviewTab,
  } = props
  const selectedClientAppointment = useSelector(
    state => state.receiptManagement.selectedClientAppointments
  )
  const [isEnabled, setIsEnabled] = useState(false)
  const [selectedList, setSelectedList] = useState([])

  useEffect(() => {
    const currentObj = selectedClientAppointment.find(
      n => n.id == row.client_id
    )
    if (currentObj) {
      if (currentObj.selectedList.length >= 1) {
        setIsEnabled(true)
        setSelectedList([...currentObj.selectedList])
      } else {
        setIsEnabled(false)
        setSelectedList([])
      }
    }
  }, [selectedClientAppointment])

  const onGenerateReceipt = () => {
    handleShowPreviewTab(isEnabled, selectedList)
  }

  return (
    <button
      type="button "
      className={`btn ${
        isEnabled ? "btn-primary" : "btn-inactive"
      } btn-sm text-capitalize`}
      disabled={!isEnabled}
      onClick={onGenerateReceipt}
    >
      Generate Receipt
    </button>
  )
}

export default GenerateReceipt
