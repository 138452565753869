import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  ADD_PAYMENT_TYPE,
  GET_PAYMENT_TYPE_ID,
  UPDATE_PAYMENT_TYPE,
} from "./gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import Loader from "../../components/Common/Loader"
const CreatePaymentType = () => {
  let { id } = useParams()
  const decodedId = id && atob(id)
  id = decodedId
  let history = useHistory()

  const [formData, setFormData] = useState({
    name: "",
    is_direct: false,
    appointment_rate_type: undefined,
    rate: undefined,
    tax_percentage: undefined,
    is_coup: false,
    appointment_rate_couple_counselling_type: undefined,
  })
  const [selectedType, setSelectedType] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [emptyValue, setEmptyValue] = useState([])
  const handleChange = e => {
    const { name, value, type, checked } = e.target
    if (type === "checkbox" && name === "is_direct") {
      if (!checked) {
        setFormData(prevData => ({
          ...prevData,
          tax_percentage: undefined,
        }))
      }
    }
    if (type === "checkbox" && name === "is_coup") {
      if (checked) {
        setFormData(prevData => ({
          ...prevData,
          appointment_rate_couple_counselling_type: null,
        }))
      }
    }
    if (name === "rate" && parseFloat(value) < 0) {
      return
    }
    if (name === "tax_percentage" && parseFloat(value) < 0) {
      return
    }
    setFormData(prevData => ({
      ...prevData,
      [name]: type === "checkbox" ? !checked : value,
    }))
  }
  const handleTypeChange = val => {
    setSelectedType(val)
    if (val.value === "no_change") {
    }
    setFormData(prevData => ({
      ...prevData,
      appointment_rate_type: val.value,
      rate: undefined,
    }))
  }
  const [CreatePaymentType, CreatePaymentTypeRes] =
    useMutation(ADD_PAYMENT_TYPE)

  const [UpdatePaymentType, UpdatePaymentTypeRes] =
    useMutation(UPDATE_PAYMENT_TYPE)

  const [getPaymentTypesById, getPaymentTypesByIdRes] = useLazyQuery(
    GET_PAYMENT_TYPE_ID,
    {
      fetchPolicy: "no-cache",
    }
  )

  const addPaymentType = () => {
    const validateData = data => {
      const requiredFields = ["name", "appointment_rate_type"]

      if (formData?.appointment_rate_type === "set_rate") {
        requiredFields.push("rate")
      }
      if (!formData?.is_direct) {
        requiredFields.push("tax_percentage")
      }
      if (formData?.is_coup) {
        requiredFields.push("appointment_rate_couple_counselling_type")
      }

      const missingFields = requiredFields.filter(field => !data[field])
      setEmptyValue(missingFields)
      if (missingFields.length > 0) {
        return false
      }

      return true
    }

    if (validateData(formData)) {
      const {is_coup, ...otherData} = formData
      if (id) {
        setIsSubmitted(true)
        UpdatePaymentType({
          variables: {
            updateRate: otherData,
          },
        })
      } else {
        setIsSubmitted(true)
        CreatePaymentType({
          variables: {
            createPaymentSource: otherData,
          },
        })
      }
    }
  }

  useEffect(() => {
    if (
      CreatePaymentTypeRes.data &&
      CreatePaymentTypeRes?.data?.createPaymentSource?.status &&
      CreatePaymentTypeRes?.data?.createPaymentSource?.code === 200
    ) {
      toast.success(CreatePaymentTypeRes?.data?.createPaymentSource?.message)
      setTimeout(() => {
        setIsSubmitted(false)
        history.goBack()
      }, 2000)
    } else {
      setIsSubmitted(false)
    }
  }, [CreatePaymentTypeRes.data])

  useEffect(() => {
    if (
      UpdatePaymentTypeRes.data &&
      UpdatePaymentTypeRes?.data?.updateRate?.status &&
      UpdatePaymentTypeRes?.data?.updateRate?.code === 200
    ) {
      toast.success(UpdatePaymentTypeRes?.data?.updateRate?.message)
      setIsSubmitted(false)
    } else {
      toast.error(UpdatePaymentTypeRes?.data?.updateRate?.message)
      setIsSubmitted(false)
    }
  }, [UpdatePaymentTypeRes.data])

  useEffect(() => {
    if (id) {
      setIsLoading(true)
      getPaymentTypesById({
        variables: {
          getPaymentSourceByIdId: parseInt(id),
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getPaymentTypesByIdRes?.data &&
      getPaymentTypesByIdRes?.data?.getPaymentSourceById?.data
    ) {
      const getData =
        getPaymentTypesByIdRes?.data?.getPaymentSourceById?.data?.paymentType
      const appointmentRate = {
        label:
          getData?.appointment_rate_type == "set_rate"
            ? "Set Rate"
            : "No Change",
        value: getData?.appointment_rate_type,
      }
      setSelectedType(appointmentRate)
      const { __typename, ...fetchedData } = getData
      const allData = {
        ...fetchedData,
        is_coup: fetchedData?.appointment_rate_couple_counselling_type
          ? true
          : false,
      }
      setFormData(allData)
      setIsLoading(false)
    }
  }, [getPaymentTypesByIdRes.data])
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col className="col-12"></Col>
              <div className="container-fluid">
                <div>
                  <div className="py-3">
                    <div className="flex align-item-center">
                      <i
                        className="waves-effect mdi color-dark-green mdi-arrow-left font-size-22 mr-2"
                        id="backArrow"
                        onClick={() => history.goBack()}
                      />
                      <h4 className="mt-1 mx-2 card-title-main">
                        {" "}
                        {id ? "Edit" : "Create New "} Payment Type
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                {" "}
                <Loader />{" "}
              </div>
            ) : (
              <>
                <Row className="form-custom-white-inputs d-flex justify-content-center mt-5">
                  <Col xl={7} md={7}>
                    <Label className="form-label">Name</Label>
                    <Input
                      name="name"
                      className="form-control"
                      placeholder="Enter Name"
                      type="text"
                      onChange={handleChange}
                      value={formData?.name}
                    />
                    {emptyValue.includes("name") && !formData.name && (
                      <span className="text-danger">
                        Please enter payment type name
                      </span>
                    )}
                  </Col>

                  <Col xl={7} md={7} className="mt-4 mb-2 relative">
                    <Label>Appointment Rate Type</Label>
                    <Select
                      placeholder={"Select"}
                      name={"appointment_rate_type"}
                      className="default-search-input"
                      value={selectedType}
                      onChange={handleTypeChange}
                      options={[
                        { label: "No Change", value: "no_change" },
                        { label: "Set Rate", value: "set_rate" },
                      ]}
                    />
                    {emptyValue.includes("appointment_rate_type") &&
                      !formData.appointment_rate_type && (
                        <span className="text-danger">
                          Please select appointment rate type{" "}
                        </span>
                      )}
                  </Col>

                  {formData?.appointment_rate_type?.toLowerCase() ===
                    "set_rate" && (
                    <Col xl={7} md={7} className="mt-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <Label className="form-label" htmlFor="rate">
                          Rate (Excluding GST 5%)
                        </Label>
                        <div className="form-check form-switch">
                        <Label className="form-label" htmlFor="rate">
                          Coup.
                        </Label>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            name="is_coup"
                            id="is_coup"
                            checked={formData?.is_coup}
                            onClick={handleChange}
                          />
                        </div>
                      </div>

                      <div className="">
                        <Input
                          name="rate"
                          id="rate"
                          className="default-redesign-input"
                          type="number"
                          value={formData?.rate}
                          onChange={handleChange}
                        />
                      </div>
                      {emptyValue.includes("rate") && !formData.rate && (
                        <span className="text-danger">Please enter rate </span>
                      )}
                    </Col>
                  )}

                  {formData?.is_coup && (
                    <>
                      <Col xl={7} md={7} className="mt-4">
                        <div className="mt-2">
                        <Label className="form-label" htmlFor="appointment_rate_couple_counselling_type">
                        Coup. Rate 
                        </Label>
                          <Input
                            name="appointment_rate_couple_counselling_type"
                            id="appointment_rate_couple_counselling_type"
                            className="default-redesign-input"
                            type="number"
                            value={
                              formData?.appointment_rate_couple_counselling_type
                            }
                            onChange={handleChange}
                          />
                          {emptyValue.includes(
                            "appointment_rate_couple_counselling_type"
                          ) &&
                            !formData.appointment_rate_couple_counselling_type && (
                              <span className="text-danger">
                                Please enter Couple rate
                              </span>
                            )}
                        </div>
                      </Col>
                    </>
                  )}

                  <Col xl={7} md={7} className="mt-4">
                    <Label className="form-label" htmlFor="is_direct">
                      Direct
                    </Label>
                    <div className="form-check form-switch">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        name="is_direct"
                        id="is_direct"
                        checked={formData?.is_direct}
                        onClick={handleChange}
                      />
                    </div>
                  </Col>
                  {!formData?.is_direct && (
                    <Col xl={7} md={7} className="mt-4">
                      <Label className="form-label" htmlFor="rate">
                        Tax Percentage
                      </Label>
                      <div className="">
                        <Input
                          name="tax_percentage"
                          id="tax_percentage"
                          className="default-redesign-input"
                          type="number"
                          value={formData?.tax_percentage}
                          onChange={handleChange}
                        />
                      </div>
                      {emptyValue.includes("tax_percentage") &&
                        !formData.tax_percentage && (
                          <span className="text-danger">
                            Please enter tax percentage{" "}
                          </span>
                        )}
                    </Col>
                  )}
                </Row>
                <Row className="form-custom-white-inputs d-flex justify-content-center mt-5">
                  <Col xs={7} className="text-end">
                    <button
                      className="btn btn-inactive mx-2"
                      type="button"
                      onClick={() => {
                        history.push("/payment-types")
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary "
                      onClick={addPaymentType}
                      disabled={isSubmitted}
                    >
                      {id ? "Update" : "Add"}
                    </button>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default CreatePaymentType
