const { makeStyles } = require("@material-ui/styles")

export const useStyles = makeStyles(theme => ({
  selectFilter: {
    minWidth: "200px",
    "& .css-yk16xz-control": {
      // borderRadius: 0
    },
    "@media (max-width: 768px)": {
      minWidth: "100%",
    },
    "@media (max-width: 568px)": {
      minWidth: "100%",
    },
  },
}))

export default useStyles
