import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Card, CardBody, Button } from "reactstrap"
import useStyles from "../styles"
import classNames from "classnames"

//Import Image
import pending from "../../../assets/images/dashboard-icons/pending.svg"
import approved from "../../../assets/images/dashboard-icons/approved.svg"
import cancelled from "../../../assets/images/dashboard-icons/canceled.svg"
import rescheduled from "../../../assets/images/dashboard-icons/rescheduled.svg"
import completed from "../../../assets/images/dashboard-icons/completed.svg"
import noshow from "../../../assets/images/dashboard-icons/noshow.svg"
import paymentPending from "../../../assets/images/dashboard-icons/payment-pending.svg"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import { addLeadingZero } from "../../../functions/functions"

function AppointmentStats(props) {
  const {
    dashboardData,
    callMainApi,
    isLoader,
    partiallyPaidCount,
    noPaidCount,
    approvedStat,
  } = props
  const classes = useStyles()
  const [loading, setLoading] = useState(isLoader)
  const [partiallyCount, setPartiallyCount] = useState(0)
  const [paymentPendingCount, setPaymentPendingCount] = useState(0)

  useEffect(() => {
    setPartiallyCount(partiallyPaidCount)
  }, [partiallyPaidCount])

  useEffect(() => {
    setPaymentPendingCount(noPaidCount)
  }, [noPaidCount])

  useEffect(() => {
    setLoading(isLoader)
  }, [isLoader])

  const refreshApi = () => {
    setLoading(true)
    callMainApi(true)
  }

  const data = [
    {
      id: 1,
      title: "Partically Paid",
      icon: pending,
      value: partiallyCount ? partiallyCount : 0,
    },
    {
      id: 2,
      title: "Approved",
      icon: approved,
      value: approvedStat,
    },
    {
      id: 3,
      title: "Cancelled",
      icon: cancelled,
      value: dashboardData?.no_show_total_counts
        ? dashboardData?.no_show_total_counts
        : 0,
    },
    {
      id: 5,
      title: "Completed",
      icon: completed,
      value: dashboardData?.session_completed
        ? dashboardData?.session_completed
        : 0,
    },
    {
      id: 7,
      title: "Payment Pending",
      icon: paymentPending,
      value: paymentPendingCount ? paymentPendingCount : 0,
    },
  ]

  const columns = [
    {
      text: "Client Name",
      headerAttrs: {
        hidden: true,
      },
      dataField: "title",
      width: 270,
      formatter: NameFun,
      style: {
        minWidth: "150px",
      },
    },
  ]

  function NameFun(rowValue, row) {
    return (
      <div className="d-flex gap-2">
        <img src={row.icon} width="25px" height="25px" />
        <h4 className={classes.normalFontsize}>{row.title}</h4>
      </div>
    )
  }

  return (
    <Card body className="light-green-bg-card-table">
      <CardBody className="p-0">
        <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
          <h4
            className={classNames("card-title", classes.cardTitle)}
          >{`Appointment's Stats`}</h4>
          <img
            src={refresh}
            width="24px"
            height="24px"
            onClick={() => refreshApi()}
            className={classNames(
              classes.refreshIcon,
              loading && classes.refresh
            )}
          />
        </div>
        <div className="d-flex flex-column justify-content-between mt-4 gap-4 card-height-calc-ap-stats">
          {data.map((n, index) => (
            <div key={n.id} className="d-flex justify-content-between">
              <div className="d-flex gap-2 justify-content-center">
                <img src={n.icon} width="32px" height="32px" />
                <div className={`${classes.dataTitle} card-para-main`}>
                  {n.title}
                </div>
              </div>
              <div className={classes.dataTitle}>{addLeadingZero(n.value)}</div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default AppointmentStats
