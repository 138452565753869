import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import { FORGOT_PASSWORD } from "./gql/loginGql"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// import images

import logoNew from "../../assets/images/nfcld.png"
import loginAccent from "../../assets/images/accent-login.svg"

import { useSelector } from "react-redux"

const ForgetPasswordPage = props => {
  const [inProgress, setInProgress] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [initForgotPasswordInput, setInitForgotPasswordInput] = useState({
    email: "",
    otp: "",
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initForgotPasswordInput,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter valid email address")
        .required("Please enter your email"),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      handleSubmit(values)
    },
  })

  const handleSubmit = async values => {
    let confirmEmailInput = {
      email: values.email,
    }
    try {
      setInProgress(true)
      await submitMutation({
        variables: {
          confirmEmailInput: confirmEmailInput,
        },
      })
      localStorage.setItem("reset_password_email", values.email)

      validation.resetForm()
    } catch (e) {
      setInProgress(false)
      setIsSubmitted(false)
      console.log(e)
    }
  }

  const [submitMutation, submitResponse] = useMutation(FORGOT_PASSWORD)
  React.useEffect(() => {
    setInProgress(false)
    if (submitResponse.data && submitResponse.data.forgotPassword.status) {
      toast.success(
        "Email has been sent to your email address also check your spam folder"
      )
      setTimeout(() => {
        props.history.push(`/validate-otp`)
      }, 5000)
    } else {
      if (submitResponse.data && submitResponse.data.forgotPassword) {
        toast.error(submitResponse.data.forgotPassword.message)
        setIsSubmitted(false)
      }
    }
  }, [submitResponse.data])

  useEffect(() => {
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })
  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="account-pages login-account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col
              lg={6}
              xl={5}
              className="align-items-center justify-content-space-between login-left-section"
            >
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logoNew}
                    alt=""
                    height="80"
                    className="logo logo-dark"
                  />
                </Link>
              </div>
              <img
                src={loginAccent}
                height="120"
                className="accent-login-image"
              />
            </Col>
            <Col
              xl={7}
              lg={6}
              className="align-items-center justify-content-space-between"
            >
              <Row className="align-items-center justify-content-center">
                <Col md={8} lg={9} xl={9}>
                  <Card className="custom-card">
                    <CardBody className="p-2">
                      <div className=" mt-2">
                        <h1 className="mb-4 h1-login-details">
                          Reset Password
                        </h1>
                        <p className="text-muted">
                          Reset Password For NFAD account
                        </p>
                      </div>
                      <div className="mt-4 margin-top-login-page">
                        <div
                          className="alert alert-success text-center mb-4"
                          role="alert"
                        >
                          Enter your email and instructions will be sent to you!
                        </div>
                        {forgetError && forgetError ? (
                          <Alert
                            color="danger"
                            className="text-center mb-4 mt-13px-fp"
                          >
                            {forgetError}
                          </Alert>
                        ) : null}
                        {forgetSuccessMsg ? (
                          <Alert
                            color="success"
                            className="text-center mb-4 mt-13px-fp"
                          >
                            {forgetSuccessMsg}
                          </Alert>
                        ) : null}
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3 form-div-login-page">
                            <Label className="form-label form-label-login">
                              Email
                            </Label>
                            <Input
                              name="email"
                              className="form-control default-search-input"
                              placeholder="Enter your email address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row className="mb-5">
                            <Col xs={12} className="text-end">
                              {!inProgress ? (
                                <button
                                  className="button-default-login-page btn btn-primary w-md w-100 waves-effect waves-light"
                                  type="submit"
                                  disabled={isSubmitted}
                                >
                                  Reset
                                </button>
                              ) : (
                                <button
                                  className="button-default-login-page btn btn-primary w-sm w-100 waves-effect waves-light"
                                  disabled
                                >
                                  <Spinner
                                    type="grow"
                                    className="me-1 align-middle spinner-grow-sm "
                                    color="light"
                                  />
                                  Processing...
                                </button>
                              )}
                            </Col>
                          </Row>

                          <div className="mb-3 form-div-login-page">
                            <p className="bottom-cta-register-account">
                              Already have an account?{" "}
                              <Link to="/login#withEmail">Login Now</Link>
                            </p>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

export default withRouter(ForgetPasswordPage)
