import React, { useState, useEffect } from "react"
import feedbackImage from "../../../assets/images/feedback-head.svg"
import style from "../feedback.module.scss"
import classNames from "classnames"
import { useLazyQuery } from "@apollo/client"
import { GET_SESSION_TYPE } from "../gql/gql"
import { capitalizeFirstLetter } from "../../../functions/functions"
import Loader from "../../../components/Common/Loader"

function SesssionType(props) {
  const {
    handleNext,
    selectedSessionTypeId,
    setSelectedSessionTypeId,
    getQuestionsListFun,
  } = props

  const [sessionTypeData, setSessionTypeData] = useState([])
  const [selectedSessionType, setSelectedSessionType] = useState(null)
  const [isSpinner, setIsSpinner] = useState(false)

  //************************** get session type ***************************** */

  const [getSessionTypeDetail, sessionTypeResponse] = useLazyQuery(
    GET_SESSION_TYPE,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    getSessionTypeDetail()
  }, [])

  useEffect(() => {
    if (
      sessionTypeResponse.data &&
      sessionTypeResponse.data.getSessionTypes &&
      sessionTypeResponse.data.getSessionTypes.status &&
      sessionTypeResponse.data.getSessionTypes.code === 200
    ) {
      setIsSpinner(false)
      setSessionTypeData(
        sessionTypeResponse.data.getSessionTypes.data.sessionTypes
      )
    } else {
      setIsSpinner(true)
      setSessionTypeData([])
    }
  }, [sessionTypeResponse.data])

  //************************** functions ***************************** */

  const handleSessionType = id => {
    setSelectedSessionType(id)
    setSelectedSessionTypeId(id)
  }

  const handleClick = () => {
    handleNext(selectedSessionTypeId !== 4 ? 2 : 1)
    getQuestionsListFun(selectedSessionTypeId)
  }

  const slugFormatter = value => {
    switch (value) {
      case "noshow":
        return "No Show"
      case "cancelled":
        return "Cancelled"
      case "rescheduled":
        return "Rescheduled"
      case "attended":
        return "Attended"
      default:
        return value
    }
  }

  return (
    <>
      {isSpinner ? (
        <div className="spinner">
          <Loader />
        </div>
      ) : (
        <div className="d-flex justify-content-center p-3">
          <div>
            <img
              className={`session-type-mgtop-15 ${style.headImage}`}
              src={feedbackImage}
              alt="feedback"
            />
            <h1 className="basic-card-title">Post Consultation Feedback</h1>
            <h4 className="card-para-main ">Is that session is : </h4>
            <div
              className={`${style.sessionContainer} d-flex align-items-center flex-wrap`}
            >
              {sessionTypeData.map(sessionTypeData => (
                <div key={sessionTypeData.id}>
                  <label className={style.inputstyle}>
                    <p className="default-input-style ">
                      {slugFormatter(sessionTypeData.slug)}
                    </p>
                    <input
                      className="default-input-style"
                      type="checkbox"
                      id={sessionTypeData.id}
                      name="sessionType"
                      checked={selectedSessionType === sessionTypeData.id}
                      onChange={e => handleSessionType(sessionTypeData.id)}
                    />
                  </label>
                </div>
              ))}
            </div>
            <button
              className={classNames(
                "btn-lg btn btn-primary waves-effect waves-light session-type-mgtop-15"
              )}
              disabled={!selectedSessionTypeId}
              onClick={handleClick}
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default SesssionType
