import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"


function* loginUser({ payload: { user, history,nexturl } }) {
  try {
    localStorage.setItem("authUser", JSON.stringify(user))
    localStorage.setItem("token", user.token)
    yield put(loginSuccess(user))
       if(nexturl){
      if(user.user.role.role_name == "Patient" || user.user.role.role_name == "patient"){
        return history.push('/appointments')  
      }
      return history.push(nexturl)
    }else if(!nexturl && history){
      if(user.user.role.role_name == "Patient" || user.user.role.role_name == "patient"){
        return history.push('/appointments')  
      }
      return history.push("/dashboard")
    }else if(!nexturl && history){
      if(user.user.role.role_name == "SuperAdmin" || user.user.role.slug.toLowerCase() == "superadmin"){
        return history.push('/organizations')  
      }
      return history.push("/dashboard")
    }
      
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}



function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
