import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// 404 page not found
import PageNotFound from "../pages/404"

// Notification List
import NotificationList from "../components/CommonForBoth/TopbarDropdown/NotificationList"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ValidateOtp from "../pages/Authentication/ValidateOtp"
import UpdatePwd from "../pages/Authentication/UpdatePassword"

// Profile
// import UserProfile from "../pages/Authentication/user-profile";
import ChangePassword from "../pages/Authentication/ChangePassword"

// Users
import UserCreate from "../pages/User/UserCreate"
import UserList from "../pages/User/UserList"

import UserRoleCreate from "../pages/UserRole/UserRoleCreate"
import UserRoleList from "../pages/UserRole/UserRoleList"

import UserPermissionTables from "../pages/Permission/UserPermissionList"
import RolePermissionTables from "../pages/Permission/RolePermissionList"
import CreateEditPermission from "../pages/Permission/CreatePermission"

// Appointment
import Appointments from "../pages/Appointments/AppointmentList"
import AppointmentDetails from "../pages/Appointments/AppointmentDetails"

// Counselor
import Counselors from "../pages/Counselor/CounselorList"
import CounselorDetails from "../pages/Counselor/CounselorDetails"
import CounselorManage from "../pages/Counselor/CounselorManage"

// Clients
import NonDirectClientList from "../pages/Client/NonDirectClient/NonDirectClientList"
import DirectClientList from "../pages/Client/DirectClient/DirectClientList"
import ClientManage from "../pages/Client/components/ClientManage"
import ClientDetails from "../pages/Client/components/ClientDetails"

// Feedback-Form
import Consultationfeedback from "../pages/FeedBack-Form/Consultationfeedback"

// Twilio
import VideoRoom from "../pages/Twillio/VideoRoom"
import DashboardDetails from "../pages/Dashboard/Admin/dashboardDetails"
import MeetRiderect from "../pages/meet-redirect"

// Counselor pay
import CounselorPayList from "../pages/Counselor-Pay/CounselorPayList"
import CounselorPayDetails from "../pages/Counselor-Pay/CounselorPayDetails"
import InvoiceList from "../pages/Counselor/InvoiceList"

// Invoice management
import InvoiceManagement from "../pages/Invoicing-Receipt-Management/InvoicingManagement/index"

// Receipt management
import ReceiptManagement from "../pages/Invoicing-Receipt-Management/ReceiptManagement/index"

// Privacy policy & Term conditions
import PrivacyPolicy from "../pages/PrivacyPolicy"
import TermConditions from "../pages/TermsConditions"
import FeedbackDetails from "../pages/FeedBack-Form/components/FeedbackDetails"
import Support from "../pages/Support"
import FeedbackSubmitted from "../pages/FeedBack-Form/tabs/FeedbackSubmitted"

// import { TabProvider } from "../pages/FeedBack-Form/TabContext";
// import TabComponent from "../pages/FeedBack-Form/TabProvider";
import ReciptTemplate from "../pages/tamplate/ReciptTemplate"
import CounsellorInvoice from "../pages/tamplate/CounsellorInvoice"
import OnBoard from "../pages/Authentication/OnBoard"
import AppointmentType from "../pages/Appointment-Type/appointmentType"
import Languages from "../pages/Languages/Languages"
import ErrorBoundryCheck from "../pages/ErrorBoundryCheck"
import CounsellorType from "../pages/CounsellorType/CounsellorType"
import CounsellorTypeDetail from "../pages/CounsellorType/CounsellorTypeDetail"
import LocationGroups from "../pages/Locations/LocationGroups"
import EditProfile from "../pages/Authentication/EditProfile"
import Therapies from "../pages/Therapies/Therapies"
import Concern from "../pages/Concern/Concern"
import AddLanguages from "../pages/Languages/AddLanguage"
import AddLocations from "../pages/Locations/AddLocation"
import AddTherapies from "../pages/Therapies/AddTherapies"
import AddConcern from "../pages/Concern/AddConcern"
import Blogs from "../pages/Blogs/Blogs"
import Location from "../pages/Location/Location"
import AddLocation from "../pages/Location/AddLocation"
import AddBlog from "../pages/Blogs/AddBlog"
import CreateNewAppointment from "../pages/Appointments/CreateNewAppointment"
import PaymentTypes from "../pages/PaymentTypes/PaymentTypes"
import AddNewCounsellor from "../pages/Counselor/AddNewCounsellor"
import Availibity from "../pages/User/Availibity"
import CreateAppointmentType from "../pages/User/CreateAppointmentType"
import CreateClassType from "../pages/User/CreateClassType"
import Rooms from "../pages/Location/Rooms"
import AddRoom from "../pages/Location/AddRoom"
import RoomAvailibity from "../pages/Location/RoomAvailibity"
import RoomAvail from "../pages/Location/RoomAvail"
import Organizations from "../pages/Organizations/Organizations"
import Organizationstatus from "../pages/Organizations/Organizationstatus"
import CreateOrganization from "../pages/Organizations/CreateOrganization"
import UserPermission from "../pages/Organizations/UserPermission"
import OrganizationUsers from "../pages/Organizations/OrganizationUsers"
import OrganizationSettings from "../pages/Organizations/OrganizationSettings/index"
import EditUserPermission from "../pages/Organizations/EditUserPermission"
import CreatePaymentType from "../pages/PaymentTypes/CreatePaymentType"
import DomainEmail from "../pages/Organizations/DomainEmail"
import LookUp from "../pages/Authentication/LookUp"
import PaymentFailed from "../pages/PaymentFailed"
import LinkAccount from "../pages/Dashboard/Admin/LinkAccount"
import Transaction from "../pages/Organizations/Transaction"
import CardDetails from "../pages/Organizations/CardDetails"
import Badfit from "../pages/User/Badfit"
import MultiRoleUser from "../pages/User/MultiRoleUser"
import DirectNonDirect from "../pages/Client/DirectNonDirect/DirectNonDirect"
import ClientList from "../pages/Counselor/ClientList"
import TrashUsers from "../pages/User/TrashUsers"

const userRoutes = [
  // Permission
  // { path: "/email-tamplate", component: EmailTamplate },
  { path: "/recipt-tamplate", component: ReciptTemplate },
  { path: "/invoice-tamplate", component: CounsellorInvoice },
  { path: "/group-permissions/assign", component: CreateEditPermission },
  { path: "/user-permission-list", component: UserPermissionTables },
  { path: "/group-permissions", component: RolePermissionTables },
  {
    path: "/permission/manage/:type/:id/:name",
    component: CreateEditPermission,
  },

  { path: "/dashboard", component: Dashboard },
  { path: "/dashboard-details", component: DashboardDetails },
  { path: "/notification-list", component: NotificationList },

  // Profile
  { path: "/change-password", component: ChangePassword },
  { path: "/profile/:id", component: CounselorManage },
  { path: "/linkAccount", component: LinkAccount },
  { path: "/user-groups/create", component: UserRoleCreate },
  { path: "/user-groups", component: UserRoleList },
  { path: "/user-groups/:id/edit", component: UserRoleCreate },

  { path: "/users/create", component: UserCreate },
  { path: "/bad-fit", component: Badfit },
  { path: "/users", component: UserList },
  { path: "/trash-users", component: TrashUsers },
  { path: "/users/:username/edit", component: UserCreate },
  { path: "/multiUsers/:username/edit", component: MultiRoleUser },
  { path: "/multiUsers/create", component: MultiRoleUser },

  // Appointments
  { path: "/appointments", component: Appointments },
  { path: "/appointments/:id", component: AppointmentDetails },

  // Counselors
  { path: "/counsellors", component: Counselors },
  { path: "/counsellors/new", component: AddNewCounsellor },
  { path: "/counsellors/:id", component: CounselorDetails },
  { path: "/counsellors/:id/edit", component: CounselorManage },
  { path: "/counsellors/:id/invoice", component: InvoiceList },

  // Client
  { path: "/direct-clients", component: DirectClientList },
  { path: "/non-direct-clients", component: NonDirectClientList },
  { path: "/direct-non-direct", component: DirectNonDirect },
  { path: "/my-clients", component: ClientList },
  //Organizations
  { path: "/organizations", component: Organizations },
  { path: "/organizations/create", component: CreateOrganization },
  { path: "/organizations/create/:id", component: CreateOrganization },
  { path: "/organizations/domain-verification/:id", component: DomainEmail },
  { path: "/organizationstatus", component: Organizationstatus },
  { path: "/user-permission", component: UserPermission },
  { path: "/user-permission/create/:roleId", component: EditUserPermission },
  { path: "/orgnization-settings", component: OrganizationSettings },
  { path: "/orgnization-users", component: OrganizationUsers },
  { path: "/transactions", component: Transaction },

  // Plans
  { path: "/plans/:id/edit", component: ClientManage },
  { path: "/plans/:id", component: ClientDetails },

  // Counselor pay
  { path: "/counsellor-pay", component: CounselorPayList },
  { path: "/counsellor-pay/:id", component: CounselorPayDetails },

  // Receipt management
  { path: "/receipt-management", component: ReceiptManagement },

  // Receipt management
  { path: "/invoice-management", component: InvoiceManagement },

  // Feedback Consultation
  { path: "/feedback/:appointment_id", component: FeedbackDetails },
  { path: "/appointment-type", component: AppointmentType },
  { path: "/languages", component: Languages },
  { path: "/languages/add", component: AddLanguages },
  { path: "/languages/:id", component: AddLanguages },
  { path: "/areas", component: LocationGroups },
  { path: "/areas/add", component: AddLocations },
  { path: "/areas/:id", component: AddLocations },
  { path: "/counsellor-type", component: CounsellorType },
  { path: "/counsellor-type/add", component: CounsellorTypeDetail },
  { path: "/counsellor-type/:id", component: CounsellorTypeDetail },
  { path: "/therapies", component: Therapies },
  { path: "/therapies/add", component: AddTherapies },
  { path: "/therapies/:id", component: AddTherapies },
  { path: "/concern", component: Concern },
  { path: "/concern/add", component: AddConcern },
  { path: "/concern/:id", component: AddConcern },
  { path: "/blogs/", component: Blogs },
  { path: "/blogs/add", component: AddBlog },
  { path: "/blogs/:id", component: AddBlog },
  { path: "/location/", component: Location },
  { path: "/location/add", component: AddLocation },
  { path: "/location/:id", component: AddLocation },
  { path: "/create-appointment", component: CreateNewAppointment },
  { path: "/create-appointment/:calendarId", component: CreateNewAppointment },
  { path: "/create-appointment/:userId/edit", component: CreateNewAppointment },
  { path: "/payment-types", component: PaymentTypes },
  { path: "/create-payment-type", component: CreatePaymentType },
  { path: "/create-payment-type/:id", component: CreatePaymentType },
  { path: "/counsellors/:id/availability", component: Availibity },
  { path: "/create-appointment-type", component: CreateAppointmentType },
  { path: "/create-appointment-type/:id", component: CreateAppointmentType },
  { path: "/create-class-type", component: CreateClassType },
  { path: "/rooms/:locationId", component: Rooms },
  { path: "/roomsview/:counsellorId", component: Rooms },
  { path: "/addRoom/:locationId", component: AddRoom },
  { path: "/roomAvailibity/:roomId", component: RoomAvailibity },
  { path: "/roomAvail/:roomId", component: RoomAvail },
  { path: "/card-details", component: CardDetails },

  // this route should be at the end of all other routes
  // { path: "*", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/edit-profile", component: EditProfile },
]

const authRoutes = [
  { path: "/404", component: PageNotFound },
  { path: "/payment-failed", component: PaymentFailed },
  { path: "/check-boundry", component: ErrorBoundryCheck },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/on-board", component: OnBoard },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/validate-otp", component: ValidateOtp },
  { path: "/update-password", component: UpdatePwd },
  { path: "/register", component: Register },
  { path: "/lookup", component: LookUp },
  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },
]

const videoCallRoutes = [
  // Video Room
  // { path: "/appointments/join/:appointment_id", component: VideoRoom },
  // Feedback Consultation
  {
    path: "/feedback-consultation/:appointment_id",
    component: Consultationfeedback,
  },
  { path: "/thank-you", component: FeedbackSubmitted },
]

const withoutUserMiddlewareRoutes = [
  { path: "/appointments/join/:appointment_id", component: VideoRoom },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-of-services", component: TermConditions },
  { path: "/meet", component: MeetRiderect },

  { path: "/support", component: Support },
]

export { userRoutes, authRoutes, videoCallRoutes, withoutUserMiddlewareRoutes }
