import React, { useState, useEffect } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import useStyles from "../Style"
import "react-toastify/dist/ReactToastify.css"
import _ from "lodash"
import classNames from "classnames"
import ExtraToolkit from "../components/ExtraToolkit"
import TabContent from "../../../components/Common/TabBar/TabContent"
import TabNavItem from "../../../components/Common/TabBar/TabNavItem"
import ClientAppointments from "./components/ClientAppointments"
import { GET_INVOICE_DATA, GET_CLIENTS } from "./gql/gql"
import { useLazyQuery } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"
import {
  capitalizeFirstLetter,
  convertLocaltoUTC,
  hasPermission,
} from "../../../functions/functions"
import Loader from "../../../components/Common/Loader"
import PreviewInfo from "./components/PreviewInfo"
import CustomSelect from "../../../components/CustomSelect"
import PreviewInfoInvoice from "./components/PreviewInfoInvoice"
import {
  deleteAllExportedInvoices,
  getClientAppointments,
} from "../../../store/actions"
import AsyncSelect from "react-select/async"
import ExportedInvoiceList from "./components/ExportedInvoiceList"
import PageNotFound from "../../404"

export const ShowContent = () => {
  const classes = useStyles()

  return (
    <div
      className={classNames(
        "h-100 w-100 d-flex justify-content-center align-items-center",
        classes.emptyPageContent
      )}
    >
      There appears to be nothing here
    </div>
  )
}

function InvoiceManagement() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectedClient, setSelectedClient] = useState(undefined)
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)
  const [activeTab, setActiveTab] = useState("clientAppointments")
  const [isTabContentVisible, setIsTabContentVisible] = useState(false)
  const [isPreviewTabVisible, setIsPreviewTabVisible] = useState(false)
  const [previewTabData, setPreviewTabData] = useState([])
  const [clientList, setClientList] = useState([])
  const [selectedPreviewID, setSelectedPreviewID] = useState({})

  //************************** client list ***************************** */

  const [selectedClientValue, setselectedClientValue] = useState("")

  const loadOptions = (inputSelect, callback) => {
    getClientList({
      variables: {
        argumentsInput: {
          page: 1,
          limit: 100,
          search: inputSelect || "",
          sort: "id",
          sort_dir: "DESC",
          role_id:3,
        },
      },
    }).then(listResponse => {
      if (
        listResponse.data &&
        listResponse.data.getUsers &&
        listResponse.data.getUsers.status &&
        listResponse.data.getUsers.code === 200
      ) {
        const data = listResponse.data.getUsers.data.users.map(obj => ({
          name: `${
            obj.first_name
              ? capitalizeFirstLetter(obj.first_name)
              : capitalizeFirstLetter(obj.name ? obj.name : "")
          } ${obj.last_name ? capitalizeFirstLetter(obj.last_name) : ""}`,
          label: `${
            obj.first_name
              ? capitalizeFirstLetter(obj.first_name)
              : capitalizeFirstLetter(obj.name ? obj.name : "")
          } ${obj.last_name ? capitalizeFirstLetter(obj.last_name) : ""}`,
          id: parseInt(obj.id),
          value: parseInt(obj.id),
        }))
        callback(data)
      }
    })
  }

  const [getClientList, listResponse] = useLazyQuery(GET_CLIENTS, {
    fetchPolicy: "no-cache",
  })

  function getClientListFun(search) {
    getClientList({
      variables: {
        argumentsInput: {
          page: 1,
          limit: 100,
          search: search || "",
          sort: "id",
          sort_dir: "DESC",
          role_id:3,
        },
      },
    })
  }

  useEffect(() => {
    getClientListFun()
  }, [])

  useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getUsers &&
      listResponse.data.getUsers.status &&
      listResponse.data.getUsers.code === 200
    ) {
      const data = listResponse.data.getUsers.data.users.map(obj => ({
        name: `${
          obj.first_name
            ? capitalizeFirstLetter(obj.first_name)
            : capitalizeFirstLetter(obj.name ? obj.name : "")
        } ${obj.last_name ? capitalizeFirstLetter(obj.last_name) : ""}`,
        label: `${
          obj.first_name
            ? capitalizeFirstLetter(obj.first_name)
            : capitalizeFirstLetter(obj.name ? obj.name : "")
        } ${obj.last_name ? capitalizeFirstLetter(obj.last_name) : ""}`,
        id: parseInt(obj.id),
        value: parseInt(obj.id),
      }))
      setClientList(data)
    } else {
      setClientList([])
    }
  }, [listResponse.data])

  //************************** Payment list ***************************** */

  const [isSpinner, setIsSpinner] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [paymentListCount, setPaymentListCount] = useState(0)
  const [expandenedRow, setExpandenedRow] = useState([])

  const [getPaymentList, paymentListResponse] = useLazyQuery(GET_INVOICE_DATA, {
    fetchPolicy: "no-cache",
  })

  function getPaymentListFun(clientId, startingDate, endingDate) {
    getPaymentList({
      variables: {
        argumentsInput: {
          start_date: startingDate
            ? convertLocaltoUTC(startingDate, "YYYY-MM-DD HH:mm:ssZ", true)
            : null,
          end_date: endingDate
            ? convertLocaltoUTC(endingDate, "YYYY-MM-DD HH:mm:ssZ", false, true)
            : null,
          ...(clientId ? { user_id: parseInt(clientId) } : {}),
        },
      },
    })
  }

  useEffect(() => {
    if (
      paymentListResponse.data &&
      paymentListResponse.data.getInvoicedata &&
      paymentListResponse.data.getInvoicedata.code === 200
    ) {
      setIsSpinner(false)
      setIsTabContentVisible(true)
      const withDataArray =
        paymentListResponse.data.getInvoicedata.data.paymentTypeData.map(
          obj => ({ ...obj, data: [] })
        )
      dispatch(getClientAppointments(withDataArray))
      const sumOfTotalAppointments =
        paymentListResponse.data.getInvoicedata.data.paymentTypeData.reduce(
          (acc, obj) => {
            return acc + parseInt(obj.appointmentcount)
          },
          0
        )
      setPaymentListCount(sumOfTotalAppointments)
    } else if (
      paymentListResponse.data &&
      paymentListResponse.data.getInvoicedata &&
      listResponse.data.getInvoicedata.code !== 200
    ) {
      setIsSpinner(false)
      dispatch(getClientAppointments([]))
      setPaymentListCount(0)
      setTotalCount(0)
    } else {
      setIsSpinner(true)
      setPaymentListCount(0)
      setTotalCount(0)
    }
  }, [paymentListResponse.data])

  //************************** Exported Invoices ***************************** */

  const [exportCount, setExportCount] = useState(0)

  const exportedInvoicesData = useSelector(
    state => state.invoicingManagement.exportedInvoices
  )

  useEffect(() => {
    setExportCount(exportedInvoicesData.length)
  }, [exportedInvoicesData.length])

  //************************** functions ***************************** */

  const handleClientChange = value => {
    setselectedClientValue(value)
    setSelectedClient(value.id)
  }

  const onSearch = () => {
    setActiveTab("clientAppointments")
    setIsPreviewTabVisible(false)
    dispatch(deleteAllExportedInvoices())

    setIsTabContentVisible(true)
    getPaymentListFun(selectedClient, startDate, endDate)
    setExpandenedRow([])
  }

  const handleClear = () => {
    setActiveTab("clientAppointments")
    setIsPreviewTabVisible(false)
    setSelectedClient(undefined)
    setStartDate("")
    setEndDate("")
    setselectedClientValue(null)
    setIsTabContentVisible(false)
    loadOptions("")
    dispatch(deleteAllExportedInvoices())
    setExpandenedRow([])
  }

  const handleShowPreviewTab = value => {
    setIsPreviewTabVisible(value)
    if (value) {
      setActiveTab("preview")
    }
  }

  return (
    <React.Fragment>
          {hasPermission() ? (
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <div className="p-2">
              <h4 className="card-title-main">Invoice Management</h4>
            </div>
          </div>
          <Row className="mg-top-default-ui">
            <Col className="col-12">
              <Card
                className={`${
                  !isTabContentVisible ? classes.cardContainer : ""
                } light-green-bg-card-table`}
              >
                <CardBody>
                  <Row>
                    <Col xs={12} sm={6} lg={3} xl={3}>
                      <AsyncSelect
                        cacheOptions
                        placeholder="Select a client"
                        loadOptions={loadOptions}
                        defaultOptions={clientList}
                        value={selectedClientValue}
                        onChange={selectedOption =>
                          handleClientChange(selectedOption)
                        }
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "8px",
                            minHeight: "41px",
                            border: "1px solid #ced4da",
                            cursor:'pointer'
                          }),
                        }}
                        className="default-redesign-input"
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={7}
                      xl={6}
                      className="d-flex margin-top-10px-xs"
                    >
                      <ExtraToolkit
                        startDate={startDate}
                        endDate={endDate}
                        setIsSpinner={setIsSpinner}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onSearch={onSearch}
                        handleClear={handleClear}
                        selectedClient={selectedClient}
                        selectedClientValue={selectedClientValue}
                        className="default-redesign-input"
                      />
                    </Col>
                  </Row>
                  {isTabContentVisible ? (
                    <>
                      {isSpinner ? (
                        <Col className="col-12 user-role-create-height-double">
                          <div className="spinner">
                            <Loader />
                          </div>
                        </Col>
                      ) : (
                        <Row Row className="mt-4">
                          <div className="Tabs">
                            <ul
                              className={classNames(
                                "nav d-flex gap-4 tab-items-counsellor-details",
                                classes.tabBar
                              )}
                            >
                              <TabNavItem
                                title={`Client Appointments (${paymentListCount})`}
                                id="clientAppointments"
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                              />
                              {isPreviewTabVisible && (
                                <TabNavItem
                                  title="Preview"
                                  id="preview"
                                  activeTab={activeTab}
                                  setActiveTab={setActiveTab}
                                />
                              )}
                              <TabNavItem
                                title={`Exported Invoices (${exportCount})`}
                                id="exportedInvoices"
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                              />
                            </ul>
                            <div className="outlet mt-4">
                              <TabContent
                                id="clientAppointments"
                                activeTab={activeTab}
                              >
                                <ClientAppointments
                                  selectedClient={selectedClient}
                                  startDate={startDate}
                                  endDate={endDate}
                                  totalCount={totalCount}
                                  handleShowPreviewTab={handleShowPreviewTab}
                                  setPreviewTabData={setPreviewTabData}
                                  setSelectedPreviewID={setSelectedPreviewID}
                                  setExpandenedRow={setExpandenedRow}
                                  expandenedRow={expandenedRow}
                                />
                              </TabContent>
                              <TabContent id="preview" activeTab={activeTab}>
                                <PreviewInfoInvoice
                                  selectedList={previewTabData}
                                  selectedPreviewID={selectedPreviewID}
                                  startDate={startDate}
                                  endDate={endDate}
                                  selectedClient={selectedClient}
                                  setIsSpinner={setIsSpinner}
                                />
                              </TabContent>
                              <TabContent
                                id="exportedInvoices"
                                activeTab={activeTab}
                              >
                                {exportCount === 0 ? (
                                  <ShowContent />
                                ) : (
                                  <ExportedInvoiceList
                                    handleShowPreviewTab={handleShowPreviewTab}
                                    setSelectedPreviewID={setSelectedPreviewID}
                                  />
                                )}
                              </TabContent>
                            </div>
                          </div>
                        </Row>
                      )}
                    </>
                  ) : (
                    <ShowContent />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  )
}

export default InvoiceManagement
