import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_LANGUAGE, SUBMIT_LANGUAGE } from "./gql/Gql"
import * as Yup from "yup"
import { useFormik } from "formik"
import Loader from "../../components/Common/Loader"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
const AddLanguages = () => {
  let history = useHistory()
  const [toggleSwitch, settoggleSwitch] = useState(true)
  let { id } = useParams()
  const decodedFId = id && atob(id)
  id = decodedFId
  const [getLanguage, getLanguageList] = useLazyQuery(GET_LANGUAGE, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(SUBMIT_LANGUAGE)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: "",
    status: false,
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Please enter language name"),
    }),
    onSubmit: values => {
      setIsSubmit(true)
      let obj = {
        name: values.name,
        status: initialValues.status ? "active" : "inactive",
      }
      if (id) {
        obj.id = parseInt(id)
      }
      submitMutation({
        variables: {
          createLanguageInput: obj,
        },
      }).then(res => {
        toast.success(`Language successfully ${id ? "updated" : "created"}`)
        setTimeout(() => {
          if (id){
            setIsSubmit(false)
          }else{
            history.push("/languages")
          }
        }, 1500).catch(err => {
          toast.error("something went wrong !")
          setIsSubmit(false)
        })
      })
    },
  })
  useEffect(() => {
    if (id) {
      setIsLoader(true)
      getLanguage({
        variables: {
          createLanguageInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [id])
  useEffect(() => {
    if (getLanguageList.data && getLanguageList.data?.getLanguage?.name) {
      setInitialValues({
        name: getLanguageList.data.getLanguage.name,
        status:
          getLanguageList.data.getLanguage.status == "active" ? true : false,
      })
      settoggleSwitch(
        getLanguageList.data.getLanguage.status == "active" ? true : false
      )
      setIsLoader(false)
    } else {
      setIsLoader(false)
    }
  }, [getLanguageList.data])

  const handleToggle = e => {
    setInitialValues(prev => {
      return { name: validation.values.name, status: !validation.values.status }
    })
  }

  return (
    <div className="page-content">
      {isLoader ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div>
              <div className="py-3">
                <div className="flex align-item-center">
                  <i
                    className="waves-effect mdi color-dark-green mdi-arrow-left font-size-22 mr-2"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className="mt-1 mx-2 card-title-main">
                    {" "}
                    {id ? "" : "Create New "} Language
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={6}>
              <Card className="transparent-bg-card">
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="form-custom-white-inputs">
                      <Col xl={12} md={12}>
                        <Label className="form-label">Language</Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Language"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && (
                          <div className={style.warnText}>
                            {validation.errors.name}
                          </div>
                        )}
                      </Col>
                      <Col xl={12} md={12} className="mt-3">
                        <Label className="form-label" htmlFor="customSwitch2">
                          Publish
                        </Label>
                        <div className="form-check form-switch">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch2"
                            checked={validation.values.status}
                            onClick={e => handleToggle(e)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col xs={12} className="text-end">
                        <button
                          className="btn btn-inactive mx-2"
                          type="button"
                          disabled={isSubmit}
                          onClick={() => {
                            history.push("/languages")
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary "
                          type="submit"
                          disabled={isSubmit}
                        >
                          {id ? "Update" : "Add"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
export default AddLanguages
