import React, { useState, useEffect } from "react"
import { Card, CardBody, CardImg, CardImgOverlay, CardTitle } from "reactstrap"
import useStyles from "../styles"
import classNames from "classnames"
import appointmentCard from "../../../assets/images/backgroundNFCvideocall.png"
import videoCam from "../../../assets/images/dashboard-icons/videocam.svg"
import calendar from "../../../assets/images/dashboard-icons/calendar.svg"
import format from "../../../assets/images/dashboard-icons/typeCounselling.svg"
import { useLazyQuery } from "@apollo/client"
import { GET_APPOINTMENTS } from "./gql/gql"
import moment from "moment"
import {
  convertUTCToLocal,
  calculateDuration,
  capitalizeFirstLetter,
  meetingType,
} from "../../../functions/functions"

function AppointmentCard() {
  const classes = useStyles()

  // appointments

  const [appointments, setAppointments] = useState([])
  const [firstAppointment, setFirstAppointment] = useState({})

  const [getAppointmentList, listResponse] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: "no-cache",
  })

  function getAppointmentListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder
  ) {
    getAppointmentList({
      variables: {
        argumentsInput: {
          current_appointment: true,
          page: 1,
          limit: 5,
          search: "",
          sort: "utc_datetime",
          sort_dir: "ASC",
          device_type:"web",
        },
      },
    })
  }

  useEffect(() => {
    getAppointmentListFun()
  }, [])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointments &&
      listResponse.data.getAppointments.code === 200 &&
      listResponse.data.getAppointments.status
    ) {
      if (listResponse.data.getAppointments.data) {
        const appointments = listResponse.data.getAppointments.data.appointments_arr?.map((elem)=>{
          return elem?.appointment
        })
        setAppointments(appointments)
        setFirstAppointment(
          appointments[0]
        )
      }
    } else if (!listResponse.data) {
      setAppointments([])
      setFirstAppointment({})
    } else {
      setAppointments([])
      setFirstAppointment({})
    }
  }, [listResponse.data])

  // Twilio

  const handleSubmit = () => {
    const win = window.open(
      `/appointments/join/${firstAppointment.appointment_id}`,
      "_blank"
    )
    win.focus()
  }

  const handleDirection = () => {
    const win = window.open(
      `${firstAppointment.type_of_appointment.google_address}`,
      "_blank"
    )
    win.focus()
  }

  // calculate time

  const startTime = firstAppointment?.utc_datetime
    ? convertUTCToLocal(firstAppointment.utc_datetime)
    : null
  const endTime = firstAppointment?.duration
    ? moment(startTime).add(firstAppointment.duration, "minutes")
    : null
  let durationTimeInMin = calculateDuration(moment(), moment(startTime))
  const isLiveMeeting = () => {
    if (durationTimeInMin <= 10) {
      return "Live"
    } else {
      return `In Next ${durationTimeInMin} min`
    }
  }

  const meetingTypeButton = () => {
    if (firstAppointment?.type_of_appointment?.call_type === "online video") {
      return (
        <button
          className={classNames("btn btn-primary w-100")}
          onClick={handleSubmit}
        >
          Join
        </button>
      )
    }
    if (
      firstAppointment?.type_of_appointment?.call_type === "in person" &&
      firstAppointment.type_of_appointment?.google_address
    ) {
      return (
        <button className={`${classes.joinBtn} w-100`} onClick={handleDirection}>
          <span className={classes.joinBtnText}>Direction</span>
        </button>
      )
    }
  }

  return (
    <Card
      className={classNames(
        "w-100 light-green-bg-card-table",
        classes.appointmentCardContainer
      )}
    >
      <CardBody className={classNames("p-0", classes.cardImg)}>
        <CardImg
          alt="Card image cap"
          src={appointmentCard}
          className="appointment-card-height"
          width="100%"
        />
      </CardBody>
      <CardImgOverlay
        className={classNames(
          "p-0 d-flex flex-column justify-content-between",
          classes.appointmentCard
        )}
      >
        <CardTitle className={`${classes.appointmentTitle} card-title-main`}>
          Next Appointment
        </CardTitle>
        {appointments.length === 0 ? (
          <div className="d-flex justify-content-center card-title-main align-items-center h-100">
            No appointments for today
          </div>
        ) : (
          <>
            <div className={classes.appointmentDetailsContainer}>
              <div>
                <div className="d-flex gap-2 ">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex">
                      <img
                        src={videoCam}
                        width={20}
                        className="counselor-appointcards-marginright-10"
                      />

                      <span className="card-title-main">
                        {firstAppointment?.type_of_appointment?.call_type
                          ? meetingType(
                              firstAppointment.type_of_appointment?.call_type
                            )
                          : ""}
                      </span>
                    </div>
                    <span
                      className={`${classes.detailsTitle} card-title-main appointment-card-name`}
                    >
                      {capitalizeFirstLetter(
                        firstAppointment?.first_name
                          ? firstAppointment?.first_name
                          : " "
                      )}{" "}
                      {capitalizeFirstLetter(
                        firstAppointment?.last_name
                          ? firstAppointment?.last_name
                          : " "
                      )}
                    </span>
                    <div className="d-flex mt-3">
                      <img
                        src={calendar}
                        width={20}
                        className="counselor-appointcards-marginright-10"
                      />
                      <span className={`${classes.detailsDate} card-para-main`}>
                        {moment(startTime).format("DD-MMM-yyyy")}
                      </span>
                    </div>

                    {firstAppointment?.type_of_appointment?.name && (
                      <>
                        <div className="d-flex mt-3">
                          <img
                            src={format}
                            width={20}
                            className="counselor-appointcards-marginright-10"
                          />
                          <span
                            className={`${classes.detailsDate} card-para-main`}
                          >
                            {firstAppointment?.type_of_appointment?.name}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between position-relative">
                <div className="d-flex gap-1 align-items-center">
                  <span className="d-flex justify-content-end card-para-main counselor-appointcards-width-100">
                    {moment(startTime).format("hh:mm A")}
                  </span>
                  <span
                    className={`${classes.appointmentDot} dot-appointments-card`}
                  ></span>
                </div>
                <div className="d-flex gap-1 align-items-center">
                  <span className="d-flex justify-content-end card-para-main counselor-appointcards-width-100">
                    {moment(endTime).format("hh:mm A")}
                  </span>
                  <span
                    className={`${classes.appointmentDot} dot-appointments-card`}
                  ></span>
                </div>
                <span
                  className={`${classes.dotBorder} line-appointments-card`}
                />
              </div>
            </div>
            <div
              className={`${classes.appointmentDetailsFooter} d-flex flex-wrap`}
            >
              <span className="w-100 text-center mb-2 card-para-main">
                {isLiveMeeting()}
              </span>
              {meetingTypeButton()}
            </div>
          </>
        )}
      </CardImgOverlay>
    </Card>
  )
}

export default AppointmentCard
