import { gql } from "@apollo/client"

export const GET_NOTIFICATIONS = gql`
query GetNotifications($argumentsInput: ArgumentsInput) {
  getNotifications(argumentsInput: $argumentsInput) {
    code
    status
    message
    data {
      unreadCount
      totalCount
      notifications {
        id
        created_at
        job_id
        candidate_id
        job_application_id
        type
        module
        title
        message
        is_read
        created_on
        redirect_url
        icon_url
      }
    }
  }
}
`

export const READ_NOTIFICATION = gql`
mutation ReadNotification($notificationInput: NotificationInput) {
  readNotification(notificationInput: $notificationInput) {
    code
    status
    message
  }
}
`
