import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Card, CardBody, Button } from "reactstrap"
import CountUp from "react-countup"
import useStyles from "../styles"
import classNames from "classnames"
import Table from "../../../components/Common/Table"
import { GET_APPROVAL_REQUIRED_DETAILS } from "./gql/gql"
import { useLazyQuery } from "@apollo/client"
import { GET_COUNSELORS } from "../../Client/gql/clientGql"
import style from "./style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"

function ApprovalRequired({ setIsLoader, approvedStat, setApprovedStat }) {
  const classes = useStyles()
  const [calenderID, setCalenderID] = useState("")
  const [getApprovalREquired, getApprovalREquiredResponse] = useLazyQuery(
    GET_APPROVAL_REQUIRED_DETAILS,
    { fetchPolicy: "no-cache" }
  )
  const [getCounselor, getCounselorResponse] = useLazyQuery(GET_COUNSELORS, {
    fetchPolicy: "no-cache",
  })

  const [data, setData] = useState([])

  const columns = [
    {
      text: "Client Name",
      dataField: "name",
      width: 270,
      formatter: NameFun,
      style: {
        minWidth: "150px",
      },
    },
    {
      text: "Used Session",
      dataField: "total_sessions",
      width: 270,
      headerFormatter: NameHeaderFormatter,
      style: {
        minWidth: "150px",
        textAlign: "right",
      },
    },
  ]

  function NameFun(rowValue, row) {
    return (
      <div className="d-flex gap-2 align-items-center">
        <span className={classNames(classes.boldFont, classes.normalFontsize)}>
          {row.client && row.client.first_name}{" "}
          {row.client && row.client.last_name}
        </span>
      </div>
    )
  }

  function NameHeaderFormatter(column, colIndex) {
    return (
      <div className="">
        <div className={`${classes.tableHeader} text-end`}>{column.text}</div>
      </div>
    )
  }

  useEffect(() => {
    getCounselor({
      variables: {
        argumentsInput: {
          is_direct: true,
        },
      },
    }).then(res => {
      if (
        res.data &&
        res.data.getCalendarLegend &&
        res.data.getCalendarLegend.code == 200
      ) {
        if (
          res.data.getCalendarLegend.data &&
          res.data.getCalendarLegend.data.calendars.length > 0
        ) {
          setCalenderID(res.data.getCalendarLegend.data.calendars[0].id)
        }
      }
    })
  }, [])
  const getApprovalApi = () => {
    if (calenderID) {
      getApprovalREquired({
        variables: {
          argumentsInput: {
            page: 1,
            limit: 5,
            calendar_id: calenderID,
            search: "",
            sort_dir: "DESC",
            sort: "id",
            direct_client: true,
            is_expiry_date: true,
          },
        },
      })
    }
  }

  useEffect(() => {
    getApprovalApi()
  }, [calenderID])

  useEffect(() => {
    if (
      getApprovalREquiredResponse.data &&
      getApprovalREquiredResponse.data.getPlans.code == 200
    ) {
      setApprovedStat(getApprovalREquiredResponse.data.getPlans.data.totalCount)
      setData(getApprovalREquiredResponse.data.getPlans.data.plans)
    }
  }, [getApprovalREquiredResponse])

  return (
    <Card body className="light-green-bg-card-table">
      <CardBody className="p-0">
        <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
          <h4 className={classNames("card-title", classes.cardTitle)}>
            {`Approval Required`}
            <Tooltip
              className={style.tooltip}
              placement="right"
              title={
                <div className="invoice-list-fsize-14">
                  {" "}
                  This will display list of clients whose expiry date crossed
                  and their used sessions are greater than charged session .
                </div>
              }
              arrow
            >
              <i
                className={`uil ul-bg-dark  uil-info-circle ${style.circleinfo}`}
              ></i>
            </Tooltip>
          </h4>
          <div>[{approvedStat}]</div>
        </div>
        <div
          className={classNames(
            "table-responsive table-custom-redesign",
            classes.appointmentTable
          )}
        >
          <Table
            data={data}
            isPagination={false}
            columns={columns}
            noDataIndication={"No Data Found"}
            isBordereded={false}
            isStriped={false}
            isHover={false}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default ApprovalRequired
