import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { convertLocaltoUTC, downloadPDF } from "../../../../functions/functions"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  GET_INVOICE_NUMBER,
  GET_PREVIEW_DATA,
  GET_PREVIEW_INVOICE_NUMBER,
  UPDATE_INVOICE_NUMBER,
} from "../gql/gql"
import { toast } from "react-toastify"
import moment from "moment"
import { setExportedInvoices } from "../../../../store/actions"
import cloudIcon from "../../../../assets/images/dashboard-icons/cloud-download.png"
import { Button } from "reactstrap"

function MultipleExportedInvoice(props) {
  const dispatch = useDispatch()

  const {
    rowValue,
    row,
    index,
    field,
    setPreviewTabData,
    handleShowPreviewTab,
    startDate,
    endDate,
    setIsLoader,
  } = props
  const selectedClientAppointment = useSelector(
    state => state.invoicingManagement.selectedClientAppointments
  )
  const clientAppointmentsData = useSelector(
    state => state.invoicingManagement.clientAppointments
  )
  const [isEnabled, setIsEnabled] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [allApi, setAllApi] = useState([])
  useEffect(() => {
    const currentObj = selectedClientAppointment.find(n => n.id == row.id)
    if (currentObj) {
      const parent = clientAppointmentsData.filter(n => n.id == currentObj.id)
      if (currentObj.selectedList.length > 0) {
        setIsEnabled(true)
        let array = []
        if (currentObj && currentObj.id) {
          currentObj.selectedList.forEach(element => {
            if (
              parent &&
              parent[0] &&
              parent[0].data &&
              parent[0].data[element]
            ) {
              array.push({
                payment_id: parseInt(currentObj.id),
                calendar_id: parseInt(parent[0].data[element].calendar_id),
                user_id: parseInt(parent[0].data[element].client_id),
                appointment_type_id: parent[0].data[element].id,
              })
            }
          })
          setAllApi(array)
        }
        setSelectedList([...currentObj.selectedList])
      } else {
        setIsEnabled(false)
        setSelectedList([])
      }
    }
  }, [selectedClientAppointment])
  const [getPreviewData, PreviewDataResponse] = useLazyQuery(GET_PREVIEW_DATA, {
    fetchPolicy: "no-cache",
  })
  const [updateInvoiceNumber, invoiceNumberResponse] = useMutation(
    UPDATE_INVOICE_NUMBER
  )
  const [getInvoiceNumber, InvoiceNumberResponse] = useLazyQuery(
    GET_PREVIEW_INVOICE_NUMBER,
    { fetchPolicy: "no-cache" }
  )

  const handleExportAllApi = async () => {
    toast.info("Generating ZIP, Please wait for some time")
    setIsLoader(true)
    if (allApi.length > 0) {
      let allApiParams = []
      allApi.map((element, index) => {
        allApiParams.push({
          start_date: startDate
            ? convertLocaltoUTC(startDate, "YYYY-MM-DD HH:mm:ssZ", true)
            : null,
          end_date: endDate
            ? convertLocaltoUTC(endDate, "YYYY-MM-DD HH:mm:ssZ", false, true)
            : null,
          payment_id: parseInt(element.payment_id),
          calendar_id: parseInt(element.calendar_id),
          user_id: parseInt(element.user_id),
        })
      })
      let url = `${process.env.REACT_APP_API_ENDPOINT}/invoicing-management/getAppointmentTypeWithAmount`
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(allApiParams),
      }
      let allExportResponse = await fetch(url, requestOptions)
        .then(res => {
          if (res.status == "201") {
            return res.json()
          } else {
            setIsLoader(false)
          }
        })
        .then(reviewDataResponse => {
          let downlodpdfRequest = []
          if (reviewDataResponse.length > 0) {
            getInvoiceNumber().then(InvoiceNumberResponse => {
              if (
                InvoiceNumberResponse.data &&
                InvoiceNumberResponse.data.getInvoiceNumber &&
                InvoiceNumberResponse.data.getInvoiceNumber.status &&
                InvoiceNumberResponse.data.getInvoiceNumber.code === 200
              ) {
                let updatedInvoiceNumber =
                  InvoiceNumberResponse.data.getInvoiceNumber.data
                    .invoice_Number
                reviewDataResponse.map((previewData, InvoiceiIndex) => {
                  const therapistName =
                    previewData.Invoicing_array.length > 0
                      ? previewData.Invoicing_array[0].calendar
                      : ""
                  const therapistEmail =
                    previewData.Invoicing_array.length > 0
                      ? previewData.Invoicing_array[0].email
                      : ""
                  let total_rate = 0
                  let total_gst = 0
                  let total_amount = 0
                  let updatedPreviewData = previewData.Invoicing_array.map(
                    element => {
                      total_rate += parseFloat(element.price)
                      total_gst += parseFloat(element.gst)
                      total_amount += parseFloat(element.total)

                      let client = {
                        first_name: element.first_name,
                        last_name: element.last_name,
                      }
                      let payment_type = {
                        name: element.payment_type.name,
                        id: element.payment_type.id,
                      }
                      element.client = {
                        first_name: element.first_name,
                        last_name: element.last_name,
                      }
                      element.gst = parseFloat(element.gst)
                      return {
                        ...element,
                        ...{ client: client },
                        ...{ payment_type: payment_type },
                      }
                    }
                  )
                  let pdf_name = ""
                  if (
                    previewData.Invoicing_array.length > 0 &&
                    previewData.Invoicing_array[0].client.first_name &&
                    previewData.Invoicing_array[0].client.last_name
                  ) {
                    pdf_name = `Invoice-${
                      parseInt(InvoiceiIndex) +
                      1 +
                      parseInt(updatedInvoiceNumber)
                    }${
                      previewData.claim_Number
                        ? `-${previewData.claim_Number}`
                        : ""
                    }-${previewData.Invoicing_array[0].client.first_name}-${
                      previewData.Invoicing_array[0].client.last_name
                    }.pdf`
                  } else if (previewData.Invoicing_array[0].client.name) {
                    let arr = previewData.Invoicing_array[0].client.name
                    pdf_name = `Invoice-${parseInt(InvoiceiIndex) + 1}${
                      previewData.claim_Number
                        ? `-${previewData.claim_Number}`
                        : ""
                    }-${previewData.Invoicing_array[0].client.name}}.pdf`
                  }
                  const margedData = {
                    name: therapistName,
                    filename: pdf_name,
                    email: therapistEmail,
                    total_sessions: previewData.Invoicing_array.length,
                    total_billed:
                      parseInt(previewData.total_billed) +
                      previewData.Invoicing_array.length,
                    is_invoice: true,
                    invoice_number:
                      parseInt(InvoiceiIndex) +
                      1 +
                      parseInt(updatedInvoiceNumber),
                    total_rate: total_rate.toFixed(2),
                    total_Amount: total_amount.toFixed(2),
                    total_gst_Amount: total_gst.toFixed(2),
                    claim_Number: previewData.claim_Number,
                    claim_number: previewData.claim_Number,
                    appointments: updatedPreviewData,
                  }

                  // add export data
                  const exportedData = {
                    invoice_number:
                      parseInt(InvoiceiIndex) +
                      1 +
                      parseInt(updatedInvoiceNumber),
                    claim_Number:
                      previewData.claim_Number || previewData.claim_number,
                    claim_number:
                      previewData.claim_number || previewData.claim_Number,
                    invoice_date: moment(),
                    therapist_name:
                      previewData.Invoicing_array.length > 0
                        ? previewData.Invoicing_array[0].calendar
                        : "",
                    client_name:
                      previewData &&
                      previewData?.Invoicing_array &&
                      previewData?.Invoicing_array?.length > 0
                        ? previewData?.Invoicing_array[0].client &&
                          previewData?.Invoicing_array[0].client.name
                          ? previewData?.Invoicing_array[0].client.name
                          : `${previewData?.Invoicing_array[0].client.first_name} ${previewData?.Invoicing_array[0].client.last_name}`
                        : "",
                    total: parseFloat(total_amount).toFixed(2),
                    number_of_appointment: previewData.Invoicing_array.length,
                    calendar_id:
                      previewData.Invoicing_array.length > 0
                        ? parseInt(previewData.Invoicing_array[0].calendar_id)
                        : "",
                  }
                  dispatch(setExportedInvoices(exportedData))

                  return downlodpdfRequest.push(margedData)
                })

                let url = `${process.env.REACT_APP_API_ENDPOINT}/mail/multiplePdf`
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined


                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

                  },
                  body: JSON.stringify({ data: downlodpdfRequest }),
                }
                let pdfResponse = fetch(url, requestOptions)
                  .then(res => {
                    if (res.status == "201") {
                      return res.json()
                    } else {
                      setIsLoader(false)
                    }
                  })
                  .then(res => {
                    if (res && res.Location) {
                      downloadPDF(res.Location, "Invoices", ".zip")
                      updateInvoiceNumber({
                        variables: {
                          argumentsInput: {
                            invoice_Number:
                              parseInt(row.appointmentcount) +
                              parseInt(updatedInvoiceNumber),
                          },
                        },
                      }).then(res => {
                        setIsLoader(false)
                      })
                    } else {
                      setIsLoader(false)
                    }
                  })
              } else {
                setIsLoader(false)
              }
            })
          }
        })
    }
  }
  const [getInvoiceData, invoiceDataResponse] = useLazyQuery(
    GET_INVOICE_NUMBER,
    { fetchPolicy: "no-cache" }
  )
  let downloadDialogTriggered = false
  function getInvoiceDataFun(
    totalBilled,
    margedData,
    payment_id,
    calendar_id,
    user_id
  ) {
    getInvoiceData({
      variables: {
        argumentsInput: {
          calendar_id: parseInt(calendar_id),
          client_id: parseInt(user_id),
          payment_type_id: parseInt(payment_id),
          total_billed: totalBilled,
        },
      },
    }).then(() => {
      let url = `${process.env.REACT_APP_API_ENDPOINT}/mail/receiptPdf`
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(margedData),
      }
      fetch(url, requestOptions)
        .then(res => {
          if (res.status == "201") {
            return res.json()
          }
        })
        .then(res => {
          downloadPDF(res.data.Location, res.data.Key)
        })
    })
  }
  return (
    <Button
      type="button"
      className={`btn ${
        isEnabled ? "btn-primary" : "disabled"
      } text-capitalize`}
      disabled={!isEnabled}
      onClick={handleExportAllApi}
    >
      <img src={cloudIcon} /> Export All
    </Button>
  )
}
export default MultipleExportedInvoice
