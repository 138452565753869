import React, { useCallback, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Row,
} from "reactstrap"
import Table from "../../components/Common/Table"
import { checkPermissions, sortCaret } from "../../functions/functions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  DELETE_PERMISSION,
  GET_USER_PERMISSION_LIST,
} from "./gql/PermissionGql"
import debounce from "lodash.debounce"
import { Link, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"

const UserPermissionTables = () => {
  let history = useHistory()
  const [isSpinner, setIsSpinner] = useState(false)
  const [switch3, setswitch3] = useState(true)
  const [totalCount, setTotalCount] = useState(20)
  const [userPermission, setUserPermission] = useState([])
  const params = new URLSearchParams(window.location.search)
  const pageNumber = params.get("page")
  const [state, setState] = useState({
    page: pageNumber ? parseInt(pageNumber) : 1,
    data: userPermission,
    sizePerPage: 20,
  })
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteRecordSlug, setDeleteRecordSlug] = useState({
    user_id: null,
  })
  const [inProgress, setInProgress] = useState(false)
  const userData = useSelector(state => state.Login?.user?.user)

  const handleEditClick = row => {
    const encodedId = btoa(row.id);
    history.push(`/permission/manage/user/${encodedId}/${row.name}`)
  }

  const [getUserPermissionList, listResponse] = useLazyQuery(
    GET_USER_PERMISSION_LIST,
    { fetchPolicy: "no-cache" }
  )

  function getUserPermissionListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder
  ) {
    getUserPermissionList({
      variables: {
        argumentsInput: {
          page: page,
          limit: sizePerPage,
          search: searchText,
          sort: sortField,
          sort_dir: sortOrder,
        },
      },
    })
  }

  React.useEffect(() => {
    if (
      userData?.user_type !== "admin" &&
      !checkPermissions("user_permission_list")
    ) {
      window.location.href = "/404"
    }
    getUserPermissionListFun(state.page, state.sizePerPage, "", null, null)
  }, [])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getUserPermissionList &&
      listResponse.data.getUserPermissionList.status
    ) {
      setIsSpinner(false)
      setUserPermission(listResponse.data.getUserPermissionList.data.response)
      setTotalCount(listResponse.data.getUserPermissionList.data.totalCount)
      setState({ data: listResponse.data.getUserPermissionList.data.response })
    } else if (listResponse.data) {
      setIsSpinner(false)
      setUserPermission()
      setTotalCount(0)
      setState({ data: [] })
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="d-flex justify-content-center">
        {(checkPermissions("user_permission_edit") ||
          userData?.user_type === "admin") && (
          <a
            className="px-2 waves-effect text-success waves-light me-2 mb-1"
            onClick={() => handleEditClick(row)}
          >
            <i className="mdi mdi-pencil font-size-18" />
          </a>
        )}
        {(checkPermissions("user_permission_delete") ||
          userData?.user_type === "admin") && (
          <a
            className="waves-effect text-danger waves-light me-2 mb-1"
            onClick={() => openDeleteModel(row.id)}
          >
            <i className="mdi mdi-delete font-size-18" />
          </a>
        )}
      </div>
    )
  }

  const columns = [
    {
      text: "ID",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "User Name",
      dataField: "name",
      width: 270,
      sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "center",
      align: "center",
      formatter: actionEvent,
    },
  ]

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p>{rowIndex + 1}</p>
      </>
    )
  }

  const delaySearch = useCallback(
    debounce(({ page, sizePerPage, searchText, sortField, sortOrder }) => {
      getUserPermissionListFun(
        page,
        sizePerPage,
        searchText,
        sortField,
        sortOrder
      )
    }, 1500)
  )

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    // props.history.push(`games?page=${page}`)
    setIsSpinner(true)
    if (type === "search") {
      delaySearch({ page, sizePerPage, searchText, sortField, sortOrder })
    } else {
      getUserPermissionListFun(
        page,
        sizePerPage,
        searchText,
        sortField,
        sortOrder
      )
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  // ********************** Delete Email Template ************/
  const openDeleteModel = async id => {
    setModalDelete(!modalDelete)
    setDeleteRecordSlug({
      user_id: id,
    })
  }

  function deleteToggle() {
    setModalDelete(!modalDelete)
  }

  const handleDelete = async () => {
    try {
      setInProgress(true)
      await deleteMutation({
        variables: {
          permissionInput: {
            user_id: deleteRecordSlug.user_id,
          },
        },
      })
    } catch (e) {
      setInProgress(false)
    }
    setDeleteRecordSlug({
      user_id: null,
    })
    deleteToggle()
  }

  const [deleteMutation, deleteResponse] = useMutation(DELETE_PERMISSION)
  React.useEffect(() => {
    setInProgress(false)
    if (
      deleteResponse?.data &&
      deleteResponse?.data?.deleteUserPermission?.status
    ) {
      toast.success(deleteResponse?.data?.deleteUserPermission?.message)
      getUserPermissionListFun(state.page, state.sizePerPage, "", null, null)
    } else {
      if (deleteResponse?.data && deleteResponse?.data?.deleteUserPermission) {
        toast.error(deleteResponse?.data?.deleteUserPermission?.message)
        getUserPermissionListFun(state.page, state.sizePerPage, "", null, null)
      }
    }
  }, [deleteResponse.data])

  if (userData?.user_type === "admin") {
    columns.splice(2, 0, {
      text: "Agency Name",
      dataField: "agency_name",
      width: 270,
      sortCaret: sortCaret,
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="User Permission"
            breadcrumbItem="User permission list"
          />

          <Row>
            <Col className="col-12">
              <Card>
                {(checkPermissions("user_permission_add") ||
                  userData?.user_type === "admin") && (
                  <CardHeader className="d-flex justify-content-between align-items-center bg-white">
                    <CardTitle className="mb-0">
                      <Link to="/create-permission/user">
                        <Button className="border text-white bg-info waves-effect waves-light">
                          <i className="uil uil-plus"></i> Create User
                          Permission
                        </Button>
                      </Link>
                    </CardTitle>
                  </CardHeader>
                )}
                <CardBody>
                  <Table
                    data={state.data}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setModalDelete(false)
                    setDeleteRecordSlug({ user_id: null })
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => handleDelete()}
                >
                  Yes, delete it
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default UserPermissionTables
