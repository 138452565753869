import React, { useEffect, useState } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { GET_CONPOSITION_STATUS, GET_RECORDING_BY_APPOINTMENT } from "./gql/Gql"
import { useLazyQuery } from "@apollo/client"
import Loader from "../../components/Common/Loader"

const ViewRecordingModal = ({
  composionData,
  setComposionData,
  viewStatusModal,
  viewStatus,
  appointmentID,
}) => {
  const [loading, setLoading] = useState(false)
  const [getCompositionStatus, getCompositionStatusRes] = useLazyQuery(
    GET_CONPOSITION_STATUS,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [compositionCheck, compositionCheckRes] = useLazyQuery(
    GET_CONPOSITION_STATUS,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getRecordingsByAppointmentId, getRecordingsByAppointmentIdRes] =
    useLazyQuery(GET_RECORDING_BY_APPOINTMENT, {
      fetchPolicy: "no-cache",
    })

  const composionCheck = (cId) => {
    setLoading(true)
    compositionCheck({
      variables: {
        compositionId: cId?.composition_sid,
      },
    })
  }

  const getRecordingsById = () => {
    getRecordingsByAppointmentId({
      variables: {
        argumentsInput: {
          id: appointmentID,
        },
      },
    })
  }
  const checkStatus = cId => {
    if (cId) {
      if (cId && cId?.composition_sid) {
        setLoading(true)
        getCompositionStatus({
          variables: {
            compositionId: cId?.composition_sid,
          },
        })
      }
    } 
  }

  useEffect(() => {
    if (viewStatusModal) {
      getRecordingsById()
    }
  }, [viewStatusModal])

  useEffect(() => {
    if (
      (getRecordingsByAppointmentIdRes?.data &&
        getRecordingsByAppointmentIdRes?.data?.getRecordingsByAppointmentId
          ?.code === 200,
      getRecordingsByAppointmentIdRes?.data?.getRecordingsByAppointmentId
        ?.status)
    ) {
      setComposionData(
        getRecordingsByAppointmentIdRes?.data?.getRecordingsByAppointmentId
          ?.data?.appointment?.recording
      )
    }
  }, [getRecordingsByAppointmentIdRes?.data])

  useEffect(() => {
    if (
      compositionCheckRes?.data &&
      compositionCheckRes?.data?.getCompositionStatus?.code === 200 &&
      compositionCheckRes?.data?.getCompositionStatus?.status
    ) {
      setLoading(false)
      getRecordingsById()
    }
  }, [compositionCheckRes?.data])
  useEffect(() => {
    if (
      getCompositionStatusRes?.data &&
      getCompositionStatusRes?.data?.getCompositionStatus?.code === 200 &&
      getCompositionStatusRes?.data?.getCompositionStatus?.status
    ) {
      setLoading(false)
      let url =
        getCompositionStatusRes?.data?.getCompositionStatus?.data?.presigned_url
      if (url) {
        window.open(url)
      }
    }
  }, [getCompositionStatusRes?.data])

  const [loadingIndex, setLoadingIndex] = useState(null)
  return (
    <Modal
      isOpen={viewStatusModal}
      centered={true}
      toggle={() => {
        viewStatus()
      }}
    >
      <ModalHeader
        toggle={() => {
          viewStatus()
        }}
        tag="h4"
      >
        Recording Status
      </ModalHeader>
      <ModalBody>
        <Row>
          {!composionData?.length > 0 ? (
            <p>No recording found!</p>
          ) : (
            <>
              {composionData?.map((elem, ind) => {
                const reverseIndex = composionData.length - ind
                return (
                  <>
                    <Row className="text-capitalize font-weight-bold d-flex align-items-center my-2">
                      <Col className="col-4">
                        <p className="p-0 m-0">Recording {reverseIndex}: </p>
                      </Col>
                      <Col className="col-8">
                        {loading && loadingIndex === ind ? (
                          <div className="d-flex justify-content-start">
                            <Loader />
                          </div>
                        ) : elem?.composition[0]?.status?.toLowerCase() ===
                          "completed" ? (
                          <>
                            <i
                              className="fas fa-download text-primary cursor-pointer fa-2x mx-2"
                              onClick={() => {
                                setLoadingIndex(ind)
                                checkStatus(elem?.composition[0])
                              }}
                            ></i>
                          </>
                        ) : (
                          <>
                            <span className="text-capitalize ">
                              {elem?.composition[0]?.status}
                            </span>
                            <button
                              className="btn btn-primary mx-3 p-2 "
                              onClick={() => {
                                setLoadingIndex(ind)
                                composionCheck(elem?.composition[0])
                              }}
                            >
                              Recheck Status
                            </button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                )
              })}
            </>
          )}
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ViewRecordingModal
