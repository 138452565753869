import React, { useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import { useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import "react-date-picker/dist/DatePicker.css"
import "react-calendar/dist/Calendar.css"
import moment from "moment"
import { Button } from "reactstrap"

function ExtraToolkit(props) {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    onSearch,
    selectedClient,
    handleClear,
    setIsSpinner,
    selectedClientValue,
  } = props
  const userData = useSelector(state => state.Login?.user?.user)

  const handleSearchClick = () => {
    onSearch()
  }

  const handleClose = () => {
    setIsSpinner(true)
    setTimeout(() => {
      handleClear()
    }, 1500)
  }

  return (
    <div className="d-flex gap-2 mt-xs-2 align-items-stretch">
      {userData.role.id === 1 && (
        <>
          <DatePicker
            selected={startDate}
            onChange={value => setStartDate(value)}
            dateFormat="dd-MMM-yyyy"
            placeholderText="DD-MM-YYYY"
            className={`form-control pointer ${
              selectedClientValue && !startDate ? "redBoarder" : " "
            }`}
            customInput={
              <input type="text" id="validationCustom01" placeholder="Date" />
            }
          />
          <DatePicker
            selected={endDate}
            onChange={value => setEndDate(value)}
            dateFormat="dd-MMM-yyyy"
            placeholderText="DD-MM-YYYY"
            className={`form-control pointer ${
              selectedClientValue && !endDate ? "redBoarder" : " "
            }`}
            customInput={
              <input type="text" id="validationCustom01" placeholder="Date" />
            }
          />
        </>
      )}
      <Button
        type="button"
        className="btn btn-primary text-capitalize m-0 p-0 px-3 "
        onClick={handleSearchClick}
        disabled={
          userData.role.id === 1
            ? !(startDate && endDate)
              ? true
              : false
            : !selectedClient
            ? true
            : false
        }
      >
        Search
      </Button>
      <Button
        type="button"
        className="btn btn-primary text-capitalize m-0 p-0 px-3"
        disabled={
          userData.role.id === 1
            ? !(startDate && endDate)
              ? true
              : false
            : !selectedClient
            ? true
            : false
        }
        onClick={handleClose}
      >
        <i className="mdi mdi-close  m-0 p-0"></i>
      </Button>
    </div>
  )
}

export default ExtraToolkit
