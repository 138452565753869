import React, { useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import { capitalizeFirstLetter } from "../../functions/functions"
import { GET_USER_ROLE } from "../User/gql/userGql"
import { useLazyQuery } from "@apollo/client"
import edit from "../../assets/images/dashboard-icons/edit.svg"
import { useHistory } from "react-router-dom"

const UserPermission = () => {
  const [isSpinner, setIsSpinner] = useState(false)
  const [users, setUsers] = useState([])
  const history = useHistory()
  const [state, setState] = useState({
    page: 1,
    data: users,
    sizePerPage: 20,
    sortBy: "id",
    sortOrder: "DESC",
  })
  const [totalCount, setTotalCount] = useState(20)

  const [getUserRoleList, listResponse] = useLazyQuery(GET_USER_ROLE, {
    fetchPolicy: "no-cache",
  })

  function getUserRoleListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder
  ) {
    getUserRoleList({
      variables: {
        argumentsInput: {
          page: page,
          limit: sizePerPage || 20,
          search: searchText || "",
          sort: sortField || "id" ,
          sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        },
      },
    })
  }
  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse?.data?.getRoles &&
      listResponse?.data?.getRoles?.code === 200 && 
      listResponse?.data?.getRoles?.data?.roles
    ) {
      setIsSpinner(false)
      setUsers(listResponse?.data?.getRoles?.data?.roles)
      setTotalCount(listResponse?.data?.getRoles?.data?.totalCount)
      setState(s => ({ ...s, data: listResponse.data.getRoles.data.roles }))
    } else if (
      listResponse.data &&
      listResponse.data.getRoles &&
      !listResponse.data.getRoles.status &&
      listResponse.data.getRoles.code !== 200
    ) {
      setIsSpinner(false)
      setUsers()
      setTotalCount(0)
      setState(s => ({ ...s, data: [] }))
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  React.useEffect(() => {
    getUserRoleListFun(
      state.page,
      state.sizePerPage,
      "",
      state.sortBy,
      state.sortOrder
    )
  }, [])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    getUserRoleListFun( page,
      sizePerPage,
      searchText,
      sortField,
      sortOrder)
  }

  const columns = [
    {
      text: "#",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "Name",
      dataField: "role_name",
      width: 270,
      sort: true,
      formatter: cell => capitalizeFirstLetter(cell),
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
    },
  ]

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="p-0 m-0">
          {rowIndex + 1 + state.sizePerPage * (state.page - 1)}
        </p>
      </>
    )
  }

  const handleEditClick = row => {
    const encodedId = btoa(row?.id);
    history.push(`/user-permission/create/${encodedId}`)
  }

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-primary cursor-pointer"
            onClick={() => handleEditClick(row)}
          >
            <img src={edit} />
          </button>
        </div>
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center mgbottom-appointment-list-50">
            <div className="p-2">
              <h4 className="card-title-main">Permission List</h4>
              <h5 className="card-para-main">List of all the User role.</h5>
            </div>
          </div>
        </div>
        <Row className="mg-top-default-ui">
          <Col className="col-12">
            <Card className="light-green-bg-card-table">
              <CardBody className="table-custom-redesign">
                <Table
                  data={state.data}
                  page={state.page}
                  sizePerPage={state.sizePerPage}
                  totalSize={totalCount}
                  onTableChange={handleTableChange}
                  columns={columns}
                  noDataIndication={"No Data Found"}
                  loading={isSpinner}
                  isSearch={true}
                  isHover={false}
                  isStriped={false}
                  isBordereded={false}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default UserPermission
