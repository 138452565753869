import { gql } from "@apollo/client"
export const GET_PAYMENT_OPTIONS = gql`
  query GetPaymentType {
    getPaymentType {
      code
      message
      status
      data {
        paymentTypes {
          id
          name
          is_direct
        }
      }
    }
  }
`
