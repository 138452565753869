import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Row,
  Col,
  Button,
} from "reactstrap"
import { useLazyQuery } from "@apollo/client"
import { toast } from "react-toastify"
import Gleap from "gleap"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { GET_COUNSELORS, GET_USER_ORG } from "./gql/profileGql"
import { loginUser } from "../../../store/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  let history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)

  const [modalDelete, setModalDelete] = useState(false)
  function deleteToggle() {
    setModalDelete(!modalDelete)
  }
  const dispatch = useDispatch()

  function logout() {
    Gleap.clearIdentity()
    dispatch(loginUser(undefined))
    localStorage.removeItem("token")
    localStorage.removeItem("userPermission")
    localStorage.removeItem("is_password_reset")
    localStorage.removeItem("authUser")
    localStorage.removeItem("multiOrg")
    localStorage.removeItem("accountData")
    history.push("/login")
  }

  //************************** Counselor list ***************************** */

  const [counsellorId, setCounsellorId] = useState(null)

  const [getCounselorList, counselorsResponse] = useLazyQuery(GET_COUNSELORS, {
    fetchPolicy: "no-cache",
  })

  const [getUserOrg, getUserOrgRes] = useLazyQuery(GET_USER_ORG, {
    fetchPolicy: "no-cache",
  })

  function getCounselorListFun() {
    getCounselorList({
      variables: {
        argumentsInput: {
          is_direct: true,
        },
      },
    })
  }

  function getUserORgFun(id) {
    getUserOrg({
      variables: {
        argumentsInput: {
          user_id: id,
        },
      },
    })
  }

  React.useEffect(() => {
    if (userData && userData.role.id == 2) {
      getCounselorListFun()
    }
  }, [])

  React.useEffect(() => {
    if (
      counselorsResponse.data &&
      counselorsResponse.data.getCalendarLegend &&
      counselorsResponse.data.getCalendarLegend.status &&
      counselorsResponse.data.getCalendarLegend.code === 200
    ) {
      if (counselorsResponse.data.getCalendarLegend.data) {
        counselorsResponse.data.getCalendarLegend.data.calendars.map(n => {
          setCounsellorId(n.id)
        })
      }
    } else if (
      counselorsResponse.data &&
      counselorsResponse.data.getCalendarLegend &&
      counselorsResponse.data.getCalendarLegend.status &&
      counselorsResponse.data.getCalendarLegend.code !== 200
    ) {
      setCounsellorId(null)
    } else {
      setCounsellorId(null)
    }
  }, [counselorsResponse.data])

  function handleClickProfileMenu() {
    const encodedId = btoa(counsellorId)
    history.push(`/profile/${encodedId}`)
  }

  function handleAccountLink() {
    history.push(`/linkAccount`)
  }
  function handleCardDetails() {
    history.push(`/card-details`)
  }

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null

  function handleEditOrganization() {
    history.push(`/organizations/create/${orgData?.organization_identifier}`)
  }

  useEffect(() => {
    if (userData && userData?.id) {
      getUserORgFun(userData?.id)
    }
  }, [])

  const [multiOrg, setMultiOrg] = useState([])

  useEffect(() => {
    if (
      getUserOrgRes?.data?.getUserOrganizations &&
      getUserOrgRes?.data?.getUserOrganizations?.data?.user?.organizations
    ) {
      const orgData =
        getUserOrgRes?.data?.getUserOrganizations?.data?.user?.organizations
      const currentDomain = window.location.origin + "/"

      const orgList = orgData
        .map(item => ({
          name: item.organization.name,
          domain: item.organization.domain,
          favicon: item.organization.favicon,
          id: item.organization.id,
          token:item?.token
        }))
        .filter(item => item.domain !== currentDomain)
      setMultiOrg(orgList)
    }
  }, [getUserOrgRes?.data])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className=" d-inline-block d-xl-inline-block ms-1 fw-medium text-capitalize font-size-15">
            {userData?.first_name && userData.last_name
              ? `${userData?.first_name} ${userData.last_name}`
              : ""}
          </span>{" "}
          <i className="uil-angle-down d-xl-inline-block pm-fsize-24px"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="div">
            {userData?.first_name && (
              <h5 className="text-capitalize">{userData?.first_name + " " + userData?.last_name}</h5>
            )}
            <p className="menu-item-16px ">{userData?.email}</p>
          </DropdownItem>
          <div className="dropdown-divider" />
          {userData.role.id === 2 && (
            <>
              <a
                onClick={handleClickProfileMenu}
                className="dropdown-item cp menu-item-16px "
              >
                <i className="uil uil-user-circle  font-size-18 align-middle me-1 text-muted"></i>
                <span>Profile</span>
              </a>
              <div className="dropdown-divider" />
            </>
          )}
          {userData.role.id === 1 && (
            <>
              <a
                onClick={handleAccountLink}
                className="dropdown-item cp menu-item-16px "
              >
                <i className="uil uil-link  font-size-18 align-middle me-2 text-muted"></i>
                <span>Account Details</span>
              </a>
              <div className="dropdown-divider" />
            </>
          )}
                    {userData.role.id === 1 && orgData?.id && (
            <>
              <a
                onClick={handleEditOrganization}
                className="dropdown-item cp menu-item-16px "
              >
                <i className="uil uil-edit  font-size-18 align-middle me-1 text-muted"></i>
                <span className="mx-1">Edit Organization</span>
              </a>
              <div className="dropdown-divider" />
            </>
          )}
            {multiOrg.length > 0 &&
              multiOrg.map((elem, index) => (
                <a
                  href={elem.domain}
                  className="dropdown-item cp menu-item-16px border"
                  key={index}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    data-dz-thumbnail=""
                    height="20"
                    className="multiOrgImage rounded"
                    alt={elem?.name}
                    src={elem?.favicon ? elem?.favicon : "favicon.png"}
                  />
                  <span className="mx-2">{elem?.name}</span>
                </a>
              ))}
          {/* {userData.role.id !== 3 && (
            <>
              <Link
                to="/change-password"
                className="dropdown-item menu-item-16px "
              >
                <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
                <span>Change password</span>
              </Link>
              <div className="dropdown-divider" />
            </>
          )} */}
          {userData.role.id === 3 && (
            <>
              <a
                onClick={handleCardDetails}
                className="dropdown-item cp menu-item-16px "
              >
                <i className="uil uil-link  font-size-18 align-middle me-2 text-muted"></i>
                <span>Card Details</span>
              </a>
              <div className="dropdown-divider" />
            </>
          )}
          {userData
          .role.role_name == "Client" && (
            <>
              <Link
                to="/edit-profile"
                className="dropdown-item menu-item-16px "
              >
                <i className="uil uil-user font-size-18 align-middle me-1 "></i>
                <span>Edit Profile</span>
              </Link>
              <div className="dropdown-divider" />
            </>
          )}

          <a
            onClick={() => setModalDelete(true)}
            className="dropdown-item cp menu-item-16px "
          >
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 "></i>
            <span>Log out</span>
          </a>
        </DropdownMenu>
      </Dropdown>
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
        backdrop="static"
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure you want to log out?</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setModalDelete(false)
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  No
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => logout()}
                >
                  Yes, Log out
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(connect(mapStatetoProps, {})(ProfileMenu))
