import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import moment from "moment"
import DatePicker from "react-datepicker"
import useStyles from "../styles"
import UpComingAppointment from "./upcoming-appointment"
import NotPaid from "./not-paid"
import classNames from "classnames"
import AnalyticsChart from "./analytics-chart"
import PieChart from "../Charts/pie-chart"
import Location from "./location"
import AppointmentStats from "./appointment-stats"
import TotalClient from "./total-client"
import PartiallyPaid from "./partially-paid"
import ApprovalRequired from "./approval-required"
import MiniWidget from "./mini-widget"
import AppointmentCard from "./appointment-card"
import CustomSelect from "../../../components/CustomSelect"
import { useLazyQuery } from "@apollo/client"
import { GET_DASHBOARD_ANALYTICS } from "./gql/gql"
import Loader from "../../../components/Common/Loader"
import BillableChart from "./billable"
import TotalClients from "./TotalClient"

function CounselorDashboard() {
  const classes = useStyles()
  const [endDate, setEndDate] = useState("")
  const [startDate, setstartDate] = useState("")
  const [value, setValue] = useState(2)
  const [approvedStat, setApprovedStat] = useState(0)
  const [dashboardData, setDashboardData] = useState({})
  const [partiallyPaidCount, setpartiallyPaidCount] = useState(0)
  const [noPaidCount, setNoPaidCount] = useState(0)
  const [isLoader, setIsLoader] = useState(true)
  const [filterDate, setFilterDate] = useState({
    start_date: null,
    end_date: null,
  })

  //************************** select ***************************** */

  const data = [
    {
      id: 2,
      name: "Monthly",
      value: moment(endDate).startOf("month").toDate(),
    },
    {
      id: 3,
      name: "Yearly",
      value: moment(endDate).startOf("year").toDate(),
    },
  ]

  //************************** Date Filter ***************************** */

  const onDateChange = data => {
    setValue(data.id)
    setstartDate("")
    setstartDate("")
  }

  const handleDateClick = () => {
    setIsLoader(true)
    getDashboardList()
  }

  useEffect(() => {
    if (value == 2 && startDate) {
      setFilterDate({
        start_date: moment(startDate)
          .startOf("month")
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ"),
        end_date: moment(startDate)
          .endOf("month")
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ"),
        month: parseInt(moment(startDate).endOf("month").format("MM")),
        year: moment(startDate).endOf("month").format("YYYY"),
      })
    } else if (value == 3 && endDate) {
      setFilterDate({
        start_date: moment(endDate)
          .startOf("year")
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ"),
        end_date: moment(endDate)
          .endOf("year")
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ"),
        month: null,
        year: moment(endDate).endOf("month").format("YYYY"),
      })
    } else {
      setFilterDate({
        start_date: "",
        end_date: "",
      })
    }
  }, [startDate, endDate])

  //************************** Dashboard Data ***************************** */

  const [getDashboardAnalytics, dashboardAnalyticsResponse] = useLazyQuery(
    GET_DASHBOARD_ANALYTICS,
    {
      fetchPolicy: "no-cache",
    }
  )

  const getDashboardList = () => {
    getDashboardAnalytics({
      variables: {
        dashboardInput: filterDate,
      },
    })
  }

  useEffect(() => {
    getDashboardList()
  }, [])

  useEffect(() => {
    if (
      dashboardAnalyticsResponse &&
      dashboardAnalyticsResponse.data &&
      dashboardAnalyticsResponse.data.CounselordashboardGraph.status &&
      dashboardAnalyticsResponse.data.CounselordashboardGraph.code === 200
    ) {
      setTimeout(() => {
        setIsLoader(false)
      }, 1000)
      setDashboardData(
        dashboardAnalyticsResponse.data.CounselordashboardGraph.data
      )
    } else if (
      dashboardAnalyticsResponse &&
      dashboardAnalyticsResponse.data &&
      dashboardAnalyticsResponse.data.CounselordashboardGraph.code !== 200
    ) {
      setDashboardData({})
      setIsLoader(false)
    } else {
      setDashboardData({})
      setIsLoader(true)
    }
  }, [dashboardAnalyticsResponse.data])

  //************************** Loading Data ***************************** */

  const callMainApi = status => {
    getDashboardList()
    setIsLoader(status)
  }

  //************************** Data count ***************************** */

  const handlePartiallyPaidCount = count => {
    setpartiallyPaidCount(count)
  }

  const handlePaymentPendingCount = count => {
    setNoPaidCount(count)
  }
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null

  return (
    <React.Fragment>
      {isLoader && (
        <div className="counselor-dashboard-div-abs">
          <div className="counselor-dashboard-div-fixed">
            <Loader />
          </div>
        </div>
      )}
      <Row>
        <Col sm={12} md={3} lg={3} xl={6} className="align-self-center">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className={`card-title-main minwidth-110-cd`}>Dashboard</h4>
          </div>
        </Col>
        <Col sm={12} md={9} lg={9} xl={6}>
          <div
            className={classNames(
              "page-title-box gap-2",
              classes.filterContainer
            )}
          >
            <div
              className={`${classes.selectFilter} custom-date-picker-style `}
            >
              <CustomSelect
                className="input default-search-input"
                onChange={value => onDateChange(value)}
                placeholder="Select..."
                value={value}
                options={data}
              />
            </div>
            <div
              className={classNames(
                "d-flex custom-date-picker-style ",
                classes.datePickerFilter
              )}
            >
              {value == 2 ? (
                <DatePicker
                  selected={startDate}
                  className="form-control default-search-input pointer"
                  onChange={value => setstartDate(value)}
                  dateFormat="MMM-yyyy"
                  placeholderText="MMM-YYYY"
                  maxDate={moment().toDate()}
                  showMonthYearPicker={value == 2 ? true : false}
                  customInput={
                    <input
                      type="text"
                      id="validationCustom01"
                      placeholder="Date"
                    />
                  }
                />
              ) : (
                <DatePicker
                  selected={endDate}
                  className="form-control default-search-input pointer"
                  dateFormat="yyyy"
                  onChange={value => setEndDate(value)}
                  maxDate={moment().toDate()}
                  placeholderText="YYYY"
                  showYearPicker={value == 3 ? true : false}
                  customInput={
                    <input
                      type="text"
                      id="validationCustom01"
                      placeholder="Date"
                    />
                  }
                />
              )}
              <button
                type="button"
                className="btn btn-primary btn-handleDateClick-mgleft-15"
                onClick={handleDateClick}
              >
                <i className="mdi mdi-magnify"></i>
              </button>
            </div>
          </div>
        </Col>
      </Row>

      <Row className={classes.normalFontsize}>
        <Col md={12} xl={7} xxl={8} className="d-flex align-items-stretch">
          <PieChart
            title={"Appointment Booked Most"}
            series={[10, 55, 41, 17]}
            height={"500"}
            refreshData={getDashboardList}
            label={[
              "Individual Counselling",
              "Couple Counselling",
              "Family Counselling",
              "Extended Counselling",
            ]}
            colors={[
              "#FF6361",
              "#93C3B3",
              "#BC5090",
              "#FFA600",
              "#3fd4a2",
              "#3fd441",
              "#694741",
              "#3f80d4",
              "#3f41d4",
              "#673fd4",
              "#d43f82",
              "#5d6941",
              "#41694e",
              "#41695d",
              "#415369",
              "#424169",
              "#694141",
              "#e3b6bf",
              "#d1be8e",
              "#d1968e",
              "#bbd18e",
              "#8ed1b3",
              "#8e9ad1",
            ]}
            donutSize={"50%"}
            loading={dashboardAnalyticsResponse.loading}
            data={dashboardAnalyticsResponse.data}
            setIsLoader={setIsLoader}
          />
        </Col>
        <Col md={12} xl={5} xxl={4} className="d-flex align-items-stretch">
          <AppointmentCard />
        </Col>
        <Col md={12} xl={12} xxl={12} className="d-flex align-items-stretch">
          <UpComingAppointment setIsLoader={setIsLoader} />
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <AnalyticsChart
            title={"Appointment scheduled"}
            setIsLoader={setIsLoader}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <BillableChart title={"Billable"} setIsLoader={setIsLoader} />
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <TotalClients setIsLoader={setIsLoader} />
        </Col>
      </Row>

      {orgId === 1 && <><Row>
        <Col
          sm={12}
          md={5}
          xl={5}
          xxl={5}
          className="d-flex align-items-stretch"
        >
          <ApprovalRequired
            setIsLoader={setIsLoader}
            approvedStat={approvedStat}
            setApprovedStat={setApprovedStat}
          />
        </Col>
        <Col
          sm={12}
          md={7}
          xl={7}
          xxl={7}
          className="d-flex align-items-stretch"
        >
          <Location
            dashboardData={dashboardData}
            callMainApi={callMainApi}
            isLoader={isLoader}
          />
        </Col>
      </Row>

      <Row className={classes.normalFontsize}>
        <Col xl={12} xxl={12}>
          <NotPaid
            handlePaymentPendingCount={handlePaymentPendingCount}
            setIsLoader={setIsLoader}
          />
        </Col>
      </Row>

      <Row className={classes.normalFontsize}>
        <Col xl={12} xxl={12} className="d-flex align-items-stretch">
          <PartiallyPaid
            handlePartiallyPaidCount={handlePartiallyPaidCount}
            setIsLoader={setIsLoader}
          />
        </Col>
      </Row>

      <Row className={classes.normalFontsize}>
        <Col md={8} xl={8}>
          <TotalClient dashboardData={dashboardData} />
        </Col>
        <Col md={4} xl={4} className="d-flex align-items-stretch">
          <AppointmentStats
            dashboardData={dashboardData}
            callMainApi={callMainApi}
            isLoader={isLoader}
            partiallyPaidCount={partiallyPaidCount}
            noPaidCount={noPaidCount}
            approvedStat={approvedStat}
          />
        </Col>
      </Row>
      </>}
    </React.Fragment>
  )
}

export default CounselorDashboard
