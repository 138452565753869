import PropTypes from "prop-types";
import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";

const NonAuthLayout = (props) => {
  useEffect(() => {
    const title = props.location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    // document.title = currentage + " | No Fear Counselling";
    // document.title =  "No Fear Counselling";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/favicon.png'
  }, [props.location.pathname]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
