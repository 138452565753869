import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
import calendar from "./calendar/reducer"

//invoicing-management
import invoicingManagement from "./invoicing-management/reducer"

//invoicing-management
import receiptManagement from "./receipt-management/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//chat
import chat from "./chat/reducer"

//invoices
import invoices from "./invoices/reducer"

//contacts
import contacts from "./contacts/reducer"
import counsellorPay from "./counsellorPay/reducer"
import paymentTypeManage from "./payment-types/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  ecommerce,
  invoices,
  contacts,
  invoicingManagement,
  receiptManagement,
  counsellorPay,
  paymentTypeManage
})

export default rootReducer