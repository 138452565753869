import React from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import AsyncSelect from "react-select/async"
const BlockOftimeModal = ({
  isModalOpen,
  handleEModalToggle,
  validationCounsellor,
  bookedAppointmentMessage,
  handleDateChange,
  selectedDate,
  selectedEndDate,
  handleMultiTimeSlot,
  endTime,
  startTime,
  bookedAppointmentList,
  isVisible,
  counsellorList,
  handleChangeCounsellors,
  selectedOptions,
  fetchOptions,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={handleEModalToggle}
      size="lg"
      backdrop="static"
    >
      <ModalBody>
        <div className="w-100 text-end">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h4> Block off time</h4>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                onClick={handleEModalToggle}
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-75 mt-3">
          <p className="defaultText">
            Going on vacation? Taking some time off? Block off time on your
            calendar to prevent clients from booking appointments (existing
            appointments will remain on your calendar).
          </p>
        </div>

        <p className="defaultText">Block Off time</p>

        <Row>
          <Col md="5 mt-3">
            <div className="mb-2 form-custom-white-inputs">
              <Label className="form-label">Select calendar </Label>
              <AsyncSelect
                className={`custom-date-picker-style-bradius`}
                name="selectCounsellors"
                placeholder="Select calendar"
                defaultOptions={counsellorList}
                loadOptions={fetchOptions}
                onChange={handleChangeCounsellors}
                value={selectedOptions}
                formatGroupLabel={data => formatGroupLabel(data, validation)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "8px",
                    minHeight: "41px",
                    border: "1px solid #ced4da",
                  }),
                }}
                closeMenuOnSelect={true}
              />
            </div>
          </Col>
        </Row>

        <div className="row">
          <div className="col-md-12">
            <div className="mb-3 form-custom-white-inputs ">
              <div className="row my-3">
                <div className="col-12 col-md-5">
                <Label for="datePicker">From date:</Label>
                  <Input
                    type="date"
                    id="datePicker"
                    className="form-control default-search-input d-flex justify-content-between"
                    value={selectedDate}
                    onChange={e =>
                      handleDateChange(e.target.value, "startDate")
                    }
                    min={new Date().toISOString().split("T")[0]} // Disable past dates
                  />
                </div>
                <div className="col-12 col-md-5">
                  <Label for="datePicker">To date:</Label>
                  <Input
                    type="date"
                    id="datePicker"
                    className="form-control default-search-input d-flex justify-content-between"
                    value={selectedEndDate}
                    onChange={e => handleDateChange(e.target.value, "endDate")}
                    min={new Date().toISOString().split("T")[0]} // Disable past dates
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">

          <div className="col-12 col-md-5">
          <Label className="font-weight-bold defaultText">From time:</Label>
            <Input
              className="form-control default-search-input w-100 w-md-50"
              name={`blocktime_start_time`}
              placeholder="From"
              type="time"
              onChange={e => handleMultiTimeSlot(e, "start_time")}
              value={startTime}
            />
          </div>

          <div className="col-12 col-md-5">
          <Label className="font-weight-bold defaultText">To time:</Label>
            <Input
              className="form-control default-search-input w-100 w-md-50"
              name={`blocktime_end_time`}
              placeholder="To"
              type="time"
              onChange={e => handleMultiTimeSlot(e, "end_time")}
              value={endTime}
            />
          </div>
        </div>

        <Row>
          <Col md={12} className="my-3 text-left">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!isVisible}
              onClick={validationCounsellor}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-inactive mx-3"
              onClick={handleEModalToggle}
            >
              Cancel
            </button>
          </Col>
        </Row>
        {bookedAppointmentList?.length > 0 && (
          <Row>
            <Col md="12" className="mt-4">
              <p className="h5 mx-3 mb-4 text-danger">
                {bookedAppointmentMessage} :
              </p>
              {bookedAppointmentList.map((elem, index) => {
                const { time, date, end_time, type } = elem.appointment
                return (
                  <div className="px-3 my-2 border border-dark p-2" key={index}>
                    <span className="font-weight-bold">{`${type} : `}</span>
                    <span className="">{`${date},  ${time} to ${end_time}`}</span>
                  </div>
                )
              })}
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  )
}
export default BlockOftimeModal
