import React, { useCallback, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Modal,
  Row,
} from "reactstrap"
import Table from "../../components/Common/Table"
import { checkPermissions, sortCaret } from "../../functions/functions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  DELETE_PERMISSION,
  GET_ROLE_PERMISSION_LIST,
} from "./gql/PermissionGql"
import debounce from "lodash.debounce"
import { Link, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import style from "./style.module.scss"

const RolePermissionTables = () => {
  let history = useHistory()
  const [isSpinner, setIsSpinner] = useState(false)
  const [totalCount, setTotalCount] = useState(20)
  const [userPermission, setUserPermission] = useState([])
  const params = new URLSearchParams(window.location.search)
  const pageNumber = params.get("page")
  const [state, setState] = useState({
    page: pageNumber ? parseInt(pageNumber) : 1,
    data: userPermission,
    sizePerPage: 20,
  })
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteRecordSlug, setDeleteRecordSlug] = useState({
    role_id: null,
  })
  const [inProgress, setInProgress] = useState(false)
  const userData = useSelector(state => state.Login?.user?.user)

  const handleEditClick = row => {
    const encodedId = btoa(row.id);
    history.push(`/permission/manage/role/${encodedId}/${row.role_name}`)
  }

  const [getRolePermissionList, listResponse] = useLazyQuery(
    GET_ROLE_PERMISSION_LIST,
    { fetchPolicy: "no-cache" }
  )

  function getRolePermissionListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder
  ) {
    getRolePermissionList({
      variables: {
        argumentsInput: {
          page: page,
          limit: sizePerPage,
          search: searchText,
          sort: sortField,
          sort_dir: sortOrder,
        },
      },
    })
  }

  React.useEffect(() => {
    getRolePermissionListFun(state.page, state.sizePerPage, "", null, null)
  }, [])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getRolePermissionList &&
      listResponse.data.getRolePermissionList.status
    ) {
      setIsSpinner(false)
      setUserPermission(listResponse.data.getRolePermissionList.data.response)
      setTotalCount(listResponse.data.getRolePermissionList.data.totalCount)
      setState({ data: listResponse.data.getRolePermissionList.data.response })
    } else if (listResponse.data) {
      setIsSpinner(false)
      setUserPermission()
      setTotalCount(0)
      setState({ data: [] })
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="d-flex justify-content-left">
        <a
          onClick={() => handleEditClick(row)}
          className="edit-btn cursor-pointer"
        >
          Edit
        </a>
        <a
          onClick={() => openDeleteModel(row.id)}
          className="px-3 text-danger delete-btn cursor-pointer"
        >
          Delete
        </a>
      </div>
    )
  }

  const columns = [
    {
      text: "S no.",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "Role Name",
      dataField: "role_name",
      width: 270,
      sortCaret: sortCaret,
    },

    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
    },
  ]

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p>{rowIndex + 1}</p>
      </>
    )
  }

  const delaySearch = useCallback(
    debounce(({ page, sizePerPage, searchText, sortField, sortOrder }) => {
      getRolePermissionListFun(
        page,
        sizePerPage,
        searchText,
        sortField,
        sortOrder
      )
    }, 1500)
  )

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setIsSpinner(true)
    if (type === "search") {
      delaySearch({ page, sizePerPage, searchText, sortField, sortOrder })
    } else {
      getRolePermissionListFun(
        page,
        sizePerPage,
        searchText,
        sortField,
        sortOrder
      )
    }
  }

  // ********************** Delete Email Template ************/
  const openDeleteModel = async id => {
    setModalDelete(!modalDelete)
    setDeleteRecordSlug({
      role_id: id,
    })
  }

  function deleteToggle() {
    setModalDelete(!modalDelete)
  }

  const handleDelete = async () => {
    try {
      setInProgress(true)
      await deleteMutation({
        variables: {
          permissionInput: {
            role_id: deleteRecordSlug.role_id,
          },
        },
      })
    } catch (e) {
      setInProgress(false)
    }
    setDeleteRecordSlug({
      role_id: null,
    })
    deleteToggle()
  }

  const [deleteMutation, deleteResponse] = useMutation(DELETE_PERMISSION)
  React.useEffect(() => {
    setInProgress(false)
    if (
      deleteResponse?.data &&
      deleteResponse?.data?.deleteUserPermission?.status
    ) {
      toast.success(deleteResponse?.data?.deleteUserPermission?.message)
      getRolePermissionListFun(state.page, state.sizePerPage, "", null, null)
    } else {
      if (deleteResponse?.data && deleteResponse?.data?.deleteUserPermission) {
        toast.error(deleteResponse?.data?.deleteUserPermission?.message)
        getRolePermissionListFun(state.page, state.sizePerPage, "", null, null)
      }
    }
  }, [deleteResponse.data])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <div className="p-2">
              <h4>Role Permissions</h4>
              <h5 className={style.subtitlemenu}>
                List of all the Role Permissions created for assigning the
                access rights for the admin panel users.
              </h5>
            </div>
            <div className="p-2">
              <Link to="/group-permissions/assign" className="btn btn-primary">
                <i className="bx bx-plus-medical mx-2" />
                <span>Add Role Permissions</span>
              </Link>
            </div>
          </div>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Table
                    data={state.data}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    isSearch={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setModalDelete(false)
                    setDeleteRecordSlug({ role_id: null })
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => handleDelete()}
                >
                  Yes, delete it
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default RolePermissionTables
