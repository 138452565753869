import React, { useCallback, useEffect, useRef, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  Button,
} from "reactstrap"
import Table from "../../../components/Common/Table"

//Import Components

import CustomSelect from "../../../components/CustomSelect"
import { connect } from "react-redux"
import useStyles from "./styles"
import { GET_ORGANIZATION } from "../gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_ROLES } from "../../Permission/gql/PermissionGql"
import {
  capitalizeFirstLetter,
  formatPhoneNumber,
  convertUTCToLocal,
  meetingType,
  sortCaret,
} from "../../../functions/functions"
import moment from "moment"
import {
  DELETE_USER,
  GET_CLIENT_APPOINTMENT,
  GET_USERS,
} from "../../User/gql/userGql"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import edit from "../../../assets/images/dashboard-icons/edit.svg"
import deleteBin from "../../../assets/images/dashboard-icons/deleteBin.svg"
import { debounce } from "lodash"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AppointmentInfo from "../../Appointments/AppointmentInfo"

const OrganizationUsers = ({ user }) => {
  const classes = useStyles()
  const [isSpinner, setIsSpinner] = useState(false)
  const [organizationsList, setOrganizationsList] = useState([])
  const [roleList, setRoleList] = useState([])
  let history = useHistory()
  const [viewApp, setViewApp] = useState(false)
  const [clientId, setClientId] = useState()
  const [modalDelete, setModalDelete] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [users, setUsers] = useState([])
  const location = useLocation()
  const [totalCount, setTotalCount] = useState(20)
  const [deleteRecord, setDeleteRecord] = useState({
    id: null,
    orgId: undefined,
  })
  const [appointmentData, setAppointmentData] = useState([])
  const [totalAppCount, setTotalAppCount] = useState(20)
  const modalRef = useRef()
  const [modal, setModal] = useState(false)
  const [multiOrgDelete, setMultiOrgDelete] = useState([])
  const [multimodalDelete, setMultiModalDelete] = useState(false)
  const [isAppSpinner, setIsAppSpinner] = useState(false)

  const [state, setState] = useState({
    page: 1,
    data: users,
    sizePerPage: 20,
    sortBy: "id",
    search: undefined,
    sortOrder: "DESC",
    roleId: undefined,
    orgSlug: undefined,
  })

  const [getUserList, listResponse] = useLazyQuery(GET_USERS, {
    fetchPolicy: "no-cache",
  })

  const [getClientAppointment, clientAppointmentRes] = useLazyQuery(
    GET_CLIENT_APPOINTMENT,
    {
      fetchPolicy: "no-cache",
    }
  )

  const modalStyle = {
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "hidden",
  }

  function getUserListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
    organizationId,
    roleId,
    is_all
  ) {
    const argumentsInput = {
      page: page,
      limit: sizePerPage,
      search: searchText,
      sort: sortField,
      sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
      organization_id: organizationId,
      role_id: roleId,
      is_all: organizationId ? undefined : true,
    }

    getUserList({
      variables: {
        argumentsInput: argumentsInput,
      },
    })
      .then(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          searchText,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
      .catch(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          searchText,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
  }

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getUsers &&
      listResponse.data.getUsers.status &&
      listResponse.data.getUsers.code === 200
    ) {
      setIsSpinner(false)
      const updatedUsers = listResponse.data.getUsers.data.users.map(user => ({
        ...user,
        role: user.role !== null ? user.role : { id: 3, role_name: "Client" },
      }))
      setUsers(updatedUsers)
      setTotalCount(listResponse.data.getUsers.data.totalCount)
      setState(s => ({ ...s, data: updatedUsers }))
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])
  const selectedOrg = Number(new URLSearchParams(location.search).get("org"))
  const selectedRole = Number(new URLSearchParams(location.search).get("role"))

  useEffect(() => {
    const selectedPage = new URLSearchParams(location.search).get("page")
    const selectedOrg = Number(new URLSearchParams(location.search).get("org"))
    const selectedRole = Number(
      new URLSearchParams(location.search).get("role")
    )
    let page = state.page
    if (selectedPage) {
      page = Number(selectedPage)
    }

    getUserListFun(
      page,
      state.sizePerPage,
      "",
      state.sortBy,
      state.sortOrder,
      selectedOrg,
      selectedRole,
      true
    )
  }, [])

  const getClientAppointmentFun = cId => {
    getClientAppointment({
      variables: {
        argumentsInput: {
          page: appState.page,
          limit: appState.sizePerPage,
          client_id: cId?.id,
          search: "",
          sort: "utc_datetime",
          sort_dir: "DESC",
          start_date: "",
          end_date: "",
          organization_id: state?.orgSlug?.id ? state?.orgSlug?.id : null,
          device_type:"web",
        },
      },
    })
  }
  const openUserCreate = () => {
    history.push(`/multiUsers/create`)
  }
  const handleViewAppointment = client => {
    setViewApp(!viewApp)
    if (client) {
      setIsAppSpinner(true)
      setClientId(client)
      getClientAppointmentFun(client)
    } else {
      setClientId(undefined)
    }
  }

  useEffect(() => {
    if (
      clientAppointmentRes?.data &&
      clientAppointmentRes?.data?.getAppointments &&
      clientAppointmentRes?.data?.getAppointments?.status &&
      clientAppointmentRes?.data?.getAppointments?.code === 200
    ) {
      setIsAppSpinner(false)
      if (clientAppointmentRes?.data?.getAppointments?.data?.appointments) {
        const appointments = clientAppointmentRes.data.getAppointments.data.appointments_arr?.map((elem)=>{
          return elem?.appointment
        })
        setAppointmentData(
          appointments
        )
      }
      if (clientAppointmentRes?.data?.getAppointments?.data?.totalCount) {
        setTotalAppCount(
          clientAppointmentRes.data.getAppointments.data.totalCount
        )
      }
      modalRef?.current?.scroll({ top: 0, behavior: "smooth" })
    }
  }, [clientAppointmentRes.data])

  function appointmentActionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="">
        <Button
          className="btn btn-primary cursor-pointer"
          onClick={() => setModal(row)}
        >
          View
        </Button>
      </div>
    )
  }

  function viewAppointment(cell, row, rowIndex, formatExtraData) {
    const allRole = row.organizations.map(elem => {
      return elem?.role?.id
    })
    return allRole.includes(3) ? (
      <div className="d-flex align-items-center">
        <button
          className="btn btn-primary cursor-pointer"
          onClick={() => handleViewAppointment(row)}
        >
          View
        </button>
      </div>
    ) : (
      <>-</>
    )
  }

  const [showOrg, setShowOrg] = useState(false)
  const [allOrgFavicon, setAllOrgFavicon] = useState([])
  const showAllOrg = allOrgs => {
    const faviconsAndNames = allOrgs?.map(item => ({
      name: item?.organization?.name,
      favicon: item?.organization?.favicon,
      roleName: item?.role?.role_name,
      created_at: item?.created_at,
    }))
    setAllOrgFavicon(faviconsAndNames)
    setShowOrg(!showOrg)
  }

  function orgImage(cell, row, rowIndex, formatExtraData) {
    const imageCount = row.organizations.length

    const generateImageGalleryStyles = imageCount => `
  .image-gallery-container {
    position: relative;
    width: 100%;
    height: 100%;
    display:flex;
  }

  .orgImage {
    height: 35px;
    width: 35px;
    border-radius:50%;
    background: white;
    border:1px solid gray;
    transition: transform 0.3s ease;
    position: relative;
    z-index: 1;
  }

  ${[...Array(imageCount).keys()]
    .map(
      index => `
      .image-${index + 1} {
        transform: translateX(${-35 * index}px);
        z-index: ${index}; 
      }
    `
    )
    .join("\n")}

  .moreText{
    transform: translateX(-35px);
  }
`

    const styles = generateImageGalleryStyles(imageCount)

    return (
      <div className="d-flex align-items-center">
        <style>{styles}</style>

        <div className="image-gallery-container">
          {row.organizations.slice(0, 2).map((elem, index) => (
            <img
              key={index}
              src={elem?.organization?.favicon || "favicon.png"}
              className={`orgImage mx-2 cursor-pointer image-${index + 1}`}
              alt={`Organization ${index + 1}`}
              onClick={() => showAllOrg(row?.organizations)}
            />
          ))}
          {row.organizations.length > 2 && (
            <span
              className="mt-1 cursor-pointer moreText"
              onClick={() => showAllOrg(row?.organizations)}
            >
              +{row.organizations.length - 2}
            </span>
          )}
        </div>
      </div>
    )
  }

  function meetingTypeFun(value, row) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {value && value?.call_type ? meetingType(value?.call_type) : ""}
      </div>
    )
  }

  const AppointmentColumn = [
    {
      text: "ID",
      dataField: "id",
      style: {
        minWidth: 50,
      },
    },
    {
      text: "Client Name",
      dataField: "first_name",
      formatter: fullNameFun,
      style: {
        minWidth: 130,
      },
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      text: "Counsellor Name",
      dataField: "therapist_calendar",
      style: {
        minWidth: 130,
      },
      headerStyle: {
        cursor: "pointer",
      },
      formatter: (cell, row) => {
        return <p className="m-0">{row?.therapist_calendar?.name}</p>
      },
    },
    {
      text: "Organization",
      dataField: "organization",
      style: {
        minWidth: 50,
      },
      formatter: (cell, row) => {
        return (
          <img
            key={row.id}
            src={row?.organization?.favicon || "favicon.png"}
            className={`orgImage mx-2 cursor-pointer`}
            alt={`Organization`}
          />
        )
      },
    },
    {
      text: "Meeting Type",
      dataField: "type_of_appointment",
      style: {
        minWidth: 100,
      },
      formatter: meetingTypeFun,
    },
    {
      text: "Date",
      dataField: "utc_datetime",
      style: {
        minWidth: 190,
      },
      formatter: (cell, row) => {
        const date = convertUTCToLocal(row.utc_datetime)
        return (
          <p className="m-0">{moment(date).format("DD-MMM-yyyy hh:mm A")}</p>
        )
      },
      headerStyle: {
        cursor: "pointer",
      },
      sort: true,
    },
    {
      text: "Duration (in mins)",
      dataField: "duration",
      style: {
        minWidth: 50,
      },
      sortCaret: sortCaret,
    },
    {
      dataField: "canceled",
      text: "Status",
      sort: false,
      headerAlign: "left",
      align: "left",
      style: {
        minWidth: 100,
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row?.canceled ? <span>Cancelled</span> : <span>Active</span>
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "center",
      align: "center",
      style: {
        minWidth: 100,
      },
      formatter: appointmentActionEvent,
    },
  ]

  const handleEditClick = row => {
    const encodedId = btoa(row.id)
    history.push(`/multiUsers/${encodedId}/edit`)
  }

  function deleteToggle() {
    setModalDelete(!modalDelete)
  }

  const openDeleteModel = async (id, data) => {
    if (data?.organizations.length > 1) {
      setMultiOrgDelete(data?.organizations)
      setMultiModalDelete(!multimodalDelete)
      setDeleteRecord({
        id,
        orgId: undefined,
      })
    } else {
      setMultiOrgDelete([])
      setModalDelete(!modalDelete)
      setDeleteRecord({
        id,
        orgId: data?.organizations[0]?.organization?.id,
      })
    }
  }

  const handleUserDelete = oId => {
    setDeleteRecord(prevData => ({
      ...prevData,
      orgId: oId,
    }))
    setModalDelete(!modalDelete)
    setMultiModalDelete(!multimodalDelete)
  }

  const handleDelete = async () => {
    try {
      setInProgress(true)
      await deleteMutation({
        variables: {
          argumentsInput: {
            id: deleteRecord.id,
            organization_id: deleteRecord.orgId
              ? deleteRecord.orgId
              : undefined,
          },
        },
      })
    } catch (e) {
      setInProgress(false)
      console.log(e)
    }
    setDeleteRecord({
      id: null,
    })
    deleteToggle()
  }
  const [deleteMutation, deleteResponse] = useMutation(DELETE_USER)
  useEffect(() => {
    if (deleteResponse?.data && deleteResponse?.data?.deleteUser?.status) {
      toast.success(deleteResponse?.data?.deleteUser?.message)
      getUserListFun(
        state.page,
        state.sizePerPage,
        "",
        state.sortBy,
        state.sortOrder,
        state.orgSlug?.key,
        state.roleId?.key
      )
      setInProgress(false)
    } else {
      if (deleteResponse?.data && deleteResponse?.data?.deleteUser) {
        toast.error(deleteResponse?.data?.deleteUser?.message)
        getUserListFun(
          state.page,
          state.sizePerPage,
          "",
          state.sortBy,
          state.sortOrder,
          state.orgSlug?.key,
          state.roleId?.key
        )
      }
      setInProgress(false)
    }
  }, [deleteResponse.data])

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="d-flex align-items-center">
        <button
          className="btn btn-primary cursor-pointer"
          onClick={() => handleEditClick(row)}
        >
          <img src={edit} />
        </button>
        <a
          onClick={() => openDeleteModel(row.id, row)}
          className="px-3 text-danger delete-btn cursor-pointer"
        >
          <img src={deleteBin} />
        </a>
      </div>
    )
  }

  function dateFormatter(value, row, index, field) {
    return value === null ? " " : moment(value).format("DD-MMM-yyyy")
  }

  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="m-0">
          {rowIndex + 1 + state.sizePerPage * (state.page - 1)}
        </p>
      </>
    )
  }

  const [infoModal, setInfoModal] = useState(false)

  const handleNameClick = row => {
    setInfoModal(row)
  }

  function fullNameFun(value, row, index, field) {
    const firstName = row?.first_name ? row?.first_name : " "
    const lastName = row?.last_name ? row?.last_name : " "

    return (
      <span onClick={() => handleNameClick(row)} className="cursor-pointer">
        {capitalizeFirstLetter(firstName) +
          " " +
          capitalizeFirstLetter(lastName)}
      </span>
    )
  }

  const columns = [
    {
      text: "#",
      dataField: "id",
      width: 150,
      formatter: idFormatFun,
    },
    {
      text: "Name",
      dataField: "first_name",
      width: 270,
      formatter: fullNameFun,
      sort: true,
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      dataField: "organization",
      text: "Organization",
      sort: false,
      headerAlign: "left",
      align: "left",
      width: 70,
      style: {
        maxWidth: 130,
      },
      formatter: orgImage,
    },
    {
      text: "Email Address",
      dataField: "email",
      width: 70,
      style: {
        maxWidth: 120,
      },
    },
    {
      text: "Contact",
      dataField: "mobile_number",
      width: 100,
      formatter: cell => formatPhoneNumber(cell),
    },
    {
      dataField: "View Appointments",
      text: "Appointments",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: viewAppointment,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
    },
  ]

  const [getOrganizations] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: "no-cache",
  })

  const getOrganizationsFun = () => {
    getOrganizations({
      variables: {
        argumentsInput: {
          limit: 50,
          page: 1,
          search: "",
        },
      },
      onCompleted({ getOrganizations }) {
        if (getOrganizations?.data && getOrganizations?.data?.organizations) {
          const orgList = getOrganizations?.data?.organizations
          setOrganizationsList(orgList)
          setState(p => ({
            ...p,
            orgSlug: selectedOrg && {
              key: selectedOrg,
              id: selectedOrg,
              value: selectedOrg,
            },
            roleId: selectedRole && {
              key: selectedRole,
              id: selectedRole,
              value: selectedRole,
            },
          }))
        }
      },
    })
  }

  const organizationOption = organizationsList?.map(item => {
    return {
      key: item?.id,
      id: item?.id,
      value: item?.id,
      name: item?.name,
      label: item?.name,
    }
  })

  const [getAllRoleList] = useLazyQuery(GET_ROLES, {
    fetchPolicy: "no-cache",
  })

  const getAllRolesListFun = () => {
    getAllRoleList({
      variables: {
        argumentsInput: {
          page: 1,
          limit: 10,
          search: "",
          sort: "id",
        },
      },
      onCompleted({ getRoles }) {
        if (getRoles && getRoles.code === 200) {
          setRoleList(getRoles.data.roles)
        } else {
          setRoleList([])
        }
      },
    })
  }

  const rolesOption = roleList?.map(item => {
    return {
      key: item?.id,
      id: item?.id,
      value: item?.id,
      name: item?.role_name,
      label: item?.role_name,
    }
  })

  useEffect(() => {
    getAllRolesListFun()
    getOrganizationsFun()
  }, [])

  const changeHandler = data => {
    const field = !data?.sortField ? "id" : data?.sortField
    let currentUrl = new URL(window.location.href)

    currentUrl.searchParams.set("page", 1)
    getUserListFun(
      1,
      data?.sizePerPage,
      data?.searchText,
      field,
      data?.sortOrder,
      data?.organization_id,
      data?.role_id
    )
  }

  const delaySearch = useCallback(debounce(changeHandler, 1000), [])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setState(p => ({
      ...p,
      page,
      sizePerPage,
    }))
    let currentUrl = new URL(window.location.href)

    currentUrl.searchParams.set("page", page)
    currentUrl.searchParams.set("org", state.orgSlug?.key)
    currentUrl.searchParams.set("role", state.roleId?.key)
    window.history.replaceState({}, document.title, currentUrl.href)
    const field = sortField === null ? "id" : sortField
    setIsSpinner(true)
    if (type === "search") {
      delaySearch({ page, sizePerPage, searchText, sortField, sortOrder })
    } else {
      getUserListFun(
        page,
        sizePerPage,
        searchText,
        field,
        sortOrder,
        state?.orgSlug?.value,
        state?.roleId?.value
      )
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const [appState, setAppState] = useState({
    page: 1,
    sizePerPage: 20,
    sortBy: "utc_datetime",
    sortOrder: "DESC",
  })

  const handleAppTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setAppState(p => ({
      ...p,
      page,
      sizePerPage,
      sortOrder,
    }))

    getClientAppointment({
      variables: {
        argumentsInput: {
          page: page || 1,
          limit: sizePerPage || 20,
          client_id: clientId?.id,
          search: "",
          sort: "utc_datetime",
          sort_dir: sortOrder?.toUpperCase() || "DESC",
          start_date: "",
          end_date: "",
          device_type:"web",
          organization_id: state?.orgSlug?.id ? state?.orgSlug?.id : null,
        },
      },
    })
  }

  const handleChangeOrganization = e => {
    setState({
      ...state,
      orgSlug: e,
    })
    setIsSpinner(true)
    let currentUrl = new URL(window.location.href)
    setState(p => ({
      ...p,
      search: "",
    }))
    currentUrl.searchParams.set("page", 1)
    currentUrl.searchParams.set("org", e.key)
    window.history.replaceState({}, document.title, currentUrl.href)
    setState(p => ({
      ...p,
      page: 1,
    }))
    getUserListFun(
      1,
      state.sizePerPage,
      "",
      state.sortBy,
      state.sortOrder,
      e.id,
      state.roleId?.key
    )
  }

  const handleChangeRole = e => {
    setState({
      ...state,
      roleId: e,
    })
    setIsSpinner(true)
    let currentUrl = new URL(window.location.href)
    setState(p => ({
      ...p,
      search: "",
    }))
    currentUrl.searchParams.set("page", 1)
    currentUrl.searchParams.set("role", e.key)
    window.history.replaceState({}, document.title, currentUrl.href)
    setState(p => ({
      ...p,
      page: 1,
    }))
    getUserListFun(
      1,
      state.sizePerPage,
      "",
      state.sortBy,
      state.sortOrder,
      state.orgSlug?.key,
      e.id
    )
  }

  const onChangeSearch = e => {
    // handleSearch(e)
    const searchText = e.target.value
    const role_id = state.roleId?.key
    const organization_id = state.orgSlug?.key
    setState(p => ({
      ...p,
      search: searchText,
    }))
    const { page, sizePerPage, sortBy, sortOrder } = state
    delaySearch({
      page,
      sizePerPage,
      searchText,
      sortBy,
      sortOrder,
      organization_id,
      role_id,
    })
  }

  const handleResetFilter = () => {
    setIsSpinner(true)
    let currentUrl = new URL(window.location.href)

    currentUrl.searchParams.set("page", 1)
    currentUrl.searchParams.delete("org")
    currentUrl.searchParams.delete("role")
    window.history.replaceState({}, document.title, currentUrl.href)
    setState(p => ({
      ...p,
      search: "",
      page: 1,
      orgSlug: undefined,
      roleId: undefined,
    }))
    getUserListFun(
      1,
      state.sizePerPage,
      "",
      state.sortBy,
      state.sortOrder,
      undefined,
      undefined,
      true
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex flex-wrap-mobile-only justify-content-between">
            <div className="p-2 w-75">
              <h4 className="card-title-main">Organization Users</h4>
              <h5 className="card-para-main">
                List of all the organization users.
              </h5>
            </div>
            <div className="p-2">
              <Button
                type="button "
                className=" btn-primary appointment-list-toggle-button"
                onClick={() => openUserCreate()}
              >
                <i className="uil uil-plus"></i>
                <span className="d-inline-block">Add User</span>
              </Button>
            </div>
          </div>

          <Row className="mg-top-default-ui">
            <Col>
              <Card className="light-green-bg-card-table">
                <CardBody>
                  <Row className="d-flex align-item-center justify-content-between mb-2 mt-4 justify-content-center-mobile-only">
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={8}
                      className="align-self-center"
                    >
                      <div className="d-flex align-items-center gap-3">
                        <div className={`${classes.selectFilter}`}>
                          <input
                            className="form-control default-search-input"
                            value={state.search}
                            type="text"
                            placeholder="Search Users"
                            onChange={onChangeSearch}
                          />
                        </div>
                        <div className={`${classes.selectFilter}`}>
                          <CustomSelect
                            className="input default-search-input"
                            placeholder="Select Organization"
                            options={organizationOption}
                            onChange={handleChangeOrganization}
                            value={state?.orgSlug?.value}
                          />
                        </div>
                        <div className={`${classes.selectFilter}`}>
                          <CustomSelect
                            className="input default-search-input"
                            placeholder="Select Role"
                            options={rolesOption}
                            onChange={handleChangeRole}
                            value={state?.roleId?.value}
                          />
                        </div>

                        <label className="w-100 search-label-appointments"></label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                      <div>
                        <div className="float-end flex gap-3 align-items-stretch margin-top-10px-xs ">
                          <button
                            type="button"
                            className="btn ml-3 btn-primary btn-sm p-2"
                            onClick={handleResetFilter}
                            disabled={!state.orgSlug && !state.roleId}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-custom-redesign">
                    <Table
                      data={state.data}
                      page={state.page}
                      sizePerPage={state.sizePerPage}
                      totalSize={totalCount}
                      onTableChange={handleTableChange}
                      columns={columns}
                      noDataIndication={"No Data Found"}
                      loading={isSpinner}
                      isHover={false}
                      isStriped={false}
                      isBordereded={false}
                      location={location.pathname}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal isOpen={infoModal} size="lg">
        <ModalHeader toggle={() => setInfoModal(undefined)} tag="h4">
          User Details
        </ModalHeader>
        <ModalBody>
          <Card className="light-green-bg-card">
            <CardBody>
              <CardTitle className="h4 m-0 ">
                <h5 className="m-0 card-title-appointments">User Details</h5>
              </CardTitle>
              <hr style={!infoModal ? { marginTop: "8px" } : null} />
              <div>
                <div className="card-title-appointments mb-2">
                  {infoModal?.first_name
                    ? capitalizeFirstLetter(infoModal.first_name)
                    : " "}{" "}
                  {infoModal?.last_name
                    ? capitalizeFirstLetter(infoModal.last_name)
                    : " "}
                </div>
              </div>
              <div className={`${infoModal ? "flex-column" : null}`}>
                {infoModal?.email && (
                  <div className="d-flex gap-2 align-items-center">
                    <i className="mdi mdi-email font-size-20 color-dark-green m-0 mt-2 "></i>
                    <p className="card-para-main m-0 mt-2">{infoModal.email}</p>
                  </div>
                )}

                {infoModal?.mobile_number && (
                  <div className="d-flex gap-2 align-items-center">
                    <i className="mdi mdi-phone font-size-20 color-dark-green  m-0 mt-1"></i>
                    <p className="card-para-main m-0 mt-2">
                      {formatPhoneNumber(infoModal?.mobile_number)}
                    </p>
                  </div>
                )}
              </div>

              <div>
                {infoModal?.created_at && (
                  <div className="d-flex gap-2 align-items-center">
                    <i className="mdi mdi-calendar font-size-20 color-dark-green  m-0 mt-1"></i>
                    <p className="card-para-main m-0 mt-2">
                      {moment(infoModal?.created_at).format("DD-MMM-yyyy")}
                    </p>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={multimodalDelete}
        toggle={() => {
          setMultiModalDelete(false)
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12 my-3">
              <p className="h5 ms-1 mb-5">
                You can delete user from one organization at a time!
              </p>
              {multiOrgDelete.map(elem => {
                return (
                  <>
                    <div className="my-3 d-flex align-items-center justify-content-between border border-dark rounded p-2 ">
                      <p className="m-0 h5  d-flex align-items-center">
                        <img
                          data-dz-thumbnail=""
                          height="30"
                          className="multiOrgImage rounded me-3"
                          alt={elem?.name}
                          src={
                            elem?.organization?.favicon
                              ? elem?.organization?.favicon
                              : "favicon.png"
                          }
                        />
                        {elem.organization.name}
                      </p>
                      <img
                        src={deleteBin}
                        className="cursor-pointer"
                        onClick={() => handleUserDelete(elem.organization.id)}
                      />
                    </div>
                  </>
                )
              })}
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => {
                    setModalDelete(false)
                    setDeleteRecord({ id: null })
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!inProgress ? (
                  <Button
                    disabled={inProgress}
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => handleDelete()}
                  >
                    Yes, delete it
                  </Button>
                ) : (
                  <Button
                    className="btn btn-primary waves-effect waves-light"
                    disabled
                  >
                    <Spinner
                      type="grow"
                      className="me-1 align-middle spinner-grow-sm "
                      color="light"
                    />
                    Processing...
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        isOpen={viewApp}
        toggle={() => {
          handleViewAppointment()
        }}
        centered={true}
        size="xl"
      >
        <ModalHeader toggle={() => setViewApp(!viewApp)} tag="h4">
          Client Appointments
        </ModalHeader>
        <ModalBody>
         
          <div style={modalStyle} ref={modalRef}>
          {state?.orgSlug?.id && appointmentData[0]?.organization?.name ? <div className="alert alert-warning" role="alert">
            Showing appointments of {appointmentData[0]?.organization?.name}
          </div> : <></>}
            <Row className="">
              <Col className="col-12">
                <Card className="light-green-bg-card-table">
                  <CardBody className="table-custom-redesign">
                    <Table
                      data={appointmentData}
                      page={appState.page}
                      sizePerPage={appState.sizePerPage}
                      totalSize={totalAppCount}
                      onTableChange={handleAppTableChange}
                      columns={AppointmentColumn}
                      noDataIndication={"No Data Found"}
                      loading={isAppSpinner}
                      isHover={false}
                      isSearch={false}
                      isStriped={false}
                      isBordereded={false}
                      isViewAppointment={viewApp}
                      location={location.pathname}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} size="lg">
        <ModalHeader toggle={() => setModal(undefined)} tag="h4">
          Appointment Details
        </ModalHeader>
        <ModalBody>
          <AppointmentInfo
            id={parseInt(modal?.id)}
            isModel={modal}
            setModal={setModal}
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={showOrg} size="lg">
        <ModalHeader toggle={() => showAllOrg([])} tag="h4">
          <p className="h4 mx-3">Organizations</p>
        </ModalHeader>
        <ModalBody className="orgModalBody">
          {allOrgFavicon.map((elem, index) => (
            <div
              key={index}
              className="d-flex my-3 align-items-center border-light "
            >
              <img
                src={elem?.favicon || "favicon.png"}
                className="orgImage mx-2 cursor-pointer"
                alt={`Organization ${index + 1}`}
              />
              <Row className="d-flex w-100">
                <Col md={4} className="d-flex align-items-center">
                  <p className="text-white pt-3">-</p>
                  <h5 className="pt-2 mx-3 text-white">{elem?.name}</h5>
                </Col>
                <Col
                  md={4}
                  className="d-flex  justify-content-start align-items-center"
                >
                  <i className="uil-users-alt text-white font-weight-bold font20"></i>
                  <p className="mx-2 text-white font-weight-bold pt-3">-</p>
                  <h5 className="pt-2 mx-1 text-white font-weight-bold">
                    {elem?.roleName}
                  </h5>
                </Col>
                <Col
                  md={4}
                  className="d-flex  justify-content-start align-items-center"
                >
                  <i className="uil uil-calendar-alt text-white font-weight-bold font20"></i>
                  <p className="mx-2 text-white font-weight-bold pt-3">-</p>
                  <h5 className="pt-2 mx-1 text-white font-weight-bold">
                    {moment(elem?.created_at).format("DD-MMM-yyyy")}
                  </h5>
                </Col>
              </Row>
            </div>
          ))}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state && state.Login.user.user,
  }
}

export default connect(mapStateToProps)(OrganizationUsers)

// export default OrganizationUsers
