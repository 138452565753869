import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_LOCATION, SUBMIT_LOCATION } from "./gql/Gql"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import Loader from "../../components/Common/Loader"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { fetchApi } from "../../common/api"
import AsyncSelect from "react-select/async"
import style from "./style.module.scss"
import { getLocationName } from "../../store/actions"

const AddLocation = () => {
  let history = useHistory()
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = decodedId
  const encodedId = btoa(id);
  const dispatch = useDispatch()

  const [getLocation, getLocationList] = useLazyQuery(GET_LOCATION, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(SUBMIT_LOCATION)
  const [isSubmitted, setIsSubmited] = useState(false)
  const [areaList, setAreaList] = useState({})
  const [isLoader, setIsLoader] = useState(true)
  const [initialValues, setInitialValues] = useState({
    name: "",
  })
  const [selectedArea, setSelectedArea] = useState({
    value: "",
    label: "",
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Please enter name"),
      latitude: Yup.string().trim().required("Please enter latitude"),
      longitude: Yup.string().trim().required("Please enter longitude"),
    }),
    onSubmit: values => {
      setIsSubmited(true)
      let obj = {
        address: values.address,
        google_maps_url: values.google_maps_url,
        latitude: values.latitude,
        status: values.status ? "active" : "inactive",
        longitude: values.longitude,
        name: values.name,
      }
      if (id) {
        obj.id = parseInt(id)
      }
      if (selectedArea && selectedArea.value) {
        obj.location_group_id = selectedArea.value
      }
      submitMutation({
        variables: {
          createLocationInput: obj,
        },
      })
        .then(res => {
          toast.success(`Location successfully ${id ? "updated" : "created"}`)
          setTimeout(() => {
            if (id) {
              setIsSubmited(false)
            }else{
              history.push("/location")
            }
          }, 1500)
        })
        .catch(err => {
          toast.error("something went wrong !")
          setIsSubmited(false)
        })
    },
  })
  useEffect(() => {
    if (id) {
      setIsLoader(true)
      getLocation({
        variables: {
          getLocationInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [id])
  useEffect(() => {
    if (getLocationList.data && getLocationList.data.getLocation) {
      setInitialValues({
        ...getLocationList.data.getLocation,
        status:
          getLocationList.data.getLocation.status == "active" ? true : false,
      })
      dispatch(getLocationName(getLocationList.data.getLocation.name))
      if (
        getLocationList.data.getLocation &&
        getLocationList.data.getLocation.location_group_id
      ) {
        setSelectedArea({
          value: getLocationList.data.getLocation.location_group_id.id,
          label: getLocationList.data.getLocation.location_group_id.name,
        })
      }
      setIsLoader(false)
    } else {
      setIsLoader(false)
    }
  }, [getLocationList.data])

  let [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    totalCount: 0,
  })
  const [searchText, setSearchText] = useState("")

  const getLocationsApi = async (limit, page, search, issearch, callback) => {
    try {
      let { data, code, status } = await fetchApi(
        `locationGroup?is_all=true&limit=${limit}&page=${page}&search=${search}`,
        "GET"
      )
      if (code == 200 && status && data) {
        const { limit, page, totalCount } = data
        setState({
          page: Number(page),
          sizePerPage: Number(limit),
          totalCount,
        })
        const locationGroup = data?.locationGroup?.map((data, i) => ({
          ...data,
          index: (page - 1) * limit + i + 1,
        }))
        locationGroup.map(item => {
          item.value = item.id
          item.label = item.name
          return item
        })
        setAreaList(locationGroup)
        if (issearch) {
          callback(locationGroup)
        }
      }
    } catch (error) {
      console.log(error)
      setIsSpinner(false)
    }
  }
  useEffect(() => {
    const { sizePerPage, page } = state
    getLocationsApi(sizePerPage, page, searchText)
  }, [])
  const loadOptions = (inputSelect, callback) => {
    const { sizePerPage, page } = state
    getLocationsApi(sizePerPage, page, inputSelect, true, callback)
  }
  const handleAreaChange = value => {
    setSelectedArea({
      value: value.id,
      label: value.name,
    })
  }
  const handleToggle = e => {
    setInitialValues(prev => {
      return { ...validation.values, status: !validation.values.status }
    })
  }
  return (
    <div className="page-content">
      {isLoader ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <Row>
              <Col xs={12} md={6} className="py-3">
                <div className="flex align-item-center">
                  <i
                    className="waves-effect color-dark-green  mdi mdi-arrow-left font-size-22 mr-2"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className="mt-1 mx-2 card-title-main">
                    {" "}
                    {id ? "" : "Create New "} Location
                  </h4>
                </div>
              </Col>
              {id && (
                <Col
                  xs={12}
                  md={6}
                  className="d-flex justify-content-end align-items-center"
                >
                  <div className="d-flex justify-content-end align-items-center">
                    <Button
                      type="button "
                      className=" btn-primary appointment-list-toggle-button"
                      onClick={() => history.push(`/rooms/${encodedId}?location=${validation.values.name}`)}
                    >
                      <i className="uil uil-eye"></i>
                      <span className="mx-3">View Rooms</span>
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={6}>
              <Card className="transparent-bg-card">
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="form-custom-white-inputs">
                      <Col xl={12} md={12}>
                        <Label className="form-label">Name </Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && (
                          <div className={style.warnText}>
                            {validation.errors.name}
                          </div>
                        )}
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label">Address </Label>
                        <Input
                          name="address"
                          className="form-control"
                          placeholder="Enter Address"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address &&
                            validation.errors.address
                              ? true
                              : false
                          }
                        />
                        <small className="light-gray">
                          Number, Street, City, Province, Postal Code
                        </small>
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label">Google Map URL </Label>
                        <Input
                          name="google_maps_url"
                          className="form-control"
                          placeholder="Enter Google Map URL"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.google_maps_url || ""}
                          invalid={
                            validation.touched.google_maps_url &&
                            validation.errors.google_maps_url
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label">Latitude </Label>
                        <Input
                          name="latitude"
                          className="form-control"
                          placeholder="Enter Latitude"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.latitude || ""}
                          invalid={
                            validation.touched.latitude &&
                            validation.errors.latitude
                              ? true
                              : false
                          }
                        />
                        {validation.errors.latitude && (
                          <div className={style.warnText}>
                            {validation.errors.latitude}
                          </div>
                        )}
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label">Longitude </Label>
                        <Input
                          name="longitude"
                          className="form-control"
                          placeholder="Enter Longitude"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.longitude || ""}
                          invalid={
                            validation.touched.longitude &&
                            validation.errors.longitude
                              ? true
                              : false
                          }
                        />
                        {validation.errors.longitude && (
                          <div className={style.warnText}>
                            {validation.errors.longitude}
                          </div>
                        )}
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label">Locations </Label>
                        <AsyncSelect
                          cacheOptions
                          placeholder="Select Locations"
                          loadOptions={loadOptions}
                          defaultOptions={areaList}
                          value={selectedArea}
                          onChange={selectedOption =>
                            handleAreaChange(selectedOption)
                          }
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "8px",
                              minHeight: "41px",
                              border: "1px solid #ced4da",
                            }),
                          }}
                        />
                        <small className="defaultText">Add location to a city tab</small>
                      </Col>
                      <Col xl={12} md={12} className="mt-2">
                        <Label className="form-label" htmlFor="customSwitch2">
                          Publish
                        </Label>
                        <div className="form-check form-switch">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch2"
                            checked={validation.values.status}
                            onClick={e => handleToggle(e)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col xs={12} className="text-end">
                        <button
                          className="btn btn-inactive mx-2"
                          type="button"
                          onClick={() => {
                            history.push("/location")
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary "
                          type="submit"
                          disabled={isSubmitted}
                        >
                          {id ? "Update" : "Add"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
export default AddLocation
