import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Input, CardTitle, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import DatePicker from "react-datepicker"
import style from "./style.module.scss"
import Table from "../../components/Common/Table"

//Import Components

import useStyles from "./styles"
import CustomSelect from "../../components/CustomSelect"
import moment from "moment"
import classNames from "classnames"
import TableInput from "../../components/Common/TableInput"
import { GET_COUNSELORPAY_DATA_LIST } from "../Counselor/gql/Gql"
import { useLazyQuery } from "@apollo/client"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  textFilter,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { useHistory } from "react-router-dom"
import { convertLocaltoUTC, convertUTCToLocal, hasPermission } from "../../functions/functions"
import CustomMultiSelect from "../../components/CandidateMultiSelect"
import { useCallback } from "react"
import { debounce } from "lodash"
import { connect, useSelector, useDispatch } from "react-redux"
import { storeCounsellorPay } from "../../store/counsellorPay/actions"
import { Tooltip } from "@material-ui/core"
import PageNotFound from "../404"

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    {" "}
    Showing {from} to {to} of {size} Results
  </span>
)

const EditableTables = ({ user }) => {
  const counsellorPay = useSelector(state => {
    return state.counsellorPay && state.counsellorPay.counsellor_pay
      ? state.counsellorPay.counsellor_pay
      : {}
  })
  const dispatch = useDispatch()
  const classes = useStyles()
  const [endDate, setEndDate] = useState(moment().date(14).toDate())
  const [products, setproducts] = useState([])
  const [showInvoice, setShowInvoice] = useState(true)
  const [startDate, setstartDate] = useState(
    moment().date(15).subtract(1, "month").toDate()
  )
  const [dateRange, setDateRange] = useState({})
  const [value, setValue] = useState(1)
  const [customchk, setcustomchk] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
  const [isHovering, setIsHovering] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotalData] = useState(0)
  const [totalCount, setTotalCount] = useState(20)
  const [search, setSearch] = useState("")
  const [limit, setLimit] = useState(20)
  const [isSpinner, setIsSpinner] = useState(false)
  const [month, setMonth] = useState("")
  const [state, setState] = useState({
    page: 1,
    data: products,
    sizePerPage: 20,
    sortBy: "id",
    sortOrder: "DESC",
  })
  let options = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPage: limit,

    page: page,
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    totalSize: total,
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const [getCounselorDataList, counselorDataDetails] = useLazyQuery(
    GET_COUNSELORPAY_DATA_LIST,
    { fetchPolicy: "no-cache" }
  )
  React.useEffect(() => {
    getCounselorPayList()
    // setOptionsList(optionsList.filter(item => item.id <= moment().format("MM")))
  }, [])
  React.useEffect(() => {
    getCounselorPayList()
  }, [search, month, dateRange])

  React.useEffect(() => {
    if (
      counselorDataDetails.data &&
      counselorDataDetails.data.getCalendarsCounsellorsPay &&
      counselorDataDetails.data.getCalendarsCounsellorsPay.code === 200 &&
      counselorDataDetails.data.getCalendarsCounsellorsPay.data
    ) {
      setIsSpinner(false)
      let data = {
        ...state,
        data: counselorDataDetails.data.getCalendarsCounsellorsPay.data
          .calendars,
        start_date: startDate && startDate,
        end_date: endDate && endDate,
        page: page,
      }
      localStorage.setItem("counsellorPay", JSON.stringify(data))
      dispatch(storeCounsellorPay(data))
      setState(s => ({
        ...s,
        data: counselorDataDetails.data.getCalendarsCounsellorsPay.data
          .calendars,
      }))
      setTotalData(
        counselorDataDetails.data.getCalendarsCounsellorsPay.data.totalCount
      )
    } else if (
      counselorDataDetails.data &&
      counselorDataDetails.data.getCalendarsCounsellorsPay &&
      counselorDataDetails.data.getCalendarsCounsellorsPay.code === 404
    ) {
      setIsSpinner(false)
      setState(s => ({ ...s, data: [] }))
    } else {
      setState(s => ({ ...s, data: [] }))
      setIsSpinner(true)
      setTotalData(20)
    }
  }, [counselorDataDetails.data])

  useEffect(() => {}, [counsellorPay])

  let history = useHistory()

  const downloadPDF = (url, name) => {
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", `${name}.pdf`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  useEffect(() => {
    if(startDate){
    const selectedDate = new Date(startDate);
    const today = new Date();
    const isPastDate = selectedDate < today;
    setShowInvoice(isPastDate)
  }

  }, [startDate])
  

  const storedId = localStorage.getItem("orgId")
  const orgId = storedId ? parseInt(atob(JSON.parse(storedId))) : null

  const columns = [
    {
      dataField: "name",
      text: "Counsellor",
      editable: false,
    },
    {
      dataField: "Month",
      text: "Month",
      editable: false,
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => {
        return row.calendar_pay && row.calendar_pay.length > 0
          ? row.calendar_pay[0].month
          : "-"
      },
    },
    {
      dataField: "InvoiceRequest",
      text: "Invoice Request",
      editable: false,
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => {
        const counsellorPayArray =
          row.calendar_pay && row.calendar_pay.length > 0
            ? row.calendar_pay
            : []
        const values = Array.from(counsellorPayArray, n => n.id)
        const maxId = Math.max(...values)
        const counsellorPayObj = counsellorPayArray.find(
          option => option.id === maxId
        )
        if (row.calendar_pay && row.calendar_pay.length > 0) {
          return counsellorPayObj.is_revise_invoice &&
            counsellorPayObj.is_revise_invoice ? (
            <Tooltip
              placement="right"
              title={
                <div className="invoice-list-fsize-14">
                  Submitted for counsellor review
                </div>
              }
              arrow
            >
              <img src="/reviewed.png" className="counsellor-pay-img-24" />
            </Tooltip>
          ) : counsellorPayObj.is_change_request &&
            counsellorPayObj.is_change_request ? (
            <Tooltip
              placement="right"
              title={
                <div className="invoice-list-fsize-14">
                  Change request for invoice
                </div>
              }
              arrow
            >
              <img
                src="/request_to_change.png"
                className="counsellor-pay-img-24"
              />
            </Tooltip>
          ) : (
            "-"
          )
        } else {
          return "-"
        }
      },
    },
    orgId === 1 && {
      dataField: "download",
      text: "Download",
      editable: false,
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => {
        const counsellorPayArray =
          row.calendar_pay && row.calendar_pay.length > 0
            ? row.calendar_pay
            : []
        const values = Array.from(counsellorPayArray, n => n.id)
        const maxId = Math.max(...values)
        const counsellorPayObj = counsellorPayArray.find(
          option => option.id === maxId
        )
        return row.calendar_pay && row.calendar_pay.length > 0 ? (
          <>
            {(user.role.id === 1 ||
              counsellorPayObj?.is_admin_review) && showInvoice ? (
              <i
                onClick={() =>
                  downloadPDF(
                    counsellorPayObj.url,
                    counsellorPayObj.invoice_number
                  )
                }
                className="uil-file-download pointer"
              ></i>
            ) : "NA"}
          </>
        ) : (
          "-"
        )
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "center",
      align: "center",
      editable: false,
      formatter: (cell, row, index) => {
        const year =
          row.calendar_pay && row.calendar_pay.length > 0
            ? moment(row.invoice_start_date).year()
            : moment().year()
        const consellorPayArray =
          row.calendar_pay && row.calendar_pay.length > 0
            ? row.calendar_pay
            : []
        const startingDate = startDate
          ? startDate
          : row.calendar_pay && row.calendar_pay.length > 0
          ? moment()
              .month(consellorPayArray[0].month)
              .date(15)
              .year(year)
              .subtract(1, "month")
              .toDate()
          : moment().date(15).year(year).subtract(1, "month").toDate()
        const endingDate = endDate
          ? endDate
          : row.calendar_pay && row.calendar_pay.length > 0
          ? moment()
              .month(consellorPayArray[0].month)
              .date(14)
              .year(year)
              .toDate()
          : moment().date(14).year(year).toDate()
          const values = Array.from(consellorPayArray, n => n.id)
          const maxId = Math.max(...values)
          const counsellorPayObj = consellorPayArray.find(
            option => option.id === maxId
          )
        return (
          <>
            {(user.role.id === 1 || counsellorPayObj?.is_admin_review) && showInvoice  ? (
              <a
                onClick={() =>
                  handleInvoiceClick(row, startingDate, endingDate, index)
                }
                className="px-2 edit-btn text-center text-green cursor-pointer wieght600"
              >
                Invoice
              </a>
            ) : (
              "-"
            )}
          </>
        )
      },
    },
  ]
  const handleInvoiceClick = (row, startingDate, endingDate, index) => {
    dispatch(storeCounsellorPay({ ...state, index, id: row.id }))
    localStorage.setItem(
      "counsellorPay",
      JSON.stringify({ ...state, index, id: row.id })
    )
    const encodedId = btoa(row.id);
    history.push(
      `/counsellors/${encodedId}/invoice?start=${moment(startingDate).format(
        "YYYY-MM-DD"
      )}&end=${moment(endingDate).format("YYYY-MM-DD")}`
    )
  }

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const onDateChange = data => {
    setValue(data.id)
    setstartDate(data.value)
  }

  const handleDateClick = () => {
    setDateRange({
      start: startDate,
      end: endDate,
    })
    getCounselorPayList()
    setPage(1)
  }
  const handleclearDate = () => {
    setstartDate("")
    setEndDate("")
    setDateRange({})
    setMonth("")
    setPage(1)
  }

  const data = [
    {
      id: 1,
      name: "Weekly",
      value: moment(endDate).startOf("isoWeek").toDate(),
    },
    {
      id: 2,
      name: "Monthly",
      value: moment(endDate).startOf("month").toDate(),
    },
    {
      id: 3,
      name: "Yearly",
      value: moment(endDate).startOf("year").toDate(),
    },
  ]
  const handleMonthChange = value => {
    const startOfMonth = moment()
      .month(value.value)
      .date(15)
      .subtract(1, "month")
      .toDate()
    const endOfMonth = moment().month(value.value).date(14).toDate()
    setstartDate(startOfMonth)
    setEndDate(endOfMonth)
    setMonth(value.id)
  }

  const [optionsList, setOptionsList] = useState([
    { value: "January", lable: "January", name: "January", id: 1 },
    { value: "February", lable: "February", name: "February", id: 2 },
    { value: "March", lable: "March", name: "March", id: 3 },
    { value: "April", lable: "April", name: "April", id: 4 },
    { value: "May", lable: "May", name: "May", id: 5 },
    { value: "June", lable: "June", name: "June", id: 6 },
    { value: "July", lable: "July", name: "July", id: 7 },
    { value: "August", lable: "August", name: "August", id: 8 },
    { value: "September", lable: "September", name: "September", id: 9 },
    { value: "October", lable: "October", name: "October", id: 10 },
    { value: "November", lable: "November", name: "November", id: 11 },
    { value: "December", lable: "December", name: "December", id: 12 },
  ])

  useEffect(() => {
    const currentMonth = moment().format("MMMM")
    const selectedMonth = optionsList.find(
      option => option.value === currentMonth
    )
    setMonth(selectedMonth.id)
  }, [])

  const getCounselorPayList = (
    searchString = "",
    selectedMonth = "",
    start,
    end
  ) => {
    const selectedMonthValue = optionsList.find(option => option.id === month)
    const monethName = selectedMonthValue ? selectedMonthValue.name : ""
    let start_date =
      startDate && convertLocaltoUTC(startDate, "YYYY-MM-DD HH:mm:ssZ", true)
    let end_date =
      endDate && convertLocaltoUTC(endDate, "YYYY-MM-DD HH:mm:ssZ", false, true)
    getCounselorDataList({
      variables: {
        argumentsInput: {
          page: page,
          limit: limit,
          month: selectedMonth || monethName,
          search: searchString || search,
          start_date: start_date,
          end_date: end_date,
        },
      },
    })
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }
  const delaySearch = useCallback(debounce(handleSearch, 1000), [])
  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    setState(p => ({
      ...p,
      page,
      sizePerPage,
    }))
    setLimit(sizePerPage)
    setPage(page)
    const field = sortField === null ? "id" : sortField
    setIsSpinner(true)
    if (type === "search") {
      delaySearch({ page, sizePerPage, searchText, sortField, sortOrder })
    } else {
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }
  useEffect(() => {
    getCounselorPayList(search)
  }, [page, limit])

  const allPermission = localStorage.getItem("userPermission")
  let userPermission = allPermission ? JSON.parse(allPermission) : []
  let hasPermission = (userPermission.includes("counsellor-pay") || userPermission.includes("my-pay")) 

  return (
    <React.Fragment>
          {hasPermission ? (
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <div className="pt-2">
              <h4 className="card-title-main">Counsellor pay</h4>
              <h5 className="card-para-main">
                List of all the payments generated in given time period.
              </h5>
            </div>
          </div>

          <Row className="mg-top-default-ui">
            <Col>
              <Card className="light-green-bg-card-table">
                <CardBody>
                  <Row className="d-flex align-item-center justify-content-start mb-2 mt-4 justify-content-center-mobile-only">
                    {user.role.id == 1 && <Col
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={2}
                      xxl={2}
                      className="align-self-center mt-3 mt-sm-3 mt-lg-0"
                    >
                    
                        <div className="d-flex w-100">
                          <div className=" ml-2 form-custom-white-inputs width-100-mobile-devices w-100">
                            <Input
                              type="text"
                              className="form-control bg-[#fff] rounded "
                              placeholder="Search Counsellor"
                              onChange={e => delaySearch(e)}
                            />
                          </div>
                        </div>
                    </Col>}
                    <Col xs={12} sm={12} md={3} lg={3} xl={2} xxl={2} className="d-flex mt-3 mt-sm-3 mt-lg-0">
                      <div className="w-100">
                        <div
                          className={classNames(
                            "d-flex w-100 ",
                            classes.datePickerFilter
                          )}
                        >
                          <div className=" form-custom-white-inputs w-100 min-width150-date-picket-cpl width-100-mobile-devices margin-top-10px-xs ">
                            <CustomSelect
                              className="input "
                              placeholder="Select Month"
                              onChange={value => handleMonthChange(value)}
                              value={month}
                              options={optionsList}
                            />
                          </div>
                          </div>
                          </div>
                          </Col>
                          <Col xs={12} sm={12} md={4} lg={3} xl={4} xxl={3} className="d-flex mt-3 mt-sm-3 mt-lg-0">
                          <div className="float-end mx-1 mg-x-sm margin-top-10px-xs  search-input-search-appointments">
                            <DatePicker
                              selected={startDate}
                              className="form-control rounded  ml-5 pointer "
                              placeholderText="DD-MM-YYYY"
                              onChange={value => {
                                return setstartDate(value)
                              }}
                              dateFormat="dd-MMM-yyyy"
                              maxDate={endDate ? endDate : moment().toDate()}
                              customInput={
                                <input
                                  type="text"
                                  id="validationCustom01"
                                  placeholder="Date"
                                />
                              }
                            />
                          </div>
                          <div className="float-end mx-1 mg-x-sm margin-top-10px-xs search-input-search-appointments">
                            <DatePicker
                              selected={endDate}
                              className="form-control rounded pointer"
                              dateFormat="dd-MMM-yyyy"
                              placeholderText="DD-MM-YYYY"
                              onChange={value => setEndDate(value)}
                              minDate={startDate ? startDate : moment().toDate()}
                              customInput={
                                <input
                                  type="text"
                                  id="validationCustom01"
                                  placeholder="Date"
                                />
                              }
                            />
                          </div>
                          </Col>
                          <Col xs={12} sm={12} md={3} lg={3} xl={2} xxl={2} className="d-flex mt-3 mt-sm-3 mt-lg-0">
                          <div className="float-end flex align-items-stretch margin-top-10px-xs ">
                            <Button
                              type="button"
                              className="btn btn-primary p-0 px-3 mx-2"
                              disabled={
                                startDate == "" && endDate == "" ? true : false
                              }
                              onClick={handleDateClick}
                            >
                              Apply
                            </Button>
                            <Button
                              type="button"
                              className="btn btn-primary p-0 px-3 "
                              onClick={handleclearDate}
                            >
                              <i
                                className="mdi mdi mdi-close close-btn-icon-table"
                                //
                              ></i>
                            </Button>
                          </div>
                        
                    </Col>
                  </Row>

                  <div className="table-custom-redesign">
                    <Table
                      data={state.data || []}
                      columns={columns}
                      isPagination={true}
                      page={page}
                      totalSize={total}
                      loading={isSpinner}
                      noDataIndication={"No Data Found"}
                      onTableChange={handleTableChange}
                      isHover={false}
                      isStriped={false}
                      isBordereded={false}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state && state.Login.user.user,
  }
}

export default connect(mapStateToProps)(EditableTables)

// export default EditableTables
