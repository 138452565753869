import React, { useCallback, useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import style from "./style.module.scss"

const InvoiceTotalTable = ({ InvoiceData, setInvoiceData }) => {
  const [subTotal, setSubtotal] = useState(0)
  const [serviceTax, setServiceTax] = useState(0)
  const [invoices, setInvoices] = useState(InvoiceData)
  useEffect(() => {
    setInvoices(InvoiceData)
  }, [JSON.stringify(InvoiceData.misc), JSON.stringify(InvoiceData.default)])
  useEffect(() => {
    let defaultTotal = 0.0
    let miscTotal = 0.0
    invoices.default &&
      invoices.default.forEach(item => {
        defaultTotal += parseFloat(item.total)
      })
    invoices.misc &&
      invoices.misc.forEach(item => {
        miscTotal += parseFloat(item.counsellor_rate)
      })

    const netAmount = ((invoices.service_fee / 100) * defaultTotal).toFixed(2)

    setServiceTax(netAmount)

    setSubtotal(defaultTotal + miscTotal)
  }, [JSON.stringify(invoices)])

  const formatValue = value =>
    value == null || value == "undefined" || value == 0
      ? `0.00`
      : `${parseFloat(value).toFixed(2)}`
  const totalColumns = [
    {
      text: "InvoiceTotalTable",
      dataField: "id",
      hidden: true,
    },
    {
      text: "Session",
      dataField: "total_sessions",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            {cell == null || cell == "undefined" || cell == 0
              ? `0.00`
              : `${parseFloat(cell).toFixed(2)}`}
          </span>
        )
      },
    },
    {
      text: "Sub Total",
      dataField: "sub_total",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            $
            {cell == null || cell == "undefined" || cell == 0
              ? `0.00`
              : row.finalSubTotal && parseFloat(row.finalSubTotal).toFixed(2)}
          </span>
        )
      },
    },
    {
      text: "Service Fee",
      dataField: "service_fee_amount",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            $
            {cell == null || cell == "undefined" || cell == 0
              ? `0.00`
              : `${parseFloat(cell).toFixed(2)}`}
          </span>
        )
      },
    },
    {
      text: "GST",
      dataField: "total_gst_Amount",
      width: 150,
      hidden: !InvoiceData.gst_enabled,
      formatter: (cell, row) => {
        return (
          <span>
            $
            {cell == null || cell == "undefined" || cell == 0
              ? `0.00`
              : `${parseFloat(cell).toFixed(2)}`}
          </span>
        )
      },
    },
    {
      text: "Total",
      dataField: "total_Amount",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            $
            {row.finalSubTotal
              ? (row.finalSubTotal - InvoiceData.service_fee_amount).toFixed(2)
              : `0.00`}
          </span>
        )
      },
    },
  ]
  return (
    <React.Fragment>
      <Row className="mt-4">
        <Col className="col-12">
          <Card className="dark-green-bg-card bg-white">
            <CardBody className="p-0">
              <Row>
                <Col
                lg={3}
                  xl={3}
                  md={6}
                  className="p-3 sessions-details-counsellors-invoice"
                >
                  <p>Sessions</p>
                  <p className="session-flex-title-per">
                    <span className="value-session-stats">
                      {formatValue(invoices.total_sessions)}
                    </span>
                    {/* <span className="percentage-session-stats">+1.3%</span> */}
                  </p>
                </Col>
                <Col
                lg={3}
                  xl={3}
                  md={6}
                  className="p-3 sessions-details-counsellors-invoice"
                >
                  <p>Sub Total</p>
                  <p className="session-flex-title-per">
                    <span className="value-session-stats">
                      ${formatValue(subTotal)}
                    </span>
                  </p>
                </Col>
                <Col
                lg={3}
                  xl={3}
                  md={6}
                  className=" p-3 sessions-details-counsellors-invoice"
                >
                  <p>Service Fee</p>
                  <p className="session-flex-title-per">
                    <span className="value-session-stats">
                      ${serviceTax}
                    </span>
                  </p>
                </Col>
                <Col
                lg={3}
                  xl={3}
                  md={6}
                  className="p-3 sessions-details-counsellors-invoice"
                >
                  <p>Total</p>
                  <p className="session-flex-title-per">
                    <span className="value-session-stats">
                      $
                      {subTotal
                        ? parseFloat(
                            subTotal - serviceTax
                          ).toFixed(2)
                        : `0.00`}
                    </span>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default InvoiceTotalTable
