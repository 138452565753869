import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import {
  sortCaret,
  capitalizeFirstLetter,
  convertUTCToLocal,
  meetingType,
} from "../../functions/functions"
import {
  GET_APPOINTMENTS,
  LINKED_ACCOUNT_STATUS,
  MAKE_PAYMENT,
} from "./gql/Gql"
import debounce from "lodash.debounce"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useHistory, useLocation } from "react-router-dom"
import { useStateParams } from "../../hooks/urlStateParams"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import classNames from "classnames"
import ViewRecordingModal from "./ViewRecordingModal"

const today = new Date()
today.setDate(today.getDate() + 1)

const currentYear = today.getFullYear()
const currentMonth = today.getMonth() + 1 // Months are zero-indexed
const currentDay = today.getDate()
const appointmentTodays = []

const AppointmentListView = ({ selectedCounselor }) => {
  const history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)
  const [totalCount, setTotalCount] = useState(20)
  const [appointments, setAppointments] = useState([])
  const [allAppointments, setAllAppointments] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const location = useLocation()
  const [accountDetailsStatus, setAccountDetailsStatus] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [paymentType, setPaymentType] = useState(null)
  let clientName = new URLSearchParams(location.search).get("client")

  const encodedAccountData = localStorage.getItem("accountData")
  const accountStatus = encodedAccountData
    ? JSON.parse(atob(encodedAccountData))
    : null

  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])

  const [MakePayment, MakePaymentResponse] = useMutation(MAKE_PAYMENT)

  const makePaymentFun = id => {
    if (id) {
      setIsLoading(true)
      MakePayment({
        variables: {
          appointmentId: id,
        },
      })
    }
  }

  useEffect(() => {
    if (
      MakePaymentResponse.data?.makePayment?.data &&
      MakePaymentResponse.data?.makePayment?.data?.url
    ) {
      const paymentUrl = MakePaymentResponse.data?.makePayment?.data?.url
      window.location.href = paymentUrl
    } else {
      setIsLoading(false)
      toast.error(MakePaymentResponse.data?.makePayment?.message)
    }
  }, [MakePaymentResponse.data])

  let sessionParams = sessionStorage.getItem("appointmentParams")
  if (sessionParams) {
    sessionParams = JSON.parse(sessionParams)
  }

  const [state, setState] = useState({
    page: sessionParams?.page ? sessionParams.page : 1,
    sizePerPage: sessionParams?.sizePerPage ? sessionParams.sizePerPage : 20,
    sortBy: sessionParams?.sortBy ? sessionParams?.sortBy : "utc_datetime",
    sortOrder: sessionParams?.sortOrder ? "DESC" : sessionParams?.sortOrder,
    search: clientName
      ? clientName
      : sessionParams?.search
      ? sessionParams?.search
      : "",
    message: sessionParams?.message ? sessionParams?.message : "",
    startDate: sessionParams?.startDate
      ? sessionParams?.startDate !== "undefined"
        ? moment(sessionParams?.startDate).startOf("day").toDate()
        : ""
      : "",
    endDate: sessionParams?.endDate
      ? sessionParams?.endDate !== "undefined"
        ? moment(sessionParams?.endDate).startOf("day").toDate()
        : ""
      : "",
  })

  //************************** Appointment list ***************************** */

  const [getAppointmentList, listResponse] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: "no-cache",
  })
  function getAppointmentListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
    startDate,
    endDate,
    counsellorId
  ) {
    let variables = {
      variables: {
        argumentsInput: {
          page: page ? parseInt(page) : 1,
          limit: sizePerPage ? parseInt(sizePerPage) : 10,
          search: searchText,
          sort: sortField,
          device_type:"web",
          sort_dir: sortOrder ? sortOrder?.toUpperCase() : "DESC",
          start_date: startDate
            ? moment(startDate)
                .startOf("day")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ")
            : "",
          end_date: endDate
            ? moment(endDate).endOf("day").utc().format("YYYY-MM-DD HH:mm:ssZ")
            : "",
          ...(userData.role.id !== 3 ? { calendar_id: counsellorId } : {}),
        },
      },
    }
    getAppointmentList(variables)
  }

  React.useEffect(() => {
    setIsPageLoaded(true)
  }, [])

  React.useEffect(() => {
    if (selectedCounselor) {
      sessionStorage.setItem("CounsellorId", selectedCounselor)
      if (!isPageLoaded) {
        setState(s => ({ ...s, page: 1 }))
        sessionStorage.setItem(
          "appointmentParams",
          JSON.stringify({
            ...state,
            page: 1,
          })
        )
        getAppointmentListFun(
          1,
          state.sizePerPage,
          state.search,
          state.sortBy,
          state.sortOrder,
          state.startDate,
          state.endDate,
          selectedCounselor
        )
      } else {
        getAppointmentListFun(
          state.page,
          state.sizePerPage,
          state.search,
          state.sortBy,
          state.sortOrder,
          state.startDate,
          state.endDate,
          selectedCounselor
        )
      }
    }
  }, [selectedCounselor])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointments &&
      listResponse.data.getAppointments.status &&
      listResponse.data.getAppointments.code === 200
    ) {
      const appointments = listResponse.data.getAppointments.data.appointments_arr?.map((elem)=>{
        return elem?.appointment
      })
      setAppointments(appointments)
      setAllAppointments(appointments)
      setPaymentType(null)
      setTotalCount(listResponse.data.getAppointments.data.totalCount)
    } else if (
      listResponse.data &&
      listResponse.data.getAppointments &&
      !listResponse.data.getAppointments.status &&
      listResponse.data.getAppointments.code === 404
    ) {
      setAppointments([])
      setTotalCount(0)
      setState(s => ({
        ...s,
        message: listResponse.data.getAppointments.message,
      }))
    }
  }, [listResponse.data])

  //************************** Table ***************************** */
  function idFormatFun(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <p className="m-0">
          {rowIndex + 1 + state.sizePerPage * (state.page - 1)}
        </p>
      </>
    )
  }

  const viewStatusBtn = (column, row) => {
    return row?.recording?.length > 0 ? (
      <Button
        className="btn btn-primary"
        onClick={() => {
          viewStatus(row?.recording)
          setAppointmentID(row?.id)
        }}
      >
        View
      </Button>
    ) : (
      <span>-</span>
    )
  }

  const [viewStatusModal, setViewStatusModal] = useState(false)
  const [composionData, setComposionData] = useState(null)
  const [appointmentID, setAppointmentID] = useState(null)

  const viewStatus = (composion) => {
    setViewStatusModal(!viewStatusModal)
    if (composion) {
      setComposionData(composion)
    } else {
      setComposionData(null)
    }
  }

  const columns = [
    {
      text: "ID",
      dataField: "id",
      formatter: idFormatFun,
      style: {
        minWidth: 50,
      },
    },
    {
      text: "Client Name",
      dataField: "first_name",
      formatter: fullNameFun,
      sort: true,
      style: {
        minWidth: 130,
      },
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      text: "Meeting Type",
      dataField: "type_of_appointment",
      style: {
        minWidth: 150,
      },
      formatter: meetingTypeFun,
    },
    {
      text: "Date",
      dataField: "utc_datetime",
      style: {
        minWidth: 190,
      },
      formatter: (cell, row) => {
        const date = convertUTCToLocal(row.utc_datetime)
        return (
          <p className="m-0">{moment(date).format("DD-MMM-yyyy hh:mm A")}</p>
        )
      },
      headerStyle: {
        cursor: "pointer",
      },
      sort: true,
    },
    // {
    //   text: "Time",
    //   dataField: "time",
    //   width: 270,
    //   sortCaret: sortCaret,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     const date = convertUTCToLocal(row.utc_datetime)
    //     return <p className="m-0">{moment(date).format("h:mm A")}</p>
    //   },
    // },
    {
      text: "Duration (in mins)",
      dataField: "duration",
      style: {
        minWidth: 50,
      },
      sortCaret: sortCaret,
    },
    {
      dataField: "is_counsellor_feedback_submitted",
      text: `${userData.role.id === 2 ? "Feedback" : "Counsellor Feedback"}`,
      sort: false,
      hidden: userData.role.id === 2 || userData.role.id === 1 ? false : true,
      headerAlign: "center",
      align: "center",
      style: {
        minWidth: 100,
      },
      formatter: counsellorFeedbackEvent,
    },
    {
      dataField: "is_client_feedback_submitted",
      text: `${userData.role.id === 3 ? "Feedback" : "Client Feedback"}`,
      sort: false,
      hidden: userData.role.id === 3 || userData.role.id === 1 ? false : true,
      headerAlign: "center",
      align: "center",
      style: {
        minWidth: 100,
      },
      formatter: clientFeedbackEvent,
    },
    {
      dataField: "payment_type",
      text: "Payment Source",
      sort: false,
      headerAlign: "center",
      align: "center",
      style: {
        minWidth: 100,
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row?.payment_type?.name
      },
    },
    {
      dataField: "canceled",
      text: "Status",
      sort: false,
      headerAlign: "left",
      align: "left",
      style: {
        minWidth: 100,
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row?.canceled ? <span>Cancelled</span> : <span>Active</span>
      },
    },
    {
      dataField: "paid",
      text: "Payment",
      sort: false,
      hidden: accountDetailsStatus && accountStatus ? false : true,
      headerAlign: "center",
      align: "center",
      style: {
        minWidth: 100,
      },
      formatter: clientPayment,
    },
    {
      dataField: "recording ",
      text: "Recording ",
      sort: false,
      headerAlign: "center",
      align: "center",
      style: {
        minWidth: 100,
      },
      formatter: viewStatusBtn,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "center",
      align: "center",
      style: {
        minWidth: 100,
      },
      formatter: actionEvent,
    },
  ]

  function fullNameFun(value, row) {
    const firstName = row.first_name ? row.first_name : " "
    const lastName = row.last_name ? row.last_name : " "
    return (
      capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName)
    )
  }

  function meetingTypeFun(value, row) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {value && value?.call_type ? meetingType(value?.call_type) : ""}
      </div>
    )
  }

  const handleShowDetailsClick = row => {
    const encodedId = btoa(row.id)
    history.push(`/appointments/${encodedId}`)
  }

  const handleSubmitFeedback = row => {
    const win = window.open(`/feedback-consultation/${row.id}`, "_blank")
    win.focus()
  }

  const handleShowCounsellorFeedBackDetails = row => {
    const appointmentFeedback = row.appointment_has_feedback
    let counsellor_feedback_id = null
    appointmentFeedback.map(feedback => {
      if (feedback.from.role.id === 2) {
        counsellor_feedback_id = feedback.id
      }
    })
    const encodedId = btoa(row.id)
    const encodedFId = btoa(counsellor_feedback_id)
    history.push(`/feedback/${encodedId}?consellor_feedback=${encodedFId}`)
  }

  const handleShowClientFeedBackDetails = row => {
    const appointmentFeedback = row.appointment_has_feedback
    let client_feedback_id = null
    appointmentFeedback.map(feedback => {
      if (feedback.from.role.id === 3) {
        client_feedback_id = feedback.id
      }
    })
    const encodedId = btoa(row.id)
    const encodedFId = btoa(client_feedback_id)
    history.push(`/feedback/${encodedId}?client_feedback=${encodedFId}`)
  }

  function actionEvent(cell, row) {
    return (
      <div className="d-flex justify-content-center">
        <Button
          onClick={() => handleShowDetailsClick(row)}
          className="px-3 btn btn-primary edit-btn cursor-pointer counsellorDetailsBtn"
        >
          Details
          <i className="mdi mdi-chevron-right ms-3 mt-1"></i>
        </Button>
      </div>
    )
  }

  const isAppointmentTimeCompleted = (startTime, duration) => {
    const endTime = moment(startTime)
      .add(duration, "minutes")
      .format("DD-MMM-yyyy hh:mm A")
    const isCompletedTime = moment().isAfter(endTime)
    return isCompletedTime
  }
  const appointmentFilters = () => {
    appointments.map(e => {
      const timestamp = new Date(e.utc_datetime)
      const yearFromTimestamp = timestamp.getFullYear()
      const monthFromTimestamp = timestamp.getMonth() + 1 // Months are zero-indexed
      const dayFromTimestamp = timestamp.getDate()

      if (
        currentYear === yearFromTimestamp &&
        currentMonth === monthFromTimestamp &&
        currentDay === dayFromTimestamp
      ) {
        appointmentTodays.push(e)
      } else {
        return
      }
    })
  }
  function counsellorFeedbackEvent(value, row) {
    const localTime = convertUTCToLocal(row.utc_datetime)
    const appointmentCompleted = isAppointmentTimeCompleted(
      localTime,
      row.duration
    )
    return (
      <div className="d-flex justify-content-center">
        {appointmentCompleted ? (
          <>
            {value ? (
              <a
                className="px-3 btn btn-primary edit-btn cursor-pointer"
                onClick={() => handleShowCounsellorFeedBackDetails(row)}
              >
                View
              </a>
            ) : userData.role.id === 2 ? (
              <a
                onClick={() => handleSubmitFeedback(row)}
                className="px-3 edit-btn btn btn-primary cursor-pointer"
              >
                Submit
              </a>
            ) : (
              <div className="px-3">Pending</div>
            )}
          </>
        ) : (
          <>-</>
        )}
      </div>
    )
  }

  function clientFeedbackEvent(value, row) {
    const localTime = convertUTCToLocal(row.utc_datetime)
    const appointmentCompleted = isAppointmentTimeCompleted(
      localTime,
      row.duration
    )
    return (
      <div className="d-flex justify-content-center">
        {appointmentCompleted ? (
          <>
            {value ? (
              <a
                className="px-3 edit-btn btn btn-primary cursor-pointer"
                onClick={() => handleShowClientFeedBackDetails(row)}
              >
                View
              </a>
            ) : userData.role.id === 2 || userData.role.id === 3 ? (
              <a
                onClick={() => handleSubmitFeedback(row)}
                className="px-3 edit-btn btn btn-primary cursor-pointer"
              >
                Submit
              </a>
            ) : (
              <div className="px-3">Pending</div>
            )}
          </>
        ) : (
          <>-</>
        )}
      </div>
    )
  }

  function clientPayment(value, row) {
    const is_direct = row?.payment_type?.is_direct
    const status = row?.paid
    return (
      <div className="d-flex justify-content-center">
        {!is_direct ? (
          <>
            {status === "yes" && userData.role.id === 3 && (
              <span
                className="border border-dark rounded px-2 py-1  d-flex "
                disabled={true}
              >
                <span className="mdi mdi-check-all me-2"></span> Paid
              </span>
            )}
            {status === "no" && userData.role.id === 3 && row?.payment_type && (
              <Button
                className="btn btn-primary  d-flex"
                onClick={() => makePaymentFun(row.id)}
              >
                <span className="mdi mdi-credit-card-outline me-2"></span> Pay
              </Button>
            )}
            {status === "yes" && userData.role.id !== 3 && (
              <span
                className="border border-dark rounded  d-flex px-2 py-1 "
                disabled={true}
              >
                <span className="mdi mdi-check-all me-2"></span> Paid
              </span>
            )}
            {status === "no" && userData.role.id !== 3 && (
              <Button className="btn btn-primary d-flex">
                <span className="mdi mdi-timer-outline"></span> Pending
              </Button>
            )}
          </>
        ) : (
          <>-</>
        )}
      </div>
    )
  }

  //************************** Table Change Event  ***************************** */

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    const field = !sortField ? "utc_datetime" : sortField
    const order = !sortOrder ? "DESC" : sortOrder
    const counsellorId = parseInt(sessionStorage.getItem("CounsellorId"))
    setState(s => ({
      ...s,
      page: page,
      sizePerPage: sizePerPage,
      sortBy: field,
      sortOrder: order,
    }))
    sessionStorage.setItem(
      "appointmentParams",
      JSON.stringify({
        ...state,
        page: page,
        sizePerPage: sizePerPage,
        sortBy: field,
        sortOrder: order,
        startDate: state.startDate ? state.startDate : "undefined",
        endDate: state.endDate ? state.endDate : "undefined",
      })
    )
    if (type !== "search") {
      getAppointmentListFun(
        page,
        sizePerPage,
        state.search,
        field,
        order,
        state.startDate,
        state.endDate,
        counsellorId
      )
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const loadingText = state.message || listResponse?.data ? "No Data Found" : ""

  //************************** Table Search ***************************** */

  const handleSearch = e => {
    const searchValue = e.target.value
    setState(prevState => {
      return {
        ...prevState,
        search: searchValue,
        page: 1,
      }
    })
    sessionStorage.setItem(
      "appointmentParams",
      JSON.stringify({
        ...state,
        search: searchValue,
        page: 1,
      })
    )
  }

  const debouncedSearch = useCallback(
    debounce((searchValue, start_date, end_date) => {
      const getCounsellorId = parseInt(sessionStorage.getItem("CounsellorId"))
      getAppointmentListFun(
        1,
        state.sizePerPage,
        searchValue,
        state.sortBy,
        state.sortOrder,
        start_date,
        end_date,
        getCounsellorId
      )
    }, 1000),
    []
  )

  const onChangeSearch = e => {
    handleSearch(e)
    debouncedSearch(e.target.value, state.startDate, state.endDate)
  }

  //************************** Date filter ***************************** */

  const submitDateFilter = () => {
    const counsellor = parseInt(sessionStorage.getItem("CounsellorId"))
    setState(s => ({ ...s, page: 1 }))
    sessionStorage.setItem(
      "appointmentParams",
      JSON.stringify({
        ...state,
        page: 1,
      })
    )
    getAppointmentListFun(
      1,
      state.sizePerPage,
      state.search,
      state.sortBy,
      state.sortOrder,
      state.startDate,
      state.endDate,
      counsellor
    )
  }

  const handleClose = () => {
    const counsellorID = parseInt(sessionStorage.getItem("CounsellorId"))
    setState(s => ({ ...s, startDate: undefined, endDate: undefined }))
    const obj = {
      ...state,
      startDate: "undefined",
      endDate: "undefined",
    }
    sessionStorage.setItem("appointmentParams", JSON.stringify(obj))
    getAppointmentListFun(
      1,
      state.sizePerPage,
      state.search,
      state.sortBy,
      state.sortOrder,
      undefined,
      undefined,
      counsellorID
    )
  }

  const paymentFilter = type => {
    if (type == paymentType) {
      setPaymentType(null)
      setAppointments(allAppointments)
    }
    if (type != paymentType) {
      setPaymentType(type)
      if (type == "paid") {
        const allAppoints = allAppointments.filter(elem => {
          return elem?.paid == "yes" && !elem?.payment_type?.is_direct
        })
        setAppointments(allAppoints)
      }
      if (type == "unpaid") {
        const allAppoints = allAppointments.filter(elem => {
          return elem?.paid == "no" && !elem?.payment_type?.is_direct
        })
        setAppointments(allAppoints)
      }
    }
  }

  return (
    <Row className="mg-top-default-ui">
      <Col className="col-12 ">
        <Card className="light-green-bg-card-table">
          <CardBody>
            <div>
              <div className={classNames("d-flex gap-2 flex-wrap")}>
                <Row className="w-100 flex-direction-column-mobile">
                  {userData.role.id !== 3 && (
                    <Col className="search-input-search-appointments">
                      {" "}
                      <label className="w-100 search-label-appointments">
                        <div className="form-control searchComponent p-0 px-2 py-1 ">
                          <input
                            className="form-control border-0 p-1 default-search-input "
                            onChange={onChangeSearch}
                            value={state.search}
                            type="text"
                            placeholder="Search Client Name"
                          />
                          <span className="mdi mdi-magnify me-2 searchIcon"></span>
                        </div>
                      </label>
                    </Col>
                  )}

                  <Col>
                    <div className="d-flex gap-2 fx-basis-appointment-dates w-100">
                      <DatePicker
                        selected={state.startDate}
                        className="form-control appointmentDatePicker fx-100 pointer"
                        placeholderText="From"
                        onChange={value =>
                          setState(s => ({ ...s, startDate: value }))
                        }
                        dateFormat="dd-MMM-yyyy"
                      />
                      <DatePicker
                        selected={state.endDate}
                        className="form-control ml-5 pointer"
                        placeholderText="To"
                        onChange={value =>
                          setState(s => ({ ...s, endDate: value }))
                        }
                        dateFormat="dd-MMM-yyyy"
                        minDate={state.startDate}
                      />
                    </div>
                  </Col>
                  <Col className="buttons-appointment-list-div">
                    <Row className="align-items-center">
                      {" "}
                      <Button
                        type="button"
                        className="btn btn-primary width-fit-content"
                        disabled={!state.startDate || !state.endDate}
                        onClick={submitDateFilter}
                      >
                        Search
                      </Button>
                      <button
                        type="button"
                        className="btn-cancel-light-green-bg btn-w-62-ml-10"
                        disabled={!state.startDate && !state.endDate}
                        onClick={handleClose}
                      >
                        <i className="mdi mdi-close btn-app-list-f-24"></i>
                      </button>
                      {accountStatus && accountDetailsStatus && (
                        <Col className="d-flex justify-content-end">
                          <button
                            type="button"
                            className={`btn mx-1 px-3 ${
                              paymentType == "paid"
                                ? "btn-primary"
                                : "font-weight-bold border border-dark"
                            }`}
                            onClick={() => paymentFilter("paid")}
                          >
                            Paid
                          </button>
                          <button
                            type="button"
                            className={`btn mx-1 px-3  ${
                              paymentType == "unpaid"
                                ? "btn-primary"
                                : "font-weight-bold border border-dark"
                            }`}
                            onClick={() => paymentFilter("unpaid")}
                          >
                            Unpaid
                          </button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="table-custom-redesign">
              <Table
                data={appointments}
                page={state.page}
                sizePerPage={state.sizePerPage}
                totalSize={totalCount}
                onTableChange={handleTableChange}
                columns={columns}
                noDataIndication={loadingText}
                loading={listResponse.loading}
                isHover={false}
                isStriped={false}
                isBordereded={false}
              />
            </div>
            <ViewRecordingModal
              composionData={composionData}
              setComposionData={setComposionData}
              viewStatusModal={viewStatusModal}
              viewStatus={viewStatus}
              appointmentID={appointmentID}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AppointmentListView
