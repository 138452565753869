import React, { useCallback, useState, useEffect } from "react"
import { Card, CardBody, Input, Col, Row, FormGroup, Button } from "reactstrap"
import Table from "../components/Table"
import { capitalizeFirstLetter, hasPermission } from "../../../functions/functions"
import { useSelector } from "react-redux"
import Tooltip from "@material-ui/core/Tooltip"
import { useLazyQuery, useMutation } from "@apollo/client"
import debounce from "lodash.debounce"
import { useHistory, useLocation } from "react-router-dom"
import {
  GET_CLIENTS,
  GET_COUNSELORS,
  DEACTIVATE_CLIENT,
  UPDATE_CLIENT,
  APPROVE_REQUEST_BY_ADMIN,
} from "../gql/clientGql"
import "react-toastify/dist/ReactToastify.css"
import moment from "moment"
import style from "../style.module.scss"
import { makeStyles } from "@material-ui/core"
import CustomDropdown from "../components/CustomDropdown"
import _ from "lodash"
import classNames from "classnames"
import ClientAction from "../components/ClientAction"
import LegendAction from "../components/LegendAction"
import { ToastContainer, toast } from "react-toastify"

//icons
import noApproval from "../../../assets/images/legend-icons/noApproval.png"
import submitted from "../../../assets/images/legend-icons/submitted.svg"
import activatedDeactivated from "../../../assets/images/legend-icons/activate-deactivated.svg"
import oneSessionOrWeek from "../../../assets/images/legend-icons/one-session-week.svg"
import twoSessionOrWeek from "../../../assets/images/legend-icons/two-sessions-week.svg"

import edit from "../../../assets/images/dashboard-icons/edit.svg"
import PageNotFound from "../../404"
import ClientManage from "../components/ClientManage"

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1700px)": {
      justifyContent: "start",
      flexDirection: "column",
    },
  },

  conatiner: {
    width: "790px",
    textAlign: "end",
    alignSelf: "end",
    "@media (max-width: 1700px)": {
      textAlign: "start",
      alignSelf: "start",
      width: "fit-content",
      padding: 0,
    },
  },

  legendContainer: {
    justifyContent: "end",
    "@media (max-width: 576px)": {
      justifyContent: "start",
    },
  },

  legendAlign: {
    "@media (max-width: 576px)": {
      flexDirection: "column",
    },
  },
}))

const DirectClientList = ({combinedPage, counsellorChange}) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const userData = useSelector(state => state.Login?.user?.user)
  const [isSpinner, setIsSpinner] = useState(false)
  const [totalCount, setTotalCount] = useState(20)
  const [directClients, setDirectClients] = useState([])
  const [counselorList, setCounselorList] = useState([])
  const [clientId, setClientId] = useState(null)
  const [selectedCounselor, setSelectedCounselor] = useState()
  const [isActive, setIsActive] = useState({
    label: "All Active",
    value: "direct_client",
  })
  const [state, setState] = useState({
    page: 1,
    data: directClients,
    sizePerPage: combinedPage? 10 : 20,
    sortBy: "updated_at",
    sortOrder: "DESC",
    selectedCounselor: selectedCounselor,
    search: "",
  })
  let counsellorId = null


  useEffect(() => {
    if(counsellorChange){
      setSelectedCounselor(counsellorChange)
      onCounselorChange(counsellorChange)
      counsellorId=counsellorChange
      getDirectClientListFun(
        state.page,
        state.sizePerPage,
        state.search,
        state.sortBy,
        state.sortOrder,
        counsellorChange
      )
    }
  }, [counsellorChange])

  const handleEditClick = row => {
    const encodedId = btoa(row.plan.id);
    history.push(`/plans/${encodedId}/edit`)
  }

  const handleShowDetailsClick = row => {
    const encodedId = btoa(row.plan.id);
    history.push(`/plans/${encodedId}`)
  }

  useEffect(() => {
    if (queryParams.get("filter")) {
      const filterParams = options.filter(o => {
        return o.value == queryParams.get("filter")
      })
      if (filterParams.length > 0) {
        setIsActive(filterParams[0])
      }
    }
  }, [])


  if (location.search && location.search.includes("?counsellor=")) {
    counsellorId = parseInt(location.search.replace("?counsellor=", ""))
  }

  //************************** Counselor list ***************************** */

  const [getCounselorList, counselorsResponse] = useLazyQuery(GET_COUNSELORS, {
    fetchPolicy: "no-cache",
  })

  const [submitMutation, submitResponse] = useMutation(UPDATE_CLIENT)

  function getCounselorListFun() {
    getCounselorList({
      variables: {
        argumentsInput: {
          is_direct: combinedPage ? undefined : true,
          is_all :  combinedPage ? true : undefined,
        },
      },
    })
  }

  React.useEffect(() => {
    getCounselorListFun()
  }, [])

  React.useEffect(() => {
    if (
      counselorsResponse.data &&
      counselorsResponse.data.getCalendarLegend &&
      counselorsResponse.data.getCalendarLegend.status &&
      counselorsResponse.data.getCalendarLegend.code === 200
    ) {
      let data = counselorsResponse.data.getCalendarLegend.data.calendars.map(
        n => {
          return {
            id: n.id,
            name: n.name,
            document_submitted_on_status: n.document_submitted_on_status,
            request_status: n.request_status,
            approval_status: n.approval_status,
            two_sessions_two_week_left: n.two_sessions_two_week_left,
            one_sessions_one_week_left: n.one_sessions_one_week_left,
            no_payment: combinedPage ?  n.no_payment : undefined,
            partial_paid: combinedPage ? n.partial_paid : undefined,
          }
        }
      )
      setCounselorList(data)
     
    } else if (counselorsResponse.data) {
      setIsSpinner(false)
      setCounselorList([])
    }
  }, [counselorsResponse.data])

  useEffect(() => {
    const sortedList = _.sortBy(counselorList, [
      function (option) {
        return option.name
      },
    ])
    if (sortedList.length > 0) {
      setSelectedCounselor(sortedList[0].id)
    }
  }, [counselorList])

  //************************** Direct client list ***************************** */

  const [getDirectClientList, listResponse] = useLazyQuery(GET_CLIENTS, {
    fetchPolicy: "no-cache",
  })

  function getDirectClientListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
    calendarId,
    filter
  ) {
    const arg = filter ? filter.value : isActive.value
    if(parseInt(calendarId)){
    setIsSpinner(true)
    let variable = {
      variables: {
        argumentsInput: {
          page: page,
          limit: sizePerPage,
          calendar_id: parseInt(calendarId),
          search: searchText.trim(),
          sort_dir: sortOrder?.toUpperCase(),
          sort: sortField,
          [arg]: true,
          is_active_clients: arg === "direct_client",
        },
      },
    }
    if (arg == "direct_client") {
      variable.variables.argumentsInput.direct_client = true
    }
    getDirectClientList(variable)
      .then(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
      .catch(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
    }
  }

  useEffect(() => {
    if (counselorList.length > 0) {
      getDirectClientListFun(
        state.page,
        state.sizePerPage,
        state.search,
        state.sortBy,
        state.sortOrder,
        state.selectedCounselor
      )
    } else {
      setIsSpinner(false)
      setDirectClients([])
      setTotalCount(20)
      setState(s => ({ ...s, data: [] }))
    }
  }, [selectedCounselor])

  useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getPlans &&
      listResponse.data.getPlans.code === 200 &&
      listResponse.data.getPlans?.data?.appointments
    ) {
      setIsSpinner(false)
      setDirectClients(listResponse.data.getPlans.data.appointments)
      setTotalCount(listResponse.data.getPlans.data.totalCount)
      const data = listResponse.data.getPlans.data.appointments.map(d => {
        d.plan.bgcolor = getBackground(d)
        return { ...d }
      })
      setState(s => ({ ...s, data }))
    } else if (
      listResponse.data &&
      listResponse.data.getPlans &&
      listResponse.data.getPlans.code !== 200
    ) {
      setIsSpinner(false)
      setDirectClients([])
      setTotalCount(0)
      setState(s => ({ ...s, data: [] }))
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  //************************** select ***************************** */

  const options = [
    {
      label: "All Active",
      value: "direct_client",
    },
    {
      label: "Active Clients - ICBC",
      value: "active_icbc",
    },
    {
      label: "Deactivated Clients - ICBC",
      value: "deactive_icbc",
    },
    {
      label: "Active Clients - Others",
      value: "active_other",
    },
    {
      label: "Deactivated Clients - Others",
      value: "deactive_other",
    },
  ]

  const onSelectChange = value => {
    setState(p => ({
      ...p,
      page: 1,
    }))
    setIsActive(value)
    if(combinedPage){
      history.push(
        `/direct-non-direct?counsellor=${counsellorId}&filter=${
          value.value
        }&page=${1}`
      )
    }else{
      history.push(
        `/direct-clients?counsellor=${counsellorId}&filter=${
          value.value
        }&page=${1}`
      )
    }
  
    getDirectClientListFun(
      1,
      state.sizePerPage,
      state.search,
      state.sortBy,
      state.sortOrder,
      counsellorId,
      value
    )
  }

  const onCounselorChange = counselor => {
    setSelectedCounselor(counselor)
    setState(p => ({
      ...p,
      page: 1,
      selectedCounselor: counselor,
    }))
    if(combinedPage){
      history.push(
        `/direct-non-direct?counsellor=${counselor}&filter=${isActive.value}&page=1`
      )
    }else{
    history.push(
      `/direct-clients?counsellor=${counselor}&filter=${isActive.value}&page=1`
    )}
  }

  //*********************** Deactivate Account from counselor  *******************//

  const [deactivateMutation, deactivateResponse] =
    useMutation(DEACTIVATE_CLIENT)

  React.useEffect(() => {
    if (
      deactivateResponse.data &&
      deactivateResponse.data.requestByCounsellor
    ) {
      toast.success(deactivateResponse.data.requestByCounsellor.message)
      getDirectClientListFun(
        state.page,
        state.sizePerPage,
        state.search,
        state.sortBy,
        state.sortOrder,
        state.selectedCounselor
      )
    }
  }, [deactivateResponse.data])

  const requestToDeactivate = (id, isClientActivated) => {
    const deactivatePlanInput = {
      createPlanInput: {
        id: parseInt(id),
        remarks: isClientActivated === "active" ? "for deactive" : "for active",
        request_type: "approval_status_update",
        session_status: isClientActivated === "active" ? "deactive" : "active",
      },
    }
    deactivateMutation({
      variables: deactivatePlanInput,
    })
  }

  const getBackground = row => {
    return row?.plan?.is_two_week_expiry
      ? "#b9b9b9"
      : row?.plan?.is_One_Week_Expiry
      ? "#ffcc4f"
      : row?.plan?.is_approved
      ? "#ff6060"
      : ""
  }

  //************************** Table ***************************** */

  const columns = [
    {
      text: "Client Name",
      dataField: "plan.first_name",
      minWidth: 270,
      formatter: fullNameFun,
      sort: true,
      style: (cell, row, rowIndex) => ({
        minWidth: 150,
        backgroundColor: row?.plan?.bgcolor,
      }),
      headerStyle: {
        cursor: "pointer",
      },
    },
    {
      text: "Alerts",
      dataField: "plan.alerts",
      formatter: legendFun,

      style: (cell, row, rowIndex) => ({
        width: 100,
        backgroundColor: row?.plan?.bgcolor,
      }),
    },
    {
      dataField: "plan.payment_source",
      text: "Payment Source",
      formatter: paymentSourceFun,
      style: (cell, row, rowIndex) => ({
        minWidth: 90,
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      text: "Approved",
      dataField: "plan.approved_sessions",
      headerAlign: "center",
      align: "center",
      formatter: approvedSessionsFun,
      style: (cell, row, rowIndex) => ({
        maxWidth: 20,
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      dataField: "plan.total_sessions",
      text: "Used",
      headerAlign: "center",
      align: "center",
      style: (cell, row, rowIndex) => ({
        minWidth: 70,
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      dataField: "plan.billed_sessions",
      text: "Billed",
      headerAlign: "center",

      align: "center",
      style: (cell, row, rowIndex) => ({
        minWidth: 70,
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      text: "Paperwork",
      dataField: "plan.is_document_submitted",
      headerAlign: "center",
      align: "center",
      formatter: PaperworkInfoFun,
      style: (cell, row, rowIndex) => ({
        minWidth: 70,
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      text: "Expiry Date",
      dataField: "plan.expiry_date",
      formatExtraData: { columnId: "expiry_date" },
      formatter: dateFormatter,
      hidden:
        isActive?.value === "active_other" ||
        isActive?.value === "deactive_other"
          ? true
          : false,
      style: (cell, row, rowIndex) => ({
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      text: "Status",
      dataField: "plan.session_status",
      headerAlign: "center",
      sort: false,
      hidden:
        isActive?.label === "All" && isActive?.value === "direct_client"
          ? false
          : true,
      align: "center",
      formatter: statusFormatter,
      style: (cell, row, rowIndex) => ({
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      text: "Active",
      dataField: "plan",
      sort: false,
      hidden: userData.role.id === 1 ? false : true,
      align: "center",
      formatter: deactiveUser,
      style: (cell, row, rowIndex) => ({
        minWidth: 100,
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      text: "",
      dataField: "plan.request",
      sort: false,
      hidden: userData.role.id === 1 ? false : true,
      align: "center",
      formatter: notificationFormatter,
      style: (cell, row, rowIndex) => ({
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      text: "",
      dataField: "plan.request_action",
      sort: false,
      hidden: userData.role.id === 2 ? false : true,
      align: "center",
      formatter: requestActionEvent,
      style: (cell, row, rowIndex) => ({
        minWidth: 150,
        backgroundColor: row?.plan.bgcolor,
      }),
    },
    {
      text: "Action",
      dataField: "action",
      sort: false,
      align: "center",
      formatter: actionEvent,
      style: (cell, row, rowIndex) => ({
        width: 70,
        backgroundColor: row?.plan.bgcolor,
      }),
    },
  ]

  function legendFun(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        className={row?.plan?.background ? style?.[row?.plan?.background] : ""}
      >
        <LegendAction
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          formatExtraData={formatExtraData}
        />
      </div>
    )
  }

  function fullNameFun(value, row, index, field) {
    const firstName = row?.plan?.client?.first_name
    const lastName = row?.plan?.client?.last_name
    const name = row?.plan?.client?.name ? row?.plan?.client?.name : " "
    return (
      <>
        {firstName
          ? capitalizeFirstLetter(firstName) +
            " " +
            capitalizeFirstLetter(lastName)
          : name}
        {row?.plan?.client?.first_name && (
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={
              <>
                Onboarded on:{" "}
                {row?.plan?.created_at
                  ? moment(row?.plan.created_at).format("MMMM D, YYYY")
                  : ""}
              </>
            }
            arrow
          >
            <i
              className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
            ></i>
          </Tooltip>
        )}
      </>
    )
  }

  function requestActionEvent(value, row, index, field) {
    return (
      <ClientAction
        value={value}
        row={row}
        index={index}
        field={field}
        requestToDeactivate={requestToDeactivate}
      />
    )
  }
  const [adminDeactivateMutation, adminDeactivateResponse] =
  useMutation(APPROVE_REQUEST_BY_ADMIN)

  React.useEffect(() => {
    if (
      adminDeactivateResponse.data &&
      adminDeactivateResponse.data.approveRequestByAdmin?.status
    ) {
      toast.success(adminDeactivateResponse.data.approveRequestByAdmin.message)
      getDirectClientListFun(
        state.page,
        state.sizePerPage,
        state.search,
        state.sortBy,
        state.sortOrder,
        state.selectedCounselor
      )
    }
  }, [adminDeactivateResponse.data])

  const userDeactivate = (row) => {
    const deactivatePlanInput = {
      createPlanInput: {
        id: parseInt(row?.plan?.id),
        remarks: isActive?.value == "deactive_other" || isActive?.value == "deactive_icbc" ? "for active" : "for deactive",
        request_type: "approval_status_update",
        session_status: isActive?.value == "deactive_other" || isActive?.value == "deactive_icbc" ? "active" : "deactive",
      },
    }
    adminDeactivateMutation({
      variables: deactivatePlanInput,
    })
  }
  function deactiveUser(value, row, index, field) {
    return (
      <div className="d-flex align-items-center ps-3">
        <FormGroup check className="direct-client-list-blanket-cursor-txt">
          <Input
            id="exampleCheck"
            name="check"
            type="checkbox"
            className="cursor-pointer"
            checked={isActive?.value == "deactive_other" || isActive?.value == "deactive_icbc" ? false : true}
            onClick={()=>userDeactivate(row)}
          />
        </FormGroup>
      </div>
    )
  }

  const submitDocumentFun = (row, value) => {
    const directClientInput = {
      id: parseInt(row.plan.id),
      is_document_submitted: !value,
      document_submitted_on: moment().toDate(),
    }

    submitMutation({
      variables: {
        updatePlanInput: directClientInput
      },
    })
  }

  React.useEffect(() => {
    if (submitResponse.data && submitResponse.data.updatePlan) {
      toast.success(submitResponse.data.updatePlan.message)
      getDirectClientListFun(
        state.page,
        state.sizePerPage,
        state.search,
        state.sortBy,
        state.sortOrder,
        state.selectedCounselor
      )
    } else {
      if (submitResponse.data && submitResponse.data.updatePlan) {
        toast.error(submitResponse.data.UpdateClient.message)
      }
    }
  }, [submitResponse.data])

  function PaperworkInfoFun(value, row, index, field) {
    return (
      <div className="d-flex align-items-center gap-2">
        <FormGroup check className="direct-client-list-blanket-cursor-txt">
          <Input
            id="exampleCheck"
            name="check"
            type="checkbox"
            className="cursor-pointer"
            checked={value}
            onClick={()=>submitDocumentFun(row, value)}
          />
        </FormGroup>
        {value && row?.plan.document_submitted_on !== null && (
          <span>
            {moment(row?.plan.document_submitted_on).format("DD-MMM-yyyy")}
          </span>
        )}
      </div>
    )
  }

  function approvedSessionsFun(value, row, index, field) {
    return value ? value : 0
  }

  function statusFormatter(value, row, index, field) {
    return value === "active" ? "Active" : "Deactivated"
  }

  function paymentSourceFun(value, row, index, field) {
    return value.name ? value.name : ""
  }

  function dateFormatter(value, row, index, field) {
    const expiryDate =
      value === null ? " " : moment(value).format("DD-MMM-yyyy")
    return row?.plan?.payment_source?.id === 3 ? expiryDate : null
  }

  function notificationFormatter(value, row, index, field) {
    return (
      <div className={style.bellContainer}>
        <i
          className={classNames(
            "font-size-20 bx",
            {
              "bxs-bell": row?.plan?.alerts_enabled,
              "bx-bell": !row?.plan?.alerts_enabled,
            },
            style.bell
          )}
        ></i>
      </div>
    )
  }
  
  const editClietnModal = (row) =>{
    setClientId(row.plan.id)
  }

  const refetchClientData = () =>{
    getDirectClientListFun(
      state.page,
      state.sizePerPage,
      state.search,
      state.sortBy,
      state.sortOrder,
      state.selectedCounselor
    )
  }

  function actionEvent(cell, row, rowIndex, field) {

    return (
      <div className="d-flex justify-content-center align-items-center">
        {userData.role.id === 1 && (
          <Button
            onClick={() => editClietnModal(row)}
            className=" btn btn-primary cursor-pointer"
          >
            <img src={edit} />
          </Button>
        )}
        <a
          onClick={() => handleShowDetailsClick(row)}
          className="px-3 btn-underline-green-dark cursor-pointer"
        >
          View
        </a>
      </div>
    )
  }

  const changeHandler = ({
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
    selectedCounselor,
    filter,
  }) => {
    const field =
      sortField === null || sortField === undefined ? "updated_at" : sortField
    const sort_dir =
      sortOrder === null || sortOrder === undefined ? "DESC" : sortOrder
    const filterObj = _.find(options, function (o) {
      return o.value === filter
    })
    setIsActive(filterObj)
    setState(s => ({ ...s, search: searchText, sortOrder: sort_dir }))
    if(combinedPage){
      history.push(
        `/direct-non-direct?counsellor=${selectedCounselor}&filter=${filter}&page=${1}`
      )
    }else{
    history.push(
      `/direct-clients?counsellor=${selectedCounselor}&filter=${filter}&page=${1}`
    )}
    getDirectClientListFun(
      1,
      sizePerPage,
      searchText,
      field,
      sort_dir,
      selectedCounselor,
      filterObj
    )
  }

  const delaySearch = useCallback(debounce(changeHandler, 1000), [])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    const field =
      sortField === null || sortField === undefined ? "updated_at" : sortField
    const sort_dir =
      sortOrder === null || sortOrder === undefined ? "DESC" : sortOrder
    setIsSpinner(true)
    if (type === "search") {
      const filter = queryParams.get("filter")
        ? queryParams.get("filter")
        : isActive.value
      delaySearch({
        page,
        sizePerPage,
        searchText,
        sortField,
        sortOrder,
        selectedCounselor,
        filter,
      })
    } else {
      const getCounsellorId = parseInt(queryParams.get("counsellor"))
      setState(s => ({
        ...s,
        page: page,
        search: searchText,
        selectedCounselor: getCounsellorId,
      }))
      setSelectedCounselor(getCounsellorId)
      getDirectClientListFun(
        page,
        sizePerPage,
        searchText,
        field,
        sort_dir,
        getCounsellorId
      )
      if(combinedPage){
        history.push(
          `/direct-non-direct?counsellor=${getCounsellorId}&filter=${isActive.value}&page=${page}`
        )
      }else{
      history.push(
        `/direct-clients?counsellor=${getCounsellorId}&filter=${isActive.value}&page=${page}`
      )
    }
  }
  if(!combinedPage){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  }

  return (
    <React.Fragment>
    {hasPermission() || combinedPage ? (
      <div className={combinedPage ? "" : "page-content"}>
        <div className="container-fluid">
          {combinedPage ? <></> : <div className={classes.headerContainer}>
            <div className="p-2">
              <h4 className="card-title-main">Direct Clients</h4>
              <h5 className={`${style.subtitlemenu} card-para-main`}>
                List of all the direct and other direct clients assigned to each
                counsellor with their details.
              </h5>
            </div>
            <div className={classNames("w-20", classes.conatiner)}>
              <div className="d-inline-flex">
                <Row>
                  <div className="col mt-0">
                    <div
                      className={classNames(
                        "card-title p-1 d-flex m-0",
                        classes.legendContainer
                      )}
                    >
                      <div className="py-2">
                        <div className={`d-flex text-black legend-list `}>
                          <div
                            className={classNames(
                              `d-flex flex-wrap justify-content-end`,
                              classes.legendAlign
                            )}
                          >
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={noApproval}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>No Approval</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={oneSessionOrWeek}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>1 Session/1 Week Left</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={twoSessionOrWeek}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>2 Sessions/2 Weeks Left</span>
                            </div>
                            {userData.role.id === 1 && (
                              <div className="p-2 d-flex align-items-center">
                                <img
                                  src={submitted}
                                  width="20px"
                                  height="20px"
                                  className="direct-client-list-legend-margin"
                                />
                                <span>Submitted</span>
                              </div>
                            )}
                            {userData.role.id === 1 && (
                              <div className="p-2 d-flex align-items-center">
                                <img
                                  src={activatedDeactivated}
                                  width="20px"
                                  height="20px"
                                  className="direct-client-list-legend-margin"
                                />
                                <span>Request to activate/deactivate</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>}
          <Row className="mg-top-default-ui">
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody className="table-custom-redesign">
                {combinedPage && <p className="h4 mx-2 my-3 card-title-main font-weight-bold">
                Direct Clients
                </p>}
                  <Table
                    data={state.data}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    isFilter={true}
                    isSearch={true}
                    selectOptions={options}
                    onSelectChange={onSelectChange}
                    selectValue={isActive}
                    isShowCustomDropdown={true}
                    isStriped={false}
                    isHover={false}
                    isBordered={false}
                    placeholder={"All Active"}
                    CustomDropdown={
                      <CustomDropdown
                        options={counselorList}
                        className={"pointer"}
                        onCounselorChange={onCounselorChange}
                        isDirectClient={true}
                        counsellorId={counsellorId}
                        isVisible={userData.role.id === 1 ? true : false}
                        counsellorChange={counsellorChange}
                      />
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ClientManage clientId={clientId}  setClientId={setClientId} refetchClientData={refetchClientData}/>
        </div>
      </div>
      ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  )
}

export default DirectClientList
