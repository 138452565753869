import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import { GET_BLOG, GET_COUNSELORS, SUBMIT_BLOG } from "./gql/Gql"
import * as Yup from "yup"
import { useFormik } from "formik"
import { validate } from "graphql"
import Loader from "../../components/Common/Loader"
import RichTextBox from "../../common/RichTextBox"
import Dropzone from "react-dropzone"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import Spinner from "../../assets/images/Loader.svg"
import { useSelector } from "react-redux"

const AddBlog = () => {
  let history = useHistory()
  const userRole = useSelector(state => state.Login?.user?.user?.role)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = decodedId
  const [getBlog, getBlogList] = useLazyQuery(GET_BLOG, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(SUBMIT_BLOG)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoader, setIsLoader] = useState(true)
  const [requestPublish, setReqPublish] = useState(true)
  const [initialValues, setInitialValues] = useState({
    name: "",
    status: false,
    main_image: undefined,
    thumbnail_image: undefined,
    post_summary: "",
    post_body: "",
  })
  const [getCounselor, counselorResponse] = useLazyQuery(GET_COUNSELORS, {
    fetchPolicy: "no-cache",
  })
  const [calenderId, setCalenderId] = useState()

  const getCounselorApi = async () => {
    try {
      const res = await getCounselor({
        variables: {
          argumentsInput: {},
        },
      })
      const { code, status, data } = res.data?.getCalendarLegend
      if (code == 200 && status && data?.calendars?.length) {
        setCalenderId(parseInt(data?.calendars?.[0]?.id))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Please enter blog name"),
    }),
    onSubmit: values => {
      setIsSubmit(true)
      let obj = {
        name: values.name,
        status: values.status ? "active" : "inactive",
        post_summary: values.post_summary,
        post_body: values.post_body,
        main_image: values.main_image?.url ?? "",
        thumbnail_image: values.thumbnail_image?.url ?? "",
      }
      if (id) {
        obj.id = parseInt(id)
      }
      obj.is_approved = userRole.id === 1 ? true : false
      obj.is_requested = userRole.id === 1 ? false : requestPublish
      if (userRole.id !== 1) {
        obj.calendar_id = calenderId
      }
      obj.status =
        userRole.id === 1
          ? values.status
            ? "active"
            : "inactive"
          : requestPublish
          ? "active"
          : "inactive"
      submitMutation({
        variables: {
          createBlogInput: obj,
        },
      })
        .then(res => {
          toast.success(`Blog ${id ? "updated" : "created"} successfully`)
          setTimeout(() => {
            if (id) {
              setReqPublish(true)
              setIsSubmit(false)
            }else{
              history.push("/blogs")
            }
          }, 1500)
        })
        .catch(err => {
          toast.error("something went wrong !")
          setReqPublish(true)
          setIsSubmit(false)
        })
    },
  })

  useEffect(() => {
    if (userRole.id !== 1) {
      getCounselorApi()
    }
    if (id) {
      setIsLoader(true)
      getBlog({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [id])

  useEffect(() => {
    if (getBlogList.data && getBlogList.data?.blog?.name) {
      setInitialValues({
        name: getBlogList.data.blog.name,
        post_summary: getBlogList.data.blog.post_summary,
        post_body: getBlogList.data.blog.post_body,
        main_image: getBlogList.data.blog.main_image
          ? {
              state: "uploaded",
              url: getBlogList.data.blog.main_image,
            }
          : undefined,
        thumbnail_image: getBlogList.data.blog.thumbnail_image
          ? {
              state: "uploaded",
              url: getBlogList.data.blog.thumbnail_image,
            }
          : undefined,
        status: getBlogList.data.blog.status == "active" ? true : false,
      })
      settoggleSwitch(getBlogList.data.blog.status == "active" ? true : false)
      setIsLoader(false)
    } else {
      setIsLoader(false)
    }
  }, [getBlogList.data])

  const handleToggle = e => {
    setInitialValues(prev => {
      return { ...validation.values, status: !validation.values.status }
    })
  }

  const handleImg = async (acceptedFiles, field) => {
    if (acceptedFiles[0].size > 5 * 1024 * 1000) {
      toast.error("Max File Upload Size is Upto 5 MB, try with smaller size")
      return
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }
    validation.setFieldValue(field, preview)

    let payload = new FormData()
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined

    payload.set("file", acceptedFiles[0])
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        {
          method: "POST",
          body: payload,
          headers: {
            organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

          },
        }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        validation.setFieldValue(field, preview)
      } else {
        validation.setFieldValue(field, undefined)
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      console.log(e)
      validation.setFieldValue(field, undefined)
      toast.error("Failed to upload cover image")
    }
  }

  return (
    <div className="page-content">
      {isLoader ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div>
              <div className="py-3">
                <div className="flex align-item-center">
                  <i
                    className="waves-effect mdi color-dark-green mdi-arrow-left font-size-22 mr-2"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className="mt-1 mx-2 card-title-main">
                    {" "}
                    {id ? "" : "Create New "} Blog
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={8}>
              <Card className="transparent-bg-card">
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="form-custom-white-inputs">
                      <Col xl={12} md={12}>
                        <Label className="form-label">Blog</Label>
                        <Input
                          name="name"
                          className="form-control"
                          placeholder="Enter Blog Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && (
                          <div className={style.warnText}>
                            {validation.errors.name}
                          </div>
                        )}
                      </Col>

                      {userRole.id === 1 && (
                        <Col xl={12} md={12} className="my-3">
                          <div className="form-check form-switch">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitch2"
                              checked={validation.values.status}
                              onChange={e => handleToggle(e)}
                              onClick={e => handleToggle(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customSwitch2"
                            >
                              Publish
                            </label>
                          </div>
                        </Col>
                      )}

                      <Col xl={6} md={12} className="mt-3">
                        <Label className="form-label">Main Image</Label>
                        <Dropzone
                          multiple={false}
                          onDrop={file => handleImg(file, "main_image")}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <input {...getInputProps()} />

                              {!validation?.values?.main_image ? (
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload" />
                                  </div>
                                  <h4>Drop JPEG, JPG or PNG.</h4>
                                  <span className="defaultText">
                                    It can be square, landscape or portrait.
                                    Upto 5MB file size.
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {validation?.values?.main_image?.state ===
                                    "uploading" && (
                                    <img
                                      className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                      alt="primary"
                                      src={Spinner}
                                    />
                                  )}
                                  {validation?.values?.main_image?.state ===
                                    "uploaded" && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          validation.setFieldValue(
                                            "main_image",
                                            undefined
                                          )
                                        }}
                                        className={`${style.removeButton} mdi mdi-close`}
                                        type="button"
                                        aria-label="Close"
                                      ></button>

                                      <img
                                        {...getRootProps()}
                                        className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                        alt="primary"
                                        src={
                                          validation?.values?.main_image?.url
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </Col>

                      <Col xl={6} md={12} className="mt-3">
                        <Label className="form-label">Thumbnail Image</Label>
                        <Dropzone
                          multiple={false}
                          onDrop={file => handleImg(file, "thumbnail_image")}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <input {...getInputProps()} />

                              {!validation?.values?.thumbnail_image ? (
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload" />
                                  </div>
                                  <h4>Drop JPEG, JPG or PNG.</h4>
                                  <span className="defaultText">
                                    It can be square, landscape or portrait.
                                    Upto 5MB file size.
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {validation?.values?.thumbnail_image
                                    ?.state === "uploading" && (
                                    <img
                                      className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                      alt="primary"
                                      src={Spinner}
                                    />
                                  )}
                                  {validation?.values?.thumbnail_image
                                    ?.state === "uploaded" && (
                                    <div>
                                      <button
                                        onClick={() => {
                                          validation.setFieldValue(
                                            "thumbnail_image",
                                            undefined
                                          )
                                        }}
                                        className={`${style.removeButton} mdi mdi-close`}
                                        type="button"
                                        aria-label="Close"
                                      ></button>

                                      <img
                                        {...getRootProps()}
                                        className="needsclick avatar-sm rounded bg-light w-100 h-auto"
                                        alt="primary"
                                        src={
                                          validation?.values?.thumbnail_image
                                            ?.url
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </Col>

                      <Col xl={12} md={12} className="mt-3">
                        <div className="my-3">
                          <Label className="form-label">Post Summary</Label>
                          <RichTextBox
                            name="post_summary"
                            value={initialValues.post_summary || ""}
                            onChange={value =>
                              validation.setFieldValue("post_summary", value)
                            }
                            validation={validation}
                            onBlur={() => {
                              validation.setFieldTouched("post_summary", true)
                            }}
                            error={validation.errors?.post_summary}
                            touched={validation.touched?.post_summary}
                          />
                        </div>
                      </Col>
                      <Col xl={12} md={12}>
                        <div className="my-3">
                          <Label className="form-label">Post Body</Label>
                          <RichTextBox
                            name="post_body"
                            value={initialValues.post_body || ""}
                            onChange={value =>
                              validation.setFieldValue("post_body", value)
                            }
                            validation={validation}
                            onBlur={() => {
                              validation.setFieldTouched("post_body", true)
                            }}
                            error={validation.errors?.post_body}
                            touched={validation.touched?.post_body}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col xs={12} className="text-end ">
                        <button
                          className="btn btn-inactive mx-2"
                          type="button"
                          onClick={() => {
                            history.push("/blogs")
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary mx-2"
                          type="submit"
                          disabled={isSubmit}
                        >
                          {userRole.id !== 1
                            ? "Request to Publish"
                            : id
                            ? "Update"
                            : "Add"}
                        </button>
                        {userRole.id !== 1 && (
                          <button
                            className="btn btn-primary "
                            type="button"
                            disabled={isSubmit}
                            onClick={() => {
                              setReqPublish(false)
                              validation.handleSubmit()
                            }}
                          >
                            Save as Draft
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}
export default AddBlog
