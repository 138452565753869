import { useLazyQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { LINKED_ACCOUNT_STATUS } from "../Appointments/gql/Gql"
import { Button } from "reactstrap"
import { useSelector } from "react-redux"

const UserTabs = ({ activeTab, setActiveTab }) => {
  const userData = useSelector(state => state.Login?.user?.user)
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
  const showDetails = data => {
    setActiveTab(data)
  }

  const encodedAccountData = localStorage.getItem("accountData")
  const accountStatus = encodedAccountData
    ? JSON.parse(atob(encodedAccountData))
    : null

  const [accountDetailsStatus, setAccountDetailsStatus] = useState(false)

  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])

  const [bottonArr, setBottonArr] = useState([])

  useEffect(() => {
    const initialArr = [
      { name: "Profile", type: "profile" },
      { name: "Notes", type: "notes" },
      { name: "Files", type: "files" },
    ]

    if (userData.role.id === 1) {
      initialArr.splice(1, 0, { name: "Edit/Settings", type: "settings" })
    }

    setBottonArr(initialArr)
  }, [userData.role.id])

  useEffect(() => {
    if (accountDetailsStatus && userData.role.id === 1 ) {
      setBottonArr(prevState => [
        ...prevState,
        {
          name: "Billing",
          type: "billing",
        },
      ])
    }
  }, [accountDetailsStatus])

  return (
    <>
      <div className="w-100 btn-group d-flex flex-wrap">
        {bottonArr.map(elem => (
          <Button
            key={elem.type}
            className={`border ${
              orgId == 1 ? "border-dark text-dark" : ""
            } font-weight-bold btn mx-2 h6 ${
              activeTab?.type === elem.type
                ? "btn-primary rounded text-white"
                : "btn-light rounded"
            }`}
            onClick={() => showDetails(elem)}
          >
            {elem.name}
          </Button>
        ))}
      </div>
    </>
  )
}

export default UserTabs
