import React, { useEffect, useState } from "react"
import { Button, Row, Col } from "reactstrap"
import classnames from "classnames"
import { useLazyQuery } from "@apollo/client"
import { GET_COUNSELORS, LINKED_ACCOUNT_STATUS } from "./gql/Gql"
import "react-toastify/dist/ReactToastify.css"
import style from "./style.module.scss"
import AppointmentCalender from "./AppointmentCalender"
import CustomDropdown from "../Client/components/CustomDropdown"
import { useHistory, useLocation } from "react-router-dom"
import Loader from "../../components/Common/Loader"
import AppointmentListView from "./AppointmentListView"
import { useSelector } from "react-redux"
import Select from "react-select"
import PageNotFound from "../404"
import { GET_PERMISSIONS_LIST } from "../Organizations/gql/Gql"
import CardWarning from "./CardWarning"

const AppointmentList = () => {
  const history = useHistory()
  const location = useLocation()
  const userData = useSelector(state => state.Login?.user?.user)
  const [activeTabJustify2, setactiveTabJustify2] = useState("calendar")
  const [counselors, setCounslers] = useState([])
  const [isSpinner, setIsSpinner] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [selectedCounselor, setSelectedCounselor] = useState(undefined)
  let counsellorId = null
  let clientName = new URLSearchParams(location.search).get("client")
  if (
    location.search &&
    location.search.includes(`?tab=${activeTabJustify2}&counsellor=`)
  ) {
    counsellorId = parseInt(
      location.search.replace(`?tab=${activeTabJustify2}&counsellor=`, "")
    )
  }

  function toggleCustomJustified2(tab) {
    setactiveTabJustify2(tab)
    let sessionParams = sessionStorage.getItem("appointmentParams")
    let paramsObject = JSON.parse(sessionParams);
      paramsObject.search = "";
      let updatedSessionParams = JSON.stringify(paramsObject);
      sessionStorage.setItem("appointmentParams", updatedSessionParams);
    history.push(`/appointments?tab=${tab}&counsellor=${counsellorId}`)
    currentUrl.searchParams.delete("client")
    window.history.replaceState({}, document.title, currentUrl.href)
  }

  React.useEffect(() => {
    getCounselorList({
      variables: {
        argumentsInput: {
          // "page": page,
          // "limit": sizePerPage,
        },
      },
    })

    if (location.search && location.search.includes("list")) {
      setactiveTabJustify2("list")
    }
  }, [])

  const [getCounselorList, counselorsResponse] = useLazyQuery(GET_COUNSELORS, {
    fetchPolicy: "no-cache",
  })

  React.useEffect(() => {
    // if (!counselorsResponse) return
    if (
      counselorsResponse.data &&
      counselorsResponse.data.getCalendarLegend &&
      counselorsResponse.data.getCalendarLegend.data
    ) {
      setIsSpinner(false)
      setSpinner(false)
      let data = counselorsResponse.data.getCalendarLegend.data.calendars.map(
        n => {
          return {
            id: n.id,
            name: n.name,
            document_submitted_on_status: n.document_submitted_on_status,
            request_status: n.request_status,
            approval_status: n.approval_status,
          }
        }
      )
      setCounslers(data)
    } else {
      setIsSpinner(true)
    }
  }, [counselorsResponse.data])

  function getCounselorListFun(search) {
    setSpinner(true)
    getCounselorList({
      variables: {
        argumentsInput: {
          search: search || "",
          is_direct: true,
        },
      },
    })
  }

  React.useEffect(() => {
    getCounselorListFun()
  }, [])

  const onCounselorChange = counselor => {
    if(clientName){
    history.push(
      `/appointments?tab=${activeTabJustify2}&counsellor=${counselor}&client=${clientName}`
    )
    }else{
      history.push(
        `/appointments?tab=${activeTabJustify2}&counsellor=${counselor}`
      )
    }
    setSelectedCounselor(counselor)
    let sessionParams = sessionStorage.getItem("appointmentParams")
    if(sessionParams){
    let paramsObject = JSON.parse(sessionParams);
      paramsObject.search = "";
      let updatedSessionParams = JSON.stringify(paramsObject);
      sessionStorage.setItem("appointmentParams", updatedSessionParams);
    }
  }
  const [selectedOption, setSelectedOption] = useState(null)

  const [options, setOptions] = useState([])

  useEffect(() => {
    if (counselors) {
      const sortedList = _.sortBy(counselors, [
        function (option) {
          return option.name
        },
      ])
      const newOptions = sortedList.map(elem => ({
        value: elem.id,
        label: elem.name,
      }))
      setOptions(newOptions)
    }
  }, [counselors])

  const openCreateAppointment = () => {
    if (userData.role.id === 2) {
      const encodedId = btoa(counsellorId)
      history.push(`/create-appointment/${encodedId}`)
    } else {
      history.push("/create-appointment")
    }
  }

  const handleSelectChange = selectedOption => {
    setSelectedOption(selectedOption)
    history.push(
      `/appointments?tab=${activeTabJustify2}&counsellor=${selectedOption.value}`
    )

    setSelectedCounselor(selectedOption.value)
  }

  const handleInputChange = inputValue => {
    getCounselorListFun(inputValue)
  }

  useEffect(() => {
    if (options.length > 0 && !selectedOption && !counsellorId) {
      onCounselorChange(options[0].value)
    }
  }, [options])

  const [getPermissionOrgWise, getPermissionOrgWiseList] = useLazyQuery(
    GET_PERMISSIONS_LIST,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [isLoading, setIsLoading] = useState(true)
  const [hasPermission, setHasPermission] = useState(false)
  const [slugArrayList, setSlugArrayList] = useState([])

  useEffect(() => {
    const storedData = localStorage.getItem("orgData")
    const orgData = storedData ? JSON.parse(storedData) : null

    if (userData) {
      setIsLoading(true)
      getPermissionOrgWise({
        variables: {
          argumentsInput: {
            role_id: Number(userData?.role.id),
            organization_id: orgData ? orgData.id : 1,
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data &&
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role &&
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role
        ?.rolePermissions
    ) {
      const moduleList =
        getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role
          ?.rolePermissions
      const slugArray = moduleList.map(permission => permission.method.slug)
      setHasPermission(slugArray.includes("appointments"))
      setSlugArrayList(slugArray)
      setIsLoading(false)
    }
  }, [getPermissionOrgWiseList.data])
  const encodedAccountData = localStorage.getItem("accountData")
  const accountStatus = encodedAccountData
  ? JSON.parse(atob(encodedAccountData))
  : null

  const [accountDetailsStatus, setAccountDetailsStatus] = useState()

  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])

  return isLoading ? (
    <div className="mainLoader">
      <Loader />
    </div>
  ) : (
    <React.Fragment>
      {!hasPermission ? (
        <PageNotFound />
      ) : (
        <div className="page-content">
          <div className="container-fluid">
            <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center mgbottom-appointment-list-50">
              <div className="p-2">
                <h4 className="card-title-main">Appointments</h4>
                <h5 className={`${style.subtitlemenu} card-para-main`}>
                  List of all the appointments booked by the clients and
                  counsellors.
                </h5>
              </div>
              <div className="ml-auto p-2 w-20">
                <div className="d-inline-flex">
                  <Row className="align-items-stretch flex-wrap">
                    {slugArrayList.includes("create-appointment") && (
                      <div className="col custom-date-picker-style-appointments mt-0">
                        <Button
                          type="button "
                          className="btn btn-primary appointment-list-toggle-button"
                          onClick={() => openCreateAppointment()}
                        >
                          <i className="uil uil-plus"></i>
                          <span className="d-inline-block">
                            Add Appointment
                          </span>
                        </Button>
                      </div>
                    )}

                    <div className="col-auto">
                      <Button
                        type="button"
                        className={` ${
                          activeTabJustify2 === "list"
                            ? "appointment-list-toggle-button btn-primary"
                            : "btn-inactive appointment-list-button"
                        } `}
                        onClick={() => {
                          toggleCustomJustified2("list")
                        }}
                      >
                        <i className="uil uil-bars me-2"></i>
                        <span className="d-inline-block  me-2">List</span>
                      </Button>
                      &nbsp;
                      <Button
                        type="button"
                        className={` ${
                          activeTabJustify2 === "calendar"
                            ? "appointment-list-toggle-button  btn-primary"
                            : "btn-inactive appointment-list-button"
                        } `}
                        onClick={() => {
                          toggleCustomJustified2("calendar")
                        }}
                      >
                        <i className="uil uil-calendar-alt me-2"></i>
                        <span className="d-inline-block  me-2 ">Calendar</span>
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
            {userData.role.id === 3 && (accountStatus && accountDetailsStatus) && (
              <div className={`p-0 warning-section ${isOpen ? " open" : ""}`}>
                <CardWarning
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  userId={userData.role.id}
                />
              </div>
            )}
            <div className="col custom-date-picker-style-appointments w-25 mt-0 mb-3 ">
              <CustomDropdown
                options={counselors}
                selectedCounselor={selectedCounselor}
                onCounselorChange={onCounselorChange}
                className={"default-redesign-input "}
                isHovered={false}
                isDirectClient={false}
                counsellorId={counsellorId}
                isVisible={userData.role.id === 1 ? true : false}
              />
            </div>
            {activeTabJustify2 === "list" && (
              spinner ? (
                <Row>
                  <Col className="col-12 mg-top-default-ui user-role-create-height">
                    <div className="spinner">
                      <Loader />
                    </div>
                  </Col>
                </Row>
              ) : (
              <AppointmentListView selectedCounselor={selectedCounselor} />
            ))}

            {activeTabJustify2 === "calendar" &&
              (isSpinner ? (
                <Row>
                  <Col className="col-12 mg-top-default-ui user-role-create-height">
                    <div className="spinner">
                      <Loader />
                    </div>
                  </Col>
                </Row>
              ) : (
                <AppointmentCalender
                  counselors={selectedCounselor}
                  className="mg-top-default-ui"
                  counselorsResponse={counselorsResponse}
                />
              ))}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default AppointmentList
