import React, { useCallback, useState, useEffect } from "react";
import Table from "../../../../components/Common/Table";
import { GET_EXPANDED_DATA } from "../gql/gql";
import { useLazyQuery } from "@apollo/client";
import CheckboxField from "./CheckboxField";
import moment from "moment";
import { Input, FormGroup, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setClientSelectedAppointments } from "../../../../store/actions";
import Loader from "../../../../components/Common/Loader";

function ExpandedReceiptRow(props) {

    const { parentRow } = props;
    const clientId = parentRow.client_id;
    const dispatch = useDispatch();
    const selectedClientAppointments = useSelector(state => state.receiptManagement.selectedClientAppointments);
    const [appointmentList, setAppointmentList] = useState([]);
    const [isSpinner, setIsSpinner] = useState(true);
    const [selectedId, setselectedId] = useState({});
    const [selectedList, setSelectedList] = useState([]);
    const currentObj = selectedClientAppointments.find(n => n.id == clientId);
    const [state, setState] = useState({
        page: 1,
        data: appointmentList,
        sizePerPage: 20,
        sortBy: "id",
        sortOrder: "DESC",
        search: ""
    });

    useEffect(() => {
        if (currentObj.selectedList.length > 0) {
            const arr = currentObj.selectedList;
            const obj = {};
            arr.forEach(val => {
                obj[val] = true;
            });
            setselectedId(obj);
            setSelectedList([...arr]);
        }
        else {
            setselectedId({});
            setSelectedList([]);
        }
    }, [currentObj.selectedList.length]);

    //************************** appointment list ***************************** */

    const [getAppointmentDateList, listResponse] = useLazyQuery(GET_EXPANDED_DATA, {
        fetchPolicy: "no-cache",
    });

    function getAppointmentDateListFun() {
        getAppointmentDateList({
            variables: {
                getAppointmentReceiptManagement: {
                    "client_id": parseInt(parentRow.client_id),
                }
            },
        })
    }

    useEffect(() => {
        getAppointmentDateListFun();
    }, []);

    useEffect(() => {
        if (listResponse.data && listResponse.data.getAppointmentReceiptManagement && listResponse.data.getAppointmentReceiptManagement.status && listResponse.data.getAppointmentReceiptManagement.code === 200) {
            setIsSpinner(false);
            setAppointmentList(listResponse.data.getAppointmentReceiptManagement.data.appointments);
            setState(s => ({ ...s, data: listResponse.data.getAppointmentReceiptManagement.data.appointments }));
        }
        else if (listResponse.data && listResponse.data.getAppointmentReceiptManagement && listResponse.data.getAppointmentReceiptManagement.status && listResponse.data.getAppointmentReceiptManagement.code !== 200) {
            setIsSpinner(false);
            setAppointmentList([]);
            setState(s => ({ ...s, data: [] }));
        }
        else {
            setIsSpinner(true);
        }
    }, [listResponse.data]);

    //************************** Selected Data ***************************** */

    const onSelectAppointment = (id, isSelected) => {
        setselectedId((prevSelectedRows) => ({
            ...prevSelectedRows,
            [id]: isSelected,
        }));
    }

    useEffect(() => {
        const keys = Object.keys(selectedId);
        const filteredObj = keys.filter((key) => {
            return selectedId[key];
        });
        const filteredArray = filteredObj.map(str => {
            return Number(str);
        });
        setSelectedList(filteredArray);
    }, [selectedId]);

    useEffect(() => {
        if (!isSpinner) {
            dispatch(setClientSelectedAppointments(clientId, selectedList));
        }
    }, [selectedList]);

    //************************** Table Data ***************************** */

    const expandColumns = [
        {
            text: "",
            dataField: "appointment_id",
            formatter: rowSelectedFun,
            style: {
                width: 20,
            },
        },
        {
            text: "",
            dataField: "date",
            formatter: (value) => {
                return moment(value).format("DD-MMM-yyyy");
            },
            style: {
                width: "calc(100% - 20px)",
            },
        },
    ];

    function rowSelectedFun(value, row, index, field) {
        return <CheckboxField rowValue={value} row={row} index={index} field={field} clientId={clientId} onSelectRow={onSelectAppointment} />
    }

    return (
        <div className="expand-table">
            {isSpinner ?
                <Col className="col-12">
                    <div className="text-center">
                        <Loader />
                    </div>
                </Col>
                : <Table
                    data={state.data}
                    columns={expandColumns}
                    noDataIndication={'No Data Found'}
                    keyField="appointment_id"
                    loading={false}
                    isSearch={false}
                    isStriped={false}
                    isPagination={false}
                    isBordereded={false}
                />
            }

        </div>
    )
}

export default ExpandedReceiptRow