import { gql } from "@apollo/client";

export const GET_DASHBOARD_ANALYTICS = gql`
query CounselordashboardGraph($dashboardInput: DashboardInput!) {
  CounselordashboardGraph(dashboardInput: $dashboardInput) {
    code
    data {
      total_billable_count
      Billing_percentage
      Billing_percentage_status
      session_completed
      non_direct_deactive_client
      non_direct_active_client
      deactive_other
      active_other
      active_icbc
      deactive_icbc
      deleted_clients
      deleted_clientPercentage
      no_show_counts
      no_show_total_counts
      no_show_percentage
      total_clients
      total_appoinments
      internal_Sessions
      external_sessions
      appointment_type_graph {
        appointments_type
        percentage
        __typename
      }
      __typename
    }
    __typename
    message
    status
  }
}
`

export const GET_APPOINTMENTS = gql`
query GetAppointments($argumentsInput: ArgumentsInput) {
  getAppointments(argumentsInput: $argumentsInput) {
    code
    data {
      appointments_arr {
        appointment {
        id 
        appointment_id
        first_name
        last_name
        date
        email
        phone
        utc_datetime
        duration
        amount_paid
        price
        type_of_appointment{
          id
          name
          call_type
          google_address
        }
        client {
          name
          first_name
          last_name
        }
        type
      }
    }
      totalCount
    }
    message
    status
  }
}`

export const GET_MONTH_WISE_CLIENT_DATA = gql`
mutation ClientCounsellorGraph($clientCounsellorGraph: DashboardInput!) {
  clientCounsellorGraph(clientCounsellorGraph: $clientCounsellorGraph) {
      data {
      year_wise_client_data {
        count
        month
        month_name
        year
      }
      month_wise_client_data {
        count
        day
      }
    }
  }
}
`

export const GET_ANALYTICS_CHART_DETAILS = gql`
mutation DashboardGraph($dashboardGraph: DashboardInput!) {
    dashboardGraph(dashboardGraph: $dashboardGraph) {
      code
      message
      status
      data {
        year_wise_appointment_data {
          count
          month
          month_name
          year
        }
        month_wise_appointment_data {
          count
          day
        }
        yearly_billable_count {
          total
        count
        month
        month_name
        year
      }
      monthly_billable_count {
        total
       count
       day
      }
      }
    }
  }
  `
export const GET_APPROVAL_REQUIRED_DETAILS = gql`query GetPlans($argumentsInput: ArgumentsInput) {
  getPlans(argumentsInput: $argumentsInput) {
    code
    message
    data {
      totalCount
      plans {
        expiry_date
        id
        alerts_enabled
        total_sessions
        approval_status
        expiry_date
        session_status
        is_document_submitted
        document_submitted_on
        approved_sessions
        billed_sessions
        created_at
        request {
          id
          is_approved
          created_at
          remarks
          requestor{
              first_name
              last_name
          }
          __typename
        }
        client {
          age
          id
          name
          first_name
          last_name
          __typename
        }
        payment_source {
          name
          id
          is_direct
          __typename
        }
        counsellor {
          id
          email
          __typename
        }
        __typename
      }
      totalCount
      __typename
    }
    status
    __typename
  }
}`