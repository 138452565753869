const { makeStyles } = require("@material-ui/styles")

export const useStyles = makeStyles(theme => ({
  //index

  container: {
    "@media (max-width: 992px)": {
      gap: "10px",
    },
  },

  cardContainer: {
    minHeight: "calc(100vh - 245px)",
  },

  emptyPageContent: {
    minHeight: "calc(100vh - 350px)",
    fontWeight: 500,
    lineHeight: "23px",
  },

  // Tabs

  tabBar: {
    borderBottom: "1px solid #DCDDDF",
    "& li": {
      padding: "5px 15px",
      cursor: "pointer",
      color: "#74788D",
      fontSize: "16px",
      fontWeight: 600,
      display: "flex",
      justifyContent: "center",
    },
    "& .active": {
      borderBottom: "2px solid #153c27",
      color: "#1D2939",
    },
    "@media (max-width: 768px)": {
      flexWrap: "nowrap",
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "& li": {
        width: "100%",
        height: "35px",
        whiteSpace: "nowrap",
      },
    },
  },

  // Client Appointments

  hideTableHeader: {
    "& table thead": {
      display: "none",
    },
    "& table tr": {
      borderBottom: "1px solid #DCDDDF",
      "&:last-child": {
        borderBottom: "none",
      },
    },
    "& .expand-table table thead": {
      display: "none",
    },
    "& .expand-table table": {
      borderBottom: "none",
    },
    "& .expand-table table tr": {
      borderBottom: "none",
    },
    "& table .expand-cell": {
      width: 80,
    },
  },

  btnAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& button": {
      padding: "0px 10px",
      height: "28px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: "20px",
        padding: 0,
      },
    },
  },

  selectFilter: {
    minWidth: "200px",
    "& .css-yk16xz-control": {
      borderRadius: "8px",
      padding:"1px"
    },
  },

  // Preview

  invoiceContainer: {
    width: "100%",
    background: "rgba(243, 248, 251)",
    padding: "20px",
    "& input": {
      marginTop: "-7px",
    },
    "& p": {
      color: "rgb(73, 80, 87)",
    },
    "@media (max-width: 768px)": {
      "& p": {
        marginTop: "5px",
      },
      "& input": {
        marginTop: "-5px",
      },
    },
    "@media (max-width: 570px)": {
      gap: "10px",
    },
  },

  button: {
    "@media (max-width: 570px)": {
      padding: "0.25rem 0.5rem",
      fontSize: "0.7875rem",
      borderRadius: "0.2rem",
    },
  },

  removeTableBorder: {
    "& table tr": {
      border: "1px solid #DCDDDF",
    },
  },

  // Exported Invoice

  hideTableBorder: {
    "& table thead": {
      borderBottom: "1px solid #DCDDDF",
    },
    "& table tr": {
      borderBottom: "1px solid #DCDDDF",
    },
  },

  clientAppointmentsItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  clientAppointmentsItemName: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "21px",
    display: "inline-block",
    marginLeft: "16px",
  },
}))

export default useStyles
