import { gql } from "@apollo/client"
export const GET_LOCATIONS = gql`
  query Query($argumentsInput: ArgumentsInput) {
    GetAllLocation(argumentsInput: $argumentsInput) {
      code
      data {
        Locations {
          id
          slug
          name
        }
      }
    }
  }
`
export const GET_LOCATION = gql`
  query Query($getLocationGroupInput: createLocationGroupInput!) {
    getLocationGroup(getLocationGroupInput: $getLocationGroupInput) {
      id
      name
      slug
      status
      subLocation {
        id
        name
      }
      updated_at
    }
  }
`
export const SUBMIT_LOCATION = gql`
  mutation CreateAndUpdateLocationGroup(
    $createAndUpdateLocationGroupInput: createLocationGroupInput!
  ) {
    createAndUpdateLocationGroup(
      createAndUpdateLocationGroupInput: $createAndUpdateLocationGroupInput
    ) {
      id
      name
      slug
      created_at
      updated_at
      status
    }
  }
`
export const DEL_LOCATION = gql`
  mutation RemoveLocationGroup($locationGroupInput: createLocationGroupInput!) {
    removeLocationGroup(locationGroupInput: $locationGroupInput) {
      name
    }
  }
`
