import React, { useState } from "react"
import Chat from "./Chat"
import Details from "./Details"
import { makeStyles } from "@material-ui/core"
import useStyles from "./style"
import classNames from "classnames"

function Tabs({ appointmentDetails, appointmentForms }) {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState("tab1")

  const handleTab1 = () => {
    setActiveTab("tab1")
  }

  const handleTab2 = () => {
    setActiveTab("tab2")
  }

  return (
    <div className={`Tabs h-100 ${classes.pb80}`}>
      <ul className={classNames("nav", classes.nav)}>
        <li
          className={classNames(
            activeTab === "tab1" ? classes.tabActive : "",
            classes.tabTitle,
            "card-dark-bg appointments-card-title-bg"
          )}
          onClick={handleTab1}
        >
          <span>Details</span>
        </li>
      </ul>
      <div className={classNames("outlet light-green-bg-card-details-table", classes.outlet)}>
        {activeTab === "tab1" ? (
          <Details
            appointmentDetails={appointmentDetails}
            appointmentForms={appointmentForms}
          />
        ) : (
          <Chat />
        )}
      </div>
    </div>
  )
}

export default Tabs
