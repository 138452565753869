import React, { useState, useEffect } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import useStyles from "../Style"
import "react-toastify/dist/ReactToastify.css"
import _ from "lodash"
import classNames from "classnames"
import ExtraToolkit from "../components/ExtraToolkit"
import TabContent from "../../../components/Common/TabBar/TabContent"
import TabNavItem from "../../../components/Common/TabBar/TabNavItem"
import ClientAppointments from "./components/ClientAppointments"
import { GET_APPOINTMENTS, GET_CLIENTS } from "./gql/gql"
import { useLazyQuery } from "@apollo/client"
import { capitalizeFirstLetter, hasPermission } from "../../../functions/functions"
import Loader from "../../../components/Common/Loader"
import PreviewInfo from "./components/PreviewInfo"
import CustomSelect from "../../../components/CustomSelect"
import PageNotFound from "../../404"

export const ShowContent = () => {
  const classes = useStyles()

  return (
    <div
      className={classNames(
        "h-100 w-100 d-flex justify-content-center align-items-center",
        classes.emptyPageContent
      )}
    >
      There appears to be nothing here
    </div>
  )
}

function ReceiptManagement() {
  const classes = useStyles()
  const [selectedClient, setSelectedClient] = useState(undefined)
  const [activeTab, setActiveTab] = useState("clientAppointments")
  const [isTabContentVisible, setIsTabContentVisible] = useState(false)
  const [isPreviewTabVisible, setIsPreviewTabVisible] = useState(false)
  const [previewTabData, setPreviewTabData] = useState([])
  const [clientList, setClientList] = useState([])

  //************************** client list ***************************** */

  const [getAppointmentDateList, listResponse] = useLazyQuery(GET_CLIENTS, {
    fetchPolicy: "no-cache",
  })

  function getAppointmentDateListFun() {
    getAppointmentDateList({
      variables: {
        getAppointmentReceiptManagement: {
          search: "",
          role_id:3,
        },
      },
    })
  }

  useEffect(() => {
    getAppointmentDateListFun()
  }, [])

  useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointmentReceiptManagement &&
      listResponse.data.getAppointmentReceiptManagement.status &&
      listResponse.data.getAppointmentReceiptManagement.code === 200
    ) {
      const data = listResponse.data.getAppointmentReceiptManagement.data
        ? listResponse.data.getAppointmentReceiptManagement.data.appointments.map(
            obj => ({
              name: `${
                obj.first_name
                  ? capitalizeFirstLetter(obj.first_name)
                  : capitalizeFirstLetter(obj.name ? obj.name : "")
              } ${obj.last_name ? capitalizeFirstLetter(obj.last_name) : ""}`,
              id: parseInt(obj.client_id),
            })
          )
        : []
      setClientList(data)
    } else if (
      listResponse.data &&
      listResponse.data.getAppointmentReceiptManagement &&
      listResponse.data.getAppointmentReceiptManagement.status &&
      listResponse.data.getAppointmentReceiptManagement.code !== 200
    ) {
      setClientList([])
      setIsSpinner([])
    } else {
      setClientList([])
      setIsSpinner([])
    }
  }, [listResponse.data])

  //************************** appointment list ***************************** */

  const [isSpinner, setIsSpinner] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [appointmentCount, setAppointmentCount] = useState(0)
  const [clientAppointments, setClientAppointments] = useState([])
  const [state, setState] = useState({
    page: 1,
    data: clientAppointments,
    limit: 20,
  })

  const [getAppointmentList, appointmentListResponse] = useLazyQuery(
    GET_APPOINTMENTS,
    {
      fetchPolicy: "no-cache",
    }
  )

  function getAppointmentListFun(page, limit) {
    getAppointmentList({
      variables: {
        getAppointmentReceiptManagement: {
          page,
          limit,
          search: selectedClient ? selectedClient.toString() : "",
        },
      },
    })
      .then(() => {
        setState(p => ({
          ...p,
          page,
          limit,
        }))
      })
      .catch(() => {
        setState(p => ({
          ...p,
          page,
          limit,
        }))
      })
  }

  useEffect(() => {
    if (clientList.length > 0) {
      getAppointmentListFun(state.page, state.limit)
      setIsPreviewTabVisible(false)
      setActiveTab("clientAppointments")
      setIsTabContentVisible(true)
    } else {
      setIsTabContentVisible(false)
    }
  }, [])

  useEffect(() => {
    if (
      appointmentListResponse.data &&
      appointmentListResponse.data.getAppointmentReceiptManagement &&
      appointmentListResponse.data.getAppointmentReceiptManagement.status &&
      appointmentListResponse.data.getAppointmentReceiptManagement.code === 200
    ) {
      setIsSpinner(false)
      setTotalCount(
        appointmentListResponse.data.getAppointmentReceiptManagement.data
          .total_records
      )
      setIsTabContentVisible(true)
      setClientAppointments(
        appointmentListResponse.data.getAppointmentReceiptManagement.data
          .appointments
      )
      setAppointmentCount(
        appointmentListResponse.data.getAppointmentReceiptManagement.data
          .total_appointments_count
      )
      setState(s => ({
        ...s,
        data: appointmentListResponse.data.getAppointmentReceiptManagement.data
          .appointments,
      }))
    } else if (
      appointmentListResponse.data &&
      appointmentListResponse.data.getAppointmentReceiptManagement &&
      appointmentListResponse.data.getAppointmentReceiptManagement.status &&
      listResponse.data.getAppointmentReceiptManagement.code !== 200
    ) {
      setIsSpinner(false)
      setClientAppointments([])
      setAppointmentCount(0)
      setTotalCount(0)
      setState(s => ({ ...s, data: [] }))
    } else {
      setIsSpinner(true)
      setAppointmentCount(0)
      setTotalCount(0)
    }
  }, [appointmentListResponse.data])

  //************************** functions ***************************** */

  const handleClientChange = value => {
    setSelectedClient(value.id)
  }

  const handleShowPreviewTab = (value, data) => {
    setIsPreviewTabVisible(value)
    if (value) {
      setActiveTab("preview")
      setPreviewTabData([...data])
    }
  }

  const onSearch = (startDate, endDate) => {
    getAppointmentListFun(1, state.limit)
    setState(p => ({
      ...p,
      page: 1,
    }))
    setIsPreviewTabVisible(false)
    setActiveTab("clientAppointments")
    setIsTabContentVisible(true)
  }

  const handleClear = () => {
    setSelectedClient(undefined)
    setIsPreviewTabVisible(false)
    setActiveTab("clientAppointments")
    const page = 1
    const limit = state.limit
    setState(p => ({
      ...p,
      page,
      limit,
    }))
    getAppointmentList({
      variables: {
        getAppointmentReceiptManagement: {
          page,
          limit,
          search: "",
        },
      },
    })
  }

  return (
    <React.Fragment>
    {hasPermission() ? (
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <div className="p-2">
              <h4 className="card-title-main">Receipt Management</h4>
            </div>
          </div>
          <Row className="mg-top-default-ui">
            <Col className="col-12">
              <Card
                className={`${
                  !isTabContentVisible ? classes.cardContainer : ""
                } light-green-bg-card-table`}
              >
                <CardBody>
                  <Row>
                    <Col xs={12} sm={6} lg={3} xl={3}>
                      <CustomSelect
                        className="input default-redesign-input"
                        placeholder="Select a client"
                        onChange={value => handleClientChange(value)}
                        value={selectedClient}
                        options={clientList}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={7}
                      xl={6}
                      className="d-flex margin-top-10px-xs"
                    >
                      <ExtraToolkit
                        onSearch={onSearch}
                        selectedClient={selectedClient}
                        handleClear={handleClear}
                        setIsSpinner={setIsSpinner}
                        className="default-redesign-input"
                      />
                    </Col>
                  </Row>
                  {isTabContentVisible ? (
                    <>
                      {isSpinner ? (
                        <Col className="col-12 user-role-create-height-double">
                          <div className="spinner">
                            <Loader />
                          </div>
                        </Col>
                      ) : (
                        <Row className="mt-4">
                          <div className="Tabs">
                            <ul
                              className={classNames(
                                "nav d-flex gap-4 tab-items-counsellor-details",
                                classes.tabBar
                              )}
                            >
                              <TabNavItem
                                title={`Client Appointments (${appointmentCount})`}
                                id="clientAppointments"
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                              />
                              {isPreviewTabVisible && (
                                <TabNavItem
                                  title="Preview"
                                  id="preview"
                                  activeTab={activeTab}
                                  setActiveTab={setActiveTab}
                                />
                              )}
                            </ul>
                            <div className="outlet mt-4">
                              <TabContent
                                id="clientAppointments"
                                activeTab={activeTab}
                              >
                                <ClientAppointments
                                  selectedClient={selectedClient}
                                  state={state}
                                  totalCount={totalCount}
                                  setState={setState}
                                  handleShowPreviewTab={handleShowPreviewTab}
                                  setPreviewTabData={setPreviewTabData}
                                  getAppointmentListFun={getAppointmentListFun}
                                />
                              </TabContent>
                              <TabContent id="preview" activeTab={activeTab}>
                                <PreviewInfo selectedList={previewTabData} />
                              </TabContent>
                            </div>
                          </div>
                        </Row>
                      )}
                    </>
                  ) : (
                    <ShowContent />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  )
}

export default ReceiptManagement
