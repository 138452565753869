import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Input, Row } from "reactstrap"
import {
  CREATE_ORG_INPUT,
  GET_KEYS,
  GET_ORG,
  GET_ORGANIZATION,
} from "../gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import CustomSelect from "../../../components/CustomSelect"
import { toast } from "react-toastify"
import Loader from "../../../components/Common/Loader"

const OrganizationSettings = () => {
  const [organizationsList, setOrganizationsList] = useState([])
  const [organizationOption, setOrganizationOption] = useState([])
  const [selectedOrg, setSelectedOrg] = useState()
  const [initialValues, setInitialValues] = useState([])
  const [fields, setFields] = useState([])
  const [keyList, setKeyList] = useState([])
  const [getOrganizations, getOrganizationsList] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getKeys, getKeysList] = useLazyQuery(GET_KEYS, {
    fetchPolicy: "no-cache",
  })
  const [getOrg, getOrgRes] = useLazyQuery(GET_ORG, {
    fetchPolicy: "no-cache",
  })

  const [createOrgInput, createOrgInputRes] = useMutation(CREATE_ORG_INPUT)
  const [isLoading, setIsLoading] = useState(false)

  const getOrganizationsFun = (limit, page, search) => {
    setIsLoading(true)
    getOrganizations({
      variables: {
        argumentsInput: {
          limit: limit || 20,
          page: page || 1,
          search: search || "",
        },
      },
    })
  }

  useEffect(() => {
    getOrganizationsFun()
    getKeys()
  }, [])

  useEffect(() => {
    if (
      getKeysList?.data?.getKeys?.status &&
      getKeysList?.data?.getKeys?.data?.keys
    ) {
      const keyOptions = getKeysList?.data?.getKeys?.data?.keys.map(elem => {
        return {
          key: elem.key,
          id: elem?.id,
          value: "",
          is_required: elem?.is_required,
          name: elem.key,
          lable: elem.key,
        }
      })
      setKeyList(keyOptions)
      setInitialValues(keyOptions)
    }
  }, [getKeysList])

  useEffect(() => {
    if (
      getOrganizationsList?.data?.getOrganizations?.data &&
      getOrganizationsList?.data?.getOrganizations?.data?.organizations
    ) {
      const orgList =
        getOrganizationsList?.data?.getOrganizations?.data?.organizations
      setOrganizationsList(orgList)
    }
  }, [getOrganizationsList.data])

  useEffect(() => {
    if (organizationsList.length > 0) {
      const option = organizationsList?.map(item => {
        return {
          key: item?.organization_identifier,
          id: item?.id,
          value: item?.organization_identifier,
          name: item?.name,
          label: item?.name,
        }
      })
      setOrganizationOption(option)
      setSelectedOrg(option[0]?.id)
      getOrg({
        variables: {
          organizationInput: {
            uuid: option[0]?.value,
          },
        },
      })
    }
  }, [organizationsList])

  const handleChangeOrganization = e => {
    setIsLoading(true)
    setSelectedOrg(e)
    getOrg({
      variables: {
        organizationInput: {
          uuid: e.value,
        },
      },
    })
  }
  const handleKeyChange = (index, value) => {
    const updatedFields = [...fields]
    updatedFields[index] = value
    setFields(updatedFields)
  }

  const handleKeyValue = (index, e) => {
    const updatedFields = [...fields]
    updatedFields[index].value = e.target.value
    setFields(updatedFields)
  }
  const handleNewKey = value => {
    setInitialValues(keyList)
    const updatedFields = [...fields]
    updatedFields[fields.length] = value
    setFields(updatedFields)
  }

  const addField = () => {
    setFields([...fields, { id: "", value: "" }])
  }

  const removeField = index => {
    const updatedFields = [...fields]
    updatedFields.splice(index, 1)
    setFields(updatedFields)
  }

  useEffect(() => {
    if (
      createOrgInputRes?.data &&
      createOrgInputRes?.data?.createOrUpdateOrganization?.status &&
      createOrgInputRes?.data?.createOrUpdateOrganization?.data
    ) {
      toast.success(
        createOrgInputRes?.data?.createOrUpdateOrganization?.message
      )
    } else {
      toast.error(createOrgInputRes?.data?.createOrUpdateOrganization?.message)
    }
  }, [createOrgInputRes.data])

  useEffect(() => {
    if (getOrgRes?.data?.getOrganization?.data?.organization) {
      const data =
        getOrgRes?.data?.getOrganization?.data?.organization?.meta_data
      const formValue = data.map(elem => {
        return {
          key: elem.key,
          id: elem?.id,
          value: elem?.value,
          is_required: elem?.is_required,
          name: elem.key,
          lable: elem.key,
        }
      })
      setIsLoading(false)
      setFields(formValue.length > 0 ? formValue : [])
    }
  }, [getOrgRes.data])

  const handleSubmit = values => {
    const newValues = values.map(
      ({ is_required, lable, name, ...rest }) => rest
    )
    const filteredData = newValues.filter(
      obj => obj.key.trim() !== "" || obj.value.trim() !== ""
    )
    if (filteredData.length > 0) {
      createOrgInput({
        variables: {
          organizationInput: {
            id: selectedOrg.id ? selectedOrg.id : selectedOrg,
            meta_data: filteredData,
          },
        },
      })
    } else {
      toast.error("Please Enter Proper Values")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content ">
        <div className="container-fluid">
          <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center">
            <div className="p-2">
              <h4 className="card-title-main">Organization Settings</h4>
              <h5 className="card-para-main">List of all the Settings.</h5>
            </div>
          </div>
        </div>
      </div>
      <Row className="mg-top-default-ui m-0">
        <Col className="col-12 ">
          <Card className="light-green-bg-card-table">
          
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    className="input default-search-input"
                    placeholder="Select Organization"
                    options={organizationOption}
                    onChange={handleChangeOrganization}
                    value={selectedOrg}
                  />
                </Col>
              </Row>
             

              <div className="mt-1">
                <Row className="">
                  {isLoading ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {fields.length > 0 ? (
                        fields?.map((field, index) => (
                          <React.Fragment key={index}>
                            <Col md={4} className="my-3">
                              <CustomSelect
                                className="input default-search-input"
                                placeholder="Select Key"
                                options={field.id ? fields : initialValues}
                                onChange={value =>
                                  handleKeyChange(index, value)
                                }
                                value={field.id}
                              />
                            </Col>
                            <Col md={6} className="my-3">
                              <Input
                                className="default-search-input form-control"
                                name={`value`}
                                value={field.value}
                                onChange={e => handleKeyValue(index, e)}
                                placeholder="Enter subject value"
                              />
                            </Col>
                            <Col md={2} className="my-3">
                              {fields.length > 1 && (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => removeField(index)}
                                >
                                  -
                                </button>
                              )}
                              {fields.length < initialValues.length &&
                                index === fields.length - 1 && (
                                  <button
                                    type="button"
                                    className="btn btn-primary mx-2"
                                    onClick={addField}
                                    disabled={fields.length <= 1 && !fields[0].id}
                                  >
                                    +
                                  </button>
                                )}
                            </Col>
                          </React.Fragment>
                        ))
                      ) : (
                        <>
                          <React.Fragment>
                            <Col md={4} className="my-3">
                              <CustomSelect
                                className="input default-search-input"
                                placeholder="Select Key"
                                options={keyList}
                                onChange={value => handleNewKey(value)}
                                value={fields[fields.length - 1]?.id}
                              />
                            </Col>
                            <Col md={6} className="my-3">
                              <Input
                                className="default-search-input form-control"
                                name={`value`}
                                placeholder="Enter subject value"
                              />
                            </Col>
                            <Col md={2} className="my-3">
                              {fields.length < initialValues.length && (
                                <button
                                  type="button"
                                  className="btn btn-primary mx-2"
                                  onClick={addField}
                                  disabled={fields.length <= 1 && !fields[0]?.id}
                                >
                                  +
                                </button>
                              )}
                            </Col>
                          </React.Fragment>
                        </>
                      )}
                    </>
                  )}
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="settingWarning h6">
                      <span className="font-weight-bold mx-2">NOTE - </span>{" "}
                      PLEASE DO NOT MAKE UNNECESSARY CHANGES UNLESS YOU KNOW THE
                      IMPACT!
                    </p>
                  </Col>
                </Row>
                <div className="mt-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      handleSubmit(fields)
                    }}
                    disabled={fields.length < 1}
                  >
                    Save Settings
                  </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default OrganizationSettings
