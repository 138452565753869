import React from "react"
import Gleap from "gleap"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import store from "./store"

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_API_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
let orgData = JSON.parse(localStorage.getItem("orgData"))
  const token = localStorage.getItem("token")
  let orgIdValueFromUrl = new URL(window.location.href).searchParams.get(
    "orgId"
  )
  let decodedOrgIdFromUrl = orgIdValueFromUrl
    ? decodeURIComponent(orgIdValueFromUrl)
    : undefined

  return {
    headers: {
      ...headers,
      Authorization: token ? token : "",
      organization:
        decodedOrgIdFromUrl ||
        orgData?.organization_identifier ||
        process.env.REACT_APP_MULTITENENT,
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    fetchPolicy: "no-cache",
  },
})

if (process.env.REACT_APP_GLEAP_KEY) {
  Gleap.initialize(process.env.REACT_APP_GLEAP_KEY)
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      {/* <BrowserRouter> */}
        <App />
      {/* </BrowserRouter> */}
    </ApolloProvider>
  </Provider>
)

serviceWorker.unregister()
