const { makeStyles } = require("@material-ui/styles")

export const useStyles = makeStyles(theme => ({
  // Lobby

  preDesignTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "38px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#344054",
  },

  preDesignContent: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#667085",
  },

  // preDesignAvaterContainer: {
  //     padding: "45%",
  //     overflow: 'hidden',
  //     width: "100%",
  //     borderRadius: "12px",
  //     position: "relative",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     border: "none",
  // "& #c-stream": {
  //     position: "absolute",
  //     width: "100%",
  //     height: "100%",
  // },

  // "& .avatarContainer": {
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     top:'50%',
  //     transform:'translateY(-50%)',
  //     position: "absolute",
  //     "& .avatar": {
  //         width: "100px",
  //         height: "100px",
  //         borderRadius: "50%",
  //         background: "#F4EBFF",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         "& .avatar-text": {
  //             color: "#1D2939",
  //             fontSize: "32px"
  //         }
  //     }
  // }

  //},

  // preDesignAvater: {
  //     position: "absolute",
  //     width: "76px",
  //     height: "76px",
  //     borderRadius: "50%",
  //     background: "#F4EBFF",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     lineHeight: "38px",
  //     color: "#1D2939",
  //     fontSize: "28px",
  //     fontWeight: 500
  // },

  preJoinBtn: {
    width: "100%",
    height: "60px",
    borderRadius: "40px",
    background: "#2E90FA",
    border: "1px solid #2E90FA",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  preJoinBtnText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "28px",
    color: "#FFFFFF",
  },

  // Room

  app: {
    height: "100%",
    width: "100%",
    background: "black",
  },

  main: {
    display: "grid",
    gridTemplateColumns: "2fr",
    width: "100%",
    height: "100vh",
  },

  webcam: {
    width: "100%",
    height: "100%",
    padding: "2rem",
    display: "flex",
    gap: "10px",
    padding: "0",
    "@media (max-width: 1000px)": {
      padding: "0",
    },
  },

  webcamHeader: {
    "@media (max-width: 1000px)": {
      display: "block",
      position: "fixed",
      zIndex: 10000,
      width: "100%",
      display: "flex",
      alignItems: "end",
      justifyContent: "space-between",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      top: "20px",
      textAlign: "right",
      justifyContent: "right",
      right: "14px",
    },
  },

  webcamVideo: {
    height: "calc(100vh - 80px)",
    border:"1px solid green"
  },

  webcamContainer: {
    width: "70%",
    height: "100%",
    background: "#101828",
    borderRadius: "0px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    position: "relative",
    paddingRight:"3rem",
    height: "calc(100vh - 80px)",
    "@media (max-width: 1000px)": {
      width: "100%",
      borderRadius: "0px",
    },

    // "& .avatarContainer": {
    //     position: "absolute",
    //     right: 20,
    //     bottom: 20,
    //     width: "100px",
    //     height: "100px",
    //     objectFit: "cover",
    //     borderRadius: "20px",
    //     boxSizing: "border-box",
    //     background: "#101828",
    //     opacity: "70%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     "& .avatar": {
    //         width: "100px",
    //         height: "100px",
    //         borderRadius: "50%",
    //         background: "#F4EBFF",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         "& .avatar-text": {
    //             color: "#1D2939",
    //             fontSize: "32px"
    //         }
    //     }
    // }
  },

  card: {
    width: "30%",
    height: "100%",
    //borderRadius: "20px",
    position: "relative",
    boxShadow: "none",
    border: 0,
    height: "calc(100vh)",
    overflow: "hidden",
    marginBottom: 0,
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },

  videoControls: {
    zIndex: 2,
    position: "fixed",
    width: "100%",
    background: "#1D2939",
    bottom: "0",
    left: "0",
    right: "0",
    padding: "10px 0 8px",
    cursor: "pointer",

    "@media (max-width: 600px)": {
      "& ul": {
        width: "100%",
        justifyContent: "space-around",
      },
    },
    "& ul": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: 0,
      padding: 0,
    },
    "& li": {
      margin: "0 1rem",
      listStyle: "none",
    },
  },

  videoControl: {
    height: 40,
    width: 40,
    backgroundColor: "rgb(74,84,101)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },

  control: {
    zIndex: 2,
    width: "50px",
    height: "50px",
    borderRadius: "100rem",
    border: 0,
    color: "#FFFFFF",
    fontSize: "18px",
    position: "relative",
    background: "transparent",
    overflow: "hidden",
  },

  webcamLocalVideo: {
    // "& #c-stream": {
    //     position: "absolute", //absolute
    //     right: 20,
    //     bottom: 20,
    //     width: "100px",
    //     height: "100px",
    //     objectFit: "contain", //cover
    //     borderRadius: "0px",
    //     "@media (max-width: 1000px)": {
    //         bottom: 20,
    //         right: 20,
    //     },
    //     "@media (max-width: 600px)": {
    //         bottom: 70,
    //         right: 20,
    //         objectFit: "cover", //cover
    //     },
    // },
    // "& .avatarContainer": {
    //     position: "absolute",
    //     right: 20,
    //     bottom: 20,
    //     width: "100px",
    //     height: "100px",
    //     objectFit: "cover",
    //     borderRadius: "20px",
    //     boxSizing: "border-box",
    //     background: "#344054",
    //     opacity: "70%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     "@media (max-width: 1000px)": {
    //         right: 20,
    //     },
    //     "@media (max-width: 600px)": {
    //         bottom: 70,
    //     },
    //     "& .avatar": {
    //         width: "50px",
    //         height: "50px",
    //         borderRadius: "50%",
    //         background: "#F4EBFF",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         "& .avatar-text": {
    //             color: "#1D2939",
    //             fontSize: "18px"
    //         }
    //     }
    // }
  },

  webcamVideo: {
    // "& #c-stream": {
    //     position: "absolute", //absolute
    //     top: 0,
    //     left: 0,
    //     width: "100%",
    //     height: "calc(100vh - 80px)",
    //     objectFit: "contain",
    //     borderRadius: "0px",
    //     "@media (max-width: 600px)": {
    //     objectFit: "cover", //cover
    //     }
    // },
    // "& .avatarContainer": {
    //     position: "absolute",
    //     top: 0,
    //     left: 0,
    //     width: "100%",
    //     height: "100%",
    //     objectFit: "cover",
    //     borderRadius: "15px",
    //     "@media (max-width: 1000px)": {
    //         height: "calc(100% - 130px)",
    //         top: 25,
    //     },
    // }
  },

  overlay: {
    visibility: "hidden",
    "@media (max-width: 1000px)": {
      display: "block",
      borderRadius: "20px 20px 0px 0px",
      zIndex: 200,
      isibility: "hidden",
      background: "white",
      position: "fixed",
      bottom: 0,
      width: "100%",
      height: "0%",
      textAlign: "left",
    },
  },

  activeOverlay: {
    "@media (max-width: 1000px)": {
      display: "block",
      visibility: "visible",
      height: "100%",
    },
  },

  textControls: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "12px",
    lineHeight: "18px",
    alignSelf: "center",
    fontWeight: 400,
    width: "35px",
    display: "flex",
    justifyContent: "center",
  },

  //Tabs page

  nav: {
    display: "flex",
    height: "65px",
    // borderRadius: "20px 20px 0px 0px",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  close: {
    position: "absolute",
    color: "rgb(255, 255, 255)",
    zIndex: "999999",
    marginTop: "17px",
    marginLeft: "15px",
    fontSize: "25px",
  },
  info: {
    position: "absolute",
    color: "#fff",
    zIndex: "999999",
    right: "15px",
    top: "15px",
    fontSize: "25px",
    "@media (max-width: 1079px)": {
      display: "none",
    },
  },
  w100: {
    width: "100%",
  },
  w70: {
    width: "70%",
  },
  w30: {
    width: "30%",
  },
  w40: {
    width: "45%",
  },
  w0: {
    width: "0%",
  },

  tabTitle: {
    flex: "50%",
    background: "#F2F4F7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& span": {
      color: "#000000",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "20px",
    },
    "@media (max-width: 1000px)": {
      justifyContent: "left",
      paddingLeft: "30px",
    },
  },

  outlet: {
    height: "calc(100% - 65px)",
    overflow: "auto",
  },

  pb80: {
    paddingBottom: "80px",
  },

  tabActive: {
    background: "#2E90FA",
    "& span": {
      color: "#FFFFFF",
    },
  },

  //details page

  accordionContainer: {
    padding: "20px",
  },

  accordionList: {
    padding: 0,
    position: "relative",
    display: "block",
    width: "100%",
    height: "auto",
    margin: 0,
    listStyle: "none",
    "& li": {
      position: "relative",
      display: "block",
      width: "100%",
      height: "auto",
      background: "#F9FAFB",
      padding: "20px",
      margin: "0 auto 15px auto",
      border: "1px solid #EAECF0",
      borderRadius: "5px",
      cursor: "pointer",
    },
  },

  accordionTitle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#344054",
    margin: 0,
  },

  accordianContent: {
    display: "none",
  },

  activeAccordianContent: {
    display: "block",
    cursor: "auto",
  },

  accordianContentTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    opacity: 500,
  },

  accordianContentData: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#475467",
  },

  // h3 {
  //     font-weight: 700;
  //     position: relative;
  //     display: block;
  //     width: 100%;
  //     height: auto;
  //     padding: 0 0 0 0;
  //     margin: 0;
  //     font-size: 15px;
  //     letter-spacing: 0.01em;
  //     cursor: pointer;

  //     &:after {
  //       content: "\f278";
  //       font-family: "material-design-iconic-font";
  //       position: absolute;
  //       right: 0;
  //       top: 0;
  //       color: #Fcc110;
  //       transition: all 0.3s ease-in-out;
  //       font-size: 18px;
  //     }
  //   }

  // Rejoin screen

  exitBtn: {
    width: "200px",
    height: "60px",
    color: "#344054",
    fontSize: "18px",
    fontWeight: 500,
    background: "#FFFFFF",
    border: "1px solid #D0D5DD",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  rejoinBtn: {
    width: "200px",
    height: "60px",
    color: "#344054",
    fontSize: "18px",
    fontWeight: 500,
    background: "#2E90FA",
    border: "1px solid #D0D5DD",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  feedbackBtn: {
    width: "200px",
    height: "45px",
    fontSize: "14px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export default useStyles
