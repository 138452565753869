import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Modal,
  ModalBody,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import Switch from "react-switch"
import { useFormik } from "formik"
import "react-datepicker/dist/react-datepicker.css"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentPlugin from "@fullcalendar/moment"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useParams, Link, useHistory } from "react-router-dom"
import { FocusError } from "focus-formik-error"
import _, { debounce } from "lodash"
import style from "./style.module.scss"
import Loader from "../../components/Common/Loader"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  GetCounsellorAvailabilities,
  getMonthWiseAvailabilitiesCalendarView,
  SetOverrideForDate,
  CreateAvailability,
  UpdateRegularAvailability,
  GetCounsellorSchedulingSetting,
  UpdateStoreSchedulingLimit,
  GetCounsellorBlockedTime,
  DeleteBlockedTime,
  GET_ROOMS_DROPDOWN,
} from "./gql/userGql"
import { CalendarChip } from "./CalendarChip"
import moment from "moment"
import { convertTo12Hour } from "../../functions/functions"
import { GET_COUNSELOR } from "../Counselor/gql/Gql"
import { useSelector } from "react-redux"
import Select from "react-select"

const Availibity = props => {
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = parseInt(decodedId)
  let history = useHistory()
  const [isLoader, setIsLoader] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [monthlyData, setMonthlyData] = useState([])
  const [appointmentTyeList, setAppointmentTyeList] = useState([])
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteBlockId, setDeleteBlockId] = useState()
  const userData = useSelector(state => state.Login?.user?.user)
  const isCounsellor = userData.role.id === 2
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    moment().format("YYYY-MM")
  )
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"))
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM"))
  const [multipleAvailData, setMultipleAvailData] = useState([])
  const [scheduleLimitForm, setScheduleLimitForm] = useState({
    advance_hours: 0,
    future_days: 0,
    appointment_per_slot: 0,
    min_change_notice_hours: 0,
    calendar_id: 0,
    accept_maximum_appointments: false,
    accept_max_appointments: false,
    max_hours_per_day: 0,
    max_number_per_day: 0,
    max_number_per_week: 0,
  })
  const [modal, setModal] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isMultipleHoursOpen, setIsMultipleHoursOpen] = useState(false)
  const [dateMultipleHoursOpen, setDateMultipleHoursOpen] = useState(false)
  const [blockTimeData, setBlockTImeData] = useState()
  const [scheduleLimit, setScheduleLimit] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: id,
    is_closed_all: false,
    availabilities: [],
  })
  const [initialValuesModal, setInitialValuesModal] = useState({
    id: id,
    is_closed: true,
  })
  const [getAvailabilities, GetAvailabilitiesRes] = useLazyQuery(
    GetCounsellorAvailabilities,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getMonthlyAvailabilities, getMonthWiseAvailabilitiesCalendarViewRes] =
    useLazyQuery(getMonthWiseAvailabilitiesCalendarView, {
      fetchPolicy: "no-cache",
    })

  const [getCounsellorScheduling, GetCounsellorSchedulingRes] = useLazyQuery(
    GetCounsellorSchedulingSetting,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [GetBlockedTime, GetBlockedTimeRes] = useLazyQuery(
    GetCounsellorBlockedTime,
    {
      fetchPolicy: "no-cache",
    }
  )

  const [updateAvailability, UpdateAvailabilityRes] = useMutation(
    UpdateRegularAvailability,
    { fetchPolicy: "no-cache" }
  )

  const [getCounselorDetail, counselorResponse] = useLazyQuery(GET_COUNSELOR, {
    fetchPolicy: "no-cache",
  })

  React.useEffect(() => {
    if (id) {
      getCounselorDetail({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [])

  React.useEffect(() => {
    if (
      id &&
      counselorResponse.data &&
      counselorResponse.data?.getCalendar &&
      counselorResponse.data?.getCalendar?.status
    ) {
      let appointmentTypeList =
        counselorResponse.data?.getCalendar.data?.calendar?.appointment_types.map(
          elem => {
            return elem.name
          }
        )

      setAppointmentTyeList(appointmentTypeList)
    }
  }, [counselorResponse.data])

  const formattedOverrides = blockTimeData?.map(override => {
    const overrideDate = new Date(override.override_date)
    const startParts = override.start_time.split(":")
    const endParts = override.end_time.split(":")

    // Set the time components
    overrideDate.setHours(
      parseInt(startParts[0], 10),
      parseInt(startParts[1], 10)
    )
    const endDate = new Date(overrideDate)
    endDate.setHours(parseInt(endParts[0], 10), parseInt(endParts[1], 10))

    const formattedStartDate = overrideDate.toLocaleString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })

    const formattedEndDate = endDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })

    const dateObj = {
      date: `${formattedStartDate} - ${formattedEndDate}`,
      id: override.id,
    }

    return dateObj
  })

  function deleteToggle() {
    setModalDelete(!modalDelete)
    setDeleteBlockId(null)
  }

  const [DeleteBlockedTimes, DeleteBlockedTimesRes] = useMutation(
    DeleteBlockedTime,
    { fetchPolicy: "no-cache" }
  )

  const handleDeleteBlockTIme = () => {
    DeleteBlockedTimes({
      variables: {
        deleteBlockedTimeId: deleteBlockId,
      },
    })
    setDeleteBlockId(null)
  }

  useEffect(() => {
    if (
      DeleteBlockedTimesRes?.data?.deleteBlockedTime?.status &&
      DeleteBlockedTimesRes?.data?.deleteBlockedTime?.message
    ) {
      toast.success(DeleteBlockedTimesRes?.data?.deleteBlockedTime?.message)
      GetCounsellorBlockedTimeFun()
      setModalDelete(!modalDelete)
    }
  }, [DeleteBlockedTimesRes.data])

  const [UpdateStoreScheduling, UpdateStoreSchedulingRes] = useMutation(
    UpdateStoreSchedulingLimit,
    { fetchPolicy: "no-cache" }
  )

  const [CreateAvailabilitySlots, CreateAvailabilitySlotsRes] = useMutation(
    CreateAvailability,
    { fetchPolicy: "no-cache" }
  )

  const [setOverrideForDate, setOverrideForDateRes] = useMutation(
    SetOverrideForDate,
    { fetchPolicy: "no-cache" }
  )
  const [availabilities, setAvailabilities] = useState([
    { start_time: "", end_time: "" },
  ])
  const [activeTab, setActiveTab] = useState("current")

  const toggleTab = tab => {
    setActiveTab(tab)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().nullable(),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      let updateInput = values?.availabilities?.flatMap(item => {
        return item.availabilities.map(availability => {
          return {
            id: availability.id,
            start_time: availability.start_time,
            end_time: availability.end_time,
            is_closed: availability.is_closed,
          }
        })
      })
      const isStartTimeGreaterThanEndTime = (availability) => {
        let indices = [];
        availability.every((avail,index) => {
            const startTime = new Date(`1970-01-01T${avail.start_time}`);
            const endTime = new Date(`1970-01-01T${avail.end_time}`);
            if (startTime.getTime() > endTime.getTime()) {
              indices.push(index);
          }
      return indices;
      });
      return indices;
    };
    const isValid = isStartTimeGreaterThanEndTime(updateInput);
    const getIndexToDayMapping = (indices) => {
      const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return indices.map(index => days[index]);
  };
  const dayNames = getIndexToDayMapping(isValid);
  const dayNamesString = dayNames.join(', ')
    if(!isValid.length > 0){
      updateAvailability({
        variables: {
          updateAvailabilityInput: { availability: updateInput },
        },
      }).then(() => {
        getmonthlyApi()
      })
    }else{
      toast.error(`Time slots for ${dayNamesString} ${isValid.length > 1 ? "are" : "is" } overlapping each other`);
      setIsSubmitted(false)
    }
    
    },
  })

  const getmonthlyApi = () => {
    getMonthlyAvailabilities({
      variables: {
        argumentsInput: {
          calendar_id: id,
          month: selectedMonth,
          year: selectedYear,
        },
      },
    })
  }

  const getCounsellorSchedulingSettingFun = () => {
    getCounsellorScheduling({
      variables: {
        argumentsInput: {
          calendar_id: id,
        },
      },
    })
  }

  const GetCounsellorBlockedTimeFun = () => {
    GetBlockedTime({
      variables: {
        argumentsInput: {
          calendar_id: id,
        },
      },
    })
  }

  const setMultipleHours = () => {
    const inputDate = new Date(multipleAvailData[0].start)

    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, "0")
    const day = String(inputDate.getDate()).padStart(2, "0")
    const formattedDateString = `${year}-${month}-${day}`
    const selectedMonth = moment(formattedDateString).format("MMMM")
    const selectedDay = moment(formattedDateString).format("dddd")
    const availabilityInputArr = multipleAvailData.map(item => ({
      calendar_id: id,
      start_time: item.start_time,
      end_time: item.end_time,
      is_closed: multipleAvailData[0].is_closed ? true : false,
      week_day: item.week_day,
      is_blocked: false,
      override_date: formattedDateString,
      month: selectedMonth,
      week_day: selectedDay,
      room_id: item.room_id
    }))

    const isNotEmpty = availabilityInputArr.every(availability => {
      return (
        availability.start_time.trim() !== "" &&
        availability.end_time.trim() !== ""
      )
    })

    if (isNotEmpty && availabilityInputArr.length >= 0) {
      function validateAvailability(availabilities) {

        const sortedAvailabilities = availabilities.slice().sort((a, b) => {
          return a.start_time.localeCompare(b.start_time)
        })

        for (let i = 0; i < sortedAvailabilities.length; i++) {
          const prevAvailability = sortedAvailabilities[i - 1]
          const currentAvailability = sortedAvailabilities[i]

          let prevEndTime = ""
          if (prevAvailability) {
            prevEndTime = new Date(`1970-01-01T${prevAvailability.end_time}`)
          }
          const currentStartTime = new Date(
            `1970-01-01T${currentAvailability.start_time}`
          )

          const currentEndTime = new Date(
            `1970-01-01T${currentAvailability.end_time}`
          )
          if (currentStartTime >= currentEndTime) {
            return false
          }
          if (prevEndTime) {
            if (prevEndTime >= currentStartTime) {
              return false
            }
          }
        }

        return true
      }
      let isValid = ""

      if (availabilityInputArr.length > 0) {
        isValid = validateAvailability(availabilityInputArr)
      } else {
        isValid = true
      }

      if (isValid) {
        setOverrideForDate({
          variables: {
            setOverrideForDate: {
              availability: availabilityInputArr,
            },
          },
        }).then(res => {
          getmonthlyApi()
          setMultipleAvailData([])
          setIsMultipleHoursOpen(false)
          setDateMultipleHoursOpen(false)
          setSwitchStates(multipleAvailData?.map(() => false))
        })
      } else {
        toast.error("Time slots are overlapping each other")
      }
    } else {
      toast.error("Select proper time slots")
    }
  }

  const [switchStates, setSwitchStates] = useState(
    multipleAvailData?.map(() => false)    
  )

  const [roomOption, setRoomOption] = useState(multipleAvailData?.map(() => []))
  const [roomLoad, setRoomLoad] = useState(false)

  const [GetAvailableRoomsDropdown, GetAvailableRoomsDropdownRes] =
    useLazyQuery(GET_ROOMS_DROPDOWN, {
      fetchPolicy: "no-cache",
    })

  const handleEnableRoom = (checked, index) => {
    if (checked) {
      const { calendar_id, week_day, end_time, start_time } =
        multipleAvailData[index]
      setRoomLoad(true)


      const inputDate = new Date(multipleAvailData[0].start)

      const year = inputDate.getFullYear()
      const month = String(inputDate.getMonth() + 1).padStart(2, "0")
      const day = String(inputDate.getDate()).padStart(2, "0")
      const formattedDateString = `${year}-${month}-${day}`
      const selectedDay = moment(formattedDateString).format("dddd")

      GetAvailableRoomsDropdown({
        variables: {
          getAvailableRoomsDropdown: {
            calendar_id: !dateMultipleHoursOpen ? calendar_id : id,
            week_day: !dateMultipleHoursOpen ? week_day : selectedDay,
            override_date: dateMultipleHoursOpen ? formattedDateString : undefined,
            start_time: start_time,
            end_time: end_time,
          },
        },
      }).then(res => {
        const roomOptions = res.data.getAvailableRoomsDropdown.data.rooms.map(
          room => ({
            value: room.id,
            label: `${room?.location?.name} - ${room.name}`,
          })
        )
        const newRoomOption = [...roomOption]
        newRoomOption[index] = roomOptions

        setRoomOption(newRoomOption)
        setRoomLoad(false)
      })
    }
    if (!checked) {
      const newRoomOption = [...roomOption]
      newRoomOption[index] = []
      setRoomOption(newRoomOption)

      setMultipleAvailData(prevState => {
        return prevState.map((item, i) =>
          i === index ? { ...item, room_id: undefined, room:undefined } : item
        )
      })
    }
    const newSwitchStates = [...switchStates]
    newSwitchStates[index] = checked
    setSwitchStates(newSwitchStates)
  }

  const handleRoomSelect = (val, ind) => {
    setMultipleAvailData(prevState => {
      return prevState.map((item, i) =>
        i === ind ? { ...item, room_id: val.value, room: val } : item
      )
    })
  }

  const addAvailability = () => {
    if (multipleAvailData.length < 5) {
      setMultipleAvailData([
        ...multipleAvailData,
        {
          start_time: "",
          end_time: "",
          calendar_id: multipleAvailData[0].calendar_id,
          week_day: multipleAvailData[0].week_day,
        },
      ])
    }
  }

  const handleMultiTimeSlot = (e, index, time) => {
    const newArray = [...multipleAvailData]

    newArray[index][time] = e.target.value + ":00"

    setMultipleAvailData(newArray)

    const newSwitchStates = [...switchStates]
    newSwitchStates[index] = false
    setSwitchStates(newSwitchStates)
  }
  const removeAvailability = index => {
    const updatedAvailabilities = multipleAvailData.filter(
      (availability, ind) => ind !== index
    )
    setMultipleAvailData(updatedAvailabilities)
  }

  const handleAvailabilityChange = (e, index, field) => {
    const updatedAvailabilities = [...availabilities]
    updatedAvailabilities[index][field] = e.target.value
    setAvailabilities(updatedAvailabilities)
  }

  const getAvailabilitiesFun = () => {
    getAvailabilities({
      variables: {
        argumentsInput: {
          calendar_id: id,
        },
      },
    })

    getMonthlyAvailabilities({
      variables: {
        argumentsInput: {
          calendar_id: id,
          month: moment().format("MMMM"),
          year: parseInt(moment().format("YYYY")),
        },
      },
    })
  }

  useEffect(() => {
    getAvailabilitiesFun()
    getCounsellorSchedulingSettingFun()
    GetCounsellorBlockedTimeFun()
  }, [])

  useEffect(() => {
    if (
      GetAvailabilitiesRes?.data?.getCounsellorAvailabilities?.data &&
      GetAvailabilitiesRes?.data?.getCounsellorAvailabilities?.code == 200
    ) {
      setInitialValues(prev => ({
        ...prev,
        availabilities:
          GetAvailabilitiesRes?.data?.getCounsellorAvailabilities?.data?.avilabilitiesMultiple?.map(
            availability => ({
              ...availability,
              availabilities: availability?.availabilities?.map(slot => ({
                ...slot,
                room: slot?.room
                  ? {
                      label: `${slot?.room?.location?.name} - ${slot?.room?.name}` || "",
                      value: slot?.room?.id || null,
                    }
                  : null,
              })),
            })
          ),
        is_closed_all:
          !GetAvailabilitiesRes?.data?.getCounsellorAvailabilities?.data
            ?.is_closed_all,
      }))
      setIsLoader(false)
    } else if (
      GetAvailabilitiesRes?.data?.getCounsellorAvailabilities?.data &&
      GetAvailabilitiesRes?.data?.getCounsellorAvailabilities?.code !== 200
    ) {
      setIsLoader(false)
    }
  }, [GetAvailabilitiesRes.data])

  useEffect(() => {
    if (
      UpdateStoreSchedulingRes?.data?.updateStoreSchedulingLimit?.status &&
      UpdateStoreSchedulingRes?.data?.updateStoreSchedulingLimit?.code === 200
    ) {
      toast.success(
        UpdateStoreSchedulingRes?.data.updateStoreSchedulingLimit.message
      )
      handleScheduleLimitModal()
    } else {
      toast.error(
        UpdateStoreSchedulingRes?.data?.updateStoreSchedulingLimit?.message
      )
    }
  }, [UpdateStoreSchedulingRes.data])

  useEffect(() => {
    if (
      UpdateAvailabilityRes.data &&
      UpdateAvailabilityRes?.data?.updateRegularAvailability?.code == 200
    ) {
      setIsSubmitted(false)
      toast.success(
        UpdateAvailabilityRes?.data?.updateRegularAvailability?.message
      )
    } else if (
      UpdateAvailabilityRes.data &&
      UpdateAvailabilityRes?.data?.updateRegularAvailability?.code !== 200
    ) {
      setIsSubmitted(false)
    }
  }, [UpdateAvailabilityRes.data])
  useEffect(() => {
    if (
      getMonthWiseAvailabilitiesCalendarViewRes?.data
        ?.getMonthWiseAvailabilitiesCalendarView?.data &&
      getMonthWiseAvailabilitiesCalendarViewRes?.data
        ?.getMonthWiseAvailabilitiesCalendarView?.data
        ?.avilabilitiesCalendarView
    ) {
      // let data =
      // getMonthWiseAvailabilitiesCalendarViewRes?.data?.getMonthWiseAvailabilitiesCalendarView?.data?.avilabilitiesCalendarView
      // data = data.map(item => {
      //   item.start = `${moment(`${selectedMonthYear}-${item.date}`).format(
      //     "YYYY-MM-DD"
      //   )}`
      //   item.title = `${convertTo12Hour(item?.start_time)}-${convertTo12Hour(
      //     item.end_time
      //   )}`
      //   item.id = item.date
      //   return item
      // })
      setMonthlyData(
        getMonthWiseAvailabilitiesCalendarViewRes?.data
          ?.getMonthWiseAvailabilitiesCalendarView?.data
          ?.avilabilitiesCalendarView
      )
    }
  }, [getMonthWiseAvailabilitiesCalendarViewRes.data])

  useEffect(() => {
    if (
      GetCounsellorSchedulingRes?.data?.getCounsellorSchedulingSetting?.data &&
      GetCounsellorSchedulingRes?.data?.getCounsellorSchedulingSetting?.data
        ?.scheduling
    ) {
      const { scheduling } =
        GetCounsellorSchedulingRes.data.getCounsellorSchedulingSetting.data

      setScheduleLimitForm(prevVal => ({
        ...prevVal,
        ...scheduling,
        accept_maximum_appointments:
          scheduling.accept_maximum_appointments ===
          "Accept_appointments_until_fully_booked"
            ? true
            : false,
        accept_max_appointments:
          scheduling.accept_maximum_appointments ===
          "Accept_appointments_until_fully_booked"
            ? false
            : true,
      }))
    } else {
    }
  }, [GetCounsellorSchedulingRes.data])

  useEffect(() => {
    if (
      GetBlockedTimeRes?.data?.getCounsellorBlockedTime?.data &&
      GetBlockedTimeRes?.data?.getCounsellorBlockedTime?.status
    ) {
      setBlockTImeData(
        GetBlockedTimeRes?.data?.getCounsellorBlockedTime?.data?.overrides
      )
    }
  }, [GetBlockedTimeRes.data])

  const handleChange = (e, index, field) => {
    const newArray = [...validation.values.availabilities]
    newArray[index].availabilities[0][field] = e.target.value + ":00"
    validation.setFieldValue("availabilities", newArray)
  }

  const handleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const handleMultipleHoursModal = availData => {
    setSwitchStates(multipleAvailData?.map(() => false))
    if (availData) {
      setMultipleAvailData(availData)
      availData.forEach((elem, index)=>{
        if(elem.room){
          handleEnableRoom(true, index)
        }
      })  
    }
    setDateMultipleHoursOpen(false)
    setIsMultipleHoursOpen(!isMultipleHoursOpen)
  }
  const handleScheduleLimitModal = () => {
    setScheduleLimit(!scheduleLimit)
    setActiveTab("current")
    setDisableSubmit(false)
  }

  const saveMultipleHours = () => {
    const availabilityInputArr = multipleAvailData.map(item => ({
      calendar_id: item.calendar_id,
      start_time: item.start_time,
      end_time: item.end_time,
      week_day: item.week_day,
      room_id: item.room_id ? item.room_id : item?.room?.value ?item?.room?.value : undefined ,
    }))

    const isNotEmpty = availabilityInputArr.every(availability => {
      return (
        availability.start_time.trim() !== "" &&
        availability.end_time.trim() !== ""
      )
    })

    if (isNotEmpty && availabilityInputArr.length >= 0) {
      function validateAvailability(availabilities) {
        const sortedAvailabilities = availabilities.slice().sort((a, b) => {
          return a.start_time.localeCompare(b.start_time)
        })

        for (let i = 0; i < sortedAvailabilities.length; i++) {
          const prevAvailability = sortedAvailabilities[i - 1]
          const currentAvailability = sortedAvailabilities[i]
          let prevEndTime = ""
          if (prevAvailability) {
            prevEndTime = new Date(`1970-01-01T${prevAvailability.end_time}`)
          }

          const currentStartTime = new Date(
            `1970-01-01T${currentAvailability.start_time}`
          )

          const currentEndTime = new Date(
            `1970-01-01T${currentAvailability.end_time}`
          )
          if (currentStartTime >= currentEndTime) {
            return false
          }

          if (prevEndTime) {
            if (prevEndTime >= currentStartTime) {
              return false
            }
          }
        }

        return true
      }
      let isValid = ""
      if (availabilityInputArr.length > 0) {
        isValid = validateAvailability(availabilityInputArr)
      } else {
        isValid = true
      }

      if (isValid) {
        CreateAvailabilitySlots({
          variables: {
            createAvailabilityInput: { availability: availabilityInputArr },
          },
        }).then(() => {
          getmonthlyApi()
          handleMultipleHoursModal()
          getAvailabilitiesFun()
        })
      } else {
        toast.error("Time slots are overlapping each other")
      }
    } else {
      toast.error("Select proper time slots")
    }
  }

  const handleToggle = e => {
    if (e.target.checked) {
      setInitialValues(prev => {
        return {
          ...prev,
          is_closed_all: !prev.is_closed_all,
          availabilities: prev.availabilities.map(item => ({
            ...item,
            availabilities: item.availabilities.map(availability => ({
              ...availability,
              is_closed: !prev.is_closed,
            })),
          })),
        }
      })
    } else {
      setInitialValues(prev => {
        return {
          ...prev,
          is_closed_all: !prev.is_closed_all,
        }
      })
    }
  }

  function formatTimeTo12Hour(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number)
    const period = hours < 12 ? "AM" : "PM"
    const formattedHours = hours % 12 || 12
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`
  }

  function generateEventsFromMonthlyData(availabilityData) {
    const events = []

    for (const dayAvailability of availabilityData) {
      const date = dayAvailability.date
      const isClosedAllDay = dayAvailability.is_closed_all_for_whole_day
      const availabilities = dayAvailability.availabilities

      // Check if the day is fully closed for the whole day
      if (isClosedAllDay) {
        const event = {
          date: date,
          start_time: "Closed",
          end_time: "", // You can leave this empty for a closed event
          is_closed: true,
          start: `${selectedMonthYear}-${date
            .toString()
            .padStart(2, "0")}T00:00:00`,
          title: "Closed (All Day)",
          id: `${date}_closed_all_day`,
        }

        events.push(event)
      } else {
        for (const availability of availabilities) {
          const event = {
            date: date,
            start_time: availability.start_time,
            end_time: availability.end_time,
            is_closed: availability.is_closed,
            start: `${selectedMonthYear}-${date.toString().padStart(2, "0")}T${
              availability.start_time
            }`,
            title: availability.is_closed
              ? null
              : `${formatTimeTo12Hour(
                  availability.start_time
                )} - ${formatTimeTo12Hour(availability.end_time)}`,
            id: availability.id
              ? availability.id
              : `${date}${availability.start_time.substring(0, 2)}`,
          }

          events.push(event)
        }
      }
    }

    return events
  }

  const handleToggleDynamic = (e, item, index) => {
    let updatedItem = { ...item }
    setInitialValues(prevState => {
      const updatedAvailabilities = [...prevState.availabilities]
      updatedAvailabilities[index].availabilities.map(elem => {
        elem.is_closed = e.target.checked
      })
      return { ...prevState, availabilities: updatedAvailabilities }
    })
  }

  const handleDateChange = payload => {
    const viewTitle = payload.view.title
    const [currentMonth, currentYear] = viewTitle.split(" ")
    setSelectedMonth(currentMonth)
    setSelectedYear(parseInt(currentYear))

    const monthMappings = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    }

    const formattedMonth = monthMappings[currentMonth] || currentMonth

    const selectedMonthYear = `${currentYear}-${formattedMonth}`
    setSelectedMonthYear(selectedMonthYear)

    if (payload.start) {
      getMonthlyAvailabilities({
        variables: {
          argumentsInput: {
            calendar_id: id,
            month: currentMonth,
            year: parseInt(currentYear),
          },
        },
      })
    }

    // setSelectedMonthYear(moment(payload.start).add(1, "M").format("YYYY-MM"))
    if (payload.start) {
      getMonthlyAvailabilities({
        variables: {
          argumentsInput: {
            calendar_id: id,
            month: currentMonth,
            year: parseInt(moment(payload.start).add(1, "M").format("YYYY")),
          },
        },
      })
    }
  }

  const handleScheduleChange = event => {
    const { name, value, type, checked } = event.target
    if (type === "checkbox") {
      if (name === "accept_maximum_appointments") {
        setScheduleLimitForm({
          ...scheduleLimitForm,
          accept_maximum_appointments: checked,
          accept_max_appointments: false,
        })
      } else if (name === "accept_max_appointments") {
        setScheduleLimitForm({
          ...scheduleLimitForm,
          accept_maximum_appointments: false,
          accept_max_appointments: checked,
        })
      }
    } else {
      const parsedValue = parseInt(value)
      const newValue = parsedValue < 0 ? 0 : parsedValue

      setScheduleLimitForm({
        ...scheduleLimitForm,
        [name]: newValue,
      })
    }
  }

  const submitScheduleLimits = () => {
    let {
      advance_hours,
      future_days,
      appointment_per_slot,
      min_change_notice_hours,
      accept_maximum_appointments,
      accept_max_appointments,
      max_hours_per_day,
      max_number_per_day,
      max_number_per_week,
      id,
    } = scheduleLimitForm

    const scheduleLimitData = {
      advance_hours,
      id,
      future_days,
      appointment_per_slot,
      min_change_notice_hours,
      accept_maximum_appointments: accept_maximum_appointments
        ? "Accept_appointments_until_fully_booked"
        : null,
      max_hours_per_day: accept_maximum_appointments ? null : max_hours_per_day,
      max_number_per_day: accept_maximum_appointments
        ? null
        : max_number_per_day,
      max_number_per_week: accept_maximum_appointments
        ? null
        : max_number_per_week,
    }

    if (
      scheduleLimitData.advance_hours >= 0 &&
      scheduleLimitData.future_days >= 0 &&
      scheduleLimitData.appointment_per_slot >= 0 &&
      scheduleLimitData.min_change_notice_hours >= 0 &&
      (scheduleLimitData.accept_maximum_appointments ===
        "Accept_appointments_until_fully_booked" ||
        (accept_max_appointments &&
          scheduleLimitData.max_hours_per_day >= 0 &&
          scheduleLimitData.max_number_per_day >= 0 &&
          scheduleLimitData.max_number_per_week >= 0))
    ) {
      UpdateStoreScheduling({
        variables: {
          updateStoreSchedulingLimit: scheduleLimitData,
        },
      })
    } else {
      setDisableSubmit(true)
    }
  }

  const handleEventClick = arg => {
    const events = []

    for (const dayAvailability of monthlyData) {
      const date = dayAvailability.date
      const availabilities = dayAvailability.availabilities

      for (const availability of availabilities) {
        const event = {
          date: date,
          start_time: availability.start_time,
          end_time: availability.end_time,
          is_closed: availability.is_closed,
          start: `${selectedMonthYear}-${date.toString().padStart(2, "0")}T${
            availability.start_time
          }`,
          title: availability.is_closed
            ? null
            : `${availability.start_time.slice(
                0,
                5
              )} - ${availability.end_time.slice(0, 5)}`,
          id:
            availability.id ||
            `${date}${availability.start_time.substring(0, 2)}`,
          room: availability?.room
                  ? {
                      label: availability?.room?.name || "",
                      value: availability?.room?.id || null,
                    }
                  : null,
        }

        events.push(event)
      }
    }
    const event = arg.event
    const appointments = events
    const dateToFind = new Date(event.start)
    const date = dateToFind.getDate()
    const modalData = appointments.filter(item => item.date === date)
    setMultipleAvailData(modalData)
    setIsMultipleHoursOpen(true)
    setDateMultipleHoursOpen(true)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            {/* <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    Set Hours of Availability
                  </h4>
                </div>
              </div>
            </Col> */}
          </Row>
          <Row>
            <Col className="col-12 position-relative mb-4">
              {isLoader ? (
                <div className={style.spinnerContainer}>
                  <Loader />
                </div>
              ) : (
                <Form
                  className="needs-validation"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <FocusError formik={validation} />
                  <Row className="d-flex justify-content-center">
                    <Col xs={12} md={8}>
                      <Col xl="12">
                        <Card className="transparent-bg-card">
                          <CardBody>
                            <Row className="form-custom-white-inputs">
                              <Col md="12" className="mb-2">
                                <div className="form-check form-switch">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input "
                                    id="customSwitch2"
                                    name="is_closed"
                                    disabled={isCounsellor}
                                    checked={validation?.values?.is_closed_all}
                                    onChange={e => handleToggle(e)}
                                    onClick={e => handleToggle(e)}
                                  />
                                  <label
                                    className="form-check-label labelfor-regular-hours-service"
                                    htmlFor="customSwitch2"
                                  >
                                    This service group has regular hours every
                                    week
                                  </label>
                                </div>
                              </Col>
                              <Col
                                md={12}
                                className={
                                  validation?.values?.is_closed_all
                                    ? "d-none"
                                    : "light-green-bg-card mb-3 p-4"
                                }
                              >
                                <div className="menu-item-16px ">
                                  Switching to only Availability for Specific
                                  Days will remove your current Regular Weekly
                                  Hours.
                                </div>

                                <div className="menu-item-16px ">
                                  If you want to have the same availability
                                  every week, select Cancel to go back over to
                                  Regular Weekly Hours and set those instead.
                                </div>
                              </Col>
                            </Row>
                            <Row
                              className={`${
                                validation?.values?.is_closed_all
                                  ? " align-items-center mb-4 "
                                  : "d-none"
                              }`}
                            >
                              {validation?.values?.availabilities?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <div className="mb-4 row" key={index}>
                                        <Col md="3" className="">
                                          <div className="form-check form-switch ">
                                            <Input
                                              type="checkbox"
                                              disabled={isCounsellor}
                                              className="form-check-input default-search-input"
                                              id={`customSwitch-${index}`}
                                              name={`availabilities[${index}].is_closed`}
                                              checked={
                                                !item?.availabilities[0]
                                                  .is_closed
                                              }
                                              onChange={e =>
                                                handleToggleDynamic(
                                                  e,
                                                  item?.availabilities,
                                                  index
                                                )
                                              }
                                              onClick={e =>
                                                handleToggleDynamic(
                                                  e,
                                                  item?.availabilities,
                                                  index
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`customSwitch-${index}`}
                                            >
                                              {item?.availabilities[0].week_day}
                                            </label>
                                          </div>
                                        </Col>
                                        {item?.availabilities[0]?.is_closed ? (
                                          <Col
                                            md="8"
                                            className="mb-2 menu-item-16px"
                                          >
                                            Closed
                                          </Col>
                                        ) : (
                                          <>
                                            <Col
                                              md="3"
                                              className="custom-date-picker-style"
                                            >
                                              <Input
                                                id="name"
                                                className="form-control default-search-input"
                                                name={`availabilities[${index}].start_time`}
                                                placeholder="From"
                                                type="time"
                                                onChange={e =>
                                                  handleChange(
                                                    e,
                                                    index,
                                                    "start_time"
                                                  )
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  item?.availabilities[0]
                                                    .start_time || ""
                                                }
                                                disabled={
                                                  item?.availabilities[0]
                                                    ?.is_closed
                                                }
                                                invalid={
                                                  validation.touched.name &&
                                                  validation.errors.name
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {item?.availabilities.length >
                                                1 && (
                                                <h6
                                                  className="mt-2 seeMoreTimeSlots"
                                                  onClick={() => {
                                                    handleMultipleHoursModal(
                                                      item?.availabilities
                                                    )
                                                  }}
                                                >
                                                  +
                                                  {item?.availabilities.length -
                                                    1}{" "}
                                                  more
                                                </h6>
                                              )}
                                            </Col>
                                            <Col
                                              md="3"
                                              className="custom-date-picker-style "
                                            >
                                              <Input
                                                id="end_time"
                                                name={`availabilities[${index}].end_time`}
                                                className="form-control default-search-input mt-2 mt-md-0 mb-1 mt-mb-0 mt-lg-0 mb-lg-0"
                                                placeholder="To"
                                                type="time"
                                                onChange={e =>
                                                  handleChange(
                                                    e,
                                                    index,
                                                    "end_time"
                                                  )
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  item?.availabilities[0]
                                                    .end_time || ""
                                                }
                                                disabled={
                                                  item?.availabilities[0]
                                                    ?.is_closed
                                                }
                                                invalid={
                                                  validation.touched.name &&
                                                  validation.errors.name
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Col>
                                            <Col md="2">
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleMultipleHoursModal(
                                                    item?.availabilities
                                                  )
                                                }}
                                                disabled={isCounsellor}
                                                className="h-40 btn btn-primary waves-effect waves-light d-flex align-items-center justify-content-center"
                                              >
                                                <i
                                                  className="waves-effect mdi mdi-pencil font-size-20 "
                                                  id="backArrow"
                                                />
                                              </button>
                                            </Col>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )
                                }
                              )}
                            </Row>
                            <Row>
                              <div className="flex-wrap gap-2 text-end mb-3">
                                {true ? (
                                  <>
                                    {!isCounsellor && (
                                      <>
                                        <button
                                          className="btn btn-primary w-sm waves-effect waves-light"
                                          type="submit"
                                          disabled={isSubmitted}
                                        >
                                          {validation.values.is_closed_all
                                            ? "Save Regular Hours"
                                            : "Save, switch to Specific Days"}
                                        </button>
                                        <button
                                          className={`btn  w-sm waves-effect waves-light btn-light btn ml10 mt-2 mt-sm-0 mt-md-0 mb-1 mt-mb-0 mt-lg-0 mb-lg-0 ${
                                            !validation?.values?.is_closed_all
                                              ? "d-none"
                                              : ""
                                          }`}
                                          type="button"
                                          disabled={isSubmitted}
                                          onClick={() => {
                                            handleScheduleLimitModal()
                                          }}
                                        >
                                          Set Scheduling Limits
                                        </button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-primary w-sm waves-effect waves-light"
                                    disabled
                                  >
                                    <Spinner
                                      type="grow"
                                      className="me-1 align-middle spinner-grow-sm "
                                      color="light"
                                    />
                                    Processing...
                                  </button>
                                )}
                                <Button
                                  type="button"
                                  color="secondary"
                                  className={`btn-inactive btn ml10 ${
                                    validation?.values?.is_closed_all
                                      ? "d-none"
                                      : ""
                                  }`}
                                  onClick={e => handleToggle(e)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
            <Row className="d-flex justify-content-center">
              <Col xs={12} md={11}>
                <FullCalendar
                  plugins={[
                    momentPlugin,
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                  ]}
                  headerToolbar={{
                    left: "",
                    center: "prev title next",
                    right: "",
                  }}
                  initialView="dayGridMonth"
                  editable={false}
                  selectable={false}
                  selectMirror={false}
                  dayMaxEvents={true}
                  weekends={true}
                  events={generateEventsFromMonthlyData(monthlyData)}
                  datesSet={handleDateChange}
                  eventClick={!isCounsellor && handleEventClick}
                  eventContent={CalendarChip}
                  eventColor="#e2ecdf"
                  eventTimeFormat={{
                    hour: "numeric",
                    minute: "2-digit",
                    meridiem: false,
                  }}
                  views={{
                    timeGridWeek: {
                      dayHeaderFormat: {
                        day: "numeric",
                      },
                      dayHeaderContent: function (info) {
                        let s = ""
                        // get the day of the month (number)
                        const dayOfMonth = info?.date?.getDate()
                        // set the ordinal value of the date in s
                        if (dayOfMonth > 3 && dayOfMonth < 21) s = "th"
                        switch (dayOfMonth % 10) {
                          case 1:
                            s = ""
                            break
                          case 2:
                            s = ""
                            break
                          case 3:
                            s = ""
                            break
                          default:
                            s = ""
                        }
                        const locale = "en-US"
                        // get the name of the day (Friday, Saturday ...)
                        const dayName = info?.date?.toLocaleDateString(locale, {
                          weekday: "short",
                        })
                        return (
                          <div className="d-flex flex-column cursor-pointer-accordion">
                            <div>{"dayName"}</div>
                            <div
                              className={info?.isToday ? style.todaystyle : ""}
                            >
                              {"dayOfMonth"}
                            </div>
                          </div>
                        )
                      },
                    },
                    timeGridDay: {
                      dayHeaderContent: function (info) {
                        const dayOfMonth = info.date.getDate()
                        const locale = "en-US"
                        const dayName = info.date.toLocaleDateString(locale, {
                          weekday: "short",
                        })
                        return (
                          <div className="d-flex flex-column">
                            <div className="cursor-pointer-accordion">
                              {dayName}
                            </div>
                            <div
                              className={info.isToday ? style.todaystyle : ""}
                            >
                              {dayOfMonth}
                            </div>
                          </div>
                        )
                      },
                    },
                  }}
                />

                <Modal isOpen={scheduleLimit} size="lg">
                  <ModalBody>
                    <div className="w-100 text-end">
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => handleScheduleLimitModal()}
                      ></button>
                    </div>
                    <Col md={12}>
                      {appointmentTyeList.length > 1 ? (
                        <h3 className="card-title-main">
                          {appointmentTyeList[0]} +{" "}
                          {appointmentTyeList.length - 1} others
                        </h3>
                      ) : (
                        appointmentTyeList[0]
                      )}
                    </Col>

                    <div className="mb-4 mt-5">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          type="button"
                          className={`px-5 border border-dark btn ${
                            activeTab === "current"
                              ? "btn-primary"
                              : "btn-light"
                          }`}
                          onClick={() => toggleTab("current")}
                        >
                          Calendar Scheduling Limits
                        </button>
                        <button
                          type="button"
                          className={` border border-dark btn px-5 ${
                            activeTab === "block-time"
                              ? "btn-primary"
                              : "btn-light"
                          }`}
                          onClick={() => toggleTab("block-time")}
                        >
                          Blocked Time
                        </button>
                      </div>
                    </div>
                    {activeTab === "current" && (
                      <Form className="needs-validation">
                        <Row>
                          <Col md={6} className="mt-2 mb-2 relative">
                            <div className="mt-4">
                              <p className="f-18-card-title-main ">
                                Do Not Allow Clients to Schedule Fewer Than
                              </p>

                              <div className="mt-3 d-flex align-items-center justify-content-flex-start">
                                <div className="input-select-days-and-time ">
                                  <Input
                                    name="advance_hours"
                                    className="border border-dark w-75"
                                    type="number"
                                    value={scheduleLimitForm.advance_hours}
                                    onChange={handleScheduleChange}
                                  />
                                  {"  "}
                                  <span>Hours in advance and more than</span>
                                  {scheduleLimitForm.advance_hours &&
                                  scheduleLimitForm.advance_hours < 1 &&
                                  disableSubmit ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      Nagative value not allowed
                                    </FormFeedback>
                                  ) : null}

                                  <Input
                                    name="future_days"
                                    className="mt-3 border border-dark w-75"
                                    type="number"
                                    value={scheduleLimitForm.future_days}
                                    onChange={handleScheduleChange}
                                  />
                                  <span>Days in the future</span>
                                  {scheduleLimitForm.future_days &&
                                  scheduleLimitForm.future_days < 1 &&
                                  disableSubmit ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      Nagative value not allowed
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <p className="f-18-card-title-main ">
                                Appointments Per Time Slot
                              </p>

                              <div className="mt-4 d-flex align-items-center justify-content-flex-start">
                                <div className="input-select-days-and-time ">
                                  <Input
                                    name="appointment_per_slot"
                                    className="border border-dark"
                                    type="number"
                                    value={
                                      scheduleLimitForm.appointment_per_slot
                                    }
                                    onChange={handleScheduleChange}
                                  />
                                  {"  "}
                                  <span>Appointments</span>
                                  {scheduleLimitForm.appointment_per_slot &&
                                  scheduleLimitForm.appointment_per_slot < 1 &&
                                  disableSubmit ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      Nagative value not allowed
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} className="mt-2 mb-2 relative">
                            <div className="mt-4">
                              <p className="f-18-card-title-main ">
                                Do Not Allow Clients to Cancel or Reschedule
                                Appointments Less Than
                              </p>

                              <div className="mt-3 d-flex align-items-center justify-content-flex-start">
                                <div className="input-select-days-and-time ">
                                  <Input
                                    name="min_change_notice_hours"
                                    className="border border-dark"
                                    type="number"
                                    value={
                                      scheduleLimitForm.min_change_notice_hours
                                    }
                                    onChange={handleScheduleChange}
                                  />
                                  {"  "}
                                  <span>Hours in advance</span>
                                  {scheduleLimitForm.min_change_notice_hours &&
                                  scheduleLimitForm.min_change_notice_hours <
                                    1 &&
                                  disableSubmit ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      Nagative value not allowed
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <p className="f-18-card-title-main ">
                                Maximum Appointments
                              </p>

                              <div className="mt-3 d-flex align-items-center justify-content-flex-start">
                                <div className="input-select-days-and-time ">
                                  <Input
                                    name="accept_maximum_appointments"
                                    className="border border-dark"
                                    type="checkbox"
                                    defaultChecked={
                                      scheduleLimitForm.accept_maximum_appointments
                                    }
                                    isChecked={
                                      scheduleLimitForm.accept_maximum_appointments
                                    }
                                    onChange={handleScheduleChange}
                                  />
                                  {"  "}
                                  <label>
                                    Accept appointments until fully booked
                                  </label>
                                </div>
                              </div>
                              {!scheduleLimitForm.accept_maximum_appointments && (
                                <div className="mt-1 ">
                                  <div className="input-select-days-and-time ">
                                    <Input
                                      name="accept_max_appointments"
                                      className="border border-dark"
                                      type="checkbox"
                                      isChecked={
                                        scheduleLimitForm.accept_max_appointments
                                      }
                                      defaultChecked={
                                        scheduleLimitForm.accept_max_appointments
                                      }
                                      onChange={handleScheduleChange}
                                    />
                                    {"  "}
                                    <label>
                                      Accept a max number of appointments
                                    </label>
                                  </div>

                                  {scheduleLimitForm.accept_max_appointments && (
                                    <>
                                      <div className="mt-2 input-select-days-and-time margin-left-10 d-flex  gap-1">
                                        <Input
                                          name="max_hours_per_day"
                                          className="w-25 mx-2 border border-dark"
                                          type="number"
                                          value={
                                            scheduleLimitForm.max_hours_per_day
                                          }
                                          onChange={handleScheduleChange}
                                        />
                                        <label className="mt-2">
                                          Max hours per day
                                        </label>

                                        {"  "}
                                      </div>
                                      {scheduleLimitForm.max_hours_per_day &&
                                      scheduleLimitForm.max_hours_per_day < 1 &&
                                      disableSubmit ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          Nagative value not allowed
                                        </FormFeedback>
                                      ) : null}
                                      <div className="mt-2 input-select-days-and-time margin-left-10 d-flex  gap-1">
                                        <Input
                                          name="max_number_per_day"
                                          className="w-25 mx-2 border border-dark"
                                          type="number"
                                          onChange={handleScheduleChange}
                                          value={
                                            scheduleLimitForm.max_number_per_day
                                          }
                                        />
                                        <label className="mt-2">
                                          Max number per day
                                        </label>

                                        {"  "}
                                      </div>
                                      {scheduleLimitForm.max_number_per_day &&
                                      scheduleLimitForm.max_number_per_day <
                                        1 &&
                                      disableSubmit ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          Nagative value not allowed
                                        </FormFeedback>
                                      ) : null}
                                      <div className="mt-2 input-select-days-and-time margin-left-10 d-flex  gap-1">
                                        <Input
                                          name="max_number_per_week"
                                          className="w-25 mx-2 border border-dark"
                                          type="number"
                                          value={
                                            scheduleLimitForm.max_number_per_week
                                          }
                                          onChange={handleScheduleChange}
                                        />
                                        <label className="mt-2">
                                          Max number per week
                                        </label>

                                        {"  "}
                                      </div>
                                      {scheduleLimitForm.max_number_per_week &&
                                      scheduleLimitForm.max_number_per_week <
                                        1 &&
                                      disableSubmit ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          Nagative value not allowed
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                            {!scheduleLimitForm.accept_max_appointments &&
                            !scheduleLimitForm.accept_max_appointments &&
                            !scheduleLimitForm.accept_maximum_appointments &&
                            !scheduleLimitForm.accept_maximum_appointments &&
                            disableSubmit ? (
                              <FormFeedback type="invalid" className="d-block">
                                Please select any of these
                              </FormFeedback>
                            ) : null}
                          </Col>

                          <Col md={12} className="mt-4 align-items-center">
                            <button
                              type="button"
                              className="btn  btn-primary"
                              onClick={submitScheduleLimits}
                            >
                              Save
                            </button>{" "}
                            <button
                              type="button"
                              className="btn  margin-left-10"
                              onClick={() => handleScheduleLimitModal()}
                            >
                              Cancel
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    {activeTab === "block-time" && (
                      <>
                        {formattedOverrides.length > 0 ? (
                          <>
                            {" "}
                            <h3 className="my-5 mx-4">Blocked Off Time</h3>
                            <Row>
                              {formattedOverrides?.map(data => {
                                return (
                                  <>
                                    <Col md={12} className="mx-4">
                                      <p>
                                        <a
                                          onClick={() => {
                                            setDeleteBlockId(data.id)
                                            setModalDelete(true)
                                          }}
                                          className="text-danger delete-btn cursor-pointer"
                                        >
                                          <img src={deleteBin} />
                                        </a>{" "}
                                        <span className="mx-4 ">
                                          {data.date}
                                        </span>
                                      </p>
                                    </Col>
                                  </>
                                )
                              })}
                            </Row>{" "}
                          </>
                        ) : (
                          <h3 className="mx-3 my-5">No data found</h3>
                        )}
                      </>
                    )}
                  </ModalBody>
                </Modal>
                <Modal isOpen={isMultipleHoursOpen}>
                  <ModalBody>
                    <div className="w-100 text-end">
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => handleMultipleHoursModal()}
                      ></button>
                    </div>
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md={12} className="mt-2 mb-2 relative">
                          {dateMultipleHoursOpen ? (
                            <h4>
                              {" "}
                              Hours for{" "}
                              {moment(multipleAvailData[0]?.start).format(
                                "MMMM DD"
                              )}
                            </h4>
                          ) : (
                            <p className="text-center card-title-main">
                              Select {multipleAvailData[0]?.week_day} timeslots
                            </p>
                          )}
                          {dateMultipleHoursOpen && (
                            <Col md="12" className="mb-2">
                              <div className="form-check form-switch">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitch2"
                                  name="is_closed"
                                  checked={multipleAvailData[0]?.is_closed}
                                  onChange={e => {
                                    const updatedData = [...multipleAvailData]
                                    updatedData[0].is_closed = !e.target.checked
                                    setMultipleAvailData(updatedData)
                                  }}
                                  onClick={e => {
                                    const updatedData = [...multipleAvailData]
                                    updatedData[0].is_closed = !e.target.checked
                                    setMultipleAvailData(updatedData)
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customSwitch2"
                                >
                                  Closed
                                </label>
                              </div>
                            </Col>
                          )}
                          {multipleAvailData[0]?.is_closed &&
                          dateMultipleHoursOpen
                            ? ""
                            : multipleAvailData.map((item, index) => (
                                <div key={index}>
                                  <Row className="mt-4 align-items-end">
                                    <Col
                                      md="5"
                                      className="custom-date-picker-style"
                                    >
                                      <Label>Start Time</Label>
                                      <Input
                                        className="form-control default-search-input"
                                        name={`availabilities[${index}].start_time`}
                                        placeholder="From"
                                        disabled={isCounsellor}
                                        type="time"
                                        onChange={e =>
                                          handleMultiTimeSlot(
                                            e,
                                            index,
                                            "start_time"
                                          )
                                        }
                                        value={item.start_time}
                                      />
                                    </Col>
                                    <Col
                                      md="5"
                                      className="custom-date-picker-style"
                                    >
                                      <Label>End Time</Label>
                                      <Input
                                        className="form-control default-search-input"
                                        name={`availabilities[${index}].end_time`}
                                        placeholder="To"
                                        type="time"
                                        disabled={isCounsellor}
                                        onChange={e =>
                                          handleMultiTimeSlot(
                                            e,
                                            index,
                                            "end_time"
                                          )
                                        }
                                        value={item.end_time}
                                      />
                                    </Col>
                                    {multipleAvailData.length > 1 && (
                                      <Col
                                        md="2"
                                        className="d-flex align-items-center justify-content-center"
                                      >
                                        {!isCounsellor && (
                                          <button
                                            type="button"
                                            onClick={() =>
                                              removeAvailability(index)
                                            }
                                            className="h-40 btn d-flex btn-light align-items-center waves-effect waves-light"
                                          >
                                            <i className="waves-effect mdi mdi-close font-size-20" />
                                          </button>
                                        )}
                                      </Col>
                                    )}
                                    {item.start_time && item.end_time && (
                                      <Col
                                        md="12"
                                        className="mt-2 d-flex align-items-center mt-4"
                                      >
                                        <Switch
                                          onChange={checked =>
                                            handleEnableRoom(checked, index)
                                          }
                                          checked={
                                            switchStates[index] || item.room
                                          }
                                          onColor="#153c3c"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          width={40}
                                          height={20}
                                        />
                                        <span className="mx-3 font-weight-bold ">
                                          Want select room for this slot?
                                        </span>
                                      </Col>
                                    )}
                                    {(switchStates[index] ||  multipleAvailData[index].room) && (
                                      <Col md="12" className="mt-2">
                                        {roomOption[index]?.length > 0 ||
                                        multipleAvailData[index].room ? (
                                          <Select
                                            options={roomOption[index]}
                                            value={item?.room}
                                            placeholder="Select Room "
                                            className="me-4 mt-2"
                                            onChange={value =>
                                              handleRoomSelect(value, index)
                                            }
                                          />
                                        ) : roomLoad ? (
                                          <p className="text-center">
                                            {" "}
                                            <Loader />
                                          </p>
                                        ) : (
                                          <span className="font-weight-bold text-danger">
                                            No rooms available for selected time
                                            slot !
                                          </span>
                                        )}
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              ))}
                          {multipleAvailData[0]?.is_closed &&
                          dateMultipleHoursOpen
                            ? ""
                            : multipleAvailData.length < 5 && (
                                <Row className="mt-2">
                                  <Col
                                    md="5"
                                    className="custom-date-picker-style mt-2"
                                  >
                                    {!isCounsellor && (
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={addAvailability}
                                      >
                                        Add more hours
                                      </button>
                                    )}
                                  </Col>
                                </Row>
                              )}
                        </Col>

                        <Col md={12} className="mt-4 align-items-center">
                          {!dateMultipleHoursOpen && (
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={isCounsellor}
                              onClick={() => saveMultipleHours()}
                            >
                              Save
                            </button>
                          )}
                          {dateMultipleHoursOpen && (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => setMultipleHours()}
                            >
                              Set Hours
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn  margin-left-10"
                            onClick={() => handleMultipleHoursModal()}
                          >
                            Cancel
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={modalDelete}
                  toggle={() => {
                    deleteToggle()
                  }}
                  centered={true}
                >
                  <div className="modal-body">
                    <Row>
                      <Col className="col-12">
                        <div className="d-flex align-items-center flex-column">
                          <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                          <h3>Are you sure?</h3>
                          <h5>{"You won't be able to revert this!"}</h5>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-center">
                          <Button
                            type="button"
                            onClick={() => {
                              deleteToggle()
                            }}
                            className="btn btn-inactive waves-effect m-2"
                            data-dismiss="modal"
                          >
                            No
                          </Button>
                          <Button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => handleDeleteBlockTIme()}
                          >
                            Yes, Delete it
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Modal>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Availibity
