import { gql } from "@apollo/client"

export const GET_COUNSELLORS = gql`
  query Calendars($argumentsInput: ArgumentsInput) {
    getCalendarLegend(argumentsInput: $argumentsInput) {
      data {
        calendars {
          name
          timezone
          id
        }
      }
      code
      status
      message
    }
  }
`

export const GET_ALL_BLOCKED_CALENDAR = gql`
query GetAllBlockedCalendar {
  getAllBlockedCalendar {
    data {
      overrides {
        id
        start_time
        end_time
        override_date
        is_blocked
        therapist_calendar {
          id
          name
        }
      }
    }
  }
}
`
export const BLOCK_OFF_CALENDAR = gql`
  mutation BlockOffCalendar(
    $createAvailabilityInput: CreateAvailabilityInputArr
  ) {
    blockOffCalendar(createAvailabilityInput: $createAvailabilityInput) {
      data {
        overrides {
          calendar_id
          id
          end_time
          is_blocked
          start_time
          week_day
          month
        }
        block_off_slots {
          appointment {
            id
            time
            date
            end_time
            type
          }
        }
      }
      code
      message
      status
      __typename
    }
  }
`

export const GET_COUNSELORS = gql`
  query GetCalendars($argumentsInput: ArgumentsInput) {
    getCalendars(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        calendars {
          id
          slug
          name
          email
          clientCount
          share_percentage
          external_amount
          gst_enabled
          service_fee
          is_published
          user {
            name
            first_name
            last_name
            id
          }
        }
        totalCount
      }
    }
  }
`

export const GET_COUNSELOR = gql`
  query Query($argumentsInput: ArgumentsInput) {
    getCalendar(argumentsInput: $argumentsInput) {
      code
      data {
        calendar {
          timezone
          is_published
          id
          name
          description
          url
          email
          reply_to
          location
          appointments {
            id
            client {
              id
              first_name
              last_name
              email
              is_active
            }
          }
          appointmentTypeHasCalendars {
            id
            price
            appointment_type_id
            appointmentType{
              name
            }
          }
          appointment_types {
            id
            name
            address
            duration
            price
            color
            call_type
          }
          therapies {
            id
            name
          }
          specialization {
            id
            name
          }
          user {
            id
            name
            first_name
            last_name
            phones {
              id
              mobile_number
            }
            mobile_number
          }
          counselling_type {
            id
            name
          }
          gst_enabled
          share_percentage
          external_amount
          service_fee

          about_2
          slug
          team_overview
          webflow_name
          archived
          draft
          role
          language {
            id
            name
          }
          locations {
            id
            name
          }
        }
      }
      status
      message
    }
  }
`

export const GET_COUNSELOR_DATA_LIST = gql`
  query GetCalendarDropdown($argumentsInput: ArgumentsInput) {
    getCalendarDropdown(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        counselling {
          id
          name
        }
        therapies {
          id
          name
        }
        __typename
        languages {
          id
          item_id
          name
        }
        location_groups {
          id
          name
          item_id
        }
        locations {
          id
          item_id
          location_id
          name
        }
        concerns {
          id
          item_id
          name
        }
      }
    }
  }
`
export const UPDATE_COUNSELOR = gql`
  mutation UpdateCalendar($updateCalendarInput: UpdateCalendarInput!) {
    UpdateCalendar(updateCalendarInput: $updateCalendarInput) {
      code
      status
      message
    }
  }
`
export const UPDATE_COUNSELORPAY = gql`
  mutation UpdateCounsellorPay($updateCalendarInput: UpdateCalendarInput!) {
    updateCounsellorPay(updateCalendarInput: $updateCalendarInput) {
      message
    }
  }
`
export const UPDATE_COUNSELOR_PAY = gql`
  mutation UpdateCalendarCounsellorPay(
    $updateCalendarInput: UpdateCalendarInput!
  ) {
    updateCalendarCounsellorPay(updateCalendarInput: $updateCalendarInput) {
      code
      status
      message
      data {
        calendar {
          name
          email
          service_fee
          share_percentage
          gst_enabled
          external_amount
        }
      }
    }
  }
`

export const GET_COUNSELORPAY_DATA_LIST = gql`
  query GetCalendarsCounsellorsPay($argumentsInput: ArgumentsInput) {
    getCalendarsCounsellorsPay(argumentsInput: $argumentsInput) {
      code
      data {
        calendars {
          id
          name
          email
          gst_enabled
          external_amount
          service_fee
          share_percentage
          calendar_pay {
            month
            id
            invoice_number
            invoice_start_date
            invoice_end_date
            is_admin_review
            url
            paid_status
            is_change_request
            is_revise_invoice
          }
        }
        totalCount
      }
      message
      status
    }
  }
`

export const GET_INVOICE_DATA_LIST = gql`
  query counsellorAppointment($argumentsInput: ArgumentsInput) {
    counsellorAppointment(argumentsInput: $argumentsInput) {
      code
      data {
        calendar {
          id
          name
          email
          gst_enabled
          service_fee
          external_amount
          sub_total
          total_Amount
          total_gst_Amount
          total_sessions
          service_fee_amount
          share_percentage
          calendarPay {
            url
            id
            is_change_request
            is_revise_invoice
            is_revise_invoice_approved
          }
          outstanding {
            total
            gst
            counsellor_rate
            utc_datetime
            price
            amount_paid
            session
            email
            paid
            id
            date
            client {
              id
              name
              first_name
              last_name
              email
            }
            amount_paid
            therapist_calendar {
              id
              name
            }
            location
            last_name
            first_name
            end_time
            plan {
              id
              is_document_submitted
              approval_status
              total_sessions
              use_session
              billed_sessions
              payment_type_id
              payment_source {
                name
              }
              use_session
            }
            price
            paid
            type
          }
          default {
            total
            gst
            date
            counsellor_rate
            utc_datetime
            price
            amount_paid
            session
            email
            paid
            id
            client {
              id
              name
              first_name
              last_name
              email
            }
            amount_paid
            therapist_calendar {
              id
              name
            }
            location
            last_name
            first_name
            end_time
            plan {
              id
              is_document_submitted
              approval_status
              total_sessions
              use_session
              billed_sessions
              payment_type_id
              payment_source {
                name
              }
              use_session
            }
            price
            paid
            type
          }
          misc {
            total
            gst
            counsellor_rate
            utc_datetime
            date
            amount_paid
            session
            email
            paid
            description
            id
            client {
              id
              name
              first_name
              last_name
              email
            }
            amount_paid
            therapist_calendar {
              id
              name
            }
            location
            last_name
            first_name
            end_time
            plan {
              id
              is_document_submitted
              approval_status
              total_sessions
              use_session
              billed_sessions
              payment_type_id
              payment_source {
                name
              }
              use_session
            }
            paid
            type
            is_delete_misc
            is_future_paid
            is_misc
            is_outstanding
            price
            first_name
            last_name
          }
          future_paid {
            date
            total
            gst
            counsellor_rate
            utc_datetime
            amount_paid
            session
            email
            paid
            description
            id
            client {
              id
              name
              first_name
              last_name
              email
            }
            amount_paid
            therapist_calendar {
              id
              name
            }
            location
            last_name
            first_name
            end_time
            plan {
              id
              payment_status
              is_document_submitted
              approval_status
              total_sessions
              use_session
              billed_sessions
              payment_type_id
              payment_source {
                name
                is_direct
              }
              use_session
            }
            paid
            type
            is_delete_misc
            is_future_paid
            is_misc
            is_outstanding
            price
            first_name
            last_name
          }
        }
      }
      message
      status
    }
  }
`

export const EXPAND_INVOICE_DATA_LIST = gql`
  query expandCounsellorsAppointments($argumentsInput: ArgumentsInput) {
    expandCounsellorsAppointments(argumentsInput: $argumentsInput) {
      code
      data {
        totalCount
        appointments {
          total
          gst
          date
          top_up_amount
          counsellor_rate
          type
          price
          date_created
          amount_paid
          utc_datetime
          email
          paid
          id
          amount_paid
          location
          last_name
          first_name
          end_time
          plan {
            id
            payment_status
            payment_type_id
            payment_source {
              name
            }
            use_session
          }
          price
          paid
          type
          client {
            first_name
            last_name
            email
          }
        }
      }
      message
      status
    }
  }
`
export const UPDATE_EXPANDED_LIST = gql`
  mutation UpdateAppointment($updateAppointmentInput: UpdateAppointmentInput!) {
    updateAppointment(updateAppointmentInput: $updateAppointmentInput) {
      code
      message
      status
    }
  }
`

export const GET_EMAIL_TEXT_DATA = gql`
  query {
    emailText {
      emailText
    }
  }
`
export const SET_EMAIL_TEXT_DATA = gql`
  mutation EmailSaveText($argumentsInput: ArgumentsInput) {
    emailSaveText(argumentsInput: $argumentsInput) {
      emailText
    }
  }
`
export const GET_COUNSELORS_DATA = gql`
  query Calendars($argumentsInput: ArgumentsInput) {
    getCalendarLegend(argumentsInput: $argumentsInput) {
      data {
        calendars {
          name
          id
          item_id
        }
      }
      code
      status
      message
    }
  }
`

export const GET_LOCATIONS_CONCERNS = gql`
  query Query($argumentsInput: ArgumentsInput) {
    findOneLocationGroupsAndConcerns(argumentsInput: $argumentsInput) {
      code
      data {
        calendar {
          id
          name
          description
          email
          reply_to
          location
          locationsGroup {
            id
            name
            __typename
          }
          concerns {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      status
      message
      __typename
    }
  }
`

export const SYNC_API_DATA = gql`
  query SyncDatatoAucityTo {
    SyncDatatoAucityTo {
      message
      status
    }
  }
`
export const GET_CLIENTS_DATA = gql`
  query GetClients($argumentsInput: ArgumentsInput) {
    getClients(argumentsInput: $argumentsInput) {
      data {
        clientResponse {
          id
          name
          first_name
          last_name
          email
          mobile_number
          is_active
        }
      }
    }
  }
`
export const GET_TIMEZONE = gql`
  query GetAllTimeZones {
    getAllTimeZones {
      code
      message
      status
      data {
        allTimeZone {
          display
          zoneName
          gmtOffset
        }
      }
    }
  }
`

export const GET_CATEGORY_TYPE = gql`
  query CategoryTypeAppointmentTypes($argumentsInput: ArgumentsInput) {
    getAllAppointmentsTypeByCategory(argumentsInput: $argumentsInput) {
      data {
        categoryTypeAppointmentTypes {
          category
          appointmentTypes {
            id
            name
          }
        }
      }
    }
  }
`
export const GET_LOCATION_LIST = gql`
  query GetCalendarDropdown($argumentsInput: ArgumentsInput) {
    getCalendarDropdown(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        locations {
          id
          item_id
          location_id
          name
        }
        location_groups {
          id
          name
          item_id
        }
      }
    }
  }
`
