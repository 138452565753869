import React, { useCallback, useState, useEffect } from "react";
import { Col, Row, CardTitle } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/Common/Table";
import useStyles from "../../Style";
import classNames from "classnames";
import moment from "moment";
import { downloadExcel, formatCurrency } from "../../../../functions/functions";
import SelectedExportInvoice from "./SelectedExportInvoice";
import { deleteExportedInvoices } from "../../../../store/actions";

function ExportedInvoiceList(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { handleShowPreviewTab, setSelectedPreviewID } = props;
    const exportedInvoicesData = useSelector(state => state.invoicingManagement.exportedInvoices);
    const [selectedId, setselectedId] = useState({});
    const [selectedList, setSelectedList] = useState([]);
    const [XLSXData, setXLSXData] = useState([]);

    const handlClickPreviewTab = (row) => {
        handleShowPreviewTab(true);
        setSelectedPreviewID({
            "payment_id": row.payment_id,
            "calendar_id": row.calendar_id,
            "client_id": row.client_id,
            child: row.child_row,
            parent: row.parent_row,
        });
    }

    const handleExportXLSXClick = () => {

        const updatedXLSXData = exportedInvoicesData.map(({ payment_id, calendar_id, client_id, child_row, parent_row, ...rest }) => rest);

        const formattedXLSXData = updatedXLSXData.map(obj => {
            return {
                ...obj,
                invoice_date: moment(obj.invoice_date).format("DD-MMM-yyyy"),
                total: "$" + obj.total
            };
        });

        const headerXLSXObj = {
            invoice_number: "Invoice Number",
            claim_number: "Claim Number",
            invoice_date: "Invoice Date",
            therapist_name: "Therapist",
            client_name: "Client",
            number_of_appointment: "Number of Appointments",
            total: "Invoice Total"
        };

        const newXLSXDataArray = [headerXLSXObj, ...formattedXLSXData];

        const convertedArray = newXLSXDataArray.map(obj => [
            obj.invoice_number,
            obj.claim_number,
            obj.invoice_date,
            obj.therapist_name,
            obj.client_name,
            obj.number_of_appointment,
            obj.total
        ]);

        downloadExcel(convertedArray, "Exported Invoices");
    };

    //************************** Table Data ***************************** */

    const columns = [
        {
            text: "",
            dataField: "calendar_id",
            formatter: allCheckedRowFun,
            style: {
                width: 50,
            },
        },
        {
            text: "Invoice Number",
            dataField: "invoice_number",
            formatter: appointmentTypeFun,
        },
        {
            text: "Claim Number",
            dataField: "claim_number",
        },
        {
            text: "Invoice Date",
            dataField: "invoice_date",
            formatter: invoiceDateFun,
        },
        {
            text: "Therapist",
            dataField: "therapist_name",
        },
        {
            text: "Client",
            dataField: "client_name",
        },
        {
            text: "Number of Appointments",
            dataField: "number_of_appointment",
            headerAlign: "center",
            align: "center",
        },
        {
            text: "Invoice Total",
            dataField: "total",
            formatter: (value, row, index, field) => {
                return "$" + value
            },
        },
    ]

    function allCheckedRowFun(value, row, index, field) {
        return <SelectedExportInvoice rowValue={value} row={row} index={index} field={field} onSelectRow={onSelectAppointment} />
    }

    function appointmentTypeFun(value, row, index, field) {
        return (
            value
        )
    }

    function invoiceDateFun(value, row, index, field) {
        return moment(value).format("DD-MMM-yyyy");
    }

    //************************** Selected Data ***************************** */

    const onSelectAppointment = (id, isSelected) => {
        setselectedId((prevSelectedRows) => ({
            ...prevSelectedRows,
            [id]: isSelected,
        }));
    }

    useEffect(() => {
        const keys = Object.keys(selectedId);
        const filteredObj = keys.filter((key) => {
            return selectedId[key];
        });
        const filteredArray = filteredObj.map(str => {
            return Number(str);
        });
        setSelectedList(filteredArray);
    }, [selectedId]);

    const handleDeletedItem = () => {
        dispatch(deleteExportedInvoices(selectedList));
    }

    return (
        <>
            <Row className="align-items-center">
                <Col xs={8} sm={6}>
                    <CardTitle className="h4 m-0">
                        <h5 className="m-0">Exported Invoices</h5>
                    </CardTitle>
                </Col>
                <Col xs={4} sm={6}>
                    <div className="d-flex align-items-end justify-content-end gap-2">
                        <button
                            type="button"
                            className={classNames(`btn btnLightBlue text-capitalize`, classes.button)}
                            disabled={selectedList.length > 0 ? false : true}
                            onClick={handleDeletedItem}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            className={classNames(`btn btnLightBlue text-capitalize`, classes.button)}
                            onClick={handleExportXLSXClick}
                        >
                            Export
                        </button>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={12} md={12} lg={12} xl={12} className="table-custom-redesign">
                    <div className={classNames(classes.hideTableBorder)}>
                        <Table
                            data={exportedInvoicesData}
                            columns={columns}
                            noDataIndication={'No Data Found'}
                            loading={false}
                            keyField="invoice_number"
                            isSearch={false}
                            isStriped={false}
                            isHover={false}
                            isBordereded={false}
                            isPagination={false}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ExportedInvoiceList