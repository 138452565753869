import React from "react"
import { Button, Col, Modal, Row, Spinner } from "reactstrap"

const DeleteCounsellor = ({deleteToggle, modalDelete, setModalDelete, setDeleteRecord,  inProgress, handleDelete}) => {
  return (
    <>
      {" "}
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => {
                    setModalDelete(false)
                    setDeleteRecord({ id: null })
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!inProgress ? (
                  <Button
                    disabled={inProgress}
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => handleDelete()}
                  >
                    Yes, delete it
                  </Button>
                ) : (
                  <Button
                    className="btn btn-primary waves-effect waves-light"
                    disabled
                  >
                    <Spinner
                      type="grow"
                      className="me-1 align-middle spinner-grow-sm "
                      color="light"
                    />
                    Processing...
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default DeleteCounsellor
