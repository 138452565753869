import React, { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import Select from "react-select"
import { GET_APPOINTMENTS } from "../Appointments/gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import moment from "moment"
import { CREATE_NOTE } from "./gql/userGql"
import { toast } from "react-toastify"

const SessionNotesModal = ({
  sessionNoteShow,
  openSessionModal,
  username,
  additionalInformation,
  sessionEditInfo,
  handleRetriveUserRemarks,
}) => {
  const [selectValue, setSelectValue] = useState(null)
  const [selectOptions, setSelectOptions] = useState(null)
  const [allAppointment, setAllAppointment] = useState([])
  const [noteFields, setNoteFields] = useState({
    topics: "",
    interventions: "",
    plans: "",
  })

  useEffect(() => {
    setNoteFields({
      topics: "",
      interventions: "",
      plans: "",
    })
    setSelectValue([])
  }, [sessionNoteShow])

  const addMessage = e => {
    const { name, value } = e.target
    setNoteFields(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const [getAppointmentList, listResponse] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: "no-cache",
  })

  const onSelectChange = val => {
    setSelectValue(val)
  }

  const [createOrUpdateNote, createOrUpdateNoteRes] = useMutation(CREATE_NOTE)

  useEffect(() => {
    if (
      createOrUpdateNoteRes.data &&
      createOrUpdateNoteRes.data?.createOrUpdateNote?.status &&
      createOrUpdateNoteRes.data?.createOrUpdateNote?.code === 200
    ) {
      toast.success(createOrUpdateNoteRes.data?.createOrUpdateNote?.message)
      handleRetriveUserRemarks()
      openSessionModal()
    }
  }, [createOrUpdateNoteRes.data])

  useEffect(() => {
    if (username) {
      getAppointmentList({
        variables: {
          argumentsInput: {
            page: 1,
            limit: 20,
            client_id: parseInt(username),
            search: "",
            sort: "utc_datetime",
            sort_dir: "DESC",
            device_type:"web",
            start_date: moment()
              .subtract(1, "month")
              .startOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss[Z]"),
            end_date: moment()
              .add(1, "month")
              .startOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss[Z]"),
          },
        },
      })
    }
  }, [sessionNoteShow])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getAppointments &&
      listResponse.data.getAppointments.code === 200 &&
      listResponse.data.getAppointments.status
    ) {
      if (listResponse.data.getAppointments.data) {
        const appointments = listResponse.data.getAppointments.data.appointments_arr?.map((elem)=>{
          return elem?.appointment
        })
        setAllAppointment(appointments)
      }
    } else if (!listResponse.data) {
      setAllAppointment({})
    } else {
      setAllAppointment({})
    }
  }, [listResponse.data])

  useEffect(() => {
    if (allAppointment.length > 0) {
      const additionalInformationIds = additionalInformation?.map(elem => {
        return elem.id
      })
      const filterAppointments = allAppointment.filter(elem => {
        return !additionalInformationIds.includes(elem.id)
      })
      const sessionOption = filterAppointments?.map(elem => {
        return {
          label: `${elem?.type_of_appointment?.name} - ${moment(
            elem?.utc_datetime
          ).format("dddd, MMMM Do, h:mm A")}`,
          name: `${elem?.type_of_appointment?.name} - ${moment(
            elem?.utc_datetime
          ).format("dddd, MMMM Do, h:mm A")}`,
          id: elem?.id,
          date: elem?.utc_datetime,
        }
      })
      setSelectOptions(sessionOption)
    }
  }, [allAppointment])

  const saveSessionNote = () => {
    const sessionNoteVariables = {
      id: sessionEditInfo ? sessionEditInfo?.appointment_notes?.id : undefined,
      appointment_id: parseInt(selectValue?.id),
      interventions: noteFields?.interventions,
      topics: noteFields?.topics,
      plans: noteFields?.plans,
    }

    createOrUpdateNote({
      variables: {
        updateAppointmentInput: sessionNoteVariables,
      },
    })
  }

  useEffect(() => {
    if (sessionEditInfo) {
      setNoteFields({
        topics: sessionEditInfo?.appointment_notes?.topics,
        interventions: sessionEditInfo?.appointment_notes?.interventions,
        plans: sessionEditInfo?.appointment_notes?.plans,
      })
      setSelectValue({
        id: sessionEditInfo?.id,
      })
    }
  }, [sessionEditInfo])

  return (
    <>
      <Modal isOpen={sessionNoteShow || sessionEditInfo} size="lg">
        <ModalHeader toggle={() => openSessionModal()} tag="h4">
        {sessionEditInfo ? "Edit" : "Add" } session note
        </ModalHeader>
        <ModalBody>
          {selectOptions?.length > 0 || sessionEditInfo ? (
            <>
              {" "}
              {!sessionEditInfo && (
                <div className="border border-3 p-3 rounded">
                  <p className="h5 mb-3">Session</p>
                  <Select
                    className="input"
                    onChange={onSelectChange}
                    placeholder="Select session"
                    value={selectValue}
                    options={selectOptions}
                  />
                </div>
              )}
              <>
                <div className="border border-3 p-3 my-3 rounded">
                  <p className="h5 mb-3">Topics discussed</p>
                  <textarea
                    placeholder="Write a message"
                    rows={3}
                    name="topics"
                    className="p-3 rounded w-100"
                    value={noteFields?.topics}
                    onChange={addMessage}
                  ></textarea>
                </div>
                <div className="border border-3 p-3 my-3 rounded">
                  <p className="h5 mb-3">Intervention</p>
                  <textarea
                    placeholder="Write a message"
                    rows={3}
                    className="p-3 rounded w-100"
                    value={noteFields?.interventions}
                    onChange={addMessage}
                    name="interventions"
                  ></textarea>
                </div>
                <div className="border border-3 p-3 my-3 rounded">
                  <p className="h5 mb-3">Plans</p>
                  <textarea
                    placeholder="Write a message"
                    rows={3}
                    className="p-3 rounded w-100"
                    value={noteFields?.plans}
                    onChange={addMessage}
                    name="plans"
                  ></textarea>
                </div>
                <div>
                  <Button
                    className="btn btn-primary"
                    disabled={selectValue.length <= 0}
                    onClick={saveSessionNote}
                  >
                    Save
                  </Button>
                </div>
              </>
            </>
          ) : (
            <>
                <p className="h5 text-center ">No appointments to add session notes!</p>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default SessionNotesModal
