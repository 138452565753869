import { Card, CardBody, Col, Row } from "reactstrap"
import Table from "../../components/Common/Table"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import style from "./style.module.scss"
import noApproval from "../../assets/images/legend-icons/noApproval.png"

import notPaid from "../../assets/images/legend-icons/not-paid.svg"
import { useSelector } from "react-redux"
const FuturPaidList = ({
  futurPaidList,
  setFuturPaidList,
  miscList,
  setMiscList,
  updateMutations,
}) => {
  const handleFuturPaidCheckbox = (e, row) => {
    setFuturPaidList(prev => {
      return prev.filter(item => {
        if (item.id == row.id) {
          return false
        }
        return item
      })
    })
    if (miscList.length > 0) {
      setMiscList(prev => {
        return [...prev, row]
      })
    } else {
      setMiscList([row])
    }
    updateMutations(row.id, { is_misc: true })
  }
  const userRole = useSelector(state => state.Login?.user?.user?.role)
  const columns = [
    {
      text: "",
      dataField: "checkbox",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            <input
              type="checkbox"
              defaultChecked
              data-title={row.id}
              onClick={e => handleFuturPaidCheckbox(e, row, false)}
            />
          </span>
        )
      },
      hidden: userRole.role_name == "Admin" ? false : true,
    },
    {
      text: "Client",
      dataField: "first_name",
      width: 150,
      formatter: (cell, row) => {
        let img = ""
        if (row.plan.is_document_submitted == false) {
          img = <img src={noApproval} width="20px" height="20px" />
        } else if (row.paid == "no") {
          img = <img src={notPaid} width="20px" height="20px" />
        }
        let name = `${
          row?.client && row?.client?.name
            ? row?.client.name
            : row?.client?.first_name && row?.client?.last_name
            ? `${row?.client?.first_name} ${row?.client?.last_name}`
            : `${row.first_name} ${row.last_name}`
        }`
        return (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div>{name}</div>
              <div className="errorBoundary-ml-10">{img}</div>
            </div>
          </>
        )
      },
    },
    {
      text: "Rate",
      dataField: "price",
      width: 150,
      formatter: (cell, row) => {
        return <span>${Number(cell).toFixed(2)}</span>
      },
    },
    {
      text: "Counsellor Rate",
      dataField: "counsellor_rate",
      width: 150,
      formatter: (cell, row) => {
        return <span>${Number(cell).toFixed(2)}</span>
      },
    },
    {
      text: "Date",
      dataField: "date",
      width: 150,
      formatter: (cell, row) => {
        return <span>{moment(cell).format("DD-MMM-YYYY")}</span>
      },
    },
    {
      text: "Appointment type",
      dataField: "Appointment type",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            {row.plan &&
            row.plan.payment_source &&
            row.plan.payment_source.is_direct
              ? "Direct Bill Client"
              : "Non Direct Bill Client"}
          </span>
        )
      },
    },
    {
      text: "Category",
      dataField: "Category",
      width: 150,
      formatter: (cell, row) => {
        return <span>{row?.plan?.payment_source.name}</span>
      },
    },
    {
      text: "Comment",
      dataField: "Comment",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            {row.plan &&
            row.plan.payment_source &&
            row.plan.payment_source.is_direct
              ? "These sessions will show up on your future invoice once paperwork is caught up"
              : "These sessions will show up on your future invoice once sessions have been charged."}
          </span>
        )
      },
    },
  ]
  return (
    <React.Fragment>
      {futurPaidList && futurPaidList.length > 0 && (
        <>
          <div
            className={`${style.titleInvoice} flex-wrap-mobile-only  basic-card-title d-flex justify-content-between align-items-center`}
          >
            Future paid list
            <div className={`legend-list d-flex align-items-center`}>
              <span>Legend: &nbsp;</span>
              <img src={noApproval} width="20px" height="20px" />
              &nbsp; No Approval &nbsp;
              <img src={notPaid} width="20px" height="20px" />
              &nbsp; No Payment
            </div>
          </div>

          <Row>
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
                <CardBody className="p-0 table-custom-redesign">
                  <Table
                    data={futurPaidList || []}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    isPagination={false}
                    isSearch={false}
                    isStriped={false}
                    isHover={false}
                    isBordereded={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}
export default FuturPaidList
