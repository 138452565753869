import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Col } from "reactstrap"
import { GENERATE_RECEIPT } from "../gql/gql"
import { useLazyQuery } from "@apollo/client"
import Preview from "../../Preview/Preview"
import Loader from "../../../../components/Common/Loader"
import { useDispatch, useSelector } from "react-redux"
import { setRecieptPreviewData } from "../../../../store/actions"

const PreviewInfo =({ selectedList }) => {
  const dispatch = useDispatch()
  const [receiptData, setReceiptData] = useState([])
  const [obj, setObj] = useState({})
  const [isSpinner, setIsSpinner] = useState(true)
  const [isRateUpdate, setIsRateUpdate] = useState(false)
  const previewStore = useSelector(
    state => state.invoicingManagement.reciptPreview
  )
  const previewData = useMemo(()=>{
    if(previewStore){
      return previewStore
    }
  },[previewStore])

  //************************** Receipt ***************************** */

  const [getReceiptData, receiptDataResponse] = useLazyQuery(GENERATE_RECEIPT, {
    fetchPolicy: "no-cache",
  })

  function getReceiptDataFun() {
    getReceiptData({
      variables: {
        getReceiptView: {
          appointmentIds: selectedList,
        },
      },
    })
  }

  const handleUpdateRate = (value, row,index) => {
    let updatedData = receiptData
    let { rate, price, total, gst } = updatedData.appointments[index]
    value = parseFloat(value)
    price = value
    gst = (price * 5) / 100
    total = price + gst
    updatedData.appointments[index].price = price
    updatedData.appointments[index].gst = gst
    updatedData.appointments[index].amount_paid = total
    updatedData.appointments[index].total = total
    let objUpdatedData = obj
    objUpdatedData.calendar = updatedData
    dispatch(setRecieptPreviewData({data:objUpdatedData,
      id:index,
      price,gst,total
    }))
  }
  useEffect(()=>{
    setObj(previewData)
    setReceiptData(previewData.calendar)
  },[previewData])

  useEffect(() => {
    getReceiptDataFun()
  }, [])

  useEffect(() => {
    if (
      receiptDataResponse.data &&
      receiptDataResponse.data.getReceiptView &&
      receiptDataResponse.data.getReceiptView.status &&
      receiptDataResponse.data.getReceiptView.code === 200
    ) {
      dispatch(setRecieptPreviewData({data:receiptDataResponse.data.getReceiptView.data}))
      setIsSpinner(false)
      setReceiptData(receiptDataResponse.data.getReceiptView.data.calendar)
      setObj(receiptDataResponse.data.getReceiptView.data)
    } else {
      setIsSpinner(true)
      setReceiptData([])
      setObj({})
    }
  }, [receiptDataResponse.data])
  
  const storeAppointments=[];
  return (
    <>
      {isSpinner ? (
        <Col className="col-12 user-role-create-height-double ">
          <div className="spinner">
            <Loader />
          </div>
        </Col>
      ) : (
        <Preview
          objData={obj}
          data={receiptData}
          handleUpdateRate={handleUpdateRate}
          isRateUpdate={isRateUpdate}
          storeAppointments={storeAppointments}
          previewData={previewData}
        />
      )}
    </>
  )
}

export default PreviewInfo
