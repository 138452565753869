import classNames from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import DatePicker from "react-datepicker"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  textFilter,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import { makeStyles } from "@material-ui/core"
import { Modal, ModalBody, Spinner } from "reactstrap"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css"
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  CardTitle,
  Container,
} from "reactstrap"
import moment from "moment"
import { useHistory, Link } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import BlockOftimeModal from "../../pages/Counselor/BlockOftimeModal"
import {
  GET_COUNSELLORS,
  BLOCK_OFF_CALENDAR,
  GET_ALL_BLOCKED_CALENDAR,
} from "../../pages/Counselor/gql/Gql"
import { DeleteBlockedTime } from "../../pages/User/gql/userGql"
import { useLocation } from "react-router-dom"

// import overlayFactory from 'react-bootstrap-table2-overlay';
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      marginBottom: "10px",
    },
  },

  boxContainer: {
    flex: "100%",
    "@media (max-width: 768px)": {
      flex: "100%",
    },
  },

  addBtn: {
    width: "100%",
    textAlign: "end",
    display: "flex",
    padding: "0.5rem",
    justifyContent: "end",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  width: {
    width: "800px",
    textAlign: "end",
    display: "flex",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  btnHeight: {
    height: "40px",
    marginLeft: "5px",
  },
  searchContainer: {
    maxWidth: "250px",
    "@media (max-width: 768px)": {
      flex: "40%",
      width: "100%",
    },
  },

  filterContainer: {
    width: "250px",
    "@media (max-width: 768px)": {
      flex: "60%",
      width: "100%",
    },
  },
}))
const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  keyField = "id",
  dropup,
  totalSize,
  columns,
  noDataIndication,
  loading,
  isPagination = true,
  isSearch,
  isFilter,
  selectOptions,
  onSelectChange,
  selectValue,
  isBordereded = true,
  isStriped = true,
  isHover = true,
  isDisplayColumnList = false,
  isDisplayUserAdd = false,
  CustomToggleList,
  myColumnToggle,
  extraTableToolKits,
  expandRow,
  rowStyle,
  cellEdit,
  handleSync,
  location,
  isViewAppointment,
}) => {
  const [myload, setMyload] = useState(false)
  const classes = useStyles()
  const { SearchBar } = Search
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} to {to} of {size} Results
    </span>
  )
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPage: sizePerPage,
    page: page,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    totalSize: totalSize,
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      // , {
      //   text: 'All', value: totalSize
      // }
    ], // A numeric array is also available. the purpose of above example is custom the text
  }

  const [show, setShow] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [counsellorList, setCounsellorList] = useState([])
  const [loaderCounsellor, setLoaderCounsellor] = useState([])
  const [blockedCalendarList, setBlockedCalendarList] = useState([])
  const [blockListModal, setBlockListModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteBlockId, setDeleteBlockId] = useState(null)

  const [getCounsellorList, getCounsellorListRes] = useLazyQuery(
    GET_COUNSELLORS,
    { fetchPolicy: "no-cache" }
  )
  const storedData = localStorage.getItem("orgId")
  const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null

  const [blockOffCalendar, blockOffCalendarRes] =
    useMutation(BLOCK_OFF_CALENDAR)

  const getCounsellorListFun = async search => {
    await getCounsellorList({
      variables: {
        argumentsInput: {
          page: 1,
          limit: 20,
          search: search || "",
          sort: "created_at",
          sort_dir: "DESC",
        },
      },
    })
  }
  const [getAllBlockedCalendarList, getAllBlockedCalendarListRes] =
    useLazyQuery(GET_ALL_BLOCKED_CALENDAR, {
      fetchPolicy: "no-cache",
    })
  useEffect(() => {
    if (
      getAllBlockedCalendarListRes?.data?.getAllBlockedCalendar?.data &&
      getAllBlockedCalendarListRes?.data?.getAllBlockedCalendar?.data?.overrides
    ) {
      const allBlockTime =
        getAllBlockedCalendarListRes?.data?.getAllBlockedCalendar?.data
          ?.overrides

      const groupedOverrides = allBlockTime.reduce((result, override) => {
        const therapistId = override.therapist_calendar?.id

        if (!result[therapistId]) {
          result[therapistId] = {
            therapist_calendar_name: override?.therapist_calendar?.name,
            overrides: [],
          }
        }

        const overrideDate = new Date(override.override_date)
        const startParts = override.start_time.split(":")
        const endParts = override.end_time.split(":")

        overrideDate.setHours(
          parseInt(startParts[0], 10),
          parseInt(startParts[1], 10)
        )

        const endDate = new Date(overrideDate)
        endDate.setHours(parseInt(endParts[0], 10), parseInt(endParts[1], 10))

        const formattedStartDate = overrideDate.toLocaleString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })

        const formattedEndDate = endDate.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })

        result[therapistId].overrides.push({
          id: override.id,
          date: `${formattedStartDate} - ${formattedEndDate}`,
        })

        return result
      }, {})
      const formattedData = Object.keys(groupedOverrides).map(therapistId => ({
        id: therapistId,
        therapist_calendar_name:
          groupedOverrides[therapistId].therapist_calendar_name,
        availabilityOverrides: groupedOverrides[therapistId].overrides,
      }))
      setBlockedCalendarList(formattedData)
    }
  }, [getAllBlockedCalendarListRes.data])

  const handleBlockedCalendarModal = () => {
    setSelectedOptions([])
    setSelectedDate(null)
    setSelectedEndDate(null)
    setStartTime(null)
    setEndTime(null)
    setBlockListModal(!blockListModal)
    setExpanded([])
  }

  function deleteToggle() {
    setModalDelete(!modalDelete)
    setDeleteBlockId(null)
  }

  const [DeleteBlockedTimes, DeleteBlockedTimesRes] = useMutation(
    DeleteBlockedTime,
    { fetchPolicy: "no-cache" }
  )

  const handleDeleteBlockTIme = () => {
    DeleteBlockedTimes({
      variables: {
        deleteBlockedTimeId: deleteBlockId,
      },
    })
    setDeleteBlockId(null)
  }

  useEffect(() => {
    if (
      DeleteBlockedTimesRes?.data?.deleteBlockedTime?.status &&
      DeleteBlockedTimesRes?.data?.deleteBlockedTime?.message
    ) {
      toast.success(DeleteBlockedTimesRes?.data?.deleteBlockedTime?.message)
      setModalDelete(false)
      getAllBlockedCalendarList()
    }
  }, [DeleteBlockedTimesRes.data])

  useEffect(() => {
    if (
      getCounsellorListRes.data &&
      getCounsellorListRes.data?.getCalendarLegend &&
      getCounsellorListRes.data?.getCalendarLegend?.data.calendars &&
      getCounsellorListRes.data?.getCalendarLegend?.data
    ) {
      const newCounsellorList = [
        { value: "", name: "Select calendar", label: "Select calendar" },
        ...getCounsellorListRes.data.getCalendarLegend.data.calendars.map(
          elem => ({
            value: elem.name,
            name: elem.name,
            id: elem.id,
            label: elem.name,
          })
        ),
      ]
      setCounsellorList(newCounsellorList)
    }
  }, [getCounsellorListRes.data])

  const [selectedOptions, setSelectedOptions] = useState([])

  const handleChangeCounsellors = val => {
    setSelectedOptions([val])
  }

  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [bookedAppointmentList, SetBookedAppointmentList] = useState([])
  const [bookedAppointmentMessage, SetBookedAppointmentMessage] = useState("")

  const handleclick = event => {
    setShow(!show)
  }

  const handleEModalToggle = () => {
    getCounsellorListFun("")
    setSelectedOptions([])
    SetBookedAppointmentMessage("")
    SetBookedAppointmentList([])
    setSelectedDate(null)
    setSelectedEndDate(null)
    setStartTime(null)
    setEndTime(null)
    setIsModalOpen(!isModalOpen)
    setTimeout(() => {
      setLoaderCounsellor(false)
    }, 3000)
  }
  const handleDateChange = (date, type) => {
    if (type === "endDate") {
      setSelectedEndDate(date)
    } else if (type === "startDate") {
      setSelectedDate(date)
    }
  }

  const validationCounsellor = () => {
    let isValid = ""
    let start_time = new Date(`1970-01-01T${startTime}`)
    let end_time = new Date(`1970-01-01T${endTime}`)
    if (start_time >= end_time) {
      isValid = false
    } else {
      isValid = true
    }

    const startDateTime = new Date(selectedDate).getTime()
    const endDateTime = new Date(selectedEndDate).getTime()

    if (startDateTime <= endDateTime) {
      isValid = true
    } else {
      isValid = false
    }

    if (isValid) {
      const createAvailability = selectedOptions.flatMap(option => {
        function getDates(startDate, endDate) {
          const dateArray = []
          let currentDate = new Date(startDate)

          while (currentDate <= new Date(endDate)) {
            dateArray.push(currentDate.toISOString().split("T")[0])
            currentDate.setDate(currentDate.getDate() + 1)
          }

          return dateArray
        }

        const datesBetween = getDates(selectedDate, selectedEndDate)

        let newOption = datesBetween.map(date => ({
          calendar_id: option.id,
          is_blocked: true,
          week_day: moment(date).format("dddd"),
          month: moment(date).format("MMMM"),
          start_time: startTime,
          end_time: endTime,
          override_date: date,
        }))
        return newOption
      })
      blockOffCalendar({
        variables: {
          createAvailabilityInput: {
            availability: createAvailability,
          },
        },
      }).then(res => {
        if (
          res?.data?.blockOffCalendar?.code === 200 &&
          res?.data?.blockOffCalendar?.status
        ) {
          toast.success(res?.data?.blockOffCalendar?.message)
          handleEModalToggle()
        } else if (
          res?.data?.blockOffCalendar?.code === 400 &&
          !res?.data?.blockOffCalendar?.status
        ) {
          toast.error(res?.data?.blockOffCalendar?.message)
          SetBookedAppointmentList(
            res?.data?.blockOffCalendar?.data?.block_off_slots
          )
          SetBookedAppointmentMessage(res?.data?.blockOffCalendar?.message)
        }
      })
    } else {
      toast.error("Please select the valid date & time")
    }
  }

  useEffect(() => {
    if (startTime && endTime && selectedDate && selectedOptions.length > 0) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [startTime, endTime, selectedDate, selectedOptions])

  const handleMultiTimeSlot = (e, time) => {
    if (time === "end_time") {
      setEndTime(e.target.value + ":00")
    } else if (time === "start_time") {
      setStartTime(e.target.value + ":00")
    }
  }

  const fetchOptions = async e => {
    if (e) {
      const filteredData = counsellorList.filter(item =>
        item.name.toLowerCase().includes(e.toLowerCase())
      )
      return filteredData
    } else {
      return counsellorList
    }
  }

  const pageOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
  }
  const tabColumns = [
    {
      dataField: "therapist_calendar_name",
      text: "Name",
    },
  ]

  const [expanded, setExpanded] = useState([])
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (expanded.length > 0) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [expanded])
  const handleRowExpansion = row => {
    if (expanded.includes(row.id)) {
      setExpanded(expanded.filter(id => id !== row.id))
    } else {
      setExpanded([...expanded, row.id])
    }
  }

  const expandRowTab = {
    renderer: row => (
      <div className={`slide-in ${isActive ? "active" : ""}`}>
        <ul>
          {row.availabilityOverrides.map((availability, index) => (
            <p key={index}>
              <a
                onClick={() => {
                  deleteToggle()
                  setDeleteBlockId(Number(availability.id))
                }}
                className="pe-2 text-danger delete-btn cursor-pointer"
              >
                <img src={deleteBin} alt="Delete" />
              </a>
              {availability.date}
            </p>
          ))}
        </ul>
      </div>
    ),
    showExpandColumn: true,
    expandColumnPosition: "right",
    onExpand: handleRowExpansion,
    expanded: expanded,
  }

  const history = useHistory()

  const openCreateUser = role => {
    if (role === "counsellor") {
      history.push(`/users/create?role=counsellor`)
    }
    if (role === "client") {
      history.push(`/users/create?role=client`)
    }
  }

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
        })}
      >
        {({ paginationTableProps }) => (
          <div>
            <ToolkitProvider
              keyField={keyField}
              columns={columns}
              data={data}
              loading={loading}
              search
              columnToggle
            >
              {toolkitprops => (
                <div className="">
                  <div className={`${classes.container} mb-4`}>
                    <div
                      className={classNames(
                        "d-flex gap-2",
                        classes.boxContainer
                      )}
                    >
                      {isSearch && !isViewAppointment && (
                        <MySearch {...toolkitprops.searchProps} />
                      )}
                      {/* {location === "/users" && !isViewAppointment && (
                        <BadfitModule />
                      )} */}
                      {isFilter && (
                        <div className="width-190">
                          <Select
                            className="input"
                            onChange={onSelectChange}
                            placeholder="Select client"
                            value={selectValue}
                            options={selectOptions}
                          />
                        </div>
                      )}
                      {isDisplayColumnList && (
                        <>
                          <div className={classNames("", classes.addBtn)}>
                            <Button
                              onClick={() => openCreateUser("counsellor")}
                              className="btn btn-primary d-flex align-items-center flex-wrap"
                            >
                              <div className="border border-wahite rounded-circle">
                                <i className="bx bx-plus-medical  p-1 font-size-14" />
                              </div>
                              <span className="mx-2">Add New Counsellor</span>
                            </Button>
                          </div>
                        </>
                      )}
                      {isDisplayUserAdd && (
                        <>
                          <div className={classNames("", classes.addBtn)}>
                            <Button
                              onClick={() => openCreateUser("client")}
                              className="btn btn-primary d-flex align-items-center flex-wrap"
                            >
                              <div className="border border-wahite rounded-circle">
                                <i className="bx bx-plus-medical  p-1 font-size-14" />
                              </div>
                              <span className="mx-2">Add New Client</span>
                            </Button>
                          </div>
                        </>
                      )}
                      {extraTableToolKits && (
                        <ExtraTableToolKits
                          extraTableToolKits={extraTableToolKits}
                        />
                      )}
                    </div>
                  </div>
                  {isDisplayColumnList && (
                    <>
                      <div
                        className={classNames(
                          "d-flex w-100 justify-content-start mb-3",
                          classes.boxContainer
                        )}
                      >
                        <div
                          className={`${classes.width} justify-content-center-mobile-only`}
                        >
                          {orgId === 1 && (
                            <Button
                              className={`${classes.btnHeight} btn btn-primary mr-3`}
                              onClick={handleSync}
                            >
                              Sync With Acuity
                            </Button>
                          )}
                          <div className="text-left">
                            <Button
                              className={`${classes.btnHeight} btn btn-primary mr-3`}
                              onClick={() => {
                                handleEModalToggle()
                                setLoaderCounsellor(true)
                              }}
                            >
                              Block off the Counsellor
                            </Button>
                            <Button
                              className="btn btn-primary margin-top-10px-xs mx-2 "
                              onClick={() => {
                                getAllBlockedCalendarList()
                                handleBlockedCalendarModal()
                              }}
                            >
                              <span>View blocked Counsellor</span>
                            </Button>
                          </div>
                          <Dropdown
                            isOpen={show}
                            toggle={event => handleclick(event)}
                            className="ml-2"
                          >
                            <DropdownToggle
                              tag="button"
                              className={`${classes.btnHeight} btn btn-primary`}
                              caret
                            >
                              <i className="uil-setting"></i> View More
                            </DropdownToggle>
                            <DropdownMenu className="pl-2">
                              {show && (
                                <CustomToggleList
                                  {...toolkitprops.columnToggleProps}
                                  onColumnToggle={myColumnToggle}
                                />
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </>
                  )}
                  {loading && <Spinner className="data-table-spinner" />}
                  <div className={loading ? "data-table-wrap" : ""}>
                    <BootstrapTable
                      striped={isStriped}
                      hover={isHover}
                      noDataIndication={noDataIndication}
                      // loading={loading}
                      // overlay={overlayFactory()}
                      expandRow={expandRow}
                      remote
                      keyField={keyField}
                      onTableChange={onTableChange}
                      {...paginationTableProps}
                      {...toolkitprops.baseProps}
                      {...(isPagination && {
                        pagination: paginationFactory(options),
                      })}
                      bordered={isBordereded}
                      wrapperClasses="table-responsive tableredesigned"
                      cellEdit={cellEdit}
                      rowStyle={rowStyle}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        )}
      </PaginationProvider>

      <BlockOftimeModal
        isModalOpen={isModalOpen}
        handleEModalToggle={handleEModalToggle}
        handleDateChange={handleDateChange}
        validationCounsellor={validationCounsellor}
        handleMultiTimeSlot={handleMultiTimeSlot}
        startTime={startTime}
        fetchOptions={fetchOptions}
        endTime={endTime}
        isVisible={isVisible}
        bookedAppointmentList={bookedAppointmentList}
        bookedAppointmentMessage={bookedAppointmentMessage}
        selectedDate={selectedDate}
        selectedEndDate={selectedEndDate}
        counsellorList={counsellorList}
        handleChangeCounsellors={handleChangeCounsellors}
        selectedOptions={selectedOptions}
      />
      <Modal isOpen={blockListModal} size="lg">
        <ModalBody>
          <div className="w-100 text-end">
            <Button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleBlockedCalendarModal()}
            ></Button>
          </div>
          <>
            {blockedCalendarList.length > 0 ? (
              <>
                <h3 className="my-2 ">Blocked off Counsellor</h3>

                <ToolkitProvider
                  keyField="id"
                  data={blockedCalendarList}
                  columns={tabColumns}
                  search
                >
                  {props => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        striped
                        hover
                        condensed
                        keyField="id"
                        data={blockedCalendarList}
                        columns={tabColumns}
                        expandRow={expandRowTab}
                        rowClasses="cursor-pointer "
                        className="table table-hover "
                        pagination={paginationFactory(pageOptions)}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </>
            ) : (
              <h3 className="mx-3 my-5">No data found</h3>
            )}
          </>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          deleteToggle()
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                <h3>Are you sure?</h3>
                <h5>{"You won't be able to revert this!"}</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    deleteToggle()
                  }}
                  className="btn btn-inactive waves-effect m-2"
                  data-dismiss="modal"
                >
                  No
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => handleDeleteBlockTIme()}
                >
                  Yes, Delete it
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default RemotePagination

const MySearch = props => {
  let input
  const handleClick = event => {
    const { value } = event.target
    props.onSearch(value)
  }

  return (
    <label className="width-100-mobile-devices ">
      <div className="form-control searchComponent p-0 px-2 py-1 ">
        <input
          className="form-control border-0 p-1 default-search-input "
          ref={n => (input = n)}
          onChange={handleClick}
          type="text"
          placeholder="Search"
        />
        <span className="mdi mdi-magnify me-2 searchIcon"></span>
      </div>
    </label>
  )
}

const BadfitModule = () => {
  const [recentCancellation, setRecentCancellation] = useState(false)
  const [minSessionsNoFollowUp, setMinSessionsNoFollowUp] = useState(false)
  const [repeatLateCancellation, setRepeatLateCancellation] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const selectedBadFitModule = new URLSearchParams(location.search).get(
    "badFit"
  )

  const handleClick = setter => {
    if (setter === "recent_cancellation") {
      setRecentCancellation(!recentCancellation)
      setMinSessionsNoFollowUp(false)
      setRepeatLateCancellation(false)
      if (!recentCancellation) {
        history.push(`/users?badFit=recent_cancellation`)
        localStorage.setItem("batFitSelect", "recent_cancellation")
      } else {
        history.push(`/users`)
        localStorage.removeItem("batFitSelect")
      }
    } else if (setter === "less_than_six_sessions") {
      setRecentCancellation(false)
      setMinSessionsNoFollowUp(!minSessionsNoFollowUp)
      setRepeatLateCancellation(false)
      if (!minSessionsNoFollowUp) {
        history.push(`/users?badFit=less_than_six_sessions`)
        localStorage.setItem("batFitSelect", "less_than_six_sessions")
      } else {
        history.push(`/users`)
        localStorage.removeItem("batFitSelect")
      }
    } else if (setter === "consecutive_cancellation") {
      setRecentCancellation(false)
      setMinSessionsNoFollowUp(false)
      setRepeatLateCancellation(!repeatLateCancellation)
      if (!repeatLateCancellation) {
        history.push(`/users?badFit=consecutive_cancellation`)
        localStorage.setItem("batFitSelect", "consecutive_cancellation")
      } else {
        history.push(`/users`)
        localStorage.removeItem("batFitSelect")
      }
    }
  }

  return (
    <div className="text-right">
      <Button
        className={`btn badFirBtn  mx-1 ${
          recentCancellation || selectedBadFitModule === "recent_cancellation"
            ? "btn-primary"
            : "font-weight-bold border border-dark"
        }`}
        title="Canceled appointment within the last 30 days and no appointment for 2 weeks thereafter"
        onClick={() => handleClick("recent_cancellation")}
      >
        Recent Cancellation
      </Button>
      <Button
        className={`btn badFirBtn  mx-1 ${
          minSessionsNoFollowUp ||
          selectedBadFitModule === "less_than_six_sessions"
            ? "btn-primary"
            : " font-weight-bold border border-dark"
        }`}
        title="Less then 6 appointments & no appointment booked in last month"
        onClick={() => handleClick("less_than_six_sessions")}
      >
        <span>Min. Sessions no Follow up</span>
      </Button>
      <Button
        className={`btn badFirBtn  mx-1 ${
          repeatLateCancellation ||
          selectedBadFitModule === "consecutive_cancellation"
            ? "btn-primary"
            : " font-weight-bold border border-dark"
        }`}
        title="Any two appointments have been cancelled consecutively"
        onClick={() => handleClick("consecutive_cancellation")}
      >
        <span>Repeat Late Cancellation</span>
      </Button>
    </div>
  )
}

const ExtraTableToolKits = ({ extraTableToolKits }) => {
  const history = useHistory()
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    const search = new URLSearchParams(history.location.search)
    const startDate = search.get("startDate")
    const endDate = search.get("endDate")
    if (endDate && startDate) {
      if (endDate == "undefined" && startDate == "undefined") {
        setstartDate("")
        setEndDate("")
      } else {
        setstartDate(new Date(startDate))
        setEndDate(new Date(endDate))
      }
    }
  }, [])

  const submitDateFilter = () => {
    extraTableToolKits({
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    })
  }

  return (
    <>
      <div className="d-flex gap-2">
        <DatePicker
          selected={startDate}
          className="form-control rounded pointer"
          placeholderText="DD-MM-YYYY"
          onChange={value => setstartDate(value)}
          dateFormat="dd-MMM-yyyy"
        />
        <DatePicker
          selected={endDate}
          className="form-control rounded ml-5 pointer"
          placeholderText="DD-MM-YYYY"
          onChange={value => setEndDate(value)}
          dateFormat="dd-MMM-yyyy"
          minDate={startDate}
        />
      </div>
      <Button
        type="button"
        className="btn btn-primary height-38"
        disabled={!startDate || !endDate}
        onClick={submitDateFilter}
      >
        Apply
      </Button>
      <Button
        type="button"
        disabled={!startDate && !endDate}
        className="h38-w64-btn"
        onClick={() => {
          setstartDate("")
          setEndDate("")
          extraTableToolKits({ startDate: undefined, endDate: undefined })
        }}
      >
        <i className="mdi mdi-close close-btn-icon-table "></i>
      </Button>
    </>
  )
}
