import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import moment from "moment"
import Table from "../../components/Common/Table"
import useStyles from "../Dashboard/styles"
import { fetchApi } from "../../common/api"
import { useHistory } from "react-router-dom"
import style from "./style.module.scss"
import AsyncSelect from "react-select/async"
import { debounce } from "lodash"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { formatToCommonDate, hasPermission } from "../../functions/functions"
import { useLazyQuery, useMutation } from "@apollo/client"
import paginationFactory from "react-bootstrap-table2-paginator"
import {
  DEL_LOCATION,
  GET_ALL_ROOMS,
  BLOCK_OFF_ROOMS,
  GET_ALL_BLOCKED_ROOMS,
  DELETE_BLOCK_TIME,
} from "./gql/Gql"
import { toast } from "react-toastify"
import DeleteModal from "../../components/Common/DeleteModal"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import edit from "../../assets/images/dashboard-icons/edit.svg"
import deleteBin from "../../assets/images/dashboard-icons/deleteBin.svg"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import PageNotFound from "../404"
const Location = () => {
  const classes = useStyles()
  const { SearchBar } = Search
  let history = useHistory()
  let [state, setState] = useState({
    page: 1,
    sizePerPage: 20,
    totalCount: 0,
  })
  const [searchText, setSearchText] = useState("")
  let [list, setList] = useState([])
  let [isSpinner, setIsSpinner] = useState(true)
  const [inProgress, setInProgress] = useState(false)
  const [deleteModalId, setDeleteModalId] = useState(-1)
  const [submitMutation, submitResponse] = useMutation(DEL_LOCATION)
  const [blockOffRoom, blockOffRoomRes] = useMutation(BLOCK_OFF_ROOMS)
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteBlockId, setDeleteBlockId] = useState(null)
  const [blockedTimeModal, setBlockedTimeModal] = useState(false)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [roomList, setRoomList] = useState([])
  const [blockedRoomList, setBlockedRoomList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getLocationsApi = async (limit, page, search) => {
    try {
      let { data, code, status } = await fetchApi(
        `locationGroup/locations?is_all=tru&limit=${limit}&page=${page}&search=${search}`,
        "GET"
      )
      if (code == 200 && status && data) {
        const { limit, page, totalCount } = data
        setState({
          page: Number(page),
          sizePerPage: Number(limit),
          totalCount,
        })
        const locationGroup = data?.locationGroup?.map((data, i) => ({
          ...data,
          index: ((page || 1) - 1) * (limit || 1) + i + 1,
        }))
        setList(locationGroup)
        setIsSpinner(false)
      } else {
        setIsSpinner(false)
      }
    } catch (error) {
      console.log(error)
      setIsSpinner(false)
    }
  }
  useEffect(() => {
    const { sizePerPage, page } = state
    getLocationsApi(sizePerPage, page, searchText)
  }, [])
  const [columns, setColumns] = useState([
    {
      text: "ID",
      dataField: "id",
      keyField: "id",
      hidden: true,
    },
    { text: "#", dataField: "index" },
    {
      text: "Name",
      dataField: "name",
      style: {
        maxWidth: 200,
      },
    },
    {
      text: "Slug",
      dataField: "slug",
      formatter: (cell, row) => row?.slug ?? "-",
    },
    {
      text: "Address",
      dataField: "address",
      style: {
        minWidth: 100,
        maxWidth: 200,
      },
    },

    // {
    //   text: "Latitude",
    //   dataField: "latitude",
    //   style: {
    //     minWidth: 50
    //   },
    // },
    // {
    //   text: "Longitude",
    //   dataField: "longitude",
    //   style: {
    //     minWidth: 50
    //   },
    // },

    {
      text: "Status",
      dataField: "status",

      formatter: (cell, row) =>
        cell && cell == "active" ? (
          <span className="rounded-pill bg-info badge bg-secondary">
            Published
          </span>
        ) : (
          <span className="rounded-pill bg-danger badge bg-secondary">
            Unpublished
          </span>
        ),
    },
    {
      text: "Modified",
      dataField: "updated_at",
      width: 270,
      formatter: (cell, row) =>
        cell && formatToCommonDate(cell, "DD-MMM-YYYY HH:mm"),
    },

    {
      text: "Action",
      dataField: "action",
      style: {
        minWidth: 150,
      },
      formatter: (cell, row) => {
        const encodedId = btoa(row.id);
       return (<div>
          <Button
            className="btn btn-primary cursor-pointer"
            onClick={() => history.push(`/location/${encodedId}`)}
          >
            <img src={edit} />
          </Button>
          <a
            onClick={() => setDeleteModalId(Number(row.id))}
            className="px-3 text-danger delete-btn cursor-pointer"
          >
            <img src={deleteBin} />
          </a>
        </div>
      )},
    },
    // {
    //   text: "Directions",
    //   dataField: "google_maps_url",
    //   formatter:(cell,row)=>{
    //     return cell && <button
    //                 className={`btn btn-primary btn-rounded waves-effect waves-light ${style.typebutton}`}
    //                 onClick={()=>handleDirection(cell)}
    //             >
    //                 <span className={style.joinBtnText}>Direction</span>
    //             </button>
    //   }
    // },
  ])

  const delaySearch = useCallback(
    debounce(search => {
      getLocationsApi(state?.sizePerPage, 1, search)
    }, 2000),
    []
  )

  function deleteToggle() {
    setModalDelete(!modalDelete)
    setDeleteBlockId(null)
  }

  const [DeleteBlockedTimes, DeleteBlockedTimesRes] = useMutation(
    DELETE_BLOCK_TIME,
    { fetchPolicy: "no-cache" }
  )

  const handleDeleteBlockTIme = () => {
    DeleteBlockedTimes({
      variables: {
        deleteBlockedTimeRoomId: deleteBlockId,
      },
    })
    setDeleteBlockId(null)
  }

  useEffect(() => {
    if (
      DeleteBlockedTimesRes?.data?.deleteBlockedTimeRoom?.status &&
      DeleteBlockedTimesRes?.data?.deleteBlockedTimeRoom?.message
    ) {
      toast.success(DeleteBlockedTimesRes?.data?.deleteBlockedTimeRoom?.message)
      getAllBlockedRoomsList()
      setModalDelete(!modalDelete)
    }
  }, [DeleteBlockedTimesRes.data])

  const handleSearchText = search => {
    setIsSpinner(true)
    setSearchText(search)
    delaySearch(search)
  }

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setIsSpinner(true)
    getLocationsApi(sizePerPage, page, searchText)
    // getAppoitmentType({
    //   variables: {
    //     argumentsInput: {
    //       limit: sizePerPage,
    //       page: page,
    //     },
    //   },
    // })
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  const handleDelete = async id => {
    try {
      setInProgress(true)
      await submitMutation({
        variables: {
          getLocationInput: { id },
        },
      })
      const { sizePerPage, page } = state
      await getLocationsApi(sizePerPage, page, searchText)
      setTimeout(() => {
        toast.success("Record deleted successfully")
      }, 1500)
    } catch (error) {
      console.log(error)
      toast.error("something went wrong !")
    } finally {
      setInProgress(false)
      setDeleteModalId(-1)
    }
  }

  const handleEModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleBlockedTimeModal = () => {
    setSelectedOptions([])
    setSelectedDate(null)
    setSelectedEndDate(null)
    setStartTime(null)
    setEndTime(null)
    setBlockedTimeModal(!blockedTimeModal)
  }

  const [getAllRoomsList, getAllRoomsListRes] = useLazyQuery(GET_ALL_ROOMS, {
    fetchPolicy: "no-cache",
  })

  const [getAllBlockedRoomsList, getAllBlockedRoomsListRes] = useLazyQuery(
    GET_ALL_BLOCKED_ROOMS,
    {
      fetchPolicy: "no-cache",
    }
  )

  useEffect(() => {
    getAllRoomsList()
    getAllBlockedRoomsList()
  }, [])

  useEffect(() => {
    if (
      getAllBlockedRoomsListRes?.data?.getAllBlockedRooms?.status &&
      getAllBlockedRoomsListRes?.data?.getAllBlockedRooms?.data?.blockedRooms
    ) {
      const formattedData =
        getAllBlockedRoomsListRes?.data?.getAllBlockedRooms?.data?.blockedRooms.reduce(
          (acc, room) => {
            const locationName = room.room.location.name

            let locationEntry = acc.find(
              entry => entry.locationName === locationName
            )

            if (!locationEntry) {
              locationEntry = { locationName, rooms: [] }
              acc.push(locationEntry)
            }

            locationEntry.rooms.push({
              start_time: room.start_time,
              end_time: room.end_time,
              override_date: room.override_date,
              is_blocked: room.is_blocked,
              roomName: room.room.name,
              id: room.id,
            })

            return acc
          },
          []
        )
      const formattedOverrides = formattedData?.map((location, ind) => {
        const locationName = location.locationName
        const roomData = location.rooms.map(room => {
          const overrideDate = new Date(room.override_date)
          const startParts = room.start_time.split(":")
          const endParts = room.end_time.split(":")

          overrideDate.setHours(
            parseInt(startParts[0], 10),
            parseInt(startParts[1], 10)
          )

          const endDate = new Date(overrideDate)
          endDate.setHours(parseInt(endParts[0], 10), parseInt(endParts[1], 10))

          const formattedStartDate = overrideDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })

          const formattedEndDate = endDate.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })

          return {
            room: room.roomName,
            date: `${formattedStartDate} - ${formattedEndDate}`,
            id: room.id,
          }
        })

        return {
          id:ind,
          location: locationName,
          rooms: roomData,
        }
      })

      setBlockedRoomList(formattedOverrides)
    }
  }, [getAllBlockedRoomsListRes.data])

  const pageOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    ),
  }
  const tabColumns = [
       {
      dataField: "location",
      text: "Location",
    },
  ]

  const [expanded, setExpanded] = useState([])
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (expanded.length > 0) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [expanded])
  const handleRowExpansion = row => {
    if (expanded.includes(row.id)) {
      setExpanded(expanded.filter(id => id !== row.id))
    } else {
      setExpanded([...expanded,row.id])
    }
  }

  const expandRowTab = {
    renderer: row => (
      <div className={`slide-in ${isActive ? 'active' : ''}`}>
        <ul>
          {row.rooms.map((room, index) => (
            <p key={index}>
              <a
                onClick={() => {
                  deleteToggle()
                  setDeleteBlockId(Number(room.id))
                }}
                className="pe-2 text-danger delete-btn cursor-pointer"
              >
                <img src={deleteBin} alt="Delete" />
              </a>
              {`${room.room} -> ${room.date}`}
            </p>
          ))}
        </ul>
      </div>
    ),
    showExpandColumn: true,
    expandColumnPosition: "right",
    onExpand: handleRowExpansion,
    expanded: expanded,
  }


  useEffect(() => {
    if (
      getAllRoomsListRes?.data?.getAllRooms?.data &&
      getAllRoomsListRes?.data?.getAllRooms?.data?.rooms
    ) {
      const newRoomList = [
        ...getAllRoomsListRes.data.getAllRooms.data.rooms.map(elem => ({
          value: elem.name,
          name: elem.name,
          id: elem.id,
          label: `${elem.location.name} > ${elem.name}`,
        })),
      ]
      setRoomList(newRoomList)
    }
  }, [getAllRoomsListRes.data])

  const handleDateChange = (date, type) => {
    if (type === "endDate") {
      setSelectedEndDate(date)
    } else if (type === "startDate") {
      setSelectedDate(date)
    }
  }

  const validationRooms = () => {
    let isValid = ""
    let start_time = new Date(`1970-01-01T${startTime}`)
    let end_time = new Date(`1970-01-01T${endTime}`)
    if (start_time >= end_time) {
      isValid = false
    } else {
      isValid = true
    }

    const startDateTime = new Date(selectedDate).getTime()
    const endDateTime = new Date(selectedEndDate).getTime()

    if (startDateTime <= endDateTime) {
      isValid = true
    } else {
      isValid = false
    }

    if (isValid) {
      const createAvailability = selectedOptions.flatMap(option => {
        function getDates(startDate, endDate) {
          const dateArray = []
          let currentDate = new Date(startDate)

          while (currentDate <= new Date(endDate)) {
            dateArray.push(currentDate.toISOString().split("T")[0])
            currentDate.setDate(currentDate.getDate() + 1)
          }

          return dateArray
        }

        const datesBetween = getDates(selectedDate, selectedEndDate)

        let newOption = datesBetween.map(date => ({
          room_id: option.id,
          is_blocked: true,
          week_day: moment(date).format("dddd"),
          month: moment(date).format("MMMM"),
          start_time: startTime,
          end_time: endTime,
          override_date: date,
        }))
        return newOption
      })
      blockOffRoom({
        variables: {
          createAvailabilityInput: {
            availability: createAvailability,
          },
        },
      }).then(() => {
        toast.success("Block off time details updated successfully")
        handleEModalToggle()
      })
    } else {
      toast.error("Please select the valid date & time")
    }
  }
  const handleMultiTimeSlot = (e, time) => {
    if (time === "end_time") {
      setEndTime(e.target.value + ":00")
    } else if (time === "start_time") {
      setStartTime(e.target.value + ":00")
    }
  }

  const fetchOptions = async e => {
    if (e) {
      const filteredData = roomList.filter(item =>
        item.label.toLowerCase().includes(e.toLowerCase())
      )
      return filteredData
    } else {
      // If the input is empty, show the initial data
      return roomList
    }
  }

  const [selectedOptions, setSelectedOptions] = useState([])
  const handleChangeRooms = useCallback(selected => {
    setSelectedOptions(selected)
  }, [])

  useEffect(() => {
    if (startTime && endTime && selectedDate && selectedOptions.length > 0) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [startTime, endTime, selectedDate, selectedOptions])

  return (
    <div className="page-content">
          {hasPermission() ? (
      <div className="container-fluid">
        <div>
          <div className="py-3">
            <h4 className="card-title-main">Locations</h4>
            <h5 className="card-para-main">List of all the locations.</h5>
          </div>
        </div>

        <Row className="mg-top-default-ui">
          <Col>
            <Card className="light-green-bg-card-table">
              <CardBody>
                <Row className="d-flex align-item-center justify-content-between mb-2 mt-4">
                  <Col
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    className="align-self-center"
                  >
                    <Input
                      value={searchText}
                      onChange={e => handleSearchText(e.target.value)}
                      placeholder="Search"
                      className="default-search-input"
                    />
                  </Col>
                  <Col sm={12} md={9} lg={9} xl={9} className="text-end">
                    <Button
                      className="btn btn-primary margin-top-10px-xs "
                      onClick={() => {
                        handleEModalToggle()
                      }}
                    >
                      <span>Block off the Room</span>
                    </Button>
                    <Button
                      className="btn btn-primary margin-top-10px-xs mx-3 "
                      onClick={() => {
                        getAllBlockedRoomsList()
                        handleBlockedTimeModal()
                      }}
                    >
                      <span>View blocked rooms</span>
                    </Button>
                    <Button
                      className="btn btn-primary margin-top-10px-xs "
                      onClick={() => {
                        history.push(`/location/add`)
                      }}
                    >
                      <span>
                        <i className="mdi mdi-plus" />
                        Add Location
                      </span>
                    </Button>
                  </Col>
                </Row>
                <div className="table-custom-redesign">
                  <Table
                    data={list}
                    page={state?.page}
                    sizePerPage={state?.sizePerPage}
                    totalSize={state?.totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    keyField={"id"}
                    isHover={false}
                    isStriped={false}
                    isBordereded={false}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <DeleteModal
          show={deleteModalId !== -1}
          onDeleteClick={() => {
            handleDelete(deleteModalId)
          }}
          onCloseClick={() => {
            setDeleteModalId(-1)
          }}
          inProgress={inProgress}
        />
        <Modal
          isOpen={isModalOpen}
          toggle={handleEModalToggle}
          size="lg"
          backdrop="static"
        >
          <ModalBody>
            <div className="w-100 text-end">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h4> Block off time</h4>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleEModalToggle}
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between w-75 mt-3">
              <p className="defaultTexts">
                Taking some time off? Block off time on your room to prevent
                clients from booking appointments (existing appointments will
                remain on your calendar).
              </p>
            </div>

            <div>Block Off time</div>

            <Row>
              <Col md="5 mt-3">
                <div className="mb-2 form-custom-white-inputs">
                  <Label className="form-label">Select rooms </Label>
                  <AsyncSelect
                    className={`custom-date-picker-style-bradius`}
                    isMulti
                    name="selectrooms"
                    placeholder="Select rooms"
                    defaultOptions={roomList}
                    loadOptions={fetchOptions}
                    onChange={handleChangeRooms}
                    value={selectedOptions}
                    formatGroupLabel={data =>
                      formatGroupLabel(data, validation)
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: "8px",
                        minHeight: "41px",
                        border: "1px solid #ced4da",
                      }),
                    }}
                    closeMenuOnSelect={false}
                  />
                </div>
              </Col>
            </Row>

            <div className="row">
              <div className="col-md-12">
                <div className="mb-3 form-custom-white-inputs ">
                  <div className="row my-3">
                    <div className="col-12 col-md-5">
                    <Label for="datePicker">From Date:</Label>
                      <Input
                        type="date"
                        id="datePicker"
                        className="form-control default-search-input d-flex justify-content-between"
                        value={selectedDate}
                        onChange={e =>
                          handleDateChange(e.target.value, "startDate")
                        }
                        min={new Date().toISOString().split("T")[0]} // Disable past dates
                      />
                    </div>
                 
                    <div className="col-12 col-md-5">
                    <Label for="datePicker">To Date:</Label>
                      <Input
                        type="date"
                        className="form-control default-search-input d-flex justify-content-between"
                        id="datePicker"
                        value={selectedEndDate}
                        onChange={e =>
                          handleDateChange(e.target.value, "endDate")
                        }
                        min={new Date().toISOString().split("T")[0]} // Disable past dates
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">

              <div className="col-12 col-md-5">
              <Label className="font-weight-bold defaultText">From Time:</Label>
                <Input
                  className="form-control default-search-input w-100 w-md-50"
                  name={`blocktime_start_time`}
                  placeholder="From"
                  type="time"
                  onChange={e => handleMultiTimeSlot(e, "start_time")}
                  value={startTime}
                />
              </div>

              <div className="col-12 col-md-5">
              <Label className="font-weight-bold defaultText">To Time:</Label>
                <Input
                  className="form-control default-search-input w-100 w-md-50"
                  name={`blocktime_end_time`}
                  placeholder="To"
                  type="time"
                  onChange={e => handleMultiTimeSlot(e, "end_time")}
                  value={endTime}
                />
              </div>
            </div>

            <Row>
              <Col md={12} className="mt-2 text-left">
                <Button
                  type="button"
                  className="btn btn-primary"
                  disabled={!isVisible}
                  onClick={validationRooms}
                >
                  Save
                </Button>
                <Button
              type="button"
              className="btn btn-inactive mx-3"
              onClick={handleEModalToggle}
            >
              Cancel
            </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modalDelete}
          toggle={() => {
            deleteToggle()
          }}
          centered={true}
        >
          <div className="modal-body">
            <Row>
              <Col className="col-12">
                <div className="d-flex align-items-center flex-column">
                  <i className="mdi mdi-alert-circle-outline delete-modal-i-style" />
                  <h3>Are you sure?</h3>
                  <h5>{"You won't be able to revert this!"}</h5>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <Button
                    type="button"
                    onClick={() => {
                      deleteToggle()
                    }}
                    className="btn btn-inactive waves-effect m-2"
                    data-dismiss="modal"
                  >
                    No
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => handleDeleteBlockTIme()}
                  >
                    Yes, Delete it
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal isOpen={blockedTimeModal} size="lg">
          <ModalBody>
            <div className="w-100 text-end">
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => handleBlockedTimeModal()}
              ></button>
            </div>
            <>
              {blockedRoomList.length > 0 ? (
                <>
                  {" "}
                  <h3 className="my-2 mx-4">Blocked off rooms</h3>
                  <ToolkitProvider
                keyField="id"
                data={blockedRoomList}
                columns={tabColumns}
                search
              >
                {props => (
                  <div>
                    <SearchBar {...props.searchProps} />
                    <BootstrapTable
                      {...props.baseProps}
                      striped
                      hover
                      condensed
                      keyField="id"
                      data={blockedRoomList}
                      columns={tabColumns}
                      expandRow={expandRowTab}
                      rowClasses="cursor-pointer"
                        className="table table-hover"
                      pagination={paginationFactory(pageOptions)}
                    />
                  </div>
                )}
              </ToolkitProvider>
                </>
              ) : (
                <h3 className="mx-3 my-5">No data found</h3>
              )}
            </>
          </ModalBody>
        </Modal>
      </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  )
}
export default Location
