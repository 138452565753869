import React, { useEffect, useState } from "react"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  CardSubtitle,
  BreadcrumbItem,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Tooltip from "@material-ui/core/Tooltip"

import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { CREATE_EDIT_USER_ROLE, GET_SINGLE_ROLE } from "./gql/userGql"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useParams, Link, useHistory } from "react-router-dom"
import { scrollTop } from "../../functions/functions"
import { FocusError } from "focus-formik-error"
import style from "./style.module.scss"
import SweetAlert from "react-bootstrap-sweetalert"
import Permission from "./component/Permission"
import Loader from "../../components/Common/Loader"

const UserRoleCreate = props => {
  const { id } = useParams()
  const history = useHistory()
  const [modal_center, setmodal_center] = useState(false)
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [isSpinner, setIsSpinner] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: null,
    role_name: "",
    description: "",
  })

  const [getOldData, oldData] = useLazyQuery(GET_SINGLE_ROLE, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (id) {
      getOldData({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [id])

  useEffect(() => {
    if (oldData?.data && oldData.data.getRole?.code === 200) {
      const fillerData = oldData.data.getRole.data?.role
      let result = {
        role_name: fillerData.role_name,
        description: fillerData.description,
        is_active: fillerData.is_active,
      }
      setIsSpinner(false)
      setSelectedPermissions(oldData.data.getRole.data?.role?.permission ?? [])
      setInitialValues(result)
    } else {
      if (id) {
        setIsSpinner(true)
      }
    }
  }, [oldData])

  function tog_center() {
    setmodal_center(!modal_center)
  }

  const handleSubmit = async values => {
    let payload = {
      role_name: values.role_name,
      description: values.description,
      methods: [],
      is_active: values.is_active ? true : false,
    }
    if (id) payload.id = parseInt(id)
    payload.methods = selectedPermissions
    setLoading(true)
    submitMutation({ variables: { roleInput: payload } })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      role_name: Yup.string()
        .matches(
          /^[A-Z][a-zA-Z0-9\s]*$/,
          "Please enter user group name with the first letter capitalized"
        )
        .required("Please enter user group"),
      description: Yup.string().required(
        "Please describe user group description"
      ),
    }),
    onSubmit: handleSubmit,
  })

  const [submitMutation, submitResponse] = useMutation(CREATE_EDIT_USER_ROLE)
  const [loading, setLoading] = useState(false)
  React.useEffect(() => {
    if (submitResponse.data && submitResponse.data.createEditRole.status) {
      toast.success(
        id
          ? "User groups detail is updated successfully"
          : "User groups detail is created successfully"
      )
      setTimeout(() => {
        props.history.push(`/user-groups`)
      }, 2000)
    } else {
      if (submitResponse.data && submitResponse.data.createEditRole) {
        toast.error(submitResponse.data.createEditRole.message)
      }
    }
  }, [submitResponse.data])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    {id ? "Edit User Group" : "Add User Group"}
                  </h4>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/user-groups">User Groups</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">
                        {id ? "Edit User Group" : "Add User Group"}
                      </Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {id && isSpinner ? (
            <Row>
              <Col className="col-12 user-role-create-height">
                <div className="spinner">
                  <Loader />
                </div>
              </Col>
            </Row>
          ) : !isSpinner ? (
            <Form
              className="needs-validation"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <FocusError formik={validation} />
              <Row className={style.cardCenter}>
                <Col lg={2}></Col>
                <Col lg={10}>
                  <Row>
                    <Col xl="10">
                      <Card className="transparent-bg-card">
                        <CardBody>
                          <h4 className="card-title-main mb-4">
                            User Group Details
                          </h4>
                          <Row className="form-custom-white-inputs">
                            <Col md="12">
                              <div className="mb-3">
                                <Label className="form-label">
                                  User Group Name
                                  <Tooltip
                                    className={style.tooltip}
                                    placement="right"
                                    title="Name of new user group being created."
                                    arrow
                                  >
                                    <i
                                      className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                    ></i>
                                  </Tooltip>
                                </Label>
                                <Input
                                  id="name"
                                  name="role_name"
                                  className="form-control"
                                  placeholder="Enter user group name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.role_name || ""}
                                  invalid={
                                    validation.touched.role_name &&
                                    validation.errors.role_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.role_name &&
                                validation.errors.role_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.role_name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <div className="mb-3">
                              <Label>
                                User Group Description
                                <Tooltip
                                  className={style.tooltip}
                                  placement="right"
                                  title="Displayed in the user group list for reference as a note."
                                  arrow
                                >
                                  <i
                                    className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                  ></i>
                                </Tooltip>
                              </Label>

                              <textarea
                                type="textarea"
                                id="name"
                                name="description"
                                maxLength="225"
                                placeholder="Enter user group description"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                              />

                              {validation.touched.description &&
                              validation.errors.description ? (
                                <div className="error">
                                  {validation.errors.description}
                                </div>
                              ) : null}
                            </div>
                            <Col md="12">
                              <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <div className="mb-3 form-check form-switch form-switch-lg">
                                  <input
                                    name="is_active"
                                    type="checkbox"
                                    className="form-check-input"
                                    onBlur={validation.onBlur}
                                    onChange={validation.handleChange}
                                    value={validation.values.is_active}
                                    defaultChecked={validation.values.is_active}
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col md="12" className="">
                              <div className="mb-3">
                                <Label className="form-label">
                                  Permissions
                                  <Tooltip
                                    className={style.tooltip}
                                    placement="right"
                                    title="Assign rights to perform a specific action on
                                  the admin panel by this group"
                                    arrow
                                  >
                                    <i
                                      className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                    ></i>
                                  </Tooltip>
                                </Label>
                              </div>
                            </Col>
                            <Row className="light-green-bg-card p-4">
                              <Col md="2">
                                <Permission
                                  name="user"
                                  title="Users"
                                  selectedPermissions={selectedPermissions}
                                  setSelectedPermissions={
                                    setSelectedPermissions
                                  }
                                />
                              </Col>
                              <Col md="2">
                                <Permission
                                  name="client"
                                  title="Clients"
                                  selectedPermissions={selectedPermissions}
                                  setSelectedPermissions={
                                    setSelectedPermissions
                                  }
                                />
                              </Col>
                              <Col md="2">
                                <Permission
                                  name="appointment"
                                  title="Appointments"
                                  selectedPermissions={selectedPermissions}
                                  setSelectedPermissions={
                                    setSelectedPermissions
                                  }
                                />
                              </Col>
                              <Col md="2">
                                <Permission
                                  name="counselor"
                                  title="Counselors"
                                  selectedPermissions={selectedPermissions}
                                  setSelectedPermissions={
                                    setSelectedPermissions
                                  }
                                />
                              </Col>
                              <Col md="2">
                                <Permission
                                  name="role"
                                  title="User Groups"
                                  selectedPermissions={selectedPermissions}
                                  setSelectedPermissions={
                                    setSelectedPermissions
                                  }
                                />
                              </Col>
                            </Row>
                          </Row>
                        </CardBody>
                      </Card>
                      <div className="flex-wrap gap-2 text-end">
                        <button
                          type="submit"
                          color="secondary"
                          disabled={loading}
                          className="btn btn-primary w-md waves-effect waves-light"
                        >
                          Submit
                        </button>
                        &nbsp; &nbsp; &nbsp;
                        <Button
                          type="button"
                          color="secondary"
                          onClick={tog_center}
                          className="btn-inactive btn"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          ) : null}
        </div>
      </div>
      <ToastContainer />
      <SweetAlert
        show={modal_center}
        title="The changes done will not be saved, do you really want to exit."
        info
        showCancel
        confirmBtnText="No"
        confirmBtnBsStyle="primary"
        cancelBtnText="Yes"
        cancelBtnBsStyle="secondary"
        onConfirm={() => {
          tog_center()
        }}
        onCancel={() => {
          window.location.href = `/user-groups`
        }}
      ></SweetAlert>
    </React.Fragment>
  )
}

export default UserRoleCreate
