import React, { useEffect, useState } from "react"
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Col, Row } from "reactstrap"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  CHECK_PAYMENT_METHOD,
  SAVE_PAYMENT_METHOD,
} from "../Appointments/gql/Gql"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Loader from "../../components/Common/Loader"
const CardDetails = ({ recheckMaymentMethod }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(false)
  const userData = useSelector(state => state.Login?.user?.user)
  const userDetails = new URLSearchParams(location.search).get("userId")
  const clientData = userDetails && JSON.parse(atob(userDetails))

  const [savePaymentMethod, savePaymentMethodRes] =
    useMutation(SAVE_PAYMENT_METHOD)
  const handleSubmitForm = async event => {
    event.preventDefault()
    if (!stripe || !elements) {
      return
    }
    setIsLoading(true)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    })
    if (error) {
      setIsLoading(false)
      toast.error("oops! Something went wrong.")
    } else {
      setIsLoading(false)
      const paymentMethodID = {
        paymentMethodId: paymentMethod.id,
        clientId: clientData
          ? JSON.stringify(clientData?.value)
          : JSON.stringify(userData.id),
      }
      savePaymentMethod({
        variables: paymentMethodID,
      })
    }
  }

  useEffect(() => {
    if (
      savePaymentMethodRes.data &&
      savePaymentMethodRes?.data?.saveClientPaymentMethod?.data
        ?.userPaymentMethod
    ) {
      toast.success("Your card details have been successfully saved!")
      setIsLoading(false)
      recheckMaymentMethod()
    }else if(savePaymentMethodRes?.data && !savePaymentMethodRes?.data?.saveClientPaymentMethod?.status){
      toast.error(savePaymentMethodRes?.data?.saveClientPaymentMethod?.message)
    }
  }, [savePaymentMethodRes.data])

  return (
    <form onSubmit={handleSubmitForm} className="payment-form">
      <p className="h4 ">Card</p>
      <CardElement className="card-element mt-2" />
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          disabled={!stripe || isLoading}
          className="btn btn-primary"
        >
          Add Card
        </button>
      </div>
    </form>
  )
}

const CardFormWithElements = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
  )

  const userData = useSelector(state => state.Login?.user?.user)
  const [isPaymentMethod, setIsPaymentMethod] = useState([])
  const [loading, setLoading] = useState(false)
  const [checkPaymentMethod, checkPaymentMethodRes] = useLazyQuery(
    CHECK_PAYMENT_METHOD,
    {
      fetchPolicy: "no-cache",
    }
  )
  const userDetails = new URLSearchParams(location.search).get("userId")
  const clientData = userDetails && JSON.parse(atob(userDetails))

  const recheckMaymentMethod = () => {
    setLoading(true)
    checkPaymentMethod({
      variables: {
        clientId: clientData
          ? JSON.stringify(clientData?.value)
          : JSON.stringify(userData.id),
      },
    })
  }

  useEffect(() => {
    if (userData.id) {
      recheckMaymentMethod()
    }
  }, [userData.id])
  useEffect(() => {
    if (checkPaymentMethodRes?.data) {
      const isCardAdded =
        checkPaymentMethodRes?.data?.getUserPaymentMethod?.data
          ?.userPaymentMethods
      setIsPaymentMethod(isCardAdded)
      setLoading(false)
    }
  }, [checkPaymentMethodRes.data])
  const history = useHistory()

  const goBackBtn = () => {
    if(clientData){
      window.close();
    }else{
      history.push("/appointments")
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <i
            className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
            id="backArrow"
            onClick={() => goBackBtn()}
          />
          <h4 className="card-title-main mt-2 mx-2">Card Details</h4>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : isPaymentMethod?.length == 0 ? (
          <Row className="container-fluid d-flex justify-content-center mb-5">
            <Col
              md={9}
              className="m-0 p-3 mt-5  d-flex justify-content-center "
            >
              <div className="pre-card-section">
                <h3>Securely Save Your Card for Future Sessions</h3>
                <p className="mt-3">Dear valued client,</p>
                <ul>
                  <li className="mt-2">
                    We value your convenience and trust, which is why we offer
                    the option to securely save your card details for future
                    sessions. By saving your card, you allow your counsellor to
                    easily process payments for upcoming appointments, making
                    the transaction process seamless and hassle-free for both
                    parties.
                  </li>
                  <li className="mt-2">
                    Rest assured, your card information is protected and
                    confidential. We utilize industry-standard encryption
                    methods to safeguard your data, ensuring your peace of mind
                    throughout the process.
                  </li>
                  <li className="mt-2">
                    Thank you for entrusting us with your care. If you have any
                    concerns or questions regarding the saving of your card
                    details, please do not hesitate to reach out to us.
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={9} className="m-0 p-3 my-5">
              <div className="">
                <Elements stripe={stripePromise}>
                  <CardDetails recheckMaymentMethod={recheckMaymentMethod} />
                </Elements>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="d-flex align-items-center justify-content-center mt-5 p-0 m-0">
              <Col md={10} className="border border-dark p-0 m-0">
                <Row className="card-data-section m-0 p-0">
                  <Col md={2} className="card-data-left"></Col>
                  <Col md={8} className="card-data-right">
                    <div className="card-data-title mt-5">
                      <p className="h2 font-weight-bold">
                        Your Payment Details
                      </p>
                    </div>
                    <Row className="mt-5 d-flex justify-content-end">
                      <Col md={10} className="h5 font-weight-bold">
                        CARDHOLDER NAME
                      </Col>
                      <Col md={10} className="border-bottom border-dark mt-3">
                        <p className="h5 text-capitalize ms-3">
                          {clientData
                            ? clientData?.name
                            : `${userData?.first_name} ${userData?.last_name}`}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-4 d-flex justify-content-end">
                      <Col md={10} className="h5 font-weight-bold">
                        CARD NUMBER
                      </Col>
                      <Col md={10} className="border-bottom border-dark mt-3">
                        <p className="h5 card-digit font-weight-bold ms-4 text-capitalize">
                          **** **** **** {isPaymentMethod[0]?.last4}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-3 d-flex justify-content-end">
                      <Col md={10} className=" mt-3">
                        <Row className="d-flex justify-content-between">
                          <Col
                            md={5}
                            className="border-bottom border-dark mt-3 "
                          >
                            <div className="h5 font-weight-bold">
                              EXPIRATION DATE
                            </div>
                            <p className="font-weight-bold ms-4">
                              {" "}
                              {isPaymentMethod[0]?.exp_month} /{" "}
                              {isPaymentMethod[0]?.exp_year}
                            </p>
                          </Col>
                          <Col
                            md={5}
                            className="border-bottom border-dark mt-3 "
                          >
                            <div className="h5 font-weight-bold">CVV</div>
                            <p className="font-weight-bold ms-4 d-flex">
                              <span className="mdi mdi-circle-small"></span>
                              <span className="mdi mdi-circle-small"></span>
                              <span className="mdi mdi-circle-small"></span>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="credit-card border border-dark">
                  <div className="d-flex align-items-start justify-content-end h4 font-weight-bold mt-4 me-5">
                    {isPaymentMethod[0]?.brand?.toUpperCase()}
                  </div>
                  <div className="d-flex align-items-center justify-content-start h3 font-weight-bold card-chip mt-4 ms-5">
                    <img src="/chip.png" height={50} width={70} />
                  </div>
                  <div className="d-flex align-items-end justify-content-center h3 font-weight-bold card-chip mt-4">
                    <p className="card-digit mt-2">
                      **** **** **** {isPaymentMethod[0]?.last4}
                    </p>
                  </div>
                  <div className="d-flex align-items-end justify-content-between h6 font-weight-bold card-chip ms-5 ">
                    <p className=" ms-2">
                      {isPaymentMethod[0]?.exp_month} /{" "}
                      {isPaymentMethod[0]?.exp_year}
                    </p>
                    <p className="h5 text-capitalize me-5">
                    {clientData
                            ? clientData?.name
                            : `${userData?.first_name} ${userData?.last_name}`}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default CardFormWithElements
