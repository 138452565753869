import {
  GET_COUNSELLOR_PAY, STORE_COUNSELLOR_PAY,
} from "./actionTypes"

export const getCounsellorPay = () => ({
  type: GET_COUNSELLOR_PAY,
})
export const storeCounsellorPay = (data) => {
  return {
  type: STORE_COUNSELLOR_PAY,
  payload:{data}
}}