import { gql } from "@apollo/client"

export const GET_CLIENTS = gql`
query GetPlans($argumentsInput: ArgumentsInput) {
  getPlans(argumentsInput: $argumentsInput) {
    code
    message
    data {
    appointments {
      # email
       plan{
        id
        alerts_enabled
        total_sessions
        approval_status
        expiry_date
        session_status
        document_submitted_on
        approved_sessions
        billed_sessions
        charged_session
        partial_paid_ids
        is_approved
        is_document_submitted
        is_request
        is_two_week_expiry
        is_One_Week_Expiry
        is_no_payment
        created_at
        request {
          id
          is_approved
          created_at
          remarks
          requestor{
              first_name
              last_name
          }
          __typename
        }
        client {
          age
          id
          name
          first_name
          last_name
          __typename
        }
        payment_source {
          name
          id
          is_direct
          __typename
        }
        counsellor {
          id
          email
          __typename
        }
        __typename
      }
    }
      totalCount
      __typename
    }
    status
    __typename
  }
}`

export const GET_COUNSELORS = gql`
query Calendars($argumentsInput: ArgumentsInput) {
  getCalendarLegend(argumentsInput: $argumentsInput) {
    data {
      calendars {
        name
        id
        document_submitted_on_status
        no_payment
        request_status
        approval_status
        two_sessions_two_week_left
        one_sessions_one_week_left
        partial_paid
      }
    }
    code
    status
    message
  }
}
`

export const GET_CLIENT = gql`
query GetPlan($argumentsInput: ArgumentsInput) {
  getPlan(argumentsInput: $argumentsInput) {
    data {
      plan {
        billed_sessions
        approved_sessions
        charged_session
        alerts_enabled
        session_status
        expiry_date
        session_status
        request {
          id
          is_approved
          created_at
          remarks
          requestor{
              first_name
              last_name
          }
        }
        client {
          age
          id
          name
          first_name
          last_name
          email
          mobile_number
        }
        payment_source {
          name
          id
          is_direct
        }
        document_submitted_on
        is_document_submitted
        appointments {
          id
          email
          utc_datetime
          duration
          appointment_type
          therapist_calendar {
            id
            email
            name
          }
        }
      }
    }
  }
}
`


export const UPDATE_CLIENT = gql`
mutation Mutation($updatePlanInput: UpdatePlanInput!) {
  updatePlan(updatePlanInput: $updatePlanInput) {
    code
    message
    status
  }
}
`

export const GET_PAYMENT_TYPE = gql`
query GetPaymentType {
  getPaymentType {
    data {
      paymentTypes {
        id
        name
        is_direct
      }
    }
  }
}
`

export const DEACTIVATE_CLIENT = gql`
mutation Mutation($createPlanInput: CreatePlanInput!) {
  requestByCounsellor(createPlanInput: $createPlanInput) {
    code
    message
    status
  }
}
`

export const APPROVE_REQUEST_BY_ADMIN = gql`
mutation Mutation($createPlanInput: CreatePlanInput!) {
  approveRequestByAdmin(createPlanInput: $createPlanInput) {
    code
    message
    status
  }
}
`