import React from "react"
import { Button, Container } from "reactstrap"
import { useHistory } from "react-router-dom"

const PaymentFailed = () => {
  const history = useHistory()

  const goBack = () => {
    history.push(`/appointments`)
  }
  return (
    <React.Fragment>
      <Container>
        <div className="paymentFailedSection text-center m-0 p-0">
          <img src="/payment-failed.png" />
          <p>Payment Failed</p>
          <span className="h5">
            The Payment was unsuccessful due to an abnormality.{" "}
          </span>
          <span className="h5">Please try again or later.</span>
          <Button className="btn btn-primary mt-3 py-2 px-3" onClick={goBack}>
            Go Back
          </Button>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default PaymentFailed
