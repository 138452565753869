import React, { useState, useEffect } from "react";
import TableInput from "../../../components/Common/TableInput";

function TextboxField(props) {

    const { rowValue, row, index, field, successAPI,onhandleSuccessApi } = props;
    const [value, setValue] = useState(rowValue);
    const [isEditing, setisEditing] = useState(false);

    const handleEdit = () => {
        setisEditing(true);
    }

    const onhandleCancel = () => {
        setisEditing(false);
    }

    const onhandleSuccess = (e, value, row, index) => {
        const formattedValue = parseFloat(value).toFixed(2);
        setValue(formattedValue);
        setisEditing(false);
        successAPI(formattedValue, row,index)
    }

    return (
        <>
            {isEditing ?
                <TableInput
                    onhandleSuccess={onhandleSuccess}
                    onhandleCancle={onhandleCancel}
                    row={row}
                    defaultValue={parseFloat(value).toFixed(2)}
                    index={index}
                /> :
                <div>{"$" + parseFloat(value).toFixed(2)}<i className="uil uil-pen ms-2 pointer" onClick={handleEdit}></i></div>
            }
        </>
    )

}

export default TextboxField