import React, { useState, useEffect } from "react"
import { Card, CardBody } from "reactstrap"
import CountUp from "react-countup"
import useStyles from "../styles"
import classNames from "classnames"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import { Divider } from "@material-ui/core"

// Icons
import ExternalSession from "../../../assets/images/dashboard-icons/counselor-dashboard/external-session.svg"
import InternalSession from "../../../assets/images/dashboard-icons/counselor-dashboard/internal-session.svg"
import CombinationSession from "../../../assets/images/dashboard-icons/counselor-dashboard/combination-session.svg"

function Location({ dashboardData, callMainApi, isLoader }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(isLoader)

  useEffect(() => {
    setLoading(isLoader)
  }, [isLoader])

  const locations = [
    {
      id: 1,
      arrowIcon: "mdi mdi-arrow-up-bold",
      title: "Total External",
      icon: ExternalSession,
      value: dashboardData?.external_sessions || 0,
      decimal: 0,
      desc: "This will reflect the session took place outside the NFC premises",
    },
    {
      id: 2,
      arrowIcon: "mdi mdi-arrow-down-bold",
      title: "Total Internal",
      icon: InternalSession,
      value: dashboardData?.internal_Sessions || 0,
      decimal: 0,
      desc: "This will reflect the no. of sessions that took place on the premises of NFC.",
    },
  ]

  const refreshApi = () => {
    setLoading(true)
    callMainApi(true)
  }

  return (
    <Card body className="light-green-bg-card-table">
      <CardBody className="p-0">
        <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
          <h4 className={classNames("card-title", classes.cardTitle)}>
            Appointment Location
          </h4>
          <div className="d-flex gap-2">
            <span>
              <img
                src={refresh}
                width="24px"
                height="24px"
                className={classNames(
                  classes.refreshIcon,
                  loading && classes.refresh
                )}
                onClick={() => refreshApi()}
              />
            </span>
          </div>
        </div>
        <div
          className={classNames(
            "d-flex justify-centent-center",
            classes.appointmentLocation
          )}
        >
          <div
            className={classNames(
              "d-flex gap-2 flex-wrap justify-content-around my-auto w-100",
              classes.locationContainer
            )}
          >
            {dashboardData &&
              locations.map((n, index) => (
                <React.Fragment key={index}>
                  <div className={classNames(classes.locationItems)}>
                    <img src={n.icon} width="100" height="100" />
                    <div>
                      <h4 className="mt-1 mb-0">
                        <span className={classes.locationTitle}>
                          <span className="counselor-location-fsize32">
                            {n.value}
                          </span>
                        </span>
                      </h4>
                      <p
                        title={n.desc}
                        className={classNames("mt-1 " + classes.locationTitle)}
                      >
                        <span>{n.title}</span>
                        <span>Session</span>
                      </p>
                    </div>
                  </div>
                  {index !== locations.length - 1 ? (
                    <Divider className={`divider-w-h ${classes.divider}`} />
                  ) : null}
                </React.Fragment>
              ))}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default Location
