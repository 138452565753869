import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  BreadcrumbItem,
  Modal,
  ModalBody,
} from "reactstrap"
import style from "./style.module.scss"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import Tooltip from "@material-ui/core/Tooltip"
import { Link } from "react-router-dom/cjs/react-router-dom"

const CreateClassType = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    New Type of Class
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-lg-8 col-sm-12 position-relative mb-4">
              <Row className="d-flex justify-content-center">
                <Col xs={12} md={8}>
                  <Card className="transparent-bg-card">
                    <CardBody>
                      <Form className="form-horizontal" onSubmit="">
                        <Row className="form-custom-white-inputs">
                          <Col xl={12} md={12}>
                            <Label className="form-label">Name</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <Input
                              name="name"
                              className="form-control"
                              placeholder="ex. Aroma Therapy"
                              type="text"
                            />
                          </Col>
                          <Col xl={12} md={12} className="mt-3">
                            <Label className="form-label">Description</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <Input
                              name="description"
                              className="form-control"
                              placeholder="Write some info about this appointment type"
                              type="textarea"
                            />
                          </Col>
                          <Col xl={12} md={12} className="mt-3">
                            <Label className="form-label">Duration</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <div className="d-flex align-items-center">
                              <Input
                                name="duration "
                                className="form-control w-25 per-25-input"
                                placeholder=""
                                defaultValue={30}
                                type="number"
                              />
                              <span className="mx-2 d-block">Minutes</span>
                            </div>
                          </Col>
                          <Col xl={12} md={12} className="mt-3">
                            <Label className="form-label">Price ($)</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <div className="d-flex align-items-center">
                              <Input
                                name="duration "
                                className="form-control w-25 per-25-input"
                                placeholder=""
                                defaultValue={30}
                                type="number"
                              />
                            </div>
                          </Col>
                          <Col xl={12} md={12} className="mt-3">
                            <Label className="form-label">Category</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <div className="form-custom-white-inputs">
                              <CustomMultiSelect
                                className="input w-100"
                                placeholder="Select category"
                              />
                              <div className="d-flex align-items-center mt-2">
                                <Input
                                  name="duration "
                                  className="form-control "
                                  placeholder="Create a new category"
                                  type="text"
                                />
                                <Button className="btn btn-primary mx-2 h-40">
                                  Save
                                </Button>
                              </div>
                              <Link to="#" className="mt-1 color-dark-green">
                                Create a new category
                              </Link>
                            </div>
                          </Col>
                          <Col xl={12} md={12} className="mt-3">
                            <Label className="form-label">Color</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <div className="form-custom-white-inputs">
                              <div className="d-flex align-items-center mt-2">
                                <span
                                  className="color-button-appointment-type mx-1"
                                  style={{ background: "green" }}
                                ></span>
                                <span
                                  className="color-button-appointment-type mx-1"
                                  style={{ background: "red" }}
                                ></span>
                                <span
                                  className="color-button-appointment-type mx-1"
                                  style={{ background: "blue" }}
                                ></span>
                                <span
                                  className="color-button-appointment-type mx-1"
                                  style={{ background: "orange" }}
                                ></span>
                                <span
                                  className="color-button-appointment-type mx-1"
                                  style={{ background: "brown" }}
                                ></span>
                                <span
                                  className="color-button-appointment-type mx-1"
                                  style={{ background: "beige" }}
                                ></span>
                              </div>
                            </div>
                          </Col>
                          <Col xl={12} md={12} className="mt-3">
                            <Label className="form-label">Picture</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <div className="form-custom-white-inputs">
                              <div className="d-flex align-items-center mt-2">
                                <Input
                                  name="file "
                                  className="form-control "
                                  placeholder=""
                                  type="file"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xl={12} md={12} className="mt-3">
                            <Label className="form-label">Access</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <div className="form-custom-white-inputs">
                              <div className=" mt-2">
                                <div>
                                  <div className="d-flex align-items-center">
                                    <input
                                      name="access "
                                      className=" "
                                      placeholder=""
                                      type="radio"
                                      value="Public"
                                      id="public"
                                    />
                                    <Label
                                      className="form-label mx-2 mb-0"
                                      for="public"
                                    >
                                      Public
                                    </Label>
                                  </div>
                                  <p>
                                    Clients can book this on your scheduling
                                    page
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  <input
                                    name="access "
                                    className=" "
                                    placeholder=""
                                    type="radio"
                                    value="Private"
                                    id="private"
                                  />

                                  <Label
                                    className="form-label mx-2 mb-0"
                                    for="private"
                                  >
                                    Private
                                  </Label>
                                </div>
                                <p>
                                  Only admins and clients with the direct
                                  scheduling link can book this (you&asop;ll see
                                  the direct link after you create it)
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col xl={12} md={12} className="mt-3">
                            <Label className="form-label">Group Class</Label>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="invoice-list-fsize-14 ">
                                  This will display the total appointments
                                  scheduled.
                                </div>
                              }
                              arrow
                            >
                              <i
                                className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                              ></i>
                            </Tooltip>
                            <div className="form-custom-white-inputs">
                              <div className=" mt-2">
                                <div>
                                  <div className="d-flex align-items-center">
                                    <input
                                      name="access "
                                      className=" form-check-input checkbox mt-0"
                                      placeholder=""
                                      type="checkbox"
                                      value="group"
                                      id="group"
                                    />

                                    <Label
                                      className="form-label mx-2 mb-0"
                                      for="group"
                                    >
                                      This is a class or group event
                                    </Label>
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex align-items-center mt-2">
                                    <Label
                                      className="form-label mx-2 mb-0"
                                      for="group"
                                    >
                                      Max number of people
                                    </Label>
                                    <input
                                      name="access "
                                      className="form-control w-10 per-10-input"
                                      placeholder=""
                                      type="number"
                                      value=""
                                      id="group"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex align-items-center mt-3">
                                    <input
                                      name="access "
                                      className=" form-check-input checkbox mt-0"
                                      placeholder=""
                                      type="checkbox"
                                      value="group"
                                      id="group"
                                    />

                                    <Label
                                      className="form-label mx-2 mb-0"
                                      for="group"
                                    >
                                      Clients must sign up for all the times
                                      this is offered
                                    </Label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col xl={12} md={12} className="mt-5">
                            <Button className="btn btn-primary">
                              Create appointment type
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col className="col-lg-4 col-sm-12 position-relative mb-4">
              <Row className="align-items-center">
                <Col xl={12} md={12} className="mt-3">
                  <div className="form-custom-white-inputs">
                    <Label className="form-label">Forms </Label>
                    <Tooltip
                      placement="right"
                      title={
                        <div className="invoice-list-fsize-14 ">
                          This will display the total appointments scheduled.
                        </div>
                      }
                      arrow
                    >
                      <i
                        className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                      ></i>
                    </Tooltip>
                    <br></br>
                    <span className="invoice-list-fsize-14 ">
                      Forms are optional, but useful to ask extra questions when
                      clients book their appointments.
                    </span>
                    <p className="invoice-list-fsize-14 ">
                      You can set these up later in the&nbsp;
                      <Link className="color-dark-green">
                        <u>Intake Form Questions</u>
                      </Link>
                      &nbsp;section.
                    </p>

                    <div className=" mt-2">
                      <div>
                        <div className="d-flex align-items-center">
                          <input
                            name="access "
                            className=" form-check-input checkbox mt-0"
                            placeholder=""
                            type="checkbox"
                            value="group"
                            id="ph-consultation"
                          />

                          <Label
                            className="form-label mx-2 mb-0"
                            for="ph-consultation"
                          >
                            Phone Consultation Intake Form
                          </Label>
                        </div>
                        <Link className="invoice-list-fsize-14 color-dark-green">
                          <u>View all forms »</u>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={12} md={12} className="mt-3">
                  <div className="form-custom-white-inputs">
                    <Label className="form-label">Classes </Label>
                    <Tooltip
                      placement="right"
                      title={
                        <div className="invoice-list-fsize-14 ">
                          You can choose when classes are offered after creating
                          this appointment type
                        </div>
                      }
                      arrow
                    >
                      <i
                        className={`uil uil-info-circle ul-bg-dark ${style.circleinfo}`}
                      ></i>
                    </Tooltip>
                    <br></br>
                    <span className="invoice-list-fsize-14 ">
                      You can choose when classes are offered after creating
                      this appointment type
                    </span>

                    <div className=" mt-2">
                      <div>
                        <div className="d-flex align-items-center">
                          <input
                            name="access "
                            className=" form-check-input checkbox mt-0"
                            placeholder=""
                            type="checkbox"
                            value="group"
                            id="ph-consultation"
                          />

                          <Label
                            className="form-label mx-2 mb-0"
                            for="ph-consultation"
                          >
                            Class 1
                          </Label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateClassType
