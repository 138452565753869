import React, { Fragment } from "react"
import { Button } from "reactstrap"
import Tooltip from "@material-ui/core/Tooltip"
import style from "./style.module.scss"
import { isColorDark } from "../../functions/functions"

export const CalendarChip = eventInfo => {
  // "dayGridMonth"
  if (eventInfo?.view?.type === "timeGridWeek") {
    return (
      <div
        title={eventInfo?.event?.extendedProps?.appointmentName}
        className={`pr-${eventInfo?.backgroundColor}`}
      >{`${eventInfo?.timeText} ${eventInfo?.event?.title}`}</div>
    )
  }
  if (eventInfo?.view?.type === "timeGridDay") {
    return (
      <div
        title={eventInfo?.event?.extendedProps?.appointmentName}
        className={`pr-${eventInfo?.backgroundColor}`}
      >{`${eventInfo?.event?.title} ${eventInfo?.timeText}`}</div>
    )
  }

  const storedData = localStorage.getItem("orgData")
  const customColor = storedData ? JSON.parse(storedData) : null

  return (
    <Fragment>
      <div
        title={eventInfo?.event?.extendedProps?.appointmentName}
        // arrow
        style={{
          padding: "5px",
          cursor: "pointer",
          borderRadius: "5px",
          border: `1px solid ${eventInfo?.backgroundColor}`,
          color: eventInfo?.event?.extendedProps?.is_closed
            ? "#153c27 !important"
            : "#fff !important",
          backgroundColor: eventInfo?.event?.extendedProps?.is_closed
            ? customColor?.secondary_color ? customColor?.secondary_color : "#e2ecdf"
            : customColor?.primary_color ? customColor?.primary_color : "#153c27",
        }}
      >
        <div
          style={{
            color: eventInfo?.event?.extendedProps?.is_closed
              ? "#153c27 "
              : "#fff ",
          }}
        >
          <div>
            
              {eventInfo?.event?.extendedProps?.is_closed
                ?<strong> {"Closed" }</strong>
                : <span> {eventInfo?.event?.title} </span>}
            
          </div>
          {/* <div>{eventInfo?.timeText}</div> */}
        </div>
      </div>
    </Fragment>
  )
}
