import React from "react";
import PropTypes from "prop-types";

const TabContent = ({ id, activeTab, children }) => {

  return (
    activeTab === id ? <div className="TabContent">
      {children}
    </div>
      : null
  );
};

TabContent.propTypes = {
  id: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired
}

export default TabContent;