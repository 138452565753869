import React from "react"
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import Loader from "../../components/Common/Loader"
const AppointmentTypeModal = ({
  isSubmittedAppointment,
  handleEModalToggle,
  validationAppointment,
  categoryTypeAppointmentTypesTotal,
  categoryTypeAppointmentTypes,
  handleMainChecked,
  selectedAppointmentTypes,
  handleChildChecked,
  handleSearchApoointment,
  loaderAppointmentType,
}) => {
  return (
    <Row className="d-flex justify-content-center mt-5">
      <Col md={12}>
        <Card className="light-green-bg-card-white border-light-shadow">
          <CardBody>
            <Form
              className="needs-validation"
              onSubmit={e => {
                e.preventDefault()
                validationAppointment.handleSubmit()
                return false
              }}
            >
              <Row className="appointmentHight">
                <Col md={12}>
                  <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                    <div className="w50">
                      <Input
                        name="search"
                        placeholder="Search"
                        onChange={handleSearchApoointment}
                        className="default-search-input "
                      />
                    </div>
                    <div className="fw600">
                      [
                      {(categoryTypeAppointmentTypesTotal &&
                        categoryTypeAppointmentTypesTotal) ||
                        0}
                      ]
                    </div>
                  </div>
                  {loaderAppointmentType ? (
                    <div className="w=100 text-center">
                      <Loader />
                    </div>
                  ) : categoryTypeAppointmentTypes.length > 0 ? (
                    categoryTypeAppointmentTypes.map((category, index) => {
                      return (
                        <div key={index}>
                          <hr/>
                          <div className="mb-4">
                            <span className="fw600 ">{category.category}</span>
                            
                            <div className="mt-2">
                              <Label className="d-flex">
                                <Input
                                  type="checkbox"
                                  className="form-check-input "
                                  name={`${category.category}_${index}`}
                                  id={`${category.category}_${index}`}
                                  onClick={e => handleMainChecked(e, index)}
                                  onChange={() => {}}
                                  defaultChecked={
                                    category &&
                                    category.selectAll &&
                                    category.selectAll.length ===
                                      category?.appointmentTypes.length
                                      ? true
                                      : false
                                  }
                                  checked={
                                    category &&
                                    category.selectAll &&
                                    category.selectAll.length ===
                                      category?.appointmentTypes.length
                                      ? true
                                      : false
                                  }
                                />
                                <div className="ml-10">Check all</div>
                              </Label>
                            </div>
                            {category?.appointmentTypes.map(
                              (type, typeindex) => {
                                return (
                                  <div key={typeindex} className="ml-10">
                                    <Label className="d-flex">
                                      <div className="">
                                        <Input
                                          className="form-check-input "
                                          type="checkbox"
                                          defaultChecked={
                                            selectedAppointmentTypes.includes(
                                              type.id
                                            )
                                              ? true
                                              : false
                                          }
                                          checked={
                                            selectedAppointmentTypes.includes(
                                              type.id
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={() => {}}
                                          onClick={e =>
                                            handleChildChecked(
                                              e,
                                              type,
                                              typeindex,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="ml-10">{type.name}</div>
                                    </Label>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="w=100 mt-5 text-center">No Data Found</div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-2 text-right">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmittedAppointment}
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
export default AppointmentTypeModal
