import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap"
import style from "../Counselor/style.module.scss"
import { ADD_USER_ATTACHMENTS } from "./gql/userGql"
import { useMutation } from "@apollo/client"
import Soinner from "../../assets/images/Loader.svg"
import { toast } from "react-toastify"

const AttachmentModal = ({
  addAttachmentToggle,
  modal,
  getUserAttachmentsFun,
  username,
}) => {
  const [attachmentImg, setAttachmentImg] = useState(null)
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState("")
  const [AddUserAttachment, AddUserAttachmentRes] =
    useMutation(ADD_USER_ATTACHMENTS)

  const handleImg = async (acceptedFiles, field) => {
    if (acceptedFiles[0].size > 5 * 1024 * 1000) {
      toast.error("Max File Upload Size is Upto 5 MB, try with smaller size")
      return
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }
    setAttachmentImg(preview)

    let payload = new FormData()
    payload.set("file", acceptedFiles[0])
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get(
      "orgId"
    )
    const decodedOrgIdFromUrl = orgIdValueFromUrl
      ? decodeURIComponent(orgIdValueFromUrl)
      : undefined

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        {
          method: "POST",
          body: payload,
          headers: {
            organization:
              decodedOrgIdFromUrl ||
              orgData?.organization_identifier ||
              process.env.REACT_APP_MULTITENENT,
          },
        }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        setAttachmentImg(preview)
      } else {
        setAttachmentImg(undefined)
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      setAttachmentImg(undefined)
      toast.error("Failed to upload cover image")
    }
  }

  const handleChange = e => {
    setDescription(e.target.value)
  }

  useEffect(() => {
    if (
      AddUserAttachmentRes?.data?.addUserAttachment?.code === 200 &&
      AddUserAttachmentRes?.data?.addUserAttachment?.status
    ) {
      toast.success(AddUserAttachmentRes?.data?.addUserAttachment?.message)
      addAttachmentToggle()
      getUserAttachmentsFun()
      setAttachmentImg(null)
      setDescription("")
      setLoading(false)
    } 
  }, [AddUserAttachmentRes.data])

  const uploadFile = () => {
    setLoading(true)
    AddUserAttachment({
      variables: {
        attachment: {
          user_id: parseInt(username),
          url: attachmentImg?.url,
          description: description,
        },
      },
    })
  }

  return (
    <Modal isOpen={modal} keyboard={false}>
      <ModalHeader toggle={() => addAttachmentToggle()} tag="h4">
        Add file
      </ModalHeader>
      <ModalBody>
        <Dropzone multiple={false} onDrop={file => handleImg(file, "url")}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <input {...getInputProps()} />

              {!attachmentImg?.url ? (
                <div className="dz-message needsclick" {...getRootProps()}>
                  <div className="mb-2">
                    <i className="display-4 uil uil-cloud-upload" />
                  </div>
                  <h5>Drop JPEG, JPG or PNG.</h5>
                  <h6>
                    It can be square, landscape or portrait. Upto 5MB file size.
                  </h6>
                </div>
              ) : (
                <>
                  {attachmentImg?.state === "uploading" && (
                    <img
                      className="needsclick rounded bg-light w-100"
                      alt="primary"
                      src={Soinner}
                      height={200}
                      width={200}
                    />
                  )}
                  {attachmentImg?.state === "uploaded" && (
                    <div>
                      <img
                        {...getRootProps()}
                        className="needsclick rounded bg-light w-100"
                        alt="primary"
                        src={attachmentImg.url}
                      />
                      <button
                        onClick={() => {
                          setAttachmentImg(undefined)
                        }}
                        className={`${style.removeButton} mdi mdi-close`}
                        type="button"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </Dropzone>
        <div className="mt-3">
          <textarea
            id="notes"
            name="notes"
            className="form-control additional-information-client"
            placeholder="Enter description"
            onChange={handleChange}
            value={description}
          ></textarea>
        </div>
        <div className="text-end">
          {!loading ? (
            <button
              className="btn btn-inactive mt-3"
              onClick={() => uploadFile()}
              disabled={!attachmentImg?.url || !description}
            >
              Upload
            </button>
          ) : (
            <button
              className="btn btn-primary w-sm waves-effect waves-light mt-3"
              disabled
            >
              <Spinner
                type="grow"
                className="me-1 align-middle spinner-grow-sm "
                color="light"
              />
              Uploading...
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default AttachmentModal
