import {
    GET_CLIENT_APPOINTMENTS,
    GET_CLIENT_APPOINTMENTS_DETAILS,
    SET_CLIENT_APPOINTMENTS,
    UPDATE_CLIENT_APPOINTMENTS_DETAILS,
    UPDATE_PREVIEW,
    SET_EXPORTED_INVOICE,
    DELETE_EXPORTED_INVOICE,
    DELETE_ALL_EXPORTED_INVOICE,
    UPDATE_PREVIEW_ALL_DATA,
    SET_RECIEPT_PREVIEW_DATA
} from "./actionTypes";

const INIT_STATE = {
    clientAppointments: [],
    selectedClientAppointments: [],
    exportedInvoices: [],
    reciptPreview:{}
};

const clientAppointments = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CLIENT_APPOINTMENTS:
            return {
                ...state,
                clientAppointments: action.payload,
            };
            break;
        case GET_CLIENT_APPOINTMENTS_DETAILS:
            const index = state.clientAppointments.findIndex(n => n.id === action.payload.id);
            const data = action.payload.data
            // const updatedObject = Object.assign({}, currentObj, { payment_type_data: action.payload.data });
            const updatedArray = [
                ...state.clientAppointments.slice(0, index),
                { ...state.clientAppointments[index], data }, // merge previous object with payload data
                ...state.clientAppointments.slice(index + 1)
            ];
            return {
                ...state,
                clientAppointments: updatedArray
            };
            break;
        case SET_CLIENT_APPOINTMENTS:
            const appointments = Object.assign([], state.selectedClientAppointments);
            const parentRowIndex = appointments.findIndex(n => n.id === action.payload.id);
            if (parentRowIndex >= 0) {
                return {
                    ...state,
                    selectedClientAppointments: Object.assign([], appointments, {
                        [parentRowIndex]: Object.assign({}, appointments[parentRowIndex], action.payload)
                    })
                }
            }
            return {
                ...state,
                selectedClientAppointments: [
                    ...state.selectedClientAppointments,
                    Object.assign({}, action.payload)
                ]
            };
        case UPDATE_CLIENT_APPOINTMENTS_DETAILS:
            const clientAppointmentData = Object.assign([], state.clientAppointments)
            const childIndex = action.payload.childIndex
            const parentIndex = state.clientAppointments.findIndex(n => n.id == action.payload.parentId)
            let parentObj = state.clientAppointments[parentIndex]
            const parentObjData = parentObj.data
            const changedObj = Object.assign([], parentObjData, {
                [childIndex]: Object.assign({}, parentObjData[childIndex], action.payload)
            });
            if (parentIndex >= 0) {
                return {
                    ...state,
                    clientAppointments: Object.assign([], clientAppointmentData, {
                        [parentIndex]: { ...clientAppointmentData[parentIndex], data: changedObj }
                    })
                }
            }
            break;
        case UPDATE_PREVIEW:
            const clientAppointmentsData = Object.assign([], state.clientAppointments)
            const childIndexs = action.payload.childIndex
            const changedObjs = action.payload.changedObj
            const parentsIndexs = state.clientAppointments.findIndex(n => n.id == action.payload.parentId)
            let parentObjs = state.clientAppointments[parentsIndexs]
            const parentObjDatas = parentObjs.data
            // const changedObj = Object.assign([], parentObjDatas, {
            //     [childIndex]: Object.assign({}, parentObjDatas[childIndex], action.payload)
            // });
            if (parentsIndexs >= 0) {
                return {
                    ...state,
                    clientAppointments: Object.assign([], clientAppointmentsData, {
                        [parentsIndexs]: { ...clientAppointmentsData[parentsIndexs], preview: changedObjs }
                    })
                }
            }
            break;
        case UPDATE_PREVIEW_ALL_DATA:
            const clientAppointmentsDataAll = Object.assign([], state.clientAppointments)
            const childIndexsAll = action.payload.childIndex
            const changedObjsAll = action.payload.changedObj
            const parentsIndexsAll = state.clientAppointments.findIndex(n => n.id == action.payload.parentId)
            let parentObjsAll = state.clientAppointments[parentsIndexsAll]
            const parentObjDatasAll = parentObjsAll.data
            // const changedObj = Object.assign([], parentObjDatas, {
            //     [childIndex]: Object.assign({}, parentObjDatas[childIndex], action.payload)
            // });
            if (parentsIndexsAll >= 0) {
                return {
                    ...state,
                    clientAppointments: Object.assign([], clientAppointmentsDataAll, {
                        [parentsIndexsAll]: { ...clientAppointmentsDataAll[parentsIndexsAll], preview: changedObjsAll }
                    })
                }
            }
            break;
        case SET_EXPORTED_INVOICE:
            const isDuplicate = state.exportedInvoices.some(item => item.invoice_number === action.payload.invoice_number);
            if (isDuplicate) {
                // If it's a duplicate, return the current state
                return state;
            } else {
                // If it's not a duplicate, add the item to the array
                return {
                    ...state,
                    exportedInvoices: [...state.exportedInvoices, action.payload]
                };
            }
            break;
        case DELETE_EXPORTED_INVOICE:
            const idsToDelete = action.payload; // Array of ids to delete
            const updatedExportedInvoicesArray = state.exportedInvoices.filter(obj => !idsToDelete.includes(obj.invoice_number));
            return {
                ...state,
                exportedInvoices: updatedExportedInvoicesArray
            };
            break;
        case DELETE_ALL_EXPORTED_INVOICE:
            return {
                ...state,
                exportedInvoices: []
            };
            break;
            
        case SET_RECIEPT_PREVIEW_DATA:
            if(action.payload.id === undefined || action.payload.id === null){
                // for on page load 
                return {...state,
                    reciptPreview: action.payload.data};
            }
            // when click on edit rates
            let total_gst_Amount=0;
            let total_rate=0;
            let total_Amount=0;
            const newData = state?.reciptPreview?.calendar?.appointments.map((item,index) =>{
                if(index === action.payload.id){
                    total_gst_Amount += parseFloat(action.payload.gst)
                    total_rate += parseFloat(action.payload.price)
                    total_Amount += parseFloat(action.payload.total)
                    return { ...item, 
                    price:action.payload.price,
                    gst:action.payload.gst,
                    total:action.payload.total,
                    amount_paid:action.payload.total,
                    }
                }else{
                    total_gst_Amount += parseFloat(item.gst)
                    total_rate += parseFloat(item.price)
                    total_Amount += parseFloat(item.total)
                    return item
                } 
            });
            return {
                ...state,
                reciptPreview: {
                    calendar:{
                        ...state?.reciptPreview?.calendar,
                        total_gst_Amount,
                        total_rate,
                        total_Amount,
                        appointments:[...newData]
                    }
                }
            };
            break;
        default:
            return state;
    }
};

export default clientAppointments;