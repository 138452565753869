import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Table from "../../components/Common/Table"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import React, { useCallback, useEffect, useState } from "react"
import style from "./style.module.scss"
import Soinner from "../../assets/images/Loader.svg"
import { useHistory, useParams, Link } from "react-router-dom"
import { FocusError } from "focus-formik-error"
import Loader from "../../components/Common/Loader"
import { useFormik } from "formik"
import * as Yup from "yup"
import { GET_CATEGORY_TYPE, GET_LOCATION_LIST, GET_TIMEZONE } from "./gql/Gql"
import { useLazyQuery } from "@apollo/client"
import PhoneNoField from "./PhoneNoField"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import Dropzone from "react-dropzone"
import AsyncSelect from "react-select/async"
import Checkbox from "@material-ui/core/Checkbox"
const AddNewCounsellor = ({
  validation,
  categoryTypeAppointmentTypes,
  handleMainChecked,
  selectedAppointmentTypes,
  handleChildChecked,
  handleSearchApoointment,
  getAppointmentmentApi,
  selectedOrganization
}) => {
  let history = useHistory()
  const [initialValues, setInitialValues] = useState({
    timezone: "America/Los_Angeles",
  })
  const [locationGroups, setLocationGroupsList] = useState([])
  const [location, setLocationList] = useState([])
  const [timezone, setTimeZone] = useState([])
  const storedData = localStorage.getItem("orgData")
  const customOrg = storedData ? JSON.parse(storedData) : null

  const [getCategoryType, getCategoryTypeRes] = useLazyQuery(
    GET_CATEGORY_TYPE,
    { fetchPolicy: "no-cache" }
  )
  const [getTimezone, getTimezoneRes] = useLazyQuery(GET_TIMEZONE, {
    fetchPolicy: "no-cache",
  })
  const [getLocations, getLocationsRes] = useLazyQuery(GET_LOCATION_LIST, {
    fetchPolicy: "no-cache",
  })
  const storedOrg = localStorage.getItem("orgId")
  const orgId = storedOrg ? parseInt(atob(JSON.parse(storedOrg))) : null
  useEffect(() => {
    getTimezone()
    getCategoryType({
      variables: {
        argumentsInput: {
          search: "",
        },
      },
    })
    getLocations({
      variables: {
        argumentsInput: {
          organization_id: orgId,
        },
      },
    })
  }, [])

  useEffect(() => {
    getLocations({
      variables: {
        argumentsInput: {
          organization_id: orgId,
        },
      },
    })
    getCategoryType({
      variables: {
        argumentsInput: {
          search: "",
          organization_id: selectedOrganization ? selectedOrganization?.id : customOrg ? customOrg?.id : 1,
        },
      },
    })
  }, [selectedOrganization])
  

  useEffect(() => {
    if (
      getTimezoneRes.data &&
      getTimezoneRes.data.getAllTimeZones &&
      getTimezoneRes.data.getAllTimeZones.code === 200
    ) {
      let arraytimezone = getTimezoneRes.data.getAllTimeZones.data.allTimeZone

      arraytimezone = arraytimezone.map(e => {
        return { name: e.display, id: e.zoneName }
      })
      setTimeZone(arraytimezone)
      validation.setFieldValue("timezone", {id:"America/Vancouver" ,name: "(GMT-8:00) America/Vancouver"})
    }
  }, [getTimezoneRes.data])
  useEffect(() => {
    if (
      getLocationsRes.data &&
      getLocationsRes.data.getCalendarDropdown &&
      getLocationsRes.data.getCalendarDropdown.code === 200
    ) {
      setLocationGroupsList(
        getLocationsRes.data.getCalendarDropdown.data.location_groups
      )
    }
  }, [getLocationsRes.data])

  const handleImg = async (acceptedFiles, field) => {
    if (acceptedFiles[0].size > 5 * 1024 * 1000) {
      toast.error("Max File Upload Size is Upto 5 MB, try with smaller size")
      return
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }
    validation.setFieldValue(field, preview)

    let payload = new FormData()
    payload.set("file", acceptedFiles[0])
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        {
          method: "POST",
          body: payload,
          headers: {
            organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,


          },
        }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        validation.setFieldValue(field, preview)
      } else {
        validation.setFieldValue(field, undefined)
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      validation.setFieldValue(field, undefined)
      toast.error("Failed to upload cover image")
    }
  }
  const fetchOptions = (inputValue, callback) => {
    getAppointmentmentApi(inputValue, callback)
  }
  const [selectedOptions, setSelectedOptions] = useState([])
  const handleChangeAppointment = useCallback(
    selected => {
      validation.setFieldValue("appointment_type", selected)
      setSelectedOptions(selected)
    },
    [selectedOptions]
  )

  const formatGroupLabel = (data, validation) => (
    <div>
      <Input
        type="checkbox"
        className="form-check-input mr-2"
        onChange={e => {
          if (e.target.checked) {
            const newSelectedOptions = [
              ...selectedOptions,
              ...data.options.filter(
                option => !selectedOptions.some(o => o.value === option.value)
              ),
            ]
            validation.setFieldValue("appointment_type", newSelectedOptions)
            setSelectedOptions(newSelectedOptions)
          } else {
            const newSelectedOptions = selectedOptions.filter(
              option => !data.options.some(o => o.value === option.value)
            )
            setSelectedOptions(newSelectedOptions)
          }
        }}
        style={{marginRight:'5px'}}
      />
      <span className="ml-2">{data.label}</span>
    </div>
  )

  return (
    <React.Fragment>
      {false ? (
        <Row>
          <Col className="col-12 user-role-create-height">
            <div className="spinner">
              <Loader />
            </div>
          </Col>
        </Row>
      ) : (
        <Row className={style.cardcenter}>
          <Col xl="12">
            <Col
              xl={5}
              lg={12}
              md={5}
              className="mb-4 form-custom-white-inputs"
            >
              <Label className="form-label">Image</Label>
              <Dropzone
                multiple={false}
                onDrop={file => handleImg(file, "url")}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <input {...getInputProps()} />

                    {!validation?.values?.url ? (
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <div className="mb-2">
                          <i className="display-4 uil uil-cloud-upload" />
                        </div>
                        <h5>Drop JPEG, JPG or PNG.</h5>
                        <h6>
                          It can be square, landscape or portrait. Upto 5MB file
                          size.
                        </h6>
                      </div>
                    ) : (
                      <>
                        {validation?.values?.url?.state === "uploading" && (
                          <img
                            className="needsclick rounded bg-light w-100 h-auto"
                            alt="primary"
                            src={Soinner}
                          />
                        )}
                        {validation?.values?.url?.state === "uploaded" && (
                          <div>
                            <img
                              {...getRootProps()}
                              className="needsclick rounded bg-light w-100 h-auto"
                              alt="primary"
                              src={validation?.values?.url?.url}
                            />
                            <button
                              onClick={() => {
                                validation.setFieldValue("url", undefined)
                              }}
                              className={`${style.removeButton} mdi mdi-close`}
                              type="button"
                              aria-label="Close"
                            ></button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Dropzone>
            </Col>

            <Col md="12">
              <div className="mb-2 form-custom-white-inputs">
                <Label className="form-label">Calendar Name</Label>
                <Input
                  id="name"
                  name="name"
                  className={` form-control`}
                  placeholder="Enter calendar name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Row>
              <Col md="6">
                <div className="mb-2 form-custom-white-inputs">
                  <Label className="form-label">City</Label>
                  <CustomMultiSelect
                    className="input"
                    onChange={value =>
                      validation.setFieldValue("location_groups", value)
                    }
                    name="location_groups"
                    placeholder="Select city"
                    value={validation.values.location_groups}
                    options={locationGroups}
                    error={validation.errors?.location_groups}
                    touched={validation.touched?.location_groups}
                    isCloseMenuOnSelect={false}
                    isMulti
                    onBlur={() => {
                      validation.setFieldTouched("location_groups", true)
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-2 form-custom-white-inputs">
                  <Label className="form-label">Timezone</Label>
                  <CustomMultiSelect
                    className="input"
                    name="timezone"
                    onChange={value =>
                      validation.setFieldValue("timezone", value)
                    }
                    placeholder="Select Timezone"
                    value={validation.values.timezone}
                    options={timezone}
                    error={validation.errors?.timezone}
                    touched={validation.touched?.timezone}
                    onBlur={() => {
                      validation.setFieldTouched("timezone", true)
                    }}
                  />
                  {validation.touched.timezone && validation.errors.timezone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.timezone}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {/* <Col md="12">
                <div className="mb-2 form-custom-white-inputs">
                  <Label className="form-label">Appointment Types</Label>
                  <AsyncSelect
                    className={`custom-date-picker-style-bradius`}
                    isMulti
                    name="appointment_type"
                    placeholder="Select Appointment Types"
                    defaultOptions={categoryTypeAppointmentTypes}
                    loadOptions={fetchOptions}
                    onBlur={() => {
                      validation.setFieldTouched("appointment_type", true)
                    }}
                    onChange={handleChangeAppointment}
                    error={validation.errors?.appointment_type}
                    touched={validation.touched?.appointment_type}
                    value={selectedOptions}
                    formatGroupLabel={data =>
                      formatGroupLabel(data, validation)
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: "8px",
                        minHeight: "41px",
                        border: "1px solid #ced4da",
                      }),
                    }}
                    closeMenuOnSelect={false}
                  />

                  {validation.touched.appointment_type &&
                  validation.errors.appointment_type ? (
                    <div className="error">
                      {validation.errors.appointment_type}
                    </div>
                  ) : null}
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
export default AddNewCounsellor
