import React, { useState, useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
} from "reactstrap"
import refresh from "../../../assets/images/dashboard-icons/refresh.svg"
import classNames from "classnames"
import useStyles from "../styles"
import moment from "moment"
import DatePicker from "react-datepicker"
import { GET_MONTH_WISE_CLIENT_DATA } from "../Counselor/gql/gql"
import { useMutation } from "@apollo/client"
import { convertUTCToLocal } from "../../../functions/functions"
import { formatMonthSeriesData, formatYearSeriesData } from "../function"
import style from "./style.module.scss"
import Tooltip from "@material-ui/core/Tooltip"

const TotalClients = ({ setIsLoader }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("Monthly")
  const [endDate, setEndDate] = useState(moment().toDate())
  const [startDate, setstartDate] = useState(
    moment(endDate).startOf("isoWeek").toDate()
  )

  const handleMonthYear = value => {
    setstartDate(value)
  }

  const [series, setSeries] = useState([
    {
      name: "Total Client Registered",
      type: "area",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ],
    },
  ])

  const [options, setOptions] = useState({
    chart: {
      stacked: !1,
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [2, 2, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
        borderRadius: 8,
      },
    },
    colors: ["#153C27"],
    borderRadius: 8,
    fill: {
      opacity: [0.25, 1, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
    },
    yaxis: {
      title: {
        text: "Number of total client registered",
        style: {
          fontSize: "11px",
          fontWeight: "Medium",
          color: "#3F4E50",
        },
      },
      style: {
        fontSize: "11px",
        fontWeight: "Medium",
        color: "#3F4E50",
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0)
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#E2ECDF",
    },
    zoom: {
      enabled: false,
      type: "x",
      autoScaleYaxis: false,
    },
  })

  //************************** Get Data ***************************** */

  const [getChartDetails, chartResponse] = useMutation(
    GET_MONTH_WISE_CLIENT_DATA
  )

  function getChartDetailsFun() {
    getChartDetails({
      variables: {
        clientCounsellorGraph: {
          ...(value == "Monthly"
            ? { month: parseInt(moment(startDate).format("MM")) }
            : null),
          year: moment(value == "Monthly" ? startDate : endDate).format("YYYY"),
        },
      },
    })
  }

  useEffect(() => {
    getChartDetailsFun()
  }, [value, startDate, endDate])

  useEffect(() => {
    if (chartResponse.data && chartResponse.data.clientCounsellorGraph) {
      setLoading(false)
      setIsLoader(false)

      if (value == "Monthly") {
        let clientMonthData =
          chartResponse.data.clientCounsellorGraph.data.month_wise_client_data.map(
            (item, i) => {
              item.date = item.day
              item.day = convertUTCToLocal(item.day, "MM/DD/YYYY")
              return item
            }
          )

        let clientObj = formatMonthSeriesData(clientMonthData)
        let categories = []
        let clientData = []
        let startDateString = parseInt(
          moment(startDate).startOf("month").format("DD")
        )
        let endDateString = parseInt(
          moment(startDate).endOf("month").format("DD")
        )

        let currentMonth = moment(startDate).format("MMM")

        for (let i = startDateString; i <= endDateString; i++) {
          categories.push(`${currentMonth} ${i} `) // x axis for month
          //  x axis for month
          if (clientObj[i]) {
            clientData.push(parseInt(clientObj[i])) // for all count client register data
          } else {
            clientData.push(0)
          }
        }

        setOptions({
          ...options,
          ...{
            xaxis: {
              categories: categories,
            },
          },
        })
        let newSeries = series
        newSeries[0].data = clientData
        setSeries([...newSeries])
      } else {
        let categories = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ]
        let yearsData =
          chartResponse.data.clientCounsellorGraph.data.year_wise_client_data

        setOptions({
          ...options,
          ...{
            xaxis: {
              categories: categories,
            },
          },
        })
        let clientData = []
        categories.forEach((item, i) => {
          if (yearsData[i]) {
            clientData.push(yearsData[i].count)
          } else clientData.push(0)
        })
        let newSeries = series
        newSeries[0].data = clientData
        setSeries(newSeries)
      }
    } else {
      setLoading(true)
    }
  }, [chartResponse.data])

  const refreshAPI = () => {
    setLoading(true)
    setIsLoader(true)
    getChartDetailsFun()
  }

  return (
    <React.Fragment>
      <Card className="light-green-bg-card-table">
        <CardBody>
          <div className="float-end d-flex gap-2 align-items-center">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="text-reset bg-light p-2 pointer"
                id="dropdownMenuButton5"
                caret
              >
                <span className="text-muted">
                  {value}
                  <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  value={"Monthly"}
                  onClick={e => setValue(e.target.value)}
                >
                  Monthly
                </DropdownItem>
                <DropdownItem
                  value={"Yearly"}
                  onClick={e => setValue(e.target.value)}
                >
                  Yearly
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <div className="w-100">
              {value == "Monthly" ? (
                <DatePicker
                  selected={startDate}
                  className="form-control pointer"
                  onChange={value => handleMonthYear(value, "Monthly")}
                  dateFormat="MMM-yyyy"
                  maxDate={moment().toDate()}
                  showMonthYearPicker={value == "Monthly" ? true : false}
                  customInput={
                    <input
                      type="text"
                      id="validationCustom01"
                      placeholder="Date"
                    />
                  }
                />
              ) : (
                <DatePicker
                  selected={endDate}
                  className="form-control"
                  dateFormat="yyyy"
                  onChange={value => setEndDate(value)}
                  maxDate={moment().toDate()}
                  showYearPicker={value == "Yearly" ? true : false}
                  customInput={
                    <input
                      type="text"
                      id="validationCustom01"
                      placeholder="Date"
                    />
                  }
                />
              )}
            </div>
            <span>
              <img
                src={refresh}
                width="24px"
                height="24px"
                className={classNames(
                  classes.refreshIcon,
                  loading && classes.refresh
                )}
                onClick={refreshAPI}
              />
            </span>
          </div>
          <CardTitle className={classNames("mb-4 h4", classes.cardTitle)}>
            {"Total Client Registered"}
            <Tooltip
              className={style.tooltip}
              placement="right"
              title={
                <div className="invoice-list-fsize-14">
                  {" "}
                  This will display the count of newly registered clients on
                  platform.
                </div>
              }
              arrow
            >
              <i
                className={`color-dark-green ul-bg-dark uil uil-info-circle ${style.circleinfo}`}
              ></i>
            </Tooltip>
          </CardTitle>
          <div className="mt-3 this-month">
            <ReactApexChart
              options={options}
              series={series}
              height="450"
              type="line"
              className="apex-charts"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TotalClients
