import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  BreadcrumbItem,
  ModalBody,
  Modal,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Tooltip from "@material-ui/core/Tooltip"
import SimpleBar from "simplebar-react"
import CustomSelect from "../../components/CustomSelect"
import { useLazyQuery, useMutation } from "@apollo/client"
import CustomMultiSelect from "../../components/CustomMultiSelect"

import {
  AddUserRemarks,
  CREATE_CALENDAR,
  CREATE_EDIT_USER,
  DELETE_USER,
  GET_USER,
  GET_USER_BY_USER,
  GET_USER_ROLE,
  GetCategoryTypeAppointmentTypes,
  GetUserRemarks,
  RemoveUserRemarks,
  UPDATE_CALENDER_APPOINTMENT,
} from "./gql/userGql"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import PhoneNoField from "./PhoneNoField"
import { useParams, Link, useHistory } from "react-router-dom"
import {
  getAllCity,
  getAllCountry,
  getAllStates,
  scrollTop,
} from "../../functions/functions"
import { useSelector } from "react-redux"
import { FocusError } from "focus-formik-error"
import _, { debounce } from "lodash"
import style from "./style.module.scss"
import SweetAlert from "react-bootstrap-sweetalert"
import Loader from "../../components/Common/Loader"
import AddNewCounsellor from "../Counselor/AddNewCounsellor"
import { GET_ORGANIZATION } from "../Organizations/gql/Gql"
import Switch from "react-switch"
import Remarks from "./Remarks"
import UserTabs from "./UserTabs"
import ClientProfile from "./ClientProfile"
import Transaction from "../Organizations/Transaction"
import UserAttachments from "./UserAttachments"
import DeleteCounsellor from "../Counselor/DeleteCounsellor"
import Alerts from "../../components/Alerts"
import Select from "react-select"

const UserCreate = props => {
  let { username } = useParams()
  const decodedId = username && atob(username)
  username = decodedId
  let history = useHistory()
  const orgData = useSelector(state => state.Login?.orgDetails)
  const [originalEmail, setOriginalEmail] = useState(false)
  const [isEmailChanges, setIsEmailChanges] = useState(false)
  // Form validation
  const [roleList, setRoleList] = useState()
  const [organizationsList, setOrganizationsList] = useState()
  const [selectedOrganization, setSelectedOrganization] = useState()
  const [filterRoleList, setFilterRoleList] = useState([])
  const [inProgress, setInProgress] = useState(false)
  const userData = useSelector(state => state.Login?.user?.user)
  const [isSpinner, setIsSpinner] = useState(false)
  const [calenderId, setCalenderId] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmittedAppointment, setIsSubmittedAppointment] = useState(true)
  const [modal_center, setmodal_center] = useState(false)
  const [isChangeRole, setIsChangeRole] = useState(false)
  const [currentRoleId, setCurrentRoleId] = useState(null)
  const [categoryTypeAppointmentTypes, setCategoryTypeAppointmentTypes] =
    useState([])
  const [
    categoryTypeAppointmentTypesTotal,
    setCategoryTypeAppointmentTypesTotal,
  ] = useState(0)
  const storedData = localStorage.getItem("orgData")
  const customOrg = storedData ? JSON.parse(storedData) : null
  const [getAllRoles, setGetAllRoles] = useState([])
  const [getAllOrg, setGetAllOrgs] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [isUserAlreadyExist, setIsUserAlreadyExist] = useState(false)
  const [profileData, setProfileData] = useState(null)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [initialValues, setInitialValues] = useState({
    id: null,
    role_id: 3,
    name: "",
    email: "",
    mobile_number: "",
    first_name: "",
    last_name: "",
    timezone: "",
    appointment_type: [],
    gender: null,
    city: "",
    pronouns: "",
    province: "",
    country: "",
    zipcode: "",
    address: "",
    date_of_birth: "",
    emergency_contact_number:""
  })
  const [initialValuesAppointment, setInitialValuesAppointment] = useState({
    timezone: "",
  })
  const [isDisableRoleId, setIsDisableRoleId] = useState(0)
  const [loaderAppointmentType, setLoaderAppointmentType] = useState(false)
  const locationValue = new URLSearchParams(location.search).get("login")
  const updateValue = new URLSearchParams(location.search).get("update")
  const roleParam = new URLSearchParams(location.search).get("role")
  const [activeTab, setActiveTab] = useState(
    username
      ? {
          name: "Profile",
          type: "profile",
        }
      : {
          name: "Edit/Settings",
          type: "settings",
        }
  )
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 100,
  })

  function tog_center() {
    setmodal_center(!modal_center)
  }

  useEffect(() => {
    setCountryList(getAllCountry())
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      timezone: Yup.object().test(
        "notEmptyArr",
        "Please select Timezone",
        value => {
          return !value ? false : value.id ? true : false
        }
      ),
      timezone: Yup.object().when("role", {
        is: role => {
          return username ? false : validation.values.role_id == "2"
        }, // adjust this condition based on how your 'role' field is structured
        then: Yup.object()
          .test("notEmptyArr", "Please select timezone", value => {
            return !value ? false : value.id ? true : false
          })
          .required("Please select timezone"),
        otherwise: Yup.object().notRequired(),
      }),
      first_name: Yup.string()
        .required("Please enter your first name")
        .min(
          3,
          "A minimum of 3 characters is required for the first name field"
        )
        .max(
          50,
          "The system restricts the first name input to a maximum of 50 characters"
        ),
      last_name: Yup.string()
        .required("Please enter your last name")
        .min(3, "A minimum of 3 characters is required for the last name field")
        .max(
          50,
          "The system restricts the last name input to a maximum of 50 characters"
        ),
      email: Yup.string()
        .nullable()
        .email("Enter valid email address")
        .required("Email address is required")
        .test("email", "Please enter your email", function (email) {
          if (this.parent.role_id === 3 && !email) {
            return true
          } else if (email) {
            return true
          }
          return false
        }),
    }),
    onSubmit: values => {
      if (locationValue || updateValue) {
        const getOrgUserData = Array.isArray(selectedOrganization)
          ? selectedOrganization
          : [selectedOrganization]
        const selOrg = getOrgUserData.map(elem => elem.id)
        if (selOrg) {
          values["organizationIds"] = selOrg
          setIsSubmitted(true)
          if (
            isChangeRole &&
            values.role_id == 2 &&
            values.role_id != currentRoleId
          ) {
            if (values.timezone) {
              handleSubmit(values)
              setIsSubmitted(true)
            } else {
              toast.error("Please select timezone!")
            }
          } else {
            handleSubmit(values)
            setIsSubmitted(true)
          }
        } else {
          toast.error("Please select Organization")
        }
      } else {
        if (
          isChangeRole &&
          values.role_id == 2 &&
          values.role_id != currentRoleId
        ) {
          if (values.timezone) {
            handleSubmit(values)
            setIsSubmitted(true)
          } else {
            toast.error("Please select timezone!")
          }
        } else {
          handleSubmit(values)
          setIsSubmitted(true)
        }
      }
    },
  })
  const validationAppointment = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesAppointment,
    validationSchema: Yup.object({
      appointmentType: Yup.string().nullable(),
    }),
    onSubmit: values => {
      setIsSubmittedAppointment(true)
      updateAppointmentType({
        variables: {
          updateCalendarInput: {
            id: calenderId,
            appointment_type_ids: selectedAppointmentTypes,
          },
        },
      }).then(() => {
        setIsSubmittedAppointment(true)
        handleEModalToggle()
        toast.success("Counsellor Details updated successfully")
      })
    },
  })

  const [getUserDetail, details] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  })
  const [getAppointmentTypes, getAppointmentTypesRes] = useLazyQuery(
    GetCategoryTypeAppointmentTypes,
    {
      fetchPolicy: "no-cache",
    }
  )
  const [getUserRoleList, rolesResponse] = useLazyQuery(GET_USER_ROLE, {
    fetchPolicy: "no-cache",
  })
  const [getEmailByRole, getEmailByRoleRes] = useLazyQuery(GET_USER_BY_USER, {
    fetchPolicy: "no-cache",
  })

  const [getOrganizations] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: "no-cache",
  })

  const getOrganizationsFun = () => {
    getOrganizations({
      variables: {
        argumentsInput: {
          limit: 50,
          page: 1,
          search: "",
        },
      },
      onCompleted({ getOrganizations }) {
        if (getOrganizations?.data && getOrganizations?.data?.organizations) {
          const orgList = getOrganizations?.data?.organizations
          setOrganizationsList(orgList)
        }
      },
    })
  }

  function getUserRoleListFun() {
    getUserRoleList({
      variables: {
        argumentsInput: {
          page: 1,
          limit: 1000,
          search: "",
          sort: "id",
        },
      },
    })
  }
  React.useEffect(() => {
    getUserRoleListFun()
    getAppointmentTypes({
      variables: {
        argumentsInput: {
          search: "",
          limit: 200,
          page: 1,
          organization_id: selectedOrganization
            ? selectedOrganization?.id
            : customOrg
            ? customOrg?.id
            : 1,
        },
      },
    })
    getOrganizationsFun()
  }, [])

  const changeOrg = val => {
    setSelectedOrganization(val)
  }
  useEffect(() => {
    getAppointmentTypes({
      variables: {
        argumentsInput: {
          search: "",
          limit: 200,
          page: 1,
          organization_id: selectedOrganization
            ? selectedOrganization?.id
            : customOrg
            ? customOrg?.id
            : 1,
        },
      },
    })
  }, [selectedOrganization])
  useEffect(() => {
    if (
      getAppointmentTypesRes.data &&
      getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory &&
      getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.code ==
        200
    ) {
      let data =
        getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.data
          ?.categoryTypeAppointmentTypes

      data = data.map(item => {
        item.label = item.category
        let data = item?.appointmentTypes?.map(subitem => {
          subitem.label = subitem.name
          subitem.value = subitem.id
          return { ...subitem }
        })

        return { ...item, options: data }
      })

      setCategoryTypeAppointmentTypes(data)
      setCategoryTypeAppointmentTypesTotal(
        getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.data
          ?.totalCount
      )
      setLoaderAppointmentType(false)
    } else if (
      getAppointmentTypesRes.data &&
      getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory &&
      getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.code !==
        200
    ) {
      setLoaderAppointmentType(false)
    }
  }, [getAppointmentTypesRes.data])
  const getAppointmentmentApi = (inputValue, callback) => {
    getAppointmentTypes({
      variables: {
        argumentsInput: {
          search: inputValue,
          limit: 200,
          page: 1,
          organization_id: selectedOrganization
            ? selectedOrganization?.id
            : customOrg
            ? customOrg?.id
            : 1,
        },
      },
    }).then(getAppointmentTypesRes => {
      let data =
        getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.data
          ?.categoryTypeAppointmentTypes

      data = data.map(item => {
        item.label = item.category
        let data = item?.appointmentTypes?.map(subitem => {
          subitem.label = subitem.name
          subitem.value = subitem.id
          return { ...subitem }
        })
        return { ...item, options: data }
      })
      callback(data)
    })
  }
  const handleSearch = e => {
    setLoaderAppointmentType(true)
    getAppointmentTypes({
      variables: {
        argumentsInput: {
          search: e.target.value,
          organization_id: selectedOrganization
            ? selectedOrganization?.id
            : customOrg
            ? customOrg?.id
            : 1,
        },
      },
    })
  }
  const handleSearchApoointment = useCallback(debounce(handleSearch, 1000), [])
  useCallback(debounce(handleSearch, 1000), [])

  React.useEffect(() => {
    const filterArray = _.filter(roleList, function (role) {
      return role.id !== 2 && role.id !== 3
    })
    setFilterRoleList([...filterArray])
  }, [roleList])

  React.useEffect(() => {
    if (
      rolesResponse.data &&
      rolesResponse.data.getRoles &&
      rolesResponse.data.getRoles.status
    ) {
      let data = rolesResponse.data.getRoles.data.roles.map(item => {
        return { id: item.id, name: item.role_name }
      })
      data = data.filter(elem => {
        return elem.name.toLowerCase() != "superadmin"
      })
      if (updateValue) {
        data = data.filter(elem => {
          return elem.name.toLowerCase() != "admin"
        })
      }
      setRoleList(data)
      if (roleParam) {
        if (roleParam == "counsellor") {
          validation.setFieldValue("role_id", 2)
        }
      }
    } else if (rolesResponse.data) {
      setRoleList(0)
    }
  }, [rolesResponse.data])

  React.useEffect(() => {
    if (username) {
      getUserDetail({
        variables: {
          argumentsInput: {
            id: parseInt(username),
          },
        },
      })
    } else {
    }
  }, [username])

  React.useEffect(() => {
    if (
      details.data &&
      details.data?.getUser &&
      details.data?.getUser?.status
    ) {
      setIsEmailVerified(details.data?.getUser?.data?.user?.is_email_confirmed)
      const orgData =
        details.data?.getUser?.data?.user.organizations.map(elem => ({
          id: elem?.organization.id,
          name: elem?.organization.name,
          label: elem?.organization.name,
          value: elem?.organization?.name,
        })) || []
      getOrganizationsFun()
      setSelectedOrganization(orgData[0])
      setCurrentRoleId(details.data?.getUser?.data?.user.role.id)
      const allRoles = details.data?.getUser?.data?.user?.organizations.map(
        elem => {
          return elem?.role?.id
        }
      )
      setGetAllRoles(allRoles)
      const allOrgs = details.data?.getUser?.data?.user?.organizations.map(
        elem => {
          return elem?.organization?.id
        }
      )
      const storedData = localStorage.getItem("orgId")
      const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
      if (allOrgs.includes(orgId)) {
        const encodedId = btoa(details.data?.getUser?.data?.user?.id)
        if (!username) {
          toast.success(
            "User with this email is already exists. Redirecting you to the edit page."
          )
        }
        setTimeout(() => {
          history.push(`/users/${encodedId}/edit`)
        }, 1000)
      }
      setGetAllOrgs(allOrgs)
      const indexOfOrg = allOrgs.indexOf(orgId)
      const roleIndex = allRoles[indexOfOrg]
      const selectedCountry = getAllCountry().filter((elem)=>{
        return details.data?.getUser?.data?.user?.country == elem?.name
      })
      const selectedProvince = getAllStates(selectedCountry[0]?.value).filter((elem)=>{
        return details.data?.getUser?.data?.user?.province == elem?.name
      })
      const selectedCity = getAllCity(selectedCountry[0]?.value, selectedProvince[0]?.value).filter((elem)=>{
        return details.data?.getUser?.data?.user?.city == elem?.name
      })
      setProvinceList(getAllStates(selectedCountry[0]?.value))
      setCityList(getAllCity(selectedCountry[0]?.value, selectedProvince[0]?.value))
      const selectedGender = {
        name:details.data?.getUser?.data?.user?.gender,
        value:details.data?.getUser?.data?.user?.gender,
        label:details.data?.getUser?.data?.user?.gender,
        id:details.data?.getUser?.data?.user?.gender,
      }
      const formatDate = (isoString) => {
        if (!isoString) return '';
        return isoString.split('T')[0];
      };
      const formattedDate = formatDate(details.data?.getUser?.data?.user.date_of_birth || '');
      let data = {
        role_id: roleIndex ? roleIndex : 3,
        name: details.data?.getUser?.data?.user.name
          ? details.data?.getUser?.data?.user.name?.trim()
          : "",
        email: details.data?.getUser?.data?.user.email
          ? details.data?.getUser?.data?.user.email?.trim()
          : "",
        first_name: details.data?.getUser?.data?.user.first_name
          ? details.data?.getUser?.data?.user.first_name?.trim()
          : "",
        last_name: details.data?.getUser?.data?.user.last_name
          ? details.data?.getUser?.data?.user.last_name?.trim()
          : "",
        name: details.data?.getUser?.data?.user?.calendar?.name?.trim() || "",
        mobile_number: details.data?.getUser?.data?.user?.mobile_number || "",
        age: details.data?.getUser?.data?.user?.age || "",
        id: details.data?.getUser?.data?.user?.id || "",
        country: selectedCountry ? selectedCountry[0] : "",
        province: selectedProvince ? selectedProvince[0] : "",
        city: selectedCity ? selectedCity[0] : "",
        gender: selectedGender ? selectedGender : "",
        emergency_contact_number: details.data?.getUser?.data?.user?.emergency_contact_number ? details.data?.getUser?.data?.user?.emergency_contact_number : "",
        date_of_birth: formattedDate
        ? formattedDate
        : "",
        address: details.data?.getUser?.data?.user.address
        ? details.data?.getUser?.data?.user.address
        : "",
        zipcode: details.data?.getUser?.data?.user.zipcode
        ? details.data?.getUser?.data?.user.zipcode
        : "",
        pronouns: details.data?.getUser?.data?.user.pronouns
        ? details.data?.getUser?.data?.user.pronouns
        : "",
      }
      setIsSpinner(false)
      setOriginalEmail(data?.email)
      setIsDisableRoleId(details.data?.getUser?.data?.user?.role?.id)
      setProfileData(details.data?.getUser?.data)
      setInitialValues(data)
    } else {
      if (username) {
        setIsSpinner(true)
      }
    }
  }, [details?.data])
  const handleSubmit = async values => {
    let appointmentListIds = []
    values?.appointment_type?.forEach(item => {
      appointmentListIds.push(item.id)
    })
    let mobile = values.mobile_number ? values.mobile_number : ""
    if (!mobile.startsWith("+")) {
      mobile = "+" + mobile
    }
    const storedData = localStorage.getItem("orgId")
    const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
    const orgIndex = getAllOrg.indexOf(orgId)
    const currentRole = getAllRoles[orgIndex]
    let allRoles = getAllRoles
    let allOrgs = getAllOrg
    if (currentRole !== values.role_id && username) {
      allRoles[orgIndex] = values.role_id
    }
    if (isUserAlreadyExist || !username) {
      allOrgs.push(orgId)
      allRoles.push(values.role_id)
    }
    let userInput = {
      id: isUserAlreadyExist ? initialValues?.id : values?.id,
      role_id: allRoles[0],
      name: values.name,
      email:
        initialValues.email && isEmailVerified
          ? initialValues.email.toLowerCase()
          : values.email.toLowerCase(),
      mobile_number: mobile.length > 5 ? mobile : null,
      first_name: values.first_name,
      last_name: values.last_name,
      organizationIds: allOrgs,
      roleIds: allRoles,
      ...(username ? { is_edit: true } : {}),
      is_role_editable: !isUserAlreadyExist
        ? undefined
        : allRoles[orgIndex] === values.role_id
        ? undefined
        : true,
    }
    let emergancyNumber = values.emergency_contact_number ? values.emergency_contact_number : ""
    if (!emergancyNumber.startsWith("+")) {
      emergancyNumber = "+" + emergancyNumber
    }
    if (values.role_id == 3) {
      userInput.gender = values?.gender?.id
      userInput.city = values?.city?.id
      userInput.province = values?.province?.id
      userInput.country = values?.country?.id
      userInput.pronouns = values?.pronouns
      userInput.zipcode = values?.zipcode
      userInput.address = values?.address
      userInput.date_of_birth = values?.date_of_birth
      userInput.emergency_contact_number = emergancyNumber
      
    }else{
      delete userInput?.gender 
      delete userInput?.city 
      delete userInput?.province 
      delete userInput?.country
      delete userInput?.pronouns
      delete userInput?.zipcode 
      delete userInput?.address 
      delete userInput?.date_of_birth 
      delete userInput?.emergency_contact_number 
    }

    if (values.role_id == 2 && !username) {
      delete userInput.role_id
      delete userInput.mobile_number
      userInput.name = values.name
      const location_groups = values?.location_groups
        ? values?.location_groups.map(n => n.id)
        : []
      userInput.locations_group_ids = location_groups
      userInput.url = values?.url?.url || ""
      userInput.timezone = values?.timezone?.id
      userInput.primary_phone =
        mobile.length > 5
          ? mobile.includes("+")
            ? mobile
            : `+${mobile}`
          : null
    }
    if (
      isChangeRole &&
      userInput.role_id == 2 &&
      userInput.role_id != currentRoleId
    ) {
      const location_groups = values?.location_groups
        ? values?.location_groups.map(n => n.id)
        : []
      userInput.locations_group_ids = location_groups
      userInput.url = values?.url?.url || ""
      userInput.timezone = values?.timezone?.id
      userInput.primary_phone =
        mobile.length > 5 ? (mobile.includes("+") ? mobile : `+${mobile}`) : ""
      userInput.appointment_type_ids = appointmentListIds
      userInput.is_role_editable = true
    }
    if (isChangeRole && userInput.role_id == 3) {
      userInput.is_role_editable = true
      delete userInput.appointment_type_ids
      delete userInput.locations_group_ids
      delete userInput.timezone
      delete userInput.url
      delete userInput.primary_phone
    }
    try {
      setInProgress(true)
      if (!username) {
        if (values.role_id == 2 && !isUserAlreadyExist) {
          submitCalendar({
            variables: {
              createCalendarInput: userInput,
            },
          }).then(submitCalendarResponse => {
            setInProgress(false)
            if (
              submitCalendarResponse.data &&
              submitCalendarResponse.data.CreateCalendar.status &&
              submitCalendarResponse.data.CreateCalendar.code === 200
            ) {
              setCalenderId(
                submitCalendarResponse?.data?.CreateCalendar?.data?.calendar?.id
              )
              let calenderIds =
                submitCalendarResponse?.data?.CreateCalendar?.data?.calendar?.id
              setIsSubmittedAppointment(true)
              updateAppointmentType({
                variables: {
                  updateCalendarInput: {
                    id: calenderIds,
                    appointment_type_ids: appointmentListIds,
                  },
                },
              }).then(() => {
                setInProgress(false)
                setIsSubmittedAppointment(true)
                toast.success(
                  submitCalendarResponse.data.CreateCalendar.message
                )
                setTimeout(() => {
                  setIsSubmitted(false)
                }, 5000)
              })
            } else {
              if (
                submitCalendarResponse.data &&
                submitCalendarResponse.data.CreateCalendar
              ) {
                toast.error(submitCalendarResponse.data.CreateCalendar.message)
                setIsSubmitted(false)
              }
            }
          })
        } else {
          await submitMutation({
            variables: {
              createUserInput: userInput,
            },
          })
        }
      } else {
        await submitMutation({
          variables: {
            createUserInput: userInput,
          },
        })
      }
    } catch (e) {
      setInProgress(false)
      setIsSubmitted(false)
    }
  }

  const [submitMutation, submitResponse] = useMutation(CREATE_EDIT_USER)
  const [submitCalendar, submitCalendarResponse] = useMutation(CREATE_CALENDAR)
  const [updateAppointmentType, updateAppointmentTypeRes] = useMutation(
    UPDATE_CALENDER_APPOINTMENT
  )
  const goBack = () => {
    if (roleParam) {
      history.goBack()
    } else {
      if (userData.role.id === 1) {
        history.push("/users")
      } else {
        history.push("/my-clients")
      }
    }
  }

  React.useEffect(() => {
    setInProgress(false)
    if (
      submitResponse?.data &&
      submitResponse?.data?.createAdminUser?.status &&
      submitResponse?.data?.createAdminUser?.code === 200
    ) {
      toast.success(submitResponse.data.createAdminUser.message)
      setIsSubmitted(false)
      setTimeout(() => {
        if (username) {
          setIsSubmitted(false)
          goBack()
        } else {
          setIsSubmitted(false)
          goBack()
        }
      }, 3000)
    } else {
      if (submitResponse?.data && submitResponse?.data?.createAdminUser) {
        toast.error(submitResponse.data.createAdminUser.message)
        setIsSubmitted(false)
      }
      setIsSubmitted(false)
    }
  }, [submitResponse.data])

  function handleResetForm() {
    validation.resetForm()
    if (!username) {
      validation.setFieldValue("mobile_number", "+1")
    }
    scrollTop()
  }
  const handleEModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  }
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState([]) // 35958931, 35958964
  const handleChildChecked = (e, type, typeindex, index) => {
    if (
      categoryTypeAppointmentTypes[index].appointmentTypes[typeindex].checked
    ) {
      categoryTypeAppointmentTypes[index].appointmentTypes[typeindex].checked =
        e.target.checked
    } else {
      categoryTypeAppointmentTypes[index].appointmentTypes[typeindex].checked =
        e.target.checked
    }

    if (!e.target.checked) {
      setSelectedAppointmentTypes(prev => [...prev, type.id])
      if (categoryTypeAppointmentTypes[index].selectAll) {
        categoryTypeAppointmentTypes[index].selectAll = [
          ...categoryTypeAppointmentTypes[index].selectAll,
          type.id,
        ]
      } else {
        categoryTypeAppointmentTypes[index].selectAll = [type.id]
      }
    } else {
      if (categoryTypeAppointmentTypes[index].selectAll) {
        categoryTypeAppointmentTypes[index].selectAll =
          categoryTypeAppointmentTypes[index].selectAll.filter(
            item => item !== type.id
          )
      }
      setSelectedAppointmentTypes(
        selectedAppointmentTypes.filter(item => item !== type.id)
      )
    }

    setCategoryTypeAppointmentTypes(categoryTypeAppointmentTypes)
  }
  const handleMainChecked = (e, index) => {
    let arr = []
    categoryTypeAppointmentTypes[index].appointmentTypes.forEach(item => {
      arr.push(item.id)
    })
    if (!e.target.checked) {
      setSelectedAppointmentTypes(prev => [...prev, ...arr])
      categoryTypeAppointmentTypes[index].selectAll = arr
    } else {
      let final = selectedAppointmentTypes.filter(item => !arr.includes(item))
      setSelectedAppointmentTypes(final)
      categoryTypeAppointmentTypes[index].selectAll = []
    }
    setCategoryTypeAppointmentTypes(categoryTypeAppointmentTypes)
  }

  React.useEffect(() => {
    if (selectedAppointmentTypes.length === 0) {
      setIsSubmittedAppointment(true)
    } else {
      setIsSubmittedAppointment(false)
    }
  }, [selectedAppointmentTypes])

  // Retrive user remarks

  const handleRoleSwitch = () => {
    setIsChangeRole(!isChangeRole)
    validation.setFieldValue("role_id", currentRoleId)
  }

  const handleCheckEmail = e => {
    const email = e.target.value.toLowerCase().replace(/\s/g, "")
    function isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailPattern.test(email)
    }
    if (isValidEmail(email)) {
      getEmailByRole({
        variables: {
          argumentsInput: {
            email: email,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (
      getEmailByRoleRes.data?.getUserByEmail?.status &&
      getEmailByRoleRes.data?.getUserByEmail?.code === 200
    ) {
      const isExist = getEmailByRoleRes.data?.getUserByEmail?.data?.is_exist
      if (isExist) {
        setIsUserAlreadyExist(true)
        const userData = getEmailByRoleRes.data?.getUserByEmail?.data?.user
        getUserDetail({
          variables: {
            argumentsInput: {
              id: parseInt(userData?.id),
            },
          },
        })
        let data = {
          name: userData?.name ? userData?.name?.trim() : "",
          id: userData?.id ? userData?.id : "",
          first_name: userData?.first_name ? userData?.first_name?.trim() : "",
          last_name: userData?.last_name ? userData?.last_name?.trim() : "",
          email: userData?.email ? userData?.email?.trim() : "",
          mobile_number: userData?.mobile_number || "",
        }
        setInitialValues(data)
      } else {
        setIsUserAlreadyExist(false)
        setInitialValues({
          id: null,
          role_id: 3,
          name: "",
          email: "",
          mobile_number: "",
          first_name: "",
          last_name: "",
          timezone: "",
          appointment_type: [],
        })
      }
    }
  }, [getEmailByRoleRes.data])
  const isAlreadyExistsFun = useCallback(debounce(handleCheckEmail, 1000), [])

  const [deleteRecord, setDeleteRecord] = useState({
    id: null,
  })
  const [inProcess, setInProcess] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteMutation, deleteResponse] = useMutation(DELETE_USER)
  function deleteToggle() {
    setModalDelete(!modalDelete)
  }
  const handleDelete = async () => {
    const storedData = localStorage.getItem("orgId")
    const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
    try {
      setInProcess(true)
      await deleteMutation({
        variables: {
          argumentsInput: {
            id: parseInt(deleteRecord.id),
            organization_id: orgId ? orgId : undefined,
          },
        },
      })
    } catch (e) {
      setInProcess(false)
      console.log(e)
    }
    setDeleteRecord({
      id: null,
    })
    deleteToggle()
  }
  const openDeleteModel = async id => {
    setModalDelete(!modalDelete)
    setDeleteRecord({
      id,
    })
  }

  React.useEffect(() => {
    if (deleteResponse?.data && deleteResponse?.data?.deleteUser?.status) {
      toast.success(deleteResponse?.data?.deleteUser?.message)
      setInProcess(false)
      history.push("/users")
    } else {
      if (deleteResponse?.data && deleteResponse?.data?.deleteUser) {
        toast.error(deleteResponse?.data?.deleteUser?.message)
      }
      setInProcess(false)
    }
  }, [deleteResponse.data])

  useEffect(() => {
    if (validation?.values?.email !== originalEmail) {
      setIsEmailChanges(true)
    } else {
      setIsEmailChanges(false)
    }
  }, [validation?.values?.email])

  const handleCountryChange = val => {
    validation.setFieldValue("country", val)
    validation.setFieldValue("province", "")
    validation.setFieldValue("city", "")
    setProvinceList(getAllStates(val.value))
  }

  const handleProvinceChange = val => {
    validation.setFieldValue("province", val)
    validation.setFieldValue("city", "")
    setCityList(getAllCity(validation?.values?.country?.value, val.value))
  }
  const handleCityChange = val => {
    validation.setFieldValue("city", val)
  }

  const [genderOptions, setGenderOptions] = useState([
    {
      name: "Male",
      id: "Male",
      value: "Male",
      label: "Male",
    },
    {
      name: "Female",
      id: "Female",
      value: "Female",
      label: "Female",
    },
    {
      name: "Other",
      id: "Other",
      value: "Other",
      label: "Other",
    },
  ])

  const handleGenderChange = (val) => {
    validation.setFieldValue("gender", val)
  }

  const handleDateChange = (val) => {
    validation.setFieldValue("date_of_birth", val)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    {username ? "Edit user" : "Add User"}
                  </h4>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/users">Users</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{username ? "Edit user" : "Add User"}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {username && validation?.values?.first_name && (
            <Col className="col-12 d-flex flex-wrap flex-row mt-2 mb-4 align-items-center justify-content-between ">
              <div className="d-flex flex-row align-items-center">
                <p className="h4 font-weight-bold mx-2 text-capitalize">
                  {validation.values.first_name} {validation.values.last_name}{" "}
                </p>
                <p className="h4 font-weight-bold mx-2">-</p>
                <p className="h4 font-weight-bold mx-2 text-capitalize">
                  {activeTab?.name}
                </p>
              </div>
              <div className="d-flex flex-row align-items-center">
                {userData.role.id === 1 && (
                  <button
                    onClick={() => openDeleteModel(initialValues?.id)}
                    className=" btn button-delete  delete-btn text-white cursor-pointer"
                  >
                    Delete User
                    <span className="mx-2 mdi mdi-trash-can-outline font-size-16"></span>
                  </button>
                )}
              </div>
            </Col>
          )}
          {username && (
            <Col className="col-12 text-right">
              <Row className="m-0 p-0 ">
                <Col md={12} className="m-0 p-0">
                  <UserTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                </Col>
              </Row>
            </Col>
          )}
          {activeTab?.type === "profile" &&
            (username && isSpinner ? (
              <div className="d-flex align-items-center justify-content-center mt-5 ">
                <Loader />
              </div>
            ) : (
              <ClientProfile
                user={initialValues}
                setActiveTab={setActiveTab}
                profileData={profileData}
              />
            ))}
          {activeTab?.type === "settings" && (
            <Row>
              <Col className="col-12 user-role-create-height position-relative mt-3">
                {username && isSpinner ? (
                  <div className={style.spinnerContainer}>
                    <Loader />
                  </div>
                ) : !isSpinner ? (
                  <Row className="d-flex justify-content-center">
                    <Col md={9} className=" mt-5">
                      <Form
                        className="needs-validation"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <FocusError formik={validation} />
                        <Card className="light-green-bg-card-white border-light-shadow">
                          <CardBody>
                            <h5 className="font-weight-bold mb-4">
                              User Details
                            </h5>
                            <Row className="form-custom-white-inputs">
                              <Col md="12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Email
                                    <Tooltip
                                      className={style.tooltip}
                                      placement="right"
                                      title="A full name of the user. This will appear in User Profile, Ownership History, and Bid Activity."
                                      arrow
                                    >
                                      <i
                                        className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                      ></i>
                                    </Tooltip>
                                  </Label>
                                  <Input
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    type="email"
                                    onChange={event => {
                                      validation.handleChange(event)
                                      isAlreadyExistsFun(event)
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      validation.touched.email &&
                                      validation.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.email &&
                                  validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.email}
                                    </FormFeedback>
                                  ) : null}
                                  {orgData?.is_acuity_integration &&
                                    isEmailChanges && (
                                      <Alerts
                                        type={"warning"}
                                        message={
                                          "Kindly make sure to update the email address on Acuity as well. It's not synced automatically."
                                        }
                                      />
                                    )}
                                </div>
                              </Col>
                              <Col md="12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Role
                                    <Tooltip
                                      className={style.tooltip}
                                      placement="right"
                                      title="Role of the user. This will appear in User Profile, Ownership History, and Bid Activity."
                                      arrow
                                    >
                                      <i
                                        className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                      ></i>
                                    </Tooltip>
                                  </Label>
                                  <CustomSelect
                                    className="input"
                                    onChange={value =>
                                      validation.setFieldValue(
                                        "role_id",
                                        value.id
                                      )
                                    }
                                    placeholder="Select role"
                                    value={validation.values.role_id}
                                    options={
                                      roleList
                                      // : filterRoleList
                                    }
                                    error={validation.errors.role_id}
                                    touched={validation.touched.role_id}
                                    disabled={!isChangeRole && username}
                                    onBlur={() => {
                                      validation.setTouched({ role_id: true })
                                    }}
                                  />
                                </div>
                              </Col>
                              {username && (
                                <Col md="12" className="d-flex ">
                                  <Switch
                                    onChange={handleRoleSwitch}
                                    checked={isChangeRole}
                                    onColor="#153c3c"
                                    onHandleColor="#2693e6"
                                    handleDiameter={20}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={40}
                                    height={20}
                                  />
                                  <p className="mx-2">
                                    Want to change Role of User?
                                  </p>
                                </Col>
                              )}
                              {locationValue && (
                                <Col md="12">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Organization
                                      <Tooltip
                                        className={style.tooltip}
                                        placement="right"
                                        title="This newly created user can access selected organizations."
                                        arrow
                                      >
                                        <i
                                          className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                        ></i>
                                      </Tooltip>
                                    </Label>
                                    <CustomSelect
                                      className="input"
                                      onChange={value => changeOrg(value)}
                                      placeholder="Select Organization"
                                      value={selectedOrganization || ""}
                                      options={organizationsList}
                                      disabled={
                                        isDisableRoleId === 2 ||
                                        isDisableRoleId === 3
                                      }
                                    />
                                  </div>
                                </Col>
                              )}
                              {updateValue && (
                                <Col md="12">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Organizations
                                      <Tooltip
                                        className={style.tooltip}
                                        placement="right"
                                        title="This newly created user can access selected organizations."
                                        arrow
                                      >
                                        <i
                                          className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                        ></i>
                                      </Tooltip>
                                    </Label>

                                    {/* <CustomMultiSelect
                                    className={`input`}
                                    value={selectedOrganization}
                                    options={organizationsList}
                                    isCloseMenuOnSelect={false}
                                    isMulti
                                    onChange={value => changeOrg(value)}

                                  /> */}
                                    <CustomSelect
                                      className="input"
                                      onChange={value => changeOrg(value)}
                                      placeholder="Select Organization"
                                      disabled={true}
                                      value={selectedOrganization?.id || ""}
                                      options={organizationsList}
                                    />
                                  </div>
                                </Col>
                              )}
                              {initialValues?.role?.role_name ==
                                "Counsellor" && (
                                <Col md="12">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Calendar Name
                                      <Tooltip
                                        className={style.tooltip}
                                        placement="right"
                                        title="A full name of the user. This will appear in User Profile, Ownership History, and Bid Activity."
                                        arrow
                                      >
                                        <i
                                          className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                        ></i>
                                      </Tooltip>
                                    </Label>
                                    <Input
                                      id="name"
                                      name="name"
                                      className="form-control"
                                      placeholder="Enter calendar name"
                                      type="text"
                                      disabled={
                                        initialValues?.name ? true : false
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                        validation.touched.name &&
                                        validation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.name &&
                                    validation.errors.name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              )}

                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    First Name
                                    <Tooltip
                                      className={style.tooltip}
                                      placement="right"
                                      title="A full name of the user. This will appear in User Profile, Ownership History, and Bid Activity."
                                      arrow
                                    >
                                      <i
                                        className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                      ></i>
                                    </Tooltip>
                                  </Label>
                                  <Input
                                    id="first_name"
                                    name="first_name"
                                    className="form-control"
                                    placeholder="Enter your name"
                                    type="text"
                                    disabled={
                                      isChangeRole || isUserAlreadyExist
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.first_name || ""}
                                    invalid={
                                      validation.touched.first_name &&
                                      validation.errors.first_name
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.first_name &&
                                  validation.errors.first_name ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.first_name}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Last Name
                                    <Tooltip
                                      className={style.tooltip}
                                      placement="right"
                                      title="A full name of the user. This will appear in User Profile, Ownership History, and Bid Activity."
                                      arrow
                                    >
                                      <i
                                        className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                      ></i>
                                    </Tooltip>
                                  </Label>
                                  <Input
                                    id="last_name"
                                    name="last_name"
                                    className="form-control"
                                    placeholder="Enter your name"
                                    type="text"
                                    disabled={
                                      isChangeRole || isUserAlreadyExist
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.last_name || ""}
                                    invalid={
                                      validation.touched.last_name &&
                                      validation.errors.last_name
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.last_name &&
                                  validation.errors.last_name ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.last_name}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              {!isUserAlreadyExist && (
                                <Col md="6">
                                  <FormGroup className="mb-3">
                                    <Label className="form-label">
                                      Phone number
                                      <Tooltip
                                        className={style.tooltip}
                                        placement="right"
                                        title="A full name of the user. This will appear in User Profile, Ownership History, and Bid Activity."
                                        arrow
                                      >
                                        <i
                                          className={`color-dark-green uil uil-info-circle ${style.circleinfo}`}
                                        ></i>
                                      </Tooltip>
                                    </Label>
                                    <div className="input-group">
                                      <PhoneNoField
                                        className="phoneinpute"
                                        name="mobile_number"
                                        disabled={
                                          isChangeRole || isUserAlreadyExist
                                        }
                                        handleChange={validation.handleChange}
                                        onChange={value =>
                                          validation.setFieldValue(
                                            "mobile_number",
                                            value
                                          )
                                        }
                                        value={
                                          validation.values.mobile_number || ""
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              )}
                              <Col md="6">
                                  <FormGroup className="mb-3">
                                    <Label className="form-label">
                                      Emergency number
                                    </Label>
                                    <div className="input-group">
                                      <PhoneNoField
                                        className="phoneinpute"
                                        name="emergency_contact_number"
                                        disabled={
                                          isChangeRole || isUserAlreadyExist
                                        }
                                        handleChange={validation.handleChange}
                                        onChange={value =>
                                          validation.setFieldValue(
                                            "emergency_contact_number",
                                            value
                                          )
                                        }
                                        value={
                                          validation.values.emergency_contact_number || ""
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              {(!username &&
                                validation?.values?.role_id == 2) ||
                              (validation?.values?.role_id == 2 &&
                                isChangeRole &&
                                currentRoleId == 3) ? (
                                <>
                                  <h4 className="card-title-main mb-4">
                                    Calendar Details
                                  </h4>
                                  <p></p>
                                  <AddNewCounsellor
                                    validation={validation}
                                    getAppointmentmentApi={
                                      getAppointmentmentApi
                                    }
                                    categoryTypeAppointmentTypes={
                                      categoryTypeAppointmentTypes
                                    }
                                    handleMainChecked={handleMainChecked}
                                    selectedAppointmentTypes={
                                      selectedAppointmentTypes
                                    }
                                    handleChildChecked={handleChildChecked}
                                    handleSearchApoointment={
                                      handleSearchApoointment
                                    }
                                    selectedOrganization={selectedOrganization}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                        {validation?.values?.role_id == 3 && (
                          <Card className="light-green-bg-card-white border-light-shadow">
                            <CardBody>
                              <h5 className="font-weight-bold mb-4">
                                Personal information
                              </h5>
                              <Row className="form-custom-white-inputs">
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Country
                                    </Label>

                                    <Select
                                      id="country"
                                      name="country"
                                      placeholder="Select country"
                                      value={validation?.values?.country}
                                      onChange={e => handleCountryChange(e)}
                                      options={countryList}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Province
                                    </Label>
                                    <Select
                                      id="province"
                                      name="province"
                                      placeholder="Select province"
                                      isDisabled={!validation?.values?.country}
                                      value={validation?.values?.province}
                                      onChange={e => handleProvinceChange(e)}
                                      options={provinceList}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label className="form-label">City</Label>
                                    <Select
                                      id="city"
                                      name="city"
                                      placeholder="Select city"
                                      isDisabled={!validation?.values?.province}
                                      value={validation?.values?.city}
                                      onChange={e => handleCityChange(e)}
                                      options={cityList}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Date of birth
                                    </Label>
                                    <Input
                                      type="date"
                                      id="date_of_birth"
                                      name="date_of_birth"
                                      className="form-control default-search-input d-flex justify-content-between"
                                      value={validation?.values?.date_of_birth}
                                      onChange={e =>
                                        handleDateChange(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Street Address
                                    </Label>
                                    <Input
                                      id="address"
                                      name="address"
                                      className="form-control"
                                      placeholder="Enter address"
                                      type="text"
                                      onChange={validation.handleChange}
                                      value={validation.values.address || ""}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Zip code
                                    </Label>
                                    <Input
                                      id="zipcode"
                                      name="zipcode"
                                      className="form-control"
                                      placeholder="Enter zipcode"
                                      type="text"
                                      onChange={validation.handleChange}
                                      value={validation.values.zipcode || ""}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label className="form-label">Gender</Label>
                                    <Select
                                      id="gender"
                                      name="gender"
                                      placeholder="Select Gender"
                                      value={validation?.values?.gender}
                                      onChange={e => handleGenderChange(e)}
                                      options={genderOptions}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Pronouns
                                    </Label>
                                    <Input
                                      id="pronouns"
                                      name="pronouns"
                                      className="form-control"
                                      placeholder="Enter pronouns"
                                      type="text"
                                      onChange={validation.handleChange}
                                      value={validation.values.pronouns || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                        <div className="flex-wrap gap-2 text-start mb-3">
                          {!inProgress ? (
                            <button
                              className="btn btn-primary w-sm waves-effect waves-light"
                              type="submit"
                              disabled={isSubmitted}
                            >
                              {username ? "Save" : "Submit"}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary w-sm waves-effect waves-light"
                              disabled
                            >
                              <Spinner
                                type="grow"
                                className="me-1 align-middle spinner-grow-sm "
                                color="light"
                              />
                              Processing...
                            </button>
                          )}
                          &nbsp; &nbsp;
                          <Button
                            type="button"
                            color="secondary"
                            className="btn-light btn"
                            disabled={isSubmitted}
                            onClick={() => {
                              tog_center()
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          )}
          {activeTab?.type === "billing" && <Transaction username={username} />}
          {activeTab?.type === "notes" && (
            <Remarks username={username} user={initialValues} />
          )}
          {activeTab?.type === "files" && (
            <UserAttachments username={username} />
          )}
        </div>
      </div>
      <DeleteCounsellor
        deleteToggle={deleteToggle}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        setDeleteRecord={setDeleteRecord}
        inProgress={inProcess}
        handleDelete={handleDelete}
      />
      <SweetAlert
        show={modal_center}
        title="The changes done will not be saved, do you really want to exit."
        info
        showCancel
        confirmBtnText="No"
        confirmBtnBsStyle="btn btn-inactive"
        cancelBtnText="Yes"
        cancelBtnBsStyle="btn btn-primary "
        onConfirm={() => {
          tog_center()
        }}
        onCancel={() => {
          goBack()
        }}
      ></SweetAlert>
    </React.Fragment>
  )
}

export default UserCreate
