import { gql } from "@apollo/client"
export const GET_LANGUAGES = gql`
  query Query($argumentsInput: ArgumentsInput) {
    GetAllLanguage(argumentsInput: $argumentsInput) {
      code
      data {
        languages {
          id
          slug
          name
        }
      }
    }
  }
`
export const GET_LANGUAGE = gql`
  query GetLanguage($createLanguageInput: createLanguageInput!) {
    getLanguage(createLanguageInput: $createLanguageInput) {
      id
      name
      status
      created_at
      updated_at
    }
  }
`
export const SUBMIT_LANGUAGE = gql`
  mutation CreateAndUpdateLanguage($createLanguageInput: createLanguageInput!) {
    createAndUpdateLanguage(createLanguageInput: $createLanguageInput) {
      name
      status
    }
  }
`

export const DEL_LANGUAGE = gql`
  mutation RemoveLanguage($createLanguageInput: createLanguageInput!) {
    removeLanguage(createLanguageInput: $createLanguageInput) {
      name
    }
  }
`
