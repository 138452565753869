import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  BreadcrumbItem,
  UncontrolledTooltip,
} from "reactstrap"
import Table from "../../../components/Common/Table"
import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useLazyQuery } from "@apollo/client"
import { GET_CLIENT } from "../gql/clientGql"
import "react-toastify/dist/ReactToastify.css"
import { useParams, Link, useHistory } from "react-router-dom"
import style from "../style.module.scss"
import {
  capitalizeFirstLetter,
  sortCaret,
  convertUTCToLocal,
  formatPhoneNumber,
} from "../../../functions/functions"
import { useSelector } from "react-redux"
import moment from "moment"
import Loader from "../../../components/Common/Loader"
import clientAge from "../../../assets/images/details-icons/client-age.png"

function ClientDetails() {
  let { id } = useParams()
  const decodedId = id && atob(id);
  id = decodedId;
  const userData = useSelector(state => state.Login?.user?.user)
  const history = useHistory()
  const [clientDetails, setClientDetails] = useState({})
  const [appointments, setAppointments] = useState([])
  const [isActive, setIsActive] = useState(false)
  const [isSpinner, setIsSpinner] = useState(false)

  //*********************************** */

  const [getClientDetail, clientResponse] = useLazyQuery(GET_CLIENT, {
    fetchPolicy: "no-cache",
  })

  React.useEffect(() => {
    if (id) {
      getClientDetail({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [])

  React.useEffect(() => {
    if (
      id &&
      clientResponse?.data &&
      clientResponse?.data?.getPlan &&
      clientResponse?.data?.getPlan?.data
    ) {
      setIsSpinner(false)
      setClientDetails(clientResponse?.data?.getPlan?.data?.plan?.client)
      setAppointments(clientResponse?.data?.getPlan?.data?.plan?.appointments)
    } else {
      setIsSpinner(true)
      setClientDetails({})
    }
  }, [clientResponse.data])

  const columns = [
    {
      text: "ID",
      dataField: "id",
      width: 150,
    },
    {
      text: "Counsellor Name",
      dataField: "first_name",
      width: 270,
      style: {
        minWidth: 150,
      },
      hidden: userData.role.id === 2 ? true : false,
      formatter: fullNameFun,
      sortCaret: sortCaret,
    },
    {
      text: "Meeting Type",
      dataField: "appointment_type",
      width: 270,
      style: {
        minWidth: 150,
      },
    },
    {
      text: "Date",
      dataField: "utc_datetime",
      width: 270,
      sortCaret: sortCaret,
      style: {
        minWidth: 150,
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const date = convertUTCToLocal(row.utc_datetime)
        return <p className="m-0">{moment(date).format("DD-MMM-yyyy")}</p>
      },
    },
    {
      text: "Time",
      dataField: "time",
      width: 270,
      style: {
        minWidth: 150,
      },
      sortCaret: sortCaret,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const date = convertUTCToLocal(row.utc_datetime)
        return <p className="m-0">{moment(date).format("hh:mm A")}</p>
      },
    },
    {
      text: "Duration (in mins)",
      dataField: "duration",
      width: 270,
      sortCaret: sortCaret,
      style: {
        minWidth: 150,
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
    },
  ]

  function fullNameFun(value, row, index, field) {
    const name = row?.therapist_calendar?.name
      ? row?.therapist_calendar?.name
      : " "
    return capitalizeFirstLetter(name)
  }

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="d-flex justify-content-left">
        <button
          onClick={() => handleShowDetailsClick(row)}
          className="px-3 btn btn-primary cursor-pointer"
        >
          View
        </button>
      </div>
    )
  }

  const handleShowDetailsClick = row => {
    const encodedId = btoa(row.id);
    history.push(`/appointments/${encodedId}/?client=direct`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={false}>
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle}  card-title-main`}>
                    Client Details
                  </h4>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/direct-clients">Client</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">Client Details</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {isSpinner ? (
            <Row>
              <Col className="col-12 user-role-create-height">
                <div className="spinner">
                  <Loader />
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xl={12}>
                <Card className="light-green-bg-card-table">
                  <CardBody>
                    {clientDetails?.first_name && (
                      <div className="d-flex gap-5 align-items-center">
                        <CardTitle className="h4">
                          <h4 className="card-title-main">
                            {capitalizeFirstLetter(
                              clientDetails?.first_name
                                ? clientDetails?.first_name
                                : " "
                            )}{" "}
                            {capitalizeFirstLetter(
                              clientDetails?.last_name
                                ? clientDetails?.last_name
                                : " "
                            )}
                          </h4>
                        </CardTitle>
                      </div>
                    )}

                    {clientDetails?.email && (
                      <div className="d-flex gap-2 align-items-center">
                        <i className="color-dark-green mdi mdi-email font-size-18  m-0 mt-2"></i>
                        <p className="card-para-main m-0 mt-2">
                          {clientDetails?.email}
                        </p>
                      </div>
                    )}

                    {clientDetails?.mobile_number && (
                      <div className="d-flex gap-2 align-items-center">
                        <i className="mdi mdi-phone font-size-18 m-0 mt-1 color-dark-green"></i>
                        <p className="card-para-main m-0 mt-2">
                          {formatPhoneNumber(clientDetails?.mobile_number)}
                        </p>
                      </div>
                    )}

                    {clientDetails?.age && (
                      <div className="d-flex gap-2">
                        <img
                          src={clientAge}
                          height={16}
                          width={16}
                          className="mt-2"
                        />
                        <p className="card-title-desc m-0 mt-2">
                          {clientDetails?.age}
                          {" Years"}
                        </p>
                      </div>
                    )}

                    <h4 className="card-title client-details-appointment-heading-mt-30">
                      Appointment Details
                    </h4>
                    <hr />
                    {appointments && (
                      <div className="table-custom-redesign">
                        <Table
                          data={appointments}
                          columns={columns}
                          noDataIndication={"No Data Found"}
                          isSearch={false}
                          isPagination={false}
                          isStriped={false}
                          isHover={false}
                          isBordereded={false}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ClientDetails
