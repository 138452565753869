import {
    GET_CLIENT_APPOINTMENTS,
    GET_CLIENT_APPOINTMENTS_DETAILS,
    SET_CLIENT_APPOINTMENTS,
    UPDATE_CLIENT_APPOINTMENTS_DETAILS,
    SET_EXPORTED_INVOICE,
    UPDATE_PREVIEW,
    DELETE_EXPORTED_INVOICE,
    DELETE_ALL_EXPORTED_INVOICE,
    UPDATE_PREVIEW_ALL_DATA,
    SET_RECIEPT_PREVIEW_DATA
} from "./actionTypes";

export const getClientAppointments = clientAppointments => ({
    type: GET_CLIENT_APPOINTMENTS,
    payload: clientAppointments,
});

export const getClientAppointmentDetails = clientAppointmentDetails => ({
    type: GET_CLIENT_APPOINTMENTS_DETAILS,
    payload: clientAppointmentDetails
});

export const setClientSelectedAppointments = (id, selectedList) => ({
    type: SET_CLIENT_APPOINTMENTS,
    payload: {
        id,
        selectedList,
    },
});

export const updatePreviewData = (parentId, childIndex, data) => ({
    type: UPDATE_PREVIEW,
    payload: {
        parentId: parentId,
        childIndex: childIndex,
        ...data,
    },
});
export const updatePreviewAllData = (parentId, childIndex, data) => ({
    type: UPDATE_PREVIEW_ALL_DATA,
    payload: {
        parentId: parentId,
        childIndex: childIndex,
        ...data,
    },
});

export const updateClientAppointmentsDetails = (parentId, childIndex, data) => {
    return {
        type: UPDATE_CLIENT_APPOINTMENTS_DETAILS,
        payload: {
            parentId: parentId,
            childIndex: childIndex,
            ...data
        },
    }
};

export const setExportedInvoices = exportedInvoice => ({
    type: SET_EXPORTED_INVOICE,
    payload: exportedInvoice,
});

export const deleteExportedInvoices = deletedExportedInvoice => ({
    type: DELETE_EXPORTED_INVOICE,
    payload: deletedExportedInvoice,
});

export const deleteAllExportedInvoices = () => ({
    type: DELETE_ALL_EXPORTED_INVOICE,
});

export const setRecieptPreviewData= data => ({
    type: SET_RECIEPT_PREVIEW_DATA,
    payload: data,
});
