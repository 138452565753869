import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, withRouter } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { GET_PERMISSIONS_LIST } from "../../pages/Organizations/gql/Gql"
import { useLazyQuery } from "@apollo/client"
import { LINKED_ACCOUNT_STATUS } from "../../pages/Appointments/gql/Gql"

const SidebarContent = props => {
  const ref = useRef()
  const userData = useSelector(state => state.Login?.user?.user)

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const [getPermissionOrgWise, getPermissionOrgWiseList] = useLazyQuery(
    GET_PERMISSIONS_LIST,
    {
      fetchPolicy: "no-cache",
    }
  )
  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null

  useEffect(() => {
    if (userData) {
      getPermissionOrgWise({
        variables: {
          argumentsInput: {
            role_id: Number(userData?.role.id),
            organization_id: orgData ? orgData.id : 1,
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data &&
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role &&
      getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role
        ?.rolePermissions
    ) {
      const moduleList =
        getPermissionOrgWiseList?.data?.getRolePermissionList?.data?.role
          ?.rolePermissions
      const slugArray = moduleList.map(permission => permission.method.slug)
      localStorage.setItem("userPermission", JSON.stringify(slugArray))
    }
  }, [getPermissionOrgWiseList.data])

  const pData = localStorage.getItem("userPermission")
  let userPermission = pData ? JSON.parse(pData) : []

  const encodedAccountData = localStorage.getItem("accountData")
  const accountStatus = encodedAccountData
    ? JSON.parse(atob(encodedAccountData))
    : null

  const [accountDetailsStatus, setAccountDetailsStatus] = useState()

  const [getAccountStatus, getAccountStatusRes] = useLazyQuery(
    LINKED_ACCOUNT_STATUS,
    { fetchPolicy: "no-cache" }
  )

  useEffect(() => {
    if (accountStatus) {
      getAccountStatus({
        variables: {
          accountId: accountStatus?.external_account_id,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      getAccountStatusRes?.data?.getStripeAccountStatus?.status &&
      getAccountStatusRes?.data?.getStripeAccountStatus?.data
    ) {
      const allData =
        getAccountStatusRes?.data?.getStripeAccountStatus?.data?.statusObj
      const accStatus = allData?.details_submitted
      setAccountDetailsStatus(accStatus)
    }
  }, [getAccountStatusRes.data])

  return (
    <React.Fragment>
      <SimpleBar
        ref={ref}
        className="sidebar-menu-scroll sidebar-mx-height-100"
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">General </li> */}
            {userData.role.id == 1 || userData.role.id == 2 ? (
              <li>
                <NavLink
                  activeclassname="mm-active"
                  exact={true}
                  to="/dashboard"
                  className="waves-effect"
                >
                  <i className="uil-home-alt"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {userData.role.slug.toLowerCase() !== "superadmin" &&
            userPermission.includes("appointments") ? (
              <li>
                <NavLink
                  activeclassname="mm-active"
                  to="/appointments"
                  className=" waves-effect"
                >
                  <i className="uil-chat-bubble-user"></i>
                  <span>Appointments</span>
                </NavLink>
              </li>
            ) : null}
            {userPermission.includes("counsellors") && (
              // <li>
              //   <NavLink
              //     activeclassname="mm-active"
              //     to="/counsellors"
              //     className=" waves-effect"
              //   >
              //     <i className="uil-user-arrows"></i>
              //     <span>Staff</span>
              //   </NavLink>
              // </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil-user-arrows"></i>
                  <span>{"Staff"}</span>
                </Link>
                <ul className="sub-menu sub-menu-list-dashboard">
                  <>
                    <li>
                      <NavLink
                        activeclassname="mm-active"
                        to="/counsellors"
                        className="waves-effect"
                      >
                        <i className="uil-user-arrows"></i>
                        <span>{"Counsellors"}</span>
                      </NavLink>
                    </li>
                    {userPermission.includes("counsellor-pay") && (
                    <li>
                      <NavLink
                        activeclassname="mm-active"
                        to="/counsellor-pay"
                        className="waves-effect"
                      >
                        <i className="mdi mdi-cash"></i>
                        <span>
                          {userData.role.id === 2 ? "My Pay" : "Staff Pay"}
                        </span>
                      </NavLink>
                    </li>
                  )}
               
                  </>
                </ul>
              </li>
            )}
            {/* ###############################################################INVOICE MANAGMENT########################################### */}
            {userPermission.includes("my-clients") && (
              <li>
                <NavLink
                  activeclassname="mm-active"
                  to="/my-clients"
                  className=" waves-effect"
                >
                  <i className="uil-users-alt"></i>
                  <span>My Clients</span>
                </NavLink>
              </li>
            )}
            {userPermission.includes("invoice-management") && (
              <li>
                <NavLink
                  activeclassname="mm-active"
                  to="/invoice-management"
                  className=" waves-effect"
                >
                  <i className="uil-invoice"></i>
                  <span>Invoices</span>
                </NavLink>
              </li>
            )}

            {/* {userPermission.includes("counsellor-pay") && (
              <li>
                <NavLink
                  activeClassName="mm-active"
                  to="/counsellor-pay"
                  className="waves-effect"
                >
                  <i className="uil-user-arrows"></i>
                  <span>
                    {userData.role.id === 2 ? "My Pay" : "Staff Pay"}
                  </span>
                </NavLink>
              </li>
            )} */}

        

            {userPermission.includes("receipt-management") && (
              <li>
                <NavLink
                  activeclassname="mm-active"
                  exact={true}
                  to="/receipt-management"
                  className="waves-effect"
                >
                  <i className="uil-file-alt"></i>
                  <span>Receipt Management</span>
                </NavLink>
              </li>
            )}
            {userPermission.includes("my-pay") && (
              <li>
                <NavLink
                  activeclassname="mm-active"
                  to="/counsellor-pay"
                  className="waves-effect"
                >
                  <i className="mdi mdi-cash"></i>
                  <span>
                    {userData.role.id === 2 ? "My Pay" : "Staff Pay"}
                  </span>
                </NavLink>
              </li>
            )}

            {userData.role.slug.toLowerCase() == "superadmin" && (
              <>
                <li>
                  <NavLink
                    activeclassname="mm-active"
                    to="/organizations"
                    className=" waves-effect"
                  >
                    <i className="mdi mdi-bank"></i>

                    <span>{"Organizations"}</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    activeclassname="mm-active"
                    to="/organizationstatus"
                    className=" waves-effect"
                  >
                    <i className="mdi mdi-format-list-bulleted"></i>

                    <span>{"Organization Status"}</span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    activeclassname="mm-active"
                    to="/user-permission"
                    className=" waves-effect"
                  >
                    <i className="mdi mdi-account"></i>

                    <span>User Permissions</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="mm-active"
                    to="/orgnization-users"
                    className=" waves-effect"
                  >
                    <i className="mdi mdi-account-multiple"></i>

                    <span>Organization Users</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="mm-active"
                    to="/orgnization-settings"
                    className=" waves-effect"
                  >
                    <i className="mdi mdi-cog"></i>

                    <span>Settings</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="mm-active"
                    to="/transactions"
                    className=" waves-effect"
                  >
                    <i className="mdi mdi-bank-transfer"></i>
                    <span>Transactions</span>
                  </NavLink>
                </li>
              </>
            )}

            {/* ########################## USERS ############################ */}
            <li>
                    {userData?.role?.id === 1 && <Link to="/#" className="has-arrow waves-effect">
                      <i className="uil-house-user"></i>
                      <span>{"Users"}</span>
                    </Link>}
              <ul className="sub-menu sub-menu-list-dashboard">
                <>
                  {userPermission.includes("users") && (
                    <li>
                      <Link
                        activeclassname="mm-active"
                        to="/users"
                        className=" waves-effect"
                      >
                        <i className="uil-user-circle"></i>
                        <span>Users</span>
                      </Link>
                    </li>
                  )}
                  {userPermission.includes("users") && (
                    <li>
                      <Link
                        activeclassname="mm-active"
                        to="/trash-users"
                        className=" waves-effect"
                      >
                        <i className="mdi mdi-trash-can-outline font-size-20"></i>
                        <span>Trash Users</span>
                      </Link>
                    </li>
                  )}
                  {userPermission.includes("user-groups") && (
                    <li>
                      <NavLink
                        activeclassname="mm-active"
                        to="/user-groups"
                        className="waves-effect"
                      >
                        <i className="uil-users-alt"></i>
                        <span>{"User Groups"}</span>
                      </NavLink>
                    </li>
                  )}
                </>
              </ul>
            </li>
            {/* ########################## CLIENTS DETAILS ############################ */}

            <>
              <li>
                {(userData?.role?.id == 1 || userData?.role?.id == 2) && <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil-book-reader"></i>
                  <span>{"Clients"}</span>
                </Link>}
                <ul className="sub-menu sub-menu-list-dashboard">
                  <>
                    {userPermission.includes("direct-clients") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/direct-clients"
                          className=" waves-effect"
                        >
                          <i className="uil-users-alt"></i>
                          <span>Direct Clients</span>
                        </NavLink>
                      </li>
                    )}
                    {userPermission.includes("non-direct-clients") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/non-direct-clients"
                          className="waves-effect"
                        >
                          <i className="uil-user-times"></i>
                          <span>Non Direct Clients</span>
                        </NavLink>
                      </li>
                    )}
                    {userData?.role?.id == 1 && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/direct-non-direct"
                          className="waves-effect"
                        >
                          <i className="uil-users-alt"></i>
                          <span>Direct x Non Direct</span>
                        </NavLink>
                      </li>
                    )}
                    
                    {userPermission.includes("bad-fit") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/bad-fit"
                          className=" waves-effect"
                        >
                          <i className="mdi mdi-tune-vertical-variant"></i>

                          <span>Bad Fit List</span>
                        </NavLink>
                      </li>
                    )}
                  </>
                </ul>
              </li>
            </>
            {/* ########################## MASTERS ########################## */}
            {[
              "languages",
              "counselling-types",
              "areas",
              "blogs",
              "location",
              "therapies",
              "concern",
              "payment-types",
              "appointment-type",
            ].some(permission => userPermission.includes(permission)) && (
              <li
                className={`${
                  userData.role.role_name !== "Client" &&
                  userData.role.slug.toLowerCase() !== "superadmin"
                    ? ""
                    : "d-none"
                }`}
              >
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil-layer-group"></i>
                  <span>{"Masters"}</span>
                </Link>
                <ul className="sub-menu sub-menu-list-dashboard">
                  <>
                    {userData.role.id === 1 &&
                      accountStatus &&
                      accountDetailsStatus && (
                        <li>
                          <NavLink
                            activeclassname="mm-active"
                            exact={true}
                            to="/transactions"
                            className="waves-effect"
                          >
                            <span className="mdi mdi-bank-transfer font-size-20"></span>
                            <span className="mx-2">Transactions</span>
                          </NavLink>
                        </li>
                      )}
                    {userPermission.includes("languages") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          exact={true}
                          to="/languages"
                          className="waves-effect"
                        >
                          <i className="fas fa-language"></i>
                          <span>Languages</span>
                        </NavLink>
                      </li>
                    )}

                    {userPermission.includes("counsellor-type") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/counsellor-type"
                          className="waves-effect"
                        >
                          <i className="uil uil-sitemap"></i>
                          <span>{"Counselling Type"}</span>
                        </NavLink>
                      </li>
                    )}
                    {userPermission.includes("appointment-type") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/appointment-type"
                          className=" waves-effect"
                        >
                          <i className="mdi mdi-calendar-account"></i>

                          <span>Appointment Types</span>
                        </NavLink>
                      </li>
                    )}
                    {userPermission.includes("areas") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/areas"
                          className="waves-effect"
                        >
                          <i className="mdi mdi-map-marker-distance"></i>
                          <span>{"Areas"}</span>
                        </NavLink>
                      </li>
                    )}
                    {userPermission.includes("location") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/location"
                          className="waves-effect"
                        >
                          <i className="mdi mdi-google-maps"></i>
                          <span>{"Locations"}</span>
                        </NavLink>
                      </li>
                    )}
                    {userPermission.includes("therapies") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/therapies"
                          className="waves-effect"
                        >
                          <i className="mdi mdi-doctor"></i>
                          <span>{"Therapies"}</span>
                        </NavLink>
                      </li>
                    )}
                    {userPermission.includes("concern") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/concern"
                          className="waves-effect"
                        >
                          <i className="mdi mdi-medical-bag"></i>
                          <span>{"Concerns"}</span>
                        </NavLink>
                      </li>
                    )}
                    {userPermission.includes("payment-types") && (
                      <li>
                        <NavLink
                          activeclassname="mm-active"
                          to="/payment-types"
                          className="waves-effect"
                        >
                          <i className="mdi mdi-cash-usd"></i>
                          <span>{"Payment Types"}</span>
                        </NavLink>
                      </li>
                    )}
                  </>

                  {userPermission.includes("blogs") && (
                    <li>
                      <NavLink
                        activeclassname="mm-active"
                        to="/blogs"
                        className="waves-effect"
                      >
                        <i className="mdi mdi-file-document-edit-outline"></i>
                        <span>{"Blogs"}</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {/* ########################## Others ############################ */}
            {/* <li>
              <NavLink to="/clients" className=" waves-effect">
              <i className="uil-users-alt"></i>
                <span>Clients</span>
              </NavLink>
            </li> */}
            {/* {userData.role.id == 1 &&
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <img className="sidebar-menu-svg" src={PERMISSION} />
                  <span>Permissions</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/user-roles">Roles</Link>
                  </li>
                  <li>
                    <Link to="/group-permissions">Permissions List</Link>
                  </li>
                </ul>
              </li>
            } */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}

export default withRouter(SidebarContent)
