import PropTypes from "prop-types"
import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import { makeStyles } from "@material-ui/core"
import millify from "millify"

//Import Image
import clients from "../../../assets/images/dashboard-icons/clients.svg"
import counselors from "../../../assets/images/dashboard-icons/counselors.svg"
import appointments from "../../../assets/images/dashboard-icons/appointments.svg"
import billable from "../../../assets/images/dashboard-icons/billable.svg"

const useStyles = makeStyles(theme => ({
  CardPadding: {
    padding: "24px 20px 0px 20px",
  },
  CountUp: {
    fontWeight: 600,
    fontSize: "1.5rem",
    fontFamily: "IBM Plex Sans Condensed",
    lineHeight: "31px",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    whiteSpace: "nowrap",
    fontWeight: 500,
    fontSize: "1rem",
    LineHeight: "20.8px",
    color: "#74788D",
    margin: 0,
    fontStyle: "normal",
    margin: "0.25rem 0 0 0",
  },
  description: {
    flex: "1 0 auto",
    fontFamily: "IBM Plex Sans",
    fontSize: "14.4px",
    fontWeight: 400,
    color: "#74788D",
    lineHeight: "19px",
    margin: "0.25rem 0 0 0",
  },
}))

const MiniWidget = ({ dashboardData, isLoader }) => {
  const reports = [
    {
      id: 1,
      arrowIcon:
        dashboardData.clientPercentageStatus == "Negative"
          ? "mdi mdi-arrow-down-bold"
          : "mdi mdi-arrow-up-bold",
      title: "Clients",
      icon: clients,
      value: (dashboardData.total_clients && dashboardData.total_clients) || 0,
      prefix: "",
      suffix: "",
      badgeValue: dashboardData.clientPercentage
        ? `${dashboardData.clientPercentage}%`
        : "",
      decimal: 0,
      color:
        dashboardData.clientPercentageStatus == "Negative"
          ? "danger"
          : "success",
      desc: "Total no. of client and % increase monthly.",
    },
    {
      id: 2,
      arrowIcon:
        dashboardData.counsellorPercentageStatus == "Negative"
          ? "mdi mdi-arrow-down-bold"
          : "mdi mdi-arrow-up-bold",
      title: "Counsellors",
      icon: counselors,
      value: dashboardData.total_counsellors || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      badgeValue: dashboardData.counsellorPercentage
        ? `${dashboardData.counsellorPercentage}%`
        : "",
      color:
        dashboardData.counsellorPercentageStatus == "Negative"
          ? "danger"
          : "success",
      desc: "Total no. of counsellor and % increase monthly.",
    },
    {
      id: 3,
      arrowIcon:
        dashboardData.appointmentPercentageStatus == "Negative"
          ? "mdi mdi-arrow-down-bold"
          : "mdi mdi-arrow-up-bold",
      title: "Appointments",
      icon: appointments,
      value: dashboardData.total_appoinments_count || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      badgeValue: dashboardData.appointmentPercentage
        ? `${dashboardData.appointmentPercentage}%`
        : "",
      color:
        dashboardData.appointmentPercentageStatus == "Negative"
          ? "danger"
          : "success",
      desc: "Total no. of appointment and % increase monthly.",
    },
    {
      id: 4,
      arrowIcon:
        dashboardData.Billing_percentage_status == "Negative"
          ? "mdi mdi-arrow-down-bold"
          : "mdi mdi-arrow-up-bold",
      title: "Total Revenue",
      icon: billable,
      value: dashboardData.total_billable_amount
        ? Math.floor(dashboardData.total_billable_amount)
        : 0,
      decimal: 0,
      prefix: "$",
      suffix: "",
      badgeValue: dashboardData.Billing_percentage
        ? `${dashboardData.Billing_percentage}%`
        : "",
      color:
        dashboardData.Billing_percentage_status == "Negative"
          ? "danger"
          : "success",
      desc: "Total no. of Revenue and % increase monthly.",
    },
  ]

  const classes = useStyles()

  return (
    <React.Fragment>
      {dashboardData &&
        reports.map((report, key) => (
          <Col md={6} xl={3} key={key} className=" mb-4">
            <Card className="h-100">
              <CardBody className={classes.CardPadding}>
                <div className="mt-0">
                  <img src={report.icon} width="64px" height="64px" />
                </div>
                <div>
                  <h4
                    className="mt-1 mb-0"
                    title={report.value?.toLocaleString("en-IN")}
                  >
                    <span className="mini-widget-report-prefix">
                      {`${report.prefix} ${millify(report.value)}`}
                    </span>
                  </h4>
                  <p className={classes.title}>
                    {report.title}
                    <span className={"text-" + report.color + " mx-1"}>
                      <i className={report.arrowIcon + " me-1"}></i>
                      {report.badgeValue}
                    </span>{" "}
                  </p>
                </div>
                <p className={classes.description}>{report.desc}</p>
              </CardBody>
            </Card>
          </Col>
        ))}
    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array,
}
