import {
    SET_CLIENT_APPOINTMENTS,
} from "./actionTypes";

const INIT_STATE = {
    selectedClientAppointments: [],
};

const receiptManagement = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_CLIENT_APPOINTMENTS:
            const appointments = Object.assign([], state.selectedClientAppointments)
            const index = appointments.findIndex(n => n.id === action.payload.id)
            // console.log("index", index, appointments)
            if (index >= 0) {
                return {
                    ...state,
                    selectedClientAppointments: Object.assign([], appointments, {
                        [index]: Object.assign({}, appointments[index], action.payload)
                    })
                }
            }
            return {
                ...state,
                selectedClientAppointments: [
                    ...state.selectedClientAppointments,
                    Object.assign({}, action.payload)
                ]
            };
        // return {
        //     ...state,
        //     selectedClientAppointments: {
        //         ...state.selectedClientAppointments,
        //         ...action.payload
        //     }
        // };
        default:
            return state;
    }
};

export default receiptManagement;