import {
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Table from "../../components/Common/Table"
import moment from "moment"
import React, { useCallback, useEffect, useState, useRef } from "react"
import style from "./style.module.scss"
import { useSelector } from "react-redux"
import debounce from "lodash.debounce"
import CustomModal from "../../components/Modal/Modal"
import { useFormik } from "formik"

import resolved from "../../assets/images/legend-icons/resolved.png"

import * as Yup from "yup"

const Misc = ({
  miscList,
  handleDeleteMisc,
  setMiscList,
  futurPaidList,
  setFuturPaidList,
  updateMutations,
  setInvoiceData
}) => {
  const [list, setList] = useState(miscList)
  useEffect(() => {
    setList(miscList) // when check on features list
    setInvoiceData(prev=>({...prev,...{misc:miscList}}))
  }, [JSON.stringify(miscList)])

  const userRole = useSelector(state => state.Login?.user?.user?.role)
  const handleFuturPaidCheckbox = (e, row) => {
    setTimeout(() => {
      setList(prev => {
        return prev.filter(item => {
          if (item.id == row.id) {
            return false
          }
          return item
        })
      })
      if (futurPaidList.length > 0) {
        setFuturPaidList(prev => {
          return [...prev, row]
        })
      } else {
        setFuturPaidList([row])
      }
      updateMutations(row.id, { is_misc: false })
    }, 1000)
  }
  useEffect(() => {
    setMiscList(list)
  },[JSON.stringify(list)])
  const columns = [
    {
      text: "",
      dataField: "checkbox",
      width: 100,
      formatter: (cell, row) => {
        return (
          <span>
            <input
              type="checkbox"
              className={row.new ? "d-none" : ""}
              data-title={row.id}
              onClick={e => handleFuturPaidCheckbox(e, row, false)}
            />
          </span>
        )
      },
      hidden: userRole.role_name == "Admin" ? false : true,
    },
    {
      text: "Item",
      dataField: "index",
      width: 150,
      formatter: (cell, row, index) => {
        return <span>{index + 1}</span>
      },
    },
    {
      text: "Description",
      dataField: "description",
      width: 150,
      formatter: (cell, row) => {
        return <span>{cell}</span>
      },
    },
    {
      text: "Total",
      dataField: "counsellor_rate",
      width: 150,
      formatter: (cell, row) => {
        return <span>${Number(cell).toFixed(2)}</span>
      },
    },
    {
      text: " ",
      dataField: "delete",
      width: 150,
      formatter: (cell, row) => {
        return (
          <i
            className="mdi mdi mdi-delete pointer invoice-list-fsize-20"
            onClick={() => handleDeleteMisc(row.id, row)}
          ></i>
        )
      },
    },
  ]
  const handleAddMiscData = () => {
    setToggle(!isModal)
  }

  const [isModal, setToggle] = useState(false)
  const [initialValues, setInitialValues] = useState({
    description: "",
    total: 0,
  })
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      description: Yup.string().required(),
      total: Yup.number().required("total can not be empty").min(0),
    }),
    onSubmit: values => {
      handleSubmit(values, validation)
      
    },
  })
  const handleSubmit = (values, validation) => {
    let listData = list
    listData.push({
      id: miscList.length,
      ...values,
      counsellor_rate: values.total,
      new: true,
    })
    setList(listData)
    validation.setFieldValue("description", "")
    validation.setFieldValue("total", "")
    setToggle(state => !isModal)
    setMiscList(listData)
    setInitialValues({
      description: "",
      total: 0,
    }) 
  }
  const handleToggle = () => {
    setToggle(state => !isModal)
  }
  return (
    <React.Fragment>
      <>
        <div
          className={`${style.titleInvoice} d-flex justify-content-between align-items-center basic-card-title`}
        >
          Misc / Resolve
          <div className={`legend-list text-end`}>
            Legend: <img src={resolved} width="20px" height="20px" /> Resolved,
            Outstanding item
          </div>
        </div>
        <Row>
          <Col className="col-12">
            <Card className="light-green-bg-card-table">
              <CardBody className="p-0 table-custom-redesign">
                <Table
                  data={list || []}
                  columns={columns}
                  noDataIndication={"No Data Found"}
                  isPagination={false}
                  isSearch={false}
                  isStriped={false}
                  isHover={false}
                  isBordereded={false}
                />
                <div className="text-center">
                  <button
                    className={` btn btn-primary mb-3`}
                    type="button"
                    onClick={handleAddMiscData}
                  >
                    <i className={"uil-plus"} />
                    <span className="ml-1 ">Add</span>
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CustomModal
          open={isModal}
          validation={validation}
          onToggle={handleToggle}
        />
      </>
    </React.Fragment>
  )
}
export default Misc
