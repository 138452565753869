import React, { useState, useEffect } from "react"
import {
  GET_INVOICE_NUMBER,
  GET_PREVIEW_DATA,
  GET_PREVIEW_INVOICE_NUMBER,
} from "../gql/gql"
import { useLazyQuery } from "@apollo/client"
import { toast, ToastContainer } from "react-toastify"
import {
  convertLocaltoUTC,
  downloadPDF,
  formatCurrency,
} from "../../../../functions/functions"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { setExportedInvoices } from "../../../../store/actions"
import cloudIcon from "../../../../assets/images/dashboard-icons/cloud-download.png"
import { Button } from "reactstrap"

function ExportInvoice(props) {
  const dispatch = useDispatch()
  const { parentRow, rowValue, row, index, field, startDate, endDate } = props
  const [exportPreviewData, setExportPreviewData] = useState(null)

  //************************** Export invoice number ***************************** */

  const [getInvoiceData, invoiceDataResponse] = useLazyQuery(
    GET_INVOICE_NUMBER,
    { fetchPolicy: "no-cache" }
  )
  const [getInvoiceNumber, InvoiceNumberResponse] = useLazyQuery(
    GET_PREVIEW_INVOICE_NUMBER,
    { fetchPolicy: "no-cache" }
  )

  let downloadDialogTriggered = false

  function getInvoiceDataFun(totalBilled, margedData) {
    getInvoiceData({
      variables: {
        argumentsInput: {
          calendar_id: parseInt(row.calendar_id),
          client_id: parseInt(row.client_id),
          payment_type_id: parseInt(parentRow.id),
          total_billed: totalBilled,
          billed_sessions: totalBilled,
        },
      },
    }).then(() => {
      let url = `${process.env.REACT_APP_API_ENDPOINT}/mail/receiptPdf`
      let orgData = JSON.parse(localStorage.getItem("orgData"))
      const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
      const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,


        },
        body: JSON.stringify(margedData),
      }
      fetch(url, requestOptions)
        .then(res => {
          if (res.status == "201" && !downloadDialogTriggered) {
            downloadDialogTriggered = true
            return res.json()
          }
        })
        .then(res => {
          if (downloadDialogTriggered) {
            downloadPDF(res.data.Location, res.data.Key)
          }
        })
    })
  }

  useEffect(() => {
    if (
      invoiceDataResponse.data &&
      invoiceDataResponse.data.exportInvoice &&
      invoiceDataResponse.data.exportInvoice.status &&
      invoiceDataResponse.data.exportInvoice.code === 200
    ) {
      let total_amount = 0
      exportPreviewData.paymentTypeData.forEach(element => {
        total_amount = total_amount + parseFloat(element.total)
      })

      const obj = {
        invoice_number: exportPreviewData.invoice_Number,
        claim_number:
          exportPreviewData.claim_number || exportPreviewData.claim_Number,
        claim_Number:
          exportPreviewData.claim_Number || exportPreviewData.claim_number,
        invoice_date: moment(),
        therapist_name:
          exportPreviewData.paymentTypeData.length > 0
            ? exportPreviewData.paymentTypeData[0].calendar
            : "",
        client_name:
          exportPreviewData.paymentTypeData.length > 0
            ? `${exportPreviewData.paymentTypeData[0].client.first_name} ${exportPreviewData.paymentTypeData[0].client.first_name}`
            : "",
        total: total_amount.toFixed(2),
        number_of_appointment: row.appointmentcount,
        payment_id: parseInt(parentRow.id),
        calendar_id: parseInt(row.calendar_id),
        client_id: parseInt(row.client_id),
        child_row: row,
        parent_row: parentRow,
      }
      dispatch(setExportedInvoices(obj))
    } else {
      console.log("error")
    }
  }, [invoiceDataResponse.data])

  //************************** Export invoice preview data  ***************************** */

  const [getPreviewData, PreviewDataResponse] = useLazyQuery(GET_PREVIEW_DATA, {
    fetchPolicy: "no-cache",
  })

  function getPreviewDataFun() {
    getPreviewData({
      variables: {
        argumentsInput: {
          start_date: startDate
            ? convertLocaltoUTC(startDate, "YYYY-MM-DD HH:mm:ssZ", true)
            : null,
          end_date: endDate
            ? convertLocaltoUTC(endDate, "YYYY-MM-DD HH:mm:ssZ", false, true)
            : null,
          payment_id: parseInt(parentRow.id),
          calendar_id: parseInt(row.calendar_id),
          user_id: parseInt(row.client_id),
        },
      },
    })
  }

  //************************** functions ***************************** */

  const handleClickExport = () => {
    toast.info("Generating PDF, Please wait for some time")
    getPreviewData({
      variables: {
        argumentsInput: {
          start_date: startDate
            ? convertLocaltoUTC(startDate, "YYYY-MM-DD HH:mm:ssZ", true)
            : null,
          end_date: endDate
            ? convertLocaltoUTC(endDate, "YYYY-MM-DD HH:mm:ssZ", false, true)
            : null,
          payment_id: parseInt(parentRow.id),
          calendar_id: parseInt(row.calendar_id),
          user_id: parseInt(row.client_id),

        },
      },
    }).then(PreviewDataResponse => {
      if (
        PreviewDataResponse.data &&
        PreviewDataResponse.data.getInvoicePreview &&
        PreviewDataResponse?.data?.getInvoicePreview?.code === 200
      ) {
        const previewData = PreviewDataResponse?.data?.getInvoicePreview?.data
        const therapistName =
          previewData.paymentTypeData.length > 0
            ? previewData.paymentTypeData[0].calendar
            : ""
        const therapistEmail =
          previewData.paymentTypeData.length > 0
            ? previewData.paymentTypeData[0].email
            : ""

        let total_rate = 0
        let total_gst = 0
        let total_amount = 0

        previewData.paymentTypeData.forEach(element => {
          total_rate += parseFloat(element.price)
          total_gst += parseFloat(element.gst)
          total_amount += parseFloat(element.total)
        })

        let dataUpdated = previewData.paymentTypeData.map((item, index) => {
          let client = {
            first_name: item.first_name,
            last_name: item.last_name,
          }
          let payment_type = {
            name: row.payment_name,
            id: item.payment_type.id,
          }
          item.client = {
            first_name: item.first_name,
            last_name: item.last_name,
          }
          item.gst = parseFloat(item.gst)
          item.type = row.name
          return {
            ...item,
            ...{ client: client },
            ...{ payment_type: payment_type },
          }
        })

        const margedData = {
          calendar: {
            name: therapistName,
            id: parseInt(row.calendar_id),
            email: therapistEmail,
            total_sessions: previewData.paymentTypeData.length,
            total_billed:
              previewData.total_billed == "NaN"
                ? 0
                : parseInt(previewData.total_billed),
            claim_Number: previewData.claim_Number
              ? previewData.claim_Number
              : "",
            claim_number: previewData.claim_Number
              ? previewData.claim_Number
              : "",
            billed_sessions: previewData.paymentTypeData.length,
            is_invoice: true,
            invoice_number: previewData.invoice_Number,
            total_rate: total_rate.toFixed(2),
            total_Amount: total_amount.toFixed(2),
            total_gst_Amount: total_gst.toFixed(2),
            appointments: dataUpdated,
          },
        }
        getInvoiceNumber().then(InvoiceNumberResponse => {
          if (
            InvoiceNumberResponse.data &&
            InvoiceNumberResponse.data.getInvoiceNumber &&
            InvoiceNumberResponse.data.getInvoiceNumber.status &&
            InvoiceNumberResponse.data.getInvoiceNumber.code === 200
          ) {
            margedData.calendar.invoice_Number = parseInt(
              InvoiceNumberResponse.data.getInvoiceNumber.data.invoice_Number
            )
            let data = PreviewDataResponse?.data?.getInvoicePreview?.data
            data.invoice_number =
              InvoiceNumberResponse.data.getInvoiceNumber.data.invoice_Number
            data.invoice_Number =
              InvoiceNumberResponse.data.getInvoiceNumber.data.invoice_Number
            setExportPreviewData(data)
            margedData.calendar.invoice_number = parseInt(
              InvoiceNumberResponse.data.getInvoiceNumber.data.invoice_Number
            )
            getInvoiceDataFun(previewData.paymentTypeData.length, margedData)
          }
        })
      } else {
        setExportPreviewData({})
      }
    })
  }

  return (
    <Button
      type="button"
      className="btn btn-primary text-capitalize"
      onClick={handleClickExport}
    >
      <img src={cloudIcon} />
      &nbsp; export
    </Button>
  )
}

export default ExportInvoice
