import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import {
  EXPAND_INVOICE_DATA_LIST,
  GET_EMAIL_TEXT_DATA,
  GET_INVOICE_DATA_LIST,
  SET_EMAIL_TEXT_DATA,
  UPDATE_EXPANDED_LIST,
} from "./gql/Gql"
import style from "./style.module.scss"
import Table from "../../components/Common/Table"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useHistory, useParams, Link, useLocation } from "react-router-dom"
import Loader from "../../components/Common/Loader"
import { toast } from "react-toastify"
import moment from "moment"
import * as Yup from "yup"
import { useFormik } from "formik"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import TableInput from "../../components/Common/TableInput"
import {
  capitalizeFirstLetter,
  convertLocaltoUTC,
  convertUTCToLocal,
  getSessionStore,
  getUrlParams,
  sessionStore,
} from "../../functions/functions"
import InvoiceTotalTable from "./InvoiceTotalTable"
import OutStandingList from "./OutStaningList"
import Misc from "./Misc"
import FuturPaidList from "./FuturePaid"
import Dialog from "../Invoicing-Receipt-Management/Preview/Dialog"
import { STORE_COUNSELLOR_PAY } from "../../store/counsellorPay/actionTypes"
import { storeCounsellorPay } from "../../store/actions"
import { Tooltip } from "@material-ui/core"
import { fetchApi } from "../../common/api"
import ExpandHtml from "./ExpandHtml"

const InvoiceList = () => {
  const userRole = useSelector(state => state.Login?.user?.user?.role)
  const [getInvoiceList, invoiceResponse] = useLazyQuery(
    GET_INVOICE_DATA_LIST,
    { fetchPolicy: "no-cache" }
  )
  const [expandInvoiceList, expandInvoiceResponse] = useLazyQuery(
    EXPAND_INVOICE_DATA_LIST,
    { fetchPolicy: "no-cache" }
  )
  const [updateExpandedList, updateExpandedListResponse] =
    useMutation(UPDATE_EXPANDED_LIST)
  const [getMailText, mailresponse] = useLazyQuery(GET_EMAIL_TEXT_DATA, {
    fetchPolicy: "no-cache",
  })
  const [setEmailContent, emailContenetRes] = useMutation(SET_EMAIL_TEXT_DATA, {
    fetchPolicy: "no-cache",
  })
  let history = useHistory()
  let { id } = useParams()
  const decodedFId = id && atob(id);
  id = decodedFId
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [startDate, setStartDate] = useState("")
  const [sharePercentage, setSharePercentage] = useState(0)
  const [therapistCalendar, setTherapistCalendar] = useState('')
  const [endDate, setEndDate] = useState("")
  const [InvoiceData, setInvoiceData] = useState([])
  const [outStanding, setOutStanding] = useState([])
  const [expanded, setExpanded] = useState([0, 1])
  const [expandedData, setExpandedData] = useState({})
  const [deletExpandedData, setDeletExpandedData] = useState({})
  const [outstaningData, setOutstaningData] = useState({})
  const [miscList, setMiscList] = useState([])
  const [futurPaidList, setFuturPaidList] = useState([])
  const [isLoader, setLoader] = useState(true)
  const [isSpinner, setIsSpinner] = useState(false)
  const [cvapID, setcvapID] = useState([])
  const [OtherDefaultID, setOtherDefaultID] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isEdit, setIsEdit] = useState({})
  const [state, setState] = useState({})
  const [initialValues, setInitialValues] = useState({
    email: "",
    title: "",
    body: "",
    name: "test.pdf",
  })
  const [isEmailModal, setEmailToggle] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  let is_current_month = moment(getUrlParams("end")).format("MM") == moment().format('MM')
  useEffect(() => {
    getMailText()
    let dates = getSessionStore("dateRange")

    if (dates && !getUrlParams("start") && !getUrlParams("end")) {
      dates = dates.split(",")
      setStartDate(dates[0])
      setEndDate(dates[1])
    } else {
      let startDate =
        convertLocaltoUTC(
          getUrlParams("start"),
          "YYYY-MM-DD HH:mm:ssZ",
          true
        ) || ""
      let endDate =
        convertLocaltoUTC(
          getUrlParams("end"),
          "YYYY-MM-DD HH:mm:ssZ",
          false,
          true
        ) || ""
      sessionStore("dateRange", `${startDate},${endDate}`)
      setStartDate(startDate)
      setEndDate(endDate)
    }
    let counsellorPay = localStorage.getItem("counsellorPay")
    counsellorPay = counsellorPay ? JSON.parse(counsellorPay) : {}
    setState(counsellorPay)
  }, [])
  useEffect(() => {
    if (userRole.id == 1) {
      setInitialValues(prev => ({
        ...prev,
        ...{ body: `${mailresponse?.data?.emailText?.emailText}` },
      }))
    }
  }, [mailresponse.data])
  const getInvoiceDetails = (counsellorID = "") => {
    let inputValues = {
      id: counsellorID || parseInt(id),
      start_date: startDate,
      end_date: endDate,
    }
    getInvoiceList({
      variables: {
        argumentsInput: inputValues,
      },
    })
  }
  React.useEffect(() => {
    if (startDate && endDate) {
      getInvoiceDetails()
    }
  }, [])
  React.useEffect(() => {
    let inputValues = {
      id: parseInt(id),
      start_date: startDate,
      end_date: endDate,
    }
    if (startDate !== "" && endDate != "") {
      getInvoiceList({
        variables: {
          argumentsInput: inputValues,
        },
      })
    }
  }, [startDate, endDate])

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }
  useEffect(() => {
    if (cvapID.length > 0) {
      cvapID.forEach(async (item, index) => {
        const calendarInput = {
          id: item.id,
          email: item.email,
          start_date: startDate,
          end_date: endDate,
          is_current_month
        }
        // aws, learn typescipt with react, live website, news
        await expandInvoiceList({
          variables: {
            argumentsInput: calendarInput,
          },
        }).then(res => {
          if (
            res?.data?.expandCounsellorsAppointments?.data?.appointments &&
            res?.data?.expandCounsellorsAppointments?.code == 200
          ) {
            let total = 0
            let cvapValue = 0
            let miscTotal = 0
            let other_price = 0
            let other_counsellor_rate = 0
            res?.data?.expandCounsellorsAppointments?.data?.appointments.forEach(
              (item, idex) => {
                if (item.top_up_amount > 0) {
                  cvapValue = item.top_up_amount
                  other_price = item.price
                  other_counsellor_rate = item.counsellor_rate
                }
                total = parseFloat(total) + parseFloat(item.counsellor_rate)
              }
            )
            let defaultArr = InvoiceData.default
            defaultArr[item.index].top_up_amount = cvapValue.toFixed(2)
            defaultArr[item.index].other_price = `$${Number(other_price).toFixed(2)}`
            defaultArr[item.index].other_counsellor_rate = `$${Number(
              other_counsellor_rate
            ).toFixed(2)}`

            defaultArr[item.index].total = total.toFixed(2)
            InvoiceData.misc.forEach((item, index) => {
              miscTotal = miscTotal + parseFloat(item.counsellor_rate)
            })
            setInvoiceData(prev => {
              return {
                ...prev,
                ...{
                  default: defaultArr,
                  sub_total: total + miscTotal,
                  total_Amount:
                    total + miscTotal - InvoiceData.service_fee_amount,
                },
              }
            })
          }
        })
      })
    }
  }, [cvapID])

  const fetchExpandedData = async (request,OtherDefaultsID, InvoiceDataDefault) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/appointment/expandCounsellorAppointmentsArray`
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined


    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,


      },
      body: JSON.stringify(request),
    }
    let allExportResponse = await fetch(url, requestOptions)
    
    let data = await allExportResponse.json()
    if (data && data.length > 0) {
      let finalArr = []
      data.forEach((subItem, index) => {
        let ItemIndex = OtherDefaultsID[index].index
        let total = 0
        let cvapValue = 0
        let miscTotal = 0
        subItem.forEach((item, i) => {
          total = parseFloat(total) + parseFloat(item.counsellor_rate)
        })

        finalArr.push({
          index: ItemIndex,
          value: total.toFixed(2),
        })
      })
      let defaultArr = InvoiceDataDefault
      finalArr.forEach(async item => {
        defaultArr[item.index] = {
          ...defaultArr[item.index],
          total: item.value,
        }
        if (data && data.length > 0) {
          let finalArr = []
          let finalCounsellorRate = []
          let finalPrice = []
          await data.forEach(async (subItem, index) => {
            let CounsellorRate = []
            let TotalPrice = []
            let ItemIndex = OtherDefaultsID[index].index
            let total = 0
            let cvapValue = 0
            let miscTotal = 0
            await subItem.forEach((item, i) => {
              if (!CounsellorRate.includes(item.counsellor_rate)) {
                CounsellorRate.push(item.counsellor_rate)
              }
              if (!TotalPrice.includes(item.price)) {
                TotalPrice.push(item.price)
              }
              total = parseFloat(total) + parseFloat(item.counsellor_rate)
            })
            CounsellorRate = CounsellorRate.map(
              item => `$${Number(item).toFixed(2)}`
            )
            TotalPrice = TotalPrice.map(item => `$${Number(item).toFixed(2)}`)

            finalArr.push({
              index: ItemIndex,
              value: total.toFixed(2),
              other_counsellor_rate: CounsellorRate.join(","),
              other_price: TotalPrice.join(","),
            })
          })
          let defaultArr = InvoiceDataDefault
          finalArr.forEach(item => {
            defaultArr[item.index] = {
              ...defaultArr[item.index],
              total: item.value,
              other_counsellor_rate: item.other_counsellor_rate,
              other_price: item.other_price,
            }
          })
          setInvoiceData(prev => {
            return { ...prev, ...{ default: defaultArr } }
          })
          setIsSpinner(false)
        }
      })
    }
  }

  const handleInvoiceResponse = async (invoiceResponse)=>{
    let { calendar } = invoiceResponse
    await calendar.default.map(item => {
        return {
          ...item,
          ...{ top_up_amount: "" },
          other_counsellor_rate: "",
          other_price: "",
        }
      })
      await setInvoiceData(calendar)
      await  setTherapistCalendar(calendar.id)
      await setSharePercentage(calendar.share_percentage)
      // handle CVAP case
      let cvapArr = []
      let OtherDefaultsID = []
      await  calendar.default.forEach((item, index) => {
        if (item?.plan?.payment_source.name == "CVAP") {
          cvapArr.push({
            id: item?.therapist_calendar?.id,
            email: item.email,
            index,
          })
        } else {
          OtherDefaultsID.push({
            id: item.therapist_calendar.id,
            email: item.email,
            index,
          })
        }
      })
      if (OtherDefaultsID.length > 0) {
        await setOtherDefaultID(OtherDefaultsID)
          let request = OtherDefaultsID.map(item => {
            return {
              ...item,
              id: item.id,
              email: item.email,
              start_date: startDate,
              end_date: endDate,
              is_current_month
            }
          });

          await fetchExpandedData(request, OtherDefaultsID, calendar.default)
      }else{
        setIsSpinner(false)
      }
      if (cvapArr.length > 0) {
        setcvapID(cvapArr)
      }
      // handle CVAP case
      setOutStanding(calendar.outstanding)
      setMiscList(calendar.misc)
      setFuturPaidList(calendar.future_paid)
      const therapistName = calendar.name ? calendar.name.trim() : " "
      const therapistNameArray = therapistName ? therapistName.split(/\s+/) : []
      const therapistFirstName =
        therapistNameArray.length > 0 ? therapistNameArray[0] : null
      const therapistLastName =
        therapistNameArray.length > 0 ? therapistNameArray[1] : null

      let dateFormate = moment(endDate).format("MMM YYYY")
      if (userRole.id == 1) {
        setInitialValues(prev => ({
          ...prev,
          ...{
            email: calendar.email,
            title: `${
              calendar.calendarPay && calendar.calendarPay.is_change_request
                ? "Updated invoice"
                : "Invoice"
            } ${dateFormate}`,
            name: `${
              calendar.calendarPay && calendar.calendarPay.is_change_request
                ? "Updated invoice"
                : "Invoice"
            } ${dateFormate} - ${therapistFirstName} ${therapistLastName}`,
          },
        }))
      } else if (userRole.id == 2) {
        setInitialValues(prev => ({
          ...prev,
          ...{
            email: calendar.email,
            title: `Change request for invoice ${dateFormate}`,
            name: `Change request for invoice ${dateFormate} - ${therapistFirstName} ${therapistLastName}`,
          },
        }))
        setIsSpinner(false)
      }

  }
  React.useEffect(() => {
    if (
      invoiceResponse.data &&
      invoiceResponse.data.counsellorAppointment &&
      invoiceResponse.data.counsellorAppointment.status &&
      invoiceResponse.data.counsellorAppointment.data
    ) {
      handleInvoiceResponse(invoiceResponse.data.counsellorAppointment.data)
    } else {
      setIsSpinner(true)
    }
  }, [invoiceResponse.data])
  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().required(),
      title: Yup.string().required(),
      body: Yup.string().required(),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      handleSubmit(values)
    },
  })
  const handleSubmit = async values => {
    const startInvoiceDate = queryParams.get("start")
    const endInvoiceDate = queryParams.get("end")
    let defaultTotal = 0
    let miscTotal = 0
    InvoiceData.default &&
      InvoiceData.default.forEach(item => {
        defaultTotal += parseFloat(item.total)
      })
    InvoiceData.misc &&
      InvoiceData.misc.forEach(item => {
        miscTotal += parseFloat(item.counsellor_rate)
      })
    InvoiceData.sub_total = parseFloat(defaultTotal + miscTotal)
    InvoiceData.total_Amount = parseFloat(
      defaultTotal + miscTotal - InvoiceData.service_fee_amount
    )

    const counselorObj = {
      ...InvoiceData,
      start_date: moment(startInvoiceDate).format("DD-MMM-YYYY"),
      end_date: moment(endInvoiceDate).format("DD-MMM-YYYY"),
    }
    let url = `${process.env.REACT_APP_API_ENDPOINT}/mail/counsellorPayPdf?email=${values.email}&subject=${values.title}&counsellor_id=${InvoiceData.id}`
    if (userRole.id == 1) {
      const is_admin_review =
        moment().month() > moment(endInvoiceDate).month() ||
        (moment().month() == moment(endInvoiceDate).month() &&
          moment().date() >= 14)
      url = url + `&is_admin_review=${is_admin_review}`
    }
    counselorObj.text = `${values.body}`
    counselorObj.text = counselorObj.text.replaceAll("\n", "</br>")
    counselorObj.subject = values.title
    counselorObj.pdf_name = values.name
    if (
      userRole.id == 1 &&
      InvoiceData.calendarPay &&
      InvoiceData.calendarPay.is_change_request
    ) {
      counselorObj.is_revise_invoice = true
      url = url + `&is_revise_invoice=true`
    } else if (userRole.id == 2) {
      counselorObj.is_change_request = true
      url = url + `&is_change_request=true`
    }
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined


    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,


      },
      body: JSON.stringify(counselorObj),
    }
    fetch(url, requestOptions).then(res => {
      setIsSubmitted(false)
      if (res.status == "201") {
        handleEmailClick()
        if (userRole.role_name == "Admin") {
          setEmailContent({
            variables: {
              argumentsInput: {
                text: values.body,
              },
            },
          })
        }
        toast.success("The mail has been sent to your email")
      }
    })
  }
  const handleDelete = (e, row) => {
    let currentTableId = e.target
      .closest(".expandedTable")
      .getAttribute("data-key")
    let deletedData = ""
    if (currentTableId) {
      let finalData = expandedData[currentTableId].filter(item => {
        if (item.id !== row.id) {
          return item
        } else {
          deletedData = item
          return false
        }
      })
      let prevDeleted = deletExpandedData[currentTableId]
        ? deletExpandedData[currentTableId]
        : []
      setDeletExpandedData(prev => ({
        ...prev,
        ...{
          [currentTableId]: [...prevDeleted, deletedData],
        },
      }))
      setExpandedData(prev => ({
        ...prev,
        ...{
          [currentTableId]: finalData,
        },
      }))
    }
  }
  const handleOutstaningList = (e, row, isOutstaning) => {
    if (isOutstaning) {
      // when click on location list
      let currentTableId = e.target
        .closest(".expandedTable")
        .getAttribute("data-key")
      let outstandingData = ""
      if (currentTableId) {
        let finalData = expandedData[currentTableId].filter(item => {
          if (item.id !== row.id) {
            return item
          } else {
            outstandingData = item
            return false
          }
        })
        if (outstandingData) {
          updateExpandedList({
            variables: {
              updateAppointmentInput: {
                id: parseInt(outstandingData.id),
                is_outstanding: true,
                is_default: false,
              },
            },
          }).then(res => {
            if (
              res.data.updateAppointment.code == 200 &&
              res.data.updateAppointment.status
            ) {
              setOutStanding(prev => {
                return [...prev, outstandingData]
              })
            }
          })
        }
        let prevOutstanding = outstaningData[currentTableId]
          ? outstaningData[currentTableId]
          : []
        setOutstaningData(prev => ({
          ...prev,
          ...{
            [currentTableId]: [...prevOutstanding, outstandingData],
          },
        }))
        setExpandedData(prev => ({
          // it should remove data
          ...prev,
          ...{
            [currentTableId]: finalData,
          },
        }))
      }
    } else {
      // when click on outstaing list
      updateExpandedList({
        variables: {
          updateAppointmentInput: {
            id: parseInt(row.id),
            is_outstanding: false,
            is_default: true,
          },
        },
      })
        .then(res => {
          if (
            res.data.updateAppointment.code == 200 &&
            res.data.updateAppointment.status
          ) {
            let update = outStanding.filter(item => {
              if (item.id == row.id) {
                return false
              }
              return item
            })
            setOutStanding(update)
          }
        })
        .then(res => {
          let inputValues = {
            id: parseInt(id),
            start_date: startDate,
            end_date: endDate,
          }
          getInvoiceList({
            variables: {
              argumentsInput: inputValues,
            },
          }).then(res => {
            setInvoiceData(res.data.counsellorAppointment.data.calendar)
          })
        })
    }
  }
  const updateMutations = (id, obj) => {
    return updateExpandedList({
      variables: {
        updateAppointmentInput: {
          id: parseInt(id),
          ...obj,
        },
      },
    })
  }
  const handleDeleteMisc = (id, obj) => {
    if (obj.new) {
      setMiscList(prev => {
        return prev.filter(item => item.id !== obj.id)
      })
    } else {
      return updateExpandedList({
        variables: {
          updateAppointmentInput: {
            id: parseInt(id),
            is_delete_misc: true,
          },
        },
      }).then(res => {
        let data = miscList.filter(item => item.id !== id)
        setMiscList(data)
      })
    }
  }
  const columns = [
    {
      text: "Client",
      dataField: "first_name",
      width: 150,
      formatter: (cell, row) => {
        return (
          <div className="link">
            {`${
              row?.client && row?.client.name
                ? row?.client.name
                : row?.client?.first_name && row?.client?.last_name
                ? `${row?.client?.first_name} ${row?.client?.last_name}`
                : `${row.first_name} ${row.last_name}`
            }`}
          </div>
        )
      },
    },
    {
      text: "Sessions",
      dataField: "session",
      width: 150,
      formatter: (cell, row) => {
        return <span>{row.session}</span>
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) =>
          handleOnExpand(row, rowIndex),
      },
    },
    {
      text: "Rate",
      dataField: "top_up_amount",
      width: 150,
      formatter: (cell, row) => {
        return (
          <span>
            {row.other_price ? `${row.other_price}` : `${row?.price.includes('$')?'':'$'}${row?.price}`}{" "}
            {cell ? `($${Number(cell).toFixed(2)})` : ""}{" "}
          </span>
        )
      },
    },
    {
      text: "Counsellor Rate",
      dataField: "other_counsellor_rate",
      formatter: (cell, row) => {
        return (
          <span>
            {row.other_counsellor_rate
              ? `${row.other_counsellor_rate}`
              : `$${row?.counsellor_rate.toFixed(2)}`}{" "}
          </span>
        )
      },
    },
    {
      text: "GST",
      dataField: "gst",
      hidden: !InvoiceData.gst_enabled,
      formatter: (cell, row) => {
        return <span>{Number(cell).toFixed(2)}</span>
      },
    },
    {
      text: "Total",
      dataField: "total",
      formatter: (cell, row) => {
        return <span>${Number(cell).toFixed(2)}</span>
      },
    },
    {
      text: "Description",
      dataField: "type",
      formatter: (cell, row) => {
        return <span>{cell}</span>
      },
    },
    {
      text: "Category",
      dataField: "Category",
      width: 150,
      formatter: (cell, row) => {
        return <span>{row?.plan?.payment_source.name}</span>
      },
    },
  ]
  const onhandleSuccess = (e, value, row, index) => {
    value = parseFloat(value)
    let currentTableId = e.target
      .closest(".expandedTable")
      .getAttribute("data-key")
      let finalData = expandedData[currentTableId]
      finalData[index].price = value
      finalData[index].gst = value * 5 / 100
      finalData[index].total = value + (value * 5 / 100)
      finalData[index].counsellor_rate = value * (sharePercentage / 100)

      let finalTotal = 0;
      finalData.forEach(item=>{
        finalTotal += item.counsellor_rate         
      })
      let updatedInvoiceDefault = InvoiceData
      updatedInvoiceDefault.default.map(item=>{
        if(item.id == currentTableId){
          item.total = finalTotal
          return item
        }else{
          return item
        }
      })
      setInvoiceData(updatedInvoiceDefault)
      // update parent total price
      setExpandedData(prev => ({
        ...prev,
        ...{
          [currentTableId]: finalData,
        },
      }))
    setIsEdit(prev => ({ ...prev, ...{ [row.id]: false } }))
  }
  const onhandleCancle = (value, id, row) => {
    setIsEdit(prev => ({ ...prev, ...{ [id]: false } }))
  }
  

  const handleEmailClick = () => {
    setEmailToggle(state => !isEmailModal)
  }

  const onTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {}

  const handleOnExpand = async (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpanded(prev => {
        return [...prev, row.id]
      })
      const calendarInput = {
        id: therapistCalendar,
        email: row.email,
        start_date: startDate,
        end_date: endDate,
        is_current_month
      }
      await expandInvoiceList({
        variables: {
          argumentsInput: calendarInput,
        },
      }).then(res => {
        setExpandedData(prev => ({
          ...prev,
          ...{
            [row.id]:
              res?.data?.expandCounsellorsAppointments?.data?.appointments,
          },
        }))
      })
    } else {
      setExpanded(() => expanded.filter(x => x !== row.id))
    }
  }
  const renderHtml = row => {
    return (
      <ExpandHtml
      row={row}
      expandedData={expandedData}
      onhandleSuccess={onhandleSuccess}
      onhandleCancle={onhandleCancle}
      handleDelete={handleDelete}
      handleOutstaningList={handleOutstaningList}
      handleOnExpand={handleOnExpand}
      setIsEdit={setIsEdit}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      onTableChange={onTableChange}
      isEdit={isEdit}
      />
    )
  }
  const expandRow = {
    renderer: row => renderHtml(row),
    expanded: expanded,
    onExpand: handleOnExpand,
  }
  const handlePrevious = () => {
    let index = state.index - 1
    let data = { ...state, index }
    let counselorID = state.data[index] && state.data[index].id
    if (counselorID) {
      localStorage.setItem("counsellorPay", JSON.stringify(data))
      setState(data)
      getInvoiceDetails(counselorID) // call api
    }
  }

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null

  const handleNext = () => {
    let index = state.index + 1
    let data = { ...state, index }
    let counselorID = state.data[index] && state.data[index].id
    if (counselorID) {
      setState(data)
      localStorage.setItem("counsellorPay", JSON.stringify(data))
      getInvoiceDetails(counselorID) // call api
    }
  }
  return (
    <React.Fragment>
      <div className="page-content position-relative">
        <div className={`${userRole.id == "1" ? "d-flex" : "d-none"}`}>
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={<div className="invoice-list-fsize-14">previous</div>}
            arrow
          >
            <i
              className="mdi mdi-skip-previous-circle pointer prev-button-styling"
              onClick={() => handlePrevious()}
              style={{
                opacity: state.index == 0 ? "0.5" : "1",
              }}
            ></i>
          </Tooltip>
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={<div className="invoice-list-fsize-14">Next</div>}
            arrow
          >
            <i
              className="mdi mdi-skip-next-circle pointer next-button-styling"
              onClick={() => handleNext()}
              style={{
                opacity:
                  state.data && state.data.length - 1 == state.index
                    ? "0.5"
                    : "1",
              }}
            ></i>
          </Tooltip>
        </div>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <div className={`d-flex align-item-center  ${style.titleInvoice}`}>
              <i
                className="waves-effect card-title-main mdi mdi-arrow-left font-size-22 mr-5"
                onClick={() => history.push("/counsellor-pay")}
                id="backArrow"
              ></i>
              <span className="card-title-main errorBoundary-ml-10">
                Counsellor Invoice
              </span>
            </div>
            <div>
              <button
                type="button"
                className={` btn btn-primary
                `}
                onClick={() => handleEmailClick()}
              >
                {userRole.id == 1
                  ? "Submit for counsellor review"
                  : "Change request for invoice"}
              </button>
            </div>
          </div>
          <InvoiceTotalTable
                InvoiceData={InvoiceData && InvoiceData}
                setInvoiceData={setInvoiceData}
              />
          {isSpinner ? (
            <Row>
              <Col className="col-12 user-role-create-height ">
                <div className="spinner">
                  <Loader />
                </div>
              </Col>
            </Row>
          ) : (
            <>
              <div className="mt-4">
                <Row>
                  <Col sm={12} md={4} xs={12} className="mb-2 mb-md-0 mb-lg-0">
                  
                    <div
                      className={`card d-flex flex-row align-items-center gap-2 ${style.cards} h-100 light-green-bg-card p-4 border-light-shadow`}
                    >
                       <i className='uil uil-clipboard-notes icon-double-color'></i>
                       <div>
                      <div className={`card-title-invoice ${style.gray}`}>
                        {" "}
                       
                        Bill To
                      </div>
                      <div className={`card-sub-title-invoice ${style.black}`}>
                        {orgData?.name ? orgData?.name : "No Fear Counselling"}
                      </div>
                      </div>
                    </div>
                  </Col>

                  <Col sm={12} md={4} xs={12} className="mb-2 mb-md-0 mb-lg-0">
                    <div
                      className={`card ${style.cards} d-flex flex-row align-items-center gap-2 h-100 light-green-bg-card p-4 border-light-shadow`}
                    >
                      <i className='uil uil-user-md icon-double-color'></i>
                      <div>
                      <div className={`card-title-invoice ${style.gray}`}>
                        {" "}
                        Therapist Name
                      </div>
                      <div className={`card-sub-title-invoice ${style.black}`}>
                        {InvoiceData.name}
                      </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={4} xs={12} className="mb-2 mb-md-0 mb-lg-0">
                    <div
                      className={`card ${style.cards} d-flex flex-row align-items-center gap-2 h-100 light-green-bg-card p-4 border-light-shadow`}
                    >
                       <i className='uil uil-calendar-alt icon-double-color'></i>
                       <div>
                      <div className={`card-title-invoice ${style.gray}`}>
                        {" "}
                        Date Range
                      </div>
                      <div className={`card-sub-title-invoice ${style.black}`}>
                        {startDate
                          ? moment(startDate).format("DD-MMM-YYYY")
                          : ""}{" "}
                        to{" "}
                        {endDate ? moment(endDate).format("DD-MMM-YYYY") : ""}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
             
              <div className="mt-4">
                <div className={`${style.titleInvoice} basic-card-title`}>
                  Contractor Location
                </div>
                <Row>
                  <Col className="col-12">
                    <Card className="light-green-bg-card-table ">
                      <CardBody className="p-0 table-custom-redesign">
                        <Table
                          data={InvoiceData.default || []}
                          columns={columns}
                          noDataIndication={"No Data Found"}
                          isPagination={false}
                          isSearch={false}
                          expandRow={expandRow}
                          isStriped={false}
                          isHover={false}
                          isBordereded={false}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Misc
                  miscList={miscList}
                  futurPaidList={futurPaidList}
                  updateMutations={updateMutations}
                  handleDeleteMisc={handleDeleteMisc}
                  setFuturPaidList={setFuturPaidList}
                  setMiscList={setMiscList}
                  setInvoiceData={setInvoiceData}
                />

                <OutStandingList
                  outStanding={outStanding}
                  handleOutstaningList={handleOutstaningList}
                />
                <FuturPaidList
                  futurPaidList={futurPaidList}
                  setFuturPaidList={setFuturPaidList}
                  miscList={miscList}
                  setMiscList={setMiscList}
                  updateMutations={updateMutations}
                />
              </div>
            </>
          )}
        </div>
        {/* model for email */}
        {isEmailModal && (
          <Dialog
            open={isEmailModal}
            onToggle={handleEmailClick}
            validation={validation}
            isShowBodyField={true}
            isDisabled={isSubmitted}
            isChooseFile={false}
            isTitleFieldDisabled={true}
            size="lg"
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default InvoiceList