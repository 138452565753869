import React, { useEffect, useState } from "react"
import { Col, FormGroup, Input, Label } from "reactstrap"
import useStyles from "../../Style"
import Table from "../../../../components/Common/Table"
import classNames from "classnames"
import TextboxField from "./TextboxField"
import { formatCurrency } from "../../../../functions/functions"
import moment from "moment"
import { toast } from "react-toastify"
import { useLazyQuery, useMutation } from "@apollo/client"
import { UPDATE_RATE } from "../../ReceiptManagement/gql/gql"

function PreviewTable({
  data,
  preview,
  child,
  handleUpdateRate,
  selectedPreviewID,
  storeData,
  onRateupdate,
  setDeleted,
}) {
  const classes = useStyles()

  const [submitMutation, submitResponse] = useMutation(UPDATE_RATE)

  const [datas, setDatas] = useState(preview.paymentTypeData)

  const successAPI = (value, row, index) => {
    onRateupdate(value, row, index)
  }
  const calculateTotal = columnData => {
    let total = 0
    columnData.forEach(item => {
      total = parseFloat(total) + parseFloat(parseFloat(item).toFixed(2))
    })
    return `$${parseFloat(total).toFixed(2)}`
  }
  useEffect(() => {
    if (
      submitResponse.data &&
      submitResponse.data.updateAppointment.status &&
      submitResponse.data.updateAppointment.code === 200
    ) {
      toast.success(submitResponse.data.updateAppointment.message)
      handleUpdateRate()
    } else {
      if (submitResponse.data && submitResponse.data.updateAppointment) {
        toast.error(submitResponse.data.updateAppointment.message)
      }
    }
  }, [submitResponse.data])
  const handleRowSelect = (value, row, index) => {
    if (value) {
      setDeleted(prev => [...prev, index])
    } else {
      setDeleted(prev => {
        return prev.filter(item => {
          return item !== index
        })
      })
    }
  }
  const columns = [
    {
      text: "",
      dataField: "",
      footer: " ",
      formatter: (cell, row, index, field) => {
        return (
          <FormGroup check>
            <Input
              id="exampleCheck"
              name="check"
              type="checkbox"
              onChange={e => handleRowSelect(e.target.checked, row, index)}
            />
          </FormGroup>
        )
      },
    },
    {
      text: "Description",
      dataField: "name",
      footer: " ",
      style: {
        minWidth: 380,
      },
      formatter: (cell, row, index, field) => {
        return child?.name
      },
    },
    {
      text: "Date",
      dataField: "date",
      footer: " ",
    },
    {
      text: "Rate",
      dataField: "price",
      style: {
        minWidth: 220,
      },
      formatter: (cell, row, index, field) => {
        return (
          <TextboxField
            rowValue={cell}
            row={row}
            index={index}
            field={field}
            successAPI={successAPI}
          />
        )
      },
      footer: columnData => {
        return calculateTotal(columnData)
      },
    },
    {
      text: "GST",
      dataField: "gst",
      formatter: (cell, row, index, field) => {
        return cell && `$${formatCurrency(parseFloat(cell).toFixed(2))}`
      },
      footer: columnData => {
        return calculateTotal(columnData)
      },
    },
    {
      text: "Total",
      dataField: "total",
      formatter: (cell, row, index, field) => {
        return cell && `$${formatCurrency(parseFloat(cell).toFixed(2))}`
      },
      footer: columnData => {
        return calculateTotal(columnData)
      },
    },
  ]
  return (
    <Col sm={12} md={12} lg={12} xl={12}>
      <div
        className={classNames(`table-custom-redesign`, classes.hideTableBorder)}
      >
        <Table
          data={preview.paymentTypeData || []}
          columns={columns}
          noDataIndication={"No Data Found"}
          loading={false}
          isSearch={false}
          isStriped={false}
          isHover={false}
          isBordereded={false}
          isPagination={false}
        />
      </div>
    </Col>
  )
}

export default PreviewTable
