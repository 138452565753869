import React from "react"

function ClientAction(props) {
  const { value, row, field, requestToDeactivate } = props

  const isClientActivated = row?.plan?.session_status
  const values = Array.from(row?.plan?.request, n => n.id)
  const maxId = Math.max(...values)
  const object = row?.plan?.request.find(item => item.id === maxId)
  const isDisabled = object?.is_approved

  const handleRequestToDeactivate = () => {
    requestToDeactivate(row?.plan?.id, isClientActivated)
  }

  return (
    <div className="d-flex justify-content-center">
      {isClientActivated === "active" ? (
        <button
          type="button"
          className="btn btn-primary w-sm waves-effect waves-light btn-sm"
          onClick={handleRequestToDeactivate}
          disabled={isDisabled === false ? true : false}
        >
          Request to Deactivate
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-sm btn-inactive w-sm waves-effect waves-light"
          onClick={handleRequestToDeactivate}
          disabled={isDisabled === false ? true : false}
        >
          Request to Activate
        </button>
      )}
    </div>
  )
}

export default ClientAction
