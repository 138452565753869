import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import MenuIcon from "../../assets/images/dashboard-icons/menu-icon.svg"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import logo from "../../assets/images/logo.png"
// import logoSm from "../../assets/images/logo-sm.png";
import logosm from "../../assets/images/logosm.png"
import { debounce } from "lodash"

const Sidebar = props => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        var body = document.body
        body.classList.add("vertical-collpsed")
        body.classList.remove("sidebar-enable")
      }
    }
    handleResize()
    window.addEventListener("resize", debounce(handleResize, 1500))
  }, [])

  function tToggle() {
    console.log("########## tToggle ###########", tToggle)
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null

  const storedId = localStorage.getItem("orgId")
  const orgId = storedId ? parseInt(atob(JSON.parse(storedId))) : null

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="d-flex mt-5 align-items-center justify-content-between">
          <div onClick={tToggle} className="navbar-brand-box">
            <div className="logo logo-dark">
              <span className="logo-sm text-center logo-small-default">
                <img src={MenuIcon} className="hamburger-icon" />
              </span>
              <span className="logo-lg logo-default">
                {orgData?.logo && (
                  <img src={orgData?.logo} alt="Logo" className="logo-center" />
                )}
                {orgId === 1 && (
                  <img src="/nfcld.png" alt="cba" />
                )}
              </span>
            </div>

            <div className="logo logo-light">
              <span className="logo-sm">
                <img src="/NFC-logo.png" alt="" height="30" />
              </span>
              <span className="logo-lg">
                <img src="/NFC-logo.png" alt="" height="20" />
              </span>
            </div>
          </div>
          <button
            onClick={tToggle}
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars"></i>
          </button>
        </div>
        <div data-simplebar className="h-100 d-inline block">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
