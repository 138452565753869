import React, { useCallback, useState, useEffect } from "react"
import classNames from "classnames"
import { Card, Input, CardTitle, Col, Row, Modal, Button } from "reactstrap"
import useStyles from "../Style"
import PreviewTable from "./PreviewTable"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { toast } from "react-toastify"
import moment from "moment"
import Dialog from "./Dialog"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector } from "react-redux"
import {
  capitalizeFirstLetter,
  downloadPDF,
} from "../../../functions/functions"
import { useLazyQuery } from "@apollo/client"
import { INCREMENT_BILLED_SESSION } from "../ReceiptManagement/gql/gql"

const Preview = ({ data, objData, handleUpdateRate,isRateUpdate,storeAppointments,previewData }) => {
  const classes = useStyles()
  const userData = useSelector(state => state.Login?.user?.user)
  const [isEditableField, setIsEditableField] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [list, setList] = useState([])
  const [isInvoice, setIsInvoice] = useState(
    userData.role.id === 1 ? true : false
  )

  const clientName =
    data.appointments.length !== 0
      ? data.appointments[0].client?.name
        ? capitalizeFirstLetter(data.appointments[0].client.name)
        : data?.appointments[0]?.client?.first_name
        ? capitalizeFirstLetter(data.appointments[0].client.first_name) +
          " " +
          capitalizeFirstLetter(data.appointments[0].client.last_name)
        : ""
      : ""
  const clientEmail =
    data.appointments.length !== 0
      ? data?.appointments[0]?.email
        ? data?.appointments[0]?.email
        : ""
      : ""
  const [state, setState] = useState({
    bill_to: clientName,
    therapist_name: data?.name,
    invoice_date: moment().toDate(),
    invoice_number: 35920,
    total_billed: 47,
    client_name: clientName,
    claim_number: 7387269261,
  })
  const [showModal, setShowModal] = useState(false)

  const onChangeEditableField = () => {
    setIsEditableField(!isEditableField)
  }
  const handleModal = () => {
    //show warning if client name updated on bill
    clientName === state.client_name
      ? onUpdateEditableField()
      : setShowModal(true)
  }
  const onUpdateEditableField = () => {
    let updatedObj = objData
    objData.calendar.bill_to = state.bill_to
    objData.calendar.appointments[0].client.name = state.client_name
    setIsEditableField(!isEditableField)
    setShowModal(false)
  }

  const handleChange = () => {
    setIsInvoice(!isInvoice)
  }

  const storedData = localStorage.getItem("orgData")
  const orgData = storedData ? JSON.parse(storedData) : null
  //************************** Email ***************************** */

  const [isOpenEmailDialog, setIsOpenEmailDialog] = useState(false)

  const handleEmailClick = () => {
    setIsOpenEmailDialog(!isOpenEmailDialog)
  }

  const [initialValues, setInitialValues] = useState({
    email: "",
    title: "",
    name: "",
    data: objData,
  })

  useEffect(() => {
    let pdf_name = ""
    let current_month = moment().format("DD-MMM")
    if (data && data.appointments && data.appointments.length > 0) {
      if (
        data.appointments[0].client.first_name &&
        data.appointments[0].client.last_name
      ) {
        data.appointments = data.appointments.map(item => {
          return { ...item, date: moment(item.date).format("DD-MMM-YYYY") }
        })
        pdf_name = `Receipt-${data.appointments[0].client.first_name
          .charAt(0)
          .toUpperCase()}${data.appointments[0].client.last_name
          .charAt(0)
          .toUpperCase()}.pdf`
      } else if (data.appointments[0].client.name) {
        let arr = data.appointments[0].client.name.split(" ")
        pdf_name = `Receipt-${arr[0].charAt(0).toUpperCase()}${arr[0]
          .charAt(0)
          .toUpperCase()}.pdf`
      }
    }
    objData.calendar.pdf_name = pdf_name
    objData.calendar.bill_to = clientName
    const dialogData = {
      email: clientEmail,
      title: `${orgData?.name ? orgData?.name : "No Fear Counselling"} Receipt`,
      name: `${
        pdf_name
          ? pdf_name
          : data.receipt_name
          ? data.receipt_name + ".pdf"
          : "Receipt.pdf"
      }`,
      data: objData,
    }
    setInitialValues(dialogData)
  }, [data])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),
      title: Yup.string().required(),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      handleSubmit(values)
    },
  })

  const [getIncrementedNumber, incrementedNumberResponse] = useLazyQuery(
    INCREMENT_BILLED_SESSION,
    {
      fetchPolicy: "no-cache",
    }
  )

  function getIncrementedNumberFun(values) {
    getIncrementedNumber({
      variables: {
        argumentsInput: {
          appointment_id: values.data.calendar.appointments[0].appointment_id,
          billed_sessions: values.data.calendar.total_sessions,
        },
      },
    })
  }

  useEffect(() => {
    if (
      incrementedNumberResponse.data &&
      incrementedNumberResponse.data.updateReceiptBilledSessions &&
      incrementedNumberResponse.data.updateReceiptBilledSessions.code === 200
    ) {
      console.log(
        incrementedNumberResponse.data.updateReceiptBilledSessions.message
      )
    }
  }, [incrementedNumberResponse.data])

  const handleSubmit = values => {
    const counselorObj = {
      ...values.data,
    }
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get("orgId");
    const decodedOrgIdFromUrl = orgIdValueFromUrl ? decodeURIComponent(orgIdValueFromUrl) : undefined


    let url = `${process.env.REACT_APP_API_ENDPOINT}/mail/receiptPdf?email=${values.email}`
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        organization: decodedOrgIdFromUrl || orgData?.organization_identifier || process.env.REACT_APP_MULTITENENT,

      },
      body: JSON.stringify(counselorObj),
    }
    fetch(url, requestOptions).then(res => {
      setIsSubmitted(false)
      if (res.status == "201") {
        handleEmailClick()
        toast.success("The mail has been sent to your email")
      }
    })
    getIncrementedNumberFun(values)
  }

  return (
    <>
      <Row className="align-items-center">
        <Col xs={4} sm={6}>
          <CardTitle className="h4 m-0">
            <h5 className="m-0 card-title-main">
              {isInvoice ? "Invoice" : "Receipt"}
            </h5>
          </CardTitle>
        </Col>
        <Col xs={8} sm={6}>
          <div className="d-flex align-items-stretch justify-content-end gap-2">
            {!isInvoice && (
              <button
                type="button"
                className={classNames(`btn btn-primary text-capitalize`)}
                onClick={() => handleEmailClick()}
              >
                Email
              </button>
            )}
            {userData.role.id === 1 && (
              <>
                <button
                  type="button"
                  className={classNames(
                    `btn btn-primary text-capitalize padding6-20`
                  )}
                  onClick={handleChange}
                >
                  {isInvoice ? "As receipt" : "As Invoice"}
                </button>
                <button
                  type="button"
                  className={classNames(
                    `btn btn-danger text-capitalize`,
                    classes.button
                  )}
                >
                  Export
                </button>
              </>
            )}
          </div>
        </Col>
      </Row>

      <Card className={classNames("w-100 mt-3 transparent-bg-card card-green-light p-3")}>
        <Row className="mt-1">
          <Col xs={6} sm={4} md={3} lg={3} xl={2}>
            <p className="fw-bold">Bill To: </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={3}>
            {isEditableField ? (
              <Input
                id="bill_to"
                name="bill_to"
                className="form-control"
                type="text"
                onChange={e =>
                  setState(s => ({
                    ...s,
                    bill_to: e.target.value,
                  }))
                }
                value={state.bill_to}
              />
            ) : (
              <div>{capitalizeFirstLetter(state.bill_to)}</div>
            )}
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} xl={{ size: 2, offset: 1 }}>
            <p className="fw-bold">Supplier Name: </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={4}>
          <div>{orgData?.name ? orgData?.name : "No Fear Counselling Corp"}</div>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={6} sm={4} md={3} lg={3} xl={2}>
            <p className="fw-bold">Client Name: </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={3}>
            {isEditableField ? (
              <Input
                id="client_name"
                name="client_name"
                className="form-control"
                type="text"
                onChange={e =>
                  setState(s => ({
                    ...s,
                    client_name: e.target.value,
                  }))
                }
                value={state.client_name}
              />
            ) : (
              <div>{capitalizeFirstLetter(state.client_name)}</div>
            )}
          </Col>

          <Col xs={6} sm={4} md={3} lg={3} xl={{ size: 2, offset: 1 }}>
            <p className="fw-bold">Supplier Address: </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={4}>
            <div>211-7885 6th St, Burnaby, BC, V3N 3N4</div>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={6} sm={4} md={3} lg={3} xl={2}>
            <p className="fw-bold">Therapist Name: </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={3}>
            <div>{state.therapist_name}</div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} xl={{ size: 2, offset: 1 }}>
            <p className="fw-bold">Supplier Phone: </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={4}>
            <div>778-288-8361</div>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={6} sm={4} md={3} lg={3} xl={2}>
            <p className="fw-bold">GST Number: </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={3}>
            <div>738726926 RT0001</div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} xl={{ size: 2, offset: 1 }}>
            <p className="fw-bold">Supplier FAX: </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={4}>
            <div>604-357-5182</div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} xl={{ size: 2 }}>
            <p className="fw-bold">
              {isInvoice ? "Invoice Date:" : "Receipt Date:"}
            </p>
          </Col>
          <Col xs={6} sm={8} md={3} lg={3} xl={4}>
            <div>{moment(state.invoice_date).format("DD-MMM-yyyy")}</div>
          </Col>
        </Row>

        {isInvoice && (
          <Row className="mt-1">
            <Col xs={6} sm={4} md={3} lg={3} xl={2}>
              <p className="fw-bold">Account Number: </p>
            </Col>
            <Col xs={6} sm={8} md={3} lg={3} xl={3}>
              <div>5017518</div>
            </Col>
            <Col xs={6} sm={4} md={3} lg={3} xl={{ size: 2, offset: 1 }}>
              <p className="fw-bold">Invoice Number: </p>
            </Col>
            <Col xs={6} sm={8} md={3} lg={3} xl={4}>
              <div>{state.invoice_number}</div>
            </Col>
          </Row>
        )}

        {isInvoice && (
          <Row className="mt-1">
            <Col xs={6} sm={4} md={3} lg={3} xl={2}>
              <p className="fw-bold">Total Billed: </p>
            </Col>
            <Col xs={6} sm={8} md={3} lg={3} xl={3}>
              <div>{state.total_billed}</div>
            </Col>
            <Col xs={6} sm={4} md={3} lg={3} xl={{ size: 2, offset: 1 }}>
              <p className="fw-bold">Claim Number: </p>
            </Col>
            <Col xs={6} sm={8} md={3} lg={3} xl={4}>
              <div>{state.claim_number}</div>
            </Col>
          </Row>
        )}

        <Row className="mt-1">
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="button"
              className={classNames(
                "btn btn-primary float-end text-capitalize"
              )}
              onClick={isEditableField ? handleModal : onChangeEditableField}
            >
              {isEditableField ? "Update" : "Edit"}
            </button>
          </div>
        </Row>
      </Card>

      <Dialog
        open={isOpenEmailDialog}
        onToggle={handleEmailClick}
        validation={validation}
        isShowBodyField={false}
        isDisabled={isSubmitted}
        isChooseFile={false}
        isTitleFieldDisabled={true}
      />

      {data.appointments.length !== 0 && (
        <Row className="mt-4">
          <PreviewTable data={data} handleUpdateRate={handleUpdateRate} 
          isRateUpdate={isRateUpdate} 
          previewData={previewData}
          list={list}
          storeAppointments={storeAppointments}
          />
        </Row>
      )}
      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(false)
        }}
        centered={true}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <div className="d-flex align-items-center flex-column">
                <i className="uil uil-times-circle text-danger fa-5x"></i>
                <h3>Are you sure?</h3>
                <p>you want to modify the client name on the receipt?</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={() => {
                    setShowModal(false)
                  }}
                  className="btn btn-secondary waves-effect m-2"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="btn btn-danger waves-effect waves-light"
                  onClick={() => onUpdateEditableField()}
                >
                  Yes, update it
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default Preview
