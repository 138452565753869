import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  GET_CLIENT,
  UPDATE_CLIENT,
  GET_PAYMENT_TYPE,
  DEACTIVATE_CLIENT,
  APPROVE_REQUEST_BY_ADMIN,
} from "../gql/clientGql"
import { ToastContainer, toast } from "react-toastify"
import { useSelector } from "react-redux"
import { useHistory, useParams, Link } from "react-router-dom"
import { FocusError } from "focus-formik-error"
import DatePicker from "react-datepicker"
import Select from "react-select"
import moment from "moment"
import style from "../style.module.scss"
import SweetAlert from "react-bootstrap-sweetalert"
import Table from "../../../components/Common/Table"
import {
  capitalizeFirstLetter,
  compareObjects,
} from "../../../functions/functions"
import Loader from "../../../components/Common/Loader"
import classNames from "classnames"

function ClientManage({ clientId, setClientId , refetchClientData}) {
  let id  = clientId
  let history = useHistory()
  const userData = useSelector(state => state.Login?.user?.user)
  const [paymentList, setPaymentList] = useState([])
  const [clientDetails, setClientDetails] = useState({})
  const [modal_center, setmodal_center] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [isSpinner, setIsSpinner] = useState(false)
  const [isDirectClientField, setisDirectClientField] = useState(false)
  const [isDocumentSubmitted, setIsDocumentSubmitted] = useState(false)
  const [isDocumentSubmittedDate, setIsDocumentSubmittedDate] = useState(null)
  const [isValidApprovedSession, setIsValidApprovedSession] = useState(0)
  const [isValidExpiryDate, setIsValidExpiryDate] = useState(true)
  const [deactiveBtn, setDeactiveBtn] = useState(false)
  const [initialValues, setInitialValues] = useState({
    payment_source_id: { value: 6, label: "CFSEU", is_direct: true },
    client_name: " ",
    approved_sessions: 0,
    expiry_date: new Date(),
    is_active: true,
    is_document_submitted: false,
    alerts_enabled: false,
    document_submitted_on: null,
    charged_session: 0,
  })

  //******************* Activate or Deactivate button *******************//

  const [isClientActivated, setisClientActivated] = useState(true)
  const [requests, setRequests] = useState([])
  const [isRequestApproved, setIsRequestApproved] = useState(false)

  useEffect(() => {
    const values = Array.from(requests, n => n.id)
    const maxId = Math.max(...values)
    const object = requests.find(item => item.id === maxId)
    setIsRequestApproved(object?.is_approved)
  }, [requests])

  //******************* Get Client *******************//

  const [getClientDetail, clientResponse] = useLazyQuery(GET_CLIENT, {
    fetchPolicy: "no-cache",
  })

  React.useEffect(() => {
    if (id) {
      getClientDetail({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [id])

  React.useEffect(() => {
    if (clientResponse.data && clientResponse.data?.getPlan) {
      const client_first_name = clientResponse.data?.getPlan?.data?.plan?.client
        ?.first_name
        ? clientResponse.data?.getPlan?.data?.plan?.client?.first_name
        : ""
      const client_last_name = clientResponse.data?.getPlan?.data?.plan?.client
        ?.last_name
        ? clientResponse.data?.getPlan?.data?.plan?.client?.last_name
        : ""
      const client_name = client_first_name
        ? client_first_name + " " + client_last_name
        : clientResponse.data?.getPlan?.data?.plan?.client?.name
      const paymentOption =
        clientResponse.data?.getPlan?.data?.plan?.payment_source
      const approvedSession =
        clientResponse.data?.getPlan?.data?.plan?.approved_sessions
      const chargedSession =
        clientResponse.data?.getPlan?.data?.plan?.charged_session
      const date = clientResponse.data?.getPlan?.data?.plan?.expiry_date
      let data = {
        ...clientResponse.data?.getPlan?.data?.plan,
        payment_source_id: {
          value: paymentOption?.id,
          label: paymentOption?.name,
          is_direct: paymentOption?.is_direct,
        },
        client_name: client_name,
        approved_sessions: !approvedSession ? 0 : approvedSession,
        charged_session: !chargedSession ? 0 : parseInt(chargedSession),
        expiry_date: date
          ? new Date(clientResponse.data?.getPlan?.data?.plan?.expiry_date)
          : null,
      }
      setIsSpinner(false)
      setIsValidApprovedSession(approvedSession)
      setisDirectClientField(
        clientResponse.data?.getPlan?.data?.plan?.payment_source?.is_direct
      )
      setInitialValues(data)
      setIsDocumentSubmitted(
        clientResponse.data?.getPlan?.data?.plan?.is_document_submitted
      )
      if (clientResponse.data?.getPlan?.data?.plan?.document_submitted_on) {
        setIsDocumentSubmittedDate(
          moment(
            clientResponse.data?.getPlan?.data?.plan?.document_submitted_on
          ).toDate()
        )
      }
      setClientDetails(clientResponse.data?.getPlan?.data?.plan)
      setisClientActivated(
        clientResponse.data?.getPlan?.data?.plan?.session_status
      )
      setRequests(clientResponse.data?.getPlan?.data?.plan?.request)
    } else {
      setIsSpinner(true)
    }
  }, [clientResponse, isValidApprovedSession])

  //******************** Get Payment Type *******************//

  const [getPaymentTypeList, paymentTypeResponse] = useLazyQuery(
    GET_PAYMENT_TYPE,
    { fetchPolicy: "no-cache" }
  )

  function getPaymentTypeFun() {
    getPaymentTypeList({
      variables: {
        argumentsInput: {
          page: 1,
        },
      },
    })
  }
  React.useEffect(() => {
    getPaymentTypeFun()
  }, [])

  React.useEffect(() => {
    if (paymentTypeResponse.data && paymentTypeResponse.data.getPaymentType) {
      setPaymentList(paymentTypeResponse.data.getPaymentType.data.paymentTypes)
    } else if (paymentTypeResponse.data) {
      setPaymentList([])
    }
  }, [paymentTypeResponse.data])

  //*********************** Dialog *******************//

  function tog_center() {
    setClientId(undefined)
  }

  //*********************** Formik *******************//

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      approved_sessions: Yup.number()
        .positive("Please enter valid approved sessions")
        .min(0, "Please enter valid approved sessions")
        .max(1000, "Please enter valid approved sessions")
        .required("Please enter approved sessions"),
      charged_session: Yup.number()
        .positive("Please enter valid charged sessions")
        .min(0, "Please enter valid charged sessions")
        .required("Please enter charged sessions"),
    }),
    onSubmit: values => {
      setIsSubmitted(true)
      handleSubmit(values)
    },
  })

  useEffect(() => {
    if (isDocumentSubmitted && isDocumentSubmittedDate) {
      setIsDocumentSubmittedDate(moment(isDocumentSubmittedDate).toDate())
    } else if (isDocumentSubmitted && !isDocumentSubmittedDate) {
      setIsDocumentSubmittedDate(moment().toDate())
    } else {
      setIsDocumentSubmittedDate(null)
    }
  }, [isDocumentSubmitted])

  useEffect(() => {
    if (validation.values.approved_sessions <= 0) {
      validation.setFieldValue("is_document_submitted", false)
      validation.setFieldValue("document_submitted_on", null)
    }
  }, [validation.values.approved_sessions])

  const handleSubmit = async values => {
    try {
      const directClientInput = {
        id: parseInt(id),
        approved_sessions: values.approved_sessions.toString(),
        expiry_date: values.expiry_date,
        is_document_submitted: values.is_document_submitted,
        payment_type_id: values.payment_source_id.value.toString(),
        alerts_enabled: values.alerts_enabled,
        document_submitted_on: isDocumentSubmittedDate,
      }
      const nonDirectClientInput = {
        id: parseInt(id),
        payment_type_id: values.payment_source_id.value.toString(),
        alerts_enabled: values.alerts_enabled,
        charged_session: parseInt(values.charged_session),
      }
      setInProgress(true)
      await submitMutation({
        variables: {
          updatePlanInput: isDirectClientField
            ? directClientInput
            : nonDirectClientInput,
        },
      })
    } catch (e) {
      setInProgress(false)
      setIsSubmitted(false)
    }
  }

  const [submitMutation, submitResponse] = useMutation(UPDATE_CLIENT)
  React.useEffect(() => {
    setInProgress(false)
    if (submitResponse.data && submitResponse.data.updatePlan) {
      toast.success(submitResponse.data.updatePlan.message)
      setClientId(undefined)
      refetchClientData()
      setTimeout(() => {
        setIsSubmitted(false)
      }, 5000)
    } else {
      if (submitResponse.data && submitResponse.data.updatePlan) {
        toast.error(submitResponse.data.UpdateClient.message)
        setIsSubmitted(false)
      }
    }
  }, [submitResponse.data])

  function handleResetForm() {
    validation.resetForm()
  }

  //*********************** Deactivate Account from admin  *******************//

  const [aprroveRequestMutation, aprroveRequestResponse] = useMutation(
    APPROVE_REQUEST_BY_ADMIN
  )

  React.useEffect(() => {
    if (
      aprroveRequestResponse.data &&
      aprroveRequestResponse.data.approveRequestByAdmin
    ) {
      toast.success(aprroveRequestResponse.data.approveRequestByAdmin.message)
      setTimeout(() => {
        setIsSubmitted(false)
        setDeactiveBtn(false)
      }, 5000)
    } else {
      if (submitResponse.data && submitResponse.data.updatePlan) {
        toast.error(submitResponse.data.approveRequestByAdmin.message)
        setIsSubmitted(false)
      }
    }
  }, [aprroveRequestResponse.data])

  const handleApproveRequest = () => {
    setDeactiveBtn(true)
    const approvedPlanInput = {
      createPlanInput: {
        id: parseInt(id),
        remarks: isClientActivated === "active" ? "for deactive" : "for active",
        request_type: "approval_status_update",
        session_status: isClientActivated === "active" ? "deactive" : "active",
      },
    }
    aprroveRequestMutation({
      variables: approvedPlanInput,
    })
  }

  //*********************** Activate Deactivate button *******************//

  const showActivateDeactivateBtn = () => {
    return isClientActivated === "active" ? (
      isRequestApproved === false ? (
        <button
          type="button"
          className="btn btn-danger w-sm waves-effect waves-light"
          onClick={handleApproveRequest}
          disabled={deactiveBtn}
        >
          Deactivate
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-inactive w-sm waves-effect waves-light"
          onClick={handleApproveRequest}
          disabled={deactiveBtn}
        >
          Deactivate
        </button>
      )
    ) : isRequestApproved === false ? (
      <button
        type="button"
        className="btn btn-success w-sm waves-effect waves-light"
        onClick={handleApproveRequest}
      >
        Active
      </button>
    ) : (
      <button
        type="button"
        className="btn btn-secondary w-sm waves-effect waves-light"
        onClick={handleApproveRequest}
      >
        Active
      </button>
    )
  }

  // Request table

  const columns = [
    {
      text: "ID",
      dataField: "id",
      width: 150,
    },
    {
      text: "Requested On",
      dataField: "created_at",
      width: 270,
      style: {
        minWidth: 150,
      },
      formatter: (value, row, rowIndex, formatExtraData) => {
        return <p className="m-0">{moment(value).format("DD-MMM-yyyy")}</p>
      },
    },
    {
      text: "Requested By",
      dataField: "requestor",
      width: 270,
      style: {
        minWidth: 150,
      },
      formatter: fullNameFun,
    },
    {
      text: "Request For",
      dataField: "remarks",
      width: 270,
      style: {
        minWidth: 150,
      },
      formatter: (value, row, rowIndex, formatExtraData) => {
        return value === "for active" ? "Activate" : "Deactivate"
      },
    },
  ]

  function fullNameFun(value, row, index, field) {
    const firstName = value?.first_name ? value?.first_name : " "
    const lastName = value?.last_name ? value?.last_name : " "
    return (
      capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName)
    )
  }

  //*********************** Payment Source *******************//

  const directPaymentSource = paymentList.filter(a => a.is_direct)
  const nonDirectPaymentSource = paymentList.filter(a => !a.is_direct)

  function handleChangePaymentSource(value) {
    setisDirectClientField(value?.is_direct)
    validation.setFieldValue("payment_source_id", value)
  }

  //*********************** Update button *******************//

  const isValid = compareObjects(initialValues, validation.values)

  return (
    <Modal isOpen={clientId} size="lg">
      <ModalHeader toggle={() => setClientId(undefined)} tag="h4">
        Edit Client
      </ModalHeader>
      <ModalBody>
        <React.Fragment>
          <div className={clientId ?  "" : "page-content"}>
            <div className="container-fluid">
             {!clientId && <Row>
                <Col className="col-12">
                  <Row>
                    <Col className="col-12">
                      <div className="page-title-box d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                          <i
                            className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                            id="backArrow"
                            onClick={() => history.goBack()}
                          />
                          <h4
                            className={`${style.headingsubtitle} card-title-main`}
                          >
                            Edit Client
                          </h4>
                        </div>
                        <div className="page-title-right">
                          <ol className="breadcrumb m-0">
                            <BreadcrumbItem>
                              <Link
                                to={
                                  isDirectClientField
                                    ? `/direct-clients`
                                    : `/non-direct-clients`
                                }
                              >
                                Client
                              </Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                              <Link to="#">Edit Client</Link>
                            </BreadcrumbItem>
                          </ol>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>}
              {isSpinner ? (
                <Row>
                  <Col className="col-12 user-role-create-height">
                    <div className="spinner">
                      <Loader />
                    </div>
                  </Col>
                </Row>
              ) : (
                <Form
                  className="needs-validation"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <FocusError formik={validation} />
                  <Row className={style.cardCenter}>
                    <Col lg={2}></Col>
                    <Col lg={10}>
                      <Row>
                        <Col xl="9">
                          <Card className="transparent-bg-card">
                            <CardBody>
                              <h4 className="card-title-main mb-4">
                                Client Details
                              </h4>
                              <p></p>
                              <Row className="form-custom-white-inputs">
                                <Col md="12">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Client Name
                                    </Label>
                                    <Input
                                      id="client_name"
                                      name="client_name"
                                      className="form-control"
                                      placeholder="Client Name"
                                      type="text"
                                      disabled={true}
                                      value={validation.values.client_name}
                                    />
                                  </div>
                                </Col>

                                <Col md="12">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Payment Source
                                    </Label>
                                    <Select
                                      options={[
                                        {
                                          label: "Direct",
                                          options: directPaymentSource.map(
                                            ({ id, name, is_direct }) => ({
                                              value: id,
                                              label: name,
                                              is_direct: is_direct,
                                            })
                                          ),
                                        },
                                        {
                                          label: "Non-direct",
                                          options: nonDirectPaymentSource.map(
                                            ({ id, name, is_direct }) => ({
                                              value: id,
                                              label: name,
                                              is_direct: is_direct,
                                            })
                                          ),
                                        },
                                      ]}
                                      value={
                                        validation.values.payment_source_id
                                      }
                                      formatGroupLabel={data => (
                                        <div>
                                          <span>{data.label}</span>
                                        </div>
                                      )}
                                      onChange={handleChangePaymentSource}
                                      placeholder="Select payment source"
                                    />
                                  </div>
                                </Col>

                                {isDirectClientField && (
                                  <>
                                    <Col md="12">
                                      <div className="mb-3">
                                        <Label className="form-label">
                                          Approved Sessions
                                        </Label>
                                        <Input
                                          id="approved_sessions"
                                          name="approved_sessions"
                                          className="form-control"
                                          placeholder="Approved Sessions"
                                          type="number"
                                          min={0}
                                          onChange={e => {
                                            validation.setFieldValue(
                                              "approved_sessions",
                                              e.target.value
                                            )
                                            validation.setFieldValue(
                                              "is_document_submitted",
                                              false
                                            )
                                            setIsDocumentSubmitted(false)
                                          }}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.approved_sessions
                                          }
                                          invalid={
                                            validation.touched
                                              .approved_sessions &&
                                            validation.errors.approved_sessions
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.approved_sessions &&
                                        validation.errors.approved_sessions ? (
                                          <FormFeedback type="invalid">
                                            {
                                              validation.errors
                                                .approved_sessions
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>

                                    {validation.values.payment_source_id
                                      .value == 3 && (
                                      <Col md="12">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Expiry Date
                                          </Label>
                                          <DatePicker
                                            selected={
                                              validation.values.expiry_date
                                            }
                                            onChange={value =>
                                              validation.setFieldValue(
                                                "expiry_date",
                                                value
                                              )
                                            }
                                            className={classNames(
                                              "form-control pointer",
                                              validation.errors.expiry_date &&
                                                "is-invalid"
                                            )}
                                            dateFormat="dd/MM/yyyy"
                                            customInput={
                                              <input
                                                type="text"
                                                id="validationCustom01"
                                                placeholder="Date"
                                              />
                                            }
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.expiry_date
                                            }
                                            invalid
                                          />
                                          <FormFeedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {validation.errors.expiry_date}
                                          </FormFeedback>
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}

                                {!isDirectClientField && (
                                  <Col md="12">
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Charged Sessions
                                      </Label>
                                      <Input
                                        id="charged_session"
                                        name="charged_session"
                                        className="form-control"
                                        placeholder="Charged Sessions"
                                        type="number"
                                        min={0}
                                        onBlur={validation.handleBlur}
                                        onChange={e => {
                                          validation.setFieldValue(
                                            "charged_session",
                                            e.target.value
                                          )
                                        }}
                                        value={
                                          validation.values.charged_session
                                        }
                                        invalid={
                                          validation.touched.charged_session &&
                                          validation.errors.charged_session
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.charged_session &&
                                      validation.errors.charged_session ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.charged_session}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                )}

                                {userData.role.id == 1 && (
                                  <Col md="6">
                                    <div className="mb-2 form-check form-switch form-switch-sm">
                                      <Input
                                        key="alerts_enabled"
                                        name="alerts_enabled"
                                        type="switch"
                                        className="form-check-input"
                                        id="customSwitchsizelg"
                                        onChange={e => {
                                          validation.setFieldValue(
                                            "alerts_enabled",
                                            !validation.values.alerts_enabled
                                          )
                                        }}
                                        onClick={e => {
                                          validation.setFieldValue(
                                            "alerts_enabled",
                                            !validation.values.alerts_enabled
                                          )
                                        }}
                                        checked={
                                          validation.values.alerts_enabled
                                        }
                                      />
                                      <Label check>Turn on/off alerts</Label>
                                    </div>
                                  </Col>
                                )}

                                {isDirectClientField && (
                                  <>
                                    <Col md="6" className="mb-2 pb-1">
                                      <div className="form-check">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="is_document_submitted"
                                          name="is_document_submitted"
                                          value={
                                            validation.values
                                              .is_document_submitted
                                          }
                                          onChange={e => {
                                            validation.setFieldValue(
                                              "is_document_submitted",
                                              !validation.values
                                                .is_document_submitted
                                            )
                                            setIsDocumentSubmitted(
                                              !validation.values
                                                .is_document_submitted
                                            )
                                          }}
                                          onClick={e => {
                                            validation.setFieldValue(
                                              "is_document_submitted",
                                              !validation.values
                                                .is_document_submitted
                                            )
                                            setIsDocumentSubmitted(
                                              !validation.values
                                                .is_document_submitted
                                            )
                                          }}
                                          checked={
                                            validation.values
                                              .is_document_submitted
                                          }
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="formrow-customCheck"
                                        >
                                          Paperwork Submitted
                                        </Label>
                                        {isDocumentSubmitted &&
                                          isDocumentSubmittedDate && (
                                            <span className="mx-1">
                                              {moment(
                                                isDocumentSubmittedDate
                                              ).format("DD-MMM-yyyy")}
                                            </span>
                                          )}
                                      </div>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </CardBody>
                          </Card>
                          <div className="flex-wrap gap-2 text-end client-manage-mbottom125rem">
                            {!inProgress ? (
                              <button
                                className="btn btn-primary w-sm waves-effect waves-light ms-3"
                                type="submit"
                                disabled={isSubmitted || isValid}
                              >
                                {"Update"}
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary w-sm waves-effect waves-light ms-3"
                                disabled
                              >
                                <Spinner
                                  type="grow"
                                  className="me-1 align-middle spinner-grow-sm"
                                  color="light"
                                />
                                Processing...
                              </button>
                            )}{" "}
                            &nbsp;&nbsp;
                            {requests?.length === 0 && (
                              <>{showActivateDeactivateBtn()}</>
                            )}
                            <Button
                              type="button"
                              color="secondary"
                              disabled={isSubmitted}
                              className="ms-3 btn btn-cancel-light-green-bg"
                              onClick={() => {
                                tog_center()
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                          {requests.length !== 0 && (
                            <Card>
                              <CardBody>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h2 className="card-title align-self-end m-0">
                                    History
                                  </h2>
                                  {showActivateDeactivateBtn()}
                                </div>
                                <hr className="my-2" />
                                <Table
                                  data={requests}
                                  columns={columns}
                                  noDataIndication={"No Data Found"}
                                  isPagination={false}
                                />
                              </CardBody>
                            </Card>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
          <ToastContainer />
          <SweetAlert
            show={modal_center}
            title="The changes done will not be saved, do you really want to exit."
            info
            showCancel
            confirmBtnText="No"
            confirmBtnBsStyle="btn btn-inactive"
            cancelBtnText="Yes"
            cancelBtnBsStyle="btn btn-primary"
            onConfirm={() => {
              tog_center()
            }}
            onCancel={() => {
              history.goBack()
            }}
          ></SweetAlert>
        </React.Fragment>
      </ModalBody>
    </Modal>
  )
}

export default ClientManage
