import React, { useCallback, useState, useEffect } from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { capitalizeFirstLetter, hasPermission } from "../../../functions/functions"
import Tooltip from "@material-ui/core/Tooltip"
import Table from "../components/Table"
import { useSelector } from "react-redux"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  GET_CLIENTS,
  GET_COUNSELORS,
  DEACTIVATE_CLIENT,
} from "../gql/clientGql"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import debounce from "lodash.debounce"
import { useHistory, useLocation } from "react-router-dom"
import { makeStyles } from "@material-ui/core"
import moment from "moment"
import style from "../style.module.scss"
import classNames from "classnames"
import CustomDropdown from "../components/CustomDropdown"
import ClientAction from "../components/ClientAction"
import LegendAction from "../components/LegendAction"

//icons
import notPaid from "../../../assets/images/legend-icons/not-paid.svg"
import activatedDeactivated from "../../../assets/images/legend-icons/activate-deactivated.svg"
import partiallyPaid from "../../../assets/images/legend-icons/partially-paid.svg"

import edit from "../../../assets/images/dashboard-icons/edit.svg"
import PageNotFound from "../../404"
import ClientManage from "../components/ClientManage"

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1400px)": {
      justifyContent: "start",
      flexDirection: "column",
    },
  },

  conatiner: {
    width: "500px",
    textAlign: "end",
    alignSelf: "end",
    "@media (max-width: 1400px)": {
      textAlign: "start",
      alignSelf: "start",
      width: "fit-content",
      padding: 0,
    },
  },

  legendContainer: {
    justifyContent: "end",
    "@media (max-width: 576px)": {
      justifyContent: "start",
    },
  },

  legendAlign: {
    "@media (max-width: 576px)": {
      flexDirection: "column",
    },
  },

  legendAlignEnd: {
    alignItems: "end",
    "@media (max-width: 576px)": {
      alignItems: "start",
    },
  },
}))

const NonDirectClientList = ({combinedPage, counsellorChange}) => {
  let history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const classes = useStyles()
  const userData = useSelector(state => state.Login?.user?.user)
  const [isSpinner, setIsSpinner] = useState(false)
  const [totalCount, setTotalCount] = useState(20)
  const [selectedCounselor, setSelectedCounselor] = useState()
  const [nonDirectClients, setNonDirectClients] = useState([])
  const [counselorList, setCounselorList] = useState([])
  const [clientId, setClientId] = useState(null)
  const [isActive, setIsActive] = useState({
    label: "Active Clients",
    value: "nondirect_active",
  })
  const [state, setState] = useState({
    page: 1,
    data: nonDirectClients,
    sizePerPage: combinedPage? 10 : 20,
    sortBy: "id",
    sortOrder: "DESC",
    selectedCounselor: selectedCounselor,
    search: "",
  })
  let counsellorId = null
  
useEffect(() => {
  if(counsellorChange){
    setSelectedCounselor(counsellorChange)
    onCounselorChange(counsellorChange)
    counsellorId=counsellorChange
    getNonDirectClientListFun(
      state.page,
      state.sizePerPage,
      state.search,
      state.sortBy,
      state.sortOrder,
      counsellorChange
    )
  }
}, [counsellorChange])

  const handleEditClick = row => {
    const encodedId = btoa(row.plan.id);

    history.push(`/plans/${encodedId}/edit`)
  }

  const handleShowDetailsClick = row => {
    const encodedId = btoa(row.plan.id);

    history.push(`/plans/${encodedId}`)
  }

  useEffect(() => {
    if (queryParams.get("filter")) {
      const filterParams = options.filter(o => {
        return o.value == queryParams.get("filter")
      })
      if (filterParams.length > 0) {
        setIsActive(filterParams[0])
      }
    }
  }, [])


  if (location.search && location.search.includes("?counsellor=")) {
    counsellorId = parseInt(location.search.replace("?counsellor=", ""))
  }

  //************************** Counselor list ***************************** */

  const [getCounselorList, counselorsResponse] = useLazyQuery(GET_COUNSELORS, {
    fetchPolicy: "no-cache",
  })

  function getCounselorListFun() {
    getCounselorList({
      variables: {
        argumentsInput: {
          is_all : true,
        },
      },
    })
  }

  React.useEffect(() => {
    getCounselorListFun()
  }, [])

  React.useEffect(() => {
    if (
      counselorsResponse.data &&
      counselorsResponse.data.getCalendarLegend &&
      counselorsResponse.data.getCalendarLegend.status &&
      counselorsResponse.data.getCalendarLegend.code === 200
    ) {
      let data = counselorsResponse.data.getCalendarLegend.data.calendars.map(
        n => {
          return {
            id: n.id,
            name: n.name,
            document_submitted_on_status: n.document_submitted_on_status,
            request_status: n.request_status,
            approval_status: n.approval_status,
            no_payment: n.no_payment,
            partial_paid: n.partial_paid,
            two_sessions_two_week_left: combinedPage ? n.two_sessions_two_week_left : undefined,
            one_sessions_one_week_left: combinedPage ? n.one_sessions_one_week_left : undefined,
          }
        }
      )
      setCounselorList(data)
    } else if (counselorsResponse.data) {
      setCounselorList([])
    }
  }, [counselorsResponse.data])

  useEffect(() => {
    const sortedList = _.sortBy(counselorList, [
      function (option) {
        return option.name
      },
    ])
    if (sortedList.length > 0) {
      setSelectedCounselor(sortedList[0].id)
    }
  }, [counselorList])

  //************************** Non Direct Client list ***************************** */

  const [getNonDirectClientList, listResponse] = useLazyQuery(GET_CLIENTS, {
    fetchPolicy: "no-cache",
  })

  function getNonDirectClientListFun(
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
    calendarId,
    filter
  ) {
    const arg = filter ? filter.value : isActive.value
    if(parseInt(calendarId)){
    setIsSpinner(true)
    getNonDirectClientList({
      variables: {
        argumentsInput: {
          page: page,
          limit: sizePerPage,
          calendar_id: parseInt(calendarId),
          search: searchText.trim(),
          sort_dir: sortOrder?.toUpperCase(),
          sort: sortField,
          nondirect_client:true,
          [arg]: true,
        },
      },
    })
      .then(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
      .catch(() => {
        setState(p => ({
          ...p,
          page,
          sizePerPage,
          sortBy: sortField,
          sortOrder: sortOrder ? sortOrder?.toUpperCase() : "DESC",
        }))
      })
    }
  }

  React.useEffect(() => {
    if (counselorList.length > 0) {
      getNonDirectClientListFun(
        state.page,
        state.sizePerPage,
        state.search,
        state.sortBy,
        state.sortOrder,
        state.selectedCounselor
      )
    } else {
      setIsSpinner(false)
      setNonDirectClients([])
      setTotalCount(20)
      setState(s => ({ ...s, data: [] }))
    }
  }, [selectedCounselor])

  React.useEffect(() => {
    if (
      listResponse.data &&
      listResponse.data.getPlans &&
      listResponse.data.getPlans.code === 200 &&
      listResponse.data.getPlans?.data?.appointments
    ) {
      setIsSpinner(false)
      setNonDirectClients(listResponse.data.getPlans.data.appointments)
      setTotalCount(listResponse.data.getPlans.data.totalCount)
      const data = listResponse.data.getPlans.data.appointments.map(d => {
        d.plan.bgcolor = getBackground(d);
        return {...d}
       })
      setState(s => ({ ...s, data }))
    } else if (
      listResponse.data &&
      listResponse.data.getPlans &&
      listResponse.data.getPlans.code !== 200
    ) {
      setIsSpinner(false)
      setNonDirectClients([])
      setTotalCount(0)
      setState(s => ({ ...s, data: [] }))
    } else {
      setIsSpinner(true)
    }
  }, [listResponse.data])

  //************************** select ***************************** */

  const options = [
    {
      label: "Active Clients",
      value: "nondirect_active",
    },
    {
      label: "Deactivated Clients",
      value: "nondirect_deactive",
    },
  ]

  const onSelectChange = value => {
    setState(p => ({
      ...p,
      page: 1,
    }))
    setIsActive(value)
    if(combinedPage){
      history.push(
        `/direct-non-direct?counsellor=${counsellorId}&filter=${
          value.value
        }&page=${1}`
      )
    }else{
    history.push(
      `/non-direct-clients?counsellor=${counsellorId}&filter=${
        value.value
      }&page=${1}`
    )
  }

    getNonDirectClientListFun(
      1,
      state.sizePerPage,
      state.search,
      state.sortBy,
      state.sortOrder,
      counsellorId,
      value
    )
  }

  const onCounselorChange = counselor => {
    setSelectedCounselor(counselor)
    setState(p => ({
      ...p,
      page: 1,
      selectedCounselor: counselor,
    }))
    if(combinedPage){
      history.push(
        `/direct-non-direct?counsellor=${counselor}&filter=${
          isActive.value
        }&page=1`
      )
    }else{
    history.push(
      `/non-direct-clients?counsellor=${counselor}&filter=${
        isActive.value
      }&page=1`
    )}
  }

  //*********************** Deactivate Account from counselor  *******************//

  const [deactivateMutation, deactivateResponse] =
    useMutation(DEACTIVATE_CLIENT)

  React.useEffect(() => {
    if (
      deactivateResponse.data &&
      deactivateResponse.data.requestByCounsellor
    ) {
      toast.success(deactivateResponse.data.requestByCounsellor.message)
      getNonDirectClientListFun(
        state.page,
        state.sizePerPage,
        state.search,
        state.sortBy,
        state.sortOrder,
        state.selectedCounselor
      )
    }
  }, [deactivateResponse.data])

  const requestToDeactivate = (id, isClientActivated) => {
    const deactivatePlanInput = {
      createPlanInput: {
        id: parseInt(id),
        remarks: isClientActivated === "active" ? "for deactive" : "for active",
        request_type: "approval_status_update",
        session_status: isClientActivated === "active" ? "deactive" : "active",
      },
    }
    deactivateMutation({
      variables: deactivatePlanInput,
    })
  }

  const getBackground = row => {
    const isPartiallyPaid = row.partial_paid_ids
      ? row.partial_paid_ids.length > 0
      : false
    const isNotPaid =
      parseInt(row.total_sessions) > parseInt(row.charged_session)
    return isPartiallyPaid ? "#ffcc4f" : isNotPaid ? "#ff6060" : ""
  }

  //************************** Table ***************************** */

  const columns = [
    {
      text: "Client Name",
      dataField: "plan.first_name",
      width: 270,
      formatter: fullNameFun,
      sort: true,
      style: (cell, row) => ({
        minWidth: 150,
        backgroundColor: row?.plan?.bgcolor,
      }),
      headerStyle: {
        cursor:"pointer"
      },
    },
    {
      text: "Alerts",
      dataField: "plan.alerts",
      width: 270,
      formatter: legendFun,
      style: (cell, row) => ({
        width: 100,
        backgroundColor: row?.plan?.bgcolor,
      }),
    },
    {
      dataField: "plan.payment_source",
      text: "Payment Source",
      formatter: paymentSourceFun,
      style: (cell, row) => ({
        minWidth: 200,
        backgroundColor: row?.plan?.bgcolor,
      }),
    },
    {
      dataField: "plan.charged_session",
      text: "Charged",
      headerAlign: "center",
      align: "center",
      formatter: chargedSessionsFun,
      style: (cell, row) => ({
        backgroundColor: row?.plan?.bgcolor,
      }),
    },
    {
      dataField: "plan.total_sessions",
      text: "Used",
      headerAlign: "center",
      align: "center",
      style: (cell, row) => ({
        backgroundColor: row?.plan?.bgcolor,
      }),
    },
    {
      text: "Status",
      dataField: "plan.session_status",
      sort: false,
      hidden:
        isActive?.label === "All" && isActive?.value === "nondirect_client"
          ? false
          : true,
      align: "center",
      headerAlign: "center",
      formatter: statusFormatter,
      style: (cell, row) => ({
        backgroundColor: row?.plan?.bgcolor,
      }),
    },
    {
      text: " ",
      dataField: "plan.request",
      sort: false,
      hidden: userData.role.id === 1 ? false : true,
      align: "center",
      formatter: notificationFormatter,
      style: (cell, row) => ({
        backgroundColor: row?.plan?.bgcolor,
      }),
    },
    {
      text: "",
      dataField: "plan.request_action",
      sort: false,
      hidden: userData.role.id === 2 ? false : true,
      align: "center",
      formatter: requestActionEvent,
      style: (cell, row, rowIndex) => ({
        minWidth: 200,
        backgroundColor: row?.plan?.bgcolor,
      }),
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      headerAlign: "left",
      align: "left",
      formatter: actionEvent,
      style: (cell, row) => ({
        width: 100,
        backgroundColor: row.bgcolor,
      }),
    },
  ]

  function fullNameFun(value, row, index, field) {
    const firstName = row?.plan?.client?.first_name
    const lastName = row?.plan?.client?.last_name
    const name = row?.plan?.client?.name ? row?.plan?.client?.name : " "
    return (
      <>
        {firstName
          ? capitalizeFirstLetter(firstName) +
            " " +
            capitalizeFirstLetter(lastName)
          : name}
        {row?.plan?.client?.first_name && (
          <Tooltip
            className={style.tooltip}
            placement="right"
            title={
              <>
                Onboarded on:{" "}
                {row.created_at
                  ? moment(row.created_at).format("MMMM D, YYYY")
                  : ""}
              </>
            }
            arrow
          >
            <i
              className={`uil uil-info-circle color-dark-green ${style.circleinfo}`}
            ></i>
          </Tooltip>
        )}
      </>
    )
  }

  function chargedSessionsFun(value, row, index, field) {
    return (
      <div>
        {value ? value : 0}
        {row?.plan?.partial_paid_ids && row?.plan?.partial_paid_ids.length > 0 && (
          <span>({row?.plan?.partial_paid_ids.length})</span>
        )}
      </div>
    )
  }

  function paymentSourceFun(value, row, index, field) {
    return value?.name ? value?.name : ""
  }

  function approvedSessionsFun(value, row, index, field) {
    return value ? value : 0
  }

  function statusFormatter(value, row, index, field) {
    return value === "active" ? "Active" : "Deactivated"
  }

  function requestActionEvent(value, row, index, field) {
    return (
      <ClientAction
        value={value}
        row={row}
        index={index}
        field={field}
        requestToDeactivate={requestToDeactivate}
      />
    )
  }
  
  const editClietnModal = (row) =>{
    setClientId(row.plan.id)
  }

  const refetchClientData = () =>{
    getNonDirectClientListFun(
      state.page,
      state.sizePerPage,
      state.search,
      state.sortBy,
      state.sortOrder,
      state.selectedCounselor
    )
  }

  function actionEvent(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {userData.role.id === 1 && (
          <Button
            onClick={() => editClietnModal(row)}
            className=" btn btn-primary cursor-pointer"
          >
            <img src={edit} />
          </Button>
        )}
        <a
          onClick={() => handleShowDetailsClick(row)}
          className="px-3 btn-underline-green-dark cursor-pointer"
        >
          View
        </a>
      </div>
    )
  }

  function notificationFormatter(value, row, index, field) {
    return (
      <div className={style.bellContainer}>
        <i
          className={classNames(
            "font-size-20 bx",
            {
              "bxs-bell": row?.plan?.alerts_enabled,
              "bx-bell": !row?.plan?.alerts_enabled,
            },
            style.bell
          )}
        ></i>
      </div>
    )
  }

  function legendFun(cell, row, rowIndex, formatExtraData) {
    return (
      <LegendAction
        cell={cell}
        row={row}
        rowIndex={rowIndex}
        formatExtraData={formatExtraData}
      />
    )
  }

  const changeHandler = ({
    page,
    sizePerPage,
    searchText,
    sortField,
    sortOrder,
    selectedCounselor,
    filter,
  }) => {
    const field =
      sortField === null || sortField === undefined ? "id" : sortField
    const sort_dir =
      sortOrder === null || sortOrder === undefined ? "DESC" : sortOrder
    const filterObj = _.find(options, function (o) {
      return o.value === filter
    })
    setIsActive(filterObj)
    setState(s => ({ ...s, search: searchText, sortOrder: sort_dir }))
    if(combinedPage){
      history.push(
        `/direct-non-direct?counsellor=${selectedCounselor}&filter=${filter}&page=${1}`
      )
    }else{
    history.push(
      `/non-direct-clients?counsellor=${selectedCounselor}&filter=${filter}&page=${1}`
    )}
    getNonDirectClientListFun(
      1,
      sizePerPage,
      searchText,
      field,
      sort_dir,
      selectedCounselor,
      filterObj
    )
  }

  const delaySearch = useCallback(debounce(changeHandler, 1000), [])

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    const field =
      sortField === null || sortField === undefined ? "id" : sortField
    const sort_dir =
      sortOrder === null || sortOrder === undefined ? "DESC" : sortOrder
    setIsSpinner(true)
    if (type === "search") {
      const filter = queryParams.get("filter")
        ? queryParams.get("filter")
        : isActive.value
      delaySearch({
        page,
        sizePerPage,
        searchText,
        sortField,
        sortOrder,
        selectedCounselor,
        filter,
      })
    } else {
      const getCounsellorId = parseInt(queryParams.get("counsellor"))
      setState(s => ({
        ...s,
        page: page,
        search: searchText,
        selectedCounselor: getCounsellorId,
      }))
      setSelectedCounselor(getCounsellorId)
      getNonDirectClientListFun(
        page,
        sizePerPage,
        searchText,
        field,
        sort_dir,
        getCounsellorId
      )
      if(combinedPage){
      history.push(
        `/direct-non-direct?counsellor=${getCounsellorId}&filter=${isActive.value}&page=${page}`
      )
    }else{
      history.push(
        `/non-direct-clients?counsellor=${getCounsellorId}&filter=${isActive.value}&page=${page}`
      )
    }
    }
    if(!combinedPage){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

  }

  return (
    <React.Fragment>
    {hasPermission() || combinedPage  ? (
      <div className={combinedPage ? "" : "page-content"}>
        <div className="container-fluid">
        {combinedPage ? <></> : 
          <div className={classes.headerContainer}>
            <div className="p-2">
              <h4 className="card-title-main">Non Direct Clients</h4>
              <h5 className={`${style.subtitlemenu} card-para-main`}>
                List of all the non direct clients assigned to each counsellor
                and their details.
              </h5>
            </div>
            <div className={classNames("w-20", classes.conatiner)}>
              <div className="d-inline-flex ">
                <Row>
                  <div className="col mt-0">
                    <div
                      className={classNames(
                        "card-title p-1 d-flex m-0 ",
                        classes.legendContainer
                      )}
                    >
                      <div className="py-2">
                        <div
                          className={classNames(
                            `d-flex text-black legend-list`
                          )}
                        >
                          <div
                            className={classNames(
                              `d-flex flex-wrap justify-content-end`,
                              classes.legendAlign
                            )}
                          >
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={notPaid}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>No Payment</span>
                            </div>
                            <div className="p-2 d-flex align-items-center">
                              <img
                                src={partiallyPaid}
                                width="20px"
                                height="20px"
                                className="direct-client-list-legend-margin"
                              />
                              <span>Partial Charge</span>
                            </div>
                            {userData.role.id === 1 && (
                              <div className="p-2 d-flex align-items-center">
                                <img
                                  src={activatedDeactivated}
                                  width="20px"
                                  height="20px"
                                  className="direct-client-list-legend-margin"
                                />
                                <span>Request to activate/deactivate</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>}
          <Row className="mg-top-default-ui">
            <Col className="col-12">
              <Card className="light-green-bg-card-table">
              
                <CardBody className="table-custom-redesign">
                {combinedPage && <p className="h4 mx-2 my-3 card-title-main font-weight-bold">
                Non - Direct Clients
                </p>}
                  <Table
                    data={state.data}
                    page={state.page}
                    sizePerPage={state.sizePerPage}
                    totalSize={totalCount}
                    onTableChange={handleTableChange}
                    columns={columns}
                    noDataIndication={"No Data Found"}
                    loading={isSpinner}
                    isFilter={true}
                    isSearch={true}
                    selectOptions={options}
                    placeholder={"All Active"}
                    onSelectChange={onSelectChange}
                    selectValue={isActive}
                    style={{ verticalAlign: "middle" }}
                    isShowCustomDropdown={true}
                    isStriped={false}
                    isHover={false}
                    isBordered={false}
                    CustomDropdown={
                      <CustomDropdown
                        options={counselorList}
                        onCounselorChange={onCounselorChange}
                        isDirectClient={false}
                        counsellorId={counsellorId}
                        isVisible={userData.role.id === 1 && !combinedPage ? true : false}
                        counsellorChange={counsellorChange}
                      />
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ClientManage clientId={clientId}  setClientId={setClientId} refetchClientData={refetchClientData}/>
        </div>
      </div>
      ) : (
        <PageNotFound />
      )}
    </React.Fragment>
  )
}

export default NonDirectClientList
