import React from "react"
import { Card, Col, Row } from "reactstrap"

const Support = () => {
  return (
    <Row className="d-flex justify-content-center text-center align-items-center support-h-100vh">
      <Col sm="12" xs={12} lg={4} md={4}>
        <Card>
          <div className="p-3">
            Please contact No Fear Counselling support at
            <br />
            <b>info@nofearcounselling.com</b>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default Support
