import React, { useCallback, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  BreadcrumbItem,
  Modal,
  ModalBody,
} from "reactstrap"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  GET_COUNSELOR,
  GET_COUNSELORS_DATA,
  GET_COUNSELOR_DATA_LIST,
  GET_LOCATIONS_CONCERNS,
  GET_TIMEZONE,
  UPDATE_COUNSELOR,
} from "./gql/Gql"
import { useHistory, useParams, Link } from "react-router-dom"
import { FocusError } from "focus-formik-error"
import { debounce, find } from "lodash"
import { toast } from "react-toastify"
import CustomMultiSelect from "../../components/CustomMultiSelect"
import PhoneNoField from "./PhoneNoField"
import { ordinalSuffixOf } from "../../functions/functions"
import style from "./style.module.scss"
import Loader from "../../components/Common/Loader"
import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-toastify/dist/ReactToastify.css"
import RichTextBox from "../../common/RichTextBox"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector } from "react-redux"
import Dropzone from "react-dropzone"
import Soinner from "../../assets/images/Loader.svg"
import { Tooltip } from "@material-ui/core"
import AppointmentTypeModal from "../User/AppointmentTypeModal"
import {
  DELETE_USER,
  EDIT_APPOINTMENT_TYPE_PRICE,
  GetCategoryTypeAppointmentTypes,
  UPDATE_CALENDER_APPOINTMENT,
} from "../User/gql/userGql"
import PriceModal from "./PriceModal"
import CounsellorsTabs from "./CounsellorsTabs"
import Availibity from "../User/Availibity"
import Rooms from "../Location/Rooms"
import Remarks from "../User/Remarks"
import DeleteCounsellor from "./DeleteCounsellor"
import CounsellorPay from "./CounsellorPay"
import CounsellorProfile from "./CounsellorProfile"
import ClientList from "./ClientList"

const CounselorManage = () => {
  let { id } = useParams()
  const decodedId = id && atob(id)
  id = decodedId
  let counsellorId = decodedId
  let history = useHistory()
  const urlLocation = history.location.pathname
  const userData = useSelector(state => state.Login?.user?.user)
  const isProfile = urlLocation.includes("profile") ? true : false
  const [counsellingTypeList, setcounsellingTypeList] = useState([])
  const [therapiesList, setTherapiesList] = useState([])
  const [languages, setLanguagesList] = useState([])
  const [locationGroups, setLocationGroupsList] = useState([])
  const [location, setLocationList] = useState([])
  const [appointmentType, setappointmentType] = useState([])
  const [concerns, setConcernsList] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [isSpinner, setIsSpinner] = useState(false)
  const [user, setUser] = useState({})
  const [isSlugEditable, setIsSlugEditable] = useState(false)
  const [ismodalOpen, setIsmodalOpen] = useState(false)
  const [timezone, setTimeZone] = useState([])
  const [isSubmittedAppointment, setIsSubmittedAppointment] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loaderAppointmentType, setLoaderAppointmentType] = useState(false)
  const [initialValuesAppointment, setInitialValuesAppointment] = useState({})
  const [updateAppointmentType, updateAppointmentTypeRes] = useMutation(
    UPDATE_CALENDER_APPOINTMENT
  )
  const [updateAppointmentTypePrice, updateAppointmentTypePriceRes] =
    useMutation(EDIT_APPOINTMENT_TYPE_PRICE)
  const [categoryTypeAppointmentTypes, setCategoryTypeAppointmentTypes] =
    useState([])
  const [
    categoryTypeAppointmentTypesTotal,
    setCategoryTypeAppointmentTypesTotal,
  ] = useState(0)
  const [appointmentTypesPrice, setAppointmentTypesPrice] = useState([])
  const [selectedappointmentTypesOpt, setselectedappointmentTypesOpt] =
    useState([])
  const [priceInProgress, setPriceInProgress] = useState(false)
  const [isPricemodalOpen, setIsPricemodalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState({
    name: "Profile",
    type: "profile",
  })
  const [state, setState] = useState({
    page: 1,
    sizePerPage: 100,
  })
  const [initialValues, setInitialValues] = useState({
    id: id,
    counselling_type: [],
    therapies: [],
    phones: [],
    languages: null,
    location_groups: null,
    locations: [],
    webflow_name: "",
    slug: "",
    about_2: "",
    team_overview: "",
    specialization: "",
    role: "",
    mobile_number: null,
    is_published: false,
    timezone: "",
  })
  const [initialCpayValues, setInitialCpayValues] = useState({
    id: id,
    share_percentage: 0,
    external_amount: 0,
    gst_enabled: 0,
    service_fee: 0,
  })
  const [getAppointmentTypes, getAppointmentTypesRes] = useLazyQuery(
    GetCategoryTypeAppointmentTypes,
    {
      fetchPolicy: "no-cache",
    }
  )
  const validationAppointment = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesAppointment,
    validationSchema: Yup.object({
      appointmentType: Yup.string().nullable(),
    }),
    onSubmit: values => {
      setIsSubmittedAppointment(true)
      updateAppointmentType({
        variables: {
          updateCalendarInput: {
            id: parseInt(id),
            appointment_type_ids: selectedAppointmentTypes,
          },
        },
      }).then(() => {
        setIsSubmittedAppointment(false)
        handleEModalToggle()
        if (id) {
          getCounselorDetail({
            variables: {
              argumentsInput: {
                id: parseInt(id),
              },
            },
          })
        }
        toast.success("Counsellor Details updated successfully")
      })
    },
  })
  const handleEModalToggle = () => {
    setIsModalOpen(!isModalOpen)
    setTimeout(() => {
      setLoaderAppointmentType(false)
    }, 3000)
  }
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState([
    45617887,
  ]) // 35958931, 35958964
  const handleChildChecked = (e, type, typeindex, index) => {
    if (
      categoryTypeAppointmentTypes[index].appointmentTypes[typeindex].checked
    ) {
      categoryTypeAppointmentTypes[index].appointmentTypes[typeindex].checked =
        e.target.checked
    } else {
      categoryTypeAppointmentTypes[index].appointmentTypes[typeindex].checked =
        e.target.checked
    }

    if (!e.target.checked) {
      setSelectedAppointmentTypes(prev => [...prev, type.id])
      if (categoryTypeAppointmentTypes[index].selectAll) {
        categoryTypeAppointmentTypes[index].selectAll = [
          ...categoryTypeAppointmentTypes[index].selectAll,
          type.id,
        ]
      } else {
        categoryTypeAppointmentTypes[index].selectAll = [type.id]
      }
    } else {
      if (categoryTypeAppointmentTypes[index].selectAll) {
        categoryTypeAppointmentTypes[index].selectAll =
          categoryTypeAppointmentTypes[index].selectAll.filter(
            item => item !== type.id
          )
      }
      setSelectedAppointmentTypes(
        selectedAppointmentTypes.filter(item => item !== type.id)
      )
    }
    setCategoryTypeAppointmentTypes(categoryTypeAppointmentTypes)
  }
  const handleMainChecked = (e, index) => {
    let arr = []
    categoryTypeAppointmentTypes[index].appointmentTypes.forEach(item => {
      arr.push(item.id)
    })
    if (!e.target.checked) {
      setSelectedAppointmentTypes(prev => [...prev, ...arr])
      categoryTypeAppointmentTypes[index].selectAll = arr
    } else {
      let final = selectedAppointmentTypes.filter(item => !arr.includes(item))
      setSelectedAppointmentTypes(final)
      categoryTypeAppointmentTypes[index].selectAll = []
    }
    setCategoryTypeAppointmentTypes(categoryTypeAppointmentTypes)
  }
  useEffect(() => {
    if (
      getAppointmentTypesRes.data &&
      getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory &&
      getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.code ==
        200
    ) {
      setCategoryTypeAppointmentTypes(
        getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.data
          ?.categoryTypeAppointmentTypes
      )
      setCategoryTypeAppointmentTypesTotal(
        getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.data
          ?.totalCount
      )
      setLoaderAppointmentType(false)
    } else if (
      getAppointmentTypesRes.data &&
      getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory &&
      getAppointmentTypesRes?.data?.getAllAppointmentsTypeByCategory?.code !==
        200
    ) {
      setLoaderAppointmentType(false)
    }
  }, [getAppointmentTypesRes.data])

  //************ Counselor Data list ************ */

  const [getCounselorDataList, counselorDataDetails] = useLazyQuery(
    GET_COUNSELOR_DATA_LIST,
    { fetchPolicy: "no-cache" }
  )
  const [getLocationsConcerns, locationsConcernsResponse] = useLazyQuery(
    GET_LOCATIONS_CONCERNS,
    { fetchPolicy: "no-cache" }
  )
  const [getCounselorList, counselorsResponse] = useLazyQuery(
    GET_COUNSELORS_DATA,
    { fetchPolicy: "no-cache" }
  )
  const [getTimezone, getTimezoneRes] = useLazyQuery(GET_TIMEZONE, {
    fetchPolicy: "no-cache",
  })
  useEffect(() => {
    if (
      getTimezoneRes.data &&
      getTimezoneRes.data.getAllTimeZones &&
      getTimezoneRes.data.getAllTimeZones.code === 200
    ) {
      let arraytimezone = getTimezoneRes.data.getAllTimeZones.data.allTimeZone

      arraytimezone = arraytimezone.map(e => {
        return { name: e.display, id: e.zoneName }
      })
      setTimeZone(arraytimezone)
    }
  }, [getTimezoneRes.data])

  useEffect(() => {
    getCounselorList({
      variables: {
        argumentsInput: {
          is_direct: true,
        },
      },
    })
    getLocationsConcerns({
      variables: {
        argumentsInput: {
          id: parseInt(id),
        },
      },
    })
  }, [])

  useEffect(() => {
    if (
      locationsConcernsResponse.data &&
      locationsConcernsResponse.data.findOneLocationGroupsAndConcerns.code ==
        200
    ) {
      setInitialValues(prevState => ({
        ...prevState,
        ...{
          location_groups:
            locationsConcernsResponse.data.findOneLocationGroupsAndConcerns.data
              .calendar.locationsGroup || [],
          concerns:
            locationsConcernsResponse.data.findOneLocationGroupsAndConcerns.data
              .calendar.concerns || [],
        },
      }))
    }
  }, [locationsConcernsResponse.data])

  let [itemID, setItemID] = useState(false)

  React.useEffect(() => {
    getTimezone()
    getAppointmentTypes({
      variables: {
        argumentsInput: {
          search: "",
        },
      },
    })
    getCounselorDataList({
      variables: {
        argumentsInput: {
          page: state.page,
          limit: state.sizePerPage,
        },
      },
    })
  }, [])

  React.useEffect(() => {
    if (
      counselorDataDetails.data &&
      counselorDataDetails.data.getCalendarDropdown &&
      counselorDataDetails.data.getCalendarDropdown.code === 200
    ) {
      setcounsellingTypeList(
        counselorDataDetails?.data?.getCalendarDropdown?.data?.counselling
      )
      setTherapiesList(
        counselorDataDetails?.data?.getCalendarDropdown?.data?.therapies
      )
      setLanguagesList(
        counselorDataDetails?.data?.getCalendarDropdown?.data?.languages
      )
      setLocationList(
        counselorDataDetails?.data?.getCalendarDropdown?.data?.locations
      )
      setappointmentType(
        counselorDataDetails?.data?.getCalendarDropdown?.data?.appointment_types
      )
      setLocationGroupsList(
        counselorDataDetails?.data?.getCalendarDropdown?.data?.location_groups
      )
      setConcernsList(
        counselorDataDetails?.data?.getCalendarDropdown?.data?.concerns
      )
    } else {
      setcounsellingTypeList([])
      setTherapiesList([])
    }
  }, [counselorDataDetails.data])

  // Multiple Phone Number //

  const [caldenderList, setCalenderList] = useState({})
  const [phoneNumberList, setPhoneNumberList] = useState([
    {
      mobile_number: "",
    },
  ])

  const addAnotherPhoneNumber = () => {
    const phoneNumberArr = [...phoneNumberList]
    phoneNumberArr.push({
      mobile_number: "",
    })
    setPhoneNumberList(phoneNumberArr)
  }

  const handleChangeMobileNumber = (index, key, e) => {
    phoneNumberList[index][key] = e
    let phones = []
    phoneNumberList.forEach(item => {
      if (item.mobile_number) {
        phones.push(item.mobile_number)
      }
    })
    setPhoneNumberList([...phoneNumberList])
  }

  const deletePhoneNumber = index => {
    phoneNumberList.splice(index, 1)
    let phones = phoneNumberList.map(item => {
      return item.mobile_number
    })
    setInitialValues(prevState => ({ ...prevState, ...{ phones: phones } }))
    setPhoneNumberList([...phoneNumberList])
  }

  //*********************************** */

  const [getCounselorDetail, counselorResponse] = useLazyQuery(GET_COUNSELOR, {
    fetchPolicy: "no-cache",
  })

  React.useEffect(() => {
    if (id) {
      getCounselorDetail({
        variables: {
          argumentsInput: {
            id: parseInt(id),
          },
        },
      })
    }
  }, [])

  React.useEffect(() => {
    if (
      id &&
      counselorResponse.data &&
      counselorResponse.data?.getCalendar &&
      counselorResponse.data?.getCalendar?.status
    ) {
      setCalenderList(counselorResponse.data?.getCalendar.data?.calendar.data)
      setUser(counselorResponse.data?.getCalendar.data?.calendar.user)
      let data = { ...counselorResponse.data?.getCalendar?.data?.calendar }
      let calenderData = counselorResponse.data?.getCalendar.data
      const phoneNumberArray =
        data?.user?.phones.length === 0 ? phoneNumberList : data?.user?.phones
      let isPhoneNumberList =
        phoneNumberArray !== undefined ? phoneNumberArray : phoneNumberList
      setIsSpinner(false)
      setPhoneNumberList(isPhoneNumberList)
      let calender = counselorResponse.data?.getCalendar.data?.calendar
      let appointmentTypesOption = calender?.appointmentTypeHasCalendars?.map(
        elem => {
          return {
            name: elem?.appointmentType.name,
            label: elem?.appointmentType.name,
            id: elem?.appointment_type_id,
            value: elem?.appointment_type_id,
            price: elem?.price || "0",
          }
        }
      )
      setAppointmentTypesPrice(appointmentTypesOption)
      setselectedappointmentTypesOpt(
        appointmentTypesOption.length > 0 ? appointmentTypesOption[0] : []
      )
      let selectedAppointmentTypeIDs = []
      calender?.appointment_types?.forEach(item => {
        selectedAppointmentTypeIDs.push(item.id)
      })
      setSelectedAppointmentTypes(selectedAppointmentTypeIDs)
      setCalenderList(calender)
      setIsSlugEditable(calender.slug ? false : true) // when new counsellor sync with acuity
      let selectedTimezone = ""
      timezone.map(item => {
        if (item.id == calender.timezone) {
          selectedTimezone = item
        }
      })
      setInitialValues(prevState => ({
        ...data,
        ...{
          phones: [],
          about_2: calender.about_2,
          archived: calender.archived,
          draft: calender.draft,
          slug: calender.slug || "",
          webflow_name: calender.webflow_name,
          team_overview: calender.team_overview,
          role: calender.role,
          timezone: selectedTimezone,
          url: calender.url
            ? {
                state: "uploaded",
                url: calender.url,
              }
            : undefined,
          draft: calender.draft,
          mobile_number: calender?.user?.mobile_number,
          archived: calender.archived,
          languages: calender.language || [],
          location_groups: prevState.location_groups || [],
          locations: calender.locations || [],
          concerns: prevState.concerns || [],
          first_name: calender?.user?.first_name || "",
          last_name: calender?.user?.last_name || "",
        },
      }))
      setInitialCpayValues(prevState => ({
        ...prevState,
        ...{
          external_amount:
            counselorResponse?.data?.getCalendar.data?.calendar.external_amount,
          service_fee:
            counselorResponse?.data?.getCalendar.data?.calendar.service_fee,
          share_percentage:
            counselorResponse?.data?.getCalendar.data?.calendar
              .share_percentage,
          gst_enabled:
            counselorResponse?.data?.getCalendar.data?.calendar.gst_enabled,
        },
      }))
    } else {
      setIsSpinner(true)
    }
  }, [counselorResponse.data, timezone])
  const handleSearch = e => {
    setLoaderAppointmentType(true)
    getAppointmentTypes({
      variables: {
        argumentsInput: {
          search: e.target.value,
        },
      },
    })
  }
  const handleSearchApoointment = useCallback(debounce(handleSearch, 1000), [])
  useCallback(debounce(handleSearch, 1000), [])
  //*********************** Formik *******************//
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      gst_enabled: Yup.boolean(),
      timezone: Yup.object().test(
        "notEmptyArr",
        "Please select timezone",
        value => {
          return !value ? false : value.id ? true : false
        }
      ),
      service_fee: Yup.number("Service fee should be number only")
        .min(0, "Service fee must be at least 0")
        .max(5, "Service fee must be less than or equal to 5"),
      share_percentage: Yup.number("Share percentage should be number only")
        .min(0, "Share percentage must be at least 0")
        .max(100, "Share percentage must be less than or equal to 100"),
      first_name: Yup.string().required("First name is a required field"),
      last_name: Yup.string().required("Last name is a required field"),
      external_amount: Yup.number("External amount should be number only"),
      specialization: Yup.array().test(
        "notEmptyArr",
        "Specialization must be less than equal to 25",
        value => {
          return value.length == 0 ? true : value.length > 25 ? false : true
        }
      ),
      concerns: Yup.array().test(
        "notEmptyArr",
        "Areas of Practice must be less than equal to 25",
        value => {
          return value.length == 0 ? true : value.length > 25 ? false : true
        }
      ),
    }),
    onSubmit: values => {
      handleSubmit(values)
    },
  })
  

  const handleSubmit = async values => {
    const counselling_ids = values?.counselling_type.map(n => n.id)
    const therapy_ids = values?.therapies.map(n => n.id)
    const languages = values?.languages ? values?.languages.map(n => n.id) : []
    const location_groups = values?.location_groups
      ? values?.location_groups.map(n => n.id)
      : []
    const locations = values?.locations ? values?.locations.map(n => n.id) : []
    const concerns = values?.concerns ? values?.concerns.map(n => n.id) : []
    const specializations = values?.specialization
      ? values?.specialization.map(n => n.id)
      : []
    const phoneNumberData = find(phoneNumberList, n => n.mobile_number === "")
    const phones = phoneNumberData
      ? []
      : phoneNumberList.map(value => {
          let mobile = value?.mobile_number
          mobile = mobile?.replaceAll("-", "")
          mobile = mobile?.replaceAll(" ", "")
          mobile = mobile?.replaceAll("(", "")
          mobile = mobile?.replaceAll(")", "")
          return mobile
        })
    let mobile_number = values.mobile_number?.replace("-", "")
    mobile_number = mobile_number?.replace(" ", "")
    mobile_number = mobile_number?.replace("(", "")
    mobile_number = mobile_number?.replace(")", "")
    try {
      const calendarInput = {
        id: parseInt(id),
        counselling_ids: [...counselling_ids],
        therapy_ids: [...therapy_ids],
        phones: [...phones],
        service_fee: parseInt(values.service_fee),
        share_percentage: parseInt(values.share_percentage),
        gst_enabled: values.gst_enabled,
        external_amount: parseInt(values.external_amount),
        about_2: values.about_2,
        team_overview: values.team_overview,
        webflow_name: values.webflow_name,
        role: values.role,
        url: values.url?.url ?? "",
        language_ids: languages,
        locations_group_ids: location_groups,
        location_ids: locations,
        concerns_ids: concerns,
        specialization_ids: specializations,
        email: values.email,
        primary_phone: mobile_number?.length > 5 ? mobile_number : null,
        slug: values.slug,
        draft: caldenderList.draft,
        archived: caldenderList.archived,
        is_published: values.is_published,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        timezone: values?.timezone?.id,
      }
      setInProgress(true)
      await submitMutation({
        variables: {
          updateCalendarInput: calendarInput,
        },
      }).then(res => {})
    } catch (e) {
      setInProgress(false)
      setIsSubmitted(false)
    }
  }

  const openAvalabilityPage = () => {
    const encodedId = btoa(id)
    if (userData?.role?.id === 1) {
      history.push(`availability`)
    } else if (userData?.role?.id === 2) {
      history.push(`/counsellors/${encodedId}/availability`)
    }
  }

  const viewRooms = () => {
    const encodedId = btoa(counsellorId)
    history.push(`/roomsview/${encodedId}`)
  }

  const [submitMutation, submitResponse] = useMutation(UPDATE_COUNSELOR)

  React.useEffect(() => {
    setInProgress(false)
    if (submitResponse.data && submitResponse.data.UpdateCalendar.status) {
      toast.success(
        isProfile
          ? "Profile updated successfully"
          : submitResponse.data.UpdateCalendar.message
      )
      setTimeout(() => {
        setInProgress(false)
      }, 5000)
    } else {
      if (submitResponse.data && submitResponse.data.UpdateCalendar) {
        toast.error(
          isProfile
            ? "Sorry, we couldn't update your profile."
            : submitResponse.data.UpdateCalendar.message
        )
        setIsSubmitted(false)
      }
    }
  }, [submitResponse.data])

  const handleImg = async (acceptedFiles, field) => {
    if (acceptedFiles[0].size > 5 * 1024 * 1000) {
      toast.error("Max File Upload Size is Upto 5 MB, try with smaller size")
      return
    }
    if (["image/jpeg", "image/png"].indexOf(acceptedFiles[0].type) < 0) {
      toast.error("Supported formats are: .jpeg, .jpg and .png")
      return
    }
    const preview = {
      url: URL.createObjectURL(acceptedFiles[0]),
      state: "uploading",
    }
    validation.setFieldValue(field, preview)

    let payload = new FormData()
    let orgData = JSON.parse(localStorage.getItem("orgData"))
    const orgIdValueFromUrl = new URL(window.location.href).searchParams.get(
      "orgId"
    )
    const decodedOrgIdFromUrl = orgIdValueFromUrl
      ? decodeURIComponent(orgIdValueFromUrl)
      : undefined

    payload.set("file", acceptedFiles[0])
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/file-upload`,
        {
          method: "POST",
          body: payload,
          headers: {
            organization:
              decodedOrgIdFromUrl ||
              orgData?.organization_identifier ||
              process.env.REACT_APP_MULTITENENT,
          },
        }
      )
      const result = await response.json()
      if (result.code === 201 || result.code === 200) {
        const preview = { url: result.data.url.Location, state: "uploaded" }
        validation.setFieldValue(field, preview)
      } else {
        validation.setFieldValue(field, undefined)
        toast.error("Failed to upload cover image")
      }
    } catch (e) {
      console.log(e)
      validation.setFieldValue(field, undefined)
      toast.error("Failed to upload cover image")
    }
  }

  const handleToggle = e => {
    if (userData?.role?.role_name == "Admin") {
      setInitialValues(prev => {
        return {
          ...validation.values,
          is_published: !validation.values.is_published,
        }
      })
    }
  }

  const onToggle = () => {
    setIsmodalOpen(!ismodalOpen)
  }
  const onPriceToggle = () => {
    setIsPricemodalOpen(!isPricemodalOpen)
    getCounselorDetailFun()
  }

  const handleSelectType = val => {
    setselectedappointmentTypesOpt(val)
  }

  const handleEnterPrice = e => {
    const newPrice = e.target.value
    setselectedappointmentTypesOpt(prevData => ({
      ...prevData,
      price: newPrice,
    }))
    setAppointmentTypesPrice(prevTherapies =>
      prevTherapies.map(therapy =>
        therapy.id === selectedappointmentTypesOpt?.id
          ? { ...therapy, price: newPrice }
          : therapy
      )
    )
  }

  const submitPrice = () => {
    let priceArray = []
    let appointmentIds = []

    appointmentTypesPrice.forEach(elem => {
      priceArray.push(elem.price)
      appointmentIds.push(elem.id)
    })
    setPriceInProgress(true)

    updateAppointmentTypePrice({
      variables: {
        createAppointmentTypeInput: {
          priceArr: priceArray,
          calendar_id: parseInt(id),
          appointementTypeIds: appointmentIds,
        },
      },
    })
  }

  const getCounselorDetailFun = () => {
    getCounselorDetail({
      variables: {
        argumentsInput: {
          id: parseInt(id),
        },
      },
    })
  }

  useEffect(() => {
    if (
      updateAppointmentTypePriceRes?.data &&
      updateAppointmentTypePriceRes?.data?.editAppointementTypePrice?.status
    ) {
      toast.success(
        updateAppointmentTypePriceRes?.data?.editAppointementTypePrice?.message
      )
      setPriceInProgress(false)
      getCounselorDetailFun()
      onPriceToggle()
    } else {
      toast.error(
        updateAppointmentTypePriceRes?.data?.editAppointementTypePrice?.message
      )
      setPriceInProgress(false)
    }
  }, [updateAppointmentTypePriceRes?.data])

  const [deleteRecord, setDeleteRecord] = useState({
    id: null,
  })
  const [inProcess, setInProcess] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteMutation, deleteResponse] = useMutation(DELETE_USER)
  function deleteToggle() {
    setModalDelete(!modalDelete)
  }
  const handleDelete = async () => {
    const storedData = localStorage.getItem("orgId")
    const orgId = storedData ? parseInt(atob(JSON.parse(storedData))) : null
    try {
      setInProcess(true)
      await deleteMutation({
        variables: {
          argumentsInput: {
            id: parseInt(deleteRecord.id),
            organization_id: orgId ? orgId : undefined,
          },
        },
      })
    } catch (e) {
      setInProcess(false)
      console.log(e)
    }
    setDeleteRecord({
      id: null,
    })
    deleteToggle()
  }
  const openDeleteModel = async id => {
    setModalDelete(!modalDelete)
    setDeleteRecord({
      id,
    })
  }

  React.useEffect(() => {
    if (deleteResponse?.data && deleteResponse?.data?.deleteUser?.status) {
      toast.success(deleteResponse?.data?.deleteUser?.message)
      setInProcess(false)
      history.goBack()
    } else {
      if (deleteResponse?.data && deleteResponse?.data?.deleteUser) {
        toast.error(deleteResponse?.data?.deleteUser?.message)
      }
      setInProcess(false)
    }
  }, [deleteResponse.data])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className={`${style.headingsubtitle} card-title-main`}>
                    {isProfile ? "Edit Profile" : "Edit Counsellor"}{" "}
                  </h4>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to={isProfile ? "#" : "/counsellors"}>
                        {isProfile ? "Profile" : "Counsellor"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">
                        {isProfile ? "Edit Profile" : "Edit Counsellor"}
                      </Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
            {validation?.values?.first_name && (
              <Col className="col-12 d-flex flex-wrap flex-row mt-2 mb-4 align-items-center justify-content-between   ">
                <div className="d-flex flex-row align-items-center">
                  <p className="h4 font-weight-bold mx-2 text-capitalize">
                    {validation.values.first_name} {validation.values.last_name}{" "}
                  </p>
                  <p className="h4 font-weight-bold mx-2">-</p>
                  <p className="h4 font-weight-bold mx-2 text-capitalize">
                    {activeTab.name}
                  </p>
                </div>
                <div className="d-flex w-sm-100 flex-row align-items-center">
                  {userData.role.id === 1 && (
                    <button
                      onClick={() => openDeleteModel(user?.id)}
                      className=" btn button-delete  delete-btn text-white cursor-pointer"
                    >
                      Delete Counsellor
                      <span className="mx-2 mdi mdi-trash-can-outline font-size-16"></span>
                    </button>
                  )}
                </div>
              </Col>
            )}
            <Col className="col-12 text-right">
              <Row className="m-0 p-0 ">
                <Col md={12} className="m-0 p-0">
                  <CounsellorsTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {activeTab.type === "profile" &&
            (isSpinner ? (
              <>
                <Row>
                  <Col className="col-12 user-role-create-height">
                    <div className="spinner">
                      <Loader />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <CounsellorProfile
                  user={caldenderList}
                  setActiveTab={setActiveTab}
                />
              </>
            ))}
          {activeTab.type === "settings" &&
            (isSpinner ? (
              <Row>
                <Col className="col-12 user-role-create-height">
                  <div className="spinner">
                    <Loader />
                  </div>
                </Col>
              </Row>
            ) : (
              <Form
                className="needs-validation"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <FocusError formik={validation} />
                <Row className={style.cardcenter}>
                  <Col xl="12 mt-5">
                    <Row className="form-custom-white-inputs">
                      <Card className="light-green-bg-card border-light-shadow">
                        <CardBody>
                          <Col xs={12}>
                          <div className="text-end m-0 p-0">
                        {!inProgress ? (
                          <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            type="submit"
                            disabled={isSubmitted}
                          >
                            {"Update"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            disabled
                          >
                            <Spinner
                              type="grow"
                              className="me-1 align-middle spinner-grow-sm "
                              color="light"
                            />
                            Processing...
                          </button>
                        )}
                        <button
                          className="waves-effect waves-light btn btn-light  ms-2"
                          onClick={() => history.goBack()}
                        >
                          Cancel
                        </button>
                      </div>
                          </Col>
                          {userData.role.role_name == "Admin" ? (
                            <Col xl={6} md={6} className="my-3">
                              <Label
                                className="form-label"
                                htmlFor="customSwitch2"
                              >
                                Show the team member on website{" "}
                                <Tooltip
                                  className={` ul-bg-dark`}
                                  placement="right"
                                  title={
                                    <div className="invoice-list-fsize-14">
                                      This counsellor will be published /
                                      unpublished on the website
                                    </div>
                                  }
                                  arrow
                                >
                                  <i
                                    className={`color-dark-green uil uil-info-circle m-0`}
                                  ></i>
                                </Tooltip>
                              </Label>
                              <div className="form-check form-switch">
                                <Input
                                  type="checkbox"
                                  className={`form-check-input ${
                                    userData?.role?.role_name == "Admin"
                                      ? ""
                                      : "opacity5"
                                  }`}
                                  id="customSwitch2"
                                  name={validation.values.is_published}
                                  checked={validation.values.is_published}
                                  onClick={e => handleToggle(e, validation)}
                                />
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Row>
                            <Col xl={6} lg={6} md={6}>
                              <Col xl={6} lg={12} md={12} className="mb-2">
                                <Label className="form-label">
                                  Profile Image
                                </Label>
                                <Dropzone
                                  multiple={false}
                                  onDrop={file => handleImg(file, "url")}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <input {...getInputProps()} />

                                      {!validation?.values?.url ? (
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <div className="mb-2">
                                            <i className="display-4 uil uil-cloud-upload" />
                                          </div>
                                          <h5>Drop JPEG, JPG or PNG.</h5>
                                          <h6>
                                            It can be square, landscape or
                                            portrait. Upto 5MB file size.
                                          </h6>
                                        </div>
                                      ) : (
                                        <>
                                          {validation?.values?.url?.state ===
                                            "uploading" && (
                                            <img
                                              className="needsclick rounded bg-light w-100 h-auto"
                                              alt="primary"
                                              src={Soinner}
                                            />
                                          )}
                                          {validation?.values?.url?.state ===
                                            "uploaded" && (
                                            <div>
                                              <img
                                                {...getRootProps()}
                                                className="needsclick rounded bg-light w-100 h-auto"
                                                alt="primary"
                                                src={
                                                  validation?.values?.url?.url
                                                }
                                              />
                                              <button
                                                onClick={() => {
                                                  validation.setFieldValue(
                                                    "url",
                                                    undefined
                                                  )
                                                }}
                                                className={`${style.removeButton} mdi mdi-close`}
                                                type="button"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </Dropzone>
                              </Col>
                              <Col
                                xl={6}
                                lg={12}
                                md={12}
                                className="mb-2"
                              ></Col>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">
                                  Therapist Overview
                                </Label>
                                <RichTextBox
                                  name="team_overview"
                                  value={initialValues.team_overview || ""}
                                  onChange={value =>
                                    validation.setFieldValue(
                                      "team_overview",
                                      value
                                    )
                                  }
                                  validation={validation}
                                  onBlur={() => {
                                    validation.setFieldTouched(
                                      "team_overview",
                                      true
                                    )
                                  }}
                                  error={validation.errors?.team_overview}
                                  touched={validation.touched?.team_overview}
                                />
                              </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} className="mb-2">
                              <div className="mb-2">
                                <Label className="form-label">
                                  Counsellor Bio
                                </Label>
                                <RichTextBox
                                  id="about_2"
                                  name="about_2"
                                  value={initialValues.about_2 || ""}
                                  onChange={value =>
                                    validation.setFieldValue("about_2", value)
                                  }
                                  validation={validation}
                                  onBlur={() => {
                                    validation.setFieldTouched("about_2", true)
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="light-green-bg-card border-light-shadow">
                        <CardBody>
                          <p className="h5 font-weight-bold mb-4">
                            Basic Information
                          </p>
                          <Row>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">First Name</Label>
                                <Input
                                  id="first_name"
                                  name="first_name"
                                  className="form-control"
                                  placeholder="Enter First Name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.first_name || ""}
                                  invalid={
                                    validation.touched.first_name &&
                                    validation.errors.first_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.first_name &&
                                validation.errors.first_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.first_name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                  id="last_name"
                                  name="last_name"
                                  className="form-control"
                                  placeholder="Enter Last Name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.last_name || ""}
                                  invalid={
                                    validation.touched.last_name &&
                                    validation.errors.last_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.last_name &&
                                validation.errors.last_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.last_name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">Role</Label>
                                <Input
                                  id="role"
                                  name="role"
                                  className="form-control"
                                  placeholder="Enter role"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.role || ""}
                                  disabled={
                                    userData.role.id !== 1 ? true : false
                                  }
                                  invalid={
                                    validation.touched.role &&
                                    validation.errors.role
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.role &&
                                validation.errors.role ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.role}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">
                                  Calendar Name
                                </Label>
                                <Input
                                  id="name"
                                  name="name"
                                  disabled
                                  className={`${style.disabledField} form-control`}
                                  placeholder="Enter calendar name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.name &&
                                validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">Languages</Label>
                                <CustomMultiSelect
                                  className="input"
                                  onChange={value =>
                                    validation.setFieldValue("languages", value)
                                  }
                                  isMulti
                                  placeholder="Select languages"
                                  value={validation.values.languages}
                                  options={languages}
                                  error={validation.errors?.languages}
                                  touched={validation.touched?.languages}
                                  isCloseMenuOnSelect={false}
                                  onBlur={() => {
                                    validation.setFieldTouched(
                                      "languages",
                                      true
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">City</Label>
                                <CustomMultiSelect
                                  className="input"
                                  onChange={value =>
                                    validation.setFieldValue(
                                      "location_groups",
                                      value
                                    )
                                  }
                                  placeholder="Select city"
                                  value={validation.values.location_groups}
                                  options={locationGroups}
                                  error={validation.errors?.location_groups}
                                  touched={validation.touched?.location_groups}
                                  isCloseMenuOnSelect={false}
                                  isMulti
                                  onBlur={() => {
                                    validation.setFieldTouched(
                                      "location_groups",
                                      true
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">Location</Label>
                                <CustomMultiSelect
                                  className="input"
                                  onChange={value =>
                                    validation.setFieldValue("locations", value)
                                  }
                                  placeholder="Select locations"
                                  value={validation.values.locations}
                                  options={location}
                                  error={validation.errors?.locations}
                                  touched={validation.touched?.locations}
                                  isMulti
                                  isCloseMenuOnSelect={false}
                                  onBlur={() => {
                                    validation.setFieldTouched(
                                      "locations",
                                      true
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2 form-custom-white-inputs">
                                <Label className="form-label">Timezone</Label>
                                <CustomMultiSelect
                                  className="input"
                                  name="timezone"
                                  onChange={value =>
                                    validation.setFieldValue("timezone", value)
                                  }
                                  placeholder="Select Timezone"
                                  value={validation.values.timezone}
                                  options={timezone}
                                  error={validation.errors?.timezone}
                                  touched={validation.touched?.timezone}
                                  onBlur={() => {
                                    validation.setFieldTouched("timezone", true)
                                  }}
                                />
                                {validation.touched.timezone &&
                                validation.errors.timezone ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.timezone}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="light-green-bg-card border-light-shadow">
                        <CardBody>
                          <p className="h5 font-weight-bold mb-4">
                            Contact Information
                          </p>
                          <Row>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">Email</Label>
                                <Input
                                  id="email"
                                  name="email"
                                  disabled
                                  className={`${style.disabledField} form-control`}
                                  placeholder="Enter Email"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-2">
                                <Label className="form-label font-weight-bold">
                                  Primary phone number
                                </Label>
                                <div className="d-flex gap-2">
                                  <div className="input-group">
                                    <PhoneNoField
                                      className="phoneinpute"
                                      name="primary_phones"
                                      value={validation?.values?.mobile_number}
                                      disabled={
                                        user && user.mobile_number
                                          ? true
                                          : false
                                      }
                                      onChange={value =>
                                        validation.setFieldValue(
                                          "mobile_number",
                                          value
                                        )
                                      }
                                      handleChange={validation.handleChange}
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-2">
                                <Label className="form-label font-weight-bold">
                                  Phone number
                                </Label>
                                <div className="d-flex gap-2">
                                  <div className="input-group">
                                    {phoneNumberList &&
                                      phoneNumberList.map((n, index) => {
                                        return (
                                          <div
                                            className={`input-group ${
                                              index > 0 ? "mt-3" : ""
                                            }`}
                                            key={index}
                                          >
                                            {index != 0 && (
                                              <div className="d-flex align-items-center justify-content-between w-100 mb-1">
                                                <p className="font-weight-bold mb-0">
                                                  {ordinalSuffixOf(index + 1)}{" "}
                                                  phone number
                                                </p>
                                                <i
                                                  className="mdi mdi-delete font-size-16 cursor-pointer-accordion "
                                                  onClick={() =>
                                                    deletePhoneNumber(index)
                                                  }
                                                />
                                              </div>
                                            )}
                                            <PhoneNoField
                                              className="phoneinpute"
                                              name="phones"
                                              onChange={value =>
                                                validation.setFieldValue(
                                                  "phones",
                                                  handleChangeMobileNumber(
                                                    index,
                                                    "mobile_number",
                                                    value
                                                  )
                                                )
                                              }
                                              onBlur={validation.handleBlur}
                                              value={n.mobile_number}
                                            />
                                          </div>
                                        )
                                      })}
                                    {validation.errors.phones ? (
                                      <div className="error">
                                        {validation.errors.phones}
                                      </div>
                                    ) : null}
                                    <p
                                      onClick={addAnotherPhoneNumber}
                                      className="mt-3 phone-number-field-style"
                                    >
                                      + Add another phone number
                                    </p>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="light-green-bg-card border-light-shadow">
                        <CardBody>
                          <p className="h5 font-weight-bold mb-4">
                            Counselling Information
                          </p>

                          <Row>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">
                                  Appointment Type
                                </Label>
                                <CustomMultiSelect
                                  className={`${style.disabledField} input`}
                                  disabled={true}
                                  placeholder="Select Appointment Type"
                                  value={validation.values.appointment_types}
                                  options={appointmentType}
                                  error={validation.errors?.appointment_types}
                                  touched={
                                    validation.touched?.appointment_types
                                  }
                                  isCloseMenuOnSelect={false}
                                  isMulti
                                  onBlur={() => {
                                    validation.setFieldTouched(
                                      "appointment_types",
                                      true
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xl={6} lg={6} md={6}>
                              <div className="mb-2 ">
                                <Label className="form-label">
                                  Counselling Type
                                </Label>
                                <CustomMultiSelect
                                  className="input "
                                  onChange={value =>
                                    validation.setFieldValue(
                                      "counselling_type",
                                      value
                                    )
                                  }
                                  placeholder="Select counselling type"
                                  value={validation.values.counselling_type}
                                  options={counsellingTypeList}
                                  error={validation.errors?.counselling_type}
                                  touched={validation.touched?.counselling_type}
                                  isCloseMenuOnSelect={false}
                                  isMulti
                                  onBlur={() => {
                                    validation.setFieldTouched(
                                      "counselling_type",
                                      true
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">Therapies</Label>
                                <CustomMultiSelect
                                  className="input "
                                  onChange={value =>
                                    validation.setFieldValue("therapies", value)
                                  }
                                  placeholder="Select therapies"
                                  value={validation.values.therapies}
                                  options={therapiesList}
                                  error={validation.errors?.therapies}
                                  touched={validation.touched?.therapies}
                                  isCloseMenuOnSelect={false}
                                  isMulti
                                  onBlur={() => {
                                    validation.setFieldTouched(
                                      "therapies",
                                      true
                                    )
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">
                                  Areas of Practice
                                </Label>
                                <CustomMultiSelect
                                  className="input"
                                  onChange={value =>
                                    validation.setFieldValue("concerns", value)
                                  }
                                  placeholder="Select areas of practice"
                                  value={validation.values.concerns}
                                  options={concerns}
                                  error={validation.errors?.concerns}
                                  touched={validation.touched?.concerns}
                                  isCloseMenuOnSelect={false}
                                  isMulti
                                  onBlur={() => {
                                    validation.setFieldTouched("concerns", true)
                                  }}
                                />
                                {validation.touched.concerns &&
                                validation.errors.concerns ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.concerns}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-2">
                                <Label className="form-label">
                                  Areas of Specialization
                                </Label>
                                <CustomMultiSelect
                                  className="input"
                                  onChange={value =>
                                    validation.setFieldValue(
                                      "specialization",
                                      value
                                    )
                                  }
                                  placeholder="Select areas of specialization"
                                  value={validation.values.specialization}
                                  options={concerns}
                                  error={validation.errors?.specialization}
                                  touched={validation.touched?.specialization}
                                  isCloseMenuOnSelect={false}
                                  isMulti
                                  onBlur={() => {
                                    validation.setFieldTouched(
                                      "specialization",
                                      true
                                    )
                                  }}
                                />
                                {validation.touched.specialization &&
                                validation.errors.specialization ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.specialization}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <div className="text-start m-0 p-0">
                        {!inProgress ? (
                          <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            type="submit"
                            disabled={isSubmitted}
                          >
                            {"Update"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary w-sm waves-effect waves-light"
                            disabled
                          >
                            <Spinner
                              type="grow"
                              className="me-1 align-middle spinner-grow-sm "
                              color="light"
                            />
                            Processing...
                          </button>
                        )}
                        <button
                          className="waves-effect waves-light btn btn-light  ms-2"
                          onClick={() => history.goBack()}
                        >
                          Cancel
                        </button>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Form>
            ))}
          {activeTab.type === "availability" && <Availibity />}
          {activeTab.type === "clients" && <ClientList />}
          {activeTab.type === "sessions" && (
            <AppointmentTypeModal
              isSubmittedAppointment={isSubmittedAppointment}
              // isModalOpen={isModalOpen}
              handleEModalToggle={handleEModalToggle}
              validationAppointment={validationAppointment}
              categoryTypeAppointmentTypesTotal={
                categoryTypeAppointmentTypesTotal
              }
              categoryTypeAppointmentTypes={categoryTypeAppointmentTypes}
              handleMainChecked={handleMainChecked}
              selectedAppointmentTypes={selectedAppointmentTypes}
              handleChildChecked={handleChildChecked}
              handleSearchApoointment={handleSearchApoointment}
              setLoaderAppointmentType={setLoaderAppointmentType}
              loaderAppointmentType={loaderAppointmentType}
            />
          )}
          {activeTab.type === "pricing" && (
            <>
              <PriceModal
                onPriceToggle={onPriceToggle}
                handleSelectType={handleSelectType}
                handleEnterPrice={handleEnterPrice}
                submitPrice={submitPrice}
                isPricemodalOpen={isPricemodalOpen}
                selectedappointmentTypesOpt={selectedappointmentTypesOpt}
                priceInProgress={priceInProgress}
                appointmentTypesPrice={appointmentTypesPrice}
                getCounselorDetail={getCounselorDetail}

              />
              <CounsellorPay
                initialCpayValues={initialCpayValues}
                id={id}
                getCounselorDetail={getCounselorDetail}
                userData={userData}
              />
            </>
          )}
          {activeTab.type === "rooms" && <Rooms activeTab={activeTab} />}
          {activeTab.type === "notes" && (
            <Card className="light-green-bg-card-white mt-5">
              <Remarks username={user?.id} user={{"role_id":2}}/>
            </Card>
          )}

          <Modal isOpen={ismodalOpen} toggle={onToggle}>
            <ModalBody></ModalBody>
          </Modal>
          <DeleteCounsellor
            deleteToggle={deleteToggle}
            modalDelete={modalDelete}
            setModalDelete={setModalDelete}
            setDeleteRecord={setDeleteRecord}
            inProgress={inProcess}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default CounselorManage
