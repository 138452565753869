import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import { useDispatch } from "react-redux"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { withRouter } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import { CHANGE_PASSWORD } from "./gql/loginGql"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useHistory } from "react-router-dom"
import { CREATE_EDIT_USER, GET_USER } from "../User/gql/userGql"
import Loader from "../../components/Common/Loader"
import { useSelector } from "react-redux"
import { validate } from "graphql"
import { loginSuccess } from "../../store/actions"

const EditProfile = props => {
  let history = useHistory()
  let dispatch = useDispatch()
  const userData = useSelector(state => state.Login?.user?.user)
  const [inProgress, setInProgress] = useState(false)
  const [isLoader, setIsLoader] = useState(true)
  const [user, setUser] = useState({})
  const [initValues, setInitValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    age: 0,
  })

  const [getUserDetail, details] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  })
  const [submitMutation, submitResponse] = useMutation(CREATE_EDIT_USER)
  useEffect(() => {
    setIsLoader(true)
    getUserDetail({
      variables: {
        argumentsInput: {
          id: parseInt(userData.id),
        },
      },
    })
  }, [])
  useEffect(() => {
    if (
      details.data &&
      details.data.getUser &&
      details.data.getUser.code == 200 &&
      details.data.getUser.data
    ) {
      setInitValues({ ...details.data.getUser.data.user })
      setIsLoader(false)
    } else {
    }
  }, [details.data])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initValues,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required("Please enter your first name")
        .min(
          3,
          "A minimum of 3 characters is required for the first name field"
        )
        .max(
          50,
          "The system restricts the first name input to a maximum of 50 characters"
        ),
      last_name: Yup.string()
        .required("Please enter your last name")
        .min(3, "A minimum of 3 characters is required for the last name field")
        .max(
          50,
          "The system restricts the last name input to a maximum of 50 characters"
        ),
      age: Yup.string()
        .nullable()
        .required("Please enter your age")
        .max(3, "age digit must be 3 caracter only"),
      email: Yup.string().required("Please enter your email"),
    }),
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  const handleSubmit = async values => {
    let userInput = {
      id: userData.id,
      age: values.age.toString(),
      email: initValues.email ? initValues.email : values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      role_id: userData.role.id,
      is_edit: true,
    }
    try {
      // setInProgress(true)
      await submitMutation({
        variables: {
          createUserInput: userInput,
        },
      })
      // validation.resetForm()
    } catch (e) {
      setInProgress(false)
    }
  }
  React.useEffect(() => {
    setInProgress(false)
    if (submitResponse.data && submitResponse.data.createAdminUser.status) {
      toast.success(submitResponse.data.createAdminUser.message)
      let userStoreData = localStorage.getItem("authUser")
      userStoreData = userStoreData && JSON.parse(userStoreData)
      userStoreData.user.first_name = validation.values.first_name
      userStoreData.user.last_name = validation.values.last_name
      localStorage.setItem("authUser", JSON.stringify(userStoreData))
      dispatch(loginSuccess(userStoreData))
    } else {
      if (submitResponse.data && submitResponse.data.createAdminUser) {
        toast.error(submitResponse.data.createAdminUser.message)
      }
    }
  }, [submitResponse.data])

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoader ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="container-fluid">
            {/* Render Breadcrumb */}

            <Row className="align-items-center justify-content-center">
              <Col xl="12">
                <div className="page-title-box d-flex align-items-center">
                  <i
                    className="waves-effect mdi mdi-arrow-left font-size-22 card-title-main"
                    id="backArrow"
                    onClick={() => history.goBack()}
                  />
                  <h4 className="mb-0 card-title-main mx-2">User Profile</h4>
                </div>
              </Col>
            </Row>

            <Row className="align-items-center justify-content-center">
              <Col xl="12">
                <Card className="transparent-bg-card">
                  <CardBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col
                          xl="6"
                          className="mt-3 "
                          md={{
                            offset: 0,
                            size: 6,
                          }}
                        >
                          <div className="form-group form-custom-white-inputs">
                            <Label className="form-label">First Name</Label>
                            <Input
                              name="first_name"
                              className="form-control"
                              placeholder="Enter First Name"
                              type="text"
                              onChange={e =>
                                validation.setFieldValue(
                                  "first_name",
                                  e.target.value.charAt(0).toUpperCase() +
                                    e.target.value.slice(1)
                                )
                              }
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              invalid={
                                validation.touched.first_name &&
                                validation.errors.first_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.first_name &&
                            validation.errors.first_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.first_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xl="6"
                          className="mt-3"
                          md={{
                            offset: 0,
                            size: 6,
                          }}
                        >
                          <div className="form-group form-custom-white-inputs">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              name="last_name"
                              className="form-control"
                              placeholder="Enter Last Name"
                              type="text"
                              onChange={e =>
                                validation.setFieldValue(
                                  "last_name",
                                  e.target.value.charAt(0).toUpperCase() +
                                    e.target.value.slice(1)
                                )
                              }
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ""}
                              invalid={
                                validation.touched.last_name &&
                                validation.errors.last_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.last_name &&
                            validation.errors.last_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xl="6"
                          className="mt-3"
                          md={{
                            size: 6,
                          }}
                        >
                          <div className="form-group form-custom-white-inputs">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              disabled={validation.values.email ? true : false}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xl="6"
                          className="mt-3"
                          md={{
                            size: 6,
                          }}
                        >
                          <div className="form-group form-custom-white-inputs">
                            <Label className="form-label">Age</Label>
                            <Input
                              name="age"
                              className="form-control"
                              placeholder="Enter Age"
                              type="number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.age || ""}
                              invalid={
                                validation.touched.age && validation.errors.age
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.age && validation.errors.age ? (
                              <FormFeedback type="invalid">
                                {validation.errors.age}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-4">
                        <Button type="submit" color="primary">
                          Save
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditProfile)
