import { gql } from "@apollo/client"
export const GET_DASHBOARD_ANALYTICS = gql`
  query DashboardAnalytics($dashboardGraph: DashboardInput!) {
    dashboardAnalytics(dashboardGraph: $dashboardGraph) {
      code
      message
      status
      data {
        total_billable_count
        Billing_percentage
        Billing_percentage_status
        total_counsellors
        total_clients
        total_appoinments
        external_sessions
        internal_Sessions
        session_completed
        deleted_clients
        clientPercentage
        counsellorPercentage
        appointmentPercentage
        counsellorPercentageStatus
        total_billable_amount
        appointmentPercentageStatus
        total_appoinments_count
        clientPercentageStatus
        __typename
        NoshowFilterAppointmentsCurrentmonth
        active_icbc
        active_other
        appointment_scheduled_count_current_month
        appointment_type_graph {
          appointments_type
          percentage
          __typename
        }
        client_registered_count_current_month
        counsellor_registered_count_current_month
        deactive_icbc
        deactive_other
        deleted_clientPercentage
        leastPerformingCounsellorsData {
          counsellor_name
          count
          __typename
        }
        month_wise_appointment_data {
          count
          day
          __typename
        }
        month_wise_client_data {
          count
          day
          __typename
        }
        no_show_counts
        no_show_percentage
        non_direct_active_client
        non_direct_deactive_client
        session_completed_count_current_month
        topPerformingCounsellorsData {
          counsellor_name
          count
          __typename
        }
        year_wise_client_data {
          year
          month_name
          month
          count
          __typename
        }
        year_wise_appointment_data {
          year
          month_name
          month
          count
          __typename
        }
        no_show_percentage_status
        deactivate_clients_percentage_status
        initial_report_count
        initial_report_percentage
        initial_report_percentage_status
        past_appointments_count
        past_appointments_percentage
        past_appointments_percentage_status
      }
      __typename
    }
  }
`

export const GET_APPOINTMENT_DATA = gql`
  mutation DashboardGraph($dashboardGraph: DashboardInput!) {
    dashboardGraph(dashboardGraph: $dashboardGraph) {
      code
      message
      status
      data {
        year_wise_appointment_data {
          count
          month
          month_name
          year
        }
        month_wise_appointment_data {
          count
          day
        }
        year_wise_client_data {
          count
          month
          month_name
          year
        }
        month_wise_client_data {
          count
          day
        }
      }
    }
  }
`

export const GET_ANALYTICS_BILLABLE_CHART_DETAILS = gql`
  mutation DashboardGraph($dashboardGraph: DashboardInput!) {
    dashboardGraph(dashboardGraph: $dashboardGraph) {
      code
      message
      status
      data {
        yearly_billable_count {
          total
          month
          month_name
          year
        }
        monthly_billable_count {
          total
          day
        }
      }
    }
  }
`

export const GET_CLIENT_REGISTER_DATA = gql`
  mutation DashboardGraph($dashboardGraph: DashboardInput!) {
    dashboardGraph(dashboardGraph: $dashboardGraph) {
      code
      message
      status
      data {
        year_wise_client_data {
          count
          month
          month_name
          year
        }
        month_wise_client_data {
          count
          day
        }
      }
    }
  }
`

export const GET_NO_SHOW_DATA = gql`
  query ViewNoShowAppointments($dashboardInput: DashboardInput!) {
    ViewNoShowAppointments(dashboardInput: $dashboardInput) {
      data {
        no_show_counts
        groupedData {
          appointment_date_created
          data {
            id
            first_name
            last_name
            date
            date_created
            calendar
          }
        }
        completed_task_obj {
          appointment_date_created
          data {
            is_no_show_complete_task
            id
            first_name
            last_name
            date
            date_created
          }
        }
        view_no_show_appointmens {
          id
          last_name
          first_name
          date_created
          date
          calendar
        }
      }
    }
  }
`
export const GET_Deactivated_Clients_DATA = gql`
  query ViewDeactivatedClients($dashboardInput: DashboardInput!) {
    ViewDeactivatedClients(dashboardInput: $dashboardInput) {
      data {
        groupedDataUsers {
          date_created
          data {
            id
            first_name
            last_name
            deleted_at
            created_at
          }
        }
        completed_task_obj_users {
          date_created
          data {
            id
            deleted_at
            first_name
            last_name
          }
        }
        deleted_clients
        view_deactivated_clients_Record {
          created_at
          deleted_at
          first_name
          last_name
          is_active
          id
        }
      }
    }
  }
`
export const GET_INITIAL_DATA = gql`
  query ViewInitialReports($dashboardInput: DashboardInput!) {
    ViewInitialReports(dashboardInput: $dashboardInput) {
      data {
        groupedData {
          appointment_date_created
          data {
            first_name
            id
            last_name
            date
            date_created
          }
        }
        completed_task_obj {
          appointment_date_created
          data {
            is_no_show_complete_task
            id
            first_name
            last_name
            date
            date_created
          }
        }
        initial_reports {
          date_created
          date
          first_name
          id
          last_name
        }
      }
    }
  }
`
export const GET_PAST_CLIENT_DATA = gql`
  query ViewPastClients($dashboardInput: DashboardInput!) {
    ViewPastClients(dashboardInput: $dashboardInput) {
      data {
        groupedData {
          appointment_date_created
          data {
            first_name
            last_name
            id
            date
            date_created
            __typename
          }
          __typename
        }
        completed_task_obj {
          appointment_date_created
          data {
            is_no_show_complete_task
            id
            first_name
            last_name
            date
            date_created
          }
        }
        past_appointments {
          date
          date_created
          first_name
          id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`
export const GET_TOP_COUNSELLOR_DATA = gql`
  mutation TopPerformingCounsellors(
    $topPerformingCounsellors: DashboardInput!
  ) {
    topPerformingCounsellors(
      topPerformingCounsellors: $topPerformingCounsellors
    ) {
      code
      data {
        topPerformingCounsellorsData {
          counsellor_name
          count
        }
      }
    }
  }
`
export const GET_LEAST_COUNSELLOR_DATA = gql`
  mutation leastPerformingCounsellors(
    $leastPerformingCounsellors: DashboardInput!
  ) {
    leastPerformingCounsellors(
      leastPerformingCounsellors: $leastPerformingCounsellors
    ) {
      code
      data {
        leastPerformingCounsellorsData {
          counsellor_name
          count
        }
      }
    }
  }
`
export const GET_UPDATE_COMPLETED_DATA = gql`
  mutation UpdateAppointment($updateAppointmentInput: UpdateAppointmentInput!) {
    updateAppointment(updateAppointmentInput: $updateAppointmentInput) {
      code
      message
      status
    }
  }
`
export const GET_UPDATE_DEAVTIVE_COMPLETED_DATA = gql`
  mutation UpdateUserTask($updateUserInput: updateUserInput!) {
    UpdateUserTask(updateUserInput: $updateUserInput) {
      code
      message
      status
    }
  }
`
export const GET_CLIENTS = gql`
  query GetDeactivateActivateRequests($createPlanInput: CreatePlanInput!) {
    getDeactivateActivateRequests(createPlanInput: $createPlanInput) {
      code
      message
      status
      data {
        request {
          id
          plan {
            id
            client {
              name
              first_name
              last_name
            }
          }
          remarks
          requestor {
            id
            name
          }
        }
      }
    }
  }
`

export const SUBMIT_CLIENT = gql`
  mutation Mutation($createPlanInput: CreatePlanInput!) {
    approveRequestByAdmin(createPlanInput: $createPlanInput) {
      code
      message
      status
      __typename
    }
  }
`
