import { gql } from "@apollo/client"


export const DELETE_PERMISSION = gql`
    mutation DeleteUserPermission($permissionInput: PermissionInput!) {
        deleteUserPermission(permissionInput: $permissionInput) {
            code
            status
            message
        }
    }
`

export const GET_USER_PERMISSION_LIST = gql`
    query GetUserPermissionList($argumentsInput: ArgumentsInput!) {
        getUserPermissionList(argumentsInput: $argumentsInput) {
            code
            status
            message
            data {
                totalCount
                response
            }
        }
    }
`

export const GET_ROLE_PERMISSION_LIST = gql`
query GetRolePermissionList($argumentsInput: ArgumentsInput!) {
    getRolePermissionList(argumentsInput: $argumentsInput) {
      code
      status
      data {
        response
        totalCount
      }
    }
  }
`

export const MODULES = gql`
query Query($argumentsInput: ArgumentsInput) {
    getModules(argumentsInput: $argumentsInput) {
      code
      status
      message
      data {
        modules {
          id
          name
          slug
          is_active
          methods {
            id
            name
            slug
            module_id
          }
        }
      }
    }
  }
`

export const USER_LIST = gql`
    query GetUsers($argumentsInput: ArgumentsInput) {
        getUsers(argumentsInput: $argumentsInput) {
            code
            status
            message
            data {
                totalCount
                users {
                    id
                    name
                }
            }
        }
    }
`

export const GET_PERMISSION = gql`
    query GetPermission($argumentsInput: ArgumentsInput!) {
        getPermission(argumentsInput: $argumentsInput) {
            code
            status
            message
            data {
                permission
            }
        }
    }
`

export const ADD_PERMISSION = gql`
    mutation AddPermission($permissionInput: PermissionInput!) {
        addPermission(permissionInput: $permissionInput) {
            code
            status
            message
        }
    }
`

export const GET_ROLES = gql`
query GetRoles($argumentsInput: ArgumentsInput) {
    getRoles(argumentsInput: $argumentsInput) {
      code
      status
      data {
        roles {
          id
          created_at
          updated_at
          deleted_by
          created_by
          role_name
          slug
          is_active
        }
        totalCount
      }
    }
  }`